/* eslint-disable max-len */
// import { Opt, Optional, ensure } from "../../mary-lib/utils/Optional";

import { ReduxState, CollectiveInvoiceForm } from "./ReduxState";
import { ClaimFilters } from "./Claims/Types";
import { CollectiveInvoiceFilters } from "./CollectiveInvoice/Types";
import { ClaimMatchFilters } from "./ClaimMatch/Types";
import { IncassoFilters } from "./Incassos/Types";
import { StatementFilters } from "./Statements/Types";
import { GeneralLedgerFilters } from "./GeneralLedgers/Types";
import { PolicyFilters } from "./Policies/Types";
import { FileFilters } from "./Files/Types";
import { InvoiceFilters } from "./Invoice/Types";
import { PartnerFilters } from "./Partners/Types";
import { ExcassoFilters } from "./Excassos/Types";
import { MBFilters } from "./ManualBooking/Types";
import { BookingPeriodFilters } from "./BookingPeriod/Types";
import { PaymentFilters } from "./PartnerPayments/Types";
import { MandateFilters } from "./Mandates/Types";
import { ModelFilters } from "./Models/Types";
import { ProductFilters } from "./Product/Types";
import { TariffFilters } from "./Tariffs/Types";
import { State } from "../utils/redux/State";

export type FilterTypes = ClaimFilters | ClaimMatchFilters | IncassoFilters
| StatementFilters | PolicyFilters | CollectiveInvoiceFilters | GeneralLedgerFilters | FileFilters | InvoiceFilters
| PartnerFilters | ExcassoFilters | MBFilters | BookingPeriodFilters | PaymentFilters | MandateFilters
| ModelFilters | ProductFilters | TariffFilters;
/**
 *
 */
export enum FilterScope {
    CLAIMS = "CLAIMS",
    INCASSOS = "INCASSOS",
    EXCASSOS = "EXCASSOS",
    CLAIM_MATCH = "CLAIM_MATCH",
    STATEMENTS = "STATEMENTS",
    COLLECTIVE_INVOICES = "COLLECTIVE_INVOICES",
    INVOICES = "INVOICES",
    POLICIES = "POLICIES",
    PARTNERS = "PARTNERS",
    GENERAL_LEDGER = "GENERAL_LEDGER",
    FILES = "FILES",
    MANUAL_BOOKING = "MANUAL_BOOKING",
    BOOKING_PERIOD = "BOOKING_PERIOD",
    PAYMENTS = "PAYMENTS",
    MANDATES = "MANDATES",
    MODELS = "MODELS",
    PRODUCTS = "PRODUCTS",
    TARIFFS = "TARIFFS",
}

export const filterHasValue = (value?: string | number) => !!value || value === 0;

/**
 *
 * @param s
 */
export const getFilters =
    (s: ReduxState, scope: FilterScope): FilterTypes =>
        scope === FilterScope.CLAIMS ?
            s.prop("filters").prop(scope).map(f => ({
                ...f,
            })) :
            scope === FilterScope.CLAIM_MATCH ?
                s.prop("filters").prop(scope).map(f => ({
                    ...f,
                })) :
                scope === FilterScope.INCASSOS ?
                    s.prop("filters").prop(scope).map(f => ({
                        ...f,
                    })) :
                    scope === FilterScope.EXCASSOS ?
                        s.prop("filters").prop(scope).map(f => ({
                            ...f,
                        })) :
                        scope === FilterScope.STATEMENTS ?
                            s.prop("filters").prop(scope).map(f => ({
                                ...f,
                            })) :
                            scope === FilterScope.POLICIES ?
                                s.prop("filters").prop(scope).map(f => ({
                                    ...f,
                                })) :
                                scope === FilterScope.PARTNERS ?
                                    s.prop("filters").prop(scope).map(f => ({
                                        ...f,
                                    })) :
                                    scope === FilterScope.GENERAL_LEDGER ?
                                        s.prop("filters").prop(scope).map(f => ({
                                            ...f,
                                        })) :
                                        scope === FilterScope.FILES ?
                                            s.prop("filters").prop(scope).map(f => ({
                                                ...f,
                                            })) :
                                            scope === FilterScope.INVOICES ?
                                                s.prop("filters").prop(scope).map(f => ({
                                                    ...f,
                                                })) :
                                                scope === FilterScope.MANUAL_BOOKING ?
                                                    s.prop("filters").prop(scope).map(f => ({
                                                        ...f,
                                                    })) :
                                                    scope === FilterScope.BOOKING_PERIOD ?
                                                        s.prop("filters").prop(scope).map(f => ({
                                                            ...f,
                                                        })) :
                                                        scope === FilterScope.PAYMENTS ?
                                                            s.prop("filters").prop(scope).map(f => ({
                                                                ...f,
                                                            })) :
                                                            scope === FilterScope.MANDATES ?
                                                                s.prop("filters").prop(scope).map(f => ({
                                                                    ...f,
                                                                })) :
                                                                scope === FilterScope.MODELS ?
                                                                    s.prop("filters").prop(scope).map(f => ({
                                                                        ...f,
                                                                    })) :
                                                                    scope === FilterScope.PRODUCTS ?
                                                                        s.prop("filters").prop(scope).map(f => ({
                                                                            ...f,
                                                                        })) :
                                                                        scope === FilterScope.TARIFFS ?
                                                                            s.prop("filters").prop(scope).map(f => ({
                                                                                ...f,
                                                                            })) :
                                                                            s.prop("filters").prop(scope).map(f => ({
                                                                                ...f,
                                                                            }));

export const getDuration = (duration: State<CollectiveInvoiceForm>) =>
    duration.map(f => ({
        ...f,
    }));

export const searchableFilters = (filters: FilterTypes, defaultFilters?: FilterTypes): boolean => Object.keys(filters).filter(filter => !defaultFilters
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    ? true : Object.keys(defaultFilters).every(dF => (dF !== filter || dF === filter) && (defaultFilters[dF] !== filters[filter]))).some(k => filterHasValue(filters[k]));

export const hasSearchableFilters =
    (s: ReduxState, scope: FilterScope, defaultFilters?: FilterTypes): boolean =>
        scope === FilterScope.CLAIMS ?
            s.prop("filters").prop(scope)
                .map(f => searchableFilters(f, defaultFilters))
            :
            scope === FilterScope.INCASSOS ?
                s.prop("filters").prop(scope)
                    .map(f => searchableFilters(f, defaultFilters))
                :
                scope === FilterScope.EXCASSOS ?
                    s.prop("filters").prop(scope)
                        .map(f => searchableFilters(f, defaultFilters))
                    :
                    scope === FilterScope.CLAIM_MATCH ?
                        s.prop("filters").prop(scope)
                            .map(f => searchableFilters(f, defaultFilters))
                        :
                        scope === FilterScope.STATEMENTS ?
                            s.prop("filters").prop(scope)
                                .map(f => searchableFilters(f, defaultFilters))
                            :
                            scope === FilterScope.POLICIES ?
                                s.prop("filters").prop(scope)
                                    .map(f => searchableFilters(f, defaultFilters))
                                :
                                scope === FilterScope.PARTNERS ?
                                    s.prop("filters").prop(scope)
                                        .map(f => searchableFilters(f, defaultFilters))
                                    :
                                    scope === FilterScope.GENERAL_LEDGER ?
                                        s.prop("filters").prop(scope)
                                            .map(f => searchableFilters(f, defaultFilters))
                                        :
                                        scope === FilterScope.FILES ?
                                            s.prop("filters").prop(scope)
                                                .map(f => searchableFilters(f, defaultFilters))
                                            :
                                            scope === FilterScope.INVOICES ?
                                                s.prop("filters").prop(scope)
                                                    .map(f => searchableFilters(f, defaultFilters))
                                                :
                                                scope === FilterScope.MANUAL_BOOKING ?
                                                    s.prop("filters").prop(scope)
                                                        .map(f => searchableFilters(f, defaultFilters))
                                                    :
                                                    scope === FilterScope.BOOKING_PERIOD ?
                                                        s.prop("filters").prop(scope)
                                                            .map(f => searchableFilters(f, defaultFilters))
                                                        :
                                                        scope === FilterScope.PAYMENTS ?
                                                            s.prop("filters").prop(scope)
                                                                .map(f => searchableFilters(f, defaultFilters))
                                                            :
                                                            scope === FilterScope.MANDATES ?
                                                                s.prop("filters").prop(scope)
                                                                    .map(f => searchableFilters(f, defaultFilters))
                                                                :
                                                                scope === FilterScope.MODELS ?
                                                                    s.prop("filters").prop(scope)
                                                                        .map(f => searchableFilters(f, defaultFilters))
                                                                    :
                                                                    scope === FilterScope.PRODUCTS ?
                                                                        s.prop("filters").prop(scope)
                                                                            .map(f => searchableFilters(f, defaultFilters))
                                                                        :
                                                                        scope === FilterScope.TARIFFS ?
                                                                            s.prop("filters").prop(scope)
                                                                                .map(f => searchableFilters(f, defaultFilters))
                                                                            :
                                                                            s.prop("filters").prop(scope)
                                                                                .map(f => searchableFilters(f, defaultFilters));
