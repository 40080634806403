import { RequestObject } from "../RemoteTypes";

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type BookingPeriodFilters = Readonly<Partial<{
    year: number;
    month: number;
}>>;

export enum BookingPeriodStatus {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

export enum BookingPeriodMonths {
    JANUARY = "JANUARY",
    FEBRUARY = "FEBRUARY",
    MARCH = "MARCH",
    APRIL = "APRIL",
    MAY = "MAY",
    JUNE = "JUNE",
    JULY = "JULY",
    AUGUST = "AUGUST",
    SEPTEMBER = "SEPTEMBER",
    OCTOBER = "OCTOBER",
    NOVEMBER = "NOVEMBER",
    DECEMBER = "DECEMBER",
}

export interface BookingPeriod {
    id: number;
    createdAt?: string;
    status: BookingPeriodStatus;
    month: BookingPeriodMonths;
    year: number;
    startingDate: string;
    endingDate: string;
}

export interface BookingPeriods extends RequestObject {
    content: BookingPeriod[];
}
