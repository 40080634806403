/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import { ProductDetail, ProductStatus, ProductTariff } from "../../../../../../store/Product/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";

import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { ReduxState } from "../../../../../../store/ReduxState";
import { UpdateEndDateModal } from "../UpdateEndDateModal";
import { UserInfo, UserRoles } from "../../../../../../store/AppTypes";
import { CoverageLevelDisplay } from "../../../../../../store/Policies/Displays";
import { Div } from "../../../../../../components/core/03-base/Div";
import { FlexColumn } from "../../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../../components/core/03-base/Flex/Row";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";
import { ThemeSize, ThemeShadowSizes, ThemePalette, ThemeBreakpoints } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";
import { Container } from "../../../../../../components/core/03-base/Container";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { FormTextArea } from "../../../../../../components/core/07-organisms/Form/TextArea";
import { Button } from "../../../../../../components/core/05-atoms/Button";

interface State {
    selectedIndexes: number[];
}

/**
 *
 */
interface OwnProps {
    tariffs?: ProductTariff[];
    asAudit?: boolean;
}

/**
 *
 */
interface StateProps {
    formData: ProductDetail;
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    openUpdateRequest: (modalId: string, productTariff: ProductTariff, productId: string) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param props
 */
export class TariffsComp
    extends React.Component<Props, State> {


    public constructor(props: Props) {
        super(props);

        this.state = { selectedIndexes: [] };
        this.show = this.show.bind(this);
    }
    public static mapStateToProps(s: ReduxState): StateProps {
        return {
            formData: s.prop("remote").prop(RemoteScope.PRODUCT_DETAILS) as ProductDetail,
            user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
        };
    }

    /**
     *
     */
    public static mapDispatchToProps(dispatch: DispatchFunc): DispatchProps {
        return {
            openUpdateRequest: (modalId: string, productTariff: ProductTariff, productId: string) => dispatch(
                modalShow(
                    modalId,
                    () => (
                        <Modal
                            modalID={modalId}
                            theme={{ size: ThemeSize.SMALL }}
                        >
                            <UpdateEndDateModal
                                modalID={modalId}
                                productTariff={productTariff}
                                productId={productId}
                            />
                        </Modal>
                    ),
                ),
            ),
        };
    }


    public render() {
        const tariffs = this.props.tariffs ? this.props.tariffs : this.props.formData &&
            this.props.formData.content &&
            this.props.formData.content.productTariffs ?
            this.props.formData.content.productTariffs : undefined;
        return (
            tariffs && tariffs.length > 0 ? (
                <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
                    <Container
                        theme={{
                            palette: ThemePalette.CONTRAST_PRIMARY,
                            padding: {
                                "": { y: 3 },
                                "sm": { y: 4 },
                            },
                        }}
                    >
                        {tariffs.map((tariff, index) => (
                            tariff.tariffLine && (
                                <>
                                    <Div
                                        theme={{
                                            shadow: ThemeShadowSizes.SMALL,
                                            margin: {
                                                "": { b: 2 },
                                                "sm": { b: 3 },
                                            },
                                        }}
                                        key={`tariff-${index}`}
                                    >
                                        <Container
                                            theme={{
                                                palette: ThemePalette.CONTRAST_PRIMARY,
                                                padding: {
                                                    "": { y: 3 },
                                                    "sm": { y: 4 },
                                                },
                                            }}
                                        >

                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 3 }}
                                                >
                                                    <FormField
                                                        label="Starting Date"
                                                        type="date"
                                                        value={tariff.startingDate}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 3 }}
                                                >
                                                    <FormField
                                                        label="Ending Date"
                                                        type="date"
                                                        value={tariff.endingDate}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 3 }}
                                                >
                                                    <FormField
                                                        label="Duration of Insurance"
                                                        type="number"
                                                        value={tariff.durationOfInsurance}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 3 }}
                                                >
                                                    <FormField
                                                        label="Model"
                                                        value={tariff.tariffLine.model?.name}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            {this.state.selectedIndexes.includes(index) &&
                                        <FieldSet
                                            title={"Tariff"}
                                            key={`tariff-2${index}`}
                                        >
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Max kilometers"
                                                        value={tariff.tariffLine.maxMileage}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Period"
                                                        value={tariff.tariffLine.tariffPeriod}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Coverage level"
                                                        value={CoverageLevelDisplay[
                                                            tariff.tariffLine.coverageLevel]}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Premium"
                                                        type="currency"
                                                        value={tariff.tariffLine.premium}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Tax"
                                                        type="currency"
                                                        value={tariff.tariffLine.tax}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Net Premium"
                                                        type="currency"
                                                        value={tariff.tariffLine.netPremium}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Cost"
                                                        type="currency"
                                                        value={tariff.tariffLine.cost}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Net Premium Excluding Cost"
                                                        type="currency"
                                                        value={tariff.tariffLine.netPremiumExCost}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Commission VWPFS"
                                                        type="currency"
                                                        value={tariff.tariffLine.commissionVWPFS}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Sign committee damage"
                                                        type="currency"
                                                        value={tariff.tariffLine.signCommitteeDamage}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Incasso provision"
                                                        type="currency"
                                                        value={tariff.tariffLine.incassoProvision}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="NetMij"
                                                        type="currency"
                                                        value={tariff.tariffLine.netMij}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Frontier"
                                                        type="currency"
                                                        value={tariff.tariffLine.frontier}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Insurer"
                                                        type="currency"
                                                        value={tariff.tariffLine.insurer}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Vehicle Type"
                                                        value={
                                                            tariff.tariffLine.passengerVehicle
                                                                && tariff.tariffLine.commercialVehicle
                                                                ? "Passenger and Commerical"
                                                                : tariff.tariffLine.commercialVehicle
                                                                    ? "Commercial"
                                                                    : "Passenger"}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">

                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Max kW."
                                                        value={
                                                            tariff.tariffLine?.maxKW}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Age from"
                                                        type="number"
                                                        value={tariff.ageOfVehicleFrom}
                                                        asSummary
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormField
                                                        label="Age to"
                                                        type="number"
                                                        value={tariff.ageOfVehicleTo}
                                                        asSummary
                                                    />
                                                </Grid>
                                            </Div>
                                            {tariff.tariffLine.description &&
                                                <Div className="scl-b-row">
                                                    <Grid
                                                        size={{ xs: 12, md: 12 }}
                                                    >
                                                        <FormTextArea
                                                            label="Description"
                                                            value={
                                                                tariff.tariffLine?.description}
                                                            asSummary
                                                        />
                                                    </Grid>
                                                </Div>
                                            }
                                        </FieldSet>}

                                        </Container>
                                        <FlexRow>
                                            <FlexColumn
                                                theme={{
                                                    palette: ThemePalette.CONTRAST_SECONDARY,
                                                }}
                                            >
                                                <Container
                                                    theme={{
                                                        padding: { "": { y: 3 } },
                                                    }}
                                                >
                                                    <ButtonsWrapper
                                                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                        alignment={Alignment.RIGHT}
                                                    >
                                                        <Button
                                                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                                            className="scl-a-btn--tiny"
                                                            link={{
                                                                onClick: () => this.show(index),
                                                            }}
                                                        >
                                                            {this.state.selectedIndexes.includes(index) ? "Hide tariff details" : "Show tariff details"}
                                                        </Button>
                                                        {this.props.formData?.content.status === ProductStatus.ACTIVE &&
                                                    this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                                                        <Button
                                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                            className="scl-a-btn--tiny"
                                                            link={{
                                                                onClick: () =>
                                                                    this.props.openUpdateRequest(
                                                                        "tariff",
                                                                        tariff,
                                                                        this.props.formData.content.id.toString()),
                                                            }}
                                                        >
                                                            Update Ending Date
                                                        </Button>
                                                        }
                                                    </ButtonsWrapper>
                                                </Container>
                                            </FlexColumn>
                                        </FlexRow>
                                    </Div>
                                </>)
                        ))}
                    </Container>
                </Div>
            ) : (
                <LoadingData loading={false} />
            )
        );
    }

    private show(index: number) {
        this.state.selectedIndexes.includes(index) ? this.setState({
            selectedIndexes: this.state.selectedIndexes.filter(item => item !== index),
        }) :
            this.setState({
                selectedIndexes: [...this.state.selectedIndexes, index],
            });
    }
}

/**
 *
 */
export const Tariffs = connect(
    TariffsComp.mapStateToProps,
    TariffsComp.mapDispatchToProps,
)(TariffsComp);
