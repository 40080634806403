import React from "react";
import { connect } from "react-redux";
import { Pages, PageTabs } from "../../../../../../../store/AppDisplays";
import { Filter } from "../../../../02-pages/Filter";
import { isRemoteLoading, ReduxState } from "../../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { Results } from "../../../../02-pages/Results";
import { Partners as PartnersType, PartnerType} from "../../../../../../../store/Partners/Types";
import { Div } from "../../../../../../../components/core/03-base/Div";

interface OwnProps {
    statementLineId: string;
    accountId: string;
}

interface DispatchProps {
}

interface State {
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: PartnersType;
}

type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.PARTNER_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.PARTNER_RESULTS) as PartnersType,
});

/**
 *
 * @param props
 */
export class PartnersComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { };
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={Pages.PARTNERS}
                    defaultFilters={{
                        accountId: this.props.accountId,
                        partnerType: PartnerType.POLICY_HOLDER,
                    }}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER}
                    statementLineId={this.props.statementLineId}
                    scope={RemoteScope.PARTNER_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Partners = connect(
    mapStateToProps,
    undefined,
)((props: Props) => <PartnersComp {...props}/>);
