import { AxiosResponse } from "axios";
import { Claims, ClaimDetails } from "./Types";

/**
 *
 * @param resp
 */
export const mapClaimRequests =
    (resp: AxiosResponse): Claims => resp.data as Claims;

/**
 *
 * @param resp
 */
export const mapClaimDetailsRequests =
    (resp: AxiosResponse): ClaimDetails => resp.data as ClaimDetails;
