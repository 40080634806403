import { MessageDirection, MessageStatus, MessageType } from "../Message/Types";

/**
 *
 */
export const FileTypeDisplay: Readonly<Record<MessageType, string>> = {
    [MessageType.POLICY]: "Polisaanvraag bestand",
    [MessageType.POLICY_RESPONSE]: "Terugkoppeling polisaanvraag bestand",
    [MessageType.CARPORT_FILE]: "Carport file",
    [MessageType.PAYMENT_FILE]: "Schadebetaling t.b.v. PON",
    [MessageType.CLAIM_DAMAGE_BOOKING]: "Schadeboeking PON",
    [MessageType.CLAIM_DAMAGE_REPORT]: "Schademelding PON",
    [MessageType.CLAIM_STAKEHOLDER]: "Schade betrokkene PON",
    [MessageType.CLAIM_HEADER]: "Schadegegevens PON t.b.v. VWFS",
    [MessageType.CLAIM_DETAIL]: "Schadedetails PON t.b.v. VWFS",
    [MessageType.STATEMENT]: "Bank statement",
    [MessageType.SOA_PREMIUM_BORDERAUX_REPORT]: "Statement of Account",
    [MessageType.INCASSO]: "Incasso",
    [MessageType.EXCASSO]: "Excasso",
    [MessageType.COLLECTIVE_INVOICE]: "Collective invoice",
    [MessageType.INDIVIDUAL_INVOICE]: "Policy Invoice",
    [MessageType.JOINT_COLLECTIVE_INVOICE]: "Joint collective invoice",
    [MessageType.EXACT]: "EDIS-file",
    [MessageType.NVGA]: "NVGA",
    [MessageType.DGP]: "Portefeuille productie overzicht",
    [MessageType.OIC]: "Tussenpersoon kosten rapport",
    [MessageType.UNKNOWN]: "Onbekend",
    [MessageType.OUTSTANDING_ITEMS]: "Openstaande posten lijst",
    [MessageType.INSURANCE_OVERVIEW]: "Invoice overview",
    [MessageType.PREMIUM_CLAIM_OVERVIEW]: "Premie schade raport",
    [MessageType.CLAIM_HEADER_MESSAGE_REPORT]: "Maandelijkse schadegegevens t.b.v. VWFS",
    [MessageType.POLICY_MESSAGE_REPORT]: "Maandelijkse polisoverzicht t.b.v. VWFS",
    [MessageType.CLAIM_DETAIL_MESSAGE_REPORT]: "Maandelijkse schadedetails VWFS",
    [MessageType.POLICY_PREMIUM_MESSAGE_REPORT]: "Maandelijkse premieoverzicht t.b.v. VWFS",
    [MessageType.PORTFOLIO_OVERVIEW_REPORT]: "Portfolio overview",
    [MessageType.LOSS_RATIO_REPORT]: "Loss ratio report",
    [MessageType.PREMIUM_CLAIM_PER_YEAR_REPORT]: "Premium claim per year report",
    [MessageType.LOSS_RATIO_REPORT_EXPIRED]: "Loss ratio report - (Expired Only)",
    [MessageType.PRODUCTION_REPORT]: "Production overview report",
};

/**
 *
 */
export const MessageStatusDisplay: Readonly<Record<MessageStatus, string>> = {
    [MessageStatus.CREATED]: "Created",
    [MessageStatus.RECEIVED]: "Received",
    [MessageStatus.PROCESSED]: "Processed",
    [MessageStatus.UNPARSEABLE]: "Unparseable",
};

/**
 *
 */
export const MessageDirectionDisplay: Readonly<Record<MessageDirection, string>> = {
    [MessageDirection.IN]: "In",
    [MessageDirection.OUT]: "Out",
};
