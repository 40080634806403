
import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { getPartnerTypeColor } from "../../../../utils/FormatHelpers";
import { Partners } from "../../../../../store/Partners/Types";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import { addPartnersSelectionCI, deletePartnersSelectionCI,
    deleteSinglePartnerCI } from "../../../../../store/CollectiveInvoice/GenerateInvoice";
import { ReduxState } from "../../../../../store/ReduxState";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { Container } from "../../../../../components/core/03-base/Container";

interface State {
    selectedRows: number[];
}
/**
 *
 */
interface OwnProps {
    results: Partners;
    generateInvoice?: (selectedRows: number[]) => void;
}

/**
 *
 */
interface StateProps {
    partnersCI: number[];
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    addPartners: (selectedRows: number[]) => void;
    deletePartners: (selectedRows: number[]) => void;
    deletePartner: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;


/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    partnersCI: s.prop("partnersCI"),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.PARTNER_RESULTS_CI, {
        skip: skip,
    })),
    addPartners: (selectedRows: number[]) => (
        dispatch(addPartnersSelectionCI(selectedRows))
    ),
    deletePartners: (selectedRows: number[]) => (
        dispatch(deletePartnersSelectionCI(selectedRows),
        )
    ),
    deletePartner: (id: number) => (
        dispatch(deleteSinglePartnerCI(id),
        )
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);

        this.amIChecked = this.amIChecked.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <>
                    {this.props.results?.content.length > 0 &&
                <FormBar
                    title={"Select partners (step 2/2)"}
                    onClick={() =>
                        this.props.generateInvoice ? this.props.generateInvoice(this.props.partnersCI) : null}
                    isValid={this.state.selectedRows.length > 0
                        ? true : undefined}
                    buttonLabel={(
                        <>
                            Generate
                            &nbsp;&nbsp;
                            <Icon name={IconNames.ARROW_RIGHT} />
                        </>
                    )}
                />
                    }
                    <Table
                        hasActions
                        data={{
                            columns: this.resultsData().columns,
                            rows: this.resultsData().rows,
                            sort: this.resultsData().sort,
                        }}
                    />
                    {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                        <Container
                            className="scl-h-text-align--center"
                            theme={{
                                padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                            }}
                        >
                            <Pagination
                                pageCount={this.props.results.totalPages}
                                currentPage={this.props.results.number}
                                onPageChange={this.handlePageClick}
                                theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                            />
                        </Container> : null}
                </>
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                (
                    <div
                        key={`selection-${row.id}`}
                        className="scl-row-selection"
                        style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            flexGrow: 0,
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="scl-row-selection__checkbox"
                            style={{
                                flex: 1,
                                flexGrow: 0,
                                marginRight: "5px",
                            }}
                        >
                            <Checkbox
                                checked={this.amIChecked(row.id)}
                                onChange={(value?: boolean) => this.selectItem(value, row.id)}
                            />
                        </div>
                    </div>
                ),
                row.name ?? "",
                row.partnerNumber,
                row.partnerType ? (
                    <Badge
                        theme={{
                            palette: getPartnerTypeColor(row.partnerType),
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        {PartnerTypeDisplay[row.partnerType]}
                    </Badge>
                ) : "",
                row.iban,
                <Currency amount={row.invoiceBalance} key={`partner-${index}`}/>,
            ],
        }));
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: (
                        <div
                            className="scl-row-selection scl-row-selection--all"
                            style={{
                                display: "inline-flex",
                                flexDirection: "row",
                                flexGrow: 0,
                                alignItems: "baseline",
                            }}
                        >
                            <div
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                <Checkbox
                                    style={{
                                        verticalAlign: "bottom",
                                    }}
                                    onChange={(value?: boolean) => this.selectItems(value)}
                                    checked={this.areWeAllChecked()}
                                />
                            </div>
                            <label
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                &nbsp;Select
                            </label>
                        </div>
                    ),
                },
                {
                    label: "Name",
                },
                {
                    label: "Number",
                },
                {
                    label: "Type",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Invoice balance",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }

    private selectItems(value: boolean | undefined) {
        if (value) {
            const ListArr = this.props.results.content.map((row => row.id));
            this.setState({
                selectedRows: ListArr,
            }, () => {
                this.props.addPartners(this.state.selectedRows);
            });
        } else {
            const ListArr = this.props.results.content.map((row => row.id));
            this.setState({
                selectedRows: [],
            }, () => {
                console.log(this.state.selectedRows);
                this.props.deletePartners(ListArr);
            });
        }
    }

    private areWeAllChecked(): boolean {
        if (this.props.results && this.props.results.content) {
            return this.state.selectedRows.length === this.props.results.content.length;
        }
        return false;
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: this.props.partnersCI ? this.props.partnersCI
                .filter(partner => (this.props.results.content.some(row => row.id === partner)))
                .map(partner => partner)
                : [],
        };
    }

    private selectItem(value: boolean | undefined, id: number) {
        this.setState({
            selectedRows: !!value ? [...this.state.selectedRows, Number(id)]
                : this.state.selectedRows.filter((row) => Number(row) !== id,
                ),
        }, !!value ? () => this.props.addPartners(this.state.selectedRows) : () => this.props.deletePartner(id));
    }

    private amIChecked(id: number): boolean {
        return this.state.selectedRows.includes(id);
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
