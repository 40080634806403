import React from "react";
import { connect } from "react-redux";

import { Results } from "../../../02-pages/Results";
import { ClaimErrors as ClaimErrorsType } from "../../../../../../store/ClaimErrors/Types";
import { isRemoteLoading, ReduxState } from "../../../../../../store/ReduxState";
import { Div } from "../../../../../../components/core/03-base/Div";
import { PageTabs } from "../../../../../../store/AppDisplays";
import { RemoteScope } from "../../../../../../store/RemoteTypes";

/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    results: ClaimErrorsType;
}

type Props = StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingResults: isRemoteLoading(s, RemoteScope.CLAIM_ERROR_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.CLAIM_ERROR_RESULTS) as ClaimErrorsType,
});

/**
 *
 * @param props
 */
export class ClaimErrorsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Div style={{ marginTop: "-20px" }}>
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.CLAIMS_ERRORS}
                    scope={RemoteScope.CLAIM_ERROR_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const ClaimErrors = connect(
    mapStateToProps,
    {},
)(ClaimErrorsComp);
