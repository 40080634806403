/* eslint-disable max-len */
import * as React from "react";
import { Div } from "../../../../03-base/Div";
import { Grid } from "../../../../03-base/Grid";
import { FieldSet } from "../../../../06-molecules/FieldSet";
import * as Forms from "../../../../07-organisms/Form/index";
import { FieldLabelValue } from "../../../../07-organisms/Form/index";
import * as I18n from "../../../../../../utils/translations/I18n";

import { LogoutButton } from "./LogoutButton";


export interface UserProps {
    user?: {
        name?: string | FieldLabelValue;
        subtitle?: string | FieldLabelValue;
    };
}
/**
 *
 */
interface OwnProps extends UserProps {
    onLogout: (b?: boolean) => void;

}

/**
 *
 */
type Props = OwnProps;


/**
 *
 */
export class General
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);
    }

    /**
     *
     */
    public render() {
        return (
            <>
                <FieldSet title={I18n.getText("mary.09-views.03-modals.accountsettings.tabs.general-information-title",
                    "Information") ?? ""}>
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <Forms.FormField
                                label={typeof this.props.user?.name === "string" ?
                                    I18n.getText("mary.09-views.03-modals.accountsettings.tabs.general-user-name-label",
                                        "Username") : this.props.user?.name?.label}
                                value={typeof this.props.user?.name === "string"
                                    ? this.props.user?.name : this.props.user?.name?.value}
                                asSummary
                            />
                        </Grid>
                    </Div>
                    {this.props.user?.subtitle &&
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <Forms.FormField
                                label={typeof this.props.user?.subtitle === "string" ?
                                    I18n.getText("mary.09-views.03-modals.accountsettings.tabs.general-role-label",
                                        "Role") : this.props.user?.subtitle?.label}
                                value={typeof this.props.user?.subtitle === "string"
                                    ? this.props.user?.subtitle : this.props.user?.subtitle?.value}
                                asSummary
                            />
                        </Grid>
                    </Div>
                    }
                </FieldSet>
                <FieldSet title={I18n.getText("mary.09-views.03-modals.accountsettings.tabs.general-session-title",
                    "Session") ?? ""}>
                    <LogoutButton allDevices={false} signOut={this.props.onLogout} />
                    <br /><br />
                    <LogoutButton allDevices signOut={this.props.onLogout} />
                </FieldSet>
            </>
        );
    }
}
