import * as React from "react";
import { connect } from "react-redux";


import { NavLink } from "react-router-dom";
import { Partners } from "../../../../../store/Partners/Types";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { getPartnerTypeColor } from "../../../../utils/FormatHelpers";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: Partners;
    book?: boolean;
    accountId?: string;
    statementId?: string;
    asAuditTab?: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.PARTNER_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.name ? row.name : "",
                row.partnerNumber,
                row.partnerType ? (
                    <Badge
                        theme={{
                            palette: getPartnerTypeColor(row.partnerType),
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        {PartnerTypeDisplay[row.partnerType]}
                    </Badge>
                ) : "",
                row.iban,
                <Currency amount={row.invoiceBalance} key={`partner-${index}`}/>,
                <Currency amount={row.claimBalance} key={`partner-${index}`}/>,
                (
                    <h6 key={`total-balance-${index}`}>
                        <Currency amount={row.totalBalance} key={`partner-${index}`}/>
                    </h6>
                ),
                (
                    <ButtonsWrapper
                        key={`navlink-${row.id}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >{
                            this.props.asAuditTab ? (
                                <NavLink to={`/audit/partners/${row.id}`}>
                                    <Button
                                        icon={IconNames.ARROW_RIGHT}
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                            ): (
                                <NavLink to={`/partners/${row.id}`}>
                                    <Button
                                        icon={IconNames.ARROW_RIGHT}
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                            )}</ButtonsWrapper>),
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Name",
                },
                {
                    label: "Number",
                },
                {
                    label: "Type",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Invoice balance",
                },
                {
                    label: "Claim balance",
                },
                {
                    label: "Total balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
