import { RequestObject } from "../RemoteTypes";
import { hasRequiredFields } from "../Product/Types";
import { State } from "../../utils/redux/State";

export interface Memo {
    id: number;
    createdAt: string;
    description: string;
}

export interface Memos extends RequestObject {
    content: Memo[];
}

export interface MemoDetails extends RequestObject {
    content: Memo;
}

export interface MemoRequest {
    id?: number;
    description?: string;
}

export const canSubmitMemoForm =
    (memoRequest: State<MemoRequest>): boolean => {
        const validMandatoryMemoFields =
            memoRequest.map(f => hasRequiredFields(
                f,
                ["description"],
            ));
        return (
            validMandatoryMemoFields
        );
    };
