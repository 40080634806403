import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Tabs } from "../00-blocks/Tabs";
import { ClaimErrors } from "../../../../store/ClaimErrors/Types";
import { isRemoteLoading, ReduxState } from "../../../../store/ReduxState";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { PageDisplay, Pages, PageTabs } from "../../../../store/AppDisplays";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { Container } from "../../../../components/core/03-base/Container";

/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    results: ClaimErrors;
}
/**
 *
 */
interface DispatchProps {
    loadResults: () => void;
    clearResults: () => void;
}

type Props = DispatchProps & StateProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => (
    {
        loadingResults: isRemoteLoading(s, RemoteScope.CLAIM_ERROR_RESULTS),
        results: s.prop("remote").prop(RemoteScope.CLAIM_ERROR_RESULTS) as ClaimErrors,
    });

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: () => dispatch(remoteTrigger(RemoteScope.CLAIM_ERROR_RESULTS, {skip: 0})),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.CLAIM_ERROR_RESULTS)),
        dispatch(remoteClearError(RemoteScope.CLAIM_ERROR_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class SystemLogsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadResults();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }
    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >

                    <Header
                        title={PageDisplay[Pages.SYSTEM_LOGS].title}
                        description={PageDisplay[Pages.SYSTEM_LOGS].description}
                    />
                    <Tabs
                        tabs={PageDisplay[Pages.SYSTEM_LOGS].tabs}
                        defaultTab={PageTabs.CLAIMS_ERRORS}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const SystemLogs = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SystemLogsComp);
