
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../components/core/05-atoms/Icon";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import { Products } from "../../../../store/Product/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";
/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    loadingFilters: boolean;
    results: Products;
    user?: UserInfo;
}

interface DispatchProps {
    loadResults: () => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingResults: isRemoteLoading(s, RemoteScope.PRODUCT_RESULTS),
    results: s.prop("remote").prop(RemoteScope.PRODUCT_RESULTS) as Products,
    loadingFilters: false,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: () => dispatch(remoteTrigger(RemoteScope.PRODUCT_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_RESULTS)),
        dispatch(remoteClearError(RemoteScope.PRODUCT_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class ProductComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadResults();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.PRODUCT].title}
                                description={PageDisplay[Pages.PRODUCT].description}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <NavLink
                                        to={"/extendedwarranties/create"}
                                        exact
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            className="scl-a-btn--big"
                                        >
                                            {SubPageDisplay[SubPages.PRODUCT_CREATE].title}
                                        &nbsp;
                                        &nbsp;
                                            <Icon name={IconNames.PLUS} />
                                        </Button>
                                    </NavLink>
                                </Div>
                            </Grid>}
                    </Div>

                    <Div
                    >
                        <Filter
                            loadingFilters={this.props.loadingFilters}
                            loadingResults={this.props.loadingResults}
                            currentPage={Pages.PRODUCT}
                        />
                        <Results
                            results={this.props.results}
                            loadingResults={this.props.loadingResults}
                            currentPage={Pages.PRODUCT}
                            scope={RemoteScope.PRODUCT_RESULTS}
                        />
                    </Div>
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Product = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductComp);
