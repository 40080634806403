import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { FormTextArea } from "../../../../../components/core/07-organisms/Form/TextArea";
import { ManualBookingResults } from "../../../../../store/ManualBooking/Types";


interface OwnProps {
    details: ManualBookingResults;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Booking date"
                            asSummary
                            value={this.props.details.bookingDate}
                            type={"date"}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Status"
                            asSummary
                            value={this.props.details.status}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 12 }}
                    >
                        <FormTextArea
                            label="Description"
                            asSummary
                            value={this.props.details.description}
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}
