import React from "react";
import { Policy } from "../../../../../../../../store/Policies/Types";
import { ProductView } from "../../../../../00-blocks/Policies/component/ProductView";

interface Props {
    policy: Policy;
}
/**
 *
 * @param props
 */
export const Product: React.FC<Props> = (props: Props) => (
    <ProductView product={props.policy.extendedWarranty}/>
);
