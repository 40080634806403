import React from "react";
import { Container } from "../../../components/core/03-base/Container";
import { FlexRow } from "../../../components/core/03-base/Flex/Row";
import { Div } from "../../../components/core/03-base/Div";
import { getCurrentEnv, getCurrentEnvLabel } from "../../../utils/WhichEnv";
import * as I18n from "../../../utils/translations/I18n";


export const EnvIndicatorBar: React.FunctionComponent<{}> = () => {
    if(getCurrentEnv() === "prod") {
        return null;
    }
    // eslint-disable-next-line max-len
    const getEnv = <span>{I18n.getText("mary.06-molecules.env-indicator-bar-prefix", "You are currently on the")} <span className="scl-a-badge scl-a-badge--contrast-primary">{getCurrentEnvLabel()}</span> {I18n.getText("mary.06-molecules.env-indicator-bar-postfix", "environment")}</span>;
    return (
        <FlexRow isFixed>
            <Div
                className="scl-m-env-indicator"
            >
                <Container
                    theme={{
                        padding: {
                            "": { y: 1 },
                            "sm": { y: 1 },
                        },
                    }}
                    className="scl-m-env-indicator__content"
                >
                    {getEnv}
                </Container>
            </Div>
        </FlexRow>
    );
};
