import * as React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../store/AppDisplays";
import { CollectiveInvoiceStatusTypeDisplay } from "../../../../../store/CollectiveInvoice/Displays";
import { CollectiveInvoiceFilters, CollectiveInvoiceStatus } from "../../../../../store/CollectiveInvoice/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { CreditDebitIndicatorTypeDisplay } from "../../../../../store/Statements/Displays";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ThemePalette } from "../../../../../theme/_Types";
import { validFromValidation, validTillValidation } from "../../../../../utils/FormValidations";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Button } from "../../../../../components/core/05-atoms/Button";

/**
 *
 */
type setFilterFn = (filters: Partial<CollectiveInvoiceFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof CollectiveInvoiceFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: CollectiveInvoiceFilters;
    currentTab?: PageTabs;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: CollectiveInvoiceFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.COLLECTIVE_INVOICES, p.defaultFilters),
    filters: getFilters(s, FilterScope.COLLECTIVE_INVOICES) as CollectiveInvoiceFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.COLLECTIVE_INVOICES)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.COLLECTIVE_INVOICES));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_RESULTS)),
        dispatch(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.fromDateValidation = this.fromDateValidation.bind(this);
        this.toDateValidation = this.toDateValidation.bind(this);
        this.mutationTypeValidation = this.mutationTypeValidation.bind(this);
        this.invoiceStatusValidation = this.invoiceStatusValidation.bind(this);
        this.invoiceNumberValidation = this.invoiceNumberValidation.bind(this);
        this.partnerNumberValidation = this.partnerNumberValidation.bind(this);
        this.onChangeFromDate = this.onChangeFromDate.bind(this);
        this.onChangeToDate = this.onChangeToDate.bind(this);
        this.onChangeMutationType = this.onChangeMutationType.bind(this);
        this.onChangeInvoiceStatus = this.onChangeInvoiceStatus.bind(this);
        this.onChangeInvoiceNumber = this.onChangeInvoiceNumber.bind(this);
        this.onChangePartnerNumber = this.onChangePartnerNumber.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="From date"
                            onChange={this.onChangeFromDate}
                            type="date"
                            value={this.props.filters?.creationDateFrom ?? ""}
                            validationFunction={this.fromDateValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="To date"
                            onChange={this.onChangeToDate}
                            type="date"
                            value={this.props.filters?.creationDateTo ?? ""}
                            validationFunction={this.toDateValidation}
                        />
                    </Grid>
                </Div>
                {this.props.currentTab !== PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES &&
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <FormField
                                label="Mutation type"
                                onChange={this.onChangeMutationType}
                                value={
                                    this.props.filters?.creditDebitIndicator !== undefined ?
                                        this.props.filters.creditDebitIndicator :
                                        this.props.defaultFilters?.creditDebitIndicator
                                            ? this.props.defaultFilters.creditDebitIndicator : undefined}
                                placeholder={
                                    this.props.defaultFilters && this.props.defaultFilters.creditDebitIndicator
                                        ? undefined
                                        : "Make a choice"}
                                options={Object.keys(CreditDebitIndicator).map((p) => ({
                                    label: CreditDebitIndicatorTypeDisplay[p] as string,
                                    value: p,
                                }))}
                                validationFunction={this.mutationTypeValidation}
                            />
                        </Grid>
                        {this.props.currentTab !== PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES &&
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormField
                                    label="Invoice status"
                                    onChange={this.onChangeInvoiceStatus}
                                    value={
                                        this.props.defaultFilters?.invoiceStatus
                                        ?? this.props.filters?.invoiceStatus}
                                    placeholder="Make a choice"
                                    options={Object.keys(CollectiveInvoiceStatus).map((p) => ({
                                        label: CollectiveInvoiceStatusTypeDisplay[p] as string,
                                        value: p,
                                    }))}
                                    disabled={!!(this.props.defaultFilters
                                        && this.props.defaultFilters.invoiceStatus !== undefined)}
                                    readonly={!!(this.props.defaultFilters
                                        && this.props.defaultFilters.invoiceStatus !== undefined)}
                                    validationFunction={this.invoiceStatusValidation}
                                />
                            </Grid>
                        }
                        {this.props.currentTab === PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES &&
                            <Grid
                                size={{ xs: 12, md: 6 }}
                            >
                                <FormField
                                    label="Invoice number"
                                    onChange={this.onChangeInvoiceNumber}
                                    value={this.props.filters?.invoiceNumber ?? ""}
                                    type="text"
                                    validationFunction={this.invoiceNumberValidation}
                                />
                            </Grid>
                        }
                    </Div>
                }
                <Div className="scl-b-row">
                    {this.props.currentTab !== PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES &&
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <FormField
                                label="Invoice number"
                                onChange={this.onChangeInvoiceNumber}
                                value={this.props.filters?.invoiceNumber ?? ""}
                                type="text"
                                validationFunction={this.invoiceNumberValidation}
                            />
                        </Grid>
                    }
                    {this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE &&
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <FormField
                                label="Partner number"
                                onChange={this.onChangePartnerNumber}
                                type="text"
                                value={
                                    this.props.defaultFilters?.partnerNumber
                                    ?? this.props.filters?.partnerNumber ?? ""}
                                disabled={!!(this.props.defaultFilters && this.props.defaultFilters.partnerNumber)}
                                readonly={!!(this.props.defaultFilters && this.props.defaultFilters.partnerNumber)}
                                validationFunction={this.partnerNumberValidation}
                            />
                        </Grid>
                    }
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private fromDateValidation(value: string | number) {
        return validFromValidation(value.toString(),
            this.props.filters.creationDateTo
                ? this.props.filters.creationDateTo : undefined);
    }

    private onChangeFromDate(value?: string | number) {
        return this.onChange("creationDateFrom", value?.toString() ?? "");
    }

    private toDateValidation(value: string | number) {
        return validTillValidation(value.toString(),
            this.props.filters.creationDateFrom
                ? this.props.filters.creationDateFrom : undefined);
    }

    private onChangeToDate(value?: string | number) {
        return this.onChange("creationDateTo", value?.toString() ?? "");
    }

    private mutationTypeValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Mutation type provided");
    }

    private onChangeMutationType(value?: string | number) {
        return this.onChange("creditDebitIndicator", value
            ? value.toString() as CreditDebitIndicator
            : undefined);
    }

    private invoiceStatusValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Invoice status provided");
    }

    private onChangeInvoiceStatus(value?: string | number) {
        return this.onChange("invoiceStatus", value
            ? value.toString() as CollectiveInvoiceStatus : undefined);
    }

    private invoiceNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Invoice number provided");
    }

    private onChangeInvoiceNumber(value?: string | number) {
        return this.onChange("invoiceNumber", value ? value.toString() : "");
    }

    private partnerNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Partner number provided");
    }

    private onChangePartnerNumber(value?: string | number) {
        return this.onChange("partnerNumber", value?.toString() ?? "");
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof CollectiveInvoiceFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof CollectiveInvoiceFilters>(key: K, value: CollectiveInvoiceFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
