import { PartnerType } from "./Types";

/**
 *
 */
export const PartnerTypeDisplay: Readonly<Record<PartnerType, string>> = {
    [PartnerType.CLAIM_HANDLER]: "Claim handler",
    [PartnerType.INSURER]: "Insurer",
    [PartnerType.POLICY_HOLDER]: "Policy holder",
    [PartnerType.SELF_INSURER]: "Self insurer",
};
