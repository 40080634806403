import * as React from "react";
import { connect } from "react-redux";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { PolicyDetails, PremiumPolicy } from "../../../../../../store/Policies/Types";
import { UpdatePremiumModal } from "../UpdatePremiumModal";
import { UserInfo } from "../../../../../../store/AppTypes";
import { PremiumView } from "../component/PremiumView";
import { ReduxState } from "../../../../../../store/ReduxState";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { ThemeSize } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";

/**
 *
 */
interface OwnProps {
    policyId?: string;
}

/**
 *
 */
interface StateProps {
    formData: PolicyDetails;
    resultsSuccess?: PremiumPolicy;
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    openPremiumRequest?: (id: string) => void;
}

/**
 *
 */
export type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param props
 */
export class PremiumComp
    extends React.Component<Props, {}> {


    public constructor(props: Props) {
        super(props);
    }
    /**
     *
     */
    public static mapStateToProps(s: ReduxState): StateProps {
        return {
            formData: s.prop("remote").prop(RemoteScope.POLICY_DETAILS) as PolicyDetails,
            resultsSuccess: s.prop("remote").prop(RemoteScope.POLICY_PREMIUM_PUT) as PremiumPolicy,
            user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
        };
    }

    /**
     *
     */
    public static mapDispatchToProps(dispatch: DispatchFunc): DispatchProps {
        return {
            openPremiumRequest: (id: string) => {
                dispatch(
                    modalShow(
                        "premium",
                        () => (
                            <Modal
                                modalID={"premium"}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <UpdatePremiumModal
                                    modalID={"premium"}
                                    policyId={id}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
        };
    }

    public render() {
        const premium = this.props.formData &&
            this.props.formData.content &&
            this.props.formData.content.policyPremium ?
            this.props.formData.content.policyPremium : undefined;
        return (
            premium ? (
                <PremiumView {...this.props}/>
            ) : (
                <LoadingData loading={false} />
            )
        );
    }
}

/**
 *
 */
export const Premium = connect(
    PremiumComp.mapStateToProps,
    PremiumComp.mapDispatchToProps,
)(PremiumComp);
