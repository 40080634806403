import { Action } from "redux";

import { ActionTypeKeys } from "./ActionTypes";
import { ReducerFn } from "./ReduxState";

/**
 *
 */
type UpdateDescriptionAction = Readonly<
Action<ActionTypeKeys.UPDATE_BOOK_DESCRIPTION> & {
    description?: string;
}
>;

/**
 *
 * @param description
 */
export const updateDescription:
(description: string) => UpdateDescriptionAction =
    (description) => ({
        type: ActionTypeKeys.UPDATE_BOOK_DESCRIPTION,
        description: description,
    });

/**
 *
 */
export const updateDescriptionReducer:
ReducerFn<UpdateDescriptionAction> =
    (s, a) => (
        s.setProp("bookingDescription", a.description || "")
    );

export type ClearDescriptionAction = Readonly<
Action<ActionTypeKeys.CLEAR_BOOK_DESCRIPTION>
>;

/**
 *
 *
 */
export const clearDescription:
() => ClearDescriptionAction =
    () => ({
        type: ActionTypeKeys.CLEAR_BOOK_DESCRIPTION,
    });

/**
 *
 */
export const clearDescriptionReducer:
ReducerFn<ClearDescriptionAction> =
    (s, _a) => (
        s.setProp("bookingDescription", "")
    );
