import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../store/AppDisplays";
import { isRemoteLoading, ReduxState } from "../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Results } from "../../../02-pages/Results";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { Memos as MemoType } from "../../../../../../store/Memos/Types";
import { UpdateMemoModal } from "../UpdateMemoModal";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../../store/RemoteActions";
import { UserInfo, UserRoles } from "../../../../../../store/AppTypes";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Icon, IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { FormBar } from "../../../../../../components/core/07-organisms/FormBar";
import { ThemeSize } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";

/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    results: MemoType;
    user?: UserInfo;
}

interface DispatchProps {
    openMemoRequest: () => void;
    loadResults: () => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingResults: isRemoteLoading(s, RemoteScope.MEMO_RESULTS),
    results: s.prop("remote").prop(RemoteScope.MEMO_RESULTS) as MemoType,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: () => dispatch(remoteTrigger(RemoteScope.MEMO_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.MEMO_RESULTS)),
        dispatch(remoteClearError(RemoteScope.MEMO_RESULTS))
    ),
    openMemoRequest: () => {
        dispatch(
            modalShow(
                "memo",
                () => (
                    <Modal
                        modalID={"memo"}
                        theme={{ size: ThemeSize.SMALL }}
                    >
                        <UpdateMemoModal
                            modalID={"memo"}
                        />
                    </Modal>
                ),
            ),
        );
    },
});

/**
 *
 * @param props
 */
export class MemosComp
    extends React.Component<Props, {}> {
    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.props.loadResults();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Div style={{ marginTop: "-20px" }}>
                {this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    <FormBar
                        title="Manage memos"
                        onClick={this.props.openMemoRequest}
                        showButton
                        buttonLabel={<>
                        Add memo
                        &nbsp;
                        &nbsp;
                            <Icon name={IconNames.PLUS} />
                        </>}
                    /> : <></>}
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.POLICIES_MEMO}
                    scope={RemoteScope.MEMO_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Memos = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MemosComp);
