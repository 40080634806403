import * as React from "react";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { CreateAuto } from "../../00-blocks/Tariffs/Tabs/CreateAuto";

interface Props {
    tariffId: string;
}

export const EditAuto: React.FC<Props> = (props) => (
    <Page>
        <CreateAuto edit tariffId={props.tariffId} />
    </Page>
);
