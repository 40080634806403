
import React from "react";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Checkbox } from "../../../../../../components/core/05-atoms/Checkbox";
import { Currency } from "../../../../../../components/core/05-atoms/CurrencyDisplay";
import { Hr } from "../../../../../../components/core/05-atoms/Hr";
import { IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { Label } from "../../../../../../components/core/05-atoms/Label";
import { Required } from "../../../../../../components/core/05-atoms/Required";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { hasNotExceedLengthValidation,
    hasValueValidation } from "../../../../../../components/core/07-organisms/Form/Field.utils";
import { FormTextArea } from "../../../../../../components/core/07-organisms/Form/TextArea";
import { Models } from "../../../../../../store/Models/Types";
import { CoverageLevelDisplay } from "../../../../../../store/Policies/Displays";
import { CoverageLevel } from "../../../../../../store/Policies/Types";
import { TariffRequest } from "../../../../../../store/Tariffs/Types";
import { ThemePalette, ThemeShadowSizes } from "../../../../../../theme/_Types";
import { sumOfOtherComp, validationCheck } from "../../../../07-organisms/Form/PremiumValidation";

type OnChange =
    <K extends keyof TariffRequest>(key: K, value: TariffRequest[K]) => void;

interface OwnProps {
    onChange: OnChange;
    tariff: TariffRequest;
    formHelpers: {
        modelResults: Models;
    };
    automatic?: boolean;
    canSubmitTariffForm?: boolean;
    selectModel: (modalId: string, onChange: OnChange, id?: string)
    => void;
}

type Props = OwnProps;

/**
 *
 */
export const TariffFields: React.FunctionComponent<Props> = props => {
    const { netMij = 0, tax = 0, cost = 0, signCommitteeDamage = 0, incassoProvision = 0 } = props.tariff;
    const sum = sumOfOtherComp(netMij,tax,cost,signCommitteeDamage,incassoProvision);
    return (
        <>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Max KW"
                        required
                        onChange={(value?: string | number) =>
                            props.onChange("maxKW", value ? Number(value) : undefined)}
                        value={props.tariff.maxKW}
                        validationFunction={
                            (value: string | number) => hasValueValidation(
                                value.toString(),
                                "Max KW provided",
                            )}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Max kilometers"
                        type={"number"}
                        required
                        onChange={(value?: string | number) =>
                            props.onChange("maxMileage", value ? Number(value) : undefined)}
                        value={props.tariff.maxMileage}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Max kilometers provided")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Period"
                        required
                        type="number"
                        onChange={(value?: string | number) =>
                            props.onChange("tariffPeriod",
                                value ? Number(value) : undefined)}
                        value={props.tariff.tariffPeriod}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value,
                                    "Period provided")}
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Coverage level"
                        required
                        placeholder="Make a choice"
                        options={Object.keys(CoverageLevel).map(coverage => ({
                            label: CoverageLevelDisplay[coverage] as string,
                            value: coverage,
                        }))}
                        onChange={(value?: string | number) =>
                            props.onChange("coverageLevel",
                                value ? value.toString() as CoverageLevel : undefined)}
                        value={props.tariff.coverageLevel}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Coverage level provided")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <div
                        style={{
                            position: "relative",
                        }}
                    >
                        <FormField
                            label="Model"
                            placeholder={"Select a model"}
                            readonly
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Model selected")}
                            value={props.tariff.model?.id}
                        />
                        <div
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                            }}
                        >
                            <Button
                                icon={
                                    props.tariff.model
                                        ? IconNames.EDIT : IconNames.PLUS}
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                                link={{
                                    onClick: () =>
                                        props.selectModel(
                                            "select-model--modal",
                                            props.onChange,
                                            props.tariff.model?.id?.toString(),
                                        ),
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </Div>
            <Div>
                <Div
                    theme={{
                        padding: {
                            "": { b: 3 },
                            "sm": { b: 4 },
                        },
                    }}
                >
                    <Label>
                        {"Vehicle type"}
                        <span className={"scl-a-label__field--as-required"}>*</span>
                    </Label>
                    <Checkbox
                        checked={props.tariff.passengerVehicle ?? false}
                        onChange={(value: boolean | undefined) => props.onChange(
                            "passengerVehicle",
                            !!value ? true : false)}
                        label={"Private"}
                    />
                    <Checkbox
                        checked={props.tariff.commercialVehicle ?? false}
                        onChange={(value: boolean | undefined) => props.onChange(
                            "commercialVehicle",
                            !!value ? true : false)}
                        label={"Commercial"}
                    />
                </Div>
                <Div className={"scl-b-row"}>
                    <Grid
                        size={{ xs: 12, md: 12 }}
                    >
                        <FormTextArea
                            label={"Tariff description"}
                            onChange={(value?: string | number) => props.onChange("description",
                                value ? value.toString() : undefined)}
                            value={props.tariff.description}
                            validationFunction={
                                (value: string | number) =>
                                    hasNotExceedLengthValidation(value,
                                        "Tariff description provided", 255)}
                        />
                    </Grid>
                </Div>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormField
                        label="Premium"
                        required
                        type="currency"
                        onChange={(value?: string | number) =>
                            props.onChange("premium", value ? Number(value) : undefined)}
                        value={props.tariff.premium}
                        validationFunction={
                            (value: string | number) =>
                                props.automatic ? hasValueValidation(value.toString(),
                                    "Premium provided") : validationCheck(
                                    props.tariff.premium ?? 0,
                                    props.tariff.netMij ?? 0,
                                    props.tariff.tax ?? 0,
                                    props.tariff.cost ?? 0,
                                    props.tariff.signCommitteeDamage ?? 0,
                                    props.tariff.incassoProvision ?? 0,
                                    () => props.onChange("premium", sum),
                                    "Premium provided")}
                    />
                </Grid>
            </Div>
            <FieldSet title={"Premium components"}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Tax"
                            required
                            type="currency"
                            disabled={props.automatic}
                            readonly={props.automatic}
                            onChange={(value?: string | number) =>
                                props.onChange("tax", value ? Number(value) : undefined)}
                            value={props.tariff.tax}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Tax provided")}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Cost"
                            required
                            type={"currency"}
                            onChange={(value?: string | number) =>
                                props.onChange("cost",
                                    value ? Number(value) : undefined)}
                            value={props.tariff.cost}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Cost provided")}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Net mij"
                            required
                            type={"currency"}
                            disabled={props.automatic}
                            readonly={props.automatic}
                            onChange={(value?: string | number) =>
                                props.onChange("netMij",
                                    value ? Number(value) : undefined)}
                            value={props.tariff.netMij}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Net mij provided")}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Sign Commmittee Damage"
                            required
                            type={"currency"}
                            disabled={props.automatic}
                            readonly={props.automatic}
                            onChange={(value?: string | number) =>
                                props.onChange("signCommitteeDamage",
                                    value ? Number(value) : undefined)}
                            value={props.tariff.signCommitteeDamage}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Sign Commmittee Damage provided")}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Incasso provision"
                            required
                            type={"currency"}
                            disabled={props.automatic}
                            readonly={props.automatic}
                            onChange={(value?: string | number) =>
                                props.onChange("incassoProvision",
                                    value ? Number(value) : undefined)}
                            value={props.tariff.incassoProvision}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Incasso provision provided")}
                        />
                    </Grid>
                </Div>
                <Hr />
                <Div
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                        padding: {
                            "": { b: 3 },
                            "sm": { b: 4 },
                        },
                    }}
                >
                    <Div style={{display: "flex"}}>
                        <span>Total sum</span>
                        <span style={{marginLeft: "auto"}}><b><Currency amount={sum}/></b></span>
                    </Div>
                </Div>
            </FieldSet>
            <Div className={"scl-b-row"}>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Net Premium"
                        required
                        type={"currency"}
                        disabled={props.automatic}
                        readonly={props.automatic}
                        onChange={(value?: string | number) =>
                            props.onChange("netPremium",
                                value ? Number(value) : undefined)}
                        value={props.tariff.netPremium}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Net Premium provided")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Commission VWPFS"
                        required
                        type={"currency"}
                        onChange={(value?: string | number) =>
                            props.onChange("commissionVWPFS",
                                value ? Number(value) : undefined)}
                        value={props.tariff.commissionVWPFS}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Commission VWPFS provided")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Net Premium Ex Cost"
                        required
                        type={"currency"}
                        disabled={props.automatic}
                        readonly={props.automatic}
                        onChange={(value?: string | number) =>
                            props.onChange("netPremiumExCost",
                                value ? Number(value) : undefined)}
                        value={props.tariff.netPremiumExCost}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Net Premium Ex Cost provided")}
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Frontier"
                        required
                        type={"currency"}
                        disabled={props.automatic}
                        readonly={props.automatic}
                        onChange={(value?: string | number) =>
                            props.onChange("frontier",
                                value ? Number(value) : undefined)}
                        value={props.tariff.frontier}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Frontier provided")}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Insurer"
                        required
                        type={"currency"}
                        disabled={props.automatic}
                        readonly={props.automatic}
                        onChange={(value?: string | number) =>
                            props.onChange("insurer",
                                value ? Number(value) : undefined)}
                        value={props.tariff.insurer}
                        validationFunction={
                            (value: string | number) =>
                                hasValueValidation(value.toString(),
                                    "Insurer provided")}
                    />
                </Grid>
            </Div>
            <Required />
        </>
    );
};
