

import * as I18n from "./translations/I18n";

export const checkDomain = (check: string[]) => {
    let checked = false;
    check.forEach(c => {
        if (process.env.REACT_APP_URL?.includes(c) || window.location.host.includes(c)) {
            checked = true;
        }
    });
    return checked;
};

export const getCurrentEnv = () => {
    if (checkDomain(["int.", "test."])) {
        return "int";
    } else if (checkDomain(["cons.", "acc."])) {
        return "cons";
    } else if (checkDomain(["dev."])) {
        return "dev";
    } else if (checkDomain(["localhost"])) {
        return "local";
    }
    return "prod";
};

export const getCurrentEnvLabel = () => {
    const gCE = getCurrentEnv();
    switch (gCE) {
    case "int": {
        return I18n.getText("mary.utils.whichenv.int", "Integration");
    }
    case "cons": {
        return I18n.getText("mary.utils.whichenv.cons", "Consolidation");
    }
    case "dev": {
        return I18n.getText("mary.utils.whichenv.dev", "Development");
    }
    case "prod": {
        return I18n.getText("mary.utils.whichenv.prod", "Production");
    }
    case "local": {
        return I18n.getText("mary.utils.whichenv.local", "Local");
    }
    default: {
        return "";
    }
    }
};
