import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { State } from "../../utils/redux/State";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { PartnerRequest } from "./Types";

/**
 *
 */
export type InitUpdatePartnerAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_PARTNER> & {}
>;

/**
 *
 *
 */
export const initUpdatePartner =
    (): InitUpdatePartnerAction => ({
        type: ActionTypeKeys.INIT_UPDATE_PARTNER,
    });

/**
 *
 * @param s
 * @param _a
 */
export const initUpdatePartnerReducer:
ReducerFn<InitUpdatePartnerAction> =
    (s, _a) => {
        const partnerDetails = s.prop("remote").prop(RemoteScope.PARTNER_DETAILS);
        return (
            s.setProp(
                "partnerRequest",
                s.prop("partnerRequest").update((_old) => partnerDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdatePartnerAction = Readonly<
Action<ActionTypeKeys.UPDATE_PARTNER> & {
    partner?: Partial<PartnerRequest>;
}
>;

/**
 *
 *
 */
export const updatePartner =
    (partner: PartnerRequest): UpdatePartnerAction => ({
        type: ActionTypeKeys.UPDATE_PARTNER,
        partner: partner,
    });

/**
 *
 * @param s
 * @param a
 */
export const updatePartnerReducer:
ReducerFn<UpdatePartnerAction> =
    (s, a) => (
        s.setProp(
            "partnerRequest",
            s.prop("partnerRequest").updateWith(
                a.partner ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearPartnerAction = Readonly<
Action<ActionTypeKeys.CLEAR_PARTNER>
>;

/**
 *
 * @param keys
 */
export const clearPartner:
() => ClearPartnerAction =
    () => ({
        type: ActionTypeKeys.CLEAR_PARTNER,
    });

/**
 *
 */
export const clearPartnerReducer:
ReducerFn<ClearPartnerAction> =
    (s, _a) => (
        s.setProp(
            "partnerRequest",
            State.create<PartnerRequest>(() => ({})),
        )
    );
