import * as React from "react";

import { joinClasses } from "../../../utils/Filters";
import { Button } from "../05-atoms/Button";
import { Icon, IconNames } from "../05-atoms/Icon";
import { ColumnData, SortData } from "./Table";

interface Props {
    columns: ColumnData[];
    activeSort?: SortData;
    onSortChange?: (column: number, asc: boolean) => void;
    hasActions?: boolean;
}

// filterFn
const changeSort = (column: number, asc: boolean, onSortChange?: (column: number, asc: boolean) => void) => {
    if (onSortChange) {
        onSortChange(column, asc);
    }
};

const getThClass = (
    column: ColumnData, index: number, columnLength: number, activeSort?: SortData, hasActions?: boolean) => (
    joinClasses(
        "scl-m-table__th",
        column.sortable ? "scl-m-table__th--with-filter" : undefined,
        column.alignRight ? "align-right" : undefined,
        activeSort && activeSort.column === index ? "active" : undefined,
        !!hasActions && index + 1 === columnLength ? "with-actions" : undefined,
    ));

export const TableHeader: React.FunctionComponent<Props> = (props) => (
    <thead className="scl-m-table__head">
        <tr className="scl-m-table__tr">
            {props.columns.map((column, index) => (
                <th
                    className={getThClass(column, index, props.columns.length, props.activeSort, props.hasActions)}
                    key={`th-${index}`}
                    onClick={() => column.sortable ? changeSort(index,
                        (props.activeSort && props.activeSort.column === index
                            ? (props.activeSort
                                ? !props.activeSort.asc : true) : (props.activeSort
                                ? props.activeSort.asc : true)), props.onSortChange) : null}
                >
                    {
                        column.sortable
                            ? (
                                <Button
                                    className="scl-m-table__th-filter-btn"
                                >
                                    {column.label}
                                    {"  "}
                                    {props.activeSort && (
                                        <Icon
                                            name={props.activeSort && props.activeSort.asc
                                                ? IconNames.SORT_DOWN : IconNames.SORT_UP}
                                        />
                                    )}
                                </Button>
                            )
                            : column.label
                    }
                </th>))}
        </tr>
    </thead>
);
