import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearPolicy } from "../../../../../store/Policies/EditPolicyTypes";
import { PremiumPolicy } from "../../../../../store/Policies/Types";
import { canSubmitUpdatePolicyPremiumForm, clearPolicyPremium,
    initUpdatePolicyPremium } from "../../../../../store/Policies/UpdatePolicyPremium";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";

import { PolicyPremium } from "../../02-pages/policies/PolicyPremium";

/**
 *
 */
interface OwnProps {
    modalID: string;
    policyId: string;
}

interface StateProps {
    canSubmitUpdateForm: boolean;
    policyPremium: PremiumPolicy;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    updatePolicyPremium: (id: string, policyPremium: PremiumPolicy, policyId: string) => void;
    clearPolicyPremium: () => void;
    initUpdate: () => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    canSubmitUpdateForm: canSubmitUpdatePolicyPremiumForm(s.prop("policyPremiumRequest")),
    policyPremium: s.prop("policyPremiumRequest").map(e => ({
        ...e,
    })),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    updatePolicyPremium: (id: string, policyPremium: PremiumPolicy, policyId: string) =>
        dispatch(remoteTrigger(RemoteScope.POLICY_PREMIUM_PUT, {
            id: id,
            policyPremium: policyPremium,
            policyId: policyId,
        })),
    clearPolicyPremium: () => {
        dispatch(clearPolicy());
        dispatch(clearPolicyPremium());
        dispatch(remoteClearResponse(RemoteScope.POLICY_PREMIUM_PUT));
    },
    initUpdate: () => dispatch(initUpdatePolicyPremium()),
});

/**
 *
 */
export class UpdatePremiumComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.closeModal = this.closeModal.bind(this);
        this.updatePremium = this.updatePremium.bind(this);
    }

    public componentDidMount(){
        this.props.initUpdate();
    }

    public componentWillUnmount() {
        this.props.clearPolicyPremium();
    }

    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {"Update Premium"}
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button  icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }}
                                    />
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn
                        style={{
                            maxHeight: "calc(98vh - 120px)",
                        }}>
                        <FlexRow isScrollable >
                            <div style={{width: "100%"}}>
                                <FillUp>
                                    <Container
                                        theme={{
                                            padding: {
                                                "": { y: 3 },
                                                "md": { y: 4 },
                                            },
                                        }}
                                    >
                                        <>
                                            <PolicyPremium policyPremium={this.props.policyPremium} />
                                            <Div><Required /></Div>
                                        </>
                                    </Container>
                                </FillUp>
                            </div>
                        </FlexRow>
                        <FlexRow isFixed>
                            <FlexColumn
                                theme={{
                                    palette: ThemePalette.CONTRAST_SECONDARY,
                                }}
                            >
                                <Container
                                    theme={{
                                        padding: { "": { y: 3 } },
                                    }}
                                >
                                    <ButtonsWrapper
                                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                        alignment={Alignment.RIGHT}
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                            link={{ onClick: this.closeModal() }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            disabled={!this.props.canSubmitUpdateForm}
                                            link={{ onClick: this.updatePremium }}
                                            buttonType="submit"
                                        >
                                            Update
                                        </Button>
                                    </ButtonsWrapper>
                                </Container>
                            </FlexColumn>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }

    private updatePremium() {
        this.props.updatePolicyPremium(
            this.props.policyPremium.id?.toString() ?? "",
            this.props.policyPremium,
            this.props.policyId,
        );
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }
}

/**
 *
 */
export const UpdatePremiumModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdatePremiumComp);
