import { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from "axios";
import { Opt } from "../utils/Optional";

import { AccountRequest } from "./Accounts/Types";
import { DispatchFunc } from "./ActionTypes";
import { DisplayOf } from "./AppDisplays";
import { BrandRequest } from "./Brand/Types";
import { BookClaims } from "./ClaimMatch/Types";
import { BookInvoice, ExcassoInfo, InExInfo, InExFormScope, InvoiceSelectedRows } from "./CollectiveInvoice/Types";
import { BookExcasso } from "./Excassos/Types";
import { BookLedger, GLRequest } from "./GeneralLedgers/Types";
import { BookIncasso } from "./Incassos/Types";
import { MandateRequest } from "./Mandates/Types";
import { ManualBookings } from "./ManualBooking/Types";
import { MemoRequest } from "./Memos/Types";
import { ModelRequest } from "./Models/Types";
import { BookPartner, PartnerRequest } from "./Partners/Types";
import { PolicyRequest, PremiumPolicy } from "./Policies/Types";
import { ProductRequest, ProductTariffRequests } from "./Product/Types";
import { ReduxState, RemoteState } from "./ReduxState";
import { ReportForm } from "./Reports/Types";
import { UnbookStatementLine } from "./Statements/Types";
import { TariffRequest } from "./Tariffs/Types";

export const RESULTS_LIMIT = 50;

export enum RemoteErrorType {
    DEFAULT = "DEFAULT",
    VALIDATION = "VALIDATION",
    SYSTEM_ERROR = "SYSTEM_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export type MethodType = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH";

export interface HeadersType {
    [key: string]: string;
}

export interface RemoteConfig<T extends RemoteScope> {
    scope: T;
    identifier?: string;
    pathMapper: string | ((state: ReduxState, ctx: RemoteContexts[T]) => string);
    responseMapper?: (resp: AxiosResponse<any>, state: ReduxState, ctx: RemoteContexts[T]) => Opt<{}>;
    method?: MethodType;
    bodyMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {} | undefined;
    source?: CancelTokenSource;
    paramsMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {};
    resMapper: (resp: AxiosResponse, s: ReduxState, ctx: RemoteContexts[T]) => RemoteState[T];
    onSuccess?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onError?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onInit?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    headers?: HeadersType;
    dontIncludeAuthHeader?: boolean;
    responseType?: ResponseType;
}

export interface RequestObject {
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface RefObject {
    id?: number;
}

/**
 *
 */
export type RemoteStatusValues = Readonly<{
    Statuses: Array<{
        Label: string;
        Name: string;
    }>;
}>;

/**
 *
 */
export enum RemoteScope {
    BRAND_RESULTS = "BRAND_RESULTS",
    MODEL_RESULTS = "MODEL_RESULTS",
    BUSINESS_RULE_VIOLATION_RESULTS = "BUSINESS_RULE_VIOLATION_RESULTS",
    BUSINESS_RULE_VIOLATION_RESULTS_PUT = "BUSINESS_RULE_VIOLATION_RESULTS_PUT",
    CLAIM_MATCH_RESULTS = "CLAIM_MATCH_RESULTS",
    INCASSO_RESULTS = "INCASSO_RESULTS",
    EXCASSO_RESULTS = "EXCASSO_RESULTS",
    INCASSO_BOOK_PATCH = "INCASSO_BOOK_PATCH",
    EXCASSO_BOOK_PATCH = "EXCASSO_BOOK_PATCH",
    CLAIM_ERROR_RESULTS = "CLAIM_ERROR_RESULTS",
    CLAIM_RESULTS = "CLAIM_RESULTS",
    CLAIM_DETAILS = "CLAIM_DETAILS",
    CLAIM_MATCH_BOOK_PATCH = "CLAIM_MATCH_BOOK_PATCH",
    STATEMENT_UNBOOK_PATCH = "STATEMENT_UNBOOK_PATCH",
    BOOKED_STATEMENT_RESULTS = "BOOKED_STATEMENT_RESULTS",
    STATEMENT_RESULTS = "STATEMENT_RESULTS",
    STATEMENT_DELETE = "STATEMENT_DELETE",
    STATEMENT_DETAILS = "STATEMENT_DETAILS",
    STATEMENT_LINE_UNBOOK = "STATEMENT_LINE_BOOK",
    GENERAL_LEDGER = "GENERAL_LEDGER",
    GENERAL_LEDGER_CREATE = "GENERAL_LEDGER_CREATE",
    GENERAL_LEDGER_EDIT = "GENERAL_LEDGER_EDIT",
    GENERAL_LEDGER_DETAILS = "GENERAL_LEDGER_DETAILS",
    GENERALLEDGER_BOOK_PATCH = "GENERALLEDGER_BOOK_PATCH",
    COLLECTIVE_INVOICE_RESULTS = "COLLECTIVE_INVOICE_RESULTS",
    COLLECTIVE_INVOICE_BOOK_PATCH = "COLLECTIVE_INVOICE_BOOK_PATCH",
    COLLECTIVE_INVOICE_INCASSO = "COLLECTIVE_INVOICE_INCASSO",
    COLLECTIVE_INVOICE_EXCASSO = "COLLECTIVE_INVOICE_EXCASSO",
    INVOICE_RESULTS = "INVOICE_RESULTS",
    ACCOUNT_CREATE = "ACCOUNT_CREATE",
    ACCOUNT_RESULTS = "ACCOUNT_RESULTS",
    ACCOUNT_DETAILS = "ACCOUNT_DETAILS",
    POLICY_RESULTS = "POLICY_RESULTS",
    POLICY_DETAILS = "POLICY_DETAILS",
    POLICY_CREATE = "POLICY_CREATE",
    POLICY_EDIT = "POLICY_EDIT",
    POLICY_CANCEL_PATCH = "POLICY_CANCEL_PATCH",
    POLICY_PREMIUM_PUT = "POLICY_PREMIUM_PUT",
    PRODUCT_RESULTS = "PRODUCT_RESULTS",
    PRODUCT_DETAILS = "PRODUCT_DETAILS",
    PRODUCT_CREATE = "PRODUCT_CREATE",
    PRODUCT_EDIT = "PRODUCT_EDIT",
    PRODUCT_ACTIVATE_PATCH = "PRODUCT_ACTIVATE_PATCH",
    PRODUCT_DEACTIVATE_PATCH = "PRODUCT_DEACTIVATE_PATCH",
    PRODUCT_TARIFF_ENDINGDATE_PATCH = "PRODUCT_TARIFF_ENDINGDATE_PATCH",
    PRODUCT_ADD_PRODUCT_TARIFF_PATCH = "PRODUCT_ADD_PRODUCT_TARIFF_PATCH",
    PRODUCT_DELETE = "PRODUCT_DELETE",
    PARTNER_RESULTS = "PARTNER_RESULTS",
    PARTNER_RESULTS_CI = "PARTNER_RESULTS_CI",
    PARTNER_DETAILS = "PARTNER_DETAILS",
    PARTNER_CREATE = "PARTNER_CREATE",
    PARTNER_EDIT = "PARTNER_EDIT",
    PARTNER_BOOK_PATCH = "PARTNER_BOOK_PATCH",
    GENERATE_REPORT_ACCOUNTING_POST = "GENERATE_REPORT_ACCOUNTING_POST",
    PROXIES = "PROXIES",
    COLLECTIVE_INVOICE_GENERATE = "COLLECTIVE_INVOICE_GENERATE",
    FILE_INIT_DOWNLOAD = "FILE_INIT_DOWNLOAD",
    FILE_DOWNLOAD = "FILE_DOWNLOAD",
    FILE_UPLOAD = "FILE_UPLOAD",
    FILES = "FILES",
    TARIFF_RESULTS = "TARIFF_RESULTS",
    TARIFF_DETAILS = "TARIFF_DETAILS",
    TARIFF_CREATE = "TARIFF_CREATE",
    TARIFF_EDIT = "TARIFF_EDIT",
    TARIFF_DELETE = "TARIFF_DELETE",
    MANUAL_BOOKING_RESULTS = "MANUAL_BOOKING_RESULTS",
    MANUAL_BOOKING_CREATE = "MANUAL_BOOKING_CREATE",
    BOOKING_PERIOD = "BOOKING_PERIOD",
    BOOKING_PERIOD_OPEN = "BOOKING_PERIOD_OPEN",
    BOOKING_PERIOD_CLOSE = "BOOKING_PERIOD_CLOSE",
    BOOKING_PERIOD_CREATE = "BOOKING_PERIOD_CREATE",
    PAYMENT_RESULTS = "PAYMENT_RESULTS",
    MANDATE_RESULTS = "MANDATE_RESULTS",
    MANDATE_DETAILS = "MANDATE_DETAILS",
    MANDATE_CREATE = "MANDATE_CREATE",
    MANDATE_EDIT = "MANDATE_EDIT",
    MANDATE_DELETE = "MANDATE_DELETE",
    MEMO_RESULTS = "MEMO_RESULTS",
    MEMO_CREATE = "MEMO_CREATE",
    MEMO_EDIT = "MEMO_EDIT",
    MEMO_DELETE = "MEMO_DELETE",
    BRAND_CREATE = "BRAND_CREATE",
    BRAND_EDIT = "BRAND_EDIT",
    BRAND_DETAILS = "BRAND_DETAILS",
    BRAND_DELETE = "BRAND_DELETE",
    MODEL_CREATE = "MODEL_CREATE",
    MODEL_EDIT = "MODEL_EDIT",
    MODEL_DETAILS = "MODEL_DETAILS",
    MODEL_DELETE = "MODEL_DELETE",
    MODEL_PER_BRAND_RESULTS = "MODEL_PER_BRAND_RESULTS",
    VERSION = "VERSION",
    AUDIT_TRAIL_POLICY = "AUDIT_TRAIL_POLICY",
    AUDIT_TRAIL_PRODUCT = "AUDIT_TRAIL_PRODUCT",
    AUDIT_TRAIL_PARTNER= "AUDIT_TRAIL_PARTNER",
    AUDIT_TRAIL_TARIFF = "AUDIT_TRAIL_TARIFF",
    AUDIT_TRAIL_PAYMENT = "AUDIT_TRAIL_PAYMENT",
    AUDIT_TRAIL_GL = "AUDIT_TRAIL_GL",
    AUDIT_TRAIL_BOOKING_PERIOD = "AUDIT_TRAIL_BOOKING_PERIOD",
    AUDIT_TRAIL_CI = "AUDIT_TRAIL_CI",
    AUDIT_TRAIL_INVOICE = "AUDIT_TRAIL_INVOICE",
    AUDIT_TRAIL_STATEMENT = "AUDIT_TRAIl_STATEMENT",
    AUDIT_TRAIL_FREEBOOKING = "AUDIT_TRAIL_FREEBOOKING",
}

/**
 *
 */
export interface RemoteContexts {
    [RemoteScope.BRAND_RESULTS]: {
        skip: number;
    };
    [RemoteScope.BRAND_DETAILS]: {
        id: string;
    };
    [RemoteScope.MODEL_RESULTS]: {
        skip: number;
    };
    [RemoteScope.MODEL_DETAILS]: {
        id: string;
    };
    [RemoteScope.MODEL_PER_BRAND_RESULTS]: {
        brandName: string;
        skip: number;
        onSuccess?: () => void;
    };
    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS]: {
        skip: number;
    };
    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT]: {
        id: number;
        active: boolean;
    };
    [RemoteScope.CLAIM_ERROR_RESULTS]: {
        skip: number;
    };
    [RemoteScope.CLAIM_MATCH_RESULTS]: {
        skip: number;
    };
    [RemoteScope.INCASSO_RESULTS]: {
        skip: number;
    };
    [RemoteScope.EXCASSO_RESULTS]: {
        skip: number;
    };
    [RemoteScope.INCASSO_BOOK_PATCH]: {
        id: string;
        patch: BookIncasso;
    };
    [RemoteScope.EXCASSO_BOOK_PATCH]: {
        id: string;
        patch: BookExcasso;
    };
    [RemoteScope.CLAIM_RESULTS]: {
        skip: number;
    };
    [RemoteScope.CLAIM_DETAILS]: {
        id: string;
    };
    [RemoteScope.CLAIM_MATCH_BOOK_PATCH]: {
        id: string;
        patch: BookClaims;
    };
    [RemoteScope.COLLECTIVE_INVOICE_GENERATE]: {
        selectedRows: InvoiceSelectedRows;
    };
    [RemoteScope.STATEMENT_UNBOOK_PATCH]: {
        statementLineId: string;
        statementId: string;
        patch: UnbookStatementLine;
        modalID: string;
    };
    [RemoteScope.BOOKED_STATEMENT_RESULTS]: {
        id: string;
        skip: number;
    };
    [RemoteScope.STATEMENT_RESULTS]: {
        skip: number;
    };
    [RemoteScope.GENERAL_LEDGER]: {
        skip: number;
    };
    [RemoteScope.STATEMENT_DELETE]: {
        statementId: string;
        modalID: string;
    };
    [RemoteScope.FILE_UPLOAD]: {
        // file: ArrayBuffer[];
        file: string | ArrayBuffer | null;
    };
    [RemoteScope.STATEMENT_DETAILS]: {
        id: string;
    };
    [RemoteScope.STATEMENT_LINE_UNBOOK]: {
        statementId: string;
        statementLineId: string;
        modalID: string;
    };
    [RemoteScope.COLLECTIVE_INVOICE_RESULTS]: {
        skip: number;
    };
    [RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH]: {
        id: string;
        patch: BookInvoice;
    };
    [RemoteScope.COLLECTIVE_INVOICE_INCASSO]: {
        selectedRows: InExInfo;
    };
    [RemoteScope.COLLECTIVE_INVOICE_EXCASSO]: {
        selectedRows: ExcassoInfo;
        forPartnerInvoices?: boolean;
    };
    [RemoteScope.INVOICE_RESULTS]: {
        skip: number;
    };
    [RemoteScope.ACCOUNT_RESULTS]: {
        skip: number;
        inexformscope?: InExFormScope;
    };
    [RemoteScope.ACCOUNT_DETAILS]: {
        id: string;
    };
    [RemoteScope.ACCOUNT_CREATE]: {
        account: AccountRequest;
    };
    [RemoteScope.POLICY_RESULTS]: {
        skip: number;
    };
    [RemoteScope.POLICY_DETAILS]: {
        id: string;
    };
    [RemoteScope.PRODUCT_CREATE]: {
        product: ProductRequest;
    };
    [RemoteScope.PRODUCT_RESULTS]: {
        skip: number;
    };
    [RemoteScope.PRODUCT_DETAILS]: {
        id: string;
    };
    [RemoteScope.PRODUCT_EDIT]: {
        product: ProductRequest;
    };
    [RemoteScope.POLICY_CREATE]: {
        policy: PolicyRequest;
    };
    [RemoteScope.POLICY_EDIT]: {
        policy: PolicyRequest;
    };
    [RemoteScope.POLICY_PREMIUM_PUT]: {
        policyPremium: PremiumPolicy;
        id: string;
        policyId: string;
    };
    [RemoteScope.POLICY_CANCEL_PATCH]: {
        id: string;
        modalID: string;
    };
    [RemoteScope.PRODUCT_ACTIVATE_PATCH]: {
        id: string;
        modalID: string;
    };
    [RemoteScope.PRODUCT_DEACTIVATE_PATCH]: {
        id: string;
        modalID: string;
    };
    [RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH]: {
        productId: string;
        productTariffId: string;
        modalID: string;
        endingDate: string;
    };
    [RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH]: {
        productId: string;
        productTariff: ProductTariffRequests;
    };
    [RemoteScope.PRODUCT_DELETE]: {
        id: string;
        modalID: string;
    };
    [RemoteScope.PARTNER_RESULTS]: {
        skip: number;
    };
    [RemoteScope.PARTNER_RESULTS_CI]: {
        skip: number;
    };
    [RemoteScope.PARTNER_DETAILS]: {
        id: string;
    };
    [RemoteScope.PARTNER_CREATE]: {
        partner: PartnerRequest;
    };
    [RemoteScope.PARTNER_EDIT]: {
        partner: PartnerRequest;
    };
    [RemoteScope.PARTNER_BOOK_PATCH]: {
        id: string;
        patch: BookPartner;
    };
    [RemoteScope.GENERALLEDGER_BOOK_PATCH]: {
        id: string;
        patch: BookLedger;
    };
    [RemoteScope.GENERATE_REPORT_ACCOUNTING_POST]: {
        fields: ReportForm;
    };
    [RemoteScope.PROXIES]: {
        forReports?: boolean;
        forProducts?: boolean;
    };
    [RemoteScope.FILE_INIT_DOWNLOAD]: {
        id: number;
    };
    [RemoteScope.FILE_DOWNLOAD]: undefined;
    [RemoteScope.FILES]: {
        skip: number;
    };
    [RemoteScope.TARIFF_RESULTS]: {
        skip: number;
    };
    [RemoteScope.TARIFF_DETAILS]: {
        id: number;
        modalID: string;
    };
    [RemoteScope.TARIFF_CREATE]: {
        tariff: TariffRequest;
    };
    [RemoteScope.TARIFF_EDIT]: {
        tariff: TariffRequest;
    };
    [RemoteScope.TARIFF_DELETE]: {
        tariffId: string;
        modalID: string;
    };
    [RemoteScope.MANUAL_BOOKING_RESULTS]: {
        skip: number;
    };
    [RemoteScope.MANUAL_BOOKING_CREATE]: {
        manualBooking: ManualBookings;
    };
    [RemoteScope.BOOKING_PERIOD]: {
        skip: number;
    };
    [RemoteScope.BOOKING_PERIOD_OPEN]: {
        id: number;
    };
    [RemoteScope.BOOKING_PERIOD_CLOSE]: {
        id: number;
    };
    [RemoteScope.BOOKING_PERIOD_CREATE]: {};
    [RemoteScope.PAYMENT_RESULTS]: {
        skip: number;
    };
    [RemoteScope.GENERAL_LEDGER_CREATE]: {
        ledger: GLRequest;
    };
    [RemoteScope.GENERAL_LEDGER_EDIT]: {
        ledger: GLRequest;
    };
    [RemoteScope.GENERAL_LEDGER_DETAILS]: {
        id: string;
    };
    [RemoteScope.MANDATE_RESULTS]: {
        skip: number;
    };
    [RemoteScope.MANDATE_DETAILS]: {
        id: string;
    };
    [RemoteScope.MANDATE_CREATE]: {
        mandate: MandateRequest;
    };
    [RemoteScope.MANDATE_EDIT]: {
        mandate: MandateRequest;
        modalID: string;
    };
    [RemoteScope.MANDATE_DELETE]: {
        id: string;
        modalID: string;
    };
    [RemoteScope.MEMO_RESULTS]: {
        skip: number;
    };
    [RemoteScope.MEMO_CREATE]: {
        memo: MemoRequest;
        policyId: string;
    };
    [RemoteScope.MEMO_EDIT]: {
        memo: MemoRequest;
        modalID: string;
    };
    [RemoteScope.MEMO_DELETE]: {
        id: string;
        modalID: string;
    };
    [RemoteScope.BRAND_CREATE]: {
        brand: BrandRequest;
    };
    [RemoteScope.BRAND_EDIT]: {
        brand: BrandRequest;
    };
    [RemoteScope.BRAND_DELETE]: {
        brandId: string;
        modalID: string;
    };
    [RemoteScope.MODEL_CREATE]: {
        model: ModelRequest;
    };
    [RemoteScope.MODEL_EDIT]: {
        model: ModelRequest;
    };
    [RemoteScope.MODEL_DELETE]: {
        modelId: string;
        modalID: string;
    };
    [RemoteScope.AUDIT_TRAIL_POLICY]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_PRODUCT]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_PARTNER]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_TARIFF]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_PAYMENT]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_GL]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_CI]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_INVOICE]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_STATEMENT]: {
        id: string;
        skip: number;
    };
    [RemoteScope.AUDIT_TRAIL_FREEBOOKING]: {
        id: string;
        skip: number;
    };
    [RemoteScope.VERSION]: undefined;
}

/**
 *
 */
export enum ApiGroupType {
    ONE = "group1",
    TWO = "group2",
    THREE = "group3",
    NONE = "",
}

/**
 *
 */
export const ApiGroup: DisplayOf<RemoteScope> = {
    //     GROUP 1
    // ======================
    // /statements (x)
    // /accounts (x)
    // /claims (x)
    // /brands (- not here yet)
    // /extendedwarranties (- not here yet)
    // /policies (x)
    // /files (x)
    // /version (x)
    [RemoteScope.STATEMENT_LINE_UNBOOK]: ApiGroupType.ONE,
    [RemoteScope.STATEMENT_UNBOOK_PATCH]: ApiGroupType.ONE,
    [RemoteScope.BOOKED_STATEMENT_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.STATEMENT_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.STATEMENT_DELETE]: ApiGroupType.ONE,
    [RemoteScope.STATEMENT_DETAILS]: ApiGroupType.ONE,
    [RemoteScope.AUDIT_TRAIL_STATEMENT]: ApiGroupType.ONE,
    [RemoteScope.BRAND_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.BRAND_DETAILS]: ApiGroupType.ONE,
    [RemoteScope.BRAND_DELETE]: ApiGroupType.ONE,
    [RemoteScope.BRAND_CREATE]: ApiGroupType.ONE,
    [RemoteScope.BRAND_EDIT]: ApiGroupType.ONE,
    [RemoteScope.MODEL_PER_BRAND_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.FILE_INIT_DOWNLOAD]: ApiGroupType.ONE,
    [RemoteScope.FILE_UPLOAD]: ApiGroupType.ONE,
    [RemoteScope.FILES]: ApiGroupType.ONE,
    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT]: ApiGroupType.ONE,
    [RemoteScope.POLICY_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.POLICY_DETAILS]: ApiGroupType.ONE,
    [RemoteScope.POLICY_CREATE]: ApiGroupType.ONE,
    [RemoteScope.POLICY_EDIT]: ApiGroupType.ONE,
    [RemoteScope.POLICY_CANCEL_PATCH]: ApiGroupType.ONE,
    [RemoteScope.POLICY_PREMIUM_PUT]: ApiGroupType.ONE,
    [RemoteScope.AUDIT_TRAIL_POLICY]: ApiGroupType.ONE,
    [RemoteScope.MEMO_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.MEMO_CREATE]: ApiGroupType.ONE,
    [RemoteScope.MEMO_EDIT]: ApiGroupType.ONE,
    [RemoteScope.MEMO_DELETE]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_CREATE]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_EDIT]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_DETAILS]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_ACTIVATE_PATCH]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_DEACTIVATE_PATCH]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH]: ApiGroupType.ONE,
    [RemoteScope.PRODUCT_DELETE]: ApiGroupType.ONE,
    [RemoteScope.AUDIT_TRAIL_PRODUCT]: ApiGroupType.ONE,
    [RemoteScope.CLAIM_DETAILS]: ApiGroupType.ONE,
    [RemoteScope.CLAIM_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.CLAIM_MATCH_BOOK_PATCH]: ApiGroupType.ONE,
    [RemoteScope.CLAIM_ERROR_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.CLAIM_MATCH_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.ACCOUNT_RESULTS]: ApiGroupType.ONE,
    [RemoteScope.ACCOUNT_DETAILS]: ApiGroupType.ONE,
    [RemoteScope.ACCOUNT_CREATE]: ApiGroupType.ONE,
    [RemoteScope.VERSION]: ApiGroupType.ONE,

    // Group 2
    // ======================
    // /partners (x)
    // /models (- not here yet)
    // /collectiveinvoices (x)
    // /generalledgers (x)
    // /incasso (x)
    [RemoteScope.COLLECTIVE_INVOICE_RESULTS]: ApiGroupType.TWO,
    [RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH]: ApiGroupType.TWO,
    [RemoteScope.COLLECTIVE_INVOICE_INCASSO]: ApiGroupType.TWO,
    [RemoteScope.COLLECTIVE_INVOICE_EXCASSO]: ApiGroupType.TWO,
    [RemoteScope.COLLECTIVE_INVOICE_GENERATE]: ApiGroupType.TWO,
    [RemoteScope.AUDIT_TRAIL_CI]: ApiGroupType.TWO,
    [RemoteScope.INCASSO_RESULTS]: ApiGroupType.TWO,
    [RemoteScope.INCASSO_BOOK_PATCH]: ApiGroupType.TWO,
    [RemoteScope.GENERAL_LEDGER_CREATE]: ApiGroupType.TWO,
    [RemoteScope.GENERAL_LEDGER_EDIT]: ApiGroupType.TWO,
    [RemoteScope.GENERALLEDGER_BOOK_PATCH]: ApiGroupType.TWO,
    [RemoteScope.GENERAL_LEDGER_DETAILS]: ApiGroupType.TWO,
    [RemoteScope.GENERAL_LEDGER]: ApiGroupType.TWO,
    [RemoteScope.AUDIT_TRAIL_GL]: ApiGroupType.TWO,
    [RemoteScope.PARTNER_RESULTS]: ApiGroupType.TWO,
    [RemoteScope.PARTNER_RESULTS_CI]: ApiGroupType.TWO,
    [RemoteScope.PARTNER_DETAILS]: ApiGroupType.TWO,
    [RemoteScope.PARTNER_CREATE]: ApiGroupType.TWO,
    [RemoteScope.PARTNER_EDIT]: ApiGroupType.TWO,
    [RemoteScope.PARTNER_BOOK_PATCH]: ApiGroupType.TWO,
    [RemoteScope.AUDIT_TRAIL_PARTNER]: ApiGroupType.TWO,
    [RemoteScope.MODEL_RESULTS]: ApiGroupType.TWO,
    [RemoteScope.MODEL_DETAILS]: ApiGroupType.TWO,
    [RemoteScope.MODEL_CREATE]: ApiGroupType.TWO,
    [RemoteScope.MODEL_EDIT]: ApiGroupType.TWO,
    [RemoteScope.MODEL_DELETE]: ApiGroupType.TWO,

    // GROUP 3
    // ======================
    // /mandates (not needed)
    // /proxies (x)
    // /invoices (- not here yet)
    // /exact (not needed)
    // /journaleventsentries (- not here yet)
    // /journalevents (- not here yet)
    [RemoteScope.PROXIES]: ApiGroupType.THREE,
    [RemoteScope.INVOICE_RESULTS]: ApiGroupType.THREE,
    [RemoteScope.AUDIT_TRAIL_INVOICE]: ApiGroupType.THREE,
    [RemoteScope.EXCASSO_RESULTS]: ApiGroupType.THREE,
    [RemoteScope.EXCASSO_BOOK_PATCH]: ApiGroupType.THREE,
    [RemoteScope.TARIFF_RESULTS]: ApiGroupType.THREE,
    [RemoteScope.AUDIT_TRAIL_TARIFF]: ApiGroupType.THREE,
    [RemoteScope.TARIFF_DETAILS]: ApiGroupType.THREE,
    [RemoteScope.TARIFF_CREATE]: ApiGroupType.THREE,
    [RemoteScope.TARIFF_EDIT]: ApiGroupType.THREE,
    [RemoteScope.TARIFF_DELETE]: ApiGroupType.THREE,

    // NOT AVAILABLE YET
    [RemoteScope.GENERATE_REPORT_ACCOUNTING_POST]: ApiGroupType.THREE,
    [RemoteScope.AUDIT_TRAIL_FREEBOOKING]: ApiGroupType.THREE,
    [RemoteScope.MANUAL_BOOKING_RESULTS]: ApiGroupType.THREE,
    [RemoteScope.MANUAL_BOOKING_CREATE]: ApiGroupType.THREE,
    [RemoteScope.BOOKING_PERIOD]: ApiGroupType.THREE,
    [RemoteScope.BOOKING_PERIOD_OPEN]: ApiGroupType.THREE,
    [RemoteScope.BOOKING_PERIOD_CLOSE]: ApiGroupType.THREE,
    [RemoteScope.BOOKING_PERIOD_CREATE]: ApiGroupType.THREE,
    [RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD]: ApiGroupType.THREE,
    [RemoteScope.PAYMENT_RESULTS]: ApiGroupType.THREE,
    [RemoteScope.AUDIT_TRAIL_PAYMENT]: ApiGroupType.THREE,
    [RemoteScope.MANDATE_RESULTS]: ApiGroupType.THREE,
    [RemoteScope.MANDATE_DETAILS]: ApiGroupType.THREE,
    [RemoteScope.MANDATE_CREATE]: ApiGroupType.THREE,
    [RemoteScope.MANDATE_EDIT]: ApiGroupType.THREE,
    [RemoteScope.MANDATE_DELETE]: ApiGroupType.THREE,

    // None
    [RemoteScope.FILE_DOWNLOAD]: ApiGroupType.NONE,
};

export const getApiGroup = (scope: RemoteScope) => ApiGroup[scope];

/**
 * FIXME user auth headers should be included as part of the state's cached
 * AxiosInstance.
 */
export const buildRequest =
    async <T extends RemoteScope>(
        state: ReduxState,
        trigger: RemoteConfig<T>,
        ctx: RemoteContexts[T],
    ): Promise<AxiosRequestConfig> => {
        let headers: HeadersType = {
            // 'defaultHeader': 'test'
        };

        if (trigger.headers) {
            headers = { ...headers, ...trigger.headers };
        }

        if (trigger.dontIncludeAuthHeader !== true) {
            const getToken = state.mapProp("user", u => u.map(info => info.token).getOpt());
            const token = !getToken ? "" : await getToken();
            headers = { ...headers, Authorization: `Bearer ${token ?? ""}` };
        }

        return {
            method: trigger.method || "GET",
            url: `${getApiGroup(trigger.scope)}${typeof trigger.pathMapper === "string"
                ? trigger.pathMapper : trigger.pathMapper(state, ctx)}`,
            // headers: state.mapProp("mary", u => u.map(authHeader).getOpt()),
            headers,
            responseType: trigger.responseType,
            params: !trigger.paramsMapper ? undefined : trigger.paramsMapper(state, ctx),
            data: !trigger.bodyMapper ? undefined : trigger.bodyMapper(state, ctx),
        };
    };
