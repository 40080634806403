/* eslint-disable max-len */
import React from "react";
import { ReduxState, isRemoteLoading } from "../../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { connect } from "react-redux";
import { Header } from "../../../../../../../components/core/09-views/01-sections/Header";
import { SubPageDisplay, SubPages, PageTabs } from "../../../../../../../store/AppDisplays";
import { Page } from "../../../../../../../components/core/09-views/01-sections/Page";
import { DispatchFunc } from "../../../../../../../store/ActionTypes";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../../../store/RemoteActions";
import { RouteComponentProps } from "react-router-dom";
import { StatementDetails, CreditDebitIndicator,
    StatementLineStatus } from "../../../../../../../store/Statements/Types";
import { OrderedSet } from "immutable";
import { BookingPeriods } from "../../../../../../../store/BookingPeriod/Types";
import { withinClosedBookingPeriod } from "../../../../../../utils/Booking";
import { UserInfo, UserRoles } from "../../../../../../../store/AppTypes";
import { Container } from "../../../../../../../components/core/03-base/Container";
import { LoadingData } from "../../../../../../../components/core/09-views/00-blocks/LoadingData";
import { Blockquote } from "../../../../../../../components/core/05-atoms/Blockquote";
import { Tabs } from "../../../../00-blocks/Tabs";

interface Params {
    accountId: string;
    statementId: string;
    statementLineId: string;
}

/**
 *
 */
interface StateProps {
    params: Params;
    details: StatementDetails;
    loading: boolean;
    bookingPeriodResults: BookingPeriods;
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadDetails: (id: string) => void;
    clearDetails: () => void;
    loadBookingPeriods: () => void;
    clearBookingPeriods: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    loading: isRemoteLoading(s, RemoteScope.STATEMENT_DETAILS) || isRemoteLoading(s, RemoteScope.BOOKING_PERIOD),
    details: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementDetails,
    bookingPeriodResults: s.prop("remote").prop(RemoteScope.BOOKING_PERIOD) as BookingPeriods,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadDetails:
            (id: string) =>
                dispatch(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                    id: id,
                })),
    clearDetails: () => (
        dispatch(remoteClearResponse(RemoteScope.STATEMENT_DETAILS)),
        dispatch(remoteClearError(RemoteScope.STATEMENT_DETAILS))
    ),
    loadBookingPeriods: () => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD, { skip: 0 })),
    clearBookingPeriods: () => (
        dispatch(remoteClearResponse(RemoteScope.BOOKING_PERIOD)),
        dispatch(remoteClearError(RemoteScope.BOOKING_PERIOD))
    ),
});

/**
 *
 */
export class BookComp
    extends React.Component<Props, {}> {

    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadDetails(this.props.params.statementId);
        this.props.loadBookingPeriods();
    }

    public componentWillUnmount() {
        this.props.clearDetails();
        this.props.clearBookingPeriods();
    }
    /**
     *
     */
    public render() {

        const statementLine = this.props.details
            && this.props.details.content.entries
            && this.props.details.content.entries.length > 0 ?
            this.props.details.content.entries.find(line =>
                line.id.toString() === this.props.params.statementLineId) : undefined;

        const getTabs = () => {
            if (statementLine) {
                return SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK].tabs.filter(tab => {
                    if (statementLine.creditDebitIndicator === CreditDebitIndicator.CRDT) {
                        return (tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER);
                    } else {
                        return (tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER ||
                            tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES);
                    }
                });
            } else {
                return OrderedSet([]);
            }
        };

        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL].title}
                        goBack={`/accounts/${this.props.params.accountId}/${this.props.params.statementId}`}
                    />
                    {
                        this.props.loading || (!this.props.details) ?
                            (
                                <LoadingData loading={!!this.props.loading} />
                            ) :
                            (
                                <>
                                    {this.props.user?.userRole !== UserRoles.EW_DEBTOR
                                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                                        withinClosedBookingPeriod(
                                            this.props.bookingPeriodResults,
                                            this.props.details,
                                            this.props.loading,
                                        ) &&
                                        <Blockquote>
                                            <h5>Pay attention</h5>
                                            <div className="scl-h-text">
                                                There is no open booking period for this statement
                                            </div>
                                        </Blockquote>
                                        : <></>}
                                    <Tabs
                                        tabs={SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK]
                                            .tabs.filter(tab => (
                                                tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_SUMMARY ||
                                                tab === PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOKED_ITEMS
                                            ))}
                                        defaultTab={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_SUMMARY}
                                        params={{
                                            statementLine: statementLine,
                                            statementId: this.props.params.statementId,
                                            statementLineId: this.props.params.statementLineId,
                                        }}
                                    />
                                    {statementLine &&
                                        (statementLine.statementLineStatus === StatementLineStatus.MATCHED
                                            && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                                            && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                                            (<Blockquote>
                                                <h4>This statement line has been successfully booked!</h4><br />
                                                <div className="scl-h-text--big">
                                                    Go back if you&apos;re done with booking the statement line,<br />
                                            Or else unbook the individual objects under the &quot;Booked Items&quot; tab.
                                                </div>
                                            </Blockquote>) :
                                            this.props.user?.userRole !== UserRoles.EW_DEBTOR
                                            && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                                                (<Tabs
                                                    tabs={getTabs()}
                                                    defaultTab={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER}
                                                    params={{
                                                        creditDebitIndicator: statementLine &&
                                                            statementLine.creditDebitIndicator === CreditDebitIndicator.CRDT ?
                                                            CreditDebitIndicator.DBIT : CreditDebitIndicator.CRDT,
                                                        statementLineId: this.props.params.statementLineId,
                                                        statementId: this.props.params.statementId,
                                                        accountId: this.props.params.accountId,
                                                    }}
                                                />
                                                ) : <></>)
                                    }
                                </>
                            )}
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Book = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <BookComp {...props}/>);
