/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";

import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { getClaimInvoiceStatusColor } from "../../../../utils/FormatHelpers";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { Invoices } from "../../../../../store/Invoice/Types";
import { PageTabs } from "../../../../../store/AppDisplays";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { FileDownload } from "../../../../../store/Files/Types";
import Moment from "react-moment";
import { DownloadFileProps } from "../Files/Results";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { ClaimInvoiceStatus } from "../../../../..//store/ClaimMatch/Types";
import { sumOfSelectedRows } from "../CollectiveInvoice/Results";
import { initUpdateInExForm } from "../../../../../store/CollectiveInvoice/GenerateIncasso";
import { InExFormScope } from "../../../../../store/CollectiveInvoice/Types";
import { ConfirmInExModal } from "../CollectiveInvoice/ConfirmInExModal";
import { NavLink } from "react-router-dom";
import { InvoiceTypeDisplay } from "../../../../../store/Invoice/Displays";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes, ThemeSize } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { GenerateBar } from "../../../07-organisms/GenerateBar";
import { RowData, Table, TableData } from "../../../../../components/core/06-molecules/Table";
import { Container } from "../../../../../components/core/03-base/Container";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { DownloadButtonCell } from "../../../06-molecules/DownloadButtonCell";
import { canDownloadFile } from "../../../../utils/FileDownload";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

export interface SelectedRows {
    id: string;
    balance: number;
    invoiceNumber?: number;
}

interface State {
    selectedRows: SelectedRows[];
}

/**
 *
 */
interface StateProps extends DownloadFileProps {
    user?: UserInfo;
}

/**
 *
 */
interface OwnProps {
    results: Invoices;
    currentTab?: PageTabs;
    asAuditTab?: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    loadDownload: (id: number) => void;
    openExcassoRequest: (selectedRows: SelectedRows[]) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

const OpenExcassoReq =
    (selectedRows: SelectedRows[]) => (
        () => (
            <Modal
                modalID={"excasso"}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmInExModal
                    modalID={"excasso"}
                    selectedRows={selectedRows}
                    forPartnerInvoices
                    scope={InExFormScope.EXCASSO}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFileInitDownload: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    loadingFileDownload: isRemoteLoading(s, RemoteScope.FILE_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.INVOICE_RESULTS, {
        skip: skip,
    })),
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, { id: id })),
    openExcassoRequest: (selectedRows: SelectedRows[]) => {
        dispatch(
            modalShow(
                "excasso",
                OpenExcassoReq(selectedRows),
            ),
        );
        dispatch(initUpdateInExForm());
    },
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.loadDownload = this.loadDownload.bind(this);
        this.amIChecked = this.amIChecked.bind(this);
        this.openExcasso = this.openExcasso.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                {this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE
                    && this.props.asAuditTab !== true
                    && this.props.currentTab !== PageTabs.PARTNER_INVOICE
                    && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && (
                    <GenerateBar
                        title={"Select Credit Individual invoices"}
                        selectedBalance={sumOfSelectedRows(this.state.selectedRows)}
                        isValid={this.state.selectedRows.length > 0 ? true : undefined}
                        onClick={this.openExcasso()}
                        currentTab={this.props.currentTab}
                    />
                )
                }
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const isLoading = ((this.props.fileDownloadResult && this.props.fileDownloadResult.id === row.messageId)
                && this.props.loadingFileDownload);
            return ({
                id: row.id,
                data: [
                    ...(this.props.currentTab !== PageTabs.PARTNER_INVOICE
                        && this.props.asAuditTab !== true
                        && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                        && this.props.user?.userRole !== UserRoles.EW_ADMIN
                        && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ? [(
                            row.status === ClaimInvoiceStatus.UNPAID
                            && row.creditDebitIndicator !== CreditDebitIndicator.DBIT ? (
                                    <Checkbox
                                        checked={this.amIChecked(row.id.toString())}
                                        onChange={(value?: boolean) => this.selectItem(value,
                                            {
                                                id: row.id.toString(), balance: row.amount.amount,
                                                invoiceNumber: row.invoiceNumber,
                                            })}
                                    />
                                )
                                : ""
                        )] : []),
                    row.invoiceNumber,
                    row.invoiceDate
                        ? <Moment date={new Date(row.invoiceDate)} format={"DD-MM-YYYY"} key={`invoicedate${index}`} />
                        : "",
                    row.collectiveInvoiceNumber,
                    row.mutationDate
                        ? <Moment date={new Date(row.mutationDate)} format={"DD-MM-YYYY"} key={`mutationdate${index}`}/>
                        : "",
                    (
                        <Badge key={`badge-${row.id}`} theme={{ paletteState: getClaimInvoiceStatusColor(row.status) }}>
                            {row.status}
                        </Badge>
                    ),
                    this.props.currentTab === PageTabs.PARTNER_INVOICE ? InvoiceTypeDisplay[row.objectType] : "",
                    row.creditDebitIndicator === CreditDebitIndicator.DBIT ? "Debit" : "Credit",
                    (
                        <h6 key={`h6-${row.id}`}>
                            <Currency amount={row.amount.amount} />
                        </h6>
                    ),
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >{
                            this.props.asAuditTab ? (
                                <NavLink to={`/audit/individualinvoices/${row.id}`}>
                                    <Button
                                        icon={IconNames.ARROW_RIGHT}
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                            ) :
                                row.messageId ? (
                                    <DownloadButtonCell
                                        isLoading={isLoading}
                                        loadDownload={() => this.props.loadDownload(row.messageId)}
                                        canDownloadFile={canDownloadFile(this.props)}
                                    />
                                ) : ""}</ButtonsWrapper>,
                ],
            });
        });
    }

    private openExcasso() {
        return () => this.props.openExcassoRequest(this.state.selectedRows);
    }

    private loadDownload(id: number) {
        return () => this.props.loadDownload(id);
    }

    private selectItem(value: boolean | undefined, selectedRows: SelectedRows) {
        this.setState({
            selectedRows: !!value ? [...this.state.selectedRows, selectedRows]
                : this.state.selectedRows.filter((row) => row.id !== selectedRows.id,
                ),
        });
    }

    private amIChecked(id: string): boolean {
        return this.state.selectedRows.some((row) => row.id === id);
    }

    private refreshedRowState(): State {
        return {
            selectedRows: [],
        };
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                ...(this.props.currentTab !== PageTabs.PARTNER_INVOICE
                    && this.props.asAuditTab !== true
                    && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ? [{
                        label: "Select",
                    }] : []),
                {
                    label: "Number",
                },
                {
                    label: "Date",
                },
                {
                    label: "Coll. Inv. number",
                },
                {
                    label: "Mutation date",
                },
                {
                    label: "Status",
                },
                this.props.currentTab === PageTabs.PARTNER_INVOICE ?
                    {
                        label: "Invoice type",
                    } : {
                        label: "",
                    },
                {
                    label: "Mutation type",
                },
                {
                    label: "Amount",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
