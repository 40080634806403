
import React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { PageDisplay, Pages } from "../../../../store/AppDisplays";
import { Invoices, InvoiceType } from "../../../../store/Invoice/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: Invoices;
}

type Props = StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.INVOICE_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.INVOICE_RESULTS) as Invoices,
});

/**
 *
 * @param props
 */
export class IndividualInvoicesComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {};
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.INDIVIDUALINVOICES].title}
                                description={PageDisplay[Pages.INDIVIDUALINVOICES].description}
                            />
                        </Grid>
                    </Div>
                    <Div
                        theme={{
                            padding: {
                                "": { b: 3 },
                                "sm": { b: 4 },
                            },
                        }}
                    >
                        <Filter
                            loadingFilters={this.props.loadingFilters}
                            loadingResults={this.props.loadingResults}
                            currentPage={Pages.INDIVIDUALINVOICES}
                            defaultFilters={{ invoiceType: InvoiceType.PartnerInvoice }}
                        />
                        <Results
                            results={this.props.results}
                            loadingResults={this.props.loadingResults}
                            currentPage={Pages.INDIVIDUALINVOICES}
                            scope={RemoteScope.INVOICE_RESULTS}
                        />
                    </Div>
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const IndividualInvoices = connect(
    mapStateToProps,
    {},
)(IndividualInvoicesComp);
