/* eslint-disable max-len */

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { createAccount, clearAccount } from "../../../../../store/Accounts/Create";
import { AccountRequest, AccountDetails, canSubmitAccountForm, AccountRequest as CreateAccountType } from "../../../../../store/Accounts/Types";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../../store/AppDisplays";
import { GeneralLedgers } from "../../../../../store/GeneralLedgers/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeSize, ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { SelectAccountGeneralLedgerModal } from "../../00-blocks/GeneralLedger/SelectAccountGeneralLedgerModal";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

type OnChange =
    <K extends keyof AccountRequest>(key: K, value: AccountRequest[K]) => void;

interface StateProps {
    account: CreateAccountType;
    resultsSuccess: AccountDetails;
    canSubmitAccountForm: boolean;
    loadingFormHelpers: boolean;
    formHelpers: {
        generalLedgerResults?: GeneralLedgers;
    };
}

interface DispatchProps {
    clear: () => void;
    loadFormHelpers: () => void;
    createAccount: (account: CreateAccountType) => void;
    onChange: (account: CreateAccountType) => void;
    selectGeneralLedger: (modalId: string, onChange: OnChange, id?: string)
    => void;
}

type Props = DispatchProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    canSubmitAccountForm: canSubmitAccountForm(s.prop("accountRequest")),
    account: s.prop("accountRequest").map(e => ({
        ...e,
    })),
    loadingFormHelpers: isRemoteLoading(s, RemoteScope.GENERAL_LEDGER),
    formHelpers: {
        generalLedgerResults: s.prop("remote").prop(RemoteScope.GENERAL_LEDGER),
    },
    resultsSuccess: s.prop("remote").prop(RemoteScope.ACCOUNT_CREATE) as AccountDetails,
});

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    createAccount: (account: CreateAccountType) => dispatch(remoteTrigger(RemoteScope.ACCOUNT_CREATE, {
        account: account,
    })),
    onChange: (account: CreateAccountType) => dispatch(createAccount(account)),
    clear: () => {
        dispatch(clearAccount());
        dispatch(remoteClearResponse(RemoteScope.GENERAL_LEDGER));
        dispatch(remoteClearError(RemoteScope.GENERAL_LEDGER));
        dispatch(remoteClearResponse(RemoteScope.ACCOUNT_CREATE));
        dispatch(remoteClearError(RemoteScope.ACCOUNT_CREATE));
    },
    loadFormHelpers: () => {
        dispatch(remoteTrigger(RemoteScope.GENERAL_LEDGER, { skip: 0 }));
    },
    selectGeneralLedger:
            (modalId: string, onChange: OnChange, id?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.NORMAL }}
                            >
                                <SelectAccountGeneralLedgerModal
                                    modalID={modalId}
                                    generalLedgerNumber={id}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
});

/**
 *
 */
export class CreateAccountComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.createAccount = this.createAccount.bind(this);
    }

    public componentDidMount() {
        this.props.clear();
        this.props.loadFormHelpers();
    }

    public componentWillUnmount() {
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                        margin: {
                            "": { b: 4 },
                            "sm": { b: 5 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.ACCOUNTS_CREATE].title}
                        goBack={PageDisplay[Pages.ACCOUNTS].path}
                    />
                    {this.props.resultsSuccess && (
                        <Redirect to={"/accounts"} />
                    )}
                    <Form onSubmit={this.createAccount}>
                        <Div
                            theme={{
                                shadow: ThemeShadowSizes.TINY,
                                margin: {
                                    "": { t: 2 },
                                    "sm": { t: 3 },
                                },
                            }}
                        >
                            <Container
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    padding: {
                                        "": { y: 3 },
                                        "sm": { y: 4 },
                                    },
                                }}
                            >
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormFields.FormField
                                            onChange={(value?: string | number) =>
                                                this.onChange("name", value ? value.toString() : undefined)}
                                            value={this.props.account.name}
                                            label="Name"
                                            required
                                            validationFunction={
                                                (value: string | number) =>
                                                    hasValueValidation(value,
                                                        "Name provided")}
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormFields.FormField
                                            onChange={(value?: string | number) =>
                                                this.onChange("iban", value ? value.toString() : undefined)}
                                            value={this.props.account.iban}
                                            label="IBAN"
                                            required
                                            validationFunction={
                                                (value: string | number) =>
                                                    hasValueValidation(value,
                                                        "IBAN provided")}
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormFields.FormField
                                            onChange={(value?: string | number) =>
                                                this.onChange("bic", value ? value.toString() : undefined)}
                                            value={this.props.account.bic}
                                            label="Bic"
                                            required
                                            validationFunction={
                                                (value: string | number) =>
                                                    hasValueValidation(value,
                                                        "Bic provided")}
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormFields.FormField
                                            onChange={(value?: string | number) =>
                                                this.onChange("privateIdentification", value
                                                    ? value.toString() : undefined)}
                                            value={this.props.account.privateIdentification}
                                            label="Private Identification"
                                            required
                                            validationFunction={
                                                (value: string | number) =>
                                                    hasValueValidation(value,
                                                        "Private Identification provided")}
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormFields.FormField
                                            onChange={(value?: string | number) =>
                                                this.onChange("accountOwner", value ? value.toString() : undefined)}
                                            value={this.props.account.accountOwner}
                                            label="Account owner"
                                            required
                                            validationFunction={
                                                (value: string | number) =>
                                                    hasValueValidation(value,
                                                        "Account owner provided")}
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormFields.FormField
                                            type="currency"
                                            onChange={(value?: string | number) =>
                                                this.onChange("balance", value ? Number(value) : undefined)}
                                            value={this.props.account.balance}
                                            label="Balance"
                                            validationFunction={
                                                (value: string | number) =>
                                                    hasValueValidation(value,
                                                        "Balance provided")}
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                            }}
                                        >
                                            <FormFields.FormField
                                                label="General Ledger"
                                                required
                                                placeholder={"Select a general ledger"}
                                                readonly
                                                validationFunction={
                                                    (value: string | number) =>
                                                        hasValueValidation(value.toString(),
                                                            "General ledger selected")}
                                                value={this.props.account.generalLedger?.id}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                }}
                                            >
                                                <Button
                                                    icon={
                                                        this.props.account.generalLedger
                                                            ? IconNames.EDIT : IconNames.PLUS}
                                                    theme={{
                                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                                        shadow: ThemeShadowSizes.TINY,
                                                    }}
                                                    link={{
                                                        onClick: () =>
                                                            this.props.selectGeneralLedger(
                                                                "select-gl--modal",
                                                                this.onChange,
                                                                this.props.account.generalLedger?.id?.toString(),
                                                            ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Div>
                                <Required />
                            </Container>
                        </Div>
                        <FormBar
                            buttonLabel={"Create"}
                            title={"Create account"}
                            isValid={!!this.props.canSubmitAccountForm ? this.props.canSubmitAccountForm : undefined}
                        />
                    </Form>
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof CreateAccountType>(key: K, value: CreateAccountType[K]) => {
        this.props.onChange({
            [key]: value,
        });
    };

    private createAccount() {
        this.props.createAccount(this.props.account);
    }
}

/**
 *
 */
export const CreateAccount = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateAccountComp);
