import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { GeneralLedgers } from "../../../../../store/GeneralLedgers/Types";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";

/**
 *
 */
interface OwnProps {
    results: GeneralLedgers;
    asAuditTab?: boolean;
}

/**
 *
 */
interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.GENERAL_LEDGER, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, _index) => ({
            id: row.id,
            data: [
                row.name ? row.name : "",
                row.accountCode,
                row.freebookingOnClaimInvoice ?
                    <Icon name={"fas fa-check"} /> :
                    ""
                ,
                row.freebookingOnPolicyInvoice ?
                    <Icon name={"fas fa-check"} /> :
                    ""
                ,
                row.freebookingOnCollectiveInvoice ?
                    <Icon name={"fas fa-check"} /> :
                    ""
                ,
                row.freebookingOnPartner ?
                    <Icon name={"fas fa-check"} /> :
                    ""
                ,
                <ButtonsWrapper
                    key={`navlink-${row.id}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.asAuditTab ? (
                            <NavLink to={`/audit/gl/${row.id}`}>
                                <Button
                                    icon={IconNames.ARROW_RIGHT}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                />
                            </NavLink>
                        ) :
                            this.props.user?.userRole !== UserRoles.EW_DEBTOR
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                                (
                                    <NavLink to={`/generalledgers/${row.id}/edit`}>
                                        <Button
                                            theme={{
                                                palette: ThemePalette.CONTRAST_PRIMARY,
                                                shadow: ThemeShadowSizes.TINY,
                                            }}
                                        >
                                    Edit
                                        </Button>
                                    </NavLink>
                                ) : ""}</ButtonsWrapper>,
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Name",
                },
                {
                    label: "GL Account code",
                },
                {
                    label: "FB Claim Invoice",
                },
                {
                    label: "FB Policy Invoice",
                },
                {
                    label: "FB Coll. Invoice",
                },
                {
                    label: "FB Partner",
                },
                this.props.user?.userRole !== UserRoles.EW_DEBTOR
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    {
                        label: "Actions",
                    } : {
                        label: "",
                    },
                // {
                //     label: "Actions",
                // },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
