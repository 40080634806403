import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../../store/AppDisplays";
import { Filter } from "../../../../02-pages/Filter";
import { isRemoteLoading, ReduxState } from "../../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { Results } from "../../../../02-pages/Results";
import { Incassos } from "../../../../../../../store/Incassos/Types";
import { PaidStatus } from "../../../../../../../store/AppTypes";
import { Div } from "../../../../../../../components/core/03-base/Div";

interface OwnProps {
    statementLineId: string;
}

interface State {
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: Incassos;
}

type Props = OwnProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.INCASSO_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.INCASSO_RESULTS) as Incassos,
});

/**
 *
 * @param props
 */
export class IncassoComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { };
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO}
                    defaultFilters={{
                        status: PaidStatus.UNPAID,
                    }}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO}
                    statementLineId={this.props.statementLineId}
                    scope={RemoteScope.INCASSO_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Incasso = connect(
    mapStateToProps,
    undefined,
)((props: Props) => <IncassoComp {...props}/>);
