
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ProductTariff } from "../../../../../store/Product/Types";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";

/**
 *
 */
interface OwnProps {
    modalID: string;
    productTariff: ProductTariff;
    productId: string;
}

interface State {
    endingDate: string;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    updateEndingDate: (productId: string, productTariffId: string, modalID: string, endingDate: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    updateEndingDate: (productId: string, productTariffId: string, modalID: string, endingDate: string) =>
        dispatch(remoteTrigger(RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH, {
            productId: productId,
            productTariffId: productTariffId,
            modalID: modalID,
            endingDate: endingDate,
        })),
});

/**
 *
 */
export class UpdateEndDateModalComp
    extends React.Component<Props, State> {


    public constructor(props: Props) {
        super(props);

        this.state = { endingDate: this.props.productTariff.endingDate ?? "" };
        this.closeModal = this.closeModal.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
    }

    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    Update ending date
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button  icon={IconNames.TIMES}
                                        link={{
                                            onClick: this.closeModal(),
                                        }}/>
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn>
                        <FlexRow isScrollable >
                            <FillUp>
                                <Container
                                    theme={{
                                        padding: {
                                            "": { y: 3 },
                                            "md": { y: 4 },
                                        },
                                    }}
                                    style={{ height: "490px" }}
                                >
                                        You are about to update the ending date
                                        for product tariff {this.props.productTariff.id}.
                                    <br /><br />
                                    <FormField
                                        label="Ending date"
                                        required
                                        placeholder="e.g. 2020-01-31"
                                        onChange={(value?: string | number) =>
                                            this.setState({ endingDate: value as string })}
                                        value={this.state.endingDate}
                                        type="date"
                                        popperPlacement="bottom-end"
                                    />
                                    <Required /><br /><br />
                                    <b>Are you sure you want to continue?</b>
                                </Container>
                            </FillUp>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
                <FlexRow isFixed>
                    <FlexColumn
                        theme={{
                            palette: ThemePalette.CONTRAST_SECONDARY,
                        }}
                    >
                        <Container
                            theme={{
                                padding: { "": { y: 3 } },
                            }}
                        >
                            <ButtonsWrapper
                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                alignment={Alignment.RIGHT}
                            >
                                <Button
                                    theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                    link={{ onClick: this.closeModal() }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                    link={{ onClick: this.updateEndDate() }}
                                >
                                    Yes </Button>
                            </ButtonsWrapper>
                        </Container>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn >
        );
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }

    private updateEndDate() {
        return () => {
            this.props.updateEndingDate(
                this.props.productId,
                this.props.productTariff.id?.toString() || "",
                this.props.modalID,
                this.state.endingDate,
            );
            this.props.closeModal(this.props.modalID);
        };
    }
}

/**
 *
 */
export const UpdateEndDateModal = connect(
    undefined,
    mapDispatchToProps,
)(UpdateEndDateModalComp);
