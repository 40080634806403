import React from "react";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";

import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { NavLink } from "react-router-dom";
import { Filter } from "../Filter";
import { Results } from "../Results";


import { connect } from "react-redux";
import { Partners, PartnerType } from "../../../../../store/Partners/Types";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Blockquote } from "../../../../../components/core/05-atoms/Blockquote";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Card } from "../../../../../components/core/07-organisms/Card";
import { ValidationStatus } from "../../../../../components/core/07-organisms/Form/_Types";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";
import { updateCollectiveInvoiceForm, clearCollectiveInvoiceForm,
    clearPartnersCI } from "../../../../../store/CollectiveInvoice/GenerateInvoice";
import { CollectiveInvoiceGenerate, InvoiceSelectedRows,
    canSubmitCollectiveInvoiceForm } from "../../../../../store/CollectiveInvoice/Types";
import { CollectiveInvoiceForm, RemoteErrors, ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette, ThemeBreakpoints } from "../../../../../theme/_Types";
import { validFromValidation, validTillValidation } from "../../../../../utils/FormValidations";
import { getErrorValidationMessage } from "../../../../utils/ErrorMessage";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { getDuration } from "../../../../../store/FilterTypes";

interface State {
    ready: boolean;
    continue: boolean;
    loading: boolean;
}

/**
 *
 */
interface StateProps {
    canSubmitCollectiveInvoiceForm: boolean;
    results: Partners;
    loadingFilters: boolean;
    loadingResults: boolean;
    collectiveInvoiceForm: CollectiveInvoiceForm;
    resultsGenerate?: CollectiveInvoiceGenerate;
    resultsErrorGenerate?: RemoteErrors;
}

interface DispatchProps {
    generateInvoice: (selectedRows: InvoiceSelectedRows) => void;
    updateCollectiveInvoiceForm: (duration: Partial<CollectiveInvoiceForm>) => void;
    clearCollectiveInvoiceForm: (duration?: Array<keyof CollectiveInvoiceForm>) => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => {
    const error = s.prop("remoteErrors");
    const duration = s.prop("collectiveInvoiceForm");
    return {
        // canSubmitCollectiveInvoiceForm: canSubmitCollectiveInvoiceForm(s),
        canSubmitCollectiveInvoiceForm: canSubmitCollectiveInvoiceForm(duration),
        loadingFilters: false,
        loadingResults: isRemoteLoading(s, RemoteScope.PARTNER_RESULTS_CI),
        results: s.prop("remote").prop(RemoteScope.PARTNER_RESULTS_CI) as Partners,
        collectiveInvoiceForm: getDuration(duration),
        resultsGenerate: s.prop("remote").prop(RemoteScope.COLLECTIVE_INVOICE_GENERATE) as CollectiveInvoiceGenerate,
        resultsErrorGenerate:
            getErrorValidationMessage(error, RemoteScope.COLLECTIVE_INVOICE_GENERATE),
    };
};

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    generateInvoice: (selectedRows: InvoiceSelectedRows) => (
        dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_GENERATE, {
            selectedRows: selectedRows,
        }))
    ),
    updateCollectiveInvoiceForm: (duration: CollectiveInvoiceForm) =>
        dispatch(updateCollectiveInvoiceForm(duration)),
    clearCollectiveInvoiceForm: (duration) => {
        dispatch(clearCollectiveInvoiceForm(duration));
    },
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_GENERATE)),
        dispatch(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_GENERATE)),
        dispatch(clearPartnersCI())
    ),
});

/**
 *
 * @param props
 */
export class GenerateComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);

        this.state = {
            ready: false,
            continue: false,
            loading: false,
        };
        this.continue = this.continue.bind(this);
        this.generateInvoice = this.generateInvoice.bind(this);
        this.onChange = this.onChange.bind(this);
        this.clear = this.clear.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
    }

    public componentDidMount() {
        this.clear();
    }

    public componentWillUnmount() {
        this.clear();
        this.props.clearResults();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={PageDisplay[Pages.COLLECTIVEINVOICES].title}
                        goBack={PageDisplay[Pages.COLLECTIVEINVOICES].path}
                    />

                    <Header
                        title={SubPageDisplay[SubPages.COLLECTIVE_INVOICE_GENERATION].title}
                        description={SubPageDisplay[SubPages.COLLECTIVE_INVOICE_GENERATION].description}
                    />

                    <>
                        {!this.state.continue ?
                            <Div
                                theme={{
                                    shadow: ThemeShadowSizes.TINY,
                                    margin: {
                                        "": { t: 2 },
                                        "sm": { t: 3 },
                                    },
                                }}
                            >
                                <Card
                                    title={"Specification"}
                                    collapsable={false}
                                    defaultCollapsed={false}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 12 }}
                                            >
                                                <Div className="scl-b-row">
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormFields.FormField
                                                            label="From date"
                                                            required
                                                            placeholder="e.g. 2020-01-31"
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("from",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.collectiveInvoiceForm ?
                                                                this.props.collectiveInvoiceForm.from : ""}
                                                            type="date"
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    validFromValidation(value.toString(),
                                                                        this.props.collectiveInvoiceForm
                                                                            ? this.props.collectiveInvoiceForm.until
                                                                            : undefined)
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormFields.FormField
                                                            label="To date"
                                                            required
                                                            placeholder="e.g. 2020-01-31"
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("until",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.collectiveInvoiceForm ?
                                                                this.props.collectiveInvoiceForm.until : ""}
                                                            type="date"
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    validTillValidation(value.toString(),
                                                                        this.props.collectiveInvoiceForm
                                                                            ? this.props.collectiveInvoiceForm.from
                                                                            : undefined)
                                                            }
                                                        />
                                                    </Grid>
                                                </Div>
                                            </Grid>
                                        </Div>
                                        <Required />
                                        <FormBar
                                            title={"Select a period (step 1/2)"}
                                            onClick={this.continue}
                                            isValid={this.props.canSubmitCollectiveInvoiceForm
                                                ? this.formIsValid() : undefined}
                                            buttonLabel={(
                                                <>
                                                    Next
                                                    &nbsp;&nbsp;
                                                    <Icon name={IconNames.ARROW_RIGHT} />
                                                </>
                                            )}
                                        />
                                    </Container>
                                </Card>
                            </Div>
                            :
                            (
                                !this.state.loading && !this.state.ready ?
                                    <Div>
                                        <Filter
                                            loadingFilters={this.props.loadingFilters}
                                            loadingResults={this.props.loadingResults}
                                            currentPage={SubPages.COLLECTIVE_INVOICE_GENERATION}
                                            defaultFilters={{
                                                partnerTypes: [PartnerType.POLICY_HOLDER, PartnerType.SELF_INSURER],
                                                periodFrom: this.props.collectiveInvoiceForm.from,
                                                periodTo: this.props.collectiveInvoiceForm.until,
                                            }}
                                        />
                                        <Div
                                            theme={{
                                                margin: {
                                                    "": { b: 4 },
                                                    "sm": { b: 5 },
                                                },
                                            }}
                                        >
                                            <Results
                                                results={this.props.results}
                                                loadingResults={this.props.loadingResults}
                                                currentPage={SubPages.COLLECTIVE_INVOICE_GENERATION}
                                                generateInvoice={this.generateInvoice}
                                            />
                                            {this.props.results?.content.length === 0 &&
                                            <FormBar
                                                title={"Select partners (step 2/2)"}
                                                isValid={false}
                                                invalidMessage={"No partners found for the selected period"}
                                            />}
                                        </Div>
                                    </Div>
                                    : this.state.loading && !this.state.ready ?
                                        <Div
                                            theme={{
                                                shadow: ThemeShadowSizes.TINY,
                                                margin: {
                                                    "": { t: 2 },
                                                    "sm": { t: 3 },
                                                },
                                            }}
                                        >
                                            <Card
                                                title={"Loading..."}
                                                collapsable={false}
                                                defaultCollapsed={false}
                                            >
                                                <Container
                                                    theme={{
                                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                                        padding: {
                                                            "": { y: 3 },
                                                            "sm": { y: 4 },
                                                        },
                                                    }}
                                                >
                                                    <LoadingIndicator
                                                        type={LoadingIndications.DEFAULT}
                                                        theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                                    />
                                                </Container>
                                            </Card>
                                        </Div>
                                        :
                                        <Div
                                            theme={{
                                                shadow: ThemeShadowSizes.TINY,
                                                margin: {
                                                    "": { t: 2 },
                                                    "sm": { t: 3 },
                                                },
                                            }}
                                        >
                                            {this.props.resultsGenerate ?
                                                <Card
                                                    title={"Generation Complete"}
                                                    collapsable={false}
                                                    defaultCollapsed={false}
                                                >
                                                    <Container
                                                        theme={{
                                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                                            padding: {
                                                                "": { y: 3 },
                                                                "sm": { y: 4 },
                                                            },
                                                        }}
                                                    >
                                                        <Blockquote>
                                                            <h4>We have succesfully requested the generation
                                                 of the collective invoice</h4><br />
                                                            <div className="scl-h-text--big">
                                            Go to files to be able to find your generated collective invoice,<br />
                                            Or click on continue to go back to all collective invoices.
                                                            </div>
                                                        </Blockquote>
                                                        <ButtonsWrapper
                                                            orientations={{
                                                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                            alignment={Alignment.RIGHT}
                                                        >
                                                            <NavLink
                                                                to={PageDisplay[Pages.COLLECTIVEINVOICES].path}
                                                                exact
                                                            >
                                                                <Button
                                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                                    className="scl-a-btn--big"
                                                                // buttonType="submit"
                                                                >
                                                                    Continue
                                                                    &nbsp;&nbsp;
                                                                    <Icon name={IconNames.ARROW_RIGHT} />
                                                                </Button>
                                                            </NavLink>
                                                        </ButtonsWrapper>
                                                    </Container>
                                                </Card>
                                                :
                                                <Card
                                                    title={"Generation Failed"}
                                                    collapsable={false}
                                                    defaultCollapsed={false}
                                                >
                                                    <Container
                                                        theme={{
                                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                                            padding: {
                                                                "": { y: 3 },
                                                                "sm": { y: 4 },
                                                            },
                                                        }}
                                                    >
                                                        <Blockquote>
                                                            <h4>
                                                                {this.props.resultsErrorGenerate
                                                                    ? this.props.resultsErrorGenerate.message
                                                                    : "Something went wrong"},
                                                            </h4><br />
                                                            <div className="scl-h-text--big">
                                                                We couldn&apos;t generate the Collective Invoice.
                                                            </div>
                                                            <br />
                                                            <div className="scl-h-text--big">
                                                                Claude suggests to continue and try again.
                                                            </div>
                                                        </Blockquote>
                                                        <ButtonsWrapper
                                                            orientations={{
                                                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                            alignment={Alignment.RIGHT}
                                                        >
                                                            <NavLink
                                                                to={PageDisplay[Pages.COLLECTIVEINVOICES].path}
                                                                exact
                                                            >
                                                                <Button
                                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                                    className="scl-a-btn--big"
                                                                    buttonType="submit"
                                                                >
                                                                    Continue
                                                                    &nbsp;&nbsp;
                                                                    <Icon name={IconNames.ARROW_RIGHT} />
                                                                </Button>
                                                            </NavLink>
                                                        </ButtonsWrapper>
                                                    </Container>
                                                </Card>
                                            }
                                        </Div>
                            )
                        }
                    </>
                </Container>
            </Page>
        );
    }

    private continue() {
        this.setState({
            continue: true,
        });
    }

    private generateInvoice(selectedRows: number[]) {
        this.props.generateInvoice({
            fromDate: this.props.collectiveInvoiceForm.from || "",
            toDate: this.props.collectiveInvoiceForm.until || "",
            partnerIds: selectedRows,
        });
        this.setState({
            loading: true,
        }, () =>
            setTimeout(() => this.setState({
                ready: true,
                loading: false,
            }), 2000),
        );
    }

    private onChange = <K extends keyof CollectiveInvoiceForm>(key: K, value: CollectiveInvoiceForm[K]) => {
        this.props.updateCollectiveInvoiceForm({
            [key]: value,
        });
    };

    private clear = () => {
        const duration =
            (Object.keys(this.props.clearCollectiveInvoiceForm) as Array<keyof CollectiveInvoiceForm>);
        this.props.clearCollectiveInvoiceForm(duration);
    };

    private formIsValid = () => {
        if (this.props.collectiveInvoiceForm.from && this.props.collectiveInvoiceForm.until) {
            return validFromValidation(this.props.collectiveInvoiceForm.from,
                this.props.collectiveInvoiceForm
                    ? this.props.collectiveInvoiceForm.until : undefined).status === ValidationStatus.SUCCESS
                && validTillValidation(this.props.collectiveInvoiceForm.until,
                    this.props.collectiveInvoiceForm
                        ? this.props.collectiveInvoiceForm.from : undefined).status === ValidationStatus.SUCCESS;
        }
        return false;
    };
}

/**
 *
 */
export const Generate = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GenerateComp);
