import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { FilterScope } from "../FilterTypes";
import { ReducerFn } from "../ReduxState";

export type ClearTariffModelFilterAction = Readonly<
Action<ActionTypeKeys.CLEAR_TARIFF_MODEL_FILTER>
>;

export const clearTariffModelFilter:
() => ClearTariffModelFilterAction =
() => ({
    type: ActionTypeKeys.CLEAR_TARIFF_MODEL_FILTER,
});


export const clearTariffModelFilterReducer:
ReducerFn<ClearTariffModelFilterAction> =
(s, _a) => (
    s.setProp(
        "filters",
        s.prop("filters").setProp(
            FilterScope.TARIFFS,
            s.prop("filters").prop(FilterScope.TARIFFS).updateWith({modelId: undefined, modelName: undefined}),
        ),
    )
);
