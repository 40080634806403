import React from "react";
import { GeneralView } from "../../../../../00-blocks/Partners/component/GeneralView";
import { Partner } from "../../../../../../../../store/Partners/Types";

interface Props {
    partner: Partner;
}
/**
 *
 * @param props
 */
export const General: React.FC<Props> = (props: Props) => (
    <GeneralView general={props.partner}/>
);
