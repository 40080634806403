import { AxiosResponse } from "axios";
import { Partners, PartnerDetails, PartnerFilters } from "./Types";

/**
 *
 * @param resp
 */
export const mapPartnerRequests =
    (resp: AxiosResponse): Partners => resp.data as Partners;

/**
 *
 * @param resp
 */
export const mapPartnerDetailsRequests =
    (resp: AxiosResponse): PartnerDetails => resp.data as PartnerDetails;

/**
 *
 */
export const PartnerFiltersToParams = (f: PartnerFilters) => ({
    ...f,
    partnerTypes: Array.isArray(f.partnerTypes) ? f.partnerTypes.join(",") : f.partnerTypes,
});
