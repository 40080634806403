import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { RemoteScope } from "../RemoteTypes";
import { ReducerFn } from "../ReduxState";
import { BrandRequest } from "./Types";
import { State } from "../../utils/redux/State";

/**
 *
 */
export type InitUpdateBrandAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_BRAND> & {
}
>;

/**
 *
 *
 */
export const initUpdateBrand =
    (): InitUpdateBrandAction => ({
        type: ActionTypeKeys.INIT_UPDATE_BRAND,
    });

/**
 *
 * @param s
 * @param _a
 */
export const initUpdateBrandReducer:
ReducerFn<InitUpdateBrandAction> =
    (s, _a) => {
        {
            const brandDetails = s.prop("remote").prop(RemoteScope.BRAND_DETAILS);
            return (
                s.setProp(
                    "brandRequest",
                    s.prop("brandRequest").update((_old) => brandDetails?.content ?? {}),
                )
            );
        }
    };

/**
 *
 */
export type UpdateBrandAction = Readonly<
Action<ActionTypeKeys.UPDATE_BRAND> & {
    brand?: Partial<BrandRequest>;
}
>;

/**
 *
 *
 */
export const updateBrand =
    (brand: BrandRequest): UpdateBrandAction => ({
        type: ActionTypeKeys.UPDATE_BRAND,
        brand: brand,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateBrandReducer:
ReducerFn<UpdateBrandAction> =
    (s, a) =>
    // const products = s.prop("remote").prop(RemoteScope.PRODUCT_RESULTS);
    // const selectedProduct = products?.content.find(product =>
    //     product.id === Number(a.policy?.extendedWarranty?.id));

        s.setProp(
            "brandRequest",
            s.prop("brandRequest").updateWith(
                a.brand ? { ...a.brand } : {},
            ),
        )
    ;

/**
 *
 */
export type ClearBrandAction = Readonly<
Action<ActionTypeKeys.CLEAR_BRAND>
>;

/**
 *
 * @param keys
 */
export const clearBrand:
() => ClearBrandAction =
    () => ({
        type: ActionTypeKeys.CLEAR_BRAND,
    });

/**
 *
 */
export const clearBrandReducer:
ReducerFn<ClearBrandAction> =
    (s, _a) => (
        s.setProp(
            "brandRequest",
            State.create<BrandRequest>(() => ({})),
        )
    );
