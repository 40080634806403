/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ClaimErrors } from "../../../../../store/ClaimErrors/Types";
import { BusinessRuleViolation } from "../../../../../store/Policies/Types";
import { ErrorDetailModal } from "./ErrorDetailModal";
import Moment from "react-moment";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePalette, ThemeBreakpoints, ThemeShadowSizes } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { getDamageStatusColor } from "../../../../utils/FormatHelpers";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";

/**
 *
 */
interface OwnProps {
    results: ClaimErrors;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openDetailModal: (id: string, violations: BusinessRuleViolation[]) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

export const DetailModal = (id: string, violations: BusinessRuleViolation[]) => (
    () => (
        <Modal
            modalID={id}
            theme={{ size: ThemeSize.SMALL }}
        >
            <ErrorDetailModal
                modalID={id}
                id={id}
                violations={violations}
            />
        </Modal>
    )
);

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.CLAIM_ERROR_RESULTS, {
        skip: skip,
    })),
    openDetailModal: (id: string, violations: BusinessRuleViolation[]) => dispatch(
        modalShow(
            id,
            DetailModal(id, violations),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.policyNumber,
                <Moment date={new Date(row.createdAt)} format={"DD-MM-YYYY"} key={`createddate${index}`}/>,
                row.claimNumberPAH,
                (
                    <Badge
                        key={`badge-${row.id}`}
                        theme={{ paletteState: getDamageStatusColor(row.status) }}>
                        {row.status.toString()}
                    </Badge>
                ),
                row.recordType,
                row.claimElementType,
                (
                    <ButtonsWrapper
                        key={`buttonswrapper-${row.id}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <Button
                            icon={IconNames.VIEW}
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                shadow: ThemeShadowSizes.TINY,
                            }}
                            link={{
                                onClick:
                                    this.openModal(row.id.toString(), row.businessRuleViolations),
                            }}
                        />
                    </ButtonsWrapper>
                ),
            ],
        }));
    }

    private openModal(id: string, violations: BusinessRuleViolation[]) {
        return () => this.props.openDetailModal(id.toString(), violations);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Policy number",
                },
                {
                    label: "Created at",
                },
                {
                    label: "Claim number (PAH)",
                },
                {
                    label: "Status",
                },
                {
                    label: "Record type",
                },
                {
                    label: "Claim element type",
                },
                {
                    label: "Error description",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
