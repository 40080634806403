/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from "react";
import { useDropzone } from "react-dropzone";
import { DragNDropArea } from "./DragNDropArea";

interface OwnProps {
    onInvalid: (msg: string) => void;
}
export interface OnReceivedProps {
    onReceivedFile: (getBinary: string | ArrayBuffer | null) => void;
}

/**
 *
 */
type Props = OwnProps & OnReceivedProps;

export const DragNDrop: React.FunctionComponent<Props> = props => {

    const onDrop = React.useCallback((acceptedFiles) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("Claude: file reading was aborted");
            reader.onerror = () => console.log("Claude: file reading has failed");
            reader.onload = () => {
                props.onReceivedFile(file);
            };
            reader.readAsArrayBuffer(file);
        });
    }, [props]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <DragNDropArea wrapper={getRootProps} input={getInputProps}/>
    );
};
