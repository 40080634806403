import * as React from "react";
import { connect } from "react-redux";

import { PageTabs } from "../../../../../store/AppDisplays";
import { validSelectedRow } from "../../../../utils/Booking";
import { amIChecked, myAmount } from "../../../../utils/ManualBooking";
import { ManualBooking, ManualBookingTypes, ManualBookings } from "../../../../../store/ManualBooking/Types";
import { DispatchPropsResults, mapDispatchToPropsResults,
    mapStateToPropsResults, StatePropsResults } from "../ManualBookings/Create/Results";
import { bookingBarData, ManualBookBar } from "../ManualBookings/Create/ManualBookBar";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import Moment from "react-moment";
import { Invoices, Invoice } from "../../../../../store/Invoice/Types";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { canDownloadFile } from "../../../../utils/FileDownload";
import { getClaimInvoiceStatusColor } from "../../../../utils/FormatHelpers";
import { ClaimInvoiceStatusDisplay } from "../../../../../store/ClaimMatch/Displays";
import { RowData, Table } from "../../../../../components/core/06-molecules/Table";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Container } from "../../../../../components/core/03-base/Container";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { ThemeBreakpoints, ThemePalette, ThemePaletteState } from "../../../../../theme/_Types";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { DownloadButtonCell } from "../../../06-molecules/DownloadButtonCell";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

interface State {
    selectedRows: ManualBooking[];
}

/**
 *
 */
interface OwnProps {
    results: Invoices;
    currentTab?: PageTabs;
}

/**
 *
 */
type Props = OwnProps & StatePropsResults & DispatchPropsResults;

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = this.refreshedRowState();

        this.resultsData = this.resultsData.bind(this);
        this.onAddSelection = this.onAddSelection.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.submit = this.submit.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        const calcBookingBarData = bookingBarData(this.props.manualBookings, this.state.selectedRows);

        return (
            <Form onSubmit={this.submit} isValid={calcBookingBarData.isValid === true}>
                <ManualBookBar
                    title={"Free booking"}
                    {...calcBookingBarData}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </Form>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private submit() {
        this.props.openBookRequest(
            "partner-invoices-free-booking", () =>
                this.props.manualBook(this.props.manualBooking as ManualBookings));
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const isLoading = ((this.props.fileDownloadResult && this.props.fileDownloadResult.id === row.messageId)
                && this.props.loadingFileDownload);
            return ({
                id: row.id,
                themePalleteState: validSelectedRow(- row.balance, myAmount(row.id, this.state.selectedRows))
                    ? undefined : ThemePaletteState.DANGER,
                tooltip: validSelectedRow(- row.balance,
                    myAmount(row.id, this.state.selectedRows)) ? undefined : "The selected balance is not valid",
                data: [
                    (
                        <div
                            key={`selection-${row.id}`}
                            className="scl-row-selection"
                            style={{
                                display: "inline-flex",
                                flexDirection: "row",
                                flexGrow: 0,
                                alignItems: "center",
                            }}
                        >
                            <div
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                <Checkbox
                                    key={`checkbox-${row.id}`}
                                    checked={amIChecked(row.id, this.state.selectedRows)}
                                    onChange={(value?: boolean) => this.selectItem(value, row
                                        , - row.balance)}
                                />
                            </div>
                            <div
                                className="scl-row-selection__input"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                }}
                            >
                                {amIChecked(row.id, this.state.selectedRows) &&
                                    <FormField
                                        type="currency"
                                        placeholder="e.g. 100"
                                        onChange={(value?: number | string) => {
                                            this.onChangeAmount(row,
                                                value ? typeof value === "string"
                                                    ? parseFloat(value) : value : 0);
                                        }}
                                        value={myAmount(row.id, this.state.selectedRows)}
                                    />
                                }
                            </div>
                        </div>
                    ),
                    row.invoiceNumber,
                    row.invoiceDate
                        ? <Moment date={new Date(row.invoiceDate)} format={"DD-MM-YYYY"} key={`c-date-${index}`} />
                        : "",
                    row.collectiveInvoiceNumber,
                    row.mutationDate
                        ? <Moment date={new Date(row.mutationDate)} format={"DD-MM-YYYY"} key={`mutationdate${index}`}/>
                        : "",
                    row.creditDebitIndicator === CreditDebitIndicator.DBIT ? "Debit" : "Credit",
                    (
                        <Badge
                            key={`badge-${row.id}`}
                            theme={{paletteState: getClaimInvoiceStatusColor(row.status)}}>
                            {ClaimInvoiceStatusDisplay[row.status.toString()]}
                        </Badge>
                    ),
                    (
                        <h6
                            key={`h6-${row.id}`}>
                            <Currency
                                amount={row.amount.amount}
                            />
                        </h6>
                    ),
                    (
                        <h6
                            key={`h6-2-${row.id}`}>
                            <Currency
                                amount={row.balance}
                            />
                        </h6>
                    ),
                    <ButtonsWrapper
                        key={`navlink-${row.id}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >{
                            row.messageId ? (
                                <DownloadButtonCell
                                    isLoading={isLoading}
                                    loadDownload={() => this.props.loadDownload(row.messageId)}
                                    canDownloadFile={canDownloadFile(this.props)}
                                />
                            ) : ""}</ButtonsWrapper>,
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Number",
                },
                {
                    label: "Date",
                },
                {
                    label: "Coll. Inv. number",
                },
                {
                    label: "Mutation date",
                },
                {
                    label: "Mutation type",
                },
                {
                    label: "Status",
                },
                {
                    label: "Amount",
                    alignRight: true,
                },
                {
                    label: "Balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }

    private refreshedRowState(): State {
        return {
            selectedRows: this.props.manualBookings ? this.props.manualBookings
                .filter(mB => (mB.objectType === this.props.bookingType) &&
                        (this.props.results.content.some(row => row.id === mB.objectId)))
                .map((mB): ManualBooking => ({
                    objectId: mB.objectId,
                    description: mB.description ? mB.description : "",
                    amount: mB.amount,
                    objectType: this.props.bookingType,
                })) : [],
        };
    }

    private selectItem(value: boolean | undefined, row: Invoice, amount: number) {
        this.setState({
            selectedRows: !!value
                ? [...this.state.selectedRows.filter((sR) => (sR.objectId !== row.id
                    && sR.objectType === this.props.bookingType)), {
                    objectId: row.id,
                    amount: amount,
                    description: row.invoiceNumber,
                    objectType: this.props.bookingType,
                }] : this.state.selectedRows.filter((sR) => sR.objectId !== row.id
                    && sR.objectType === this.props.bookingType),
        }, !!value
            && validSelectedRow(- row.balance, amount)
            ? this.onAddSelection : () => this.props.deleteManualBookingRow(row.id));
    }

    private onChangeAmount = (row: Invoice, value: number) => {
        const manualBookingEntry = this.state.selectedRows.find(mB => (mB.objectId === row.id
                && mB.objectType === this.props.bookingType));

        this.setState({
            selectedRows: manualBookingEntry ? this.state.selectedRows.map(sR => {
                if (sR.objectId === row.id && sR.objectType === this.props.bookingType) {
                    return ({
                        objectId: sR.objectId,
                        amount: value,
                        description: sR.description,
                        objectType: this.props.bookingType,
                    });
                }

                return sR;
            }) : [...this.state.selectedRows, {
                objectId: row.id,
                amount: value,
                objectType: this.props.bookingType,
                description: row.invoiceNumber,
            }],
        }, validSelectedRow(- row.balance, value) ? this.onAddSelection
            : () => this.props.deleteManualBookingRow(row.id));
    };

    private onAddSelection = () => {
        this.props.addManualBookSelection(this.state.selectedRows);
    };
}

/**
 *
 */
export const Results = connect(
    mapStateToPropsResults,
    mapDispatchToPropsResults(RemoteScope.INVOICE_RESULTS, ManualBookingTypes.PARTNER_INVOICE),
)(ResultsComp);
