import * as React from "react";

import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";
import { ThemePaletteState, ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";

export interface ColumnData {
    label: string | JSX.Element;
    sortable?: boolean;
    alignRight?: boolean;
}

export interface RowData {
    id: number | string;
    tooltip?: string;
    themePalleteState?: ThemePaletteState;
    data: Array<string | number | JSX.Element | Date | undefined>;
}

export interface SortData {
    column: number;
    asc: boolean;
}

export interface TableData {
    columns: ColumnData[];
    rows: RowData[];
    sort?: SortData;
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    hasActions?: boolean;
    data?: TableData;
    withoutColumnHeader?: boolean;
    onSortChange?: (column: number, asc: boolean) => void;
}

/**
 *
 * @param props
 */
const TableComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-m-table__wrapper",
    );

    if (!props.data) {
        return null;
    }

    // Data
    const dataColumns = props.data.columns;
    const dataRows = props.data.rows;

    return (
        <div className={clazzName}>
            <table className="scl-m-table">
                { !props.withoutColumnHeader && (
                    <TableHeader
                        columns={dataColumns}
                        activeSort={props.data.sort}
                        onSortChange={props.onSortChange}
                        hasActions={props.hasActions}
                    />)
                }
                <TableBody rows={dataRows} columns={dataColumns} hasActions={props.hasActions}/>
            </table>
        </div>
    );
};

/**
 *
 */
export const Table = asThemeConsumer(TableComp);
