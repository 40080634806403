import * as React from "react";
import { connect } from "react-redux";

import { remoteTrigger, remoteClearResponse } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../..//store/RemoteTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { MemoRequest, canSubmitMemoForm } from "../../../../..//store/Memos/Types";
import { updateMemo, clearMemo, initUpdateMemo } from "../../../../..//store/Memos/Create";
import { Div } from "../../../../../components/core/03-base/Div";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Container } from "../../../../../components/core/03-base/Container";
import { FormTextArea } from "../../../../../components/core/07-organisms/Form/TextArea";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";

/**
 *
 */
interface OwnProps {
    modalID: string;
    edit?: boolean;
    memoId?: string;
    policyId?: string;
}

interface StateProps {
    memo: MemoRequest;
    canSubmitMemoForm: boolean;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    createMemo: (memo: MemoRequest, policyId: string) => void;
    initUpdateMemo: (id: string) => void;
    editMemo: (modalID: string, memo: MemoRequest) => void;
    onChange: (memo: MemoRequest) => void;
    clearMemo: () => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    canSubmitMemoForm: canSubmitMemoForm(s.prop("memoRequest")),
    memo: s.prop("memoRequest").map(e => ({
        ...e,
    })),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    createMemo: (memo: MemoRequest, policyId: string) => dispatch(remoteTrigger(RemoteScope.MEMO_CREATE, {
        memo: memo,
        policyId: policyId,
    })),
    initUpdateMemo: (id: string) => dispatch(initUpdateMemo(id)),
    editMemo: (modalID: string, memo: MemoRequest) => dispatch(remoteTrigger(RemoteScope.MEMO_EDIT, {
        memo: memo,
        modalID: modalID,
    })),
    onChange: (memo: MemoRequest) => dispatch(updateMemo(memo)),
    clearMemo: () => {
        dispatch(clearMemo());
        dispatch(remoteClearResponse(RemoteScope.MEMO_CREATE));
        dispatch(remoteClearResponse(RemoteScope.MEMO_EDIT));
    },
});

/**
 *
 */
export class UpdateMemoComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.closeModal = this.closeModal.bind(this);
        this.createMemo = this.createMemo.bind(this);
        this.editMemo = this.editMemo.bind(this);
    }

    public componentDidMount() {
        this.props.clearMemo();
        if (this.props.edit) {
            this.props.initUpdateMemo(this.props.memoId ?? "");
        }
    }

    public componentWillUnmount() {
        this.props.clearMemo();
    }

    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {this.props.edit ? "Edit Memo" : "Add Memo"}
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button   icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }}
                                    />
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn>
                        <FlexRow isScrollable >
                            <FillUp>
                                <Container
                                    theme={{
                                        padding: {
                                            "": { y: 3 },
                                            "md": { y: 4 },
                                        },
                                    }}
                                >
                                    <>
                                        <Form>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 12 }}
                                                >
                                                    <FormTextArea
                                                        label={"Description"}
                                                        required
                                                        placeholder="My memo"
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("description",
                                                                value ? value.toString() : "")}
                                                        value={this.props.memo.description}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Description provided")}
                                                    />
                                                </Grid>
                                            </Div>
                                        </Form>
                                        <Div><Required /></Div>
                                    </>
                                </Container>
                                <FlexRow isFixed>
                                    <FlexColumn
                                        theme={{
                                            palette: ThemePalette.CONTRAST_SECONDARY,
                                        }}
                                    >
                                        <Container
                                            theme={{
                                                padding: { "": { y: 3 } },
                                            }}
                                        >
                                            <ButtonsWrapper
                                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                alignment={Alignment.RIGHT}
                                            >
                                                <Button
                                                    theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                                    link={{ onClick: this.closeModal() }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                    disabled={!this.props.canSubmitMemoForm}
                                                    link={{
                                                        onClick:
                                                            this.props.edit ? this.editMemo : this.createMemo,
                                                    }}
                                                    buttonType="submit"
                                                >
                                                    Yes
                                                </Button>
                                            </ButtonsWrapper>
                                        </Container>
                                    </FlexColumn>
                                </FlexRow>
                            </FillUp>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }

    private onChange = <K extends keyof MemoRequest>(key: K, value: MemoRequest[K]) => {
        this.props.onChange({
            [key]: value,
        });
    };

    private createMemo() {
        this.props.createMemo(this.props.memo, this.props.policyId ?? "");
    }

    private editMemo() {
        this.props.editMemo(this.props.modalID, this.props.memo);
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }
}

/**
 *
 */
export const UpdateMemoModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateMemoComp);
