/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Hr } from "../../../../../components/core/05-atoms/Hr";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { PremiumPolicy } from "../../../../../store/Policies/Types";
import { updatePolicyPremium } from "../../../../../store/Policies/UpdatePolicyPremium";
import { ThemePalette } from "../../../../../theme/_Types";
import { sumOfOtherComp, validationCheck } from "../../../07-organisms/Form/PremiumValidation";

interface OwnProps {
    policyPremium?: PremiumPolicy;
}

interface DispatchProps {
    onChange: (premiumPolicy: PremiumPolicy) => void;
}

export type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    onChange: (premiumPolicy: PremiumPolicy) => dispatch(updatePolicyPremium(premiumPolicy)),
});

/**
 *
 */
/**
 *
 */
export class PolicyPremiumComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {

        const { netMij = 0, tax = 0, cost = 0, signCommitteeDamage = 0, incassoProvision = 0 } = this.props.policyPremium ?? {};
        const sum = sumOfOtherComp(netMij,tax,cost,signCommitteeDamage,incassoProvision);

        return (
            !!this.props.policyPremium && (
                <>
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <FormFields.FormField
                                label="Max KW"
                                required
                                onChange={(value?: string | number) =>
                                    this.onChange("maxKW", value ? Number(value) : undefined)}
                                value={this.props.policyPremium.maxKW}
                                validationFunction={
                                    (value: string | number) => hasValueValidation(
                                        value.toString(),
                                        "Max KW provided",
                                    )}
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <FormFields.FormField
                                label="Premium"
                                required
                                type="currency"
                                onChange={(value?: string | number) =>
                                    this.onChange("premium", value ? Number(value) : undefined)}
                                value={this.props.policyPremium.premium}
                                validationFunction={() => validationCheck(
                                    this.props.policyPremium?.premium ?? 0,
                                    this.props.policyPremium?.netMij ?? 0,
                                    this.props.policyPremium?.tax ?? 0,
                                    this.props.policyPremium?.cost ?? 0,
                                    this.props.policyPremium?.signCommitteeDamage ?? 0,
                                    this.props.policyPremium?.incassoProvision ?? 0,
                                    () => this.onChange("premium", sum),
                                    "Premium provided")}
                            />
                        </Grid>
                    </Div>
                    <FieldSet title={"Premium components"}>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormFields.FormField
                                    label="Tax"
                                    required
                                    type="currency"
                                    onChange={(value?: string | number) =>
                                        this.onChange("tax", value ? Number(value) : undefined)}
                                    value={this.props.policyPremium.tax}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value.toString(),
                                                "Tax provided")}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormFields.FormField
                                    label="Cost"
                                    required
                                    type={"currency"}
                                    onChange={(value?: string | number) =>
                                        this.onChange("cost",
                                            value ? Number(value) : undefined)}
                                    value={this.props.policyPremium.cost}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value.toString(),
                                                "Cost provided")}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormFields.FormField
                                    label="Net mij"
                                    required
                                    type={"currency"}
                                    onChange={(value?: string | number) =>
                                        this.onChange("netMij",
                                            value ? Number(value) : undefined)}
                                    value={this.props.policyPremium.netMij}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value.toString(),
                                                "Net mij provided")}
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormFields.FormField
                                    label="Sign Commmittee Damage"
                                    required
                                    type={"currency"}
                                    onChange={(value?: string | number) =>
                                        this.onChange("signCommitteeDamage",
                                            value ? Number(value) : undefined)}
                                    value={this.props.policyPremium.signCommitteeDamage}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value.toString(),
                                                "Sign Commmittee Damage provided")}
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormFields.FormField
                                    label="Incasso provision"
                                    required
                                    type={"currency"}
                                    onChange={(value?: string | number) =>
                                        this.onChange("incassoProvision",
                                            value ? Number(value) : undefined)}
                                    value={this.props.policyPremium.incassoProvision}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasValueValidation(value.toString(),
                                                "Incasso provision provided")}
                                />
                            </Grid>
                        </Div>
                        <Hr />
                        <Div
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                padding: {
                                    "": { b: 3 },
                                    "sm": { b: 4 },
                                },
                            }}
                        >
                            <Div style={{display: "flex"}}>
                                <span>Total sum</span>
                                <span style={{marginLeft: "auto"}}>
                                    <b>
                                        <Currency amount={sum}/>
                                    </b>
                                </span>
                            </Div>
                        </Div>
                    </FieldSet>
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <FormFields.FormField
                                label="Net Premium"
                                required
                                type={"currency"}
                                onChange={(value?: string | number) =>
                                    this.onChange("netPremium",
                                        value ? Number(value) : undefined)}
                                value={this.props.policyPremium.netPremium}
                                validationFunction={
                                    (value: string | number) =>
                                        hasValueValidation(value.toString(),
                                            "Net Premium provided")}
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <FormFields.FormField
                                label="Commission VWPFS"
                                required
                                type={"currency"}
                                onChange={(value?: string | number) =>
                                    this.onChange("commissionVWPFS",
                                        value ? Number(value) : undefined)}
                                value={this.props.policyPremium.commissionVWPFS}
                                validationFunction={
                                    (value: string | number) =>
                                        hasValueValidation(value.toString(),
                                            "Commission VWPFS provided")}
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <FormFields.FormField
                                label="Net Premium Ex Cost"
                                required
                                type={"currency"}
                                onChange={(value?: string | number) =>
                                    this.onChange("netPremiumExCost",
                                        value ? Number(value) : undefined)}
                                value={this.props.policyPremium.netPremiumExCost}
                                validationFunction={
                                    (value: string | number) =>
                                        hasValueValidation(value.toString(),
                                            "Net Premium Ex Cost provided")}
                            />
                        </Grid>
                    </Div>
                    <Div className="scl-b-row">

                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <FormFields.FormField
                                label="Frontier"
                                required
                                type={"currency"}
                                onChange={(value?: string | number) =>
                                    this.onChange("frontier",
                                        value ? Number(value) : undefined)}
                                value={this.props.policyPremium.frontier}
                                validationFunction={
                                    (value: string | number) =>
                                        hasValueValidation(value.toString(),
                                            "Frontier provided")}
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <FormFields.FormField
                                label="Insurer"
                                required
                                type={"currency"}
                                onChange={(value?: string | number) =>
                                    this.onChange("insurer",
                                        value ? Number(value) : undefined)}
                                value={this.props.policyPremium.insurer}
                                validationFunction={
                                    (value: string | number) =>
                                        hasValueValidation(value.toString(),
                                            "Insurer provided")}
                            />
                        </Grid>
                    </Div>
                </>)
        );
    }

    private onChange =
        <K extends keyof PremiumPolicy>(key: K, value: PremiumPolicy[K]) => {
            this.props.onChange({
                [key]: value,
            });
        };
}

/**
 *
 */
export const PolicyPremium = connect(
    undefined,
    mapDispatchToProps,
)(PolicyPremiumComp);
