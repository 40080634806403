import React from "react";
import { PageDisplay, Pages } from "../../../../../../store/AppDisplays";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { Page } from "../../../../../../components/core/09-views/01-sections/Page";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { remoteTrigger, remoteClearResponse } from "../../../../../../store/RemoteActions";
import { Models } from "../../../../../../store/Models/Types";
import { Redirect } from "react-router-dom";
import { TariffDetails, TariffRequest, canSubmitTariffForm } from "../../../../../../store/Tariffs/Types";
import { updateTariff, clearTariff } from "../../../../../../store/Tariffs/CreateType";
import { TariffFields } from "./TariffFields";
import { SelectTariffModelModal } from "../../Models/SelectTariffModelModal";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { Form } from "../../../../../../components/core/07-organisms/Form";
import { FormBar } from "../../../../../../components/core/07-organisms/FormBar";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";
import { ThemeSize, ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";
import { Container } from "../../../../../../components/core/03-base/Container";

type OnChange =
    <K extends keyof TariffRequest>(key: K, value: TariffRequest[K]) => void;

interface OwnProps {
    edit: boolean;
    tariffId: string;
}

interface StateProps {
    tariff: TariffRequest;
    loadingTariff: boolean;
    loadingFormHelpers: boolean;
    formHelpers: {
        modelResults?: Models;
    };
    canSubmitTariffForm: boolean;
    resultsSuccess: TariffDetails;
}

interface DispatchProps {
    updateTariff: (tariff: TariffRequest) => void;
    clear: () => void;
    loadFormHelpers: () => void;
    loadTariffDetailResults: (id: string) => void;
    addTariff: (tariff: TariffRequest) => void;
    editTariff: (tariff: TariffRequest) => void;
    selectModel: (modalId: string, onChange: OnChange, id?: string)
    => void;
}

type Props = OwnProps & DispatchProps & StateProps;

export const mapStateToProps = (s: ReduxState): StateProps => ({
    tariff: getTariff(s),
    loadingTariff: isRemoteLoading(s, RemoteScope.TARIFF_DETAILS),
    loadingFormHelpers: isRemoteLoading(s, RemoteScope.MODEL_RESULTS),
    formHelpers: {
        modelResults: s.prop("remote").prop(RemoteScope.MODEL_RESULTS),
    },
    canSubmitTariffForm: canSubmitTariffForm(s.prop("tariffRequest")),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.TARIFF_CREATE)
            || s.prop("remote").prop(RemoteScope.TARIFF_EDIT)) as TariffDetails,
});

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateTariff: (tariff: TariffRequest) => dispatch(updateTariff(tariff)),
    clear: () => {
        dispatch(clearTariff());
        dispatch(remoteClearResponse(RemoteScope.MODEL_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.TARIFF_CREATE));
        dispatch(remoteClearResponse(RemoteScope.TARIFF_EDIT));
    },
    loadFormHelpers: () => {
        dispatch(remoteTrigger(RemoteScope.MODEL_RESULTS, { skip: 0 }));
    },
    addTariff: (tariff: TariffRequest) => dispatch(remoteTrigger(RemoteScope.TARIFF_CREATE, {
        tariff: tariff,
    })),
    editTariff: (tariff: TariffRequest) => dispatch(remoteTrigger(RemoteScope.TARIFF_EDIT, {
        tariff: tariff,
    })),
    loadTariffDetailResults: (id: string) => dispatch(remoteTrigger(RemoteScope.TARIFF_DETAILS, {
        id: Number(id),
        modalID: id,
    })),
    selectModel:
            (modalId: string, onChange: OnChange, id?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectTariffModelModal
                                    modalID={modalId}
                                    modelNumber={id}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
});

/**
 *
 * @param s
 */
export const getTariff =
    (s: ReduxState): TariffRequest =>
        s.prop("tariffRequest").map(e => ({
            ...e,
        }));

/**
 *
 */
export class CreateTariffComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.addTariff = this.addTariff.bind(this);
        this.editTariff = this.editTariff.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    public componentDidMount() {
        this.props.clear();
        this.props.loadFormHelpers();
        if (this.props.edit) {
            this.props.loadTariffDetailResults(this.props.tariffId);
        }
    }

    public componentWillUnmount() {
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                {this.props.loadingFormHelpers || this.props.loadingTariff ||
                    (
                        !this.props.formHelpers.modelResults
                    )
                    ? (
                        <LoadingData loading={this.props.loadingFormHelpers || this.props.loadingTariff} />
                    )
                    : (<>
                        {this.props.resultsSuccess && (
                            <Redirect to={PageDisplay[Pages.TARIFFS].path} />
                        )}
                        <Form onSubmit={this.props.edit ? this.editTariff : this.addTariff}>
                            <Div
                                theme={{
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            >
                                <Container
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        padding: {
                                            "": { y: 3 },
                                            "sm": { y: 4 },
                                        },
                                    }}
                                >
                                    <TariffFields
                                        tariff={this.props.tariff}
                                        formHelpers={{
                                            modelResults: this.props.formHelpers.modelResults,
                                        }}
                                        onChange={this.onChange}
                                        selectModel={this.props.selectModel}
                                        canSubmitTariffForm={this.props.canSubmitTariffForm}
                                    />
                                </Container>
                            </Div>
                            <FormBar
                                buttonLabel={this.props.edit ? "Update" : "Create"}
                                title={this.props.edit ? "Edit tariff" : "Create tariff"}
                                isValid={!!this.props.canSubmitTariffForm || undefined}
                                hasRequiredFields
                            />
                        </Form>
                    </>
                    )}
            </Page>
        );
    }

    private onChange = <K extends keyof TariffRequest>(key: K, value: TariffRequest[K]) => {
        this.props.updateTariff({
            [key]: value,
        });
    };

    private addTariff() {
        this.props.addTariff(this.props.tariff);
    }

    private editTariff() {
        this.props.editTariff(this.props.tariff);
    }
}

/**
 *
 */
export const CreateManual = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateTariffComp);
