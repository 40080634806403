import * as React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../store/AppDisplays";
import { PaymentFilters } from "../../../../../store/PartnerPayments/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ThemePalette } from "../../../../../theme/_Types";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
type setFilterFn = (filters: Partial<PaymentFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof PaymentFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: PaymentFilters;
    currentTab?: PageTabs;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: PaymentFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.PAYMENTS, p.defaultFilters),
    filters: getFilters(s, FilterScope.PAYMENTS) as PaymentFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.PAYMENTS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.PAYMENTS));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.PAYMENT_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.PAYMENT_RESULTS)),
        dispatch(remoteClearError(RemoteScope.PAYMENT_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.invoiceNumberValidation = this.invoiceNumberValidation.bind(this);
        this.invoiceDateValidation = this.invoiceDateValidation.bind(this);
        this.amountValidation = this.amountValidation.bind(this);
        this.onChangeInvoiceNumber = this.onChangeInvoiceNumber.bind(this);
        this.onChangeInvoiceDate = this.onChangeInvoiceDate.bind(this);
        this.onChangeAmount = this.onChangeAmount.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Invoice number"
                            onChange={this.onChangeInvoiceNumber}
                            value={this.props.filters?.invoiceNumber ?? ""}
                            validationFunction={this.invoiceNumberValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Invoice date"
                            onChange={this.onChangeInvoiceDate}
                            type="date"
                            value={this.props.filters?.invoiceDate ?? ""}
                            validationFunction={this.invoiceDateValidation}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Amount"
                            type="currency"
                            onChange={this.onChangeAmount}
                            value={this.props.filters?.amount ?? ""}
                            validationFunction={this.amountValidation}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private invoiceNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Invoice number provided");
    }

    private onChangeInvoiceNumber(value?: string | number) {
        return this.onChange("invoiceNumber", value ? Number(value) : undefined);
    }

    private invoiceDateValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Invoice date provided");
    }

    private onChangeInvoiceDate(value?: string | number) {
        return this.onChange("invoiceDate", value ? value.toString() : "");
    }

    private amountValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Amount provided");
    }

    private onChangeAmount(value?: string | number) {
        return this.onChange("amount", value ? Number(value) : undefined);
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof PaymentFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof PaymentFilters>(key: K, value: PaymentFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
