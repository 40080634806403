/* eslint-disable max-len */

import React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";
import { Modal } from "../../../../../components/core/06-molecules/Modal";


import * as FormFields from "../../../../../components/core/07-organisms/Form/index";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { createTariff, addTariff, deleteTariff } from "../../../../../store/Product/CreateType";
import { ProductTariffRequests } from "../../../../../store/Product/Types";
import { ReduxState } from "../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Tariffs } from "../../../../../store/Tariffs/Types";
import { ThemeSize, ThemeShadowSizes, ThemePalette, ThemePaletteState } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { DetailWrapper } from "../../00-blocks/Tariffs/DetailWrapper";
import { SelectTariffModal } from "../../00-blocks/Tariffs/SelectTariffModal";

type OnChange =
    <K extends keyof ProductTariffRequests>(key: K, value: ProductTariffRequests[K], index: number) => void;

interface OwnProps {
    tariffs?: ProductTariffRequests[];
    edit?: boolean;
}

interface StateProps {
    resultsTariffs: Tariffs;
}

interface DispatchProps {
    createTariff: (tariff: ProductTariffRequests, index: number) => void;
    addTariff: () => void;
    deleteTariff: (index: number) => void;
    selectTariff: (modalId: string, index: number, onChange: OnChange, id?: number, edit?: boolean, disabled?: boolean)
    => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    resultsTariffs: s.prop("remote").prop(RemoteScope.TARIFF_RESULTS) as Tariffs,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    createTariff: (tariff: ProductTariffRequests, index: number) => dispatch(createTariff(tariff, index)),
    addTariff: () => dispatch(addTariff()),
    deleteTariff: (index: number) => dispatch(deleteTariff(index)),
    selectTariff:
            (modalId: string, index: number, onChange: OnChange, id?: number, edit?: boolean) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectTariffModal
                                    modalID={modalId}
                                    productTariffId={id}
                                    edit={edit}
                                    index={index}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
});

/**
 *
 */
export class CreateTariffComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {
        return (
            <>
                {
                    this.props.tariffs && (this.props.tariffs.map(
                        (tariff, index) => (
                            <Div
                                theme={{
                                    shadow: ThemeShadowSizes.TINY,
                                    margin: {
                                        "": { t: 2 },
                                        "sm": { t: 3 },
                                    },
                                }}
                                key={index}
                            >
                                <Navbar
                                    theme={{
                                        palette: ThemePalette.CONTRAST_TERTIARY,
                                    }}
                                    className="scl-h-text-align--left"
                                >
                                    <NavbarStack>
                                        <NavbarStackContent>
                                            <NavbarTitle>
                                                Product Tariff {index + 1}
                                            </NavbarTitle>
                                        </NavbarStackContent>
                                        <NavbarStackRight>
                                            <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                                                <Button
                                                    link={{ onClick: () => this.props.deleteTariff(index) }}
                                                    icon={IconNames.DELETE}
                                                    theme={{ paletteState: ThemePaletteState.DANGER }}
                                                />
                                            </Div>
                                        </NavbarStackRight>
                                    </NavbarStack>
                                </Navbar>
                                <Container
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        padding: {
                                            "": { y: 3 },
                                            "sm": { y: 4 },
                                        },
                                    }}
                                >
                                    <Div className="scl-b-row">
                                        <Grid
                                            size={{ xs: 12, md: 4 }}
                                        >
                                            <FormFields.FormField
                                                type="date"
                                                onChange={(value?: string | number) =>
                                                    this.onChangeTariffs("startingDate", value
                                                        ? value.toString() : "", index)}
                                                value={tariff.startingDate}
                                                label="Starting Date"
                                                required
                                                validationFunction={
                                                    (value: string | number) =>
                                                        FormFields.hasValueValidation(value,
                                                            "Starting Date provided")}
                                            />
                                        </Grid>
                                        <Grid
                                            size={{ xs: 12, md: 4 }}
                                        >
                                            <FormFields.FormField
                                                type="date"
                                                onChange={(value?: string | number) =>
                                                    this.onChangeTariffs("endingDate", value
                                                        ? value.toString() : "", index)}
                                                value={tariff.endingDate}
                                                label="Ending Date"
                                                required
                                                validationFunction={
                                                    (value: string | number) =>
                                                        FormFields.hasValueValidation(value,
                                                            "Ending Date provided")}
                                            />
                                        </Grid>
                                        <Grid
                                            size={{ xs: 12, md: 4 }}
                                        >
                                            <FormFields.FormField
                                                type="number"
                                                onChange={(value?: string | number) =>
                                                    this.onChangeTariffs("durationOfInsurance",
                                                        value ? Number(value) : undefined, index)}
                                                value={tariff.durationOfInsurance}
                                                label="Duration Of Insurance"
                                                required
                                                validationFunction={
                                                    (value: string | number) =>
                                                        FormFields.hasValueValidation(value,
                                                            "Duration Of Insurance provided")}
                                            />
                                        </Grid>
                                    </Div>
                                    <FieldSet title="Selected Tariff line">

                                        {tariff.tariffLine?.id &&
                                            <DetailWrapper tariffId={tariff.tariffLine?.id} />
                                        }
                                        <Button
                                            link={{
                                                onClick: () =>
                                                    this.props.selectTariff(
                                                        `${index}-modal`,
                                                        index,
                                                        this.onChangeTariffs,
                                                        tariff.tariffLine?.id,
                                                        this.props.edit,
                                                    ),
                                            }}
                                            buttonType={"button"}
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                        >
                                            {tariff.tariffLine?.id ? "Update tariff line" : "Select Tariff line"}
                                        </Button>
                                    </FieldSet>
                                    <br />
                                    <br />
                                    <Required />
                                </Container>
                            </Div>
                        )))}
                <Div
                    theme={{
                        shadow: ThemeShadowSizes.TINY,
                        margin: {
                            "": { t: 2 },
                            "sm": { t: 3 },
                        },
                    }}
                >
                    <div
                        onClick={this.props.addTariff}
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <Navbar
                            theme={{
                                palette: ThemePalette.CONTRAST_TERTIARY,
                            }}
                            className="scl-h-text-align--left"
                        >
                            <NavbarStack>
                                <NavbarStackContent>
                                    <NavbarTitle>
                                        {"Add a new product tariff"}
                                    </NavbarTitle>
                                </NavbarStackContent>
                                <NavbarStackRight>
                                    <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            icon={IconNames.PLUS}
                                        />
                                    </Div>
                                </NavbarStackRight>
                            </NavbarStack>
                        </Navbar>
                    </div>
                </Div>
            </>
        );
    }

    private onChangeTariffs =
        <K extends keyof ProductTariffRequests>(key: K, value: ProductTariffRequests[K], index: number) => {
            this.props.createTariff({
                [key]: value,
            }, index);
        };
}

/**
 *
 */
export const CreateTariff = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateTariffComp);
