import React from "react";
import { NavLink } from "react-router-dom";
import { ErrorDetail } from "../ErrorDetail";
import { Props as DetailProps } from "../Detail";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { Icon } from "../../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { PageDisplay, Pages, SubPageDisplay, SubPages, PageTabs } from "../../../../../../store/AppDisplays";
import { UserRoles } from "../../../../../../store/AppTypes";
import { PolicyStatusDisplay } from "../../../../../../store/Policies/Displays";
import { PolicyStatus } from "../../../../../../store/Policies/Types";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes, ThemePaletteState } from "../../../../../../theme/_Types";
import { licensePlate } from "../../../../../../utils/FormatHelpers";
import { getPolicyStatusColor } from "../../../../../utils/FormatHelpers";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";
import { Summary } from "../../../00-blocks/Summary";
import { Tabs } from "../../../00-blocks/Tabs";
import { Header } from "../../../../../../components/core/09-views/01-sections/Header";
import { Button } from "../../../../../../components/core/05-atoms/Button";

interface OwnProps {
    asAudit?: boolean;
}

type Props = OwnProps & DetailProps;

/**
 *
 * @param props
 */
export const DetailView: React.FC<Props> = (props: Props) => (
    <>
        {!props.asAudit &&
                <Header
                    title={PageDisplay[Pages.POLICIES].title}
                    goBack={PageDisplay[Pages.POLICIES].path}
                />
        }
        {
            props.loading || !props.details ?
                (
                    <LoadingData loading={!!props.loading} />
                ) :
                (
                    <>
                        {props.details.content.businessRuleViolations.length > 0 &&
                                <ErrorDetail errors={props.details.content.businessRuleViolations} />
                        }
                        <Summary
                            hasActions
                            tableColumnData={[
                                {
                                    label: "Policy Number",
                                },
                                {
                                    label: "Status",
                                },
                                {
                                    label: "License Plate",
                                },
                                {
                                    label: "Chassis Number",
                                },
                                {
                                    label: "Product",
                                },
                                {
                                    label: "Policy Holder Name",
                                },
                                {
                                    label: "Policy Holder Number",
                                },
                                props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && props.asAudit !== true ?
                                    {
                                        label: "Actions",
                                    } :
                                    {
                                        label: "",
                                    },
                            ]}
                            tablerowData={[
                                {
                                    id: 1,
                                    data: [
                                        props.details.content.policyNumber ?? "",
                                        props.details.content.status ?
                                            (
                                                <Badge
                                                    theme={{
                                                        paletteState:
                                                                getPolicyStatusColor
                                                                (props.details.content.status),
                                                    }}
                                                >
                                                    {PolicyStatusDisplay[props.details.content.status]
                                                            ?? props.details.content.status}
                                                </Badge>
                                            ) : "",
                                        props.details.content.licensePlate
                                            ? licensePlate(props.details.content.licensePlate)
                                            : "",
                                        props.details.content.chassisNumber ?? "",
                                        props.details.content.extendedWarranty ? (
                                            <NavLink
                                                to={`/extendedwarranties/${props.details.content.extendedWarranty.id}`}
                                                target="_blank"
                                            >
                                                <span className="scl-a-link scl-b-h scl-b-h6">
                                                    <Icon name="eye" />
                                                            &nbsp;&nbsp;
                                                    {props.details.content.extendedWarranty.name}
                                                </span>
                                            </NavLink>
                                        ) : "",
                                        props.details.content.policyHolder ?
                                            props.details.content.policyHolder.name : "",
                                        props.details.content.policyHolder ? (
                                            <NavLink
                                                to={`/partners/${props.details.content.policyHolder.id}`}
                                                target="_blank"
                                            >
                                                <span className="scl-a-link scl-b-h scl-b-h6">
                                                    <Icon name="eye" />
                                                            &nbsp;&nbsp;
                                                    {props.details.content.policyHolder?.partnerNumber}
                                                </span>
                                            </NavLink>
                                        ) : props.details.content.partnerNumber,
                                        props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && props.asAudit !== true ?
                                            (
                                                <ButtonsWrapper
                                                    alignment={Alignment.RIGHT}
                                                    asGroup
                                                    orientations={{
                                                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                                                    }}
                                                >
                                                    <NavLink
                                                        to={`/policies/${props.params.policyId}/edit`}
                                                    >
                                                        <Button
                                                            label="Correct"
                                                            className="scl-a-btn--tiny"
                                                            theme={{
                                                                palette: ThemePalette.CONTRAST_PRIMARY,
                                                                shadow: ThemeShadowSizes.TINY,
                                                            }}
                                                        />
                                                    </NavLink>
                                                    {props.details.content.status
                                                            !== PolicyStatus.CANCELED && (
                                                        <Button
                                                            label="Cancel"
                                                            className="scl-a-btn--tiny"
                                                            theme={{
                                                                paletteState: ThemePaletteState.DANGER,
                                                                shadow: ThemeShadowSizes.TINY,
                                                            }}
                                                            link={{
                                                                onClick: () =>
                                                                    props.openCancelRequest?.(
                                                                        props.details.content.id.toString() ?? ""),
                                                            }}
                                                        />)}
                                                </ButtonsWrapper>
                                            ) : "",
                                    ],
                                }]}
                        />
                        {props.asAudit !== true &&
                            <Tabs
                                tabs={SubPageDisplay[SubPages.POLICY_DETAIL].tabs}
                                defaultTab={PageTabs.POLICIES_GENERAL}
                                params={{
                                    policyNumber: props.details.content.policyNumber,
                                    policyId: props.params.policyId,
                                }}
                            />}
                    </>
                )
        }
    </>
);
