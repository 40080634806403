/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames, Icon } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { CoverageLevelDisplay } from "../../../../../store/Policies/Displays";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Tariffs } from "../../../../../store/Tariffs/Types";
import { ThemeSize, ThemePalette, ThemeShadowSizes,
    ThemeBreakpoints, ThemePaletteState } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { DeleteTariffModal } from "./DeleteTariffModal";
import { DetailModal } from "./DetailModal";


/**
 *
 */
interface OwnProps {
    results: Tariffs;
    asAuditTab?: boolean;
}

/**
 *
 */
interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openTariffDetail: (id: number) => void;
    openDeleteRequest: (id: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

export const OpenDetailRequest = (id: number) => (
    () => (
        <Modal
            modalID={id.toString()}
            theme={{ size: ThemeSize.SMALL }}
        >
            <DetailModal
                modalID={id.toString()}
                tariffId={id}
            />
        </Modal>
    )
);

export const OpenDeleteRequest = (id: string) => (
    () => (
        <Modal
            modalID={id}
            theme={{ size: ThemeSize.SMALL }}
        >
            <DeleteTariffModal
                modalID={id}
                tariffId={id}
            />
        </Modal>
    )
);

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.TARIFF_RESULTS, {
        skip: skip,
    })),
    openTariffDetail: (id: number) => {
        dispatch(
            modalShow(
                id.toString(),
                OpenDetailRequest(id),
            ),
        );
    },
    openDeleteRequest: (id: string) => dispatch(
        modalShow(
            id,
            OpenDeleteRequest(id),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openDelete = this.openDelete.bind(this);
        this.openDetail = this.openDetail.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.passengerVehicle && row.commercialVehicle
                    ? "Passenger and Commerical" : row.commercialVehicle ? "Commercial" : "Passenger",
                row.maxKW,
                CoverageLevelDisplay[row.coverageLevel],
                row.model ? row.model.name : "",
                row.tariffPeriod,
                (
                    <h6 key={`tariff-premium-${index}`}>
                        <Currency amount={row.premium} />
                    </h6>
                ),
                this.props.asAuditTab ? (
                    <NavLink to={`/audit/tariffs/${row.id}`}>
                        <Button
                            icon={IconNames.ARROW_RIGHT}
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        />
                    </NavLink>
                ) :
                    (
                        <ButtonsWrapper
                            asGroup
                            alignment={Alignment.RIGHT}
                            orientations={{
                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                            }}
                        >
                            <Button
                                icon={IconNames.VIEW}
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                                link={{ onClick: this.openDetail(row.id) }}
                            />
                            {this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <>
                                <NavLink to={`/tariffs/${row.id}/edit`}>
                                    <Button
                                        label="Edit"
                                        className="scl-a-btn--tiny"
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                                <Button
                                    theme={{
                                        paletteState: ThemePaletteState.DANGER,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    className="scl-a-btn--tiny"
                                    link={{
                                        onClick: this.openDelete(row.id.toString()),
                                    }}
                                >
                                    <Icon name={IconNames.DELETE} />
                                </Button></>}
                        </ButtonsWrapper>
                    ),
            ],
        }));
    }

    private openDetail(id: number) {
        return () => this.props.openTariffDetail(id);
    }

    private openDelete(id: string) {
        return () => this.props.openDeleteRequest(id);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Type",
                },
                {
                    label: "Max KW",
                },
                {
                    label: "Coverage Level",
                },
                {
                    label: "Model",
                },
                {
                    label: "Period",
                },
                {
                    label: "Premium",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
