/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { GLRequest as CreateGLType, GLDetails, canSubmitGLForm } from "../../../../../store/GeneralLedgers/Types";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { isRemoteLoading, ReduxState } from "../../../../../store/ReduxState";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../../store/AppDisplays";
import { updateGL, clearGL } from "../../../../../store/GeneralLedgers/Create";
import { remoteTrigger, remoteClearResponse } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Container } from "../../../../../components/core/03-base/Container";
import { Label } from "../../../../../components/core/05-atoms/Label";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";

interface OwnProps {
    edit: boolean;
    gLId: string;
}

interface StateProps {
    ledger: CreateGLType;
    resultsSuccess: GLDetails;
    loadingLedger: boolean;
    canSubmitGLForm: boolean;
}

interface DispatchProps {
    createGL: (ledger: CreateGLType) => void;
    editGL: (ledger: CreateGLType) => void;
    onChange: (ledger: CreateGLType) => void;
    clearGL: () => void;
    loadGLDetail: (id: string) => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingLedger: isRemoteLoading(s, RemoteScope.GENERAL_LEDGER_DETAILS),
    canSubmitGLForm: canSubmitGLForm(s.prop("gLRequest")),
    ledger: s.prop("gLRequest").map(e => ({
        ...e,
    })),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.GENERAL_LEDGER_CREATE)
            || s.prop("remote").prop(RemoteScope.GENERAL_LEDGER_EDIT)) as GLDetails,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    createGL: (ledger: CreateGLType) => dispatch(remoteTrigger(RemoteScope.GENERAL_LEDGER_CREATE, {
        ledger: ledger,
    })),
    editGL: (ledger: CreateGLType) => dispatch(remoteTrigger(RemoteScope.GENERAL_LEDGER_EDIT, {
        ledger: ledger,
    })),
    onChange: (ledger: CreateGLType) => dispatch(updateGL(ledger)),
    clearGL: () => {
        dispatch(clearGL());
        dispatch(remoteClearResponse(RemoteScope.GENERAL_LEDGER_CREATE));
        dispatch(remoteClearResponse(RemoteScope.GENERAL_LEDGER_EDIT));
    },
    loadGLDetail: (id: string) => dispatch(remoteTrigger(RemoteScope.GENERAL_LEDGER_DETAILS, {
        id: id.toString(),
    })),
});

/**
 *
 */
export class CreateGLComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.createGL = this.createGL.bind(this);
        this.editGL = this.editGL.bind(this);
    }

    public componentDidMount() {
        this.props.clearGL();
        if (this.props.edit) {
            this.props.loadGLDetail(this.props.gLId);
        }
    }

    public componentWillUnmount() {
        this.props.clearGL();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {this.props.edit ? (
                        <Header
                            title={SubPageDisplay[SubPages.GENERAL_LEDGER_EDIT].title}
                            goBack={"/generalledgers"}
                        />
                    ) : (
                        <Header
                            title={SubPageDisplay[SubPages.GENERAL_LEDGER_CREATE].title}
                            goBack={PageDisplay[Pages.GENERAL_LEDGER].path}
                        />
                    )}
                    {this.props.loadingLedger
                        ? (
                            <LoadingData loading={this.props.loadingLedger} />
                        )
                        : (<>
                            {this.props.resultsSuccess && (
                                <Redirect to={"/generalledgers"} />
                            )}
                            <Form onSubmit={this.props.edit ? this.editGL : this.createGL}>
                                <Div
                                    theme={{
                                        shadow: ThemeShadowSizes.TINY,
                                        margin: {
                                            "": { t: 2 },
                                            "sm": { t: 3 },
                                        },
                                    }}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("name", value ? value.toString() : undefined)}
                                                    value={this.props.ledger.name}
                                                    label="Name"
                                                    required
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value,
                                                                "Name provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("accountCode", value ? value.toString() : undefined)}
                                                    value={this.props.ledger.accountCode}
                                                    label="GL Account code"
                                                    required
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value,
                                                                "GL Account code provided")}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Container
                                                theme={{
                                                    padding: {
                                                        "": { b: 3 },
                                                        "sm": { b: 4 },
                                                    },
                                                }}
                                            >
                                                <Label>
                                                    {"Free booking"}
                                                </Label>
                                                <Div>
                                                    <Checkbox
                                                        checked={this.props.ledger.freebookingOnClaimInvoice ?? false}
                                                        onChange={(value: boolean | undefined) => this.onChange(
                                                            "freebookingOnClaimInvoice",
                                                            !!value ? true : false)}
                                                        label={"Claim invoice"}
                                                    />
                                                </Div>
                                                <Div>
                                                    <Checkbox
                                                        checked={this.props.ledger.freebookingOnPolicyInvoice ?? false}
                                                        onChange={(value: boolean | undefined) => this.onChange(
                                                            "freebookingOnPolicyInvoice",
                                                            !!value ? true : false)}
                                                        label={"Policy Invoice"}
                                                    />
                                                </Div>
                                                <Div>
                                                    <Checkbox
                                                        checked={this.props.ledger.freebookingOnCollectiveInvoice ?? false}
                                                        onChange={(value: boolean | undefined) => this.onChange(
                                                            "freebookingOnCollectiveInvoice",
                                                            !!value ? true : false)}
                                                        label={"Collective Invoice"}
                                                    />
                                                </Div>
                                                <Div>
                                                    <Checkbox
                                                        checked={this.props.ledger.freebookingOnPartner ?? false}
                                                        onChange={(value: boolean | undefined) => this.onChange(
                                                            "freebookingOnPartner",
                                                            !!value ? true : false)}
                                                        label={"Partner"}
                                                    />
                                                </Div>
                                            </Container>
                                        </Div>
                                        <Required />
                                    </Container>
                                </Div>
                                <FormBar
                                    buttonLabel={this.props.edit ? "Update" : "Create"}
                                    title={this.props.edit ? "Edit general ledger" : "Create general ledger"}
                                    isValid={!!this.props.canSubmitGLForm ? this.props.canSubmitGLForm : undefined}
                                    hasRequiredFields
                                />
                            </Form>
                        </>)}
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof CreateGLType>(key: K, value: CreateGLType[K]) => {
        this.props.onChange({
            [key]: value,
        });
    };

    private createGL() {
        this.props.createGL(this.props.ledger);
    }

    private editGL() {
        this.props.editGL(this.props.ledger);
    }
}

/**
 *
 */
export const CreateGL = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateGLComp);
