import { State } from "../../utils/redux/State";
import { Brand } from "../Brand/Types";
import { hasRequiredFields } from "../Product/Types";
import { RefObject, RequestObject } from "../RemoteTypes";

export interface Model {
    id: number;
    createdAt?: string;
    name: string;
    description: string;
    brand: Brand;
}

export interface Models extends RequestObject {
    content: Model[];
}

export interface ModelDetails extends RequestObject {
    content: Model;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type ModelFilters = Readonly<Partial<{
    name: string;
}>>;

export interface ModelRequest {
    id?: number;
    name?: string;
    description?: string;
    brand?: RefObject;
}

export const canSubmitModelForm =
    (modelRequest: State<ModelRequest>): boolean => {
        const validMandatoryModelFields =
        modelRequest.map(f => hasRequiredFields(
            f,
            ["name", "brand"],
        ));
        return (
            validMandatoryModelFields
        );
    };
