import * as React from "react";

import { ThemeBreakpoints, ThemePalette } from "../../../../theme/_Types";
import * as I18n from "../../../../utils/translations/I18n";
import { Container } from "../../03-base/Container";
import { FlexColumn } from "../../03-base/Flex/Column";
import { FlexRow } from "../../03-base/Flex/Row";
import { FillUp } from "../../04-helpers/FillUp";
import { Button } from "../../05-atoms/Button";
import { IconNames } from "../../05-atoms/Icon";
import { Alignment, ButtonsWrapper, Orientation } from "../../06-molecules/ButtonsWrapper";
import { Navbar } from "../../07-organisms/Navbar";
import { NavbarIcon } from "../../07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "../../07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../07-organisms/Navbar/Title";
import { TabType, Tabs } from "../00-blocks/Tabs";
import { ChangePassword } from "./AccountSettings/Tabs/ChangePassword";
import { General, UserProps } from "./AccountSettings/Tabs/General";

/**
 *
 */
interface OwnProps extends UserProps {
    id: string;
    closeModal: (id: string) => void;
    onLogout: (b?: boolean) => void;
}

/**
 *
 */
type Props = OwnProps;

/**
 *
 */
export class ModalAccountSettings
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    /**
     *
     */
    public render() {
        const tabs: TabType[] = [{
            key: "GENERAL",
            title: I18n.getText("mary.09-views.03-modals.accountsettings.tabs.general.title",
                "General") ?? "",
            description: "",
            component: General,
        },
        {
            key: "CHANGE_PASSWORD",
            title:  I18n.getText("mary.09-views.03-modals.accountsettings.tabs.changepassword.title",
                "Change password") ?? "",
            description: "",
            component: ChangePassword,
        }];
        return (
            <>
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {I18n.getText(
                                        "mary.09-views.03-modals.accountsettings.title",
                                        "Account settings")}
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button
                                        className={"scl-o-navbar__icon"}
                                        icon={IconNames.TIMES}
                                        link={{
                                            onClick: this.closeModal(),
                                        }}
                                    />
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                    isScrollable
                >

                    <FillUp>
                        <Container
                            theme={{
                                padding: {
                                    "": { y: 1 },
                                    "md": { y: 2 },
                                },
                            }}
                        >
                            <Tabs
                                tabs={tabs}
                                defaultTab={tabs[0]}
                                params={{
                                    user: this.props.user,
                                    onLogout: this.props.onLogout,
                                }}
                            />
                        </Container>
                    </FillUp>
                </FlexRow>
                <FlexRow isFixed>
                    <FlexColumn
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <Container
                            theme={{
                                padding: { "": { y: 3 } },
                            }}
                        >
                            <ButtonsWrapper
                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL}}
                                alignment={Alignment.RIGHT}>
                                <React.Fragment>
                                    <Button
                                        theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                        link={{ onClick: this.closeModal() }}
                                    >
                                        {I18n.getText(
                                            "mary.close",
                                            "Close")}
                                    </Button>
                                </React.Fragment>
                            </ButtonsWrapper>
                        </Container>
                    </FlexColumn>
                </FlexRow>
            </>
        );
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.id);
    }
}
