import * as React from "react";
import { connect } from "react-redux";
import { ClaimFilters } from "../../../../../store/Claims/Types";
import { RemoteScope } from "../../../../../store/RemoteTypes";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Div } from "../../../../../components/core/03-base/Div";
import { ThemePalette } from "../../../../../theme/_Types";
import { hasLicensePlateValidation,
    hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { isValidLicensePlate, formatLicensePlate, parseLicenseplate } from "../../../../../utils/LicensePlate";


/**
 *
 */
type setFilterFn = (filters: Partial<ClaimFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof ClaimFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: ClaimFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: ClaimFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.CLAIMS, p.defaultFilters),
    filters: getFilters(s, FilterScope.CLAIMS) as ClaimFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.CLAIMS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.CLAIMS));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.CLAIM_RESULTS, {
        skip: 0,
    })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.CLAIM_RESULTS)),
        dispatch(remoteClearError(RemoteScope.CLAIM_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.claimNumberValidationMessage = this.claimNumberValidationMessage.bind(this);
        this.claimNumberPAHValidationMessage = this.claimNumberPAHValidationMessage.bind(this);
        this.claimDateValidationMessage = this.claimDateValidationMessage.bind(this);
        this.chassisNumberValidationMessage = this.chassisNumberValidationMessage.bind(this);
        this.licensePlateValidationMessage = this.licensePlateValidationMessage.bind(this);
        this.policyNumberValidationMessage = this.policyNumberValidationMessage.bind(this);
        this.onChangeClaimNumber = this.onChangeClaimNumber.bind(this);
        this.onChangeClaimNumberPAH = this.onChangeClaimNumberPAH.bind(this);
        this.onChangeClaimDate = this.onChangeClaimDate.bind(this);
        this.onChangeChassisNumber = this.onChangeChassisNumber.bind(this);
        this.onChangeLicensePlate = this.onChangeLicensePlate.bind(this);
        this.onChangePolicyNumber = this.onChangePolicyNumber.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Claim number"
                            onChange={this.onChangeClaimNumber}
                            value={this.props.filters?.claimNumber ?? ""}
                            validationFunction={this.claimNumberValidationMessage}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Claim number PAH"
                            onChange={this.onChangeClaimNumberPAH}
                            value={this.props.filters?.claimNumberPAH ?? ""}
                            validationFunction={this.claimNumberPAHValidationMessage}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Chassis number"
                            onChange={this.onChangeChassisNumber}
                            value={this.props.filters?.chassisNumber ?? ""}
                            validationFunction={this.chassisNumberValidationMessage}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            type="licensePlate"
                            label="License plate"
                            onChange={this.onChangeLicensePlate}
                            value={isValidLicensePlate((this.props.filters?.licensePlate))
                                ? formatLicensePlate((this.props.filters?.licensePlate))
                                : (this.props.filters?.licensePlate)?.toUpperCase()
                            }
                            validationFunction={
                                (value: string | number) =>
                                    hasLicensePlateValidation(value,
                                        "License plate provided") ?? ""}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Claim date"
                            placeholder="e.g. 2020/11/01"
                            onChange={this.onChangeClaimDate}
                            type="date"
                            value={this.props.filters?.claimDate ?? ""}
                            validationFunction={this.claimDateValidationMessage}
                        />
                    </Grid>
                    {!(this.props.defaultFilters && this.props.defaultFilters.policyNumber) &&
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <FormField
                                label="Policy Number"
                                onChange={this.onChangePolicyNumber}
                                value={
                                    this.props.defaultFilters?.policyNumber ? this.props.defaultFilters.policyNumber
                                        : this.props.filters?.policyNumber}
                                disabled={!!(this.props.defaultFilters && this.props.defaultFilters.policyNumber)}
                                readonly={!!(this.props.defaultFilters && this.props.defaultFilters.policyNumber)}
                                validationFunction={this.policyNumberValidationMessage}
                            />
                        </Grid>
                    }
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private claimNumberValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Claim number provided",
        );
    }

    private onChangeClaimNumber(value?: string | number) {
        return this.onChange("claimNumber", value?.toString() ?? "");
    }

    private claimNumberPAHValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Claim number pah provided",
        );
    }

    private onChangeClaimNumberPAH(value?: string | number) {
        return this.onChange("claimNumberPAH", value?.toString() ?? "");
    }

    private claimDateValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Claim date provided",
        );
    }

    private onChangeClaimDate(value?: string | number) {
        return this.onChange("claimDate", value?.toString() ?? "");
    }

    private chassisNumberValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Chassis number provided",
        );
    }

    private onChangeChassisNumber(value?: string | number) {
        return this.onChange("chassisNumber", value?.toString() ?? "");
    }

    private licensePlateValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "License plate provided",
        );
    }

    private onChangeLicensePlate(value?: string | number) {
        return this.onChange("licensePlate",  isValidLicensePlate(value?.toString())
            ? parseLicenseplate(value?.toString())
            ?? (value?.toString().toUpperCase() ?? "")
            : (value?.toString().toUpperCase() ?? ""));
    }

    private policyNumberValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Policy number provided",
        );
    }

    private onChangePolicyNumber(value?: string | number) {
        return this.onChange("policyNumber", value?.toString() ?? "");
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof ClaimFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof ClaimFilters>(key: K, value: ClaimFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);


