import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { ProductRequest, ProductTariffRequests } from "./Types";
import { RemoteScope } from "../RemoteTypes";
import { State } from "../../utils/redux/State";

type InitUpdateProductProxyAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_PRODUCT_PROXY> & {
}
>;

export const initUpdateProductProxy =
    (): InitUpdateProductProxyAction => ({
        type: ActionTypeKeys.INIT_UPDATE_PRODUCT_PROXY,
    });

/**
 *
 */
export const initUpdateProductProxyReducer:
ReducerFn<InitUpdateProductProxyAction> =
    (s, _a) => {
        const proxyResults = s.prop("remote").prop(RemoteScope.PROXIES)?.content;
        const hasOnlyOneProxy = proxyResults?.length === 1;
        return (
            s.setProp(
                "productRequest",
                s.prop("productRequest").updateWith({
                    proxy: hasOnlyOneProxy && proxyResults ? {id: proxyResults[0].id} : undefined,
                }),
            )
        );
    };

/**
 *
 */
export type InitUpdateProductAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_PRODUCT> & {
}
>;

/**
 *
 *
 */
export const initUpdateProduct =
    (): InitUpdateProductAction => ({
        type: ActionTypeKeys.INIT_UPDATE_PRODUCT,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateProductReducer:
ReducerFn<InitUpdateProductAction> =
    (s, _a) => {
        const productDetails = s.prop("remote").prop(RemoteScope.PRODUCT_DETAILS);
        const productRequestData: State<ProductRequest> =
            State.create<ProductRequest>(() => productDetails ? productDetails.content : {});
        return (
            s.setProp(
                "productRequest",
                productRequestData,
            )
        );
    };

/**
 *
 */
export type UpdateProductAction = Readonly<
Action<ActionTypeKeys.UPDATE_PRODUCT> & {
    product?: Partial<ProductRequest>;
}
>;

/**
 *
 *
 */
export const updateProduct =
    (product: ProductRequest): UpdateProductAction => ({
        type: ActionTypeKeys.UPDATE_PRODUCT,
        product: product,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateProductReducer:
ReducerFn<UpdateProductAction> =
    (s, a) => (
        s.setProp(
            "productRequest",
            s.prop("productRequest").updateWith(
                a.product ? a.product : {},
            ),
        )
    );

/**
 *
 */
export type CreateTariffAction = Readonly<
Action<ActionTypeKeys.CREATE_TARIFF> & {
    tariff: ProductTariffRequests;
    index: number;
}
>;

/**
 *
 *
 */
export const createTariff =
    (tariff: ProductTariffRequests, index: number): CreateTariffAction => ({
        type: ActionTypeKeys.CREATE_TARIFF,
        tariff: tariff,
        index: index,
    });

/**
 *
 * @param s
 * @param a
 */
export const createTariffReducer:
ReducerFn<CreateTariffAction> =
    (s, a) => {
        const tariffs = s.prop("productRequest").prop("productTariffs");
        return (
            s.setProp(
                "productRequest",
                s.prop("productRequest")
                    .setProp("productTariffs",
                        tariffs ? tariffs.map((tariff, index) => {
                            if (index === a.index) {
                                return { ...tariff, ...a.tariff };
                            } else {
                                return tariff;
                            }
                        }) : [a.tariff],
                    ),
            ));
    };

/**
 *
 */
export type AddTariffAction = Readonly<
Action<ActionTypeKeys.ADD_TARIFF> & {
}
>;

export const addTariff =
    (): AddTariffAction => ({
        type: ActionTypeKeys.ADD_TARIFF,
    });

export const addTariffReducer:
ReducerFn<AddTariffAction> =
    (s, _a) => {
        const tariffs = s.prop("productRequest").prop("productTariffs");
        const emptyTariffObject = {
        };
        return (
            s.setProp(
                "productRequest",
                s.prop("productRequest")
                    .setProp("productTariffs",
                        tariffs ? [...tariffs, emptyTariffObject] : [emptyTariffObject],
                    ),
            ));
    };

/**
 *
 */
export type DeleteTariffAction = Readonly<
Action<ActionTypeKeys.DELETE_TARIFF> & {
    index: number;
}
>;

export const deleteTariff =
    (index: number): DeleteTariffAction => ({
        type: ActionTypeKeys.DELETE_TARIFF,
        index: index,
    });

export const deleteTariffReducer:
ReducerFn<DeleteTariffAction> =
    (s, a) => {
        const tariffs = s.prop("productRequest").prop("productTariffs");
        return (
            s.setProp(
                "productRequest",
                s.prop("productRequest")
                    .setProp("productTariffs",
                        tariffs ? tariffs.filter((tariff, index) =>
                            index !== a.index,
                        ) : [],
                    ),
            ));
    };

/**
 *
 */
export type ClearProductAction = Readonly<
Action<ActionTypeKeys.CLEAR_PRODUCT>
>;

/**
 *
 * @param keys
 */
export const clearProduct:
() => ClearProductAction =
    () => ({
        type: ActionTypeKeys.CLEAR_PRODUCT,
    });

/**
 *
 */
export const clearProductReducer:
ReducerFn<ClearProductAction> =
    (s, _a) => (
        s.setProp(
            "productRequest",
            State.create<ProductRequest>(() => ({})),
        )
    );
