import { AxiosResponse } from "axios";
import { Policies, PolicyDetails, PolicyFilters } from "./Types";

/**
 *
 * @param resp
 */
export const mapPolicyRequests =
    (resp: AxiosResponse): Policies => resp.data as Policies;

/**
 *
 * @param resp
 */
export const mapPolicyDetailsRequests =
    (resp: AxiosResponse): PolicyDetails => resp.data as PolicyDetails;

/**
 *
 */
export const PolicyFiltersToParams = (f: PolicyFilters) => ({
    ...f,
});
