import React from "react";
import { Navbar } from "../../../components/core/07-organisms/Navbar";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../components/core/07-organisms/Navbar/Title";
import { ThemePalette } from "../../../theme/_Types";


interface OwnProps {
    totalElements?: number;
    totalPages?: number;
    currentPage?: number;
}

/**
 *
 */
type Props = OwnProps;

export const ResultCountBar: React.FunctionComponent<Props> = props => {
    const totalElements = !props.totalElements || props.totalElements === 0
        ? undefined
        : `Total results: ${props.totalElements}`;
    const currentPage = (!props.currentPage && props.currentPage !== 0)
        ? undefined
        : props.currentPage + 1;
    const pageStats = !currentPage && !props.totalPages
        ? undefined
        : `Page: ${currentPage ?? ""}/${props.totalPages ?? ""}`;

    if (!pageStats && !totalElements) {
        return null;
    }
    return (
        <Navbar theme={{palette: ThemePalette.CONTRAST_SECONDARY}} className="scl-h-text-align--left">
            <NavbarStack>
                <NavbarStackContent>
                    <NavbarTitle>{pageStats}</NavbarTitle>
                </NavbarStackContent>
                <NavbarStackRight>
                    <NavbarTitle>{totalElements}</NavbarTitle>
                </NavbarStackRight>
            </NavbarStack>
        </Navbar>
    );
};
