import React from "react";
import { Div } from "../../../../components/core/03-base/Div";
import { ColumnData, RowData, Table } from "../../../../components/core/06-molecules/Table";
import { ThemeShadowSizes, ThemePalette } from "../../../../theme/_Types";


interface OwnProps {
    tableColumnData: ColumnData[];
    tablerowData: RowData[];
    hasActions?: boolean;
    noPadding?: boolean;
}

type Props = OwnProps;

export const Summary: React.FC<Props> = (props) => (
    <Div theme={!!props.noPadding ? {} : { padding: { "": { b: 2, t: 2 }, "sm": { b: 3, t: 3 } } }}>
        <Div theme={{ shadow: ThemeShadowSizes.TINY, palette: ThemePalette.CONTRAST_PRIMARY }}>
            <Table
                hasActions={props.hasActions}
                data={{
                    columns: props.tableColumnData,
                    rows: props.tablerowData,
                }}
            />
        </Div>
    </Div>
);
