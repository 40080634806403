import { CreditDebitIndicator, BookedObjectType } from "./Types";

/**
 *
 */
export const CreditDebitIndicatorTypeDisplay: Readonly<Record<CreditDebitIndicator, string>> = {
    [CreditDebitIndicator.CRDT]: "Credit",
    [CreditDebitIndicator.DBIT]: "Debit",
};

/**
 *
 */
export const BookedObjectTypeDisplay: Readonly<Record<BookedObjectType, string>> = {
    [BookedObjectType.CLAIM_INVOICE]: "Claim Invoice",
    [BookedObjectType.COLLECTIVE_INVOICE]: "Collective Invoice",
    [BookedObjectType.EXCASSO]: "Excasso",
    [BookedObjectType.INCASSO]: "Incasso",
    [BookedObjectType.PARTNER_INVOICE]: "Partner Invoice",
    [BookedObjectType.GENERAL_LEDGER_INVOICE]: "General Ledger Invoice",
};
