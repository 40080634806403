import React from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../../../../store/ReduxState";
import { RouteComponentProps } from "react-router-dom";
import { CreateProduct } from "./CreateProduct";

interface Params {
    ewId: string;
}

interface StateProps {
    params: Params;
}

type Props = StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
});

/**
 *
 * @param s
 */

/**
 *
 */
export class EditProductComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <CreateProduct edit ewId={this.props.params.ewId} />
        );
    }
}

/**
 *
 */
export const EditProduct = connect(
    mapStateToProps,
    {},
)((props: Props) => <EditProductComp {...props} />);
