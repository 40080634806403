import { Action } from "redux";
import { State } from "../../utils/redux/State";

import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { ManualBooking, ManualBookingTypes, ManualBookings } from "./Types";

/**
 *
 */
export type UpdateManualBookingAction = Readonly<
Action<ActionTypeKeys.UPDATE_MANUAL_BOOKING> & {
    manualBookings?: ManualBooking[];
    description?: string;
    bookingDate?: string;
}
>;

/**
 *
 *
 */
export const updateManualBooking =
    (manualBookings?: ManualBooking[], description?: string, bookingDate?: string):
    UpdateManualBookingAction => ({
        type: ActionTypeKeys.UPDATE_MANUAL_BOOKING,
        manualBookings: manualBookings,
        description: description,
        bookingDate: bookingDate,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateManualBookingReducer:
ReducerFn<UpdateManualBookingAction> =
    (s, a) => {
        const manualBookingItems = s.prop("manualBooking").prop("freeBookingItems");
        const selectedBookings = a.manualBookings ? a.manualBookings : [];
        const hasManualBooking = manualBookingItems
            ? manualBookingItems.some((manualBookingItem) =>
                selectedBookings && selectedBookings.filter((mB) => (manualBookingItem.objectId === mB.objectId &&
                        manualBookingItem.objectType === mB.objectType)).length > 0) : false;

        return (
            s.setProp(
                "manualBooking",
                s.prop("manualBooking").updateWith(
                    {
                        freeBookingItems: (manualBookingItems && hasManualBooking ?
                            [...manualBookingItems.filter((manualBookingItem) =>
                                selectedBookings && !selectedBookings.some((mB) =>
                                    manualBookingItem.objectId === mB.objectId &&
                            manualBookingItem.objectType === mB.objectType)), ...selectedBookings] :  manualBookingItems
                                ? [...manualBookingItems, ...selectedBookings] : [...selectedBookings])
                            .filter(mB => mB.amount !== 0),
                        description: a.description === undefined ?
                            s.prop("manualBooking").prop("description") : a.description,
                        bookingDate: a.bookingDate === undefined ?
                            s.prop("manualBooking").prop("bookingDate") : a.bookingDate,
                    },
                ),
            )
        );
    };

export type DeleteManualBookingSelectionAction = Readonly<
Action<ActionTypeKeys.DELETE_MANUAL_BOOKING_SELECTION> & {
    manualBookings?: ManualBooking[];
}
>;

export const deleteManualBookingSelection =
(manualBookings?: ManualBooking[]):
DeleteManualBookingSelectionAction => ({
    type: ActionTypeKeys.DELETE_MANUAL_BOOKING_SELECTION,
    manualBookings: manualBookings,
});

export const deleteManualBookingSelectionReducer:
ReducerFn<DeleteManualBookingSelectionAction> =
(s, a) => {
    const manualBookingItems = s.prop("manualBooking").prop("freeBookingItems");
    const selectedBookings = a.manualBookings ? a.manualBookings : [];
    return (
        s.setProp(
            "manualBooking",
            s.prop("manualBooking").updateWith(
                {
                    freeBookingItems: manualBookingItems?.filter((manualBookingItem) =>
                        selectedBookings && !selectedBookings.some((mB) =>
                            manualBookingItem.objectId === mB.objectId &&
                        manualBookingItem.objectType === mB.objectType)),
                    description: s.prop("manualBooking").prop("description"),
                    bookingDate: s.prop("manualBooking").prop("bookingDate"),
                },
            ),
        )
    );
};
/**
 *
 */
export type DeleteManualBookingAction = Readonly<
Action<ActionTypeKeys.DELETE_MANUAL_BOOKING> & {
    id: number;
    bookingType: ManualBookingTypes;
}
>;

export const deleteManualBooking =
(id: number, bookingType: ManualBookingTypes): DeleteManualBookingAction => ({
    type: ActionTypeKeys.DELETE_MANUAL_BOOKING,
    id: id,
    bookingType: bookingType,
});

export const deleteManualBookingReducer:
ReducerFn<DeleteManualBookingAction> =
(s, a) => {
    const manualBookingItems = s.prop("manualBooking").prop("freeBookingItems");
    return (
        s.setProp(
            "manualBooking",
            s.prop("manualBooking")
                .setProp("freeBookingItems",
                    manualBookingItems ? manualBookingItems.filter((manualBookingItem) =>
                        !(manualBookingItem.objectId === a.id &&
                manualBookingItem.objectType === a.bookingType),
                    ) : [],
                ),
        ));
};

/**
 *
 */
export type ClearManualBookingsAction = Readonly<
Action<ActionTypeKeys.CLEAR_MANUAL_BOOKINGS>
>;

/**
 *
 * @param keys
 */
export const clearManualBookings:
() => ClearManualBookingsAction =
    () => ({
        type: ActionTypeKeys.CLEAR_MANUAL_BOOKINGS,
    });

/**
 *
 */
export const clearManualBookingsReducer:
ReducerFn<ClearManualBookingsAction> =
    (s, _a) => (
        s.setProp(
            "manualBooking",
            State.create<ManualBookings>(() => ({})),
        )
    );
