import * as React from "react";
import { connect } from "react-redux";

import { ManualBookings } from "../../../../../../store/ManualBooking/Types";
import { updateManualBooking } from "../../../../../../store/ManualBooking/CreateType";
import { ReduxState } from "../../../../../../store/ReduxState";
import { FlexColumn } from "../../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../../components/core/04-helpers/FillUp";
import { IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../../theme/_Types";
import { modalClose } from "../../../../../../utils/redux/ActionTypes";
import { FormTextArea } from "../../../../../../components/core/07-organisms/Form/TextArea";
import { hasNotExceedLengthValidation } from "../../../../../../components/core/07-organisms/Form/Field.utils";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Container } from "../../../../../../components/core/03-base/Container";

/**
 *
 */
interface OwnProps {
    modalID: string;
    onBookConfirmed: () => void;
}

/**
 *
 */
interface StateProps {
    manualBooking: ManualBookings;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    updateModalContent: (description?: string, date?: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    manualBooking: s.mapProp("manualBooking", (item) => ({
        freeBookingItems: item.prop("freeBookingItems"),
        bookingDate: item.prop("bookingDate"),
        description: item.prop("description"),
    })),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    updateModalContent: (description?: string, date?: string) =>
        dispatch(updateManualBooking(undefined, description, date)),
});

export const confirmBooking = (props: Props) => () => {
    props.onBookConfirmed();
    props.closeModal(props.modalID);
};

export const closeModal = (props: Props) => () => props.closeModal(props.modalID);

/**
 *
 * @param props
 */
const ConfirmManualBookingModalComp: React.FunctionComponent<Props> = (props) => (
    <FlexColumn
    >
        <FlexRow isFixed>
            <Navbar
                theme={{
                    palette: ThemePalette.CONTRAST_TERTIARY,
                }}
            >
                <NavbarStack>
                    <NavbarStackContent>
                        <NavbarTitle>
                            Confirm free booking
                        </NavbarTitle>
                    </NavbarStackContent>
                    <NavbarStackRight>
                        <NavbarIcon>
                            <Button
                                icon={IconNames.TIMES}
                                link={{
                                    onClick: closeModal(props),
                                }}/>
                        </NavbarIcon>
                    </NavbarStackRight>
                </NavbarStack>
            </Navbar>
        </FlexRow>
        <FlexRow
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
            }}
        >
            <FlexColumn>
                <FlexRow isScrollable >
                    <FillUp>
                        <Container
                            theme={{
                                padding: {
                                    "": { y: 3 },
                                    "md": { y: 4 },
                                },
                            }}
                        >
                            <>
                                    You are about to do a free booking.
                                <br /><br />
                                <FormTextArea
                                    label="Description"
                                    required
                                    placeholder="e.g. Booked today"
                                    onChange={(value?: string | number) =>
                                        props.updateModalContent(
                                            value ? value.toString() : "", props.manualBooking.bookingDate)}
                                    value={props.manualBooking.description}
                                    validationFunction={
                                        (value: string | number) =>
                                            hasNotExceedLengthValidation(value,
                                                "Booking description provided", 255)}
                                />
                                <FormField
                                    label="Booking date"
                                    required
                                    placeholder="e.g. 2020-01-31"
                                    onChange={(value?: string | number) =>
                                        props.updateModalContent(
                                            props.manualBooking.description, value ? value.toString() : "")}
                                    value={props.manualBooking.bookingDate}
                                    type="date"
                                />
                                <Required /><br /><br />
                                <b>Are you sure you want to continue?</b>
                            </>
                        </Container>
                    </FillUp>
                </FlexRow>
            </FlexColumn>
        </FlexRow>
        <FlexRow isFixed>
            <FlexColumn
                theme={{
                    palette: ThemePalette.CONTRAST_SECONDARY,
                }}
            >
                <Container
                    theme={{
                        padding: { "": { y: 3 } },
                    }}
                >
                    <ButtonsWrapper
                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                        alignment={Alignment.RIGHT}
                    >
                        <Button
                            theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                            link={{ onClick: closeModal(props) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                            link={{ onClick: confirmBooking(props) }}
                            disabled={!props.manualBooking.bookingDate || !props.manualBooking.description}
                        >
                            Yes
                        </Button>
                    </ButtonsWrapper>
                </Container>
            </FlexColumn>
        </FlexRow>
    </FlexColumn >
);

/**
 *
 */
export const ConfirmManualBookingModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmManualBookingModalComp);
