import * as React from "react";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { Form } from "../../../../../../components/core/07-organisms/Form";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { FormTextArea } from "../../../../../../components/core/07-organisms/Form/TextArea";
import { CoverageLevelDisplay } from "../../../../../../store/Policies/Displays";
import { Policy } from "../../../../../../store/Policies/Types";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";
import { licensePlate } from "../../../../../../utils/FormatHelpers";


interface Props {
    general: Policy;
}
/**
 *
 * @param props
 */
export const GeneralView: React.FC<Props> = (props: Props) => (
    <React.Fragment>
        <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
            <Container
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    padding: {
                        "": { y: 3 },
                        "sm": { y: 4 },
                    },
                }}
            >
                <Form>
                    <FieldSet title="Policy info">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Policy number"
                                    value={props.general.policyNumber}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="License plate"
                                    value={props.general.licensePlate ? licensePlate(props.general.licensePlate) : ""}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Chassis number"
                                    value={props.general.chassisNumber}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Start Date Factory Warranty"
                                    type="date"
                                    value={props.general.startDateFactoryWarranty}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="End Date Factory Warranty"
                                    type="date"
                                    value={props.general.endDateFactoryWarranty}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Duration"
                                    value={props.general.duration}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Start Date Extended Warranty"
                                    type="date"
                                    value={props.general.startDateExtendedWarranty}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Receiving date"
                                    type="date"
                                    value={props.general.receivingDate
                                        ? props.general.receivingDate : "n.a."}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Max kilometers"
                                    type="date"
                                    value={props.general.maxMileage}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Proxy"
                                    value={props.general.proxy?.name ?? ""}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Contract type (PAH)"
                                    value={props.general.contractTypePah}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                    {props.general.policyHolder &&
                            <FieldSet title="Policy holder">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Name"
                                            value={props.general.policyHolder.name}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Partner number"
                                            value={props.general.policyHolder.partnerNumber}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Partner number (PAH)"
                                            value={props.general.policyHolder.partnerNumberPah}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Address line 1"
                                            value={props.general.policyHolder.addressLine1}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Address line 2"
                                            value={props.general.policyHolder.addressLine2}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Zip code"
                                            value={props.general.policyHolder.zipCode}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="House number"
                                            value={props.general.policyHolder.houseNumber}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="House number addition"
                                            value={props.general.policyHolder.houseNumberAddition}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Residence"
                                            value={props.general.policyHolder.residence}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                    }
                    <FieldSet title="Reporting">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Soa name"
                                    value={props.general.soaName}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Product code VWFS"
                                    value={props.general.productCodeVWFS}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Coverage code VWFS"
                                    value={CoverageLevelDisplay[props.general.coverageCodeVWFS] as string}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                    <FieldSet title="Terms and conditions">
                        <FormTextArea
                            label=""
                            value={props.general.termsAndConditions}
                            asSummary
                        />
                    </FieldSet>
                </Form>
            </Container>
        </Div>
    </React.Fragment>
);
