/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { Models, ModelFilters, ModelDetails, Model } from "./Types";

/**
 *
 */
export const ModelFiltersToParams = (f: ModelFilters) => ({
    ...f,
});

/**
 *
 * @param resp
 */
export const mapModelRequests =
    (resp: AxiosResponse): Models => resp.data as Models;

/**
 *
 * @param resp
 */
export const mapModelDetailsRequests =
(resp: AxiosResponse): ModelDetails => resp.data as ModelDetails;

export const mapModelsPerBrandRequests =
    (resp: AxiosResponse): Models => {
        const requests = (resp.data.content ? resp.data.content[0]?.models as Model[] : []);

        const models = {
            content: requests,
            totalPages: 1,
            totalElements: requests.length,
            number: 1,
        };

        return models;
    };
