import * as React from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { PolicyDetails } from "../../../../../../store/Policies/Types";
import { ProductView } from "../component/ProductView";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";

/**
 *
 */
interface StateProps {
    formData: PolicyDetails;
}

/**
 *
 */
type Props = StateProps;

/**
 *
 * @param props
 */
export class ProductComp
    extends React.Component<Props, {}> {
    public constructor(props: Props) {
        super(props);
    }

    /**
     *
     */
    public static mapStateToProps(s: ReduxState): StateProps {
        return {
            formData: s.prop("remote").prop(RemoteScope.POLICY_DETAILS) as PolicyDetails,
        };
    }


    public render() {
        const product = this.props.formData &&
                        this.props.formData.content &&
                        this.props.formData.content.extendedWarranty ?
            this.props.formData.content.extendedWarranty : undefined;
        return (
            product ? (
                <ProductView product={product}/>
            ) : (
                <LoadingData loading={false}/>
            )
        );
    }
}

/**
 *
 */
export const Product = connect(
    ProductComp.mapStateToProps,
    {},
)(ProductComp);
