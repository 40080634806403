import * as React from "react";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { Form } from "../../../../../../components/core/07-organisms/Form";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { Policy } from "../../../../../../store/Policies/Types";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";
import { licensePlate } from "../../../../../../utils/FormatHelpers";


interface Props {
    general: Policy;
}
/**
 *
 * @param props
 */
export const VehicleView: React.FC<Props> = (props: Props) => (
    <React.Fragment>
        <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
            <Container
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    padding: {
                        "": { y: 3 },
                        "sm": { y: 4 },
                    },
                }}
            >
                <Form>
                    <FieldSet title="Vehicle info">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Brand"
                                    value={props.general.brand}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Model"
                                    value={props.general.model}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Vehicle Type"
                                    value={props.general.vehicleType}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="License Plate"
                                    value={props.general.licensePlate ? licensePlate(props.general.licensePlate) : ""}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Chassis Number"
                                    value={props.general.chassisNumber}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Power"
                                    value={`${props.general.power} kW`}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Start kilometers"
                                    value={props.general.mileage}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                </Form>
            </Container>
        </Div>
    </React.Fragment>
);
