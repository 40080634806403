/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { MemoDetails, Memos, Memo } from "./Types";

export const mapMemoRequests =
    (resp: AxiosResponse): Memos => {
        const requests = (resp.data.content ? resp.data.content as Memo[]
            : resp.data as Memo[]);

        const memos = {
            content: requests,
            totalPages: 1,
            totalElements: requests.length,
            number: 1,
        };

        return memos;
    };

/**
 *
 * @param resp
 */
export const mapMemoDetailsRequests =
    (resp: AxiosResponse): MemoDetails => resp.data as MemoDetails;
