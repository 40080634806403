/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";

import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { StatementDetails, CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { ConfirmBookingModal } from "../Accounts/Statements/ConfirmBookingModal";
import { Incassos, BookIncasso } from "../../../../../store/Incassos/Types";
import { FileDownload } from "../../../../../store/Files/Types";
import Moment from "react-moment";
import { DownloadFileProps } from "../Files/Results";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ThemeSize, ThemePalette, ThemeBreakpoints } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { calcUnclearedBalanceAfter, calcIsValid, amIChecked } from "../../../../utils/Booking";
import { canDownloadFile } from "../../../../utils/FileDownload";
import { getPaidStatusColor } from "../../../../utils/FormatHelpers";
import { DownloadButtonCell } from "../../../06-molecules/DownloadButtonCell";
import { BookBar } from "../../../07-organisms/BookBar";
import { Container } from "../../../../../components/core/03-base/Container";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

export interface SelectedRow {
    id: number;
    amount: number;
}

interface State {
    selectedRows: SelectedRow[];
}

/**
 *
 */
interface StateProps extends DownloadFileProps {
    details: StatementDetails;
}

/**
 *
 */
interface OwnProps {
    results: Incassos;
    statementLineId?: string;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    book: (id: string, patch: BookIncasso) => void;
    openBookRequest: (statementLineId: string, callBack: () => void) => void;
    loadDownload: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

export const OpenBookReq =
    (statementLineID: string, callBack: () => void) => (
        () => (
            <Modal
                modalID={statementLineID}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmBookingModal
                    modalID={statementLineID}
                    statementLineId={statementLineID}
                    onBookConfirmed={callBack}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    details: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementDetails,
    loadingFileInitDownload: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    loadingFileDownload: isRemoteLoading(s, RemoteScope.FILE_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.INCASSO_RESULTS, {
        skip: skip,
    })),
    book: (id: string, patch: BookIncasso) => (
        dispatch(remoteTrigger(RemoteScope.INCASSO_BOOK_PATCH, {
            patch: patch,
            id: id,
        }))
    ),
    openBookRequest: (statementLineId: string, callBack: () => void) => dispatch(
        modalShow(
            statementLineId,
            OpenBookReq(statementLineId, callBack),
        ),
    ),
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, { id: id })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {


    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.confirmedBooking = this.confirmedBooking.bind(this);
        this.loadDownload = this.loadDownload.bind(this);
        this.bookBarCallBack = this.bookBarCallBack.bind(this);
        this.confirmedBookingCB = this.confirmedBookingCB.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        const statementLine = this.props.details
            && this.props.details.content.entries
            && this.props.details.content.entries.length > 0 ? this.props.details.content.entries.find(line =>
                line.id.toString() === this.props.statementLineId) : undefined;

        const selectedBalance = this.state.selectedRows.map(row => (- row.amount))
            .reduce(
                (cur, next) => cur + next, 0);

        if (!statementLine) {
            return null;
        }

        const unclearedBalance = statementLine.balance;

        const unclearedBalanceAfter = calcUnclearedBalanceAfter(unclearedBalance, selectedBalance,
            statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT);

        const isValid = calcIsValid(this.state.selectedRows.length, unclearedBalance, selectedBalance);

        return (
            <Form onSubmit={this.bookBarCallBack(statementLine.id)} isValid={isValid}>
                <BookBar
                    title={"Booking Incassos"}
                    selectedBalance={selectedBalance}
                    unclearedBalanceAfter={unclearedBalanceAfter}
                    unclearedBalance={unclearedBalance}
                    isValid={isValid}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </Form>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const isLoading = ((this.props.fileDownloadResult && this.props.fileDownloadResult.id === row.id)
                && this.props.loadingFileDownload);
            return ({
                id: row.id,
                data: [
                    (
                        <div
                            key={`selection-${row.id}`}
                            className="scl-row-selection"
                            style={{
                                display: "inline-flex",
                                flexDirection: "row",
                                flexGrow: 0,
                                alignItems: "center",
                            }}
                        >
                            <div
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                <Checkbox
                                    checked={amIChecked(row.id, this.state.selectedRows)}
                                    onChange={(value?: boolean) => this.selectItem(value, row.id
                                        , row.controlSum)}
                                />
                            </div>
                        </div>
                    ),

                    row.id,
                    row.messageName, (
                        <Moment
                            date={new Date(row.creationDateTime)}
                            format={"DD-MM-YYYY HH:mm:ss"}
                            key={`date${index}`}
                        />),
                    // "",
                    (
                        <Badge
                            key={`badge-${row.id}`}
                            theme={{ paletteState: getPaidStatusColor(row.status) }}>
                            {row.status?.toString() ?? "n.a."}</Badge>
                    ), (
                        <h6
                            key={`h6-${row.id}`}
                        >
                            <Currency amount={row.controlSum} />
                        </h6>
                    ),
                    (<ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <DownloadButtonCell
                            key={`download-cell-${row.id}`}
                            isLoading={isLoading}
                            loadDownload={this.loadDownload(row.id)}
                            canDownloadFile={canDownloadFile(this.props)}
                        /></ButtonsWrapper>
                    ),
                ],
            });
        });
    }

    private bookBarCallBack(id: number) {
        return () => this.props.openBookRequest(id.toString(), this.confirmedBookingCB(id));
    }

    private confirmedBookingCB(id: number) {
        return () => this.confirmedBooking(id);
    }

    private loadDownload(id: number) {
        return () => this.props.loadDownload(id);
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Number",
                },
                {
                    label: "File name",
                },
                {
                    label: "Date",
                },
                {
                    label: "Status",
                },
                {
                    label: "Balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: [],
        };
    }

    private selectItem(value: boolean | undefined, id: number, amount: number) {
        this.setState({
            selectedRows: !!value ? [{
                id: id,
                amount: amount,
            }] : [],
        });
    }

    private confirmedBooking = (statementLineId: number) => {
        if (this.state.selectedRows.length > 0) {
            this.props.book(this.props.details.content.id.toString(), {
                entryId: statementLineId,
                paidObjectId: this.state.selectedRows[0].id,
                paidAmount: this.state.selectedRows[0].amount,
            });
        }
    };
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
