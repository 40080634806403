/**
 *
 */
export enum CognitoAttributeLabel {
    USERNAME = "username",
    EMAIL = "email",
    EMAIL_VERIFIED = "email_verified",
    USER_ROLE = "custom:role",
}

export type CognitoAttributes = Readonly<Record<CognitoAttributeLabel, string>>;

export enum UserRoles {
    EW_ADMIN = "insurance_administrator",
    EW_ACCOUNTING = "accounting_employee",
    EW_ADMIN_READONLY = "read_only",
    EW_DEBTOR = "read_only_accounting_employee",
    NONE = "none",
}

export const roleFromAttributes:
(attr: CognitoAttributes) => UserRoles =
    (a) => {
        const role = a[CognitoAttributeLabel.USER_ROLE].toUpperCase();

        const userRole = Object.keys(UserRoles).filter(key => UserRoles[key] === role)[0];
        if (userRole) {
            return UserRoles[userRole] as UserRoles;
        } else {
            throw new Error("Claude can't find any role with the correct privileges in this application");
        }
    };

export type UserInfo = Readonly<{
    token?: () => Promise<string | undefined>;
    username?: string;
    userDisplay?: string;
    userRole?: UserRoles;
    email?: string;
}>;

export enum PaidStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
}
