import { RequestObject } from "../RemoteTypes";
import { Amount, CreditDebitIndicator } from "../Statements/Types";
import { Partner } from "../Partners/Types";
import { ClaimInvoiceStatus } from "../ClaimMatch/Types";
import { InvoicePayment } from "../CollectiveInvoice/Types";
import { State } from "../../utils/redux/State";
import { hasValue } from "../Product/Types";

interface GeneralLedgerInvoice {
    id: number;
    createdAt: string;
    objectType: string;
    invoiceNumber: number;
    amount: Amount;
    balance: number;
    invoiceAmount: number;
    invoiceDate: string;
    creditDebitIndicator: CreditDebitIndicator;
    status: ClaimInvoiceStatus;
    partner: Partner;
    payments: InvoicePayment[];
    generalLedger: any; // check
}

export interface GeneralLedger {
    id: number;
    createdAt: string;
    accountCode: string;
    name: string;
    freebookingOnClaimInvoice: boolean;
    freebookingOnPolicyInvoice: boolean;
    freebookingOnCollectiveInvoice: boolean;
    freebookingOnPartner: boolean;
    invoices: GeneralLedgerInvoice[]; // new
}

export interface GeneralLedgers extends RequestObject {
    content: GeneralLedger[];
}

export interface GLDetails {
    content: GeneralLedger;
}

export interface GLRequest {
    id?: number;
    name?: string;
    accountCode?: string;
    freebookingOnClaimInvoice?: boolean;
    freebookingOnPolicyInvoice?: boolean;
    freebookingOnCollectiveInvoice?: boolean;
    freebookingOnPartner?: boolean;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type GeneralLedgerFilters = Readonly<Partial<{
    accountCode: string;
}>>;

export interface BookLedger {
    entryId: number;
    paidObjectId: number;
    paidAmount: number;
    description?: string;
}

export const canSubmitGLForm =
    (ledger: State<GLRequest>): boolean => {
        const validMandatoryGLFields = ledger.map(f => {
            const mandatoryGLField = Object.keys(f).filter(k =>
                (k === "accountCode" && hasValue(f[k])) ||
                (k === "name" && hasValue(f[k])));

            return mandatoryGLField;
        }).length === 2;
        return validMandatoryGLFields;
    };
