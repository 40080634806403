import { Action } from "redux";
import { State } from "../../utils/redux/State";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { ProductTariffRequests, hasRequiredFields } from "./Types";

/**
 *
 */
export type AddProductTariffAction = Readonly<
Action<ActionTypeKeys.ADD_PRODUCT_TARIFF> & {
    producttariff?: Partial<ProductTariffRequests>;
}
>;

/**
 *
 *
 */
export const addProductTariff =
    (producttariff: ProductTariffRequests): AddProductTariffAction => ({
        type: ActionTypeKeys.ADD_PRODUCT_TARIFF,
        producttariff: producttariff,
    });

/**
 *
 * @param s
 * @param a
 */
export const addProductTariffReducer:
ReducerFn<AddProductTariffAction> =
    (s, a) => (
        s.setProp(
            "productTariffRequest",
            s.prop("productTariffRequest").updateWith(
                a.producttariff ? a.producttariff : {},
            ),
        )
    );

/**
 *
 */
export type ClearProductTariffAction = Readonly<
Action<ActionTypeKeys.CLEAR_PRODUCT_TARIFF>
>;

export const clearProductTariff:
() => ClearProductTariffAction =
    () => ({
        type: ActionTypeKeys.CLEAR_PRODUCT_TARIFF,
    });

/**
 *
 */
export const clearProductTariffReducer:
ReducerFn<ClearProductTariffAction> =
    (s, _a) => (
        s.setProp(
            "productTariffRequest",
            State.create<ProductTariffRequests>(() => ({})),
        )
    );

export const canSubmitAddProductTariffForm =
    (productTariff: State<ProductTariffRequests>): boolean => {
        const validMandatoryProductTariffFields =
            productTariff.map(f => hasRequiredFields(
                f,
                ["startingDate", "endingDate", "durationOfInsurance", "tariffLine"],
            ));
        return (
            validMandatoryProductTariffFields
        );
    };
