import React from "react";

interface Props {
    active?: boolean;
}

export const Spinner: React.FunctionComponent<Props> = props => (
    <div className={`scl-m-spinner ${!!props.active ? "scl-m-spinner--active" : ""}`}>
        <div className="scl-m-spinner__loader" />
    </div>
);
