
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { Label } from "../../../../../components/core/05-atoms/Label";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasNotExceedLengthValidation,
    hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";
import { updateBrand, clearBrand } from "../../../../../store/Brand/CreateType";
import { BrandRequest, BrandDetails, canSubmitBrandForm } from "../../../../../store/Brand/Types";
import { Models } from "../../../../../store/Models/Types";
import { Products } from "../../../../../store/Product/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

interface OwnProps {
    edit: boolean;
    brandId: string;
}

interface StateProps {
    brand: BrandRequest;
    loadingFormHelpers: boolean;
    formHelpers: {
        modelResults?: Models;
        productResults?: Products;
    };
    canSubmitBrandForm: boolean;
    resultsSuccess: BrandDetails;
}

interface DispatchProps {
    updateBrand: (brand: BrandRequest) => void;
    clear: () => void;
    loadFormHelpers: () => void;
    loadBrandDetailResults: (id: string) => void;
    addBrand: (brand: BrandRequest) => void;
    editBrand: (brand: BrandRequest) => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    brand: getBrand(s),
    loadingFormHelpers:
            isRemoteLoading(s, RemoteScope.MODEL_RESULTS) ||
            isRemoteLoading(s, RemoteScope.PRODUCT_RESULTS),
    formHelpers: {
        modelResults: s.prop("remote").prop(RemoteScope.MODEL_RESULTS),
        productResults: s.prop("remote").prop(RemoteScope.PRODUCT_RESULTS),
    },
    canSubmitBrandForm: canSubmitBrandForm(s.prop("brandRequest")),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.BRAND_CREATE)
            || s.prop("remote").prop(RemoteScope.BRAND_EDIT)) as BrandDetails,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateBrand: (brand: BrandRequest) => dispatch(updateBrand(brand)),
    clear: () => {
        dispatch(clearBrand());
        dispatch(remoteClearResponse(RemoteScope.MODEL_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.BRAND_CREATE));
        dispatch(remoteClearResponse(RemoteScope.BRAND_EDIT));
    },
    loadFormHelpers: () => {
        dispatch(remoteTrigger(RemoteScope.MODEL_RESULTS, { skip: 0 }));
        dispatch(remoteTrigger(RemoteScope.PRODUCT_RESULTS, { skip: 0 }));
    },
    addBrand: (brand: BrandRequest) => dispatch(remoteTrigger(RemoteScope.BRAND_CREATE, {
        brand: brand,
    })),
    editBrand: (brand: BrandRequest) => dispatch(remoteTrigger(RemoteScope.BRAND_EDIT, {
        brand: brand,
    })),
    loadBrandDetailResults: (id: string) => dispatch(remoteTrigger(RemoteScope.BRAND_DETAILS, { id: id })),
});

/**
 *
 * @param s
 */
export const getBrand =
    (s: ReduxState): BrandRequest =>
        s.prop("brandRequest").map(e => ({
            ...e,
        }));

/**
 *
 */
export class CreateBrandComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.addBrand = this.addBrand.bind(this);
        this.editBrand = this.editBrand.bind(this);
    }

    public componentDidMount() {
        this.props.clear();
        this.props.loadFormHelpers();
        if (this.props.edit) {
            this.props.loadBrandDetailResults(this.props.brandId);
        }
    }

    public componentWillUnmount() {
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {this.props.edit ? (
                        <Header
                            title={SubPageDisplay[SubPages.BRAND_EDIT].title}
                            goBack={SubPageDisplay[SubPages.MANAGE_BRANDS].path}
                        />
                    ) : (
                        <Header
                            title={SubPageDisplay[SubPages.BRAND_CREATE].title}
                            goBack={SubPageDisplay[SubPages.MANAGE_BRANDS].path}
                        />
                    )}
                    {this.props.loadingFormHelpers ||
                        (
                            !this.props.formHelpers.modelResults ||
                            !this.props.formHelpers.productResults)
                        ? (
                            <LoadingData loading={this.props.loadingFormHelpers} />
                        )
                        : (<>
                            {this.props.resultsSuccess && (
                                <Redirect to={SubPageDisplay[SubPages.MANAGE_BRANDS].path} />
                            )}
                            <Form onSubmit={this.props.edit ? this.editBrand : this.addBrand}>
                                <Div
                                    theme={{
                                        shadow: ThemeShadowSizes.TINY,
                                        margin: {
                                            "": { t: 2 },
                                            "sm": { t: 3 },
                                        },
                                    }}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Name"
                                                    required
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("name", value ? value.toString() :
                                                            "")}
                                                    value={this.props.brand.name}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value.toString(),
                                                                "Name provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Coverage code (PAH)"
                                                    required
                                                    onChange={(value?: string | number) =>
                                                        // eslint-disable-next-line max-len
                                                        this.onChange("coverageCodeForPah", value ? value.toString() : "")}
                                                    value={this.props.brand.coverageCodeForPah}
                                                    max={15}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasNotExceedLengthValidation(value,
                                                                "Coverage code (PAH) provided", 15)}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Description"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("description", value ? value.toString() :
                                                            "")}
                                                    value={this.props.brand.description}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value.toString(),
                                                                "Description provided")}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Brand code VWFS"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("brandPartVWFS", value ? value.toString() :
                                                            "")}
                                                    value={this.props.brand.brandPartVWFS}
                                                    max={255}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasNotExceedLengthValidation(value,
                                                                "Brand code VWFS provided", 255)}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Container
                                                theme={{
                                                    padding: {
                                                        "": { b: 3 },
                                                        "sm": { b: 4 },
                                                    },
                                                }}
                                            >
                                                <Label>
                                                    {"Vehicle type"}
                                                </Label>
                                                <Checkbox
                                                    checked={this.props.brand.passengerVehicles ?? false}
                                                    onChange={(value: boolean | undefined) => this.onChange(
                                                        "passengerVehicles",
                                                        !!value ? true : false)}
                                                    label={"Private"}
                                                />
                                                <Checkbox
                                                    checked={this.props.brand.commercialVehicles ?? false}
                                                    onChange={(value: boolean | undefined) => this.onChange(
                                                        "commercialVehicles",
                                                        !!value ? true : false)}
                                                    label={"Commercial"}
                                                />
                                            </Container>
                                        </Div>
                                        <Required />
                                    </Container>
                                </Div>
                                <FormBar
                                    buttonLabel={this.props.edit ? "Update" : "Create"}
                                    title={this.props.edit ? "Edit brand" : "Create brand"}
                                    isValid={!!this.props.canSubmitBrandForm ?
                                        this.props.canSubmitBrandForm : undefined}
                                />
                            </Form>
                        </>
                        )}
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof BrandRequest>(key: K, value: BrandRequest[K]) => {
        this.props.updateBrand({
            [key]: value,
        });
    };

    private addBrand() {
        this.props.addBrand(this.props.brand);
    }

    private editBrand() {
        this.props.editBrand(this.props.brand);
    }
}

/**
 *
 */
export const CreateBrand = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateBrandComp);
