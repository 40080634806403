import React from "react";
import { Policy } from "../../../../../../../../store/Policies/Types";
import { PremiumView } from "../../../../../00-blocks/Policies/component/PremiumView";

interface Props {
    policy: Policy;
}

export const Premium: React.FC<Props> = (props: Props) => (
    <PremiumView formData={{ content: props.policy }} asAudit />
);
