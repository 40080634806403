import * as React from "react";
import { Collapse } from "react-collapse";
import { ThemePalette, ThemeProps } from "../../../theme/_Types";
import { Div } from "../03-base/Div";
import { Button } from "../05-atoms/Button";
import { Navbar } from "./Navbar";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "./Navbar/Stack";
import { NavbarTitle } from "./Navbar/Title";

/**
 *
 */
interface OwnProps {
    title: string | React.ReactElement;
    collapsable?: boolean;
    defaultCollapsed?: boolean;
}

interface State {
    expand: boolean;
}

/**
 *
 */
type Props = OwnProps & ThemeProps;

/**
 *
 * @param props
 */
export class Card
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = {
            expand: !props.defaultCollapsed,
        };

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    public render() {
        return (
            <React.Fragment>
                <Navbar
                    theme={{
                        palette: this.props.theme ? this.props.theme.palette : ThemePalette.CONTRAST_TERTIARY,
                    }}
                    className="scl-h-text-align--left"
                >
                    <NavbarStack>
                        <NavbarStackContent>
                            <NavbarTitle>
                                {this.props.title}
                            </NavbarTitle>
                        </NavbarStackContent>
                        {!!this.props.collapsable && <NavbarStackRight>
                            <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                                <div
                                    style={{
                                        transition: "transform .3s ease",
                                        transform: this.state.expand ? "rotate(-90deg)" : "rotate(0deg)",
                                    }}
                                >
                                    <Button
                                        link={{ onClick: this.toggleExpand }}
                                        icon={"chevron-left"}
                                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                    />
                                </div>
                            </Div>
                        </NavbarStackRight>}
                    </NavbarStack>
                </Navbar>
                <Collapse isOpened={this.state.expand}>
                    {this.props.children || <React.Fragment />}
                </Collapse>
            </React.Fragment>
        );
    }

    private toggleExpand() {
        this.setState({
            expand: !this.state.expand,
        });
    }
}
