import { ClaimInvoiceStatus } from "./Types";

/**
 *
 */
export const ClaimInvoiceStatusDisplay: Readonly<Record<ClaimInvoiceStatus, string>> = {
    [ClaimInvoiceStatus.PAID]: "Paid",
    [ClaimInvoiceStatus.UNPAID]: "Unpaid",
    [ClaimInvoiceStatus.PARTIALLY_PAID]: "Partially paid",
    [ClaimInvoiceStatus.PENDING]: "Pending",
    [ClaimInvoiceStatus.CANCELED]: "Canceled",
};
