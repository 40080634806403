import {
    Action,
    Store,
    applyMiddleware,
    compose,
    legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";

import { MaryActionTypeKeys } from "../utils/redux/ActionTypes";
import { withMaryReducers } from "../utils/redux/MaryReducer";
import { State } from "../utils/redux/State";
import { Reducer, Reducers } from "../utils/redux/StateReducers";
import { clearAccountReducer, createAccountReducer } from "./Accounts/Create";
import { ActionTypeKeys } from "./ActionTypes";
import { appInitReducer, signOutReducer, signedOutReducer, toggleSidebarReducer } from "./AppActions";
import { clearDescriptionReducer, updateDescriptionReducer } from "./BookingDescription";
import { clearBrandReducer, initUpdateBrandReducer, updateBrandReducer } from "./Brand/CreateType";
import { clearInExFormReducer, initUpdateInExFormReducer,
    updateInExFormReducer } from "./CollectiveInvoice/GenerateIncasso";
import { addPartnersSelectionCIReducer, clearCollectiveInvoiceFormReducer,
    clearPartnersCIReducer, deletePartnersSelectionCIReducer,
    deleteSinglePartnerCIReducer, updateCollectiveInvoiceFormReducer } from "./CollectiveInvoice/GenerateInvoice";
import { fileDownloadInitReducer } from "./Files/Actions";
import { clearFiltersReducer, updateFiltersReducer } from "./FilterActions";
import { clearGLReducer, initUpdateGLReducer, updateGLReducer } from "./GeneralLedgers/Create";
import { clearMandateReducer, initUpdateMandateReducer, updateMandateReducer } from "./Mandates/Create";
import {
    clearManualBookingsReducer,
    deleteManualBookingReducer,
    deleteManualBookingSelectionReducer,
    updateManualBookingReducer,
} from "./ManualBooking/CreateType";
import { clearMemoReducer, initUpdateMemoReducer, updateMemoReducer } from "./Memos/Create";
import { clearModelReducer, initUpdateModelReducer, updateModelReducer } from "./Models/CreateType";
import { clearPartnerReducer, initUpdatePartnerReducer, updatePartnerReducer } from "./Partners/Create";
import { clearInvoicePartnerFilterReducer } from "./Partners/FilterType";
import { clearPolicyReducer, initUpdatePolicyReducer,
    updateModelOnBrandChangeReducer, updatePolicyReducer } from "./Policies/EditPolicyTypes";
import { clearPolicyPremiumReducer, initUpdatePolicyPremiumReducer,
    updatePolicyPremiumReducer } from "./Policies/UpdatePolicyPremium";
import { addProductTariffReducer, clearProductTariffReducer } from "./Product/AddProductTariffTypes";
import {
    addTariffReducer,
    clearProductReducer,
    createTariffReducer,
    deleteTariffReducer,
    initUpdateProductProxyReducer,
    initUpdateProductReducer,
    updateProductReducer,
} from "./Product/CreateType";
import { ReduxState, ReduxStateInit } from "./ReduxState";
import {
    remoteCancelReducer,
    remoteClearErrorReducer,
    remoteClearResponseReducer,
    remoteErrorReducer,
    remoteResponseReducer,
    remoteTriggerReducer,
} from "./RemoteActions";
import { clearReportFormReducer, initUpdateReportFormReducer, updateReportFormReducer } from "./Reports/FormActions";
import { updateTariffAutoReducer } from "./Tariffs/CreateAuto";
import { clearTariffReducer, initUpdateTariffReducer, updateTariffReducer } from "./Tariffs/CreateType";
import { clearTariffModelFilterReducer } from "./Tariffs/FilterType";

// DO NOT TOUCH - WE KNOW WHAT WE ARE DOING, I SWEAR
// https://github.com/palantir/tslint/issues/144#issuecomment-195453810
//
// TODO: the disabled lint may or may not be needed here.

/* eslint-disable */
declare let window: any;
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* esling-enable */

/**
 * Reducer defines the type signature of this reducer.
 */
type StoreConfig = Readonly<{
    apiEndpoint: string;
}>;

const rootReducer =
    withMaryReducers(
        Reducers
            .create<ActionTypeKeys | MaryActionTypeKeys, ReduxState>()
            .register(
                ActionTypeKeys.APP_INIT,
                appInitReducer as Reducer<ReduxState, Action<ActionTypeKeys.APP_INIT>>,
            )
            .register(
                ActionTypeKeys.UPDATE_FILTERS,
                updateFiltersReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_FILTERS,
                clearFiltersReducer,
            )
            .register(
                ActionTypeKeys.SIGN_OUT,
                signOutReducer,
            )
            .register(
                ActionTypeKeys.SIGNED_OUT,
                signedOutReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_TRIGGER,
                remoteTriggerReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CANCEL,
                remoteCancelReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_RESPONSE,
                remoteResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_RESPONSE,
                remoteClearResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_ERROR,
                remoteErrorReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_ERROR,
                remoteClearErrorReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_REPORT_FORM,
                updateReportFormReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_REPORT_FORM,
                initUpdateReportFormReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_REPORT_FORM,
                clearReportFormReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_COLLECTIVE_INVOICE_FORM,
                updateCollectiveInvoiceFormReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_COLLECTIVE_INVOICE_FORM,
                clearCollectiveInvoiceFormReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_INEX_FORM,
                updateInExFormReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_INEX_FORM>>,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_INEX_FORM,
                initUpdateInExFormReducer as Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_INEX_FORM>>,
            )
            .register(
                ActionTypeKeys.CLEAR_INEX_FORM,
                clearInExFormReducer,
            )
            .register(
                ActionTypeKeys.FILE_DOWNLOAD_INIT,
                fileDownloadInitReducer as Reducer<ReduxState, Action<ActionTypeKeys.FILE_DOWNLOAD_INIT>>,
            )
            .register(
                ActionTypeKeys.UPDATE_BOOK_DESCRIPTION,
                updateDescriptionReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_BOOK_DESCRIPTION,
                clearDescriptionReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_PRODUCT,
                updateProductReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_POLICY,
                updatePolicyReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_PRODUCT,
                clearProductReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_POLICY,
                clearPolicyReducer,
            )
            .register(
                ActionTypeKeys.CREATE_ACCOUNT,
                createAccountReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_ACCOUNT,
                clearAccountReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_GL,
                updateGLReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_GL,
                clearGLReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_PARTNER,
                updatePartnerReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_PARTNER,
                clearPartnerReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_MANDATE,
                updateMandateReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_MANDATE,
                clearMandateReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_MEMO,
                initUpdateMemoReducer as Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_MEMO>>,
            )
            .register(
                ActionTypeKeys.UPDATE_MEMO,
                updateMemoReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_MEMO,
                clearMemoReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_BRAND,
                initUpdateBrandReducer as Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_BRAND>>,
            )
            .register(
                ActionTypeKeys.UPDATE_BRAND,
                updateBrandReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_BRAND,
                clearBrandReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_MODEL,
                initUpdateModelReducer as Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_MODEL>>,
            )
            .register(
                ActionTypeKeys.UPDATE_MODEL,
                updateModelReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_MODEL,
                clearModelReducer,
            )
            .register(
                ActionTypeKeys.CREATE_TARIFF,
                createTariffReducer as Reducer<ReduxState, Action<ActionTypeKeys.CREATE_TARIFF>>,
            )
            .register(
                ActionTypeKeys.CLEAR_TARIFF,
                clearTariffReducer as Reducer<ReduxState, Action<ActionTypeKeys.CLEAR_TARIFF>>,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_PRODUCT,
                initUpdateProductReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_PRODUCT_PROXY,
                initUpdateProductProxyReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_POLICY,
                initUpdatePolicyReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_PREMIUM_POLICY,
                initUpdatePolicyPremiumReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_TARIFF,
                initUpdateTariffReducer,
            )
            .register(
                ActionTypeKeys.ADD_PRODUCT_TARIFF,
                addProductTariffReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_PRODUCT_TARIFF,
                clearProductTariffReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_GL,
                initUpdateGLReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_PARTNER,
                initUpdatePartnerReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_MANDATE,
                initUpdateMandateReducer,
            )
            .register(
                ActionTypeKeys.ADD_TARIFF,
                addTariffReducer as Reducer<ReduxState, Action<ActionTypeKeys.ADD_TARIFF>>,
            )
            .register(
                ActionTypeKeys.UPDATE_TARIFF,
                updateTariffReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_TARIFF>>,
            )
            .register(
                ActionTypeKeys.UPDATE_PREMIUM_POLICY,
                updatePolicyPremiumReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_PREMIUM_POLICY>>,
            )
            .register(
                ActionTypeKeys.CLEAR_PREMIUM_POLICY,
                clearPolicyPremiumReducer as Reducer<ReduxState, Action<ActionTypeKeys.CLEAR_PREMIUM_POLICY>>,
            )
            .register(
                ActionTypeKeys.UPDATE_TARIFF_AUTO,
                updateTariffAutoReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_PARTNERS_CI,
                addPartnersSelectionCIReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_PARTNERS_CI>>,
            )
            .register(
                ActionTypeKeys.DELETE_PARTNERS_CI,
                deletePartnersSelectionCIReducer as Reducer<ReduxState, Action<ActionTypeKeys.DELETE_PARTNERS_CI>>,
            )
            .register(
                ActionTypeKeys.CLEAR_PARTNERS_CI,
                clearPartnersCIReducer as Reducer<ReduxState, Action<ActionTypeKeys.CLEAR_PARTNERS_CI>>,
            )
            .register(
                ActionTypeKeys.DELETE_SINGLE_PARTNER_CI,
                deleteSinglePartnerCIReducer as Reducer<ReduxState, Action<ActionTypeKeys.DELETE_SINGLE_PARTNER_CI>>,
            )
            .register(
                ActionTypeKeys.DELETE_TARIFF,
                deleteTariffReducer as Reducer<ReduxState, Action<ActionTypeKeys.DELETE_TARIFF>>,
            )
            .register(
                ActionTypeKeys.UPDATE_MANUAL_BOOKING,
                updateManualBookingReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_MANUAL_BOOKING>>,
            )
            .register(
                ActionTypeKeys.DELETE_MANUAL_BOOKING,
                deleteManualBookingReducer as Reducer<ReduxState, Action<ActionTypeKeys.DELETE_MANUAL_BOOKING>>,
            )
            .register(
                ActionTypeKeys.CLEAR_MANUAL_BOOKINGS,
                clearManualBookingsReducer as Reducer<ReduxState, Action<ActionTypeKeys.CLEAR_MANUAL_BOOKINGS>>,
            )
            .register(
                ActionTypeKeys.CLEAR_TARIFF_MODEL_FILTER,
                clearTariffModelFilterReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_MODEL_ON_BRAND_CHANGE,
                updateModelOnBrandChangeReducer as Reducer<ReduxState, Action<ActionTypeKeys.UPDATE_MODEL_ON_BRAND_CHANGE>>,
            )
            .register(
                ActionTypeKeys.DELETE_MANUAL_BOOKING_SELECTION,
                deleteManualBookingSelectionReducer as Reducer<ReduxState,
                Action<ActionTypeKeys.DELETE_MANUAL_BOOKING_SELECTION>>,
            )
            .register(
                ActionTypeKeys.CLEAR_INVOICE_PARTNER_FILTER,
                clearInvoicePartnerFilterReducer,
            )
            .register(
                ActionTypeKeys.TOGGLE_SIDEBAR,
                toggleSidebarReducer,
            ),
    );

export const createReduxStore: (conf: StoreConfig) => Store<ReduxState> =
    conf => createStore<ReduxState, Action<ActionTypeKeys | MaryActionTypeKeys>, {}, {}>(
        (state, action) => rootReducer.reduce(state || State.create(ReduxStateInit(conf)), action),
        composeEnhancers(applyMiddleware(thunk)),
    );
