import * as React from "react";
import { Container } from "../../../../../components/core/03-base/Container";
import { PageDisplay, PageTabs, Pages, SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";

import { Tabs } from "../../00-blocks/Tabs";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

export const Create: React.FC = () => (
    <Page>
        <Container
            theme={{
                padding: {
                    "": { t: 3, b: 5 },
                    "sm": { t: 4, b: 6 },
                },
            }}
        >
            <Header
                title={SubPageDisplay[SubPages.TARIFF_CREATE].title}
                goBack={PageDisplay[Pages.TARIFFS].path}
            />
            <Tabs
                tabs={SubPageDisplay[SubPages.TARIFF_CREATE].tabs}
                defaultTab={PageTabs.TARIFF_CREATE_MANUAL}
            />
        </Container>
    </Page>
);
