import { AxiosResponse } from "axios";
import { Mandates, MandateDetails, MandateFilters } from "./Types";

export const mapMandateRequests =
    (resp: AxiosResponse): Mandates => resp.data as Mandates;

/**
 *
 * @param resp
 */
export const mapMandateDetailsRequests =
    (resp: AxiosResponse): MandateDetails => resp.data as MandateDetails;

/**
 *
 */
export const MandateFiltersToParams = (f: MandateFilters) => ({
    ...f,
});
