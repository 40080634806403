import { Action } from "redux";

import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { TariffRequest } from "./Types";

/**
 *
 */
export type UpdateTariffAutoAction = Readonly<
Action<ActionTypeKeys.UPDATE_TARIFF_AUTO> & {
    tariff?: Partial<TariffRequest>;
}
>;

/**
 *
 *
 */
export const updateTariffAuto =
    (tariff: Partial<TariffRequest>): UpdateTariffAutoAction => ({
        type: ActionTypeKeys.UPDATE_TARIFF_AUTO,
        tariff: tariff,
    });

export const calcCommVWPFS = (commission?: number) => {
    // export const calcCommVWPFS = (netPremium?: number, commission?: number) => {
    // NetPremium is not needed right now.
    // if (!!netPremium) {
    //     return +(netPremium * 10.28 / 100).toFixed(2);
    // }
    if (!!commission) {
        return commission;
    }
    return 0;
};

export const calcTax = (premium?: number, tax?: number) => {
    if (!!premium) {
        return +(premium - premium / 1.21).toFixed(2);
    }
    if (!!tax) {
        return tax;
    }
    return 0;
};

export const calcNetPremium = (tax: number, premium?: number, netPremium?: number) => {
    if (!!premium) {
        return +(premium - tax).toFixed(2);
    }
    if (!!netPremium) {
        return netPremium;
    }
    return 0;
};

/**
 *
 * @param s
 * @param a
 */
export const updateTariffAutoReducer:
ReducerFn<UpdateTariffAutoAction> =
    (s, a) => {
        const tax = calcTax(a.tariff?.premium, s.prop("tariffRequest").prop("tax"));
        const netPremium = calcNetPremium(tax, a.tariff?.premium, s.prop("tariffRequest").prop("netPremium"));
        const cost = a.tariff?.cost ? a.tariff?.cost : s.prop("tariffRequest").prop("cost");
        const netPremiumExCost = cost ? +(netPremium - cost).toFixed(2)
            : s.prop("tariffRequest").prop("netPremiumExCost") ?? 0;
        // eslint-disable-next-line max-len
        const commissionVWPFS = calcCommVWPFS(a.tariff?.commissionVWPFS ?? s.prop("tariffRequest").prop("commissionVWPFS") ?? 0);
        const netMij = +(netPremiumExCost - commissionVWPFS).toFixed(2);
        const frontier = +(netPremiumExCost * 3 / 100).toFixed(2);
        const insurer = +(netPremiumExCost - commissionVWPFS - frontier).toFixed(2);
        const signCommission = +(netPremiumExCost * 2.6 / 100).toFixed(2);
        const incassoProvision = +(commissionVWPFS - signCommission).toFixed(2);
        return s.setProp(
            "tariffRequest",
            s.prop("tariffRequest").updateWith({
                ...a.tariff,
                tax: tax,
                netPremium: netPremium,
                netPremiumExCost: netPremiumExCost,
                netMij: netMij,
                frontier: frontier,
                insurer: insurer,
                signCommitteeDamage: signCommission,
                incassoProvision: incassoProvision,
            }),
        );
    };
