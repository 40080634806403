
import * as React from "react";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Blockquote } from "../../../../../../components/core/05-atoms/Blockquote";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { LinkProps } from "../../../../../../components/core/05-atoms/Link";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { Card } from "../../../../../../components/core/07-organisms/Card";
import { ThemeBreakpoints, ThemePalette } from "../../../../../../theme/_Types";


interface OwnProps {
    title?: string;
    link: LinkProps;
}

/**
 *
 */
type Props = OwnProps;

export const Failed: React.FunctionComponent<Props> = props => (
    <Card
        title={"Generation Failed"}
        collapsable={false}
        defaultCollapsed={false}
    >
        <Container
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
                padding: {
                    "": { y: 3 },
                    "sm": { y: 4 },
                },
            }}
        >
            <Blockquote>
                <h4>
                    {props.title ? props.title : "Something went wrong"},
                </h4><br/>
                <div className="scl-h-text--big">We couldn&apos;t generate the report.</div>
                <br/>
                <div className="scl-h-text--big">
                        Claude suggests to continue and try again.
                </div>
            </Blockquote>
            <ButtonsWrapper
                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                alignment={Alignment.RIGHT}
            >
                <Button
                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                    className="scl-a-btn--big"
                    buttonType="button"
                    link={props.link}
                >
                        Continue
                        &nbsp;&nbsp;
                    <Icon name={IconNames.ARROW_RIGHT} />
                </Button>
            </ButtonsWrapper>
        </Container>
    </Card>
);
