import * as React from "react";


import { TariffDetails } from "../../../../../store/Tariffs/Types";
import { CoverageLevelDisplay } from "../../../../../store/Policies/Displays";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { FormTextArea } from "../../../../../components/core/07-organisms/Form/TextArea";

/**
 *
 */
interface OwnProps {
    tariffDetails?: TariffDetails;
}

/**
 *
 */
type Props = OwnProps;

export const DetailFields: React.FunctionComponent<Props> = props => {
    if (props.tariffDetails === undefined) {
        return null;
    }
    return (
        <>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Max KW"
                        asSummary
                        value={props.tariffDetails.content.maxKW}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Max kilometers"
                        asSummary
                        value={props.tariffDetails.content.maxMileage}
                        type="text"
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        value={props.tariffDetails.content.tariffPeriod}
                        label="Period"
                        asSummary
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Coverage level"
                        value={
                            CoverageLevelDisplay[
                                props.tariffDetails.content.coverageLevel
                            ]}
                        type="text"
                        asSummary
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Premium"
                        value={props.tariffDetails.content.premium}
                        type="currency"
                        asSummary
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Tax"
                        value={props.tariffDetails.content.tax}
                        type="currency"
                        asSummary
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Net premium"
                        type="currency"
                        asSummary
                        value={props.tariffDetails.content.netPremium}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Cost"
                        asSummary
                        value={props.tariffDetails.content.cost}
                        type="currency"
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        type="currency"
                        value={props.tariffDetails.content.netPremiumExCost}
                        label="Net Premium Ex Cost"
                        asSummary
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Commission VWPFS"
                        type="currency"
                        asSummary
                        value={props.tariffDetails.content.commissionVWPFS}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Net mij"
                        asSummary
                        value={props.tariffDetails.content.netMij}
                        type="currency"
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        type="currency"
                        value={props.tariffDetails.content.frontier}
                        label="Frontier"
                        asSummary
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Insurer"
                        type="currency"
                        asSummary
                        value={props.tariffDetails.content.insurer}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Sign Committee Damage"
                        asSummary
                        value={props.tariffDetails.content.signCommitteeDamage}
                        type="currency"
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        type="currency"
                        value={props.tariffDetails.content.incassoProvision}
                        label="Incasso Provision"
                        asSummary
                    />
                </Grid>
            </Div>
            <Div className="scl-b-row">
                <Grid
                    size={{ xs: 12, md: 4 }}
                >
                    <FormField
                        label="Model"
                        asSummary
                        value={props.tariffDetails.content.model?.name ?? ""}
                    />
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                >
                    <FormField
                        label="Vehicle Type"
                        asSummary
                        value={
                            props.tariffDetails.content.passengerVehicle &&
                                props.tariffDetails.content.commercialVehicle ? "Passenger and Commerical"
                                : props.tariffDetails.content.commercialVehicle ? "Commercial"
                                    : "Passenger"
                        }
                    />
                </Grid>
            </Div>
            {props.tariffDetails.content.description &&
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 12 }}
                    >
                        <FormTextArea
                            label="Description"
                            value={props.tariffDetails.content.description ?? ""}
                            asSummary
                        />
                    </Grid>
                </Div>
            }
        </>
    );
};
