import { RequestObject, RefObject } from "../RemoteTypes";
import { Partner } from "../Partners/Types";
import { hasRequiredFields } from "../Product/Types";
import { State } from "../../utils/redux/State";

export enum SequenceType {
    FRST = "FRST",
    RCUR = "RCUR",
    FNAL = "FNAL",
    OOFF = "OOFF",
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type MandateFilters = Readonly<Partial<{
    partnerId: number;
    mandateId: string;
}>>;

export interface Mandate {
    id: number;
    createdAt?: string;
    mandateId: string;
    iban: string;
    dateOfSignature: string;
    validFrom: string;
    validTo: string;
    sequenceType: SequenceType;
    partner: Partner;
}

export interface Mandates extends RequestObject {
    content: Mandate[];
}

export interface MandateDetails extends RequestObject {
    content: Mandate;
}

export interface MandateRequest {
    id?: number;
    mandateId?: string;
    iban?: string;
    dateOfSignature?: string;
    validFrom?: string;
    validTo?: string;
    sequenceType?: SequenceType;
    partner?: RefObject;
}

export const canSubmitMandateForm =
    (mandateRequest: State<MandateRequest>): boolean => {
        const validMandatoryMandateFields =
            mandateRequest.map(f => hasRequiredFields(
                f,
                ["mandateId", "validFrom", "sequenceType"],
            ));
        return (
            validMandatoryMandateFields
        );
    };
