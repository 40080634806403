/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import Moment from "react-moment";
import { Mandates } from "../../../../../store/Mandates/Types";
import { PageTabs } from "../../../../../store/AppDisplays";
import { MandateSequenceTypeDisplay } from "../../../../../store/Mandates/Displays";
import { UpdateMandateModal } from "../Partners/UpdateMandateModal";
import { DeleteMandateModal } from "./DeleteMandateModal";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePalette, ThemeBreakpoints, ThemeShadowSizes,
    ThemePaletteState } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";

/**
 *
 */
interface OwnProps {
    results: Mandates;
    currentTab?: PageTabs;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openMandateRequest: (edit: boolean, mandateId: string) => void;
    openDeleteRequest: (mandateId: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

export const OpenMandateReq =
    (edit: boolean, mandateId: string) => (
        () => (
            <Modal
                modalID={mandateId}
                theme={{ size: ThemeSize.SMALL }}
            >
                <UpdateMandateModal
                    modalID={mandateId}
                    mandateId={mandateId}
                    edit={edit}
                />
            </Modal>
        )
    );

export const OpenDelReq =
(mandateId: string) => (
    () => (
        <Modal
            modalID={mandateId}
            theme={{ size: ThemeSize.SMALL }}
        >
            <DeleteMandateModal
                modalID={mandateId}
                mandateId={mandateId}
            />
        </Modal>
    )
);

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.MANDATE_RESULTS, {
        skip: skip,
    })),
    openMandateRequest: (edit: boolean, mandateId: string) => dispatch(
        modalShow(
            mandateId,
            OpenMandateReq(edit, mandateId),
        ),
    ),
    openDeleteRequest: (mandateId: string) => dispatch(
        modalShow(
            mandateId,
            OpenDelReq(mandateId),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openDelReq = this.openDelReq.bind(this);
        this.openMandateReq = this.openMandateReq.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.mandateId,
                row.iban,
                (
                    <Moment
                        date={new Date(row.validFrom)}
                        format={"DD-MM-YYYY"}
                        key={`from-date${index}`}
                    />
                ),
                (
                    <Moment
                        date={new Date(row.validTo)}
                        format={"DD-MM-YYYY"}
                        key={`to-date${index}`}
                    />
                ),
                MandateSequenceTypeDisplay[row.sequenceType],
                (
                    <ButtonsWrapper
                        key={`button-wrapper-${row.id}`}
                        alignment={Alignment.RIGHT}
                        asGroup
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <Button
                            link={{ onClick: this.openMandateReq(true, row.id.toString()) }}
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            icon={IconNames.DELETE}
                            link={{ onClick: this.openDelReq(row.id.toString()) }}
                            theme={{
                                paletteState: ThemePaletteState.DANGER,
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        />
                    </ButtonsWrapper>
                ),
            ],
        }));
    }

    private openMandateReq(edit: boolean, id: string) {
        return () => this.props.openMandateRequest(true, id.toString());
    }

    private openDelReq(id: string) {
        return () => this.props.openDeleteRequest(id);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Mandate ID",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Valid from",
                },
                {
                    label: "Valid to",
                },
                {
                    label: "Sequence type",
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
