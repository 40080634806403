/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Models } from "../../../../../store/Models/Types";
import Moment from "react-moment";
import { DeleteModelModal } from "./DeleteModelModal";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";
import { NavLink } from "react-router-dom";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePalette, ThemeBreakpoints,
    ThemePaletteState, ThemeShadowSizes } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Container } from "../../../../../components/core/03-base/Container";

/**
 *
 */
interface OwnProps {
    results: Models;
}

interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openDeleteRequest: (id: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

export const OpenDeleteReq =
    (id: string) => (
        () => (
            <Modal
                modalID={id}
                theme={{ size: ThemeSize.SMALL }}
            >
                <DeleteModelModal
                    modalID={id}
                    modelId={id}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});
/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.MODEL_RESULTS, {
        skip: skip,
    })),
    openDeleteRequest: (id: string) => dispatch(
        modalShow(
            id,
            OpenDeleteReq(id),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openDelReq = this.openDelReq.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results && this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.name ?? "",
                row.createdAt
                    ? <Moment date={new Date(row.createdAt)} format={"DD-MM-YYYY"} key={`c-date-${index}`} />
                    : "",
                row.description,
                row.brand ? row.brand.name : "",
                this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    (
                        <ButtonsWrapper
                            asGroup
                            alignment={Alignment.RIGHT}
                            orientations={{
                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                            }}
                        >
                            <NavLink to={`/manage/models/${row.id}/edit`}>
                                <Button
                                    theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                    className="scl-a-btn--tiny"
                                >
                                    {SubPageDisplay[SubPages.MODEL_EDIT].title}
                                </Button>
                            </NavLink>
                            <Button
                                theme={{
                                    paletteState: ThemePaletteState.DANGER,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                                className="scl-a-btn--tiny"
                                link={{ onClick: this.openDelReq(row.id.toString()) }}
                            >
                                <Icon name={IconNames.DELETE} />
                            </Button>
                        </ButtonsWrapper>
                    ) : "",
            ],
        }));
    }

    private openDelReq(memoId: string) {
        return () => this.props.openDeleteRequest(memoId);
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Name",
                },
                {
                    label: "Created at",
                },
                {
                    label: "Description",
                },
                {
                    label: "Brand",
                },
                this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    {
                        label: "Actions",
                    } :
                    {
                        label: "",
                    },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
