import * as React from "react";

import { TableRow } from "./TableRow";
import { RowData, ColumnData } from "./Table";
import * as I18n from "../../../utils/translations/I18n";

interface Props {
    rows?: RowData[];
    hasActions?: boolean;
    columns: ColumnData[];
}

export const TableBody: React.FunctionComponent<Props> = (props) => (
    <tbody className="scl-m-table__body">
        {props.rows && props.rows.length > 0 ?
            (
                props.rows.map((row, index) => (
                    <TableRow
                        rowData={row}
                        key={`tr-${index}`}
                        even={index % 2 === 0 ? true : false}
                        hasActions={!!props.hasActions}
                        dataColumns={props.columns}
                        themePalleteState={row.themePalleteState}
                    />
                ))
            ) : (
                <tr
                    className="scl-m-table__tr"
                >
                    <td
                        className="scl-m-table__td scl-h-text-align--center"
                        colSpan={props.columns.length}
                    ><br />
                        {I18n.getText("mary.06-molecules.tablebody.no-results", "No results to display.")}
                        <br /><br />
                    </td>
                </tr>

            )
        }
    </tbody>
);
