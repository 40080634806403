import { GeneralLedgerFilters, GeneralLedgers, GLDetails } from "./Types";
import { AxiosResponse } from "axios";

/**
 *
 */
export const GeneralLedgerFiltersToParams = (f: GeneralLedgerFilters) => ({
    ...f,
});

export const mapGeneralLedgerRequests =
    (resp: AxiosResponse): GeneralLedgers => resp.data as GeneralLedgers;

/**
 *
 * @param resp
 */
export const mapGLDetailsRequests =
    (resp: AxiosResponse): GLDetails => resp.data as GLDetails;
