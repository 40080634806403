import * as React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { TariffDetails } from "../../../../../store/Tariffs/Types";
import { DetailFields } from "./DetailFields";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { ThemePalette } from "../../../../../theme/_Types";

/**
 *
 */
interface OwnProps {
    tariffId: number;
}

interface State {
    tariffDetails?: TariffDetails;
}

interface StateProps {
    tariffDetails?: TariffDetails;
    remoteLoading: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadDetails: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    tariffDetails: s.prop("remote").prop(RemoteScope.TARIFF_DETAILS) as TariffDetails,
    remoteLoading: isRemoteLoading(s, RemoteScope.TARIFF_DETAILS),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadDetails: (id: number) => dispatch(remoteTrigger(RemoteScope.TARIFF_DETAILS, {
        id: id,
        modalID: id.toString(),
    })),
});

/**
 *
 */
export class DetailWrapperComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {};
    }

    public componentDidMount() {
        this.props.loadDetails(this.props.tariffId);
    }

    public componentDidUpdate(prev: Props) {
        if (prev && prev.tariffId !== this.props.tariffId) {
            this.setState({
                tariffDetails: undefined,
            });
            this.props.loadDetails(this.props.tariffId);
        } else {
            if (prev && (prev.tariffDetails !== this.props.tariffDetails)
            && this.props.tariffDetails?.content.id === this.props.tariffId) {
                this.setState({
                    tariffDetails: this.props.tariffDetails,
                });
            }
        }
    }

    /**
     *
     */
    public render() {
        return (
            <>
                {this.props.remoteLoading && this.state.tariffDetails === undefined
                    ? (
                        <LoadingIndicator
                            type={LoadingIndications.DEFAULT}
                            theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                        />
                    )
                    : !!this.state.tariffDetails && (
                        <DetailFields tariffDetails={this.state.tariffDetails} />
                    )}
            </>
        );
    }
}

/**
 *
 */
export const DetailWrapper = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DetailWrapperComp);
