import { AxiosResponse } from "axios";
import { Statements, StatementDetails, StatementFilters, BookedStatements } from "./Types";

export const mapStatementRequests =
    (resp: AxiosResponse): Statements => resp.data as Statements;

/**
 *
 * @param resp
 */
export const mapStatementDetailsRequests =
    (resp: AxiosResponse): StatementDetails => resp.data as StatementDetails;

export const mapBookedStatementsRequests =
(resp: AxiosResponse): BookedStatements => resp.data as BookedStatements;

/**
 *
 */
export const StatementFiltersToParams = (f: StatementFilters) => ({
    ...f,
});
