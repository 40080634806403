/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasNotExceedLengthValidation,
    hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../../store/AppDisplays";
import { updatePartner, clearPartner } from "../../../../../store/Partners/Create";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import { PartnerRequest, PartnerDetails, canSubmitPartnerForm, PartnerType } from "../../../../../store/Partners/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

interface OwnProps {
    edit: boolean;
    partnerId: string;
}

interface StateProps {
    partner: PartnerRequest;
    resultsSuccess: PartnerDetails;
    canSubmitPartnerForm: boolean;
    loadingPartnerDetails: boolean;
}

interface DispatchProps {
    createPartner: (partner: PartnerRequest) => void;
    editPartner: (partner: PartnerRequest) => void;
    onChange: (partner: PartnerRequest) => void;
    clearPartner: () => void;
    loadPartnerDetail: (id: string) => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    canSubmitPartnerForm: canSubmitPartnerForm(s.prop("partnerRequest")),
    partner: s.prop("partnerRequest").map(e => ({
        ...e,
    })),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.PARTNER_CREATE)
            || s.prop("remote").prop(RemoteScope.PARTNER_EDIT)) as PartnerDetails,
    loadingPartnerDetails: isRemoteLoading(s, RemoteScope.PARTNER_DETAILS),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    createPartner: (partner: PartnerRequest) => dispatch(remoteTrigger(RemoteScope.PARTNER_CREATE, {
        partner: partner,
    })),
    editPartner: (partner: PartnerRequest) => dispatch(remoteTrigger(RemoteScope.PARTNER_EDIT, {
        partner: partner,
    })),
    onChange: (partner: PartnerRequest) => dispatch(updatePartner(partner)),
    clearPartner: () => {
        dispatch(clearPartner());
        dispatch(remoteClearResponse(RemoteScope.PARTNER_CREATE));
        dispatch(remoteClearResponse(RemoteScope.PARTNER_EDIT));
    },
    loadPartnerDetail: (id: string) => dispatch(remoteTrigger(RemoteScope.PARTNER_DETAILS, {
        id: id.toString(),
    })),
});

/**
 *
 */
export class CreatePartnerComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.createPartner = this.createPartner.bind(this);
        this.editPartner = this.editPartner.bind(this);
    }

    public componentDidMount() {
        this.props.clearPartner();
        if (this.props.edit) {
            this.props.loadPartnerDetail(this.props.partnerId);
        }
    }

    public componentWillUnmount() {
        this.props.clearPartner();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {this.props.edit ? (
                        <Header
                            title={SubPageDisplay[SubPages.PARTNER_EDIT].title}
                            goBack={`/partners/${this.props.partnerId}`}
                        />
                    ) : (
                        <Header
                            title={SubPageDisplay[SubPages.PARTNER_CREATE].title}
                            goBack={PageDisplay[Pages.PARTNERS].path}
                        />
                    )}
                    {this.props.resultsSuccess && (
                        <Redirect to={`/partners/${this.props.resultsSuccess.content.id}`} />
                    )}
                    <Form onSubmit={this.props.edit ? this.editPartner : this.createPartner}>
                        <Div
                            theme={{
                                shadow: ThemeShadowSizes.TINY,
                                margin: {
                                    "": { t: 2 },
                                    "sm": { t: 3 },
                                },
                            }}
                        >
                            <Container
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    padding: {
                                        "": { y: 3 },
                                        "sm": { y: 4 },
                                    },
                                }}
                            >
                                {this.props.edit && this.props.loadingPartnerDetails ? (
                                    <LoadingIndicator
                                        type={LoadingIndications.DEFAULT}
                                        theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                    />
                                ) :
                                    <>
                                        <FieldSet title={"Basic"}>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("name", value ? value.toString() : undefined)}
                                                        value={this.props.partner.name}
                                                        label="Name"
                                                        required
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Name provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange(
                                                                "partnerNumber", value ? value.toString() : undefined)}
                                                        value={this.props.partner.partnerNumber}
                                                        disabled={this.props.edit}
                                                        readonly={this.props.edit}
                                                        label="Partner number"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Partner Number provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange(
                                                                "partnerNumberPah", value
                                                                    ? value.toString() : undefined)}
                                                        value={this.props.partner.partnerNumberPah}
                                                        disabled={this.props.edit}
                                                        readonly={this.props.edit}
                                                        label="Partner number (PAH)"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Partner numer Pah provided")}
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("iban", value ? value.toString() : undefined)}
                                                        value={this.props.partner.iban}
                                                        label="IBAN"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Iban provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("bic", value ? value.toString() : undefined)}
                                                        value={this.props.partner.bic}
                                                        label="Bic"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Bic provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange(
                                                                "partnerType",
                                                                value ? value.toString() as PartnerType : undefined)}
                                                        type="text"
                                                        value={this.props.partner.partnerType}

                                                        label="Partner Type"
                                                        placeholder="Make a choice"
                                                        options={Object.keys(PartnerType).map((p) => ({
                                                            label: PartnerTypeDisplay[p],
                                                            value: p,
                                                        }))}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Partner type provided")}
                                                    />
                                                </Grid>
                                            </Div>
                                        </FieldSet>
                                        <FieldSet title={"Contact"}>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange(
                                                                "addressLine1", value ? value.toString() : undefined)}
                                                        value={this.props.partner.addressLine1}
                                                        label="Address Line 1"
                                                        required
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Address line 1 provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange(
                                                                "addressLine2", value ? value.toString() : undefined)}
                                                        value={this.props.partner.addressLine2}
                                                        label="Address Line 2"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value,
                                                                    "Address line 2 provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("zipCode", value
                                                                ? value.toString() : undefined)}
                                                        value={this.props.partner.zipCode}
                                                        label="Zip code"
                                                        required
                                                        max={6}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasNotExceedLengthValidation(value,
                                                                    "Zip code provided", 6)}
                                                    />
                                                </Grid>
                                            </Div>
                                        </FieldSet>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange(
                                                            "residence", value ? value.toString() : undefined)}
                                                    value={this.props.partner.residence}
                                                    label="Residence"
                                                    required
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value,
                                                                "Residence provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange(
                                                            "houseNumber", value ? value.toString() : undefined)}
                                                    value={this.props.partner.houseNumber}
                                                    label="House Number"
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value,
                                                                "House number provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange(
                                                            "houseNumberAddition",
                                                            value ? value.toString() : undefined)}
                                                    value={this.props.partner.houseNumberAddition}
                                                    label="House Number Addition"
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value,
                                                                "House number addition provided")}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("email", value ? value.toString() : undefined)}
                                                    value={this.props.partner.email}
                                                    disabled={this.props.edit}
                                                    readonly={this.props.edit}
                                                    label="Email"
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value,
                                                                "Email provided")}
                                                />
                                            </Grid>
                                        </Div>
                                        <Required />
                                    </>}
                            </Container>
                        </Div>
                        <FormBar
                            buttonLabel={this.props.edit ? "Update" : "Create"}
                            title={this.props.edit ? "Edit partner" : "Create partner"}
                            isValid={!!this.props.canSubmitPartnerForm ? this.props.canSubmitPartnerForm : undefined}
                        />
                    </Form>
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof PartnerRequest>(key: K, value: PartnerRequest[K]) => {
        this.props.onChange({
            [key]: value,
        });
    };

    private createPartner() {
        this.props.createPartner(this.props.partner);
    }

    private editPartner() {
        this.props.editPartner(this.props.partner);
    }
}

/**
 *
 */
export const CreatePartner = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreatePartnerComp);
