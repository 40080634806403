/* eslint-disable max-len */
import React from "react";
import { Summary } from "../../00-blocks/Summary";
import { Tabs } from "../../00-blocks/Tabs";
import { connect } from "react-redux";

import { RouteComponentProps } from "react-router";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Icon } from "../../../../../components/core/05-atoms/Icon";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages, PageTabs } from "../../../../../store/AppDisplays";
import { ClaimDetails } from "../../../../../store/Claims/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Container } from "../../../../../components/core/03-base/Container";

interface Params {
    claimId: string;
}

interface StateProps {
    params: Params;
    details: ClaimDetails;
    loading: boolean;
}

interface DispatchProps {
    loadClaimDetails: (id: string) => void;
    clearDetails: () => void;
}

type Props = DispatchProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    details: s.prop("remote").prop(RemoteScope.CLAIM_DETAILS) as ClaimDetails,
    loading: isRemoteLoading(s, RemoteScope.CLAIM_DETAILS),
});

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadClaimDetails: (id: string) => dispatch(remoteTrigger(RemoteScope.CLAIM_DETAILS, {
        id: id,
    })),
    clearDetails: () => (
        dispatch(remoteClearResponse(RemoteScope.CLAIM_DETAILS)),
        dispatch(remoteClearError(RemoteScope.CLAIM_DETAILS))
    ),
});

/**
 *
 */
export class DetailComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public componentDidMount() {
        this.props.loadClaimDetails(this.props.params.claimId);
    }

    public componentWillUnmount() {
        this.props.clearDetails();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.CLAIMS_DETAIL].title}
                        goBack={PageDisplay[Pages.CLAIMS].path}
                    />
                    {
                        this.props.loading || !this.props.details ?
                            (
                                <LoadingData loading={!!this.props.loading} />
                            ) :
                            (
                                <>
                                    <Summary
                                        tableColumnData={[
                                            { label: "Claim number" },
                                            { label: "Claim number (PAH)" },
                                            { label: "Claim date" },
                                            { label: "Policy number" },
                                            { label: "Amount", alignRight: true },
                                        ]}
                                        tablerowData={[
                                            {
                                                id: 1,
                                                data: [
                                                    this.props.details && this.props.details.content.claimNumber,
                                                    this.props.details && this.props.details.content.claimNumberPAH,
                                                    (
                                                        <Moment
                                                            date={new Date(this.props.details.content.claimDate)}
                                                            format={"DD-MM-YYYY"}
                                                            key={`${this.props.details.content.id}`}
                                                        />
                                                    ),
                                                    this.props.details.content.policyId ? (
                                                        <NavLink
                                                            to={`/policies/${this.props.details.content.policyId}`}
                                                            target="_blank"
                                                        >
                                                            <span className="scl-a-link scl-b-h scl-b-h6">
                                                                <Icon name="eye" />
                                                                    &nbsp;&nbsp;{this.props.details.content.policyNumber}
                                                            </span>
                                                        </NavLink>
                                                    ) : this.props.details.content.policyNumber,
                                                    (
                                                        <h6
                                                            key={`h6-${this.props.details.content.id}`}>
                                                            <Currency amount={this.props.details.content.amount} />
                                                        </h6>
                                                    ),
                                                ],
                                            }]}
                                    />
                                    <Tabs
                                        tabs={SubPageDisplay[SubPages.CLAIMS_DETAIL].tabs}
                                        defaultTab={PageTabs.CLAIMS_GENERAL}
                                    />
                                </>
                            )
                    }
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Detail = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <DetailComp {...props}/>);
