/* eslint-disable max-len */

import React from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../../components/core/05-atoms/CurrencyDisplay";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { SubPageDisplay, SubPages, PageTabs, PageDisplay, Pages } from "../../../../../../store/AppDisplays";
import { UserRoles } from "../../../../../../store/AppTypes";
import { PartnerTypeDisplay } from "../../../../../../store/Partners/Displays";
import { ThemeShadowSizes, ThemeBreakpoints, ThemePalette } from "../../../../../../theme/_Types";
import { getPartnerTypeColor } from "../../../../../utils/FormatHelpers";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";
import { Summary } from "../../../00-blocks/Summary";
import { Tabs } from "../../../00-blocks/Tabs";
import { Header } from "../../../../../../components/core/09-views/01-sections/Header";
import { Props as DetailProps } from "../Detail";
import { Button } from "../../../../../../components/core/05-atoms/Button";

interface OwnProps {
    asAudit?: boolean;
}

type Props = OwnProps & DetailProps;

/**
 *
 * @param props
 */
export const DetailView: React.FC<Props> = (props: Props) => {
    const getTabsByRole = (role: UserRoles) => {
        if (role === UserRoles.EW_ADMIN) {
            return SubPageDisplay[SubPages.PARTNER_DETAIL].tabs;
        } else {
            return SubPageDisplay[SubPages.PARTNER_DETAIL].tabs.filter(tab => (
                tab !== PageTabs.PARTNER_MANDATES));
        }
    };
    return (
        <>
            {!props.asAudit &&
                <Header
                    title={PageDisplay[Pages.PARTNERS].title}
                    goBack={PageDisplay[Pages.PARTNERS].path}
                />
            }
            {
                props.loading || !props.details ?
                    (
                        <LoadingData loading={!!props.loading} />
                    ) :
                    (
                        <>
                            <Summary
                                hasActions
                                tableColumnData={[
                                    {
                                        label: "Name",
                                    },
                                    {
                                        label: "Number",
                                    },
                                    {
                                        label: "Type",
                                    },
                                    {
                                        label: "IBAN",
                                    },
                                    {
                                        label: "Invoice balance",
                                    },
                                    {
                                        label: "Claim balance",
                                    },
                                    {
                                        label: "Total balance",
                                        alignRight: true,
                                    },
                                    {
                                        // eslint-disable-next-line max-len
                                        label: props.user?.userRole === UserRoles.EW_ADMIN && props.asAudit !== true ? "Actions" : "",
                                        alignRight: true,
                                    },
                                ]}
                                tablerowData={[
                                    {
                                        id: 1,
                                        data: [
                                            props.details.content.name ? props.details.content.name : "",
                                            props.details.content.partnerNumber,
                                            props.details.content.partnerType ? (
                                                <Badge
                                                    theme={{
                                                        palette:
                                                            getPartnerTypeColor(props.details.content.partnerType),
                                                        shadow: ThemeShadowSizes.TINY,
                                                    }}
                                                >
                                                    {PartnerTypeDisplay[props.details.content.partnerType]}
                                                </Badge>
                                            ) : "",
                                            props.details.content.iban,
                                            <Currency amount={props.details.content.invoiceBalance} key="partner-invoice" />,
                                            <Currency amount={props.details.content.claimBalance} key="partner-claim" />,
                                            (<h6 key="partner-total">
                                                <Currency amount={props.details.content.totalBalance} />
                                            </h6>),
                                            (
                                                props.user?.userRole === UserRoles.EW_ADMIN && props.asAudit !== true ?
                                                    <ButtonsWrapper
                                                        asGroup
                                                        alignment={Alignment.RIGHT}
                                                        orientations={{
                                                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                                                        }}
                                                    >
                                                        <NavLink to={`/partners/${props.details.content.id}/edit`}>
                                                            <Button
                                                                theme={{
                                                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                                                    shadow: ThemeShadowSizes.TINY,
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </NavLink>
                                                    </ButtonsWrapper> : ""
                                            ),
                                        ],
                                    }]}
                            />
                            {props.asAudit !== true &&
                                <Tabs
                                    tabs={getTabsByRole(props.user?.userRole || UserRoles.NONE)}
                                    defaultTab={PageTabs.PARTNER_GENERAL}
                                    params={{
                                        partnerId: props.params.partnerId,
                                    }}
                                />
                            }
                        </>
                    )
            }
        </>
    );
};
