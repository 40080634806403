import { CollectiveInvoices, CollectiveInvoiceFilters } from "./Types";
import { AxiosResponse } from "axios";

export const mapCollectiveInvoiceRequests =
(resp: AxiosResponse): CollectiveInvoices => resp.data as CollectiveInvoices;
/**
 *
 */
export const CollectiveInvoiceFiltersToParams = (f: CollectiveInvoiceFilters) => ({
    ...f,
});
