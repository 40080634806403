import React from "react";
import { connect } from "react-redux";
import { PageDisplay, Pages, PageTabs, SubPageDisplay, SubPages } from "../../../../../../../store/AppDisplays";
import { isRemoteLoading, ReduxState } from "../../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { Filter } from "../../../../02-pages/Filter";
import { Results } from "../../../../02-pages/Results";
import { Page } from "../../../../../../../components/core/09-views/01-sections/Page";
import { Header } from "../../../../../../../components/core/09-views/01-sections/Header";
import { Payments } from "../../../../../../../store/PartnerPayments/Types";
import { Container } from "../../../../../../../components/core/03-base/Container";

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: Payments;
}

type Props = StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.PAYMENT_RESULTS),
    results: s.prop("remote").prop(RemoteScope.PAYMENT_RESULTS) as Payments,
});

/**
 *
 * @param props
 */
export class PaymentsAuditTrailComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.AUDIT_TRAIL_PAYMENTS].title}
                        description={SubPageDisplay[SubPages.AUDIT_TRAIL_PAYMENTS].description}
                        goBack={PageDisplay[Pages.AUDIT_TRAILS].path}
                    />
                    <Filter
                        loadingFilters={this.props.loadingFilters}
                        loadingResults={this.props.loadingResults}
                        currentPage={PageTabs.PARTNER_PAYMENTS}
                    />
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={SubPages.AUDIT_TRAIL_PAYMENTS}
                        scope={RemoteScope.PAYMENT_RESULTS}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const PaymentsAuditTrail = connect(
    mapStateToProps,
    {},
)(PaymentsAuditTrailComp);
