import { RequestObject } from "../RemoteTypes";

export enum PolicyBusinessRule {
    NON_EXISTING_EW_NUMBER = "NON_EXISTING_EW_NUMBER",
    EW_HAS_EXPIRED = "EW_HAS_EXPIRED",
    EW_IS_OVERLAPPING_WITH_FACTORY_WARRANTY = "EW_IS_OVERLAPPING_WITH_FACTORY_WARRANTY",
    EW_SIGN_UP_DEADLINE_MISSED = "EW_SIGN_UP_DEADLINE_MISSED",
    EW_STARTED_ALREADY = "EW_STARTED_ALREADY",
    EW_NOT_ACTIVE = "EW_NOT_ACTIVE",
    NON_EXISTING_BRAND = "NON_EXISTING_BRAND",
    BRAND_NOT_RECOGNIZED = "BRAND_NOT_RECOGNIZED",
    NON_EXISTING_MODEL = "NON_EXISTING_MODEL",
    INVALID_VEHICLE_TYPE = "INVALID_VEHICLE_TYPE",
    MAX_POWER_BREACHED = "MAX_POWER_BREACHED",
    TARIFF_LINE_NOT_FOUND = "TARIFF_LINE_NOT_FOUND",
    CLOSED_BOOKING_PERIOD = "CLOSED_BOOKING_PERIOD",
    NO_PRODUCT_NUMBER_DEFINED = "NO_PRODUCT_NUMBER_DEFINED",
    NO_PARTNER_NUMBER_DEFINED = "NO_PARTNER_NUMBER_DEFINED",
    NO_PARTNER_FOUND = "NO_PARTNER_FOUND",
    WRONG_PARTNER_TYPE = "WRONG_PARTNER_TYPE",
    INVALID_BRAND_AND_MODEL_COMBINATION = "INVALID_BRAND_AND_MODEL_COMBINATION",
    MULTIPLE_MODELS_MATCHED = "MULTIPLE_MODELS_MATCHED",
}

export interface BusinessRuleViolation {
    id: number;
    createdAt?: string;
    policyBusinessRule: PolicyBusinessRule;
    active: boolean;
}

export interface BusinessRuleViolations extends RequestObject {
    content: BusinessRuleViolation[];
}
