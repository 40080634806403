import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { FilterScope } from "../FilterTypes";
import { ReducerFn } from "../ReduxState";

export type ClearInvoicePartnerFilterAction = Readonly<
Action<ActionTypeKeys.CLEAR_INVOICE_PARTNER_FILTER>
>;

export const clearInvoicePartnerFilter:
() => ClearInvoicePartnerFilterAction =
() => ({
    type: ActionTypeKeys.CLEAR_INVOICE_PARTNER_FILTER,
});

export const clearInvoicePartnerFilterReducer:
ReducerFn<ClearInvoicePartnerFilterAction> =
(s, _a) => (
    s.setProp(
        "filters",
        s.prop("filters").setProp(
            FilterScope.INVOICES,
            s.prop("filters").prop(FilterScope.INVOICES).updateWith({partnerId: undefined, partnerName: undefined}),
        ),
    )
);
