import { RequestObject } from "../RemoteTypes";
import { CreditDebitIndicator, Entry, Amount } from "../Statements/Types";
import { Message } from "../Message/Types";
import { Partner } from "../Partners/Types";
import { InExGenerationForm, CollectiveInvoiceForm } from "../ReduxState";
import { Invoice } from "../Invoice/Types";
import { State } from "../../utils/redux/State";
import { FinEntryLine } from "../ManualBooking/Types";

export interface InvoiceSelectedRows {
    fromDate: string;
    toDate: string;
    partnerIds: number[];
}

export interface InExInfo {
    requestedDate: string;
    incassoName: string;
    excassoName: string;
    accountId: number;
    incassoNote: string;
    excassoNote: string;
    collectiveInvoicesIds: number[];
    partnerInvoicesIds: number[];
}

export interface ExcassoInfo {
    requestedDate: string;
    excassoName: string;
    incassoName: string;
    accountId: number;
    excassoNote: string;
    incassoNote: string;
    collectiveInvoicesIds: number[];
    partnerInvoicesIds: number[];
}

export enum InExFormScope {
    INCASSO = "INCASSO",
    EXCASSO = "EXCASSO",
}

export const canSubmitCollectiveInvoiceForm =
    (collectiveInvoiceForm: State<CollectiveInvoiceForm>): boolean =>
        collectiveInvoiceForm
            .map(f => Object.keys(f).length === 2 && Object.keys(f).every(k => !!f[k]));

export const canSubmitInExForm =
    (inex: State<InExGenerationForm> | State<InExGenerationForm>, scope: InExFormScope): boolean =>
        (scope === InExFormScope.EXCASSO || scope === InExFormScope.INCASSO) &&
        inex.map(f => Object.keys(f).length === 4 && Object.keys(f).every(k => !!f[k]));

export interface CollectiveInvoiceGenerate {
    message?: string;
}

export interface InExGenerate extends RequestObject {
    content: {
        downloadUrl?: string;
    };
}

export interface ExcassoGenerate extends InExGenerate {
}

export const getProps =
    (inex: State<InExGenerationForm>, scope: InExFormScope):
    InExGenerationForm | undefined =>
        (scope === InExFormScope.INCASSO || scope === InExFormScope.EXCASSO)
            ? inex.map(f => ({
                ...f,
            }))
            : undefined;

export enum CollectiveInvoiceStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
    REVERSED = "REVERSED",
    CANCELED = "CANCELED",
    PENDING = "PENDING",
    PARTIALLY_PAID = "PARTIALLY_PAID",
}

export enum Operation {
    BOOKING = "BOOKING",
    UNBOOKING = "UNBOOKING",
}

export enum CollectiveInvoiceCategory {
    INCASSO = "INCASSO",
    EXCASSO = "EXCASSO",
}

interface ExactMessage extends Message {
    glentry: []; // check
}

export interface GLEntry {
    id: number;
    createdAt: string;
    divisionCode: number;
    journalCode: number;
    journalType: string;
    finEntryLine: []; // check
    message: ExactMessage;
    gldate: string;
}

interface Payment {
    id: number;
    createdAt: string;
    entry: Entry;
    amount: number;
    creditDebitIndicator: CreditDebitIndicator;
    operation: Operation;
    invoiceDate: string;
    invoiceNumber: number;
    relatedUnbooking: Payment; // check
    finEntryLine: FinEntryLine;
    partner: Partner;
}

interface CollectiveInvoicePayment extends Payment {
    collectiveInvoice: any; // check
}

export interface InvoicePayment extends Payment {
    invoice: Invoice; // check
}

export interface CollectiveInvoice {
    id: number;
    createdAt: string;
    dealerName: string;
    streetName: string;
    houseNumber: string;
    houseNumberAddition: string;
    zipCode: string;
    residence: string;
    partnerNumber: string;
    partnerIBAN: string;
    invoiceDate: string;
    mutationDate: string;
    bookingDate: string;
    invoiceNumber: number;
    periodFrom: string;
    periodTo: string;
    totalAmount: Amount;
    balance: number;
    creditDebitIndicator: CreditDebitIndicator;
    status: CollectiveInvoiceStatus;
    payments: CollectiveInvoicePayment[];
    partner: Partner;
    message: Message;
    messageId: number;
    collectiveInvoiceCategory: CollectiveInvoiceCategory;
    partnerId: number;
}

export interface CollectiveInvoices extends RequestObject {
    content: CollectiveInvoice[];
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type CollectiveInvoiceFilters = Readonly<Partial<{
    invoiceNumber: string;
    partnerNumber: string;
    partnerId: string;
    creationDateFrom: string;
    creationDateTo: string;
    invoiceStatus: string;
    creditDebitIndicator: CreditDebitIndicator;
}>>;

export interface BookInvoice {
    entryId: number;
    paidObjectId: number;
    paidAmount: number;
    description?: string;
}
