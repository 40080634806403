import { State } from "../../utils/redux/State";

import { Brand } from "../Brand/Types";
import { Partner } from "../Partners/Types";
import { Proxy } from "../Proxies/Types";
import { RefObject, RequestObject } from "../RemoteTypes";
import { Tariff } from "../Tariffs/Types";
import { CoverageLevel } from "../Policies/Types";

export enum ProductStatus {
    CREATED = "CREATED",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

// export type TariffRequests = Readonly<Partial<Tariff>>;

export interface ProductTariffRequests {
    id?: number;
    createdAt?: string;
    extendedWarranty?: RefObject;
    startingDate?: string;
    endingDate?: string;
    tariffLine?: RefObject;
    ageOfVehicleFrom?: number;
    ageOfVehicleTo?: number;
    durationOfInsurance?: number;
}

/**
 *
 */
export interface ProductRequest {
    id?: number;
    name?: string;
    productNumber?: string;
    description?: string;
    coverageCodeForPah?: string;
    soaName?: string;
    coverageLevel?: CoverageLevel;
    firstPartVWFS?: string;
    secondPartVWFS?: string;
    termsAndConditions?: string;
    forUsedVehicles?: boolean;
    productTariffs?: ProductTariffRequests[];
    insurer?: RefObject;
    brands?: RefObject[];
    proxy?: RefObject;
}

export enum VehicleType {
    PERSONENAUTO = "PERSONENAUTO",
    BEDRIJFSAUTO = "BEDRIJFSAUTO",
}

export enum MaxMileage {
    HUNDREDTHOUSAND = "HUNDREDTHOUSAND",
    HUNDREDTWENTYTHOUSAND = "HUNDREDTWENTYTHOUSAND",
    HUNDREDFIFTYTHOUSAND = "HUNDREDFIFTYTHOUSAND",
    TWOHUNDREDTHOUSAND = "TWOHUNDREDTHOUSAND",
}

export interface ProductTariff {
    id?: number;
    createdAt?: string;
    extendedWarranty?: RefObject;
    startingDate?: string;
    endingDate?: string;
    tariffLine?: Tariff;
    ageOfVehicleFrom?: number;
    ageOfVehicleTo?: number;
    durationOfInsurance?: number;
}

export interface Product {
    id: number;
    createdAt: string;
    name: string; // mandatory
    productNumber: string;
    description: string;
    coverageCodeForPah: string;
    soaName: string;
    coverageLevel: CoverageLevel;
    firstPartVWFS: string;
    secondPartVWFS: string;
    termsAndConditions: string;
    forUsedVehicles: boolean;
    status: ProductStatus;
    productTariffs: ProductTariff[];
    insurer: Partner;
    brands: Brand[];
    proxy: Proxy; // mandatory
}

export interface Products extends RequestObject {
    content: Product[];
}

export interface ProductDetail {
    content: Product;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type ProductFilters = Readonly<Partial<{
    ewStatus: ProductStatus;
}>>;

export const hasValue = (value?: string | number | RefObject) => !!value || value === 0;

// this is the generic function that verifies required fields
export const hasRequiredFields =
    <T, K extends keyof T>(obj: T, fields: K[]) =>
        fields.every(k => hasValue(obj[k] as unknown as string | number | RefObject));

export const canSubmitEWForm =
    (productRequest: State<ProductRequest>): boolean => {
        const validMandatoryProductFields =
            productRequest.map(f => hasRequiredFields(
                f,
                ["name", "brands", "proxy", "productNumber", "coverageCodeForPah"],
            ));
        const tariffs = productRequest.prop("productTariffs");
        const hasValidTariffs =
            tariffs?.every(tariff => hasRequiredFields(
                tariff,
                ["startingDate", "endingDate", "durationOfInsurance", "tariffLine"],
            )) ?? true;
        return (
            validMandatoryProductFields && hasValidTariffs
        );
    };
