/* eslint-disable max-len */

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Value } from "../../../../../components/core/05-atoms/Select";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasLicensePlateValidation, hasNotExceedLengthValidation, hasNotExceedValueValidation,
    hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../../store/AppDisplays";
import { updatePolicy, clearPolicy } from "../../../../../store/Policies/EditPolicyTypes";
import { PolicyRequest, PolicyDetails, canSubmitPolicyForm } from "../../../../../store/Policies/Types";
import { VehicleTypeDisplay, MaxMileageDisplay } from "../../../../../store/Product/Displays";
import { VehicleType, MaxMileage } from "../../../../../store/Product/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeSize, ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { validFromValidation, validTillValidation } from "../../../../../utils/FormValidations";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { SelectPolicyBrandModal } from "../../00-blocks/Brands/SelectPolicyBrandModal";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { SelectPolicyModelModal } from "../../00-blocks/Models/SelectPolicyModelModal";
import { SelectPolicyPartnerModal } from "../../00-blocks/Partners/SelectPolicyPartnerModal";
import { SelectPolicyProductModal } from "../../00-blocks/Product/SelectPolicyProductModal";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { ErrorDetail } from "./ErrorDetail";
import { isValidLicensePlate, formatLicensePlate, parseLicenseplate } from "../../../../../utils/LicensePlate";

type OnChange =
    <K extends keyof PolicyRequest>(key: K, value: PolicyRequest[K]) => void;

interface OwnProps {
    edit: boolean;
    policyId: string;
}

interface StateProps {
    policy: PolicyRequest;
    loadingFormHelpers: boolean;
    canSubmitPolicyForm: boolean;
    resultsSuccess: PolicyDetails;
}

interface DispatchProps {
    updatePolicy: (policy: PolicyRequest) => void;
    clear: () => void;
    loadPolicyDetailResults: (id: string) => void;
    addPolicy: (policy: PolicyRequest) => void;
    editPolicy: (policy: PolicyRequest) => void;
    selectPartner: (modalId: string, onChange: OnChange, id?: string)
    => void;
    selectModel: (modalId: string, brandName: string, onChange: OnChange, id?: string)
    => void;
    selectBrand: (modalId: string, onChange: OnChange, id?: string)
    => void;
    selectProduct: (modalId: string, onChange: OnChange, id?: string)
    => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    policy: getPolicy(s),
    loadingFormHelpers:
            isRemoteLoading(s, RemoteScope.POLICY_DETAILS),
    canSubmitPolicyForm: canSubmitPolicyForm(s.prop("policyRequest")),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.POLICY_CREATE)
            || s.prop("remote").prop(RemoteScope.POLICY_EDIT)) as PolicyDetails,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updatePolicy: (policy: PolicyRequest) => dispatch(updatePolicy(policy)),
    clear: () => {
        dispatch(clearPolicy());
        dispatch(remoteClearResponse(RemoteScope.BRAND_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.MODEL_PER_BRAND_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.POLICY_EDIT));
        dispatch(remoteClearResponse(RemoteScope.POLICY_CREATE));
    },
    addPolicy: (policy: PolicyRequest) => dispatch(remoteTrigger(RemoteScope.POLICY_CREATE, {
        policy: policy,
    })),
    editPolicy: (policy: PolicyRequest) => dispatch(remoteTrigger(RemoteScope.POLICY_EDIT, {
        policy: policy,
    })),
    loadPolicyDetailResults: (id: string) => dispatch(remoteTrigger(RemoteScope.POLICY_DETAILS, { id: id })),
    selectPartner:
            (modalId: string, onChange: OnChange, id?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectPolicyPartnerModal
                                    modalID={modalId}
                                    partnerNumber={id}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
    selectModel:
            (modalId: string, brandName: string, onChange: OnChange, id?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectPolicyModelModal
                                    modalID={modalId}
                                    brandName={brandName}
                                    modelNumber={id}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
    selectBrand:
            (modalId: string, onChange: OnChange, id?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectPolicyBrandModal
                                    modalID={modalId}
                                    brandNumber={id}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
    selectProduct:
            (modalId: string, onChange: OnChange, id?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectPolicyProductModal
                                    modalID={modalId}
                                    id={id}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
});

/**
 *
 * @param s
 */
export const getPolicy =
    (s: ReduxState): PolicyRequest =>
        s.prop("policyRequest").map(e => ({
            ...e,
        }));

/**
 *
 */
export class CreatePolicyComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.addPolicy = this.addPolicy.bind(this);
        this.editPolicy = this.editPolicy.bind(this);
    }

    public componentDidMount() {
        this.props.clear();
        if (this.props.edit) {
            this.props.loadPolicyDetailResults(this.props.policyId);
        }
    }

    public componentWillUnmount() {
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {this.props.edit ? (
                        <Header
                            title={SubPageDisplay[SubPages.POLICY_EDIT].title}
                            goBack={`/policies/${this.props.policyId}`}
                        />
                    ) : (
                        <Header
                            title={SubPageDisplay[SubPages.POLICY_CREATE].title}
                            goBack={PageDisplay[Pages.POLICIES].path}
                        />
                    )}
                    {this.props.loadingFormHelpers
                        ? (
                            <LoadingData loading={this.props.loadingFormHelpers} />
                        )
                        : (<>
                            {this.props.resultsSuccess && (
                                <Redirect to={`/policies/${this.props.resultsSuccess.content.id}`} />
                            )}
                            {this.props.policy.businessRuleViolations &&
                                this.props.policy.businessRuleViolations?.length > 0 &&
                                <ErrorDetail errors={this.props.policy.businessRuleViolations} />
                            }
                            <Form onSubmit={this.props.edit ? this.editPolicy : this.addPolicy}>
                                <Div
                                    theme={{
                                        shadow: ThemeShadowSizes.TINY,
                                        margin: {
                                            "": { t: 2 },
                                            "sm": { t: 3 },
                                        },
                                    }}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <FieldSet title="Policy">
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label="Duration"
                                                        required
                                                        type="number"
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("duration", value ? Number(value) :
                                                                undefined)}
                                                        value={this.props.policy.duration}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Duration provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label="Receiving date"
                                                        type="date"
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("receivingDate", value
                                                                ? value.toString() : "")}
                                                        value={this.props.policy.receivingDate}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Receiving date provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <FormFields.FormField
                                                            label="Policy holder"
                                                            required
                                                            placeholder={"Select a policy holder"}
                                                            readonly
                                                            disabled={this.props.edit}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Policy holder selected")}
                                                            value={this.props.policy.partnerNumber}
                                                        />
                                                        {!this.props.edit &&
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                }}
                                                            >
                                                                <Button
                                                                    icon={
                                                                        this.props.policy.partnerNumber
                                                                            ? IconNames.EDIT : IconNames.PLUS}
                                                                    theme={{
                                                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                                                        shadow: ThemeShadowSizes.TINY,
                                                                    }}
                                                                    link={{
                                                                        onClick: this.props.edit ? () => null : () =>
                                                                            this.props.selectPartner(
                                                                                "select-partner--modal",
                                                                                this.onChange,
                                                                                this.props.policy.partnerNumber,
                                                                            ),
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Div>
                                        </FieldSet>
                                        <FieldSet title="Vehicle">
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        type="licensePlate"
                                                        label="License plate"
                                                        onChange={(value?: string | number) => this.onChange("licensePlate",  isValidLicensePlate(value?.toString())
                                                            ? parseLicenseplate(value?.toString())
                                                                    ?? (value?.toString().toUpperCase() ?? "")
                                                            : (value?.toString().toUpperCase() ?? ""))
                                                        }
                                                        value={isValidLicensePlate((this.props.policy?.licensePlate))
                                                            ? formatLicensePlate((this.props.policy?.licensePlate))
                                                            : (this.props.policy?.licensePlate)?.toUpperCase()
                                                        }
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasLicensePlateValidation(value,
                                                                    "License plate provided") ?? ""}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label="Chassis number"
                                                        required
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("chassisNumber", value
                                                                ? value.toString() : "")}
                                                        value={this.props.policy.chassisNumber}
                                                        max={30}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasNotExceedLengthValidation(value,
                                                                    "Chassis number provided", 30)}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label="Start kilometers"
                                                        required
                                                        type="number"
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("mileage", value ? Number(value) :
                                                                undefined)}
                                                        value={this.props.policy.mileage}
                                                        max={999999}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasNotExceedValueValidation(value,
                                                                    "Start kilometers provided", 999999)}
                                                    />
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label="Vehicle type"
                                                        required
                                                        placeholder="Make a choice"
                                                        options={Object.keys(VehicleType).map(type => ({
                                                            label: VehicleTypeDisplay[type] as string | undefined,
                                                            value: VehicleTypeDisplay[type] as Value,
                                                        }))}
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("vehicleType",
                                                                value ? value.toString() as VehicleType : "")}
                                                        value={this.props.policy.vehicleType}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Vehicle type provided")}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <FormFields.FormField
                                                            label="Brand"
                                                            required
                                                            placeholder={"Select a brand"}
                                                            readonly
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Brand selected")}
                                                            value={this.props.policy.brand}
                                                        />
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                right: "10px",
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                            }}
                                                        >
                                                            <Button
                                                                icon={
                                                                    this.props.policy.brand
                                                                        ? IconNames.EDIT : IconNames.PLUS}
                                                                theme={{
                                                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                                                    shadow: ThemeShadowSizes.TINY,
                                                                }}
                                                                link={{
                                                                    onClick: () =>
                                                                        this.props.selectBrand(
                                                                            "select-brand--modal",
                                                                            this.onChange,
                                                                            this.props.policy.brand,
                                                                        ),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <FormFields.FormField
                                                            label="Model"
                                                            required
                                                            placeholder={"Select a model"}
                                                            readonly
                                                            disabled={!this.props.policy.brand}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Model selected")}
                                                            value={this.props.policy.model}
                                                        />
                                                        {this.props.policy.brand &&
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                }}
                                                            >
                                                                <Button
                                                                    icon={
                                                                        this.props.policy.model
                                                                            ? IconNames.EDIT : IconNames.PLUS}
                                                                    theme={{
                                                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                                                        shadow: ThemeShadowSizes.TINY,
                                                                    }}
                                                                    link={{
                                                                        onClick: () =>
                                                                            this.props.selectModel(
                                                                                "select-model--modal",
                                                                                this.props.policy.brand ?? "",
                                                                                this.onChange,
                                                                                this.props.policy.model,
                                                                            ),
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Div>
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label={"Power"}
                                                        required
                                                        onChange={(value?: string | number) => this.onChange("power",
                                                            value ? Number(value) : undefined)}
                                                        value={this.props.policy.power}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Power provided")}
                                                    />
                                                </Grid>
                                                {!this.props.edit &&
                                                    <Grid
                                                        size={{ xs: 12, md: 4 }}
                                                    >
                                                        <FormFields.FormField
                                                            label="Production year"
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("productionYear", value
                                                                    ? value.toString() : "")}
                                                            value={this.props.policy.productionYear}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Production year provided")}
                                                        />
                                                    </Grid>}
                                            </Div>
                                        </FieldSet>
                                        <FieldSet title="Product">
                                            <Div className="scl-b-row">
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label={"Start date factory warranty"}
                                                        // eslint-disable-next-line max-len
                                                        onChange={(value?: string | number) => this.onChange("startDateFactoryWarranty",
                                                            value ? value.toString() : "")}
                                                        value={this.props.policy.startDateFactoryWarranty}
                                                        required
                                                        type="date"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                validFromValidation(value.toString(),
                                                                    this.props.policy.endDateFactoryWarranty
                                                                        ? this.props.policy.endDateFactoryWarranty :
                                                                        undefined)}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label={"End date factory warranty"}
                                                        // eslint-disable-next-line max-len
                                                        onChange={(value?: string | number) => this.onChange("endDateFactoryWarranty",
                                                            value ? value.toString() : "")}
                                                        value={this.props.policy.endDateFactoryWarranty}
                                                        required
                                                        type="date"
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                validTillValidation(value.toString(),
                                                                    this.props.policy.startDateFactoryWarranty
                                                                        ? this.props.policy.startDateFactoryWarranty :
                                                                        undefined)}
                                                    />
                                                </Grid>
                                                <Grid
                                                    size={{ xs: 12, md: 4 }}
                                                >
                                                    <FormFields.FormField
                                                        label="Max kilometers"
                                                        required
                                                        placeholder="Make a choice"
                                                        options={Object.keys(MaxMileage).map(type => ({
                                                            label: MaxMileageDisplay[type] as string | undefined,
                                                            value: MaxMileageDisplay[type] as Value,
                                                        }))}
                                                        onChange={(value?: string | number) =>
                                                            this.onChange("maxMileage",
                                                                value ? Number(value) : undefined)}
                                                        value={this.props.policy.maxMileage}
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Max kilometers provided")}
                                                    />
                                                </Grid>
                                            </Div>
                                        </FieldSet>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label={"Start date extended warranty"}
                                                    // eslint-disable-next-line max-len
                                                    onChange={(value?: string | number) => this.onChange("startDateExtendedWarranty",
                                                        value ? value.toString() : "")}
                                                    value={this.props.policy.startDateExtendedWarranty}
                                                    required
                                                    type="date"
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            validFromValidation(value.toString(), undefined)}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <FormFields.FormField
                                                        label="Extended warranty"
                                                        required
                                                        placeholder={"Select a product"}
                                                        readonly
                                                        validationFunction={
                                                            (value: string | number) =>
                                                                hasValueValidation(value.toString(),
                                                                    "Extended warranty selected")}
                                                        value={this.props.policy?.extendedWarranty?.id}
                                                    />
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            right: "10px",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                        }}
                                                    >
                                                        <Button
                                                            icon={
                                                                this.props.policy?.extendedWarranty?.id
                                                                    ? IconNames.EDIT : IconNames.PLUS}
                                                            theme={{
                                                                palette: ThemePalette.CONTRAST_PRIMARY,
                                                                shadow: ThemeShadowSizes.TINY,
                                                            }}
                                                            link={{
                                                                onClick: () =>
                                                                    this.props.selectProduct(
                                                                        "select-product-modal",
                                                                        this.onChange,
                                                                        // eslint-disable-next-line max-len
                                                                        this.props.policy?.extendedWarranty?.id?.toString(),
                                                                    ),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Div>
                                        <Required />
                                    </Container>
                                </Div>
                                <FormBar
                                    buttonLabel={this.props.edit ? "Update" : "Create"}
                                    title={this.props.edit ? "Correct policy" : "Create policy"}
                                    hasRequiredFields
                                    isValid={!!this.props.canSubmitPolicyForm ?
                                        this.props.canSubmitPolicyForm : undefined}
                                />
                            </Form>
                        </>
                        )}
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof PolicyRequest>(key: K, value: PolicyRequest[K]) => {
        this.props.updatePolicy({
            [key]: value,
        });
    };

    private addPolicy() {
        this.props.addPolicy(this.props.policy);
    }

    private editPolicy() {
        this.props.editPolicy(this.props.policy);
    }
}

/**
 *
 */
export const CreatePolicy = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreatePolicyComp);
