import React from "react";
import { Policy } from "../../../../../../../../store/Policies/Types";
import { GeneralView } from "../../../../../00-blocks/Policies/component/GeneralView";

interface Props {
    policy: Policy;
}
/**
 *
 * @param props
 */
export const General: React.FC<Props> = (props: Props) => (
    <GeneralView general={props.policy}/>
);
