import * as React from "react";
import { connect } from "react-redux";
import { FlexWrapperFocus } from "../../../../components/core/03-base/FlexWrapper";
import { Navbar } from "../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackLeft,
    NavbarStackContent, NavbarStackRight } from "../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { signOut } from "../../../../store/AppActions";
import { UserInfo } from "../../../../store/AppTypes";
import { ReduxState } from "../../../../store/ReduxState";
import { ThemePalette, ThemeShadowSizes } from "../../../../theme/_Types";
import { flexSetFocus } from "../../../../utils/redux/ActionTypes";
import { LogoutButton } from "../Sidebar";
import * as I18n from "../../../../utils/translations/I18n";
import { Button } from "../../../../components/core/05-atoms/Button";

interface OwnProps { }

interface DispatchProps {
    setFocus: () => void;
    signOut: (nav: () => void) => void;
}

interface StateProps {
    user?: UserInfo;
}

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFocus: () => dispatch(flexSetFocus(FlexWrapperFocus.SIDEBAR)),
    signOut: (nav: () => void) => dispatch(signOut(false, nav)),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
    user: state.prop("user").isPresent() ? state.prop("user").get() : undefined,
});

type Props = OwnProps & StateProps & DispatchProps;

class HomeNavBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Navbar
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    shadow: ThemeShadowSizes.SMALL,
                }}
                className={"scl-o-navbar__toggle-sidebar"}
            >
                <NavbarStack>
                    <NavbarStackLeft>
                        {
                            <NavbarIcon
                            >
                                <Button icon={"bars"}
                                    className="scl-o-navbar__icon"
                                    link={{ onClick: () => this.props.setFocus() }}
                                />
                            </NavbarIcon>
                        }
                    </NavbarStackLeft>
                    <NavbarStackContent>
                        <NavbarTitle>{I18n.getText("app.app-title", "Extended Warranty System")}</NavbarTitle>
                    </NavbarStackContent>
                    <NavbarStackRight>
                        {this.props.user ?
                            <LogoutButton signOut={this.props.signOut} />
                            : <div style={{width: "64px", height: "64px"}} />}
                    </NavbarStackRight>
                </NavbarStack>
            </Navbar>
        );
    }
}

export const HomeNavBarWrapper: React.FunctionComponent<OwnProps> = props => (<HomeNavBarComp {...props as Props} />);

/**
 *
 */
export const HomeNavBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeNavBarWrapper);

export const testUtil = {
    mapDispatchToProps: mapDispatchToProps,
    mapStateToProps: mapStateToProps,
};
