
import * as React from "react";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { Form } from "../../../../../../components/core/07-organisms/Form";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { Partner } from "../../../../../../store/Partners/Types";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";

interface Props {
    general: Partner;
}
/**
 *
 * @param props
 */
export const GeneralView: React.FC<Props> = (props: Props) => (
    <React.Fragment>
        <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
            <Container
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    padding: {
                        "": { y: 3 },
                        "sm": { y: 4 },
                    },
                }}
            >
                <Form>
                    <FieldSet title="Partner info">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Name"
                                    value={props.general.name}
                                    asSummary
                                />
                            </Grid>

                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Partner number"
                                    value={props.general.partnerNumber}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Partner number (PAH)"
                                    value={props.general.partnerNumberPah}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Partner Type"
                                    value={props.general.partnerType}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                    {
                        (props.general.invoiceBalance !== 0 ||
                                props.general.claimBalance !== 0 ||
                                props.general.totalBalance !== 0) &&
                            <FieldSet title="Wallet">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Invoice Balance"
                                            value={props.general.invoiceBalance}
                                            asSummary
                                            type={"currency"}
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Claim Balance"
                                            value={props.general.claimBalance}
                                            asSummary
                                            type={"currency"}
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Total Balance"
                                            value={props.general.totalBalance}
                                            asSummary
                                            type={"currency"}
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                    }
                    {(props.general.iban || props.general.bic) &&
                            <FieldSet title="Bank">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="IBAN"
                                            value={props.general.iban}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="BIC"
                                            value={props.general.bic}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                    }
                    <FieldSet title="Contact">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Email"
                                    value={props.general.email}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Address line 1"
                                    value={props.general.addressLine1}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Address line 2"
                                    value={props.general.addressLine2}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Zip code"
                                    value={props.general.zipCode}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="House number"
                                    value={props.general.houseNumber}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="House number"
                                    value={props.general.houseNumberAddition}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 3 }}
                            >
                                <FormField
                                    label="Residence"
                                    value={props.general.residence}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                </Form>
            </Container>
        </Div>
    </React.Fragment>
);
