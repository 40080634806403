import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { PaymentOperationTypeDisplay } from "../../../../../store/AuditTrail/Displays";
import { Payment } from "../../../../../store/AuditTrail/PaymentTypes";
import { CreditDebitIndicatorTypeDisplay } from "../../../../../store/Statements/Displays";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";

interface OwnProps {
    details: Payment;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Payment id"
                            asSummary
                            value={this.props.details.id}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.invoiceNumber}
                            label="Invoice number"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Invoice date"
                            asSummary
                            value={this.props.details.invoiceDate}
                            type={"date"}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Mutation type"
                            asSummary
                            value={this.props.details.creditDebitIndicator === CreditDebitIndicator.DBIT
                                ? CreditDebitIndicatorTypeDisplay.DBIT : CreditDebitIndicatorTypeDisplay.CRDT}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Operation"
                            asSummary
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            value={PaymentOperationTypeDisplay[this.props.details?.operation ?? ""]}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.amount}
                            label="Amount"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}
