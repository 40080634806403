import React from "react";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";

import * as FormFields from "../../../../../../components/core/07-organisms/Form/Field";
import { Statement } from "../../../../../../store/Statements/Types";

interface OwnProps {
    details: Statement;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Account"
                            asSummary
                            value={this.props.details.account.iban}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Mutation date"
                            asSummary
                            value={this.props.details.mutationDate}
                            type={"date"}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Mutation type"
                            asSummary
                            value={this.props.details.statementStatus}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Sequence number"
                            asSummary
                            value={this.props.details.seqNumber}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.stmtId}
                            label="Statement Id"
                            asSummary
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}

