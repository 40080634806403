/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/indent */
import { OrderedSet } from "immutable";
import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as I18n from "../../../utils/translations/I18n";

import backgroundImage from "../../assets/images/sidebar-bg.png";
import { DashboardMenu } from "./00-blocks/DashboardMenu";
import { FlexColumn } from "../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../components/core/03-base/Flex/Row";
import { FlexSidebar } from "../../../components/core/03-base/Flex/Sidebar";
import { FlexWrapperFocus } from "../../../components/core/03-base/FlexWrapper";
import { IconNames } from "../../../components/core/05-atoms/Icon";
import { ReleaseVersion } from "../../../components/core/05-atoms/ReleaseVersion";
import { Modal } from "../../../components/core/06-molecules/Modal";
import { NavbarIcon } from "../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStackContent, NavbarStackRight } from "../../../components/core/07-organisms/Navbar/Stack";
import { DispatchFunc } from "../../../store/ActionTypes";
import { signOut, toggleSidebar } from "../../../store/AppActions";
import { Pages, UserRolesDisplays } from "../../../store/AppDisplays";
import { UserInfo } from "../../../store/AppTypes";
import { ReduxState, isRemoteLoading } from "../../../store/ReduxState";
import { RemoteScope } from "../../../store/RemoteTypes";
import { VersionRequest } from "../../../store/Version/RemoteConfig";
import { ThemeSize, ThemePalette, Themes, ThemeVariants, ThemeShadowSizes } from "../../../theme/_Types";
import { BreakpointConsumer } from "../../../utils/Breakpoints";
import { flexSetFocus, modalClose, modalShow } from "../../../utils/redux/ActionTypes";
import { Button } from "../../../components/core/05-atoms/Button";
import { UserInfo as UserInfoComp } from "../../../components/core/06-molecules/UserInfo";
import { SwipeBlock } from "../../../utils/swipe";
import { Logo } from "../../../components/core/03-base/Logo";
import { ModalAccountSettings } from "../../../components/core/09-views/03-modals/AccountSettings";

export const LogoutButton: React.FC<{
    signOut: (nav: () => void) => void;
}> = props => {
    const history = useHistory();
    return (
        <NavbarIcon>
            <Button
                link={{ onClick: () => props.signOut(() => history.push("/")) }}
                icon={IconNames.POWER_OFF}
            />
        </NavbarIcon>
    );
};

/**
 *
 */
interface StateProps {
    user?: UserInfo;
    availablePages: OrderedSet<Pages>;
    apiVersion?: VersionRequest;
    isLoadingApiVersion: boolean;
    sidebarCollapsed: boolean;
}

/**
 *
 */
interface DispatchProps {
    toggleSidebar: (force?: boolean) => void;
    signOut: (nav: () => void) => void;
    setFocus: () => void;
    openAccountSettingsModal: (user?: UserInfo) => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    sidebarCollapsed: s.prop("sidebarCollapsed"),
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
    availablePages: s.prop("pages").prop("pages"),
    apiVersion: s.prop("remote").prop(RemoteScope.VERSION),
    isLoadingApiVersion: isRemoteLoading(s, RemoteScope.VERSION),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    signOut: (nav: () => void) => dispatch(signOut(false, nav)),
    toggleSidebar: (force?: boolean) => dispatch(toggleSidebar(force)),
    setFocus: () => dispatch(flexSetFocus(FlexWrapperFocus.SIDEBAR_INACTIVE)),
    openAccountSettingsModal: (user?: UserInfo) => {
        dispatch(
            modalShow(
                "change password",
                () => (
                    <Modal
                        modalID={"change password"}
                        theme={{ size: ThemeSize.SMALL }}
                    >
                        <ModalAccountSettings
                            id={"change password"}
                            user={{
                                name: user?.email,
                                subtitle: user?.userRole
                                ? UserRolesDisplays[user.userRole]
                                : undefined,
                            }}
                            closeModal={() => dispatch(modalClose("change password"))}
                            onLogout={() => {
                                dispatch(modalClose("change password"));
                                dispatch(signOut(false, () => console.log("Claude has logged out.")));
                            }}
                        />
                    </Modal>
                ),
            ),
        );
    },
});

/**
 *
 * @param _props
 */
class SideBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.updateCollapseState = this.updateCollapseState.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.updateCollapseStateWithIcon = this.updateCollapseStateWithIcon.bind(this);
    }

    public render() {
        return (
            <SwipeBlock detectSwipeDir={(value: string) => this.updateCollapseState(value === "Left")}>
                <FlexSidebar
                    isCollapsed={this.props.sidebarCollapsed}
                >
                    <BreakpointConsumer>
                        {(value) => (
                             <>
                        <FlexRow theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                            <FlexColumn hasScrollable className="v-sidebar"
                                style={{ backgroundImage: `url(${backgroundImage})` }}>
                                {(!value.breakpointAtSM || this.props.user) &&
                                    (
                                        <FlexRow
                                            isFixed
                                            className="v-sidebar__userinfo"
                                        >
                                            <>
                                                <NavbarStackContent>
                                                    {this.props.user && (!this.props.sidebarCollapsed || !value.breakpointAtSM) ?
                                                        <>
                                                            <UserInfoComp
                                                                name={this.props.user.userDisplay
                                                                    ? this.props.user.userDisplay : "Loggedin as,"}
                                                                role={this.props.user.userRole
                                                                    ? UserRolesDisplays[this.props.user.userRole]
                                                                    : undefined}
                                                            />
                                                        </>
                                                        : <></>}
                                                </NavbarStackContent>
                                                <NavbarStackRight isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}>
                                                    {
                                                        <>
                                                            {(this.props.sidebarCollapsed && value.breakpointAtSM) &&
                                                                <NavbarIcon>
                                                                    <Button
                                                                        className={"scl-o-navbar__icon"}
                                                                        link={{ href: "/" }}
                                                                        icon="home" />
                                                                </NavbarIcon>
                                                            }
                                                            {this.props.user &&
                                                                <>
                                                                        <NavbarIcon>
                                                                            <Button
                                                                                link={{  onClick:
                                                                                    () => this.props.openAccountSettingsModal
                                                                                        (this.props.user) }}
                                                                                className={"scl-o-navbar__icon"}
                                                                                icon="cog"
                                                                            />
                                                                        </NavbarIcon>
                                                                        {value.breakpointAtSM &&
                                                                            <LogoutButton signOut={this.props.signOut} />
                                                                        }
                                                                </>
                                                            }
                                                        {!value.breakpointAtSM &&
                                                        <NavbarIcon>
                                                            <Button
                                                                className={"scl-o-navbar__icon"}
                                                                link={{
                                                                    onClick: this.setFocus(),
                                                                }}
                                                                icon={IconNames.TIMES}
                                                            />
                                                        </NavbarIcon>
                                                        }
                                                        </>
                                                    }
                                                </NavbarStackRight>
                                            </>
                                        </FlexRow>
                                    )
                                }

                                <FlexRow isScrollable className="v-sidebar__menu">
                                    <FlexColumn className="v-sidebar__menu-content">
                                        {(this.props.user && this.props.availablePages && (!this.props.sidebarCollapsed || !value.breakpointAtSM)) &&
                                            <DashboardMenu
                                                tabs={this.props.availablePages}
                                            />
                                        }
                                           <FlexRow className={(this.props.sidebarCollapsed && value.breakpointAtSM) ? "v-sidebar__version-collapsed" : "v-sidebar__version"} isCollapsed={this.props.sidebarCollapsed || !value.breakpointAtSM}>
                                                 {(!this.props.sidebarCollapsed || !value.breakpointAtSM) &&
                                                <>
                                                    <div className="v-sidebar__title">
                                                        {I18n.getText("app.app-title", "Extended Warranty System")}
                                                    </div>
                                                    <Logo theme={Themes.VWPFS} variant={ThemeVariants.CONTRAST} className="v-sidebar__logo--vwpfs" />
                                                    <span className={"scl-h-b scl-h-text--tiny scl-h-foreground--contrast-text-body"}>by</span><Logo theme={Themes.CLAUDE} variant={ThemeVariants.CONTRAST} className="v-sidebar__logo--claude" />
                                                </>
                                                }
                                                <ReleaseVersion
                                                    isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}
                                                    apiVersion={this.props.isLoadingApiVersion
                                                        ? "Loading.." : this.props.apiVersion?.commit}
                                                />
                                            </FlexRow>
                                    </FlexColumn>
                                </FlexRow>
                            </FlexColumn>
                        </FlexRow>
                        {(value.breakpointAtSM) && (
                            <Button
                                link={{ onClick: this.updateCollapseStateWithIcon(!this.props.sidebarCollapsed) }}
                                theme={{
                                    palette: ThemePalette.BRAND_ACCENT,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                                className="m-info-card__icon"
                                icon={this.props.sidebarCollapsed ? "chevron-right" : "chevron-left"}
                                style={{ marginRight: "-35px", top: "50%" }}
                            />
                        )}
                         </>
                    )}
                    </BreakpointConsumer>
                </FlexSidebar>
            </SwipeBlock>
        );
    }

    private updateCollapseStateWithIcon(collapse: boolean) {
        return () => this.updateCollapseState(collapse);
    }

    private updateCollapseState(collapse: boolean) {
        this.props.toggleSidebar(collapse);
    }

    private setFocus() {
        return () => this.props.setFocus();
    }
}

const SideBarWrapper: React.FunctionComponent = props => (<SideBarComp {...props as Props} />);

export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarWrapper);
