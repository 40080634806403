import React from "react";
import { Tariffs } from "../../../../../00-blocks/Product/Tabs/Tariffs";
import { Product } from "../../../../../../../../store/Product/Types";

interface Props {
    product: Product;
}
/**
 *
 * @param props
 */
export const AuditTariffs: React.FC<Props> = (props: Props) => (
    <Tariffs tariffs={props.product.productTariffs} asAudit />
);
