import React from "react";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { InfoCard } from "../../../../components/core/06-molecules/InfoCard";
import { PageDisplay, Pages, SubPages, SubPageDisplay } from "../../../../store/AppDisplays";
import { Header } from "../../../../components/core/09-views/01-sections/Header";

export const Manage: React.FunctionComponent<{}> = () => (
    <Page>
        <Container
            theme={{
                padding: {
                    "": { y: 3 },
                    "sm": { y: 4 },
                },
            }}
        >
            <Header
                title={PageDisplay[Pages.MANAGE].title}
                description={PageDisplay[Pages.MANAGE].description}
            />
            <Div className="scl-b-row">
                {PageDisplay[Pages.MANAGE].subpages
                    // eslint-disable-next-line max-len
                    .filter((page: SubPages) => page === SubPages.MANAGE_BRANDS || page === SubPages.MANAGE_MODELS || page === SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS)
                    .map((page: SubPages, index) => (
                        <Grid key={index} size={{ xs: 12, md: 6, lg: 3 }}>
                            <InfoCard
                                title={SubPageDisplay[page].title}
                                description={SubPageDisplay[page].description}
                                navLink={SubPageDisplay[page].path}
                                beta={SubPageDisplay[page].beta}
                            />
                        </Grid>
                    ))}
            </Div>
        </Container>
    </Page>
);
