import { RequestObject } from "../RemoteTypes";
import { Message } from "../Message/Types";
import { Account } from "../Accounts/Types";
import { InvoicePayment } from "../CollectiveInvoice/Types";

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type StatementFilters = Readonly<Partial<{
    mutationDate: string;
    accountId: string;
    creationDate: string;
    stmtId: string;
}>>;

export enum CreditDebitIndicator {
    DBIT = "DBIT",
    CRDT = "CRDT",
}

export enum BookedObjectType {
    INCASSO = "INCASSO",
    EXCASSO = "EXCASSO",
    COLLECTIVE_INVOICE = "COLLECTIVE_INVOICE",
    PARTNER_INVOICE = "PARTNER_INVOICE",
    GENERAL_LEDGER_INVOICE = "GENERAL_LEDGER_INVOICE",
    CLAIM_INVOICE = "CLAIM_INVOICE",
}

export enum StatementLineStatus {
    UNMATCHED = "UNMATCHED",
    MATCHED = "MATCHED",
    PARTIALLY_MATCHED = "PARTIALLY_MATCHED",
}

export enum BalanceType {
    OPENINGBALANCE = "OPBD",
    CLOSINGBALANCE = "CLBD",
}

export enum BookedStatus {
    BOOKED = "BOOKED",
    NOT_BOOKED = "NOT_BOOKED",
    PARTIALLY_BOOKED = "PARTIALLY_BOOKED",
}

export enum StatementStatus {
    PROCESSED = "PROCESSED",
    UNPROCESSED = "UNPROCESSED",
    PROCESSING = "PROCESSING",
    ERROR = "ERROR",
}

export interface Amount {
    currencyCode: string;
    amount: number;
}

interface PartyIdentification {
    name: string;
    privateOtherId: string;
    schemeName: string;
}

interface StructuredRemittances {
    id: number;
    createdAt: string;
    creditorReferenceTypeCode: string;
    creditorReferenceTypeIssuer: string;
    creditorReference: string;
    transactionDetails: any; // check
}

export interface UnstructuredRemittances {
    id: number;
    createdAt: string;
    unstructured: string;
    transactionDetails: any; // check
}

export interface TransactionDetails {
    id: number;
    createdAt: string;
    references: {
        pmtInfId: string;
        endToEndId: string;
        mndtId: string;
    };
    amountDetails: {
        transactionAmount: Amount;
        instructedAmount: Amount;
        iaCurrencyExchange: {
            sourceCurrency: string;
            exchangeRate: number;
        };
    };
    relatedParties: {
        creditor: PartyIdentification;
        debtor: PartyIdentification;
        creditorIBAN: string;
        debtorIBAN: string;
    };
    unstructuredRemittances: UnstructuredRemittances[];
    structuredRemittances: StructuredRemittances[];
    returnInformationCode: string;
    entryDetails: any; // check
}

interface Batch {
    pmtInfId: string;
    nbOfTxs: string;
    totalAmount: Amount;
    creditDebitIndicator: CreditDebitIndicator;
}

interface EntryDetails {
    id: number;
    createdAt: string;
    batch: Batch;
    transactionDetails: TransactionDetails[];
    entry: Entry; // check
}

export interface BankTransactionCode {
    domainCode: string;
    domainFamilyCode: string;
    domainSubFamilyCode: string;
    proprietoryCode: string;
    proprietoryIssuer: string;
}

export interface Entry {
    id: number;
    createdAt: string;
    amount: Amount;
    balance: number;
    creditDebitIndicator: CreditDebitIndicator;
    reversalIndicator: boolean;
    status: string;
    bookingDate: string;
    valueDate: string;
    accountServicerReference: string;
    bankTransactionCode: BankTransactionCode;
    entryDetails: EntryDetails[];
    statementLineStatus: StatementLineStatus;
    statement: Statement;
    ipayments: InvoicePayment[];
}

export interface Balance {
    id: number;
    createdAt: string;
    balanceTypeCode: BalanceType;
    amount: Amount;
    creditDebitIndicator: CreditDebitIndicator;
    date: string;
    statement: Statement; // check
}

interface StatementMessage extends Message {
    msgId: string;
    creationDateTime: string;
    statements: any[]; // check
}

export interface Statement {
    id: number;
    createdAt: string;
    message: StatementMessage;
    stmtId: string;
    seqNumber: number;
    creationDateTime: string;
    mutationDate: string;
    accnt: Account;
    account: Account;
    balances: Balance[];
    entries: Entry[];
    statementStatus: StatementStatus;
}

export interface Statements extends RequestObject {
    content: Statement[];
}

export interface StatementDetail extends Statement {
}

export interface StatementDetails {
    content: StatementDetail;
}

interface BookedStatement {
    amount: number;
    creditDebitIndicator: CreditDebitIndicator;
    bookedObjectType: BookedObjectType;
    bookedObjectNumber: string;
    bookedObjectId: number;
}

export interface BookedStatements {
    content: BookedStatement[];
}

export interface UnbookStatementLine {
    bookedObjectId: number;
    bookedObjectType: BookedObjectType;
}
