import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { ReduxState } from "./ReduxState";

/**
 * Thunk is the ThunkAction type used by Hermann for declaring thunks / action
 * creators that return thunks.
 *
 * In ThunkAction<R, S, E, A>:
 * R => Return value, a plain redux Action type returned by the thunk (if
 * applicable). May be void.
 * S => State type, return type of Dispatch<S> and getState().
 * E => ExtraArgument, unused.
 * A => ?
 */
export type Thunk<R extends Action<ActionTypeKeys> | void> =
 ThunkAction<R, ReduxState, undefined, Action<ActionTypeKeys>>;

/**
 * DispatchFunc gives an alias for the Dispatch function provided by Redux for
 * easier type handling.
 */
// export type DispatchFunc = Dispatch<Action<ActionTypeKeys>>;
export type DispatchFunc = ThunkDispatch<
ReduxState,
undefined,
Action<ActionTypeKeys>
>;

/**
 * StateFunc gives an aliad for the getState function provided by redux-thunk
 * for easier type handling.
 */
export type StateFunc = () => ReduxState;

/**
 * ReduxStateReducer
 */
export type ReducerFunc<S, A extends Action<ActionTypeKeys>> = (state: S, action: A) => S;

/*
 * Config specifies environment-related configuration parameters for the client.
 */
export type Config = Readonly<{
    apiEndpoint: string;
}>;

/**
 *
 */
export enum ActionTypeKeys {
    /**
     * APP_INIT is triggered when the App component is mounted, meaning the user
     * is successfully authenticated and we can start loading the first dataset.
     */
    APP_INIT = "APP_INIT",
    SIGN_OUT = "SIGN_OUT",
    SIGNED_OUT = "SIGNED_OUT",
    REMOTE_TRIGGER = "REMOTE_TRIGGER",
    REMOTE_CANCEL = "REMOTE_CANCEL",
    REMOTE_RESPONSE = "REMOTE_SUCCESS",
    REMOTE_ERROR = "REMOTE_ERROR",
    REMOTE_CLEAR_ERROR = "REMOTE_CLEAR_ERROR",
    REMOTE_CLEAR_RESPONSE = "REMOTE_CLEAR_RESPONSE",
    UPDATE_FILTERS = "UPDATE_FILTER",
    UPDATE_FORMS = "UPDATE_FORMS",
    UPDATE_FORM_FIELDS = "UPDATE_FORM_FIELDS",
    FORM_SUBMIT_STATE = "FORM_SUBMIT_STATE",
    CLEAR_FORM_SUBMIT_STATE = "CLEAR_FORM_SUBMIT_STATE",
    CLEAR_FILTERS = "CLEAR_FILTERS",
    STATEMENT_DELETE_REQUEST = "STATEMENT_DELETE_REQUEST",
    INIT_UPDATE_REPORT_FORM = "INIT_UPDATE_REPORT_FORM",
    UPDATE_REPORT_FORM = "UPDATE_REPORT_FORM",
    CLEAR_REPORT_FORM = "CLEAR_REPORT_FORM",
    UPDATE_COLLECTIVE_INVOICE_FORM = "UPDATE_COLLECTIVE_INVOICE_FORM",
    CLEAR_COLLECTIVE_INVOICE_FORM = "CLEAR_COLLECTIVE_INVOICE_FORM",
    INIT_UPDATE_INEX_FORM = "INIT_UPDATE_INEX_FORM",
    UPDATE_INEX_FORM = "UPDATE_INEX_FORM",
    CLEAR_INEX_FORM = "CLEAR_INEX_FORM",
    FILE_DOWNLOAD_INIT = "FILE_DOWNLOAD_INIT",
    UPDATE_BOOK_DESCRIPTION = "UPDATE_BOOK_DESCRIPTION",
    UPDATE_MANUAL_BOOK_DESCRIPTION = "UPDATE_MANUAL_BOOK_DESCRIPTION",
    UPDATE_MANUAL_BOOK_DATE = "UPDATE_MANUAL_BOOK_DATE",
    CLEAR_BOOK_DESCRIPTION = "CLEAR_BOOK_DESCRIPTION",
    CLEAR_MANUAL_BOOK_DESCRIPTION = "CLEAR_MANUAL_BOOK_DESCRIPTION",
    CLEAR_MANUAL_BOOK_DATE = "CLEAR_MANUAL_BOOK_DATE",
    UPDATE_PRODUCT = "UPDATE_PRODUCT",
    INIT_UPDATE_PRODUCT = "INIT_UPDATE_PRODUCT",
    INIT_UPDATE_PRODUCT_PROXY = "INIT_UPDATE_PRODUCT_PROXY",
    UPDATE_POLICY = "UPDATE_POLICY",
    INIT_UPDATE_POLICY = "INIT_UPDATE_POLICY",
    CLEAR_PRODUCT = "CLEAR_PRODUCT",
    CLEAR_POLICY = "CLEAR_POLICY",
    CREATE_TARIFF = "CREATE_TARIFF",
    ADD_TARIFF = "ADD_TARIFF",
    DELETE_TARIFF = "DELETE_TARIFF",
    UPDATE_MANUAL_BOOKING = "UPDATE_MANUAL_BOOKING",
    DELETE_MANUAL_BOOKING = "DELETE_MANUAL_BOOKING",
    CLEAR_MANUAL_BOOKINGS = "CLEAR_MANUAL_BOOKINGS",
    CREATE_ACCOUNT = "CREATE_ACCOUNT",
    CLEAR_ACCOUNT = "CLEAR_ACCOUNT",
    UPDATE_TARIFF = "UPDATE_TARIFF",
    UPDATE_TARIFF_AUTO = "UPDATE_TARIFF_AUTO",
    CLEAR_TARIFF = "CLEAR_TARIFF",
    INIT_UPDATE_TARIFF = "INIT_UPDATE_TARIFF",
    TARIFF_DELETE_REQUEST = "TARIFF_DELETE_REQUEST",
    INIT_UPDATE_GL = "INIT_UPDATE_GL",
    UPDATE_GL = "UPDATE_GL",
    CLEAR_GL = "CLEAR_GL",
    INIT_UPDATE_PARTNER = "INIT_UPDATE_PARTNER",
    UPDATE_PARTNER = "UPDATE_PARTNER",
    CLEAR_PARTNER = "CLEAR_PARTNER",
    INIT_UPDATE_MANDATE = "INIT_UPDATE_MANDATE",
    UPDATE_MANDATE = "UPDATE_MANDATE",
    CLEAR_MANDATE = "CLEAR_MANDATE",
    INIT_UPDATE_PREMIUM_POLICY = "INIT_UPDATE_PREMIUM_POLICY",
    UPDATE_PREMIUM_POLICY = "UPDATE_PREMIUM_POLICY",
    CLEAR_PREMIUM_POLICY = "CLEAR_PREMIUM_POLICY",
    ADD_PRODUCT_TARIFF = "ADD_PRODUCT_TARIFF",
    CLEAR_PRODUCT_TARIFF = "CLEAR_PRODUCT_TARIFF",
    INIT_UPDATE_MEMO = "INIT_UPDATE_MEMO",
    UPDATE_MEMO = "UPDATE_MEMO",
    CLEAR_MEMO = "CLEAR_MEMO",
    INIT_UPDATE_BRAND = "INIT_UPDATE_BRAND",
    UPDATE_BRAND = "UPDATE_BRAND",
    CLEAR_BRAND = "CLEAR_BRAND",
    INIT_UPDATE_MODEL = "INIT_UPDATE_MODEL",
    UPDATE_MODEL = "UPDATE_MODEL",
    CLEAR_MODEL = "CLEAR_MODEL",
    DELETE_MANUAL_BOOKING_SELECTION = "DELETE_MANUAL_BOOKING_SELECTION",
    CLEAR_TARIFF_MODEL_FILTER = "CLEAR_TARIFF_MODEL_FILTER",
    UPDATE_MODEL_ON_BRAND_CHANGE = "UPDATE_MODEL_ON_BRAND_CHANGE",
    UPDATE_PARTNERS_CI = "UPDATE_PARTNERS_CI",
    DELETE_PARTNERS_CI = "DELETE_PARTNERS_CI",
    CLEAR_PARTNERS_CI = "CLEAR_PARTNERS_CI",
    DELETE_SINGLE_PARTNER_CI = "DELETE_SINGLE_PARTNER_CI",
    CLEAR_INVOICE_PARTNER_FILTER = "CLEAR_INVOICE_PARTNER_FILTER",
    TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
}
