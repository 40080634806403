import * as React from "react";
import { connect } from "react-redux";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { FilterScope, getFilters } from "../../../../../store/FilterTypes";
import { InvoiceFilters } from "../../../../../store/Invoice/Types";
import { Partners } from "../../../../../store/Partners/Types";
import { getPartnerTypeColor } from "../../../../utils/FormatHelpers";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import { Container } from "../../../../../components/core/03-base/Container";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { TableSelectCell } from "../../../06-molecules/TableSelectCell";

interface selectedRow {
    id: string;
    name: string;
}

interface State {
    selectedRows: selectedRow[];
}

/**
 *
 */
interface StateProps {
    filters: InvoiceFilters;
    loading: boolean;
}

/**
 *
 */
interface OwnProps {
    results: Partners;
    onChange: <K extends keyof InvoiceFilters>(key: K, value: InvoiceFilters[K]) => void;
    partnerNumber?: string;
    partnerName?: string;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    filters: getFilters(s, FilterScope.INVOICES) as InvoiceFilters,
    loading:
            isRemoteLoading(s, RemoteScope.PARTNER_RESULTS)
    ,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.PARTNER_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results && this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private selectItem(value: boolean | undefined, id: string, name: string) {
        this.setState({
            selectedRows: !!value ? [
                {id: id, name: name},
            ] : [],
        },
        () => {
            this.props.onChange("partnerId", this.state.selectedRows[0]?.id
                ? Number(this.state.selectedRows[0].id) : undefined);
            this.props.onChange("partnerName", this.state.selectedRows[0]?.name
                ? this.state.selectedRows[0].name : undefined);
        });
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: this.props.partnerNumber && this.props.partnerName ? [
                {id: this.props.partnerNumber, name: this.props.partnerName},
            ] : [],
        };
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private amIChecked(id: string, selectedRows: selectedRow[]): boolean {
        return selectedRows.some((row) => row.id === id);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const checked = this.amIChecked(row.id.toString(), this.state.selectedRows);
            return ({
                id: row.id,
                data: [
                    (
                        <TableSelectCell
                            key={`select-${row.id}`}
                            checked={checked}
                            selectItem={() => this.selectItem(!checked, row.id.toString(), row.name)}
                        />
                    ),
                    row.name ? row.name : "",
                    row.partnerNumber,
                    row.partnerType ? (
                        <Badge
                            theme={{
                                palette: getPartnerTypeColor(row.partnerType),
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        >
                            {PartnerTypeDisplay[row.partnerType]}
                        </Badge>
                    ) : "",
                    row.iban,
                    <Currency amount={row.invoiceBalance} key={`partner-${index}`}/>,
                    <Currency amount={row.claimBalance} key={`partner-${index}`}/>,
                    (
                        <h6 key={`total-balance-${index}`}>
                            <Currency amount={row.totalBalance} key={`partner-${index}`}/>
                        </h6>
                    ),
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Name",
                },
                {
                    label: "Number",
                },
                {
                    label: "Type",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Invoice balance",
                },
                {
                    label: "Claim balance",
                },
                {
                    label: "Total balance",
                    alignRight: true,
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
