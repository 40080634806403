import React from "react";
import { Summary } from "../../../00-blocks/Summary";
import { Tabs } from "../../../00-blocks/Tabs";

import { connect } from "react-redux";

import { RouteComponentProps } from "react-router";
import { Header } from "../../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../../components/core/09-views/01-sections/Page";
import Moment from "react-moment";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { Blockquote } from "../../../../../../components/core/05-atoms/Blockquote";
import { Currency } from "../../../../../../components/core/05-atoms/CurrencyDisplay";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageTabs } from "../../../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../../../store/AppTypes";
import { BookingPeriods } from "../../../../../../store/BookingPeriod/Types";
import { ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { StatementDetails, BalanceType } from "../../../../../../store/Statements/Types";
import { withinClosedBookingPeriod } from "../../../../../utils/Booking";
import { getStatementStatusColor } from "../../../../../utils/FormatHelpers";
import { Container } from "../../../../../../components/core/03-base/Container";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";


interface Params {
    statementId: string;
    accountId: string;
}

interface StateProps {
    params: Params;
    details: StatementDetails;
    loading: boolean;
    bookingPeriodResults: BookingPeriods;
    user?: UserInfo;
}

interface DispatchProps {
    loadDetails: (id: string) => void;
    clearDetails: () => void;
    loadBookingPeriods: () => void;
    clearBookingPeriods: () => void;
}

type Props = DispatchProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    details: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementDetails,
    loading: isRemoteLoading(s, RemoteScope.STATEMENT_DETAILS) || isRemoteLoading(s, RemoteScope.BOOKING_PERIOD),
    bookingPeriodResults: s.prop("remote").prop(RemoteScope.BOOKING_PERIOD) as BookingPeriods,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadDetails: (id: string) => dispatch(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
        id: id,
    })),
    clearDetails: () => (
        dispatch(remoteClearResponse(RemoteScope.STATEMENT_DETAILS)),
        dispatch(remoteClearError(RemoteScope.STATEMENT_DETAILS))
    ),
    loadBookingPeriods: () => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD, { skip: 0 })),
    clearBookingPeriods: () => (
        dispatch(remoteClearResponse(RemoteScope.BOOKING_PERIOD)),
        dispatch(remoteClearError(RemoteScope.BOOKING_PERIOD))
    ),
});

/**
 *
 */
export class DetailComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadDetails(this.props.params.statementId);
        this.props.loadBookingPeriods();
    }

    public componentWillUnmount() {
        this.props.clearDetails();
        this.props.clearBookingPeriods();
    }

    public render() {
        const balanceOPBD = this.props.details && this.props.details.content &&
            this.props.details.content.balances && this.props.details.content.balances
            .find(balance => balance.balanceTypeCode === BalanceType.OPENINGBALANCE);
        const balanceCLBD = this.props.details && this.props.details.content &&
            this.props.details.content.balances && this.props.details.content.balances
            .find(balance => balance.balanceTypeCode === BalanceType.CLOSINGBALANCE);
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.ACCOUNTS_DETAIL].title}
                        goBack={"/accounts/" + this.props.params.accountId}
                    />
                    {
                        this.props.loading || !this.props.details ?
                            (
                                <LoadingData loading={!!this.props.loading} />
                            ) :
                            (
                                <>
                                    {this.props.user?.userRole !== UserRoles.EW_DEBTOR &&
                                        this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                                        withinClosedBookingPeriod(
                                            this.props.bookingPeriodResults,
                                            this.props.details,
                                            this.props.loading,
                                        ) &&
                                        <Blockquote>
                                            <h5>Pay attention</h5>
                                            <div className="scl-h-text">
                                                There is no open booking period for this statement
                                            </div>
                                        </Blockquote>}
                                    <Summary
                                        tableColumnData={[
                                            {
                                                label: "Statement Id",
                                            },
                                            {
                                                label: "Creation date",
                                            },
                                            {
                                                label: "Mutation date",
                                            },
                                            {
                                                label: "Status",
                                            },
                                            {
                                                label: "Opening Balance",
                                            },
                                            {
                                                label: "Closing Balance",
                                                alignRight: true,
                                            },
                                        ]}
                                        tablerowData={[
                                            {
                                                id: 1,
                                                data: [
                                                    this.props.details.content.stmtId,
                                                    <Moment
                                                        date={new Date(this.props.details.content.creationDateTime)}
                                                        format={"DD-MM-YYYY"}
                                                        key={this.props.details.content.id}
                                                    />,
                                                    this.props.details.content.mutationDate ?
                                                        (
                                                            <Moment
                                                                date={new Date(this.props.details.content.mutationDate)}
                                                                format={"DD-MM-YYYY"}
                                                                key={this.props.details.content.id}
                                                            />
                                                        ) : "",
                                                    (
                                                        <Badge
                                                            key={`badge-${this.props.details.content.id}`}
                                                            theme={{
                                                                paletteState:
                                                                    getStatementStatusColor(
                                                                        this.props.details.content.statementStatus),
                                                            }}
                                                        >
                                                            {this.props.details.content.statementStatus
                                                                ? this.props.details.content.statementStatus.toString()
                                                                : "n.a."}
                                                        </Badge>
                                                    ),
                                                    balanceOPBD
                                                        ? <h6><Currency amount={balanceOPBD.amount.amount} /></h6>
                                                        : "",
                                                    balanceCLBD
                                                        ? <h6><Currency amount={balanceCLBD.amount.amount} /></h6>
                                                        : "",
                                                ],
                                            }]}
                                    />
                                    <Tabs
                                        tabs={SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL].tabs}
                                        defaultTab={PageTabs.STATEMENTS_STATEMENT_LINES}
                                        params={{
                                            statementId: this.props.params.statementId,
                                            accountId: this.props.params.accountId,
                                        }}
                                    />
                                </>
                            )
                    }
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Detail = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <DetailComp {...props}/>);
