import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { RemoteScope } from "../RemoteTypes";
import { ReducerFn } from "../ReduxState";
import { ModelRequest } from "./Types";
import { State } from "../../utils/redux/State";

/**
 *
 */
export type InitUpdateModelAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_MODEL> & {
}
>;

/**
 *
 *
 */
export const initUpdateModel =
    (): InitUpdateModelAction => ({
        type: ActionTypeKeys.INIT_UPDATE_MODEL,
    });

/**
 *
 * @param s
 * @param _a
 */
export const initUpdateModelReducer:
ReducerFn<InitUpdateModelAction> =
    (s, _a) => {
        {
            const modelDetails = s.prop("remote").prop(RemoteScope.MODEL_DETAILS);
            return (
                s.setProp(
                    "modelRequest",
                    s.prop("modelRequest").update((_old) => modelDetails?.content ?? {}),
                )
            );
        }
    };

/**
 *
 */
export type UpdateModelAction = Readonly<
Action<ActionTypeKeys.UPDATE_MODEL> & {
    model?: Partial<ModelRequest>;
}
>;

/**
 *
 *
 */
export const updateModel =
    (model: ModelRequest): UpdateModelAction => ({
        type: ActionTypeKeys.UPDATE_MODEL,
        model: model,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateModelReducer:
ReducerFn<UpdateModelAction> =
    (s, a) => s.setProp(
        "modelRequest",
        s.prop("modelRequest").updateWith(
            a.model ? { ...a.model } : {},
        ),
    );

/**
 *
 */
export type ClearModelAction = Readonly<
Action<ActionTypeKeys.CLEAR_MODEL>
>;

/**
 *
 * @param keys
 */
export const clearModel:
() => ClearModelAction =
    () => ({
        type: ActionTypeKeys.CLEAR_MODEL,
    });

/**
 *
 */
export const clearModelReducer:
ReducerFn<ClearModelAction> =
    (s, _a) => (
        s.setProp(
            "modelRequest",
            State.create<ModelRequest>(() => ({})),
        )
    );
