import * as React from "react";

import { OrderedSet } from "immutable";
import { MenuOrientation, MenuItem, Menu } from "../../../../components/core/07-organisms/Menu";
import { Pages, PageDisplay } from "../../../../store/AppDisplays";

// StateProps are props derived from Redux state through MapStateToProps.
interface StateProps {
}

/**
 *
 */
interface OwnProps {
    tabs: OrderedSet<Pages>;
}

/**
 *
 */
interface DispatchProps {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;


/**
 *
 */
export const DashboardMenu = (props: Props) => (
    <Menu
        orientation={MenuOrientation.VERTICAL}
        items={getItems(props)}
    />
);

/**
 *
 * @param props
 */
const getItems = (props: Props): MenuItem[] =>
    props.tabs
        .map((page: Pages): MenuItem => ({
            children: PageDisplay[page].title,
            navLink: PageDisplay[page].path,
            beta: PageDisplay[page].beta,
        }))
        .toArray();
