import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { TariffDetails } from "../../../../../store/Tariffs/Types";
import { DetailFields } from "./DetailFields";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";

/**
 *
 */
interface OwnProps {
    modalID: string;
    tariffId: number;
}

interface StateProps {
    tariffDetails?: TariffDetails;
    remoteLoading: boolean;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    loadDetails: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    tariffDetails: s.prop("remote").prop(RemoteScope.TARIFF_DETAILS) as TariffDetails,
    remoteLoading: isRemoteLoading(s, RemoteScope.TARIFF_DETAILS),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    loadDetails: (id: number) => dispatch(remoteTrigger(RemoteScope.TARIFF_DETAILS, {
        id: id,
        modalID: id.toString(),
    })),
});

/**
 *
 */
export class DetailModalComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    public componentDidMount() {
        this.props.loadDetails(this.props.tariffId);
    }
    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    Tariff Details
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button  icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }} />
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn>
                        <FlexRow isScrollable >
                            <FillUp>
                                <Container
                                    theme={{
                                        padding: {
                                            "": { y: 3 },
                                            "md": { y: 4 },
                                        },
                                    }}
                                >
                                    {this.props.remoteLoading && !this.props.tariffDetails
                                        ? (
                                            <LoadingIndicator
                                                type={LoadingIndications.DEFAULT}
                                                theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                            />
                                        )
                                        : !!this.props.tariffDetails && (
                                            <Form>
                                                <DetailFields tariffDetails={this.props.tariffDetails} />
                                            </Form>)}
                                </Container>
                                <FlexRow isFixed>
                                    <FlexColumn
                                        theme={{
                                            palette: ThemePalette.CONTRAST_SECONDARY,
                                        }}
                                    >
                                        <Container
                                            theme={{
                                                padding: { "": { y: 3 } },
                                            }}
                                        >
                                            <ButtonsWrapper
                                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                alignment={Alignment.RIGHT}
                                            >
                                                <Button
                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                    link={{ onClick: this.closeModal() }}
                                                >
                                                    Close
                                                </Button>
                                            </ButtonsWrapper>
                                        </Container>
                                    </FlexColumn>
                                </FlexRow>
                            </FillUp>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }
}

/**
 *
 */
export const DetailModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DetailModalComp);
