import * as React from "react";
import * as I18n from "../../../utils/translations/I18n";

export const Required: React.FunctionComponent<{}> = () =>
    (
        <span className="scl-a-required">
            <sup className="scl-a-required__sup">*</sup>
            <span className="scl-a-required__description">{I18n.getText("mary.05-atoms.required.text",
                "Required fields.")}</span>
        </span>
    );
