import { ActionTypeKeys } from "../ActionTypes";
import { Action } from "redux";
import { TariffRequest } from "./Types";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { State } from "../../utils/redux/State";

/**
 *
 */
export type InitUpdateTariffAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_TARIFF> & {}
>;

/**
 *
 *
 */
export const initUpdateTariff =
    (): InitUpdateTariffAction => ({
        type: ActionTypeKeys.INIT_UPDATE_TARIFF,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateTariffReducer:
ReducerFn<InitUpdateTariffAction> =
    (s, _a) => {
        const tariffDetails = s.prop("remote").prop(RemoteScope.TARIFF_DETAILS);
        return (
            s.setProp(
                "tariffRequest",
                s.prop("tariffRequest").update((_old) => tariffDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateTariffAction = Readonly<
Action<ActionTypeKeys.UPDATE_TARIFF> & {
    tariff?: Partial<TariffRequest>;
}
>;

/**
 *
 *
 */
export const updateTariff =
    (tariff: Partial<TariffRequest>): UpdateTariffAction => ({
        type: ActionTypeKeys.UPDATE_TARIFF,
        tariff: tariff,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateTariffReducer:
ReducerFn<UpdateTariffAction> =
    (s, a) => (
        s.setProp(
            "tariffRequest",
            s.prop("tariffRequest").updateWith(a.tariff ? a.tariff : {}),
        )
    );

/**
 *
 */
export type ClearTariffAction = Readonly<
Action<ActionTypeKeys.CLEAR_TARIFF>
>;

/**
 *
 * @param keys
 */
export const clearTariff:
() => ClearTariffAction =
    () => ({
        type: ActionTypeKeys.CLEAR_TARIFF,
    });

/**
 *
 */

export const clearTariffReducer:
ReducerFn<ClearTariffAction> =
    (s, _a) => (
        s.setProp(
            "tariffRequest",
            State.create<TariffRequest>(() => ({})),
        )
    );
