import { Payments, PaymentFilters } from "./Types";
import { AxiosResponse } from "axios";

export const mapPaymentRequests =
    (resp: AxiosResponse): Payments => resp.data as Payments;

/**
 *
 */
export const PaymentFiltersToParams = (f: PaymentFilters) => ({
    ...f,
});
