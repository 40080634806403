import React from "react";
import { connect } from "react-redux";
import { Summary } from "../../../../00-blocks/Summary";
import { CreditDebitIndicator, StatementLineStatus, Entry } from "../../../../../../../store/Statements/Types";
import Moment from "react-moment";
import { Badge } from "../../../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../../../components/core/05-atoms/CurrencyDisplay";
import { ThemePaletteState } from "../../../../../../../theme/_Types";

interface OwnProps {
    statementLine: Entry | undefined;
    statementLineId: string;
}

type Props = OwnProps;

/**
 *
 */
export class SummaryComp
    extends React.Component<Props, {}> {

    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }
    /**
     *
     */
    public render() {

        const statementLine = this.props.statementLine;

        const summaryData = (): Array<string | number | JSX.Element | Date> => {
            if (statementLine) {
                const payInfArray: string[] = [];
                statementLine.entryDetails.forEach(detail => payInfArray.push(detail.batch?.pmtInfId));
                const messageArray: string[] = [];
                statementLine.entryDetails.forEach(trandetail => trandetail.transactionDetails.forEach(unstructured => (
                    unstructured.unstructuredRemittances
                        .forEach(remittance => messageArray.push(remittance.unstructured))
                )));
                return [
                    (
                        <Moment
                            date={new Date(statementLine.bookingDate)}
                            format={"DD-MM-YYYY"}
                            key={`statement-line-date${statementLine.id}`}
                        />),
                    messageArray.length > 0 ? messageArray.join() : payInfArray.length > 0 ? payInfArray.join() : "",
                    statementLine.statementLineStatus === StatementLineStatus.MATCHED ?
                        <Badge theme={{paletteState: ThemePaletteState.SUCCESS}}>Yes</Badge> :
                        <Badge theme={{paletteState: ThemePaletteState.DANGER}}>No</Badge>,
                    statementLine.creditDebitIndicator === CreditDebitIndicator.DBIT ? "DEBIT" : "CREDIT",
                    (
                        <Currency
                            key={`currency-${statementLine.id}}`}
                            amount={statementLine.amount.amount}
                        />
                    ),
                    (
                        <h6
                            key={`currency-h6-${statementLine.id}}`}>
                            <Currency
                                amount={statementLine.balance}
                            />
                        </h6>
                    ),
                ];
            }
            return [];
        };

        return (
            <Summary
                tableColumnData={[
                    {
                        label: "Booking date",
                    },
                    {
                        label: "Message",
                    },
                    {
                        label: "Booked",
                    },
                    {
                        label: "Mutation Type",
                    },
                    {
                        label: "Mutation Amount",
                    },
                    {
                        label: "Uncleared Balance",
                        alignRight: true,
                    },
                ]}
                tablerowData={[
                    {
                        id: 1,
                        data: summaryData(),
                    }]}
                noPadding
            />
        );
    }
}

/**
 *
 */
export const SummaryTab = connect(
    undefined,
    {},
)(SummaryComp);
