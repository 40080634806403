
import React from "react";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Currency } from "../../../../../../components/core/05-atoms/CurrencyDisplay";
import { Navbar } from "../../../../../../components/core/07-organisms/Navbar";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../../components/core/07-organisms/Navbar/Title";

import { ManualBookingAmount } from "../../../../../../store/ManualBooking/Types";
import { ThemePaletteState, ThemePalette } from "../../../../../../theme/_Types";
import { roundTwoDecimals } from "../../../../../utils/Booking";

interface ManualBookBarSelection {
    debitBalance: number;
    creditBalance: number;
    totalCalcBalance: number;
}

interface OwnProps {
    title: string;
    debitBalance: number;
    creditBalance: number;
    totalBalance: number;
    isValid?: boolean;
    notFixed?: boolean;
    selection?: ManualBookBarSelection;
}

/**
 *
 */
type Props = OwnProps;

export const navbarTheme = (isValid?: boolean) => {
    if (!!isValid) {
        return { paletteState: ThemePaletteState.SUCCESS };
    } else if (isValid === false) {
        return { paletteState: ThemePaletteState.DANGER };
    }
    return { palette: ThemePalette.BRAND_ACCENT };
};

export const style = (notFixed?: boolean) => {
    if (!!notFixed) {
        return undefined;
    } else {
        return {
            position: "fixed",
            left: "400px",
            bottom: 0,
            right: 0,
            zIndex: 9,
        } as React.CSSProperties | undefined;
    }
};

export const ManualBookBar: React.FunctionComponent<Props> = props => (
    <Div
        style={style(props.notFixed)}
    >
        {!!props.selection &&
                <div className={"scl-o-navbar scl-h-text-align--left"}>
                    <div
                        className="scl-o-navbar__content"
                        style={{
                            backgroundColor: "rgba(29, 31, 32, .85)",
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    Current selection
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                                    <Div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "stretch",
                                            }}
                                            theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                                        >
                                            <span
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        paddingLeft: "5px",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <span
                                                        className="scl-a-label__field scl-a-label__field--as-summary"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        Selected debit
                                                    </span>
                                                    <span
                                                        className="scl-b-h scl-b-h3 "
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <Currency amount={props.selection.debitBalance} />
                                                    </span>
                                                </span>
                                            </span>
                                        </Div>
                                        <Div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "stretch",
                                            }}
                                            theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                                        >
                                            <span
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        paddingLeft: "5px",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <span
                                                        className="scl-a-label__field scl-a-label__field--as-summary"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        Selected credit
                                                    </span>
                                                    <span
                                                        className="scl-b-h scl-b-h3 "
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <Currency amount={props.selection.creditBalance} />
                                                    </span>
                                                </span>
                                            </span>
                                        </Div>
                                        <Div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "stretch",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        paddingLeft: "15px",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <span
                                                        className="scl-a-label__field scl-a-label__field--as-summary"
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <b>Selected balance</b>
                                                    </span>
                                                    <span
                                                        className="scl-b-h scl-b-h3 "
                                                        style={{
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        <Currency
                                                            amount={props.selection.totalCalcBalance}
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                                        </Div>
                                    </Div>
                                </Div>
                            </NavbarStackRight>
                        </NavbarStack>
                    </div>
                </div>
        }
        <Navbar theme={navbarTheme(props.isValid)} className="scl-h-text-align--left">
            <NavbarStack>
                <NavbarStackContent>
                    <NavbarTitle>
                        {props.title}
                    </NavbarTitle>
                </NavbarStackContent>
                <NavbarStackRight>
                    <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                        <Div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                }}
                                theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "5px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <span
                                            className="scl-a-label__field scl-a-label__field--as-summary"
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                                Debit balance
                                        </span>
                                        <span
                                            className="scl-b-h scl-b-h3 "
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <Currency
                                                amount={props.debitBalance}
                                            />
                                        </span>
                                    </span>
                                </span>
                            </Div>
                            <Div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                }}
                                theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "5px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <span
                                            className="scl-a-label__field scl-a-label__field--as-summary"
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                                Credit balance
                                        </span>
                                        <span
                                            className="scl-b-h scl-b-h3 "
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <Currency
                                                amount={props.creditBalance}
                                            />
                                        </span>
                                    </span>
                                </span>
                            </Div>
                            <Div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "15px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <span
                                            className="scl-a-label__field scl-a-label__field--as-summary"
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <b>Total balance</b>
                                        </span>
                                        <span
                                            className="scl-b-h scl-b-h3 "
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <Currency
                                                amount={props.debitBalance - props.creditBalance}
                                            />
                                        </span>
                                    </span>
                                </span>
                            </Div>
                            {(!!props.isValid) &&
                                    <Div
                                        theme={{ padding: { "": { l: 2 }, "sm": { l: 3 } } }}
                                    >
                                        <Button
                                            buttonType="submit"
                                            className="scl-a-btn--big"
                                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                        >
                                            Process Booking
                                        </Button>
                                    </Div>
                            }
                        </Div>
                    </Div>
                </NavbarStackRight>
            </NavbarStack>
        </Navbar>
    </Div>
);

export const bookingBarData = (manualBookings?: ManualBookingAmount[], selectedRows?: ManualBookingAmount[]) => {
    const selectedCreditBalance = roundTwoDecimals(
        selectedRows
            ?.filter(r => r.amount < 0)
            ?.reduce((acc, curr) => acc + curr.amount, 0)
        ?? 0,
    );

    const selectedDebitBalance = roundTwoDecimals(
        selectedRows
            ?.filter(r => r.amount > 0)
            ?.reduce((acc, curr) => acc + curr.amount, 0)
        ?? 0,
    );

    const currentCredBalance = roundTwoDecimals(
        manualBookings
            ?.filter(r => r.amount < 0)
            ?.reduce((acc, curr) => acc + curr.amount, 0)
        ?? 0,
    );

    const currentDebBalance = roundTwoDecimals(
        manualBookings
            ?.filter(r => r.amount > 0)
            ?.reduce((acc, curr) => acc + curr.amount, 0)
        ?? 0,
    );

    const hasSelection = selectedRows && selectedRows.length > 0;

    const isValid = (manualBookings && manualBookings.length > 0)
        ? (Math.abs(currentDebBalance) === Math.abs(currentCredBalance))
        : undefined;

    return {
        debitBalance: currentDebBalance,
        creditBalance: - currentCredBalance,
        totalBalance: roundTwoDecimals(currentDebBalance + currentCredBalance),
        selection: hasSelection ? {
            debitBalance: selectedDebitBalance,
            creditBalance: - selectedCreditBalance,
            totalCalcBalance: roundTwoDecimals(selectedCreditBalance + selectedDebitBalance),
        } as ManualBookBarSelection : undefined,
        isValid: isValid,
    };
};
