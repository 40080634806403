/* eslint-disable max-len */
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { FileTypeDisplay } from "../../../../../store/Files/Displays";
import { FileDownload, Files } from "../../../../../store/Files/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { canDownloadFile } from "../../../../utils/FileDownload";
import { getMessageStatusColor } from "../../../../utils/FormatHelpers";
import { DownloadButtonCell } from "../../../06-molecules/DownloadButtonCell";


export interface DownloadFileProps {
    loadingFileInitDownload: boolean;
    loadingFileDownload: boolean;
    fileDownloadResult: FileDownload;
}

/**
 *
 */
interface StateProps extends DownloadFileProps { }

/**
 *
 */
interface OwnProps {
    results: Files;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    loadDownload: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFileInitDownload: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    loadingFileDownload: isRemoteLoading(s, RemoteScope.FILE_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.FILES, {
        skip: skip,
    })),
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, { id: id })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.loadDownload = this.loadDownload.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const isLoading = ((this.props.fileDownloadResult && this.props.fileDownloadResult.id === row.id)
                && this.props.loadingFileDownload);
            return ({
                id: row.id,
                data: [
                    row.messageName,
                    row.createdAt
                        ? (
                            <Moment date={new Date(row.createdAt)} format={"DD-MM-YYYY HH:mm:ss"} key={`date${index}`} />
                        ) : "",
                    FileTypeDisplay[row.messageType],
                    row.messageDirection,
                    (
                        <Badge key={`${index}-badge`} theme={{ paletteState: getMessageStatusColor(row.messageStatus) }}>
                            {row.messageStatus ? row.messageStatus.toString() : "n.a."}
                        </Badge>
                    ),
                    (<ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <DownloadButtonCell
                            key={`${index}-download-button-cell`}
                            isLoading={isLoading}
                            loadDownload={this.loadDownload(row.id)}
                            canDownloadFile={canDownloadFile(this.props)}
                        /></ButtonsWrapper>
                    ),
                ],
            });
        });
    }

    private loadDownload(id: number) {
        return () => this.props.loadDownload(id);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Name",
                },
                {
                    label: "Creation date",
                },
                {
                    label: "Type",
                },
                {
                    label: "Direction",
                },
                {
                    label: "Status",
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
