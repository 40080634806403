import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Map, OrderedSet } from "immutable";
import { Action } from "redux";
import { Opt, Optional } from "../utils/Optional";
import { MaryState } from "../utils/redux/MaryProvider";
import { State, StateInit } from "../utils/redux/State";

import { AccountDetails, AccountRequest, Accounts } from "./Accounts/Types";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { Pages } from "./AppDisplays";
import { UserInfo } from "./AppTypes";
import { BookingPeriod, BookingPeriodFilters, BookingPeriods } from "./BookingPeriod/Types";
import { BrandDetails, BrandRequest, Brands } from "./Brand/Types";
import { BusinessRuleViolations } from "./BusinessRuleViolations/Types";
import { ClaimErrors } from "./ClaimErrors/Types";
import { ClaimInvoiceBookings, ClaimMatchFilters } from "./ClaimMatch/Types";
import { ClaimDetails, ClaimFilters, Claims } from "./Claims/Types";
import {
    CollectiveInvoiceFilters,
    CollectiveInvoiceGenerate,
    CollectiveInvoices,
} from "./CollectiveInvoice/Types";
import { ExcassoFilters, Excassos } from "./Excassos/Types";
import { FileDownload, FileFilters, Files } from "./Files/Types";
import { FilterScope } from "./FilterTypes";
import { GLDetails, GLRequest, GeneralLedgerFilters, GeneralLedgers } from "./GeneralLedgers/Types";
import { IncassoFilters, Incassos } from "./Incassos/Types";
import { InvoiceFilters, Invoices } from "./Invoice/Types";
import { MandateDetails, MandateFilters, MandateRequest, Mandates } from "./Mandates/Types";
import { MBFilters, ManualBookingResults, ManualBookings, ManualBookingsResults } from "./ManualBooking/Types";
import { MemoDetails, MemoRequest, Memos } from "./Memos/Types";
import { ModelDetails, ModelFilters, ModelRequest, Models } from "./Models/Types";
import { PaymentFilters, Payments } from "./PartnerPayments/Types";
import { PartnerDetails, PartnerFilters, PartnerRequest, Partners } from "./Partners/Types";
import { Policies, PolicyDetails, PolicyFilters, PolicyRequest, PremiumPolicy } from "./Policies/Types";
import { ProductDetail, ProductFilters, ProductRequest, ProductTariffRequests, Products } from "./Product/Types";
import { Proxies } from "./Proxies/Types";
import { RemoteErrorType, RemoteScope } from "./RemoteTypes";
import { ReportAccountingGenerate, ReportForm } from "./Reports/Types";
import { BookedStatements, StatementDetails, StatementFilters, Statements } from "./Statements/Types";
import { TariffDetails, TariffFilters, TariffRequest, Tariffs } from "./Tariffs/Types";
import { VersionRequest } from "./Version/RemoteConfig";

export const isRemoteLoading =
    (state: ReduxState, scope: RemoteScope): boolean =>
        state.mapProp("remotes", r => r.has(scope));

export const isRemoteLoadingAny =
    (state: ReduxState): boolean =>
        state.mapProp("remotes", r => !r.isEmpty());

/**
 *
 */
export interface CollectiveInvoiceForm {
    readonly from?: string;
    readonly until?: string;
}

/**
 *
 */
export interface InExGenerationForm {
    readonly requestedDate?: string;
    readonly incassoName?: string;
    readonly accountId?: number;
    readonly incassoNote?: string;
    readonly excassoName?: string;
    readonly excassoNote?: string;
}

export interface RemoteErrors {
    message: string;
    type: RemoteErrorType;
}

interface TState {
    conf: Config;
    client: AxiosInstance;
    mary?: MaryState;
    remotes: Map<RemoteScope, Opt<CancelTokenSource>>;
    remote: State<RemoteState>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    pages: State<CurrentPage>;
    filters: State<FilterStates>;
    user: Optional<UserInfo>;
    reportForm: State<ReportForm>;
    collectiveInvoiceForm: State<CollectiveInvoiceForm>;
    inexGenerationForm: State<InExGenerationForm>;
    bookingDescription: string;
    productRequest: State<ProductRequest>;
    policyRequest: State<PolicyRequest>;
    manualBooking: State<ManualBookings>;
    accountRequest: State<AccountRequest>;
    gLRequest: State<GLRequest>;
    partnerRequest: State<PartnerRequest>;
    mandateRequest: State<MandateRequest>;
    tariffRequest: State<TariffRequest>;
    productTariffRequest: State<ProductTariffRequests>;
    policyPremiumRequest: State<PremiumPolicy>;
    memoRequest: State<MemoRequest>;
    brandRequest: State<BrandRequest>;
    modelRequest: State<ModelRequest>;
    partnersCI: number[];
    sidebarCollapsed: boolean;
}

/**
 *
 */
export interface RemoteState {
    [RemoteScope.BRAND_RESULTS]?: Brands;
    [RemoteScope.BRAND_DETAILS]?: BrandDetails;
    [RemoteScope.BRAND_CREATE]?: BrandDetails;
    [RemoteScope.BRAND_EDIT]?: BrandDetails;
    [RemoteScope.BRAND_DELETE]?: {};
    [RemoteScope.MODEL_RESULTS]?: Models;
    [RemoteScope.MODEL_PER_BRAND_RESULTS]?: Models;
    [RemoteScope.MODEL_DETAILS]?: ModelDetails;
    [RemoteScope.MODEL_CREATE]?: ModelDetails;
    [RemoteScope.MODEL_EDIT]?: ModelDetails;
    [RemoteScope.MODEL_DELETE]?: {};
    [RemoteScope.STATEMENT_UNBOOK_PATCH]?: {};
    [RemoteScope.AUDIT_TRAIL_STATEMENT]?: {};
    [RemoteScope.BOOKED_STATEMENT_RESULTS]?: BookedStatements;
    [RemoteScope.STATEMENT_RESULTS]?: Statements;
    [RemoteScope.STATEMENT_DELETE]?: {};
    [RemoteScope.STATEMENT_LINE_UNBOOK]?: {};
    [RemoteScope.STATEMENT_DETAILS]?: StatementDetails;
    [RemoteScope.GENERAL_LEDGER]?: GeneralLedgers;
    [RemoteScope.GENERAL_LEDGER_CREATE]?: GLDetails;
    [RemoteScope.GENERAL_LEDGER_EDIT]?: GLDetails;
    [RemoteScope.GENERAL_LEDGER_DETAILS]?: GLDetails;
    [RemoteScope.AUDIT_TRAIL_GL]?: {};
    [RemoteScope.CLAIM_RESULTS]?: Claims;
    [RemoteScope.CLAIM_ERROR_RESULTS]?: ClaimErrors;
    [RemoteScope.CLAIM_MATCH_RESULTS]?: ClaimInvoiceBookings;
    [RemoteScope.CLAIM_MATCH_BOOK_PATCH]?: {};
    [RemoteScope.CLAIM_DETAILS]?: ClaimDetails;
    [RemoteScope.COLLECTIVE_INVOICE_GENERATE]?: CollectiveInvoiceGenerate;
    [RemoteScope.COLLECTIVE_INVOICE_RESULTS]?: CollectiveInvoices;
    [RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH]?: {};
    [RemoteScope.COLLECTIVE_INVOICE_INCASSO]?: {};
    [RemoteScope.COLLECTIVE_INVOICE_EXCASSO]?: {};
    [RemoteScope.AUDIT_TRAIL_CI]?: {};
    [RemoteScope.INVOICE_RESULTS]?: Invoices;
    [RemoteScope.AUDIT_TRAIL_INVOICE]?: {};
    [RemoteScope.ACCOUNT_CREATE]?: AccountDetails;
    [RemoteScope.ACCOUNT_RESULTS]?: Accounts;
    [RemoteScope.ACCOUNT_DETAILS]?: AccountDetails;
    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS]?: BusinessRuleViolations;
    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT]?: {};
    [RemoteScope.POLICY_RESULTS]?: Policies;
    [RemoteScope.POLICY_DETAILS]?: PolicyDetails;
    [RemoteScope.POLICY_CREATE]?: PolicyDetails;
    [RemoteScope.POLICY_EDIT]?: PolicyDetails;
    [RemoteScope.AUDIT_TRAIL_POLICY]?: {};
    [RemoteScope.PRODUCT_EDIT]?: ProductDetail;
    [RemoteScope.PRODUCT_CREATE]?: ProductDetail;
    [RemoteScope.PRODUCT_RESULTS]?: Products;
    [RemoteScope.PRODUCT_DETAILS]?: ProductDetail;
    [RemoteScope.PRODUCT_ACTIVATE_PATCH]?: {};
    [RemoteScope.PRODUCT_DEACTIVATE_PATCH]?: {};
    [RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH]?: {};
    [RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH]?: ProductDetail;
    [RemoteScope.POLICY_CANCEL_PATCH]?: {};
    [RemoteScope.POLICY_PREMIUM_PUT]?: PremiumPolicy;
    [RemoteScope.PRODUCT_DELETE]?: {};
    [RemoteScope.AUDIT_TRAIL_PRODUCT]?: {};
    [RemoteScope.PARTNER_RESULTS]?: Partners;
    [RemoteScope.PARTNER_RESULTS_CI]?: Partners;
    [RemoteScope.PARTNER_DETAILS]?: PartnerDetails;
    [RemoteScope.PARTNER_CREATE]?: PartnerDetails;
    [RemoteScope.PARTNER_EDIT]?: PartnerDetails;
    [RemoteScope.PARTNER_BOOK_PATCH]?: {};
    [RemoteScope.AUDIT_TRAIL_PARTNER]?: {};
    [RemoteScope.GENERALLEDGER_BOOK_PATCH]?: {};
    [RemoteScope.GENERATE_REPORT_ACCOUNTING_POST]?: ReportAccountingGenerate;
    [RemoteScope.PROXIES]?: Proxies;
    [RemoteScope.INCASSO_RESULTS]?: Incassos;
    [RemoteScope.INCASSO_BOOK_PATCH]?: {};
    [RemoteScope.EXCASSO_RESULTS]?: Excassos;
    [RemoteScope.EXCASSO_BOOK_PATCH]?: {};
    [RemoteScope.FILE_INIT_DOWNLOAD]?: FileDownload;
    [RemoteScope.FILE_DOWNLOAD]?: {};
    [RemoteScope.FILE_UPLOAD]?: {};
    [RemoteScope.FILES]?: Files;
    [RemoteScope.TARIFF_RESULTS]?: Tariffs;
    [RemoteScope.TARIFF_DETAILS]?: TariffDetails;
    [RemoteScope.TARIFF_CREATE]?: TariffDetails;
    [RemoteScope.TARIFF_EDIT]?: TariffDetails;
    [RemoteScope.TARIFF_DELETE]?: {};
    [RemoteScope.AUDIT_TRAIL_TARIFF]?: {};
    [RemoteScope.AUDIT_TRAIL_FREEBOOKING]?: {};
    [RemoteScope.MANUAL_BOOKING_RESULTS]?: ManualBookingsResults;
    [RemoteScope.MANUAL_BOOKING_CREATE]?: ManualBookingResults;
    [RemoteScope.BOOKING_PERIOD]?: BookingPeriods;
    [RemoteScope.BOOKING_PERIOD_OPEN]?: {};
    [RemoteScope.BOOKING_PERIOD_CLOSE]?: {};
    [RemoteScope.BOOKING_PERIOD_CREATE]?: BookingPeriod;
    [RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD]?: {};
    [RemoteScope.PAYMENT_RESULTS]?: Payments;
    [RemoteScope.AUDIT_TRAIL_PAYMENT]?: {};
    [RemoteScope.MANDATE_RESULTS]?: Mandates;
    [RemoteScope.MANDATE_DETAILS]?: MandateDetails;
    [RemoteScope.MANDATE_CREATE]?: MandateDetails;
    [RemoteScope.MANDATE_EDIT]?: MandateDetails;
    [RemoteScope.MANDATE_DELETE]?: {};
    [RemoteScope.MEMO_RESULTS]?: Memos;
    [RemoteScope.MEMO_CREATE]?: MemoDetails;
    [RemoteScope.MEMO_EDIT]?: MemoDetails;
    [RemoteScope.MEMO_DELETE]?: {};
    [RemoteScope.VERSION]?: VersionRequest;
}

export type ReduxState = State<TState>;

export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export type CurrentPage = Readonly<{
    pages: OrderedSet<Pages>;
}>;

export type Filters = Readonly<{
    [FilterScope.CLAIMS]: ClaimFilters;
    [FilterScope.CLAIM_MATCH]: ClaimMatchFilters;
    [FilterScope.INCASSOS]: IncassoFilters;
    [FilterScope.EXCASSOS]: ExcassoFilters;
    [FilterScope.STATEMENTS]: StatementFilters;
    [FilterScope.COLLECTIVE_INVOICES]: CollectiveInvoiceFilters;
    [FilterScope.INVOICES]: InvoiceFilters;
    [FilterScope.POLICIES]: PolicyFilters;
    [FilterScope.PARTNERS]: PartnerFilters;
    [FilterScope.GENERAL_LEDGER]: GeneralLedgerFilters;
    [FilterScope.FILES]: FileFilters;
    [FilterScope.MANUAL_BOOKING]: MBFilters;
    [FilterScope.BOOKING_PERIOD]: BookingPeriodFilters;
    [FilterScope.PAYMENTS]: PaymentFilters;
    [FilterScope.MANDATES]: MandateFilters;
    [FilterScope.MODELS]: ModelFilters;
    [FilterScope.PRODUCTS]: ProductFilters;
    [FilterScope.TARIFFS]: TariffFilters;
}>;

export type FilterStates = {
    [K in keyof Filters]: State<Filters[K]>;
};

export const clearState = (s: ReduxState) => s.clear({mary: true, conf: true});

export const ReduxStateInit: (config: Config) => StateInit<TState> =
    config => (): TState => {
        const isCollapsed = localStorage.getItem?.("isCollapsed");
        return {
            conf: config,
            client: Axios.create({
                baseURL: config.apiEndpoint,
            }),
            remotes: Map(),
            remote: State.create<RemoteState>(() => ({})),
            remoteErrors: Map(),
            mary: undefined,
            pages: State.create<CurrentPage>(() => ({
                pages: OrderedSet([]),
            })),
            filters: State.create<FilterStates>(() => ({
                [FilterScope.CLAIMS]: State.create<ClaimFilters>(() => ({})),
                [FilterScope.CLAIM_MATCH]: State.create<ClaimMatchFilters>(() => ({})),
                [FilterScope.INCASSOS]: State.create<IncassoFilters>(() => ({})),
                [FilterScope.EXCASSOS]: State.create<ExcassoFilters>(() => ({})),
                [FilterScope.STATEMENTS]: State.create<StatementFilters>(() => ({})),
                [FilterScope.COLLECTIVE_INVOICES]: State.create<CollectiveInvoiceFilters>(() => ({})),
                [FilterScope.INVOICES]: State.create<InvoiceFilters>(() => ({})),
                [FilterScope.POLICIES]: State.create<PolicyFilters>(() => ({})),
                [FilterScope.PARTNERS]: State.create<PartnerFilters>(() => ({})),
                [FilterScope.GENERAL_LEDGER]: State.create<GeneralLedgerFilters>(() => ({})),
                [FilterScope.FILES]: State.create<FileFilters>(() => ({})),
                [FilterScope.MANUAL_BOOKING]: State.create<MBFilters>(() => ({})),
                [FilterScope.BOOKING_PERIOD]: State.create<BookingPeriodFilters>(() => ({})),
                [FilterScope.PAYMENTS]: State.create<PaymentFilters>(() => ({})),
                [FilterScope.MANDATES]: State.create<MandateFilters>(() => ({})),
                [FilterScope.MODELS]: State.create<ModelFilters>(() => ({})),
                [FilterScope.PRODUCTS]: State.create<ProductFilters>(() => ({})),
                [FilterScope.TARIFFS]: State.create<TariffFilters>(() => ({})),
            })),
            reportForm: State.create<ReportForm>(() => ({})),
            collectiveInvoiceForm: State.create<CollectiveInvoiceForm>(() => ({})),
            inexGenerationForm: State.create<InExGenerationForm>(() => ({})),
            productRequest: State.create<ProductRequest>(() => ({})),
            policyRequest: State.create<PolicyRequest>(() => ({})),
            manualBooking: State.create<ManualBookings>(() => ({})),
            user: Optional.empty(),
            bookingDescription: "",
            accountRequest: State.create<AccountRequest>(() => ({})),
            gLRequest: State.create<GLRequest>(() => ({})),
            partnerRequest: State.create<PartnerRequest>(() => ({})),
            mandateRequest: State.create<MandateRequest>(() => ({})),
            tariffRequest: State.create<TariffRequest>(() => ({})),
            productTariffRequest: State.create<ProductTariffRequests>(() => ({})),
            policyPremiumRequest: State.create<PremiumPolicy>(() => ({})),
            memoRequest: State.create<MemoRequest>(() => ({})),
            brandRequest: State.create<BrandRequest>(() => ({})),
            modelRequest: State.create<ModelRequest>(() => ({})),
            partnersCI: [],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            sidebarCollapsed: JSON.parse(isCollapsed ?? "false") ?? false,
        };
    };
