import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { PageDisplay, PageTabs, Pages, SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";

import { ReduxState } from "../../../../../store/ReduxState";
import { Tabs } from "../../00-blocks/Tabs";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

interface Params {
    tariffId: string;
}

/**
 *
 */
interface StateProps {
    params: Params;
}

/**
 *
 */
type Props = StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
});

/**
 *
 * @param props
 */
export class EditTariffComp
    extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.TARIFF_EDIT].title}
                        goBack={PageDisplay[Pages.TARIFFS].path}
                    />
                    <Tabs
                        tabs={SubPageDisplay[SubPages.TARIFF_EDIT].tabs}
                        defaultTab={PageTabs.TARIFF_EDIT_MANUAL}
                        params={{
                            tariffId: this.props.params.tariffId,
                        }}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const EditTariff = connect(
    mapStateToProps,
    {},
)((props: Props) => <EditTariffComp {...props} />);
