/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { BookingPeriods, BookingPeriodStatus } from "../../../../../store/BookingPeriod/Types";
import { getBookingPeriodStatusColor } from "../../../../utils/FormatHelpers";
import { ConfirmReopenModal } from "./ConfirmReopenModal";
import { BookingPeriodMonthsDisplay } from "../../../../../store/BookingPeriod/Displays";
import Moment from "react-moment";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { NavLink } from "react-router-dom";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePalette, ThemeShadowSizes,
    ThemePaletteState, ThemeBreakpoints } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: BookingPeriods;
    asAuditTab?: boolean;
}

/**
 *
 */
interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openBookingPeriodRequest: (bookingPeriodId: string, month: string, year: number, callBack: () => void) => void;
    openBookingPeriod: (id: number) => void;
    closeBookingPeriod: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

export const OpenBookingPeriod =
    (bookingPeriodId: string, month: string, year: number, callBack: () => void) => (
        () => (
            <Modal
                modalID={bookingPeriodId}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmReopenModal
                    modalID={bookingPeriodId}
                    bookingPeriodId={bookingPeriodId}
                    month={month}
                    year={year}
                    onReopenConfirmed={callBack}
                />
            </Modal>
        )
    );

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD, {
        skip: skip,
    })),
    openBookingPeriodRequest:
            (bookingPeriodId: string, month: string, year: number, callBack: () => void) => dispatch(
                modalShow(
                    bookingPeriodId,
                    OpenBookingPeriod(bookingPeriodId, month, year, callBack),
                    // () => (
                    //     <Modal
                    //         modalID={bookingPeriodId}
                    //         theme={{ size: ThemeSize.SMALL }}
                    //     >
                    //         <ConfirmReopenModal
                    //             modalID={bookingPeriodId}
                    //             bookingPeriodId={bookingPeriodId}
                    //             month={month}
                    //             year={year}
                    //             onReopenConfirmed={callBack}
                    //         />
                    //     </Modal>
                    // ),
                ),
            ),
    openBookingPeriod: (id: number) => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD_OPEN, {
        id: id,
    })),
    closeBookingPeriod: (id: number) => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD_CLOSE, {
        id: id,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.closeBookingPeriod = this.closeBookingPeriod.bind(this);
        this.openBookingPeriod = this.openBookingPeriod.bind(this);
        this.callBackOpenBookingPeriod = this.callBackOpenBookingPeriod.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                <Moment date={new Date(row.startingDate)} format={"DD-MM-YYYY"} key={`starting-date${index}`} />,
                <Moment date={new Date(row.endingDate)} format={"DD-MM-YYYY"} key={`ending-date${index}`} />,
                row.year,
                BookingPeriodMonthsDisplay[row.month],
                (
                    <Badge
                        key={`badge-${row.id}`}
                        theme={{ paletteState: getBookingPeriodStatusColor(row.status) }}>
                        {row.status.toString()}
                    </Badge>
                ),<ButtonsWrapper
                    key={`navlink-${index}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.asAuditTab ? (
                            <NavLink
                                key={`navlink-${row.id}`}
                                to={`/audit/bookingperiods/${row.id}`}>
                                <Button
                                    icon={IconNames.ARROW_RIGHT}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                />
                            </NavLink>
                        ) :
                            this.props.user?.userRole !== UserRoles.EW_DEBTOR
                && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ? (
                                    row.status === BookingPeriodStatus.OPEN ?
                                        (
                                            <Button
                                                theme={{
                                                    palette: ThemePalette.BRAND_ACCENT,
                                                    shadow: ThemeShadowSizes.TINY,
                                                }}
                                                link={{ onClick: this.closeBookingPeriod(row.id) }}
                                            >
                                        Close
                                            </Button>
                                        ) : (
                                            <Button
                                                theme={{
                                                    paletteState: ThemePaletteState.DANGER,
                                                    shadow: ThemeShadowSizes.TINY,
                                                }}
                                                link={{
                                                    onClick: this.openBookingPeriod(
                                                        row.id,
                                                        row.month,
                                                        row.year,
                                                    ),
                                                }}
                                            >
                                        Re-open
                                            </Button>
                                        )
                                ) : ""}</ButtonsWrapper>,
            ],
        }));
    }

    private closeBookingPeriod(id: number) {
        return () => this.props.closeBookingPeriod(id);
    }

    private openBookingPeriod(bookingPeriodId: number, month: string, year: number) {
        return () => this.props.openBookingPeriodRequest(
            bookingPeriodId.toString(),
            BookingPeriodMonthsDisplay[month] as string,
            year,
            this.callBackOpenBookingPeriod(bookingPeriodId),
        );
    }

    private callBackOpenBookingPeriod(bookingPeriodId: number) {
        return () => this.props.openBookingPeriod(bookingPeriodId);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Starting date",
                },
                {
                    label: "Ending date",
                },
                {
                    label: "Year",
                },
                {
                    label: "Month",
                },
                {
                    label: "Status",
                },
                this.props.user?.userRole !== UserRoles.EW_DEBTOR
                && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    {
                        label: "Actions",
                    } : {
                        label: "",
                    },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
