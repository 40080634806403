/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { OrderedSet } from "immutable";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { InfoCard } from "../../../../components/core/06-molecules/InfoCard";
import { PageDisplay, Pages, SubPages, SubPageDisplay } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import { ReduxState } from "../../../../store/ReduxState";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Container } from "../../../../components/core/03-base/Container";

/**
 *
 */
interface StateProps {
    user?: UserInfo;
}
/**
 *
 */
interface DispatchProps {
}

type Props = DispatchProps & StateProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});


/**
 *
 * @param props
 */
export class AuditTrailsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {
        const getTabsByRole = (role: UserRoles) => {
            if (role === UserRoles.EW_ADMIN) {
                return (
                    PageDisplay[Pages.AUDIT_TRAILS].subpages
                        .filter((page: SubPages) =>
                            page === SubPages.AUDIT_TRAIL_POLICIES || page === SubPages.AUDIT_TRAIL_PRODUCTS || page === SubPages.AUDIT_TRAIL_PARTNERS
                        || page === SubPages.AUDIT_TRAIL_TARIFFS || page === SubPages.AUDIT_TRAIL_CI || page === SubPages.AUDIT_TRAIL_INVOICE
                        )
                );
            } else if (role === UserRoles.EW_ADMIN_READONLY) {
                return (
                    PageDisplay[Pages.AUDIT_TRAILS].subpages
                        .filter((page: SubPages) =>
                            page === SubPages.AUDIT_TRAIL_POLICIES || page === SubPages.AUDIT_TRAIL_PRODUCTS || page === SubPages.AUDIT_TRAIL_PARTNERS
                        || page === SubPages.AUDIT_TRAIL_TARIFFS || page === SubPages.AUDIT_TRAIL_CI || page === SubPages.AUDIT_TRAIL_INVOICE
                        || page === SubPages.AUDIT_TRAIL_GL || page === SubPages.AUDIT_TRAIL_BOOKING_PERIOD
                        || page === SubPages.AUDIT_TRAIL_STATEMENT || page === SubPages.AUDIT_TRAIL_FREEBOOKING
                        || page === SubPages.AUDIT_TRAIL_PAYMENTS
                        )
                );
            } else if (role === UserRoles.EW_ACCOUNTING || UserRoles.EW_DEBTOR) {
                return (
                    PageDisplay[Pages.AUDIT_TRAILS].subpages
                        .filter((page: SubPages) =>
                            page === SubPages.AUDIT_TRAIL_GL || page === SubPages.AUDIT_TRAIL_BOOKING_PERIOD || page === SubPages.AUDIT_TRAIL_CI
                        || page === SubPages.AUDIT_TRAIL_INVOICE || page === SubPages.AUDIT_TRAIL_STATEMENT || page === SubPages.AUDIT_TRAIL_FREEBOOKING
                        || page === SubPages.AUDIT_TRAIL_PAYMENTS || page === SubPages.AUDIT_TRAIL_PARTNERS
                        )
                );
            } else {
                return OrderedSet([]);
            }
        };
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >

                    <Header
                        title={PageDisplay[Pages.AUDIT_TRAILS].title}
                        description={PageDisplay[Pages.AUDIT_TRAILS].description}
                    />
                    <Div className="scl-b-row">
                        {getTabsByRole(this.props.user?.userRole || UserRoles.NONE)
                            .map((page: SubPages, index) => (
                                <Grid key={index} size={{ xs: 12, md: 6, lg: 3 }}>
                                    <InfoCard
                                        title={SubPageDisplay[page].title}
                                        description={SubPageDisplay[page].description}
                                        navLink={SubPageDisplay[page].path}
                                        beta={SubPageDisplay[page].beta}
                                    />
                                </Grid>
                            ))}
                    </Div>
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const AuditTrails = connect(
    mapStateToProps,
    {},
)(AuditTrailsComp);
