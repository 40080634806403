/* eslint-disable max-len */
import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { modalClose } from "../utils/redux/ActionTypes";

import { mapAccountDetailsRequests, mapAccountRequests } from "./Accounts/RemoteConfig";
import { mapAuditTrailsRequests } from "./AuditTrail/RemoteConfig";
import { clearDescription } from "./BookingDescription";
import { BookingPeriodFiltersToParams, mapBookingPeriodRequests } from "./BookingPeriod/RemoteConfig";
import { BookingPeriod } from "./BookingPeriod/Types";
import { initUpdateBrand } from "./Brand/CreateType";
import { mapBrandDetailsRequests, mapBrandRequests } from "./Brand/RemoteConfig";
import { mapBusinessRuleViolationRequests } from "./BusinessRuleViolations/RemoteConfig";
import { mapClaimErrorRequests } from "./ClaimErrors/RemoteConfig";
import { BookClaims, ClaimMatchFiltersToParams, mapClaimMatchRequests } from "./ClaimMatch/Types";
import { mapClaimDetailsRequests, mapClaimRequests } from "./Claims/RemoteConfig";
import { ClaimFiltersToParams } from "./Claims/Types";
import { updateInExForm } from "./CollectiveInvoice/GenerateIncasso";
import { CollectiveInvoiceFiltersToParams, mapCollectiveInvoiceRequests } from "./CollectiveInvoice/RemoteConfig";
import { BookInvoice, CollectiveInvoiceGenerate, ExcassoGenerate, InExGenerate } from "./CollectiveInvoice/Types";
import { ExcassoFiltersToParams, mapExcassoRequests } from "./Excassos/RemoteConfig";
import { BookExcasso } from "./Excassos/Types";
import { fileDownloadInit } from "./Files/Actions";
import { FileFiltersToParams, mapFileRequests } from "./Files/RemoteConfig";
import { FileDownload } from "./Files/Types";
import { FilterScope } from "./FilterTypes";
import { initUpdateGL } from "./GeneralLedgers/Create";
import { GeneralLedgerFiltersToParams, mapGLDetailsRequests,
    mapGeneralLedgerRequests } from "./GeneralLedgers/RemoteConfig";
import { BookLedger } from "./GeneralLedgers/Types";
import { IncassoFiltersToParams, mapIncassoRequests } from "./Incassos/RemoteConfig";
import { BookIncasso } from "./Incassos/Types";
import { InvoiceFiltersToParams, mapInvoiceRequests } from "./Invoice/RemoteConfig";
import { initUpdateMandate } from "./Mandates/Create";
import { MandateFiltersToParams, mapMandateDetailsRequests, mapMandateRequests } from "./Mandates/RemoteConfig";
import { MandateRequest } from "./Mandates/Types";
import { MBFiltersToParams, mapMBResultsRequests, mapManualBookingRequests } from "./ManualBooking/RemoteConfig";
import { mapMemoDetailsRequests, mapMemoRequests } from "./Memos/RemoteConfig";
import { MemoRequest } from "./Memos/Types";
import { RequestMessage } from "./Message/Types";
import { initUpdateModel } from "./Models/CreateType";
import { ModelFiltersToParams,
    mapModelDetailsRequests, mapModelRequests, mapModelsPerBrandRequests } from "./Models/RemoteConfig";
import { PaymentFiltersToParams, mapPaymentRequests } from "./PartnerPayments/RemoteConfig";
import { initUpdatePartner } from "./Partners/Create";
import { PartnerFiltersToParams, mapPartnerDetailsRequests, mapPartnerRequests } from "./Partners/RemoteConfig";
import { BookPartner } from "./Partners/Types";
import { initUpdatePolicy } from "./Policies/EditPolicyTypes";
import { PolicyFiltersToParams, mapPolicyDetailsRequests, mapPolicyRequests } from "./Policies/RemoteConfig";
import { PremiumPolicy } from "./Policies/Types";
import { initUpdateProduct, initUpdateProductProxy } from "./Product/CreateType";
import { ProductFiltersToParams, mapProductDetailsRequests, mapProductRequests } from "./Product/RemoteConfig";
import { Proxies } from "./Proxies/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "./RemoteActions";
import {
    RESULTS_LIMIT,
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";
import { initUpdateReportForm } from "./Reports/FormActions";
import { ReportAccountingGenerate, ReportFormScope } from "./Reports/Types";
import {
    StatementFiltersToParams,
    mapBookedStatementsRequests,
    mapStatementDetailsRequests,
    mapStatementRequests,
} from "./Statements/RemoteConfig";
import { initUpdateTariff } from "./Tariffs/CreateType";
import { TariffFiltersToParams, mapTariffDetailsRequests, mapTariffRequests } from "./Tariffs/RemoteConfig";
import { mapVersionRequest } from "./Version/RemoteConfig";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.INCASSO_RESULTS]: {
        scope: RemoteScope.INCASSO_RESULTS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.INCASSO_RESULTS));
            d(remoteClearError(RemoteScope.INCASSO_RESULTS));
        },
        pathMapper: () => "/incasso",
        resMapper: mapIncassoRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.INCASSOS).map(IncassoFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.EXCASSO_RESULTS]: {
        scope: RemoteScope.EXCASSO_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.EXCASSO_RESULTS));
            d(remoteClearError(RemoteScope.EXCASSO_RESULTS));
        },
        pathMapper: "/excasso",
        resMapper: mapExcassoRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.EXCASSOS).map(ExcassoFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.INCASSO_BOOK_PATCH]: {
        scope: RemoteScope.INCASSO_BOOK_PATCH,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.INCASSO_BOOK_PATCH));
            d(remoteClearError(RemoteScope.INCASSO_BOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: () => "/incasso/paid",
        bodyMapper: (s, ctx): BookIncasso => ({
            entryId: ctx.patch.entryId,
            paidObjectId: ctx.patch.paidObjectId,
            paidAmount: ctx.patch.paidAmount,
            description: s.prop("bookingDescription"),
        }),
        // bodyMapper: (s, ctx): BookIncasso => ctx.patch,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                id: ctx.id,
            }));
        },
    },

    [RemoteScope.EXCASSO_BOOK_PATCH]: {
        scope: RemoteScope.EXCASSO_BOOK_PATCH,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.EXCASSO_BOOK_PATCH));
            d(remoteClearError(RemoteScope.EXCASSO_BOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: () => "/excasso/paid",
        bodyMapper: (s, ctx): BookExcasso => ({
            entryId: ctx.patch.entryId,
            paidObjectId: ctx.patch.paidObjectId,
            paidAmount: ctx.patch.paidAmount,
            description: s.prop("bookingDescription"),
        }),
        // bodyMapper: (s, ctx): BookExcasso => ctx.patch,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                id: ctx.id,
            }));
        },
    },

    [RemoteScope.CLAIM_ERROR_RESULTS]: {
        scope: RemoteScope.CLAIM_ERROR_RESULTS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.CLAIM_ERROR_RESULTS));
            d(remoteClearError(RemoteScope.CLAIM_ERROR_RESULTS));
        },
        pathMapper: () => "/claims/errors",
        resMapper: mapClaimErrorRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.CLAIM_MATCH_RESULTS]: {
        scope: RemoteScope.CLAIM_MATCH_RESULTS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.CLAIM_MATCH_RESULTS));
            d(remoteClearError(RemoteScope.CLAIM_MATCH_RESULTS));
        },
        pathMapper: () => "/claims/invoices/booking",
        resMapper: mapClaimMatchRequests,
        paramsMapper: (s) => {
            const params = {
                ...s.prop("filters").prop(FilterScope.CLAIM_MATCH).map(ClaimMatchFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.CLAIM_MATCH_BOOK_PATCH]: {
        scope: RemoteScope.CLAIM_MATCH_BOOK_PATCH,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.CLAIM_MATCH_BOOK_PATCH));
            d(remoteClearError(RemoteScope.CLAIM_MATCH_BOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: () => "/claims/invoices/book",
        bodyMapper: (s, ctx): BookClaims => ({
            claimInvoiceIdToBookedAmount: ctx.patch.claimInvoiceIdToBookedAmount,
            entryId: ctx.patch.entryId,
            description: s.prop("bookingDescription"),
        }),
        // bodyMapper: (s, ctx): BookClaims => ctx.patch,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                id: ctx.id,
            }));
        },
    },

    [RemoteScope.CLAIM_RESULTS]: {
        scope: RemoteScope.CLAIM_RESULTS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.CLAIM_RESULTS));
            d(remoteClearError(RemoteScope.CLAIM_RESULTS));
        },
        pathMapper: () => "/claims",
        resMapper: mapClaimRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.CLAIMS).map(ClaimFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.CLAIM_DETAILS]: {
        scope: RemoteScope.CLAIM_DETAILS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.CLAIM_DETAILS));
            d(remoteClearError(RemoteScope.CLAIM_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/claims/${ID}`;
        },
        resMapper: mapClaimDetailsRequests,
    },

    [RemoteScope.COLLECTIVE_INVOICE_GENERATE]: {
        scope: RemoteScope.COLLECTIVE_INVOICE_GENERATE,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_GENERATE));
            d(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_GENERATE));
        },
        method: "POST",
        pathMapper: () => "/collectiveinvoices",
        bodyMapper: (_s, ctx) => ctx.selectedRows,
        resMapper: (resp: AxiosResponse): CollectiveInvoiceGenerate => {
            const requests = (resp.data as string);
            return {
                message: requests,
            };
        },
    },

    [RemoteScope.STATEMENT_UNBOOK_PATCH]: {
        scope: RemoteScope.STATEMENT_UNBOOK_PATCH,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.STATEMENT_UNBOOK_PATCH));
            d(remoteClearError(RemoteScope.STATEMENT_UNBOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: () => "/statements/unbook",
        paramsMapper: (_s, ctx) => {
            const params = {
                bookedObjectId: ctx.patch.bookedObjectId,
                bookedObjectType: ctx.patch.bookedObjectType,
            };
            return params;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(
                RemoteScope.BOOKED_STATEMENT_RESULTS, {
                    skip: 0,
                    id: ctx.statementLineId,
                }));
            d(remoteTrigger(
                RemoteScope.STATEMENT_DETAILS, {
                    id: ctx.statementId,
                }));
        },
    },

    [RemoteScope.BOOKED_STATEMENT_RESULTS]: {
        scope: RemoteScope.BOOKED_STATEMENT_RESULTS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.BOOKED_STATEMENT_RESULTS));
            d(remoteClearError(RemoteScope.BOOKED_STATEMENT_RESULTS));
        },
        pathMapper: (_s, ctx) => `/statements/bookings/${ctx.id}`,
        resMapper: mapBookedStatementsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.STATEMENT_RESULTS]: {
        scope: RemoteScope.STATEMENT_RESULTS,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.STATEMENT_RESULTS));
            d(remoteClearError(RemoteScope.STATEMENT_RESULTS));
        },
        pathMapper: () => "/statements",
        resMapper: mapStatementRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.STATEMENTS).map(StatementFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.GENERAL_LEDGER]: {
        scope: RemoteScope.GENERAL_LEDGER,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.GENERAL_LEDGER));
            d(remoteClearError(RemoteScope.GENERAL_LEDGER));
        },
        pathMapper: () => "/generalledgers",
        resMapper: mapGeneralLedgerRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.GENERAL_LEDGER).map(GeneralLedgerFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.STATEMENT_DELETE]: {
        scope: RemoteScope.STATEMENT_DELETE,
        onInit: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteClearResponse(RemoteScope.STATEMENT_DELETE));
            d(remoteClearError(RemoteScope.STATEMENT_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => {
            const statementId = ctx.statementId;
            return `/statements/${statementId}`;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(
                RemoteScope.STATEMENT_RESULTS, {
                    skip: 0,
                }));
        },
    },

    [RemoteScope.FILE_UPLOAD]: {
        scope: RemoteScope.FILE_UPLOAD,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.FILE_UPLOAD));
            d(remoteClearError(RemoteScope.FILE_UPLOAD));
        },
        method: "POST",
        pathMapper: () => "/files",
        bodyMapper: (_s, ctx) => {
            const formData = new FormData();
            formData.append("file",
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                ctx.file as any,
            );
            return formData;
        },
        headers: {
            "Content-Type": "multipart/form-data",
        },
        resMapper: _r => ({}),
        onSuccess: (_d, _s) => {
            // d(remoteTrigger(
            //     RemoteScope.STATEMENT_RESULTS, {
            //         skip: 0,
            //     }));
        },
    },

    [RemoteScope.STATEMENT_DETAILS]: {
        scope: RemoteScope.STATEMENT_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.STATEMENT_DETAILS));
            d(remoteClearError(RemoteScope.STATEMENT_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/statements/${ID}`;
        },
        resMapper: mapStatementDetailsRequests,
    },

    [RemoteScope.STATEMENT_LINE_UNBOOK]: {
        scope: RemoteScope.STATEMENT_LINE_UNBOOK,
        onInit: (d) => {
            d(remoteClearResponse(RemoteScope.STATEMENT_LINE_UNBOOK));
            d(remoteClearError(RemoteScope.STATEMENT_LINE_UNBOOK));
        },
        method: "PATCH",
        pathMapper: () =>
            // const statementLineId = ctx.statementLineId;
            // const statementId = ctx.statementId;
            // return `/statements/${statementId}/${statementLineId}`;
            "/statements/entry/unbook"
        ,
        paramsMapper: (_s, ctx) => {
            const params = {
                entryId: ctx.statementLineId,
            };
            return params;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(
                RemoteScope.STATEMENT_DETAILS, {
                    id: ctx.statementId,
                }));
        },
    },

    [RemoteScope.BRAND_RESULTS]: {
        scope: RemoteScope.BRAND_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BRAND_RESULTS));
            d(remoteClearError(RemoteScope.BRAND_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/brands",
        resMapper: mapBrandRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.BRAND_DELETE]: {
        scope: RemoteScope.BRAND_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BRAND_DELETE));
            d(remoteClearError(RemoteScope.BRAND_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => {
            const brandId = ctx.brandId;
            return `/brands/${brandId}`;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(
                RemoteScope.BRAND_RESULTS, {
                    skip: 0,
                }));
        },
    },

    [RemoteScope.BRAND_DETAILS]: {
        scope: RemoteScope.BRAND_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BRAND_DETAILS));
            d(remoteClearError(RemoteScope.BRAND_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/brands/${ID}`;
        },
        resMapper: mapBrandDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateBrand());
        },
    },

    [RemoteScope.BRAND_CREATE]: {
        scope: RemoteScope.BRAND_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BRAND_CREATE));
            d(remoteClearError(RemoteScope.BRAND_CREATE));
        },
        pathMapper: (_s, _ctx) => "/brands",
        resMapper: mapBrandDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.brand,
    },

    [RemoteScope.BRAND_EDIT]: {
        scope: RemoteScope.BRAND_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BRAND_EDIT));
            d(remoteClearError(RemoteScope.BRAND_EDIT));
        },
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        pathMapper: (_s, ctx) => `/brands/${ctx.brand.id}`,
        resMapper: mapBrandDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.brand,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.BRAND_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.MODEL_RESULTS]: {
        scope: RemoteScope.MODEL_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MODEL_RESULTS));
            d(remoteClearError(RemoteScope.MODEL_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/models",
        resMapper: mapModelRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.MODELS).map(ModelFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.MODEL_DELETE]: {
        scope: RemoteScope.MODEL_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MODEL_DELETE));
            d(remoteClearError(RemoteScope.MODEL_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => {
            const modelId = ctx.modelId;
            return `/models/${modelId}`;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(
                RemoteScope.MODEL_RESULTS, {
                    skip: 0,
                }));
        },
    },

    [RemoteScope.MODEL_PER_BRAND_RESULTS]: {
        scope: RemoteScope.MODEL_PER_BRAND_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MODEL_PER_BRAND_RESULTS));
            d(remoteClearError(RemoteScope.MODEL_PER_BRAND_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/brands",
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                name: ctx.brandName,
            };
            return params;
        },
        resMapper: mapModelsPerBrandRequests,
        onSuccess: (_d, _s, ctx) => {
            if(ctx.onSuccess) {
                ctx.onSuccess();
            }
        },
    },

    [RemoteScope.MODEL_DETAILS]: {
        scope: RemoteScope.MODEL_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MODEL_DETAILS));
            d(remoteClearError(RemoteScope.MODEL_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/models/${ID}`;
        },
        resMapper: mapModelDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateModel());
        },
    },

    [RemoteScope.MODEL_CREATE]: {
        scope: RemoteScope.MODEL_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MODEL_CREATE));
            d(remoteClearError(RemoteScope.MODEL_CREATE));
        },
        pathMapper: (_s, _ctx) => "/models",
        resMapper: mapModelDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.model,
    },

    [RemoteScope.MODEL_EDIT]: {
        scope: RemoteScope.MODEL_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MODEL_EDIT));
            d(remoteClearError(RemoteScope.MODEL_EDIT));
        },
        pathMapper: (_s, ctx) => `/models/${ctx.model.id ?? ""}`,
        resMapper: mapModelDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.model,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.MODEL_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.COLLECTIVE_INVOICE_RESULTS]: {
        scope: RemoteScope.COLLECTIVE_INVOICE_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_RESULTS));
            d(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_RESULTS));
        },
        pathMapper: () => "/collectiveinvoices",
        resMapper: mapCollectiveInvoiceRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.COLLECTIVE_INVOICES).map(CollectiveInvoiceFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.INVOICE_RESULTS]: {
        scope: RemoteScope.INVOICE_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.INVOICE_RESULTS));
            d(remoteClearError(RemoteScope.INVOICE_RESULTS));
        },
        pathMapper: () => "/invoices",
        resMapper: mapInvoiceRequests,
        paramsMapper: (s, ctx) => {
            const partnerId = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).partnerId;
            const creationDateFrom = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).creationDateFrom;
            const creationDateTo = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).creationDateTo;
            const creditDebitIndicator = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).creditDebitIndicator;
            const invoiceStatus = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).invoiceStatus;
            const invoiceNumber = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).invoiceNumber;
            const collectiveInvoiceNumber = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).collectiveInvoiceNumber;
            const policyNumber = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).policyNumber;
            const invoiceType = s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams).invoiceType;
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                partnerId,
                creationDateFrom,
                creationDateTo,
                creditDebitIndicator,
                invoiceStatus,
                invoiceNumber,
                collectiveInvoiceNumber,
                policyNumber,
                invoiceType,
            };
            return params;
        },
    },

    [RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH]: {
        scope: RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH));
            d(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, _ctx) => "/collectiveinvoices/paid",
        bodyMapper: (s, ctx): BookInvoice => ({
            entryId: ctx.patch.entryId,
            paidObjectId: ctx.patch.paidObjectId,
            paidAmount: ctx.patch.paidAmount,
            description: s.prop("bookingDescription"),
        }),
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                id: ctx.id,
            }));
            d(clearDescription());
        },
    },

    [RemoteScope.COLLECTIVE_INVOICE_INCASSO]: {
        scope: RemoteScope.COLLECTIVE_INVOICE_INCASSO,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_INCASSO));
            d(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_INCASSO));
        },
        method: "POST",
        pathMapper: (_s, _ctx) => "/incasso",
        bodyMapper: (_s, ctx) => ctx.selectedRows,
        resMapper: (resp: AxiosResponse): InExGenerate => {
            const requests = (resp.data as InExGenerate);
            return requests;
        },
        onSuccess: (d, s, _ctx) => {
            d(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_RESULTS, {
                skip: 0,
                ...s.prop("filters").prop(FilterScope.COLLECTIVE_INVOICES).map(CollectiveInvoiceFiltersToParams),
            }));
            d(modalClose("incasso"));
        },
    },

    [RemoteScope.COLLECTIVE_INVOICE_EXCASSO]: {
        scope: RemoteScope.COLLECTIVE_INVOICE_EXCASSO,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.COLLECTIVE_INVOICE_EXCASSO));
            d(remoteClearError(RemoteScope.COLLECTIVE_INVOICE_EXCASSO));
        },
        method: "POST",
        pathMapper: (_s, _ctx) => "/incasso",
        bodyMapper: (_s, ctx) => ctx.selectedRows,
        resMapper: (resp: AxiosResponse): ExcassoGenerate => {
            const requests = (resp.data as ExcassoGenerate);
            return requests;
        },
        onSuccess: (d, s, ctx) => {
            if(!!ctx.forPartnerInvoices) {
                d(remoteTrigger(RemoteScope.INVOICE_RESULTS, {
                    skip: 0,
                    ...s.prop("filters").prop(FilterScope.INVOICES).map(InvoiceFiltersToParams),
                }));
            }
            d(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_RESULTS, {
                skip: 0,
                ...s.prop("filters").prop(FilterScope.COLLECTIVE_INVOICES).map(CollectiveInvoiceFiltersToParams),
            }));
            d(modalClose("excasso"));
        },
    },

    [RemoteScope.ACCOUNT_RESULTS]: {
        scope: RemoteScope.ACCOUNT_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ACCOUNT_RESULTS));
            d(remoteClearError(RemoteScope.ACCOUNT_RESULTS));
        },
        pathMapper: () => "/accounts",
        resMapper: mapAccountRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
        onSuccess: (d, s, ctx) => {
            if(ctx.inexformscope) {
                const accountsResults = s.prop("remote").prop(RemoteScope.ACCOUNT_RESULTS)?.content;
                const hasOnlyOneAccount = accountsResults?.length === 1;
                d(updateInExForm({
                    accountId: hasOnlyOneAccount && accountsResults ? accountsResults[0].id : undefined,
                }));
            }
        },
    },

    [RemoteScope.ACCOUNT_CREATE]: {
        scope: RemoteScope.ACCOUNT_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ACCOUNT_CREATE));
            d(remoteClearError(RemoteScope.ACCOUNT_CREATE));
        },
        pathMapper: (_s, _ctx) => "/accounts",
        resMapper: mapAccountDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.account,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.ACCOUNT_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.ACCOUNT_DETAILS]: {
        scope: RemoteScope.ACCOUNT_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ACCOUNT_DETAILS));
            d(remoteClearError(RemoteScope.ACCOUNT_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/accounts/${ID}`;
        },
        resMapper: mapAccountDetailsRequests,
    },

    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS]: {
        scope: RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS));
            d(remoteClearError(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/policies/businessRules",
        resMapper: mapBusinessRuleViolationRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT]: {
        scope: RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT));
            d(remoteClearError(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT));
        },
        method: "PUT",
        pathMapper: (_s, ctx) => `/policies/businessRules/${ctx.id}`,
        resMapper: _r => ({}),
        bodyMapper: (_s, ctx) => ({
            active: ctx.active,
        }),
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.POLICY_RESULTS]: {
        scope: RemoteScope.POLICY_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.POLICY_RESULTS));
            d(remoteClearError(RemoteScope.POLICY_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/policies",
        resMapper: mapPolicyRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.POLICIES).map(PolicyFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.POLICY_DETAILS]: {
        scope: RemoteScope.POLICY_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.POLICY_DETAILS));
            d(remoteClearError(RemoteScope.POLICY_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/policies/${ID}`;
        },
        resMapper: mapPolicyDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdatePolicy());
        },
    },

    [RemoteScope.POLICY_CREATE]: {
        scope: RemoteScope.POLICY_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.POLICY_CREATE));
            d(remoteClearError(RemoteScope.POLICY_CREATE));
        },
        pathMapper: (_s, _ctx) => "/policies",
        resMapper: mapPolicyDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.policy,
    },

    [RemoteScope.POLICY_EDIT]: {
        scope: RemoteScope.POLICY_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.POLICY_EDIT));
            d(remoteClearError(RemoteScope.POLICY_EDIT));
        },
        pathMapper: (_s, ctx) => `/policies/${ctx.policy.id ?? ""}`,
        resMapper: mapPolicyDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.policy,
        onSuccess: (d, s, _ctx) => {
            d(remoteTrigger(RemoteScope.POLICY_RESULTS, {
                skip: 0,
                ...s.prop("filters").prop(FilterScope.POLICIES).map(PolicyFiltersToParams),
            }));
        },
    },

    [RemoteScope.POLICY_CANCEL_PATCH]: {
        scope: RemoteScope.POLICY_CANCEL_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.POLICY_CANCEL_PATCH));
            d(remoteClearError(RemoteScope.POLICY_CANCEL_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, ctx) => `/policies/cancel/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.POLICY_DETAILS, {
                id: ctx.id,
            }));
        },
    },

    [RemoteScope.POLICY_PREMIUM_PUT]: {
        scope: RemoteScope.POLICY_PREMIUM_PUT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.POLICY_PREMIUM_PUT));
            d(remoteClearError(RemoteScope.POLICY_PREMIUM_PUT));
        },
        method: "PUT",
        pathMapper: (_s, ctx) => `/policies/premium/${ctx.id}`,
        bodyMapper: (_s, ctx): PremiumPolicy => ctx.policyPremium,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose("premium"));
            d(remoteTrigger(RemoteScope.POLICY_DETAILS, {
                id: ctx.policyId,
            }));
        },
    },

    [RemoteScope.PRODUCT_CREATE]: {
        scope: RemoteScope.PRODUCT_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_CREATE));
            d(remoteClearError(RemoteScope.PRODUCT_CREATE));
        },
        pathMapper: (_s, _ctx) => "/extendedwarranties",
        resMapper: mapProductDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.product,
    },

    [RemoteScope.PRODUCT_EDIT]: {
        scope: RemoteScope.PRODUCT_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_EDIT));
            d(remoteClearError(RemoteScope.PRODUCT_EDIT));
        },
        pathMapper: (_s, ctx) => `/extendedwarranties/${ctx.product.id ?? ""}`,
        resMapper: mapProductDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.product,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.PRODUCT_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.PRODUCT_RESULTS]: {
        scope: RemoteScope.PRODUCT_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_RESULTS));
            d(remoteClearError(RemoteScope.PRODUCT_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/extendedwarranties",
        resMapper: mapProductRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.PRODUCTS).map(ProductFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.PRODUCT_DETAILS]: {
        scope: RemoteScope.PRODUCT_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_DETAILS));
            d(remoteClearError(RemoteScope.PRODUCT_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/extendedwarranties/${ID}`;
        },
        resMapper: mapProductDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateProduct());
        },
    },

    [RemoteScope.PRODUCT_ACTIVATE_PATCH]: {
        scope: RemoteScope.PRODUCT_ACTIVATE_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_ACTIVATE_PATCH));
            d(remoteClearError(RemoteScope.PRODUCT_ACTIVATE_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, ctx) => `/extendedwarranties/activate/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.PRODUCT_DETAILS, {
                id: ctx.id,
            }));
        },
    },

    [RemoteScope.PRODUCT_DEACTIVATE_PATCH]: {
        scope: RemoteScope.PRODUCT_DEACTIVATE_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_DEACTIVATE_PATCH));
            d(remoteClearError(RemoteScope.PRODUCT_DEACTIVATE_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, ctx) => `/extendedwarranties/deactivate/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.PRODUCT_DETAILS, {
                id: ctx.id,
            }));
        },
    },

    [RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH]: {
        scope: RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH));
            d(remoteClearError(RemoteScope.PRODUCT_TARIFF_ENDINGDATE_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, ctx) => `/extendedwarranties/producttariffs/endingdate/${ctx.productTariffId}`,
        paramsMapper: (_s, ctx) => {
            const params = {
                endingDate: ctx.endingDate,
            };
            return params;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.PRODUCT_DETAILS, {
                id: ctx.productId,
            }));
        },
    },

    [RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH]: {
        scope: RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH));
            d(remoteClearError(RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, ctx) => `/extendedwarranties/${ctx.productId}/addproducttariff`,
        bodyMapper: (_s, ctx) => {
            const body = { ...ctx.productTariff };
            return body;
        },
        resMapper: mapProductDetailsRequests,
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.PRODUCT_DETAILS, {
                id: ctx.productId,
            }));
        },
    },

    [RemoteScope.PRODUCT_DELETE]: {
        scope: RemoteScope.PRODUCT_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PRODUCT_DELETE));
            d(remoteClearError(RemoteScope.PRODUCT_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => `/extendedwarranties/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.PRODUCT_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.PARTNER_RESULTS]: {
        scope: RemoteScope.PARTNER_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PARTNER_RESULTS));
            d(remoteClearError(RemoteScope.PARTNER_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/partners",
        resMapper: mapPartnerRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.PARTNERS).map(PartnerFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.PARTNER_RESULTS_CI]: {
        scope: RemoteScope.PARTNER_RESULTS_CI,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PARTNER_RESULTS_CI));
            d(remoteClearError(RemoteScope.PARTNER_RESULTS_CI));
        },
        pathMapper: (_s, _ctx) => "/partners/open-invoices",
        resMapper: mapPartnerRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.PARTNERS).map(PartnerFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.PARTNER_DETAILS]: {
        scope: RemoteScope.PARTNER_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PARTNER_DETAILS));
            d(remoteClearError(RemoteScope.PARTNER_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/partners/${ID}`;
        },
        resMapper: mapPartnerDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdatePartner());
        },
    },

    [RemoteScope.PARTNER_CREATE]: {
        scope: RemoteScope.PARTNER_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PARTNER_CREATE));
            d(remoteClearError(RemoteScope.PARTNER_CREATE));
        },
        pathMapper: (_s, _ctx) => "/partners",
        resMapper: mapPartnerDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.partner,
    },

    [RemoteScope.PARTNER_EDIT]: {
        scope: RemoteScope.PARTNER_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PARTNER_EDIT));
            d(remoteClearError(RemoteScope.PARTNER_EDIT));
        },
        pathMapper: (_s, ctx) => `/partners/${ctx.partner.id ?? ""}`,
        resMapper: mapPartnerDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.partner,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.PARTNER_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.PARTNER_BOOK_PATCH]: {
        scope: RemoteScope.PARTNER_BOOK_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PARTNER_BOOK_PATCH));
            d(remoteClearError(RemoteScope.PARTNER_BOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, _ctx) => "/partners/paid",
        bodyMapper: (s, ctx): BookPartner => ({
            entryId: ctx.patch.entryId,
            paidObjectId: ctx.patch.paidObjectId,
            paidAmount: ctx.patch.paidAmount,
            description: s.prop("bookingDescription"),
        }),
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                id: ctx.id,
            }));
            d(clearDescription());
        },
    },

    [RemoteScope.GENERALLEDGER_BOOK_PATCH]: {
        scope: RemoteScope.GENERALLEDGER_BOOK_PATCH,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GENERALLEDGER_BOOK_PATCH));
            d(remoteClearError(RemoteScope.GENERALLEDGER_BOOK_PATCH));
        },
        method: "PATCH",
        pathMapper: (_s, _ctx) => "/generalledgers/paid",
        bodyMapper: (s, ctx): BookLedger => ({
            entryId: ctx.patch.entryId,
            paidObjectId: ctx.patch.paidObjectId,
            paidAmount: ctx.patch.paidAmount,
            description: s.prop("bookingDescription"),
        }),
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.STATEMENT_DETAILS, {
                id: ctx.id,
            }));
            d(clearDescription());
        },
    },

    [RemoteScope.GENERATE_REPORT_ACCOUNTING_POST]: {
        scope: RemoteScope.GENERATE_REPORT_ACCOUNTING_POST,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GENERATE_REPORT_ACCOUNTING_POST));
            d(remoteClearError(RemoteScope.GENERATE_REPORT_ACCOUNTING_POST));
        },
        method: "POST",
        pathMapper: (_s, ctx) => {
            if (ctx.fields.type === ReportFormScope.INSURANCE_OVERVIEW) {
                return "/reports/insurance";
            } else if (ctx.fields.type === ReportFormScope.OUTSTANDING_ITEMS) {
                return "/reports/outstandingItems";
            }

            return "/reports/nvga";
        },
        paramsMapper: (_s, ctx) => {
            if (ctx.fields.type === ReportFormScope.OUTSTANDING_ITEMS) {
                return {
                    referenceDate: ctx.fields.referenceDate,
                };
            }
            return {};
        },
        bodyMapper: (_s, ctx) => ctx.fields,
        resMapper: (resp: AxiosResponse): ReportAccountingGenerate => {
            const requests = (resp.data as ReportAccountingGenerate);
            return requests;
        },
    },

    [RemoteScope.PROXIES]: {
        scope: RemoteScope.PROXIES,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PROXIES));
            d(remoteClearError(RemoteScope.PROXIES));
        },
        pathMapper: () => "/proxies",
        resMapper: (resp: AxiosResponse, _s, ctx): Proxies => {
            const requests = (resp.data as Proxies);
            if(ctx.forReports) {
                return {
                    ...requests,
                    content: requests.content
                        .filter(f => f.name === "N920" || f.name === "N930"),
                };
            }
            return requests;
        },
        onSuccess: (d, _s, ctx) => {
            if(ctx.forReports) {
                d(initUpdateReportForm());
            }
            if(ctx.forProducts) {
                d(initUpdateProductProxy());
            }
        },
    },

    [RemoteScope.FILE_INIT_DOWNLOAD]: {
        method: "GET",
        scope: RemoteScope.FILE_INIT_DOWNLOAD,
        onInit: (d, _s, ctx) => {
            d(remoteClearResponse(RemoteScope.FILE_INIT_DOWNLOAD));
            d(remoteClearError(RemoteScope.FILE_INIT_DOWNLOAD));
            d(fileDownloadInit(ctx.id, RemoteScope.FILE_INIT_DOWNLOAD));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/files/${ID}`;
        },
        resMapper: (resp: AxiosResponse, _s, _ctx): FileDownload => {
            const requests = (resp.data as RequestMessage);
            const message = requests.content;
            return {
                id: message.id,
                fileName: message.filePath,
                preassignedURL: message.preassignedURL,
            };
        },
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.FILE_DOWNLOAD, undefined));
        },
    },

    [RemoteScope.FILE_DOWNLOAD]: {
        method: "GET",
        scope: RemoteScope.FILE_DOWNLOAD,
        responseType: "blob",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.FILE_DOWNLOAD));
            d(remoteClearError(RemoteScope.FILE_DOWNLOAD));
        },
        pathMapper: (s, _ctx) => {
            const getFileInfo = s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD);
            if (getFileInfo && getFileInfo.preassignedURL) {
                return `${getFileInfo.preassignedURL}`;
            } else {
                throw new Error("Claude, couldn't find any file");
            }
        },
        dontIncludeAuthHeader: true,
        resMapper: (resp: AxiosResponse, s, _ctx): FileDownload => {
            const requests = (resp.data as Blob);
            const getFileInfo = s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD);

            if (getFileInfo && getFileInfo.preassignedURL) {
                try {
                    saveAs(
                        new Blob([requests ]),
                        getFileInfo.fileName ? getFileInfo.fileName : "Unknown.txt",
                    );
                } catch (err) {
                    throw new Error("Claude can't provide a download for this file.");
                }
                return {
                    ...getFileInfo,
                };
            } else {
                throw new Error("Claude can't provide a download for this file.");
            }
        },
        onSuccess: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.FILE_INIT_DOWNLOAD));
        },
        onError: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.FILE_INIT_DOWNLOAD));
        },
    },

    [RemoteScope.FILES]: {
        scope: RemoteScope.FILES,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.FILES));
            d(remoteClearError(RemoteScope.FILES));
        },
        pathMapper: () => "/files",
        resMapper: mapFileRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.FILES).map(FileFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.TARIFF_RESULTS]: {
        scope: RemoteScope.TARIFF_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.TARIFF_RESULTS));
            d(remoteClearError(RemoteScope.TARIFF_RESULTS));
        },
        pathMapper: () => "/tariffs",
        resMapper: mapTariffRequests,
        paramsMapper: (s, ctx) => {
            const maxKW = s.prop("filters").prop(FilterScope.TARIFFS).map(TariffFiltersToParams).maxKW;
            const commercialVehicle = s.prop("filters").prop(FilterScope.TARIFFS).map(TariffFiltersToParams).commercialVehicle;
            const passengerVehicle = s.prop("filters").prop(FilterScope.TARIFFS).map(TariffFiltersToParams).passengerVehicle;
            const modelId = s.prop("filters").prop(FilterScope.TARIFFS).map(TariffFiltersToParams).modelId;
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                maxKW,
                commercialVehicle,
                passengerVehicle,
                modelId,
            };
            return params;
        },
    },

    [RemoteScope.TARIFF_DETAILS]: {
        scope: RemoteScope.TARIFF_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.TARIFF_DETAILS));
            d(remoteClearError(RemoteScope.TARIFF_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/tariffs/${ID}`;
        },
        resMapper: mapTariffDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateTariff());
        },
    },

    [RemoteScope.TARIFF_CREATE]: {
        scope: RemoteScope.TARIFF_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.TARIFF_CREATE));
            d(remoteClearError(RemoteScope.TARIFF_CREATE));
        },
        pathMapper: (_s, _ctx) => "/tariffs",
        resMapper: mapTariffDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.tariff,
    },

    [RemoteScope.TARIFF_EDIT]: {
        scope: RemoteScope.TARIFF_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.TARIFF_EDIT));
            d(remoteClearError(RemoteScope.TARIFF_EDIT));
        },
        pathMapper: (_s, ctx) => `/tariffs/${ctx.tariff.id ?? ""}`,
        resMapper: mapTariffDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.tariff,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.TARIFF_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.TARIFF_DELETE]: {
        scope: RemoteScope.TARIFF_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.TARIFF_DELETE));
            d(remoteClearError(RemoteScope.TARIFF_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => {
            const tariffId = ctx.tariffId;
            return `/tariffs/${tariffId}`;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(
                RemoteScope.TARIFF_RESULTS, {
                    skip: 0,
                }));
        },
    },

    [RemoteScope.MANUAL_BOOKING_RESULTS]: {
        scope: RemoteScope.MANUAL_BOOKING_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANUAL_BOOKING_RESULTS));
            d(remoteClearError(RemoteScope.MANUAL_BOOKING_RESULTS));
        },
        pathMapper: () => "/freebooking",
        resMapper: mapMBResultsRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.MANUAL_BOOKING).map(MBFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.MANUAL_BOOKING_CREATE]: {
        scope: RemoteScope.MANUAL_BOOKING_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANUAL_BOOKING_CREATE));
            d(remoteClearError(RemoteScope.MANUAL_BOOKING_CREATE));
        },
        pathMapper: (_s, _ctx) => "/freebooking",
        resMapper: mapManualBookingRequests,
        bodyMapper: (_s, ctx) => ctx.manualBooking,
    },

    [RemoteScope.BOOKING_PERIOD]: {
        scope: RemoteScope.BOOKING_PERIOD,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BOOKING_PERIOD));
            d(remoteClearError(RemoteScope.BOOKING_PERIOD));
        },
        pathMapper: (_s, _ctx) => "/bookingperiods",
        resMapper: mapBookingPeriodRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.BOOKING_PERIOD).map(BookingPeriodFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.BOOKING_PERIOD_OPEN]: {
        scope: RemoteScope.BOOKING_PERIOD_OPEN,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BOOKING_PERIOD_OPEN));
            d(remoteClearError(RemoteScope.BOOKING_PERIOD_OPEN));
        },
        method: "PATCH",
        pathMapper: (_s, _ctx) => "/bookingperiods/open",
        paramsMapper: (_s, ctx) => {
            const params = {
                id: ctx.id,
            };
            return params;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.BOOKING_PERIOD, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.BOOKING_PERIOD_CLOSE]: {
        scope: RemoteScope.BOOKING_PERIOD_CLOSE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BOOKING_PERIOD_CLOSE));
            d(remoteClearError(RemoteScope.BOOKING_PERIOD_CLOSE));
        },
        method: "PATCH",
        pathMapper: (_s, _ctx) => "/bookingperiods/close",
        paramsMapper: (_s, ctx) => {
            const params = {
                id: ctx.id,
            };
            return params;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.BOOKING_PERIOD, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.BOOKING_PERIOD_CREATE]: {
        scope: RemoteScope.BOOKING_PERIOD_CREATE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.BOOKING_PERIOD_CREATE));
            d(remoteClearError(RemoteScope.BOOKING_PERIOD_CREATE));
        },
        method: "POST",
        pathMapper: (_s, _ctx) => "/bookingperiods/next",
        resMapper: (resp: AxiosResponse): BookingPeriod => resp.data as BookingPeriod,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.BOOKING_PERIOD, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.PAYMENT_RESULTS]: {
        scope: RemoteScope.PAYMENT_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.PAYMENT_RESULTS));
            d(remoteClearError(RemoteScope.PAYMENT_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/payments",
        resMapper: mapPaymentRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.PAYMENTS).map(PaymentFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.GENERAL_LEDGER_CREATE]: {
        scope: RemoteScope.GENERAL_LEDGER_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GENERAL_LEDGER_CREATE));
            d(remoteClearError(RemoteScope.GENERAL_LEDGER_CREATE));
        },
        pathMapper: (_s, _ctx) => "/generalledgers",
        resMapper: mapGLDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.ledger,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.GENERAL_LEDGER, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.GENERAL_LEDGER_EDIT]: {
        scope: RemoteScope.GENERAL_LEDGER_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GENERAL_LEDGER_EDIT));
            d(remoteClearError(RemoteScope.GENERAL_LEDGER_EDIT));
        },
        pathMapper: (_s, ctx) => `/generalledgers/${ctx.ledger.id ?? ""}`,
        resMapper: mapGLDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.ledger,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.GENERAL_LEDGER, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.GENERAL_LEDGER_DETAILS]: {
        scope: RemoteScope.GENERAL_LEDGER_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GENERAL_LEDGER_DETAILS));
            d(remoteClearError(RemoteScope.GENERAL_LEDGER_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/generalledgers/${ID}`;
        },
        resMapper: mapGLDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateGL());
        },
    },

    [RemoteScope.MANDATE_RESULTS]: {
        scope: RemoteScope.MANDATE_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANDATE_RESULTS));
            d(remoteClearError(RemoteScope.MANDATE_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/mandates",
        resMapper: mapMandateRequests,
        paramsMapper: (s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
                ...s.prop("filters").prop(FilterScope.MANDATES).map(MandateFiltersToParams),
            };
            return params;
        },
    },

    [RemoteScope.MANDATE_DETAILS]: {
        scope: RemoteScope.MANDATE_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANDATE_DETAILS));
            d(remoteClearError(RemoteScope.MANDATE_DETAILS));
        },
        pathMapper: (_s, ctx) => {
            const ID = ctx.id;
            return `/mandates/${ID}`;
        },
        resMapper: mapMandateDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateMandate());
        },
    },

    [RemoteScope.MANDATE_CREATE]: {
        scope: RemoteScope.MANDATE_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANDATE_CREATE));
            d(remoteClearError(RemoteScope.MANDATE_CREATE));
        },
        pathMapper: (_s, _ctx) => "/mandates",
        resMapper: mapMandateDetailsRequests,
        bodyMapper: (s, ctx) => {
            const mandate: MandateRequest = {
                partner: { id: s.prop("remote").prop(RemoteScope.PARTNER_DETAILS)?.content.id ?? 0 },
                ...ctx.mandate,
            };
            return mandate;
        },
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.MANDATE_RESULTS, {
                skip: 0,
            }));
            d(modalClose("mandate"));
        },
    },

    [RemoteScope.MANDATE_EDIT]: {
        scope: RemoteScope.MANDATE_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANDATE_EDIT));
            d(remoteClearError(RemoteScope.MANDATE_EDIT));
        },
        pathMapper: (_s, ctx) => `/mandates/${ctx.mandate.id ?? ""}`,
        resMapper: mapMandateDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.mandate,
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.MANDATE_RESULTS, {
                skip: 0,
            }));
            d(modalClose(ctx.modalID));
        },
    },

    [RemoteScope.MANDATE_DELETE]: {
        scope: RemoteScope.MANDATE_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MANDATE_DELETE));
            d(remoteClearError(RemoteScope.MANDATE_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => `/mandates/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.MANDATE_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.MEMO_RESULTS]: {
        scope: RemoteScope.MEMO_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MEMO_RESULTS));
            d(remoteClearError(RemoteScope.MEMO_RESULTS));
        },
        pathMapper: (s, _ctx) => {
            const policyId = s.prop("remote").prop(RemoteScope.POLICY_DETAILS)?.content.id;
            return `/policies/${policyId ?? ""}/memos`;
        },
        resMapper: mapMemoRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.MEMO_CREATE]: {
        scope: RemoteScope.MEMO_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MEMO_CREATE));
            d(remoteClearError(RemoteScope.MEMO_CREATE));
        },
        pathMapper: (s, _ctx) => {
            const policyId = s.prop("remote").prop(RemoteScope.POLICY_DETAILS)?.content.id;
            return `/policies/${policyId ?? ""}/memo/create`;
        },
        resMapper: mapMemoDetailsRequests,
        bodyMapper: (_s, ctx) => {
            const memo: MemoRequest = {
                ...ctx.memo,
            };
            return memo;
        },
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.MEMO_RESULTS, {
                skip: 0,
            }));
            d(modalClose("memo"));
        },
    },

    [RemoteScope.MEMO_EDIT]: {
        scope: RemoteScope.MEMO_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MEMO_EDIT));
            d(remoteClearError(RemoteScope.MEMO_EDIT));
        },
        pathMapper: (_s, ctx) => `/policies/${ctx.memo.id ?? ""}/memo/update`,
        resMapper: mapMemoDetailsRequests,
        bodyMapper: (_s, ctx) => ctx.memo,
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.MEMO_RESULTS, {
                skip: 0,
            }));
            d(modalClose(ctx.modalID));
        },
    },

    [RemoteScope.MEMO_DELETE]: {
        scope: RemoteScope.MEMO_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MEMO_DELETE));
            d(remoteClearError(RemoteScope.MEMO_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => `/policies/memo/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.MEMO_RESULTS, {
                skip: 0,
            }));
        },
    },

    [RemoteScope.AUDIT_TRAIL_POLICY]: {
        scope: RemoteScope.AUDIT_TRAIL_POLICY,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_POLICY));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_POLICY));
        },
        pathMapper: (_s, ctx) => `/policies/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_PRODUCT]: {
        scope: RemoteScope.AUDIT_TRAIL_PRODUCT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_PRODUCT));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_PRODUCT));
        },
        pathMapper: (_s, ctx) => `/extendedwarranties/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_PARTNER]: {
        scope: RemoteScope.AUDIT_TRAIL_PARTNER,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_PARTNER));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_PARTNER));
        },
        pathMapper: (_s, ctx) => `/partners/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_TARIFF]: {
        scope: RemoteScope.AUDIT_TRAIL_TARIFF,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_TARIFF));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_TARIFF));
        },
        pathMapper: (_s, ctx) => `/tariffs/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_PAYMENT]: {
        scope: RemoteScope.AUDIT_TRAIL_PAYMENT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_PAYMENT));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_PAYMENT));
        },
        pathMapper: (_s, ctx) => `/payments/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_GL]: {
        scope: RemoteScope.AUDIT_TRAIL_GL,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_GL));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_GL));
        },
        pathMapper: (_s, ctx) => `/generalledgers/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD]: {
        scope: RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_BOOKING_PERIOD));
        },
        pathMapper: (_s, ctx) => `/bookingperiods/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_CI]: {
        scope: RemoteScope.AUDIT_TRAIL_CI,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_CI));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_CI));
        },
        pathMapper: (_s, ctx) => `/collectiveinvoices/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_INVOICE]: {
        scope: RemoteScope.AUDIT_TRAIL_INVOICE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_INVOICE));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_INVOICE));
        },
        pathMapper: (_s, ctx) => `/invoices/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_STATEMENT]: {
        scope: RemoteScope.AUDIT_TRAIL_STATEMENT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_STATEMENT));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_STATEMENT));
        },
        pathMapper: (_s, ctx) => `/statements/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.AUDIT_TRAIL_FREEBOOKING]: {
        scope: RemoteScope.AUDIT_TRAIL_FREEBOOKING,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.AUDIT_TRAIL_FREEBOOKING));
            d(remoteClearError(RemoteScope.AUDIT_TRAIL_FREEBOOKING));
        },
        pathMapper: (_s, ctx) => `/freebooking/${ctx.id}/changes`,
        resMapper: mapAuditTrailsRequests,
        paramsMapper: (_s, ctx) => {
            const params = {
                skip: ctx.skip,
                limit: RESULTS_LIMIT,
            };
            return params;
        },
    },

    [RemoteScope.VERSION]: {
        scope: RemoteScope.VERSION,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.VERSION));
            d(remoteClearError(RemoteScope.VERSION));
        },
        pathMapper: (_s, _ctx) => "/version",
        resMapper: mapVersionRequest,
        onSuccess: (_d, s, _ctx) => {
            console.log("EWS by Claude is using API: ",
                s.prop("remote").prop(RemoteScope.VERSION));
        },
    },
};
