import * as React from "react";
import { connect } from "react-redux";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { FreeBookingItem } from "../../../../../store/ManualBooking/Types";
import { ManualBookingTypeDisplay } from "../../../../../store/ManualBooking/Displays";
import { getManualBookingTypeColor } from "../../../../utils/FormatHelpers";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { isNegative } from "../../../../../utils/FormatHelpers";
import { modalClose } from "../../../../../utils/redux/ActionTypes";
import { Container } from "../../../../../components/core/03-base/Container";

/**
 *
 */
interface OwnProps {
    modalID: string;
    id: string;
    items: FreeBookingItem[];
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
});

/**
 *
 */
export class DetailModalComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {};

        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    public render() {
        return (
            <FlexColumn>
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.BRAND_ACCENT,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    Free booking summary
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button   icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }}/>
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn>
                        <FlexRow isScrollable >
                            <FillUp>
                                <Table
                                    hasActions
                                    data={{
                                        columns: this.resultsData().columns,
                                        rows: this.resultsData().rows,
                                        sort: this.resultsData().sort,
                                    }}
                                />
                            </FillUp>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
                <FlexRow isFixed>
                    <FlexColumn
                        theme={{
                            palette: ThemePalette.CONTRAST_PRIMARY,
                        }}
                    >
                        <Container
                            theme={{
                                padding: { "": { y: 3 } },
                            }}
                        >
                            <ButtonsWrapper
                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                alignment={Alignment.RIGHT}
                            >
                                <Button
                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                    link={{ onClick: this.closeModal() }}
                                >
                                    Close
                                </Button>
                            </ButtonsWrapper>
                        </Container>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn >
        );
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.items.map((row, _index) => ({
            id: row.id,
            data: [
                row.objectType ?
                    (
                        <Badge
                            theme={{
                                palette: getManualBookingTypeColor(row.objectType),
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        >
                            {ManualBookingTypeDisplay[row.objectType]}
                        </Badge>
                    )
                    : "",
                row.description ?? "",
                !isNegative(row.amount) ?
                    (
                        <h6>
                            <Currency amount={row.amount} />
                        </h6>
                    ) : "",
                isNegative(row.amount) ?
                    (
                        <h6>
                            <Currency amount={- row.amount} />
                        </h6>
                    ) : "",
            ],
        }));
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Type",
                },
                {
                    label: "Number / Code",
                },
                {
                    label: "Debit balance",
                },
                {
                    label: "Credit balance",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const DetailModal = connect(
    undefined,
    mapDispatchToProps,
)(DetailModalComp);
