import * as React from "react";
import { connect } from "react-redux";

import { ReduxState } from "../../../../../../store/ReduxState";
import * as ClaimTypes from "../../../../../../store/Claims/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { ClaimStatusDisplay } from "../../../../../../store/Claims/Displays";
import { PaidStatusTypeDisplay } from "../../../../../../store/AppDisplays";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";

/**
 *
 */
interface StateProps {
    details: ClaimTypes.ClaimDetails;
}

/**
 *
 */
type Props = StateProps;

/**
 *
 * @param props
 */
export class GeneralComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public static mapStateToProps(s: ReduxState): StateProps {
        return {
            details: s.prop("remote").prop(RemoteScope.CLAIM_DETAILS) as ClaimTypes.ClaimDetails,
        };
    }

    public render() {
        const general = this.props.details &&
                        this.props.details.content ?
            this.props.details.content : undefined;
        return (
            <React.Fragment>
                <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
                    <Container
                        theme={{
                            palette: ThemePalette.CONTRAST_PRIMARY,
                            padding: {
                                "": { y: 3 },
                                "sm": { y: 4 },
                            },
                        }}
                    >
                        <FieldSet title="Claim info">
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 3 }}
                                >
                                    <FormField
                                        label="Claim number"
                                        value={general && general.claimNumber}
                                        asSummary
                                    />
                                </Grid>

                            </Div>
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Claim number (PAH)"
                                        value={general && general.claimNumberPAH}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Chassis number"
                                        value={general && general.chassisNumber}
                                        asSummary
                                    />
                                </Grid>
                            </Div>
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Policy number"
                                        value={general && general.policyNumber}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Status"
                                        value={general
                                                    && PaidStatusTypeDisplay[ClaimStatusDisplay[general.status]]}
                                        asSummary
                                    />
                                </Grid>
                            </Div>
                        </FieldSet>
                    </Container>
                </Div>
            </React.Fragment>
        );
    }
}

/**
 *
 */
export const General = connect(
    GeneralComp.mapStateToProps,
    {},
)(GeneralComp);
