import { RequestObject } from "../RemoteTypes";
import { Policy, BusinessRuleViolation } from "../Policies/Types";
import { Message } from "../Message/Types";

// export type General = Readonly<Partial<{
//     claimId: string;
//     dealer: string;
//     licensePlate: string;
//     policyNumber: string;
// }>>;

// export type Conditions = Readonly<Partial<{
//     claimId: string;
//     dealer: string;
// }>>;

// export type Bookings = Readonly<Partial<{
//     licensePlate: string;
//     policyNumber: string;
// }>>;

export enum ClaimStatus {
    UNPAID = "UNPAID",
    ACCEPTED = "ACCEPTED",
    UPDATED = "UPDATED",
    PAID = "PAID",
    ERROR = "ERROR",
}

export enum DamageStatus {
    RECEIVED = "RECEIVED",
    MATCHED = "MATCHED",
    ERROR = "ERROR",
    DUPLICATED = "DUPLICATED",
    UPDATE = "UPDATE", // new
}

interface DamageBookingLine {
    id: number;
    createdAt: string;
    claimElementType: string;
    recordType: string;
    claimNumberPAH: string;
    claimNumber: string;
    message: Message;
    status: DamageStatus;
    relationNumberStakeholderPAH: string;
    referenceHandler: string;
    bankAccountBeneficiaryNumber: string;
    cascoDamageAmountInternalMaterial: number;
    cascoDamageAmountInternalLabour: number;
    cascoDamageAmountExternalMaterial: number;
    cascoDamageAmountExternalLabour: number;
    claim: object;
}

interface ClaimBusinessRuleViolation extends BusinessRuleViolation {
    damageReportLine: any; // check
}

export interface DamageReportLine {
    id: number;
    createdAt: string;
    claimElementType: string;
    recordType: string;
    claimNumberPAH: string;
    claimNumber: string;
    message: Message;
    status: DamageStatus;
    policyNumber: string;
    mutationCode: string;
    dateOfDamage: string;
    timeOfDamage: string;
    damageType: string;
    registrationDate: string;
    dateProcessed: string;
    processed: string;
    damageReserveCasco: number;
    damageCause: string;
    claim: Claim;
    businessRuleViolations: ClaimBusinessRuleViolation[];
}

interface StakeholderLine {
    id: number;
    createdAt: string;
    claimElementType: string;
    recordType: string;
    claimNumberPAH: string;
    claimNumber: string;
    message: Message;
    status: DamageStatus;
    relationNumberStakeholderPAH: string;
    relationNumberStakeholder: string;
    mutationCode: string;
    permanentStakeholder: string;
    stakeholderType: string;
    name: string;
    address: string;
    zipCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    residence: string;
    bank1: string;
    claim: object;
}

export interface Claim {
    id: number;
    createdAt?: string;
    claimNumber: string;
    claimNumberPAH: string;
    claimDate: string;
    bookingDate: string;
    chassisNumber: string;
    policyNumber: string;
    damageBookings: DamageBookingLine[];
    damageReports: DamageReportLine[];
    stakeholders: StakeholderLine[];
    status: ClaimStatus;
    policy: Policy;
    amount: number; // new
    policyId: number;
    // balance: number; no more there
}

export interface Claims extends RequestObject {
    content: Claim[];
}

export interface ClaimDetails {
    content: Claim;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type ClaimFilters = Readonly<Partial<{
    claimNumber: string;
    claimNumberPAH: string;
    chassisNumber: string;
    policyNumber: string;
    claimDate: string;
    licensePlate: string;
}>>;

/**
 *
 */
export const ClaimFiltersToParams = (f: ClaimFilters) => ({
    ...f,
});
