import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { State } from "../../utils/redux/State";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { MandateRequest } from "./Types";

/**
 *
 */
export type InitUpdateMandateAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_MANDATE> & {}
>;

/**
 *
 *
 */
export const initUpdateMandate =
    (): InitUpdateMandateAction => ({
        type: ActionTypeKeys.INIT_UPDATE_MANDATE,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateMandateReducer:
ReducerFn<InitUpdateMandateAction> =
    (s, _a) => {
        const mandateDetails = s.prop("remote").prop(RemoteScope.MANDATE_DETAILS);
        return (
            s.setProp(
                "mandateRequest",
                s.prop("mandateRequest").update((_old) => mandateDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateMandateAction = Readonly<
Action<ActionTypeKeys.UPDATE_MANDATE> & {
    mandate?: Partial<MandateRequest>;
}
>;

/**
 *
 *
 */
export const updateMandate =
    (mandate: MandateRequest): UpdateMandateAction => ({
        type: ActionTypeKeys.UPDATE_MANDATE,
        mandate: mandate,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateMandateReducer:
ReducerFn<UpdateMandateAction> =
    (s, a) => (
        s.setProp(
            "mandateRequest",
            s.prop("mandateRequest").updateWith(
                a.mandate ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearMandateAction = Readonly<
Action<ActionTypeKeys.CLEAR_MANDATE>
>;

/**
 *
 * @param keys
 */
export const clearMandate:
() => ClearMandateAction =
    () => ({
        type: ActionTypeKeys.CLEAR_MANDATE,
    });

/**
 *
 */
export const clearMandateReducer:
ReducerFn<ClearMandateAction> =
    (s, _a) => (
        s.setProp(
            "mandateRequest",
            State.create<MandateRequest>(() => ({})),
        )
    );
