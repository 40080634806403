import { RemoteScope } from "../../store/RemoteTypes";
import { ReducerFn } from "../ReduxState";
import { ActionTypeKeys } from "../ActionTypes";
import { Action } from "redux";

/**
 *
 */
type FileDownloadInitAction = Readonly<
Action<ActionTypeKeys.FILE_DOWNLOAD_INIT> & {
    id: number;
    scope?: RemoteScope;
    fileName?: string;
}>;

/**
 *
 * @param keys
 */
export const fileDownloadInit:
(
    id: number,
    scope?: RemoteScope,
    fileName?: string) => FileDownloadInitAction =
    (id, scope, fileName) => ({
        type: ActionTypeKeys.FILE_DOWNLOAD_INIT,
        id: id,
        scope: scope,
        fileName: fileName,
    });

/**
 *
 */
export const fileDownloadInitReducer:
ReducerFn<FileDownloadInitAction> =
    (s, a) => (
        a.scope
            ?
            s.setProp(
                "remote",
                s.prop("remote").setProp(
                    a.scope,
                    {
                        id: a.id,
                        fileName: a.fileName,
                    },
                ),
            )
            :
            s
    );
