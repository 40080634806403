/* eslint-disable max-len */
import * as React from "react";
import { Currency } from "../../../../components/core/05-atoms/CurrencyDisplay";
import { Link } from "../../../../components/core/05-atoms/Link";
import { ValidationFeedback } from "../../../../components/core/07-organisms/Form/Field.utils";
import { ValidationStatus } from "../../../../components/core/07-organisms/Form/_Types";
import { roundTwoDecimals } from "../../../utils/Booking";

export const sumOfOtherComp = (netMij: number, tax: number, cost: number, signCommitteeDamage: number, incassoProvision: number) => {
    const roundedNetMij = roundTwoDecimals(netMij);
    const roundedTax = roundTwoDecimals(tax);
    const roundedCost = roundTwoDecimals(cost);
    const roundedSignCommitteeDamage = roundTwoDecimals(signCommitteeDamage);
    const roundedIncassoProvision = roundTwoDecimals(incassoProvision);
    return roundTwoDecimals(roundedNetMij + roundedTax + roundedCost + roundedSignCommitteeDamage + roundedIncassoProvision);
};

export const sumOfOtherCompDifference = (premium: number, sumOfOtherComp: number) => {
    const roundedPremium = roundTwoDecimals(premium);
    return roundTwoDecimals(roundedPremium - sumOfOtherComp);
};

export const validationCheck = (
    premium: number,
    netMij: number,
    tax: number,
    cost: number,
    signCommitteeDamage: number,
    incassoProvision: number,
    onChange: (key: string, value: number) => void,
    message?: string): ValidationFeedback => {
    const sum = sumOfOtherComp(netMij, tax, cost, signCommitteeDamage, incassoProvision);
    if (premium !== sum) {
        return {
            status: ValidationStatus.ERROR,
            message: (
                <>
                    The sum of the premium components (<Currency amount={sum}/>) isn&apos;t equal to the premium (<Currency amount={premium}/>)<br/>
                    The difference is (<Currency amount={sumOfOtherCompDifference(premium, sum)}/>).Do you want to correct this?
                    <Link
                        link={{ onClick: () => onChange("premium", sum)}}
                        style={{ marginLeft: "10px" }}
                    >
                        Yes, please correct
                    </Link>
                </>
            ),
        };
    }
    return {
        status: ValidationStatus.SUCCESS,
        message: message,
    };
};
