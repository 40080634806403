/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { Label } from "../../../../../components/core/05-atoms/Label";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/index";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { Menu, MenuOrientation } from "../../../../../components/core/07-organisms/Menu";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../../store/AppDisplays";
import { Brands } from "../../../../../store/Brand/Types";
import { updateFilters, clearFilters } from "../../../../../store/FilterActions";
import { getFilters, FilterScope } from "../../../../../store/FilterTypes";
import { Models } from "../../../../../store/Models/Types";
import { PartnerFilters, Partners, PartnerType } from "../../../../../store/Partners/Types";
import { CoverageLevelDisplay } from "../../../../../store/Policies/Displays";
import { CoverageLevel } from "../../../../../store/Policies/Types";
import { updateProduct, clearProduct } from "../../../../../store/Product/CreateType";
import { ProductRequest, ProductDetail, canSubmitEWForm } from "../../../../../store/Product/Types";
import { Proxies } from "../../../../../store/Proxies/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RefObject, RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { CreateTariff } from "./CreateTariff";


/**
 *
 */
type setFilterFn = (filters: Partial<PartnerFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof PartnerFilters>) => void;

interface OwnProps {
    edit: boolean;
    ewId: string;
}

interface StateProps {
    product: ProductRequest;
    loadingFormHelpers: boolean;
    formHelpers: {
        proxiesResults?: Proxies;
        insurerResults?: Partners;
        brandResults?: Brands;
        modelResults?: Models;
    };
    filters: PartnerFilters;
    canSubmitEWForm: boolean;
    resultsSuccess: ProductDetail;
}

interface DispatchProps {
    updateProduct: (product: ProductRequest) => void;
    clear: () => void;
    loadFormHelpers: (edit: boolean) => void;
    loadProductDetailResults: (id: string) => void;
    addProduct: (product: ProductRequest) => void;
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    editProduct: (product: ProductRequest) => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    product: getProduct(s),
    loadingFormHelpers: isRemoteLoading(s, RemoteScope.PROXIES) ||
            isRemoteLoading(s, RemoteScope.PARTNER_RESULTS) || isRemoteLoading(s, RemoteScope.BRAND_RESULTS)
            || isRemoteLoading(s, RemoteScope.MODEL_RESULTS),
    formHelpers: {
        proxiesResults: s.prop("remote").prop(RemoteScope.PROXIES),
        insurerResults: s.prop("remote").prop(RemoteScope.PARTNER_RESULTS),
        brandResults: s.prop("remote").prop(RemoteScope.BRAND_RESULTS),
        modelResults: s.prop("remote").prop(RemoteScope.MODEL_RESULTS),
    },
    filters: getFilters(s, FilterScope.PARTNERS) as PartnerFilters,
    canSubmitEWForm: canSubmitEWForm(s.prop("productRequest")),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.PRODUCT_CREATE)
            || s.prop("remote").prop(RemoteScope.PRODUCT_EDIT)) as ProductDetail,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateProduct: (product: ProductRequest) => dispatch(updateProduct(product)),
    clear: () => {
        dispatch(clearProduct());
        dispatch(remoteClearResponse(RemoteScope.PROXIES));
        dispatch(remoteClearResponse(RemoteScope.PARTNER_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.BRAND_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.MODEL_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_CREATE));
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_EDIT));
    },
    loadFormHelpers: (edit: boolean) => {
        dispatch(remoteTrigger(RemoteScope.PROXIES, { forProducts: edit !== true}));
        dispatch(remoteTrigger(RemoteScope.PARTNER_RESULTS, { skip: 0 }));
        dispatch(remoteTrigger(RemoteScope.BRAND_RESULTS, { skip: 0 }));
        dispatch(remoteTrigger(RemoteScope.MODEL_RESULTS, { skip: 0 }));
    },
    addProduct: (product: ProductRequest) => dispatch(remoteTrigger(RemoteScope.PRODUCT_CREATE, {
        product: product,
    })),
    editProduct: (product: ProductRequest) => dispatch(remoteTrigger(RemoteScope.PRODUCT_EDIT, {
        product: product,
    })),
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.PARTNERS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.PARTNERS));
    },
    loadProductDetailResults: (id: string) => dispatch(remoteTrigger(RemoteScope.PRODUCT_DETAILS, { id: id })),
});

/**
 *
 * @param s
 */
export const getProduct =
    (s: ReduxState): ProductRequest =>
        s.prop("productRequest").map(e => ({
            ...e,
        }));

/**
 *
 */
export class CreateProductComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.addProduct = this.addProduct.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
        this.props.clear();
        this.props.loadFormHelpers(this.props.edit);
        if (this.props.edit) {
            this.props.loadProductDetailResults(this.props.ewId);
        }
    }

    public componentWillUnmount() {
        this.clearFilters();
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {this.props.edit ? (
                        <Header
                            title={SubPageDisplay[SubPages.PRODUCT_EDIT].title}
                            goBack={`/extendedwarranties/${this.props.ewId}`}
                        />
                    ) : (
                        <Header
                            title={SubPageDisplay[SubPages.PRODUCT_CREATE].title}
                            goBack={PageDisplay[Pages.PRODUCT].path}
                        />
                    )}
                    {this.props.loadingFormHelpers ||
                        (
                            !this.props.formHelpers.brandResults ||
                            !this.props.formHelpers.insurerResults ||
                            !this.props.formHelpers.modelResults ||
                            !this.props.formHelpers.proxiesResults)
                        ? (
                            <LoadingData loading={this.props.loadingFormHelpers} />
                        )
                        : (<>
                            {this.props.resultsSuccess && (
                                <Redirect to={`/extendedwarranties/${this.props.resultsSuccess.content.id}`} />
                            )}
                            <Form onSubmit={this.props.edit ? this.editProduct : this.addProduct}>
                                <Div
                                    theme={{
                                        shadow: ThemeShadowSizes.TINY,
                                        margin: {
                                            "": { t: 2 },
                                            "sm": { t: 3 },
                                        },
                                    }}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Name"
                                                    required
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("name", value ? value.toString() : "")}
                                                    value={this.props.product.name}
                                                    validationFunction={
                                                        (value: string | number) => FormFields.hasValueValidation(
                                                            value.toString(),
                                                            "Name provided",
                                                        )}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Product number"
                                                    required
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("productNumber", value ? value.toString() : "")}
                                                    value={this.props.product.productNumber}
                                                    max={20}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "Product number provided", 20)}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Coverage code (PAH)"
                                                    required
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("coverageCodeForPah", value ? value.toString() : "")}
                                                    value={this.props.product.coverageCodeForPah}
                                                    max={15}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "Coverage code (PAH) provided", 15)}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("proxy", value
                                                            ? { id: value } as RefObject : undefined)}
                                                    value={this.props.product.proxy && this.props.product.proxy.id
                                                        ? this.props.product.proxy.id : undefined}
                                                    label="Proxy"
                                                    required
                                                    placeholder="Make a choice"
                                                    options={
                                                        this.props.formHelpers.proxiesResults.content.map(item => ({
                                                            label: item.name,
                                                            value: Number(item.id),
                                                        }))}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasValueValidation(value.toString(),
                                                                "Proxy selected")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="First part VWFS"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("firstPartVWFS", value ? value.toString() : "")}
                                                    value={this.props.product.firstPartVWFS}
                                                    max={255}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "First part VWFS provided", 255)}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Second part VWFS"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("secondPartVWFS", value ? value.toString() : "")}
                                                    value={this.props.product.secondPartVWFS}
                                                    max={255}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "Second part VWFS provided", 255)}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="SOA name"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("soaName", value ? value.toString() : "")}
                                                    value={this.props.product.soaName}
                                                    max={255}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "SOA name provided", 255)}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Coverage level"
                                                    placeholder="Make a choice"
                                                    options={Object.keys(CoverageLevel).map(coverage => ({
                                                        label: CoverageLevelDisplay[coverage],
                                                        value: coverage,
                                                    }))}
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("coverageLevel",
                                                            value ? value.toString() as CoverageLevel : undefined)}
                                                    value={this.props.product.coverageLevel}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasValueValidation(value.toString(),
                                                                "Coverage level provided")}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Container
                                                theme={{
                                                    padding: {
                                                        "": { b: 3 },
                                                        "sm": { b: 4 },
                                                    },
                                                }}
                                            >
                                                <Checkbox
                                                    checked={this.props.product.forUsedVehicles ?? false}
                                                    onChange={(value: boolean | undefined) => this.onChange(
                                                        "forUsedVehicles",
                                                        !!value ? true : false)}
                                                    label={"For used vehicle"}
                                                />
                                            </Container>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 12 }}
                                            >
                                                <FormFields.FormTextArea
                                                    label={"Product description"}
                                                    onChange={(value?: string | number) => this.onChange("description",
                                                        value ? value.toString() : undefined)}
                                                    value={this.props.product.description}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "Product description provided", 255)}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 12 }}
                                            >
                                                <FormFields.FormTextArea
                                                    label={"Terms and conditions"}
                                                    onChange={(value?: string | number) => this.onChange("termsAndConditions",
                                                        value ? value.toString() : "")}
                                                    value={this.props.product.termsAndConditions}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasNotExceedLengthValidation(value,
                                                                "Terms and conditions provided", 300)}
                                                />
                                            </Grid>
                                        </Div>
                                        <Div className="scl-b-row">
                                            <Container
                                                theme={{
                                                    padding: {
                                                        "": { b: 3 },
                                                        "sm": { b: 4 },
                                                    },
                                                }}
                                            >
                                                <Label>
                                                    {"Brands"}
                                                    <span className={"scl-a-label__field--as-required"}>&nbsp;*</span>
                                                </Label>
                                                {
                                                    this.props.formHelpers.brandResults.content.map((row) => (
                                                        <Div key={`${row.id}-${this.props.product.id ?? ""}-brands`}>
                                                            <Checkbox
                                                                checked={
                                                                    this.props.product.brands
                                                                        ?.some(brand => brand.id === row.id)}
                                                                onChange={(value?: boolean) => this.onChange(
                                                                    "brands",
                                                                    !!value
                                                                        ? [
                                                                            ...this.props.product.brands || [],
                                                                            {
                                                                                id: row.id,
                                                                            },
                                                                        ]
                                                                        : this.props.product.brands
                                                                            ? this.props.product.brands
                                                                                .filter((rowFiltered) => rowFiltered.id !== row.id) : undefined,
                                                                )}
                                                                label={row.name}
                                                            />
                                                        </Div>
                                                    ))
                                                }
                                            </Container>
                                        </Div>
                                        <Required />
                                    </Container>
                                </Div>
                                <Menu
                                    orientation={MenuOrientation.HORIZONTAL}
                                    items={[{
                                        children: "Product Tariffs",
                                        active: true,
                                    }]}
                                />
                                {this.props.product &&
                                    <CreateTariff
                                        tariffs={this.props.product.productTariffs}
                                        edit={this.props.edit}
                                    />
                                }
                                <FormBar
                                    buttonLabel={this.props.edit ? "Update" : "Create"}
                                    title={this.props.edit ? "Edit product" : "Create product"}
                                    isValid={!!this.props.canSubmitEWForm ? this.props.canSubmitEWForm : undefined}
                                />
                            </Form>
                        </>
                        )}
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof ProductRequest>(key: K, value: ProductRequest[K]) => {
        this.props.updateProduct({
            [key]: value,
        });
    };

    private addProduct() {
        this.props.addProduct(this.props.product);
    }

    private editProduct() {
        this.props.editProduct(this.props.product);
    }

    private initFilters() {
        this.clearFilters();
        this.props.setFilter({ partnerType: PartnerType.INSURER });
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof PartnerFilters>);

        this.props.clearFilters(filters);
    };
}

/**
 *
 */
export const CreateProduct = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateProductComp);
