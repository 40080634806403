import { RequestObject } from "../RemoteTypes";

export enum MessageStatus {
    RECEIVED = "RECEIVED",
    UNPARSEABLE = "UNPARSEABLE",
    PROCESSED = "PROCESSED",
    CREATED = "CREATED",
}

export enum MessageDirection {
    IN = "IN",
    OUT = "OUT",
}

export enum MessageType {
    POLICY = "POLICY",
    POLICY_RESPONSE = "POLICY_RESPONSE",
    CARPORT_FILE = "CARPORT_FILE",
    PAYMENT_FILE = "PAYMENT_FILE",
    CLAIM_DAMAGE_BOOKING = "CLAIM_DAMAGE_BOOKING",
    CLAIM_DAMAGE_REPORT = "CLAIM_DAMAGE_REPORT",
    CLAIM_STAKEHOLDER = "CLAIM_STAKEHOLDER",
    STATEMENT = "STATEMENT",
    INCASSO = "INCASSO",
    EXCASSO = "EXCASSO",
    COLLECTIVE_INVOICE = "COLLECTIVE_INVOICE",
    INDIVIDUAL_INVOICE = "INDIVIDUAL_INVOICE",
    EXACT = "EXACT",
    NVGA = "NVGA",
    DGP = "DGP",
    OIC = "OIC",
    OUTSTANDING_ITEMS = "OUTSTANDING_ITEMS",
    CLAIM_HEADER = "CLAIM_HEADER",
    CLAIM_DETAIL = "CLAIM_DETAIL",
    INSURANCE_OVERVIEW = "INSURANCE_OVERVIEW",
    PREMIUM_CLAIM_OVERVIEW = "PREMIUM_CLAIM_OVERVIEW",
    CLAIM_HEADER_MESSAGE_REPORT = "CLAIM_HEADER_MESSAGE_REPORT",
    POLICY_MESSAGE_REPORT = "POLICY_MESSAGE_REPORT",
    CLAIM_DETAIL_MESSAGE_REPORT = "CLAIM_DETAIL_MESSAGE_REPORT",
    POLICY_PREMIUM_MESSAGE_REPORT = "POLICY_PREMIUM_MESSAGE_REPORT",
    PORTFOLIO_OVERVIEW_REPORT = "PORTFOLIO_OVERVIEW_REPORT",
    SOA_PREMIUM_BORDERAUX_REPORT = "SOA_PREMIUM_BORDERAUX_REPORT",
    JOINT_COLLECTIVE_INVOICE = "JOINT_COLLECTIVE_INVOICE",
    UNKNOWN = "UNKNOWN",
    LOSS_RATIO_REPORT = "LOSS_RATIO_REPORT",
    PREMIUM_CLAIM_PER_YEAR_REPORT = "PREMIUM_CLAIM_PER_YEAR_REPORT",
    LOSS_RATIO_REPORT_EXPIRED = "LOSS_RATIO_REPORT_EXPIRED",
    PRODUCTION_REPORT = "PRODUCTION_REPORT",
}

export interface Message {
    id: number;
    createdAt: string;
    objectType: string;
    messageType: MessageType;
    messageName: string;
    filePath: string;
    messageStatus: MessageStatus;
    messageDirection: MessageDirection;
    noOfUnProcElements: number;
    preassignedURL: string;
}

export interface RequestMessage extends RequestObject {
    content: Message;
}
