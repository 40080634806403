import * as React from "react";
import { Payments as PaymentType } from "../../../../../store/PartnerPayments/Types";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { PageTabs } from "../../../../../store/AppDisplays";
import { CreditDebitIndicatorTypeDisplay } from "../../../../../store/Statements/Displays";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
/**
 *
 */
interface OwnProps {
    results: PaymentType;
    currentTab?: PageTabs;
    asAuditTab?: boolean;
}

/**
 *
 */
type Props = OwnProps;

/**
 *
 * @param props
 */
export class Results
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
            </React.Fragment>
        );
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.paymentNumber || "",
                row.paymentDate ? (
                    <Moment
                        date={new Date(row.paymentDate)}
                        format={"DD-MM-YYYY"}
                        key={`payment-date${index}`}
                    />
                ) : "",
                row.invoiceNumber || "",
                row.invoiceDate ? (
                    <Moment
                        date={new Date(row.invoiceDate)}
                        format={"DD-MM-YYYY"}
                        key={`invoice-date${index}`}
                    />
                ) : "",
                row.bankAccount || "",
                row.statementLineId || "",
                row.mutationType === CreditDebitIndicator.DBIT
                    ? CreditDebitIndicatorTypeDisplay.DBIT : CreditDebitIndicatorTypeDisplay.CRDT,
                row.incasso ? "Incasso" : row.excasso ? "Excasso" : "",
                (
                    <h6 key={`${index}-h6`}>
                        {row.amount ? <Currency amount={row.amount} /> : "n.a."}
                    </h6>
                ),
                <ButtonsWrapper
                    key={`navlink-${row.id}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.asAuditTab ? (
                            <NavLink to={`/audit/payments/${row.id}`}>
                                <Button
                                    icon={IconNames.ARROW_RIGHT}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                />
                            </NavLink>
                        ) : <></>}</ButtonsWrapper>,
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Payment number",
                },
                {
                    label: "Payment date",
                },
                {
                    label: "Invoice number",
                },
                {
                    label: "Invoice date",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Statement line Id",
                },
                {
                    label: "Mutation type",
                },
                {
                    label: "Incasso/Excasso",
                },
                {
                    label: "Amount",
                    alignRight: true,
                },
                this.props.asAuditTab ?
                    {
                        label: "Actions",
                    } : {
                        label: "",
                    },
            ],
            rows: this.getProcessedRows(),
        };
    }
}
