import { Invoices, InvoiceFilters } from "./Types";
import { AxiosResponse } from "axios";

export const mapInvoiceRequests =
(resp: AxiosResponse): Invoices => resp.data as Invoices;

/**
 *
 */
export const InvoiceFiltersToParams = (f: InvoiceFilters) => ({
    ...f,
});
