import * as React from "react";
import { CreateManual } from "../../00-blocks/Tariffs/Tabs/CreateManual";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

interface Props {
    tariffId: string;
}

export const EditManual: React.FC<Props> = (props) => (
    <Page>
        <CreateManual edit tariffId={props.tariffId} />
    </Page>
);
