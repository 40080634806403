import React from "react";
import { ThemePalette, ThemeProviderProps } from "../../../../../../theme/_Types";
import { Button } from "../../../../05-atoms/Button";
import * as I18n from "../../../../../../utils/translations/I18n";

export const LogoutButton: React.FC<{
    allDevices: boolean; signOut: (b?: boolean) => void;
}> = props => {
    const theme: Partial<ThemeProviderProps> = props.allDevices
        ? {palette: ThemePalette.CONTRAST_TERTIARY }
        : {palette: ThemePalette.BRAND_ACCENT};

    const label = props.allDevices
        ? I18n.getText("mary.09-views.03-modals.accountsettings.tabs.logoutbutton-all-devices-label",
            "Logout from all devices")
        : I18n.getText("mary.09-views.03-modals.accountsettings.tabs.logoutbutton-label",
            "Logout");
    return (
        <Button
            theme={theme}
            link={{ onClick: () => props.signOut(props.allDevices) }}
        >
            {label}
        </Button>
    );
};
