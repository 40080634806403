import { AxiosResponse } from "axios";
import { BookingPeriods, BookingPeriodFilters } from "./Types";

export const mapBookingPeriodRequests =
    (resp: AxiosResponse): BookingPeriods => resp.data as BookingPeriods;

/**
 *
 */
export const BookingPeriodFiltersToParams = (f: BookingPeriodFilters) => ({
    ...f,
});
