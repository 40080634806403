import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Filter } from "../Filter";
import * as StatementTypes from "../../../../../store/Statements/Types";
import { Results } from "../Results";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Summary } from "../../00-blocks/Summary";
import Moment from "react-moment";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { AccountDetails } from "../../../../../store/Accounts/Types";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemePalette, ThemePaletteState } from "../../../../../theme/_Types";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";

interface Params {
    accountId: string;
}

interface DispatchProps {
    loadDetails: (id: string) => void;
    clearDetails: () => void;
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    loading: boolean;
    results: StatementTypes.Statements;
    params: Params;
    details: AccountDetails;
    user?: UserInfo;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.STATEMENT_RESULTS),
    loading: isRemoteLoading(s, RemoteScope.ACCOUNT_DETAILS),
    results: s.prop("remote").prop(RemoteScope.STATEMENT_RESULTS) as StatementTypes.Statements,
    params: p.match.params as Params,
    details: s.prop("remote").prop(RemoteScope.ACCOUNT_DETAILS) as AccountDetails,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadDetails: (id: string) => dispatch(remoteTrigger(RemoteScope.ACCOUNT_DETAILS, {
        id: id,
    })),
    clearDetails: () => (
        dispatch(remoteClearResponse(RemoteScope.ACCOUNT_DETAILS)),
        dispatch(remoteClearError(RemoteScope.ACCOUNT_DETAILS))
    ),
});

/**
 *
 * @param props
 */
export class DetailComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadDetails(this.props.params.accountId);
    }

    public componentWillUnmount() {
        this.props.clearDetails();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.ACCOUNTS].title}
                                goBack={PageDisplay[Pages.ACCOUNTS].path}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_DEBTOR &&
                            this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <NavLink
                                        to={"/accounts/" + this.props.params.accountId + "/statements/upload"}
                                        exact
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            className="scl-a-btn--big"
                                        >
                                            {SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_UPLOAD].title}
                                        &nbsp;
                                        &nbsp;
                                            <Icon name={IconNames.ARROW_RIGHT} />
                                        </Button>
                                    </NavLink>
                                </Div>
                            </Grid>}
                    </Div>
                    {
                        this.props.loading ||
                            (!this.props.details || (this.props.details && !this.props.details.content)) ?
                            (
                                <LoadingData loading={!!this.props.loading} />
                            ) :
                            (
                                <>
                                    <Summary
                                        tableColumnData={[
                                            {
                                                label: "Name",
                                            },
                                            {
                                                label: "IBAN",
                                            },
                                            {
                                                label: "GL Account code",
                                            },
                                            {
                                                label: "All Booked",
                                            },
                                            {
                                                label: "Mutation date",
                                            },
                                            {
                                                label: "Balance",
                                                alignRight: true,
                                            },
                                        ]}
                                        tablerowData={[
                                            {
                                                id: 1,
                                                data: [
                                                    this.props.details?.content.name,
                                                    this.props.details?.content.iban,
                                                    this.props.details?.content?.generalLedger?.accountCode ?? "",
                                                    (this.props.details?.content.allBooked
                                                        ? <Badge theme={{ paletteState: ThemePaletteState.SUCCESS }}>
                                                            Yes
                                                        </Badge>
                                                        : <Badge theme={{ paletteState: ThemePaletteState.DANGER }}>
                                                            No
                                                        </Badge>
                                                    ),
                                                    this.props.details.content.mutationDate ?
                                                        (
                                                            <Moment
                                                                date={new Date(this.props.details.content.mutationDate)}
                                                                format={"DD-MM-YYYY"}
                                                                key={"mutation-date"}
                                                            />
                                                        ) : "",
                                                    (<h6 key={"h6-1"}>
                                                        {this.props.details &&
                                                            <Currency amount={this.props.details.content.balance} />
                                                        }
                                                    </h6>),
                                                ],
                                            }]}
                                    />
                                    <Filter
                                        loadingFilters={this.props.loadingFilters}
                                        loadingResults={this.props.loadingResults}
                                        currentPage={SubPages.ACCOUNTS_DETAIL}
                                        defaultFilters={{ accountId: this.props.details.content.id.toString() }}
                                    />
                                    <Results
                                        results={this.props.results}
                                        loadingResults={this.props.loadingResults}
                                        currentPage={SubPages.ACCOUNTS_DETAIL}
                                        accountId={this.props.params.accountId}
                                    />
                                </>
                            )
                    }
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Detail = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <DetailComp {...props}/>);
