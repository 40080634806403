/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../../store/AppDisplays";
import { Filter } from "../../../../02-pages/Filter";
import * as InvoiceTypes from "../../../../../../../store/CollectiveInvoice/Types";
import { Results } from "../../../../02-pages/Results";
import { Div } from "../../../../../../../components/core/03-base/Div";
import { ReduxState, isRemoteLoading } from "../../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { CreditDebitIndicator } from "../../../../../../../store/Statements/Types";

interface OwnProps {
    statementLineId: string;
    creditDebitIndicator: CreditDebitIndicator;
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: InvoiceTypes.CollectiveInvoices;
}

type Props = OwnProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => {
    return {
        loadingFilters: false,
        loadingResults: isRemoteLoading(s, RemoteScope.COLLECTIVE_INVOICE_RESULTS),
        results:
            s.prop("remote").prop(RemoteScope.COLLECTIVE_INVOICE_RESULTS) as InvoiceTypes.CollectiveInvoices,
    };
};

/**
 *
 * @param props
 */
export class InvoicesComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES}
                    defaultFilters={{
                        creditDebitIndicator: this.props.creditDebitIndicator,
                        invoiceStatus: `${InvoiceTypes.CollectiveInvoiceStatus.UNPAID},${InvoiceTypes.CollectiveInvoiceStatus.PARTIALLY_PAID},${InvoiceTypes.CollectiveInvoiceStatus.REVERSED}`,
                    }}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES}
                    statementLineId={this.props.statementLineId}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Invoices = connect(
    mapStateToProps,
    undefined,
)((props: Props) => <InvoicesComp {...props}/>);
