/* eslint-disable max-len */
import { OrderedSet } from "immutable";
import { General as PoliciesAuditGeneral } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Policies/Tabs/General";
import { Vehicle as PoliciesAuditVehicle } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Policies/Tabs/Vehicle";
import { Product as PoliciesAuditProduct } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Policies/Tabs/Product";
import { Premium as PoliciesAuditPremium } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Policies/Tabs/Premium";
import { General as ProductsAuditGeneral } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Products/Tabs/General";
import { General as PartnersAuditGeneral } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Partners/Tabs/General";
import { AuditTariffs as ProductsAuditTariffs } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Products/Tabs/Tariffs";
import { Results as BookedStatementsResults } from "../App/components/09-views/02-pages/accounts/statements/detail/BookedStatementsResults";
import { Upload as StatementsUpload } from "../App/components/09-views/02-pages/accounts/statements/Upload";
import { Detail as ClaimsDetail } from "../App/components/09-views/02-pages/claims/Detail";
import { Generate as CollectiveInvoiceGeneration } from "../App/components/09-views/02-pages/collective-invoice/Generate";
import { Upload as FilesUpload } from "../App/components/09-views/02-pages/Files/Upload";
import { ManualBookings } from "../App/components/09-views/02-pages/ManualBookings";
import { Claims as BookClaims } from "../App/components/09-views/00-blocks/Accounts/Statements/Book/Claims";
import { Invoices as BookInvoices } from "../App/components/09-views/00-blocks/Accounts/Statements/Book/CollectiveInvoices";
import { Excasso as BookExcasso } from "../App/components/09-views/00-blocks/Accounts/Statements/Book/Excasso";
import { GeneralLedger as BookLedger } from "../App/components/09-views/00-blocks/Accounts/Statements/Book/GeneralLedger";
import { Incasso as BookIncasso } from "../App/components/09-views/00-blocks/Accounts/Statements/Book/Incasso";
import { Partners as BookPartners } from "../App/components/09-views/00-blocks/Accounts/Statements/Book/Partners";
import { StatementLines as StatementsStatementLines } from "../App/components/09-views/00-blocks/Accounts/Statements/tabs/StatementLines";
import { Bookings as ClaimsBookings } from "../App/components/09-views/00-blocks/Claims/Tabs/Bookings";
import { General as ClaimsGeneral } from "../App/components/09-views/00-blocks/Claims/Tabs/General";
import { Claims as ManualBookClaims } from "../App/components/09-views/00-blocks/ManualBookings/Create/Claims";
import { CollectiveInvoices as ManualBookCollectiveInvoices } from "../App/components/09-views/00-blocks/ManualBookings/Create/CollectiveInvoices";
import { GeneralLedger as ManualBookGeneralLedger } from "../App/components/09-views/00-blocks/ManualBookings/Create/GeneralLedger";
import { Invoices as ManualBookInvoices } from "../App/components/09-views/00-blocks/ManualBookings/Create/Invoices";
import { PartnerInvoices as ManualBookPartnerInvoices } from "../App/components/09-views/00-blocks/ManualBookings/Create/PartnerInvoices";
import { Partners as ManualBookPartners } from "../App/components/09-views/00-blocks/ManualBookings/Create/Partners";
import { Detail as StatementsDetail } from "../App/components/09-views/02-pages/accounts/statements/Detail";
import { Detail as PartnerDetail } from "../App/components/09-views/02-pages/partners/Detail";
import { Detail as PolicyDetail } from "../App/components/09-views/02-pages/policies/Detail";
import { Detail as ProductDetail } from "../App/components/09-views/02-pages/Product/Detail";
import {
    Invoices as PartnerCollectiveInvoices,
} from "../App/components/09-views/00-blocks/Partners/Tabs/CollectiveInvoice";
import { General as PartnerGeneral } from "../App/components/09-views/00-blocks/Partners/Tabs/General";
import {
    Invoices as PartnerInvoices,
} from "../App/components/09-views/00-blocks/Partners/Tabs/Invoice";
import { Mandates as PartnerMandates } from "../App/components/09-views/00-blocks/Partners/Tabs/Mandates";
import { Payments as PartnerPayments } from "../App/components/09-views/00-blocks/Partners/Tabs/Payments";
import { AuditTrail as PolicyAuditTrails } from "../App/components/09-views/00-blocks/Policies/Tabs/AuditTrail";
import { Claims as PolicyClaims } from "../App/components/09-views/00-blocks/Policies/Tabs/Claims";
import { General as PolicyGeneral } from "../App/components/09-views/00-blocks/Policies/Tabs/General";
import { Invoices as PolicyInvoices } from "../App/components/09-views/00-blocks/Policies/Tabs/Invoice";
import { Memos as PolicyMemo } from "../App/components/09-views/00-blocks/Policies/Tabs/Memo";
import { Premium as PolicyPremium } from "../App/components/09-views/00-blocks/Policies/Tabs/Premium";
import { Product as PolicyProduct } from "../App/components/09-views/00-blocks/Policies/Tabs/Product";
import { Vehicle as PolicyVehicle } from "../App/components/09-views/00-blocks/Policies/Tabs/Vehicle";
import { General as ProductGeneral } from "../App/components/09-views/00-blocks/Product/Tabs/General";
import { Tariffs as ProductTariffs } from "../App/components/09-views/00-blocks/Product/Tabs/Tariffs";
import { Detail as AccountsDetail } from "../App/components/09-views/02-pages/accounts/Detail";
import { BookingPeriodAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/BookingPeriod/BookingPeriod";
import { CIAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/CI/CI";
import { FreebookingAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Freebooking/Statements";
import { GLAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/GL/GL";
import { InvoiceAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Invoice/Invoice";
import { PartnersAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Partners/Partners";
import { PaymentsAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Payments/Payments";
import { PoliciesAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Policies/Policies";
import { ProductsAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Products/Products";
import { StatementAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Statements/Statements";
import { TariffsAuditTrail } from "../App/components/09-views/00-blocks/AuditTrails/Tabs/Tariffs/Tariffs";
import { ClaimErrors } from "../App/components/09-views/00-blocks/SystemLogs/Tabs/ClaimErrors";
import { CreateAuto } from "../App/components/09-views/00-blocks/Tariffs/Tabs/CreateAuto";
import { CreateManual } from "../App/components/09-views/00-blocks/Tariffs/Tabs/CreateManual";
import { Accounts } from "../App/components/09-views/02-pages/Accounts";
import { CreateAccount } from "../App/components/09-views/02-pages/accounts/Create";
import { Book } from "../App/components/09-views/02-pages/accounts/statements/detail/Book";
import { SummaryTab } from "../App/components/09-views/02-pages/accounts/statements/detail/Summary";
import { AuditTrails } from "../App/components/09-views/02-pages/AuditTrails";
import { BookingPeriodAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/BookingPeriodAuditTrailDetail";
import { CIAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/CIAuditTrailDetail";
import { FBAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/FreebookingAuditTrailDetail";
import { GLAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/GLAuditTrailDetail";
import { InvoiceAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/InvoiceAuditTrailDetail";
import { PartnerAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/PartnerAuditTrailDetail";
import { PaymentAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/PaymentAuditTrailDetail";
import { PolicyAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/PolicyAuditTrailDetail";
import { ProductAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/ProductAuditTrailDetail";
import { StatementAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/StatementAuditTrailDetail";
import { TariffAuditTrailDetail } from "../App/components/09-views/02-pages/AuditTrails/Tabs/TariffAuditTrailDetail";
import { BookingPeriod } from "../App/components/09-views/02-pages/BookingPeriod";
import { Brands } from "../App/components/09-views/02-pages/Brands";
import { CreateBrand } from "../App/components/09-views/02-pages/Brands/CreateBrand";
import { EditBrand } from "../App/components/09-views/02-pages/Brands/EditBrand";
import { BusinessRuleViolations } from "../App/components/09-views/02-pages/BusinessRuleViolations";
import { Claims } from "../App/components/09-views/02-pages/Claims";
import { CollectiveInvoices } from "../App/components/09-views/02-pages/CollectiveInvoices";
import { Files } from "../App/components/09-views/02-pages/Files";
import { GeneralLedger } from "../App/components/09-views/02-pages/GeneralLedger";
import { CreateGL } from "../App/components/09-views/02-pages/GeneralLedger/CreateGL";
import { EditGL } from "../App/components/09-views/02-pages/GeneralLedger/EditGL";
import { Home } from "../App/components/09-views/02-pages/Home";
import { IndividualInvoices } from "../App/components/09-views/02-pages/IndividualInvoices";
import { Manage } from "../App/components/09-views/02-pages/Manage";
import { CreateManualBooking } from "../App/components/09-views/02-pages/ManualBookings/Create";
import { Models } from "../App/components/09-views/02-pages/Models";
import { CreateModel } from "../App/components/09-views/02-pages/Models/CreateModel";
import { EditModel } from "../App/components/09-views/02-pages/Models/EditModel";
import { Partners } from "../App/components/09-views/02-pages/Partners";
import { CreatePartner } from "../App/components/09-views/02-pages/partners/CreatePartner";
import { EditPartner } from "../App/components/09-views/02-pages/partners/EditPartner";
import { Policies } from "../App/components/09-views/02-pages/Policies";
import { CreatePolicy } from "../App/components/09-views/02-pages/policies/CreatePolicy";
import { EditPolicy } from "../App/components/09-views/02-pages/policies/EditPolicy";
import { Product } from "../App/components/09-views/02-pages/Product";
import { AddProductTariff } from "../App/components/09-views/02-pages/Product/AddProductTariff";
import { CreateProduct } from "../App/components/09-views/02-pages/Product/CreateProduct";
import { EditProduct } from "../App/components/09-views/02-pages/Product/EditProduct";
import { Reports } from "../App/components/09-views/02-pages/Reports";
import { SystemLogs } from "../App/components/09-views/02-pages/SystemLogs";
import { Tariffs } from "../App/components/09-views/02-pages/Tariffs";
import { Create } from "../App/components/09-views/02-pages/Tariffs/Create";
import { EditTariff } from "../App/components/09-views/02-pages/Tariffs/Edit";
import { EditAuto } from "../App/components/09-views/02-pages/Tariffs/EditAuto";
import { EditManual } from "../App/components/09-views/02-pages/Tariffs/EditManual";
import { General } from "../components/core/09-views/03-modals/AccountSettings/Tabs/General";
import { UserRoles, PaidStatus } from "./AppTypes";
import { ReportFormScope } from "./Reports/Types";
import { ChangePassword } from "../components/core/09-views/03-modals/AccountSettings/Tabs/ChangePassword";


/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */
export const UserRolesDisplays: DisplayOf<UserRoles> = {
    [UserRoles.EW_ADMIN]: "Insurance Administrator",
    [UserRoles.EW_ACCOUNTING]: "Accounting Employee",
    [UserRoles.EW_DEBTOR]: "Debtor Management",
    [UserRoles.EW_ADMIN_READONLY]: "System Administrator",
    [UserRoles.NONE]: "Invalid role",
};

/**
 *
 */
export const ReportFormDisplays: DisplayOf<ReportFormScope> = {
    [ReportFormScope.NVGA]: "NVGA",
    [ReportFormScope.INSURANCE_OVERVIEW]: "Insurance overview",
    [ReportFormScope.OUTSTANDING_ITEMS]: "Outstanding items",
};

export const PaidStatusTypeDisplay: Readonly<Record<PaidStatus, string>> = {
    [PaidStatus.PAID]: "Paid",
    [PaidStatus.UNPAID]: "Unpaid",
};

/**
 *
 */
export enum Pages {
    HOME = "HOME",
    POLICIES = "POLICIES",
    CLAIMS = "CLAIMS",
    PRODUCT = "PRODUCT",
    COLLECTIVEINVOICES = "COLLECTIVEINVOICES",
    INDIVIDUALINVOICES = "INDIVIDUALINVOICES",
    REPORTS = "REPORTS",
    ACCOUNTS = "ACCOUNTS",
    PARTNERS = "PARTNERS",
    FILES = "FILES",
    SYSTEM_LOGS = "SYSTEM_LOGS",
    MANUAL_BOOKING = "MANUAL_BOOKING",
    TARIFFS = "TARIFFS",
    BOOKING_PERIOD = "BOOKING_PERIOD",
    GENERAL_LEDGER = "GENERAL_LEDGER",
    MANAGE = "MANAGE",
    AUDIT_TRAILS = "AUDIT_TRAILS",
}

/**
 *
 */
export enum SubPages {
    CLAIMS_DETAIL = "CLAIMS_DETAIL",
    COLLECTIVE_INVOICE_GENERATION = "COLLECTIVE_INVOICE_GENERATION",
    ACCOUNTS_CREATE = "ACCOUNTS_CREATE",
    ACCOUNTS_DETAIL = "ACCOUNTS_DETAIL",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL",
    ACCOUNTS_DETAIL_STATEMENTS_UPLOAD = "ACCOUNTS_DETAIL_STATEMENTS_UPLOAD",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK",
    POLICY_DETAIL = "POLICY_DETAIL",
    POLICY_CREATE = "POLICY_CREATE",
    POLICY_EDIT = "POLICY_EDIT",
    PARTNER_DETAIL = "PARTNER_DETAIL",
    PARTNER_CREATE = "PARTNER_CREATE",
    PARTNER_EDIT = "PARTNER_EDIT",
    FILES_UPLOAD = "FILES_UPLOAD",
    PRODUCT_DETAIL = "PRODUCT_DETAIL",
    PRODUCT_CREATE = "PRODUCT_CREATE",
    PRODUCT_EDIT = "PRODUCT_EDIT",
    PRODUCT_ACTIVE_ADD_PRODUCT_TARIFF = "PRODUCT_ACTIVE_ADD_PRODUCT_TARIFF",
    MANUAL_BOOKING_CREATE = "MANUAL_BOOKING_CREATE",
    TARIFF_CREATE = "TARIFF_CREATE",
    TARIFF_EDIT = "TARIFF_EDIT",
    GENERAL_LEDGER_CREATE = "GENERAL_LEDGER_CREATE",
    GENERAL_LEDGER_EDIT = "GENERAL_LEDGER_EDIT",
    MANAGE_BRANDS = "MANAGE_BRANDS",
    MANAGE_MODELS = "MANAGE_MODELS",
    MANAGE_BUSINESS_RULE_VIOLATIONS = "MANAGE_BUSINESS_RULE_VIOLATIONS",
    BRAND_CREATE = "BRAND_CREATE",
    BRAND_EDIT = "BRAND_EDIT",
    MODEL_CREATE = "MODEL_CREATE",
    MODEL_EDIT = "MODEL_EDIT",
    AUDIT_TRAIL_POLICIES = "AUDIT_TRAIL_POLICIES",
    AUDIT_TRAIL_POLICY_DETAIL = "AUDIT_TRAIL_POLICY_DETAIL",
    AUDIT_TRAIL_PRODUCTS = "AUDIT_TRAIL_PRODUCTS",
    AUDIT_TRAIL_PRODUCT_DETAIL = "AUDIT_TRAIL_PRODUCT_DETAIL",
    AUDIT_TRAIL_PARTNERS = "AUDIT_TRAIL_PARTNERS",
    AUDIT_TRAIL_PARTNER_DETAIL = "AUDIT_TRAIL_PARTNER_DETAIL",
    AUDIT_TRAIL_TARIFFS = "AUDIT_TRAIL_TARIFFS",
    AUDIT_TRAIL_TARIFFS_DETAIL = "AUDIT_TRAIL_TARIFFS_DETAIL",
    AUDIT_TRAIL_PAYMENTS = "AUDIT_TRAIL_PAYMENTS",
    AUDIT_TRAIL_PAYMENT_DETAIL = "AUDIT_TRAIL_PAYMENTS_DETAIL",
    AUDIT_TRAIL_GL = "AUDIT_TRAIL_GL",
    AUDIT_TRAIL_GL_DETAIL = "AUDIT_TRAIL_GL_DETAIL",
    AUDIT_TRAIL_BOOKING_PERIOD = "AUDIT_TRAIL_BOOKING_PERIOD",
    AUDIT_TRAIL_BOOKING_PERIOD_DETAIL = "AUDIT_TRAIL_BOOKING_PERIOD_DETAIL",
    AUDIT_TRAIL_CI = "AUDIT_TRAIL_CI",
    AUDIT_TRAIL_CI_DETAIL = "AUDIT_TRAIL_CI_DETAIL",
    AUDIT_TRAIL_INVOICE = "AUDIT_TRAIL_INVOICE",
    AUDIT_TRAIL_INVOICE_DETAIL = "AUDIT_TRAIL_INVOICE_DETAIL",
    AUDIT_TRAIL_STATEMENT = "AUDIT_TRAIL_STATEMENT",
    AUDIT_TRAIL_STATEMENT_DETAIL = "AUDIT_TRAIL_STATEMENT_DETAIL",
    AUDIT_TRAIL_FREEBOOKING = "AUDIT_TRAIL_FREEBOOKING",
    AUDIT_TRAIL_FREEBOOKING_DETAIL = "AUDIT_TRAIL_FREEBOOKING_DETAIL",
}

/**
 *
 */
export enum PageTabs {
    CLAIMS_GENERAL = "CLAIMS_GENERAL",
    CLAIMS_BOOKINGS = "CLAIMS_BOOKINGS",
    CLAIMS_ERRORS = "CLAIMS_ERRORS",
    STATEMENTS_STATEMENT_LINES = "STATEMENTS_STATEMENT_LINES",
    PARTNER_GENERAL = "PARTNER_GENERAL",
    PARTNER_COLLECTIVEINVOICE = "PARTNER_COLLECTIVEINVOICE",
    PARTNER_INVOICE = "PARTNER_INVOICE",
    PARTNER_PAYMENTS = "PARTNER_PAYMENTS",
    PARTNER_MANDATES = "PARTNER_MANDATES",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_SUMMARY = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_SUMMARY",
    ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOKED_ITEMS = "ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOKED_ITEMS",
    POLICIES_GENERAL = "POLICIES_GENERAL",
    POLICIES_VEHICLE = "POLICIES_VEHICLE",
    POLICIES_PRODUCT = "POLICIES_PRODUCT",
    POLICIES_PREMIUM = "POLICIES_PREMIUM",
    POLICIES_CLAIMS = "POLICIES_CLAIMS",
    POLICIES_INVOICES = "POLICIES_INVOICES",
    POLICIES_MEMO = "POLICIES_MEMO",
    POLICIES_AUDIT_TRAILS = "POLICIES_AUDIT_TRAILS",
    PRODUCT_GENERAL = "PRODUCT_GENERAL",
    PRODUCT_TARIFFS = "PRODUCT_TARIFFS",
    MANUAL_BOOKING_CREATE_BOOK_PARTNER = "MANUAL_BOOKING_CREATE_BOOK_PARTNER",
    MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES = "MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES",
    MANUAL_BOOKING_CREATE_BOOK_CLAIMS = "MANUAL_BOOKING_CREATE_BOOK_CLAIMS",
    MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER = "MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER",
    MANUAL_BOOKING_CREATE_BOOK_INVOICES = "MANUAL_BOOKING_CREATE_BOOK_INVOICES",
    MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES = "MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES",
    TARIFF_CREATE_MANUAL = "TARIFF_CREATE_MANUAL",
    TARIFF_CREATE_AUTOMATIC = "TARIFF_CREATE_AUTOMATIC",
    TARIFF_EDIT_MANUAL = "TARIFF_EDIT_MANUAL",
    TARIFF_EDIT_AUTO = "TARIFF_EDIT_AUTO",
    HOME_GENERAL = "HOME_GENERAL",
    HOME_CHANGE_PASSWORD = "HOME_CHANGE_PASSWORD",
    AUDIT_POLICIES_GENERAL = "AUDIT_POLICIES_GENERAL",
    AUDIT_POLICIES_VEHICLE = "AUDIT_POLICIES_VEHICLE",
    AUDIT_POLICIES_PRODUCT = "AUDIT_POLICIES_PRODUCT",
    AUDIT_POLICIES_PREMIUM = "AUDIT_POLICIES_PREMIUM",
    AUDIT_PRODUCTS_GENERAL = "AUDIT_PRODUCTS_GENERAL",
    AUDIT_PRODUCTS_PRODUCTTARIFFS = "AUDIT_PRODUCTS_PRODUCTTARIFFS",
    AUDIT_PARTNERS_GENERAL = "AUDIT_PARTNERS_GENERAL",
}

interface Tab {
    title: string;
    description: string;
    component?: React.FunctionComponent | React.ComponentState;
}

interface Page {
    title: string;
    description: string;
    component: React.FunctionComponent | React.ComponentState;
    path: string;
    subpages: OrderedSet<SubPages>;
    tabs: OrderedSet<PageTabs>;
    roles: UserRoles[];
    beta?: boolean;
}

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
    [Pages.HOME]: {
        title: "Dashboard",
        description: "You can use the dashboard to manage and get insights in Extended Warranty",
        component: Home,
        path: "/",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([PageTabs.HOME_GENERAL, PageTabs.HOME_CHANGE_PASSWORD]),
        subpages: OrderedSet([]),
    },
    [Pages.POLICIES]: {
        title: "Policies",
        description: "You can search and find all policies here",
        component: Policies,
        path: "/policies",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.POLICY_CREATE, SubPages.POLICY_DETAIL, SubPages.POLICY_EDIT]),
    },
    [Pages.CLAIMS]: {
        title: "Claims",
        description: "You can search and find all claims here",
        component: Claims,
        path: "/claims",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.CLAIMS_DETAIL]),
    },
    [Pages.PRODUCT]: {
        title: "Products",
        description: "You can find all products here",
        component: Product,
        path: "/extendedwarranties",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([
            SubPages.PRODUCT_CREATE,
            SubPages.PRODUCT_DETAIL,
            SubPages.PRODUCT_EDIT,
            SubPages.PRODUCT_ACTIVE_ADD_PRODUCT_TARIFF,
        ]),
    },
    [Pages.COLLECTIVEINVOICES]: {
        title: "Collective invoices",
        description: "You can search and find all collective invoices here",
        component: CollectiveInvoices,
        path: "/collectiveinvoices",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.COLLECTIVE_INVOICE_GENERATION]),
    },
    [Pages.INDIVIDUALINVOICES]: {
        title: "Partner invoices",
        description: "You can search and find all partner invoices here",
        component: IndividualInvoices,
        path: "/partnerinvoices",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [Pages.ACCOUNTS]: {
        title: "Accounts",
        description: "You can find all accounts here",
        component: Accounts,
        path: "/accounts",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([
            SubPages.ACCOUNTS_CREATE,
            SubPages.ACCOUNTS_DETAIL,
            SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL,
            SubPages.ACCOUNTS_DETAIL_STATEMENTS_UPLOAD,
            SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK]),
    },
    [Pages.PARTNERS]: {
        title: "Partners",
        description: "You can search and find all partners here",
        component: Partners,
        path: "/partners",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.PARTNER_CREATE, SubPages.PARTNER_DETAIL, SubPages.PARTNER_EDIT]),
    },
    [Pages.MANUAL_BOOKING]: {
        title: "Free bookings",
        description: "You can search, find and create a free booking here",
        component: ManualBookings,
        path: "/free-bookings",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.MANUAL_BOOKING_CREATE]),
        // beta: true,
    },
    [Pages.TARIFFS]: {
        title: "Tariffs",
        description: "You can find all tariffs here",
        component: Tariffs,
        path: "/tariffs",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.TARIFF_CREATE, SubPages.TARIFF_EDIT]),
    },
    [Pages.BOOKING_PERIOD]: {
        title: "Booking Period",
        description: "You can search and find all booking periods here",
        component: BookingPeriod,
        path: "/booking-periods",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [Pages.GENERAL_LEDGER]: {
        title: "General Ledgers",
        description: "You can search and find all general ledgers here",
        component: GeneralLedger,
        path: "/generalledgers",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.GENERAL_LEDGER_CREATE, SubPages.GENERAL_LEDGER_EDIT]),
    },
    [Pages.REPORTS]: {
        title: "Reports",
        description: "You can generate your reports here",
        component: Reports,
        path: "/reports",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [Pages.FILES]: {
        title: "Files",
        description: "You can search and find all files here",
        component: Files,
        path: "/files",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([SubPages.FILES_UPLOAD]),
    },
    [Pages.SYSTEM_LOGS]: {
        title: "System logs",
        description: "You can find all system logs here",
        component: SystemLogs,
        path: "/system-logs",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([PageTabs.CLAIMS_ERRORS]),
        subpages: OrderedSet([]),
    },
    [Pages.MANAGE]: {
        title: "Manage",
        description: "You can manage brands and models here",
        component: Manage,
        path: "/manage",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([
            SubPages.MANAGE_BRANDS,
            SubPages.BRAND_CREATE,
            SubPages.BRAND_EDIT,
            SubPages.MANAGE_MODELS,
            SubPages.MODEL_CREATE,
            SubPages.MODEL_EDIT,
            SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS,
        ]),
    },
    [Pages.AUDIT_TRAILS]: {
        title: "Audit trails",
        description: "You can find all audit trails here",
        component: AuditTrails,
        path: "/audit",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_DEBTOR],
        tabs: OrderedSet([]),
        subpages: OrderedSet([
            SubPages.AUDIT_TRAIL_POLICIES,
            SubPages.AUDIT_TRAIL_POLICY_DETAIL,
            SubPages.AUDIT_TRAIL_PRODUCTS,
            SubPages.AUDIT_TRAIL_PRODUCT_DETAIL,
            SubPages.AUDIT_TRAIL_PARTNERS,
            SubPages.AUDIT_TRAIL_PARTNER_DETAIL,
            SubPages.AUDIT_TRAIL_TARIFFS,
            SubPages.AUDIT_TRAIL_TARIFFS_DETAIL,
            SubPages.AUDIT_TRAIL_PAYMENTS,
            SubPages.AUDIT_TRAIL_PAYMENT_DETAIL,
            SubPages.AUDIT_TRAIL_GL,
            SubPages.AUDIT_TRAIL_GL_DETAIL,
            SubPages.AUDIT_TRAIL_BOOKING_PERIOD,
            SubPages.AUDIT_TRAIL_BOOKING_PERIOD_DETAIL,
            SubPages.AUDIT_TRAIL_CI,
            SubPages.AUDIT_TRAIL_CI_DETAIL,
            SubPages.AUDIT_TRAIL_INVOICE,
            SubPages.AUDIT_TRAIL_INVOICE_DETAIL,
            SubPages.AUDIT_TRAIL_STATEMENT,
            SubPages.AUDIT_TRAIL_STATEMENT_DETAIL,
            SubPages.AUDIT_TRAIL_FREEBOOKING,
            SubPages.AUDIT_TRAIL_FREEBOOKING_DETAIL,
        ]),
    },
};

/**
 *
 */
export const SubPageDisplay: Readonly<Record<SubPages, Page>> = {
    [SubPages.MANAGE_BRANDS]: {
        title: "Brands",
        description: "You can manage brands here",
        component: Brands,
        path: "/manage/brands",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.BRAND_CREATE]: {
        title: "Create brand",
        description: "You can create a brand here",
        component: CreateBrand,
        path: "/manage/brands/create",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.BRAND_EDIT]: {
        title: "Edit",
        description: "You can edit a brand here",
        component: EditBrand,
        path: "/manage/brands/:brandId/edit",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.MANAGE_MODELS]: {
        title: "Models",
        description: "You can manage models here",
        component: Models,
        path: "/manage/models",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.MODEL_CREATE]: {
        title: "Create model",
        description: "You can create a model here",
        component: CreateModel,
        path: "/manage/models/create",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.MODEL_EDIT]: {
        title: "Edit",
        description: "You can edit a model here",
        component: EditModel,
        path: "/manage/models/:modelId/edit",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS]: {
        title: "Business Rule Violations",
        description: "You can manage business rule violations here",
        component: BusinessRuleViolations,
        path: "/manage/business-rule-violations",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.CLAIMS_DETAIL]: {
        title: "Claim details",
        description: "Some detailed information about the claim",
        component: ClaimsDetail,
        path: "/claims/:claimId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([PageTabs.CLAIMS_GENERAL, PageTabs.CLAIMS_BOOKINGS]),
        subpages: OrderedSet([]),
    },
    [SubPages.COLLECTIVE_INVOICE_GENERATION]: {
        title: "Generate collective invoice",
        description: "You can generate a collective invoice here",
        component: CollectiveInvoiceGeneration,
        path: "/collectiveinvoices/generate",
        roles: [UserRoles.EW_ACCOUNTING],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.ACCOUNTS_CREATE]: {
        title: "Create Account",
        description: "You can create an account here",
        component: CreateAccount,
        path: "/accounts/create",
        roles: [UserRoles.EW_ACCOUNTING],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.ACCOUNTS_DETAIL]: {
        title: "Account details",
        description: "You can see your account details here",
        component: AccountsDetail,
        path: "/accounts/:accountId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL]: {
        title: "Statement details",
        description: "You can upload the statements here",
        component: StatementsDetail,
        path: "/accounts/:accountId/:statementId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([PageTabs.STATEMENTS_STATEMENT_LINES]),
        subpages: OrderedSet([]),
    },
    [SubPages.ACCOUNTS_DETAIL_STATEMENTS_UPLOAD]: {
        title: "Upload Statements",
        description: "You can upload the statements here",
        component: StatementsUpload,
        path: "/accounts/:accountId/statements/upload",
        roles: [UserRoles.EW_ACCOUNTING],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK]: {
        title: "Book statement line",
        description: "You can book your statement line here",
        component: Book,
        path: "/accounts/:accountId/:statementId/:statementLineId/manage",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_SUMMARY,
            PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOKED_ITEMS,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.POLICY_DETAIL]: {
        title: "Policy details",
        description: "Some detailed information about the policy",
        component: PolicyDetail,
        path: "/policies/:policyId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([
            PageTabs.POLICIES_GENERAL,
            PageTabs.POLICIES_VEHICLE,
            PageTabs.POLICIES_PRODUCT,
            PageTabs.POLICIES_PREMIUM,
            PageTabs.POLICIES_CLAIMS,
            PageTabs.POLICIES_INVOICES,
            PageTabs.POLICIES_MEMO,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.PARTNER_DETAIL]: {
        title: "Partner",
        description: "Some detailed information about the partner",
        component: PartnerDetail,
        path: "/partners/:partnerId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([
            PageTabs.PARTNER_GENERAL,
            PageTabs.PARTNER_COLLECTIVEINVOICE,
            PageTabs.PARTNER_INVOICE,
            PageTabs.PARTNER_PAYMENTS,
            PageTabs.PARTNER_MANDATES,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.PARTNER_CREATE]: {
        title: "Create partner",
        description: "You can create a partner here",
        component: CreatePartner,
        path: "/partners/create",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.PARTNER_EDIT]: {
        title: "Edit partner",
        description: "You can edit a partner here",
        component: EditPartner,
        path: "/partners/:partnerId/edit",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.FILES_UPLOAD]: {
        title: "Upload Files",
        description: "You can upload the files here",
        component: FilesUpload,
        path: "/files/upload",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.PRODUCT_DETAIL]: {
        title: "Product details",
        description: "You can see product details here",
        component: ProductDetail,
        path: "/extendedwarranties/:ewId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([PageTabs.PRODUCT_GENERAL, PageTabs.PRODUCT_TARIFFS]),
        subpages: OrderedSet([]),
    },
    [SubPages.PRODUCT_CREATE]: {
        title: "Create product",
        description: "You can create a product here",
        component: CreateProduct,
        path: "/extendedwarranties/create",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.PRODUCT_EDIT]: {
        title: "Edit product",
        description: "You can edit a product here",
        component: EditProduct,
        path: "/extendedwarranties/:ewId/edit",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.PRODUCT_ACTIVE_ADD_PRODUCT_TARIFF]: {
        title: "Add product tariff",
        description: "You can add a new product tariff here",
        component: AddProductTariff,
        path: "/extendedwarranties/:ewId/addproducttariff",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.POLICY_CREATE]: {
        title: "Create policy",
        description: "You can create a policy here",
        component: CreatePolicy,
        path: "/policies/create",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.POLICY_EDIT]: {
        title: "Correct policy",
        description: "You can edit a policy here",
        component: EditPolicy,
        path: "/policies/:policyId/edit",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.MANUAL_BOOKING_CREATE]: {
        title: "Make a free booking",
        description: "You can create a new free booking here",
        component: CreateManualBooking,
        path: "/free-bookings/create",
        roles: [UserRoles.EW_ACCOUNTING],
        tabs: OrderedSet([
            PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNER,
            PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES,
            PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS,
            PageTabs.MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER,
            PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES,
            PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.TARIFF_CREATE]: {
        title: "Create Tariff",
        description: "You can create a tariff here",
        component: Create,
        path: "/tariffs/create",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([PageTabs.TARIFF_CREATE_MANUAL, PageTabs.TARIFF_CREATE_AUTOMATIC]),
        subpages: OrderedSet([]),
    },
    [SubPages.TARIFF_EDIT]: {
        title: "Edit tariff",
        description: "You can edit a tariff here",
        component: EditTariff,
        path: "/tariffs/:tariffId/edit",
        roles: [UserRoles.EW_ADMIN],
        tabs: OrderedSet([PageTabs.TARIFF_EDIT_MANUAL, PageTabs.TARIFF_EDIT_AUTO]),
        subpages: OrderedSet([]),
    },
    [SubPages.GENERAL_LEDGER_CREATE]: {
        title: "Create general ledger",
        description: "You can create a general ledger here",
        component: CreateGL,
        path: "/generalledgers/create",
        roles: [UserRoles.EW_ACCOUNTING],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.GENERAL_LEDGER_EDIT]: {
        title: "Edit general ledger",
        description: "You can edit a general ledger here",
        component: EditGL,
        path: "/generalledgers/:gLId/edit",
        roles: [UserRoles.EW_ACCOUNTING],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_POLICIES]: {
        title: "Policies",
        description: "You can find all policies here",
        component: PoliciesAuditTrail,
        path: "/audit/policies",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_POLICY_DETAIL]: {
        title: "Policy audit trail log",
        description: "You can view audit trail logs per policy here",
        component: PolicyAuditTrailDetail,
        path: "/audit/policies/:policyId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([
            PageTabs.AUDIT_POLICIES_GENERAL,
            PageTabs.AUDIT_POLICIES_VEHICLE,
            PageTabs.AUDIT_POLICIES_PRODUCT,
            PageTabs.AUDIT_POLICIES_PREMIUM,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_PRODUCTS]: {
        title: "Products",
        description: "You can find all products here",
        component: ProductsAuditTrail,
        path: "/audit/products",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_PRODUCT_DETAIL]: {
        title: "Product audit trail log",
        description: "You can view audit trail logs per product here",
        component: ProductAuditTrailDetail,
        path: "/audit/products/:ewId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([
            PageTabs.AUDIT_PRODUCTS_GENERAL,
            PageTabs.AUDIT_PRODUCTS_PRODUCTTARIFFS,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_PARTNERS]: {
        title: "Partners",
        description: "You can find all partners here",
        component: PartnersAuditTrail,
        path: "/audit/partners",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_DEBTOR],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_PAYMENTS]: {
        title: "Payments",
        description: "You can find all payments here",
        component: PaymentsAuditTrail,
        path: "/audit/payments",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_PARTNER_DETAIL]: {
        title: "Partner audit trail log",
        description: "You can view audit trail logs per partner here",
        component: PartnerAuditTrailDetail,
        path: "/audit/partners/:partnerId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ACCOUNTING, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_DEBTOR],
        tabs: OrderedSet([
            PageTabs.AUDIT_PARTNERS_GENERAL,
        ]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_TARIFFS]: {
        title: "Tariffs",
        description: "You can find all tariffs here",
        component: TariffsAuditTrail,
        path: "/audit/tariffs",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_TARIFFS_DETAIL]: {
        title: "Tariff audit trail log",
        description: "You can view audit trail logs per tariff here",
        component: TariffAuditTrailDetail,
        path: "/audit/tariffs/:tariffId",
        roles: [UserRoles.EW_ADMIN, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_PAYMENT_DETAIL]: {
        title: "Payment audit trail log",
        description: "You can view audit trail logs per payment here",
        component: PaymentAuditTrailDetail,
        path: "/audit/payments/:paymentId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_GL]: {
        title: "General ledgers",
        description: "You can find all general ledgers here",
        component: GLAuditTrail,
        path: "/audit/gl",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_GL_DETAIL]: {
        title: "General ledger audit trail log",
        description: "You can view audit trail logs per general ledger here",
        component: GLAuditTrailDetail,
        path: "/audit/gl/:glId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_BOOKING_PERIOD]: {
        title: "Booking periods",
        description: "You can find all booking periods here",
        component: BookingPeriodAuditTrail,
        path: "/audit/bookingperiods",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_BOOKING_PERIOD_DETAIL]: {
        title: "Booking period audit trail log",
        description: "You can view audit trail logs per booking period here",
        component: BookingPeriodAuditTrailDetail,
        path: "/audit/bookingperiods/:periodId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_CI]: {
        title: "Collective invoices",
        description: "You can find all collective invoices here",
        component: CIAuditTrail,
        path: "/audit/collectiveinvoices",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_CI_DETAIL]: {
        title: "Collective invoice audit trail log",
        description: "You can view audit trail logs per collective invoice here",
        component: CIAuditTrailDetail,
        path: "/audit/collectiveinvoices/:ciId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_INVOICE]: {
        title: "Individual invoices",
        description: "You can find all individual invoices here",
        component: InvoiceAuditTrail,
        path: "/audit/individualinvoices",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_INVOICE_DETAIL]: {
        title: "Individual invoice audit trail log",
        description: "You can view audit trail logs per individual invoice here",
        component: InvoiceAuditTrailDetail,
        path: "/audit/individualinvoices/:invoiceId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY, UserRoles.EW_ADMIN],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_STATEMENT]: {
        title: "Statements",
        description: "You can find all statements here",
        component: StatementAuditTrail,
        path: "/audit/statements",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_STATEMENT_DETAIL]: {
        title: "Statement audit trail log",
        description: "You can view audit trail logs per statement here",
        component: StatementAuditTrailDetail,
        path: "/audit/statements/:statementId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_FREEBOOKING]: {
        title: "Free bookings",
        description: "You can find all free bookings here",
        component: FreebookingAuditTrail,
        path: "/audit/freebookings",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
    [SubPages.AUDIT_TRAIL_FREEBOOKING_DETAIL]: {
        title: "Free bookings audit trail log",
        description: "You can view audit trail logs per free booking here",
        component: FBAuditTrailDetail,
        path: "/audit/freebookings/:fbId",
        roles: [UserRoles.EW_ACCOUNTING, UserRoles.EW_DEBTOR, UserRoles.EW_ADMIN_READONLY],
        tabs: OrderedSet([]),
        subpages: OrderedSet([]),
    },
};

/**
 *
 */
export const PageTabsDisplay: Readonly<Record<PageTabs, Tab>> = {
    [PageTabs.HOME_GENERAL]: {
        title: "General",
        description: "General information",
        component: General,
    },
    [PageTabs.HOME_CHANGE_PASSWORD]: {
        title: "Change Password",
        description: "You can change your password here",
        component: ChangePassword,
    },
    [PageTabs.CLAIMS_GENERAL]: {
        title: "General",
        description: "General information",
        component: ClaimsGeneral,
    },
    [PageTabs.CLAIMS_BOOKINGS]: {
        title: "Bookings",
        description: "You can see your bookings here",
        component: ClaimsBookings,
    },
    [PageTabs.CLAIMS_ERRORS]: {
        title: "Claim errors",
        description: "You can see a log of claim errors here",
        component: ClaimErrors,
    },
    [PageTabs.STATEMENTS_STATEMENT_LINES]: {
        title: "Statement lines",
        description: "You can see the statement lines here",
        component: StatementsStatementLines,
    },
    [PageTabs.PARTNER_GENERAL]: {
        title: "General",
        description: "You can see general details here",
        component: PartnerGeneral,
    },
    [PageTabs.PARTNER_COLLECTIVEINVOICE]: {
        title: "Collective invoices",
        description: "You can see collective invoice details here",
        component: PartnerCollectiveInvoices,
    },
    [PageTabs.PARTNER_INVOICE]: {
        title: "Ind. invoices",
        description: "You can see invoice details here",
        component: PartnerInvoices,
    },
    [PageTabs.PARTNER_PAYMENTS]: {
        title: "Payments",
        description: "You can see payments details here",
        component: PartnerPayments,
    },
    [PageTabs.PARTNER_MANDATES]: {
        title: "Mandates",
        description: "You can see mandates details here",
        component: PartnerMandates,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER]: {
        title: "Partners",
        description: "You can book the statement line to the partner here",
        component: BookPartners,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES]: {
        title: "Collective invoices",
        description: "You can book the statement line to the collective invoices here",
        component: BookInvoices,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER]: {
        title: "General ledgers",
        description: "You can book the statement line to the general ledger here",
        component: BookLedger,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO]: {
        title: "Incassos",
        description: "You can book the statement line to the incasso files here",
        component: BookIncasso,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO]: {
        title: "Excassos",
        description: "You can book the statement line to the excasso files here",
        component: BookExcasso,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS]: {
        title: "Claims",
        description: "You can book the statement line to the claims here",
        component: BookClaims,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_SUMMARY]: {
        title: "Summary",
        description: "You can have a look at the statement line summary here",
        component: SummaryTab,
    },
    [PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOKED_ITEMS]: {
        title: "Booked Items",
        description: "You can have a look at the booked items here",
        component: BookedStatementsResults,
    },
    [PageTabs.POLICIES_GENERAL]: {
        title: "General",
        description: "You can see general details here",
        component: PolicyGeneral,
    },
    [PageTabs.POLICIES_MEMO]: {
        title: "Memo",
        description: "You can see memo details here",
        component: PolicyMemo,
    },
    [PageTabs.POLICIES_VEHICLE]: {
        title: "Vehicle",
        description: "You can see vehicle details here",
        component: PolicyVehicle,
    },
    [PageTabs.POLICIES_PRODUCT]: {
        title: "Product",
        description: "You can see the product details here",
        component: PolicyProduct,
    },
    [PageTabs.POLICIES_PREMIUM]: {
        title: "Premium",
        description: "You can see premium details here",
        component: PolicyPremium,
    },
    [PageTabs.POLICIES_CLAIMS]: {
        title: "Claims",
        description: "You can see claim details here",
        component: PolicyClaims,
    },
    [PageTabs.POLICIES_INVOICES]: {
        title: "Ind. invoices",
        description: "You can see invoice details here",
        component: PolicyInvoices,
    },
    [PageTabs.POLICIES_AUDIT_TRAILS]: {
        title: "Audit trails",
        description: "You can see the audit trails here",
        component: PolicyAuditTrails,
    },
    [PageTabs.PRODUCT_GENERAL]: {
        title: "General",
        description: "You can see general details here",
        component: ProductGeneral,
    },
    [PageTabs.PRODUCT_TARIFFS]: {
        title: " Product Tariffs",
        description: "You can see tariff details here",
        component: ProductTariffs,
    },
    [PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES]: {
        title: "Collective invoices",
        description: "You can do a free booking on collective invoices here",
        component: ManualBookCollectiveInvoices, // TODO: Fix, not the correct component
    },
    [PageTabs.MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER]: {
        title: "General ledgers",
        description: "You can do a free booking on general ledger here",
        component: ManualBookGeneralLedger,
    },
    [PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS]: {
        title: "Claim Invoices",
        description: "You can do a free booking on claims here",
        component: ManualBookClaims,
    },
    [PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNER]: {
        title: "Partners",
        description: "You can do a free booking on partners here",
        component: ManualBookPartners,
    },
    [PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES]: {
        title: "Indiv. Invoices",
        description: "You can do a free booking on indiv. invoices here",
        component: ManualBookInvoices, // TODO: Fix, not the correct component
    },
    [PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES]: {
        title: "Partner Invoices",
        description: "You can do a free booking on partner invoices here",
        component: ManualBookPartnerInvoices, // TODO: Fix, not the correct component
    },
    [PageTabs.TARIFF_CREATE_MANUAL]: {
        title: "Manual",
        description: "You can create a tariff here",
        component: CreateManual,
    },
    [PageTabs.TARIFF_CREATE_AUTOMATIC]: {
        title: "Auto Fill",
        description: "You can create a tariff here",
        component: CreateAuto,
    },
    [PageTabs.TARIFF_EDIT_MANUAL]: {
        title: "Manual",
        description: "You can edit a tariff here",
        component: EditManual,
    },
    [PageTabs.TARIFF_EDIT_AUTO]: {
        title: "Auto Fill",
        description: "You can edit a tariff here",
        component: EditAuto,
    },
    [PageTabs.AUDIT_POLICIES_GENERAL]: {
        title: "General",
        description: "You can see general details here",
        component: PoliciesAuditGeneral,
    },
    [PageTabs.AUDIT_POLICIES_VEHICLE]: {
        title: "Vehicle",
        description: "You can see vehicle details here",
        component: PoliciesAuditVehicle,
    },
    [PageTabs.AUDIT_POLICIES_PRODUCT]: {
        title: "Product",
        description: "You can see the product details here",
        component: PoliciesAuditProduct,
    },
    [PageTabs.AUDIT_POLICIES_PREMIUM]: {
        title: "Premium",
        description: "You can see premium details here",
        component: PoliciesAuditPremium,
    },
    [PageTabs.AUDIT_PRODUCTS_GENERAL]: {
        title: "General",
        description: "You can see general details here",
        component: ProductsAuditGeneral,
    },
    [PageTabs.AUDIT_PRODUCTS_PRODUCTTARIFFS]: {
        title: "Product Tariffs",
        description: "You can see product tariffs here",
        component: ProductsAuditTariffs,
    },
    [PageTabs.AUDIT_PARTNERS_GENERAL]: {
        title: "General",
        description: "You can see general details here",
        component: PartnersAuditGeneral,
    },
};
