
import * as React from "react";
import { connect } from "react-redux";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ValidationStatus } from "../../../../../components/core/07-organisms/Form/_Types";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ClaimMatchFilters, canSearchClaimMatch } from "../../../../../store/ClaimMatch/Types";
import { updateFilters, clearFilters } from "../../../../../store/FilterActions";
import { FilterScope, hasSearchableFilters, getFilters } from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../../theme/_Types";
import { validFromValidation, validTillValidation } from "../../../../../utils/FormValidations";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Button } from "../../../../../components/core/05-atoms/Button";


/**
 *
 */
type setFilterFn = (filters: Partial<ClaimMatchFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof ClaimMatchFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: ClaimMatchFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    hasSearchableFilters: boolean;
    filters: ClaimMatchFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => {
    const claimFilters = s.prop("filters").prop(FilterScope.CLAIM_MATCH);
    return {
        // searchEnabled: canSearchClaimMatch(s),
        searchEnabled: canSearchClaimMatch(claimFilters),
        hasSearchableFilters: hasSearchableFilters(s, FilterScope.CLAIM_MATCH, p.defaultFilters),
        filters: getFilters(s, FilterScope.CLAIM_MATCH) as ClaimMatchFilters,
    };
};

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.CLAIM_MATCH)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.CLAIM_MATCH));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.CLAIM_MATCH_RESULTS, {
        skip: 0,
    })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.CLAIM_MATCH_RESULTS)),
        dispatch(remoteClearError(RemoteScope.CLAIM_MATCH_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.fromDateValidation = this.fromDateValidation.bind(this);
        this.toDateValidation = this.toDateValidation.bind(this);
        this.onChangeFromDate = this.onChangeFromDate.bind(this);
        this.onChangeToDate = this.onChangeToDate.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <>
                <Form onSubmit={this.onSearchFilter}>
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <FormField
                                label="From date"
                                required
                                placeholder="e.g. 2020/11/01"
                                onChange={this.onChangeFromDate}
                                type="date"
                                value={this.props.filters?.startDate ?? ""}
                                validationFunction={this.fromDateValidation}
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 6 }}
                        >
                            <FormField
                                label="To date"
                                required
                                placeholder="e.g. 2020/11/01"
                                onChange={this.onChangeToDate}
                                type="date"
                                value={this.props.filters?.endDate ?? ""}
                                validationFunction={this.toDateValidation}
                            />
                        </Grid>
                    </Div>
                    <Required />
                    <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                        <Button
                            className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                            link={{ onClick: this.initFilters }}
                            disabled={!this.props.hasSearchableFilters || this.props.loadingResults}
                        >
                            Clear filters
                        </Button>
                        {" "}
                        <Button
                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                            className="scl-a-btn--big"
                            buttonType="submit"
                            disabled={(this.props.searchEnabled && !this.props.loadingResults)
                                ? !this.formIsValid() : true}
                        >
                            Search
                        </Button>
                    </ButtonsWrapper>
                </Form>
            </>
        );
    }

    private fromDateValidation(value: string | number) {
        return validFromValidation(value.toString(),
            this.props.filters.endDate
                ? this.props.filters.endDate : undefined);
    }

    private toDateValidation(value: string | number) {
        return validTillValidation(value.toString(),
            this.props.filters.startDate
                ? this.props.filters.startDate : undefined);
    }

    private onChangeFromDate(value?: string | number) {
        return this.onChange("startDate", value?.toString() ?? "");
    }

    private onChangeToDate(value?: string | number) {
        return this.onChange("endDate", value?.toString() ?? "");
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.clearResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof ClaimMatchFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof ClaimMatchFilters>(key: K, value: ClaimMatchFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private formIsValid = () => {
        if (this.props.filters.startDate && this.props.filters.endDate) {
            return validFromValidation(this.props.filters.startDate,
                this.props.filters?.endDate ?? undefined).status === ValidationStatus.SUCCESS
                && validTillValidation(this.props.filters.endDate,
                    this.props.filters?.startDate ?? undefined).status === ValidationStatus.SUCCESS;
        }
        return false;
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
