import { Operation } from "./PaymentTypes";
import { RevisionType } from "./Types";


/**
 *
 */
export const RevisionTypeDisplay: Readonly<Record<RevisionType, string>> = {
    [RevisionType.UNKNOWN]: "Unknown",
    [RevisionType.INSERT]: "Insert",
    [RevisionType.UPDATE]: "Update",
    [RevisionType.DELETE]: "Delete",
};

/**
 *
 */
export const PaymentOperationTypeDisplay: Readonly<Record<Operation, string>> = {
    [Operation.BOOKING]: "Booking",
    [Operation.UNBOOKING]: "Unbooking",
    [Operation.FREEBOOKING]: "Free Booking",
    [Operation.FREEUNBOOKING]: "Free Unbooking",
};
