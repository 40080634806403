
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { Statement } from "../../../../../store/Statements/Types";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";
import { Detail } from "../accounts/statements/DetailAudit";

/**
 *
 */
interface OwnProps {
    modalID: string;
    statement: Statement;
}

interface StateProps {
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;


/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
});

/**
 *
 */
export class DetailModalComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    Statement audit trail log
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }}/>
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn
                        style={{
                            maxHeight: "calc(98vh - 120px)",
                        }}>
                        <FlexRow isScrollable >
                            <div style={{width: "100%"}}>
                                <Container
                                    theme={{
                                        padding: {
                                            "": { y: 3 },
                                            "sm": { y: 4 },
                                        },
                                    }}
                                >
                                    <Detail details={this.props.statement} />
                                </Container>
                            </div>
                        </FlexRow>
                        <FlexRow isFixed>
                            <FlexColumn
                                theme={{
                                    palette: ThemePalette.CONTRAST_SECONDARY,
                                }}
                            >
                                <Container
                                    theme={{
                                        padding: { "": { y: 3 } },
                                    }}
                                >
                                    <ButtonsWrapper
                                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                        alignment={Alignment.RIGHT}
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            link={{ onClick: this.closeModal() }}
                                        >
                                                Close
                                        </Button>
                                    </ButtonsWrapper>
                                </Container>
                            </FlexColumn>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }
}

/**
 *
 */
export const DetailModal = connect(
    undefined,
    mapDispatchToProps,
)(DetailModalComp);
