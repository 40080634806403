import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { PolicyStatusDisplay } from "../../../../../store/Policies/Displays";
import { Policies } from "../../../../../store/Policies/Types";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { Container } from "../../../../../components/core/03-base/Container";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { licensePlate } from "../../../../../utils/FormatHelpers";
import { getPolicyStatusColor } from "../../../../utils/FormatHelpers";
import { ResultCountBar } from "../../../07-organisms/ResultCountBar";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: Policies;
    asAuditTab?: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.POLICY_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <ResultCountBar
                    totalElements={this.props.results.totalElements}
                    totalPages={this.props.results.totalPages}
                    currentPage={this.props.results.number}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row) => ({
            id: row.id,
            data: [
                row.policyNumber,
                (
                    <Badge
                        key={`badge-${row.id}`}
                        theme={{
                            paletteState:
                                getPolicyStatusColor
                                (row.status),
                        }}
                    >
                        {PolicyStatusDisplay[row.status] ?? row.status}
                    </Badge>
                ),
                row.licensePlate ? licensePlate(row.licensePlate) : "",
                row.chassisNumber,
                row.extendedWarranty ? (
                    <NavLink to={`/extendedwarranties/${row.extendedWarranty.id}`} target="_blank">
                        <span className="scl-a-link scl-b-h scl-b-h6">
                            <Icon name="eye"/>&nbsp;&nbsp;{row.extendedWarranty.name}
                        </span>
                    </NavLink>
                ) : "",
                row.policyHolder ? row.policyHolder.name : "",
                row.policyHolder ? (
                    <NavLink to={`/partners/${row.policyHolder.id}`} target="_blank">
                        <span className="scl-a-link scl-b-h scl-b-h6">
                            <Icon name="eye"/>&nbsp;&nbsp;{row.policyHolder.partnerNumber}
                        </span>
                    </NavLink>
                ) : row.partnerNumber,
                <ButtonsWrapper
                    key={`navlink-${row.id}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.asAuditTab ? (
                            <NavLink to={`/audit/policies/${row.id}`}>
                                <Button
                                    icon={IconNames.ARROW_RIGHT}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                />
                            </NavLink>
                        ) :
                            (
                                <NavLink to={`/policies/${row.id}`}>
                                    <Button
                                        icon={IconNames.ARROW_RIGHT}
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                            )}</ButtonsWrapper>,
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Policy number",
                },
                {
                    label: "Status",
                },
                {
                    label: "License plate",
                },
                {
                    label: "Chassis number",
                },
                {
                    label: "Product",
                },
                {
                    label: "Policy holder name",
                },
                {
                    label: "Policy holder number",
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
