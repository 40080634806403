import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../../store/AppDisplays";
import { isRemoteLoading, ReduxState } from "../../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { Results } from "../../../../02-pages/Results";
import { Blockquote } from "../../../../../../../components/core/05-atoms/Blockquote";
import { Div } from "../../../../../../../components/core/03-base/Div";
import { ClaimInvoiceBookings } from "../../../../../../../store/ClaimMatch/Types";
import { Filter } from "../../../../../09-views/02-pages/Filter";

interface OwnProps {
    statementLineId: string;
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results?: ClaimInvoiceBookings;
}

type Props = OwnProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.CLAIM_MATCH_RESULTS),
    results:
        s.prop("remote").prop(RemoteScope.CLAIM_MATCH_RESULTS),
});

/**
 *
 * @param props
 */
export class ClaimsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Blockquote>
                    <h4>Booking Claims</h4><br/>
                    <div className="scl-h-text">
                        Even though you might encounter Claims with the type debit, <br/>
                        booking these against a debit statement line will not be possible at the time of
                         writing (15-10-2020).
                    </div>
                </Blockquote>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS}
                    defaultFilters={{ entryId: this.props.statementLineId }}
                />

                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS}
                    statementLineId={this.props.statementLineId}
                    scope={RemoteScope.CLAIM_MATCH_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Claims = connect(
    mapStateToProps,
    undefined,
)(ClaimsComp);

