import { Action } from "redux";
import { formattedDate } from "../../utils/FormatHelpers";
import { State } from "../../utils/redux/State";

import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn, InExGenerationForm } from "../ReduxState";

type InitUpdateInExFormAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_INEX_FORM> & {
}
>;

export const initUpdateInExForm =
    (): InitUpdateInExFormAction => ({
        type: ActionTypeKeys.INIT_UPDATE_INEX_FORM,
    });

/**
 *
 */
export const initUpdateInExFormReducer:
ReducerFn<InitUpdateInExFormAction> =
    (s, _a) => (
        s.setProp(
            "inexGenerationForm",
            s.prop("inexGenerationForm").updateWith({
                requestedDate: formattedDate(new Date()),
            }),
        )
    );

/**
 *
 */
type UpdateInExFormAction = Readonly<
Action<ActionTypeKeys.UPDATE_INEX_FORM> & {
    props?: Partial<InExGenerationForm>;
}
>;

export const updateInExForm =
    (props: Partial<InExGenerationForm>): UpdateInExFormAction => ({
        type: ActionTypeKeys.UPDATE_INEX_FORM,
        props: props,
    });

/**
 *
 */
export const updateInExFormReducer:
ReducerFn<UpdateInExFormAction> =
    (s, a) => (
        s.setProp(
            "inexGenerationForm",
            s.prop("inexGenerationForm").updateWith(a.props ? a.props : {}),
        )
    );

/**
 *
 */
type ClearInExFormAction = Readonly<
Action<ActionTypeKeys.CLEAR_INEX_FORM> &
{
    keys?: Array<keyof InExGenerationForm>;
}
>;

/**
 *
 * @param keys
 */
export const clearInExForm:
(keys?: Array<keyof InExGenerationForm>) => ClearInExFormAction =
    (keys) => ({
        type: ActionTypeKeys.CLEAR_INEX_FORM,
        keys: keys,
    });

/**
 *
 */
export const clearInExFormReducer:
ReducerFn<ClearInExFormAction> =
    (s, _a) => (
        s.setProp(
            "inexGenerationForm",
            State.create<InExGenerationForm>(() => ({})),
        )
    );
