/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ManualBookingsResults, FreeBookingItem } from "../../../../../store/ManualBooking/Types";
import { getFreeBookingStatusColor } from "../../../../utils/FormatHelpers";

import { DetailModal } from "./DetailModal";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePalette, ThemeShadowSizes, ThemeBreakpoints } from "../../../../../theme/_Types";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: ManualBookingsResults;
    asAuditTab?: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openDetailModal: (id: string, items: FreeBookingItem[]) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

export const OpenDetailModal =
(id: string, items: FreeBookingItem[]) => (
    () => (
        <Modal
            modalID={id}
            theme={{ size: ThemeSize.SMALL }}
        >
            <DetailModal
                modalID={id}
                id={id}
                items={items}
            />
        </Modal>
    )
);

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.MANUAL_BOOKING_RESULTS, {
        skip: skip,
    })),
    openDetailModal: (id: string, items: FreeBookingItem[]) => dispatch(
        modalShow(
            id,
            OpenDetailModal(id, items),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {};

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openDetail = this.openDetail.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                (
                    <Moment
                        date={new Date(row.bookingDate)}
                        format={"DD-MM-YYYY"}
                        key={`booking-date${index}`}
                    />
                ),
                row.description,
                (
                    <Badge
                        key={`badge=${row.id}`}
                        theme={{ paletteState: getFreeBookingStatusColor(row.status) }}>{row.status}</Badge>
                ),
                <ButtonsWrapper
                    key={`navlink-${index}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.asAuditTab ? (
                            <NavLink to={`/audit/freebookings/${row.id}`}>
                                <Button
                                    icon={IconNames.ARROW_RIGHT}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                />
                            </NavLink>
                        ) :
                            (
                                <Button
                                    icon={IconNames.VIEW}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    link={{ onClick: this.openDetail(row.id.toString(), row.freeBookingItems) }}
                                />
                            )}</ButtonsWrapper>,
            ],
        }));
    }

    private openDetail(id: string, items: FreeBookingItem[]) {
        return () => this.props.openDetailModal(id, items);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Booking date",
                },
                {
                    label: "Description",
                },
                {
                    label: "Status",
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
