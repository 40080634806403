import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { Models } from "../../../../../store/Models/Types";
import { PolicyRequest } from "../../../../../store/Policies/Types";
import { ReduxState } from "../../../../../store/ReduxState";
import { TableSelectCell } from "../../../06-molecules/TableSelectCell";

interface State {
    selectedRows: string[];
}

/**
 *
 */
interface StateProps {
    policy: PolicyRequest;
}

/**
 *
 */
interface OwnProps {
    results: Models;
    onChange: <K extends keyof PolicyRequest>(key: K, value: PolicyRequest[K]) => void;
    modelNumber?: string;
}

/**
 *
 */
interface DispatchProps {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    policy: s.prop("policyRequest").map(e => ({
        ...e,
    })),
});


/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
            </React.Fragment>
        );
    }

    private selectItem(value: boolean | undefined, name: string) {
        this.setState({
            selectedRows: !!value ? [
                name,
            ] : [],
        }, () => this.props.onChange("model", this.state.selectedRows[0]));
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: this.props.modelNumber ? [this.props.modelNumber] : [],
        };
    }

    private amIChecked(name: string, selectedRows: string[]): boolean {
        return selectedRows.some((row) => row === name);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const checked = this.amIChecked(row.name, this.state.selectedRows);
            return ({
                id: row.id,
                data: [
                    (
                        <TableSelectCell
                            key={`tabel-cell-${row.id}`}
                            checked={checked}
                            selectItem={() => this.selectItem(!checked, row.name)}
                        />
                    ),
                    row.name ? row.name : "",
                    row.createdAt
                        ? <Moment date={new Date(row.createdAt)} format={"DD-MM-YYYY"} key={`c-date-${index}`}/>
                        : "",
                    row.description,
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Name",
                },
                {
                    label: "Created at",
                },
                {
                    label: "Description",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    {},
)(ResultsComp);
