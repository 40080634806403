export interface SelectedRow {
    objectId: number;
    amount: number;
}

export const amIChecked = (id: number, selectedRows: SelectedRow[]): boolean =>
    (selectedRows.some((row) => row.objectId === id));

export const myAmount = (id: number, selectedRows: SelectedRow[], fallBack?: number): number | undefined => {
    const currentRow = selectedRows.find((row) => row.objectId === id);

    if (!currentRow) {
        return fallBack;
    }

    if (currentRow.amount === 0) {
        return undefined;
    }

    return currentRow.amount;
};
