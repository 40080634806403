import * as React from "react";
import { connect } from "react-redux";
import { PartnerDetails } from "../../../../../../store/Partners/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { ReduxState } from "../../../../../../store/ReduxState";
import { GeneralView } from "../component/GeneralView";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";

/**
 *
 */
interface StateProps {
    formData: PartnerDetails;
}

/**
 *
 */
type Props = StateProps;

/**
 *
 * @param props
 */
export class GeneralComp
    extends React.Component<Props, {}> {


    public constructor(props: Props) {
        super(props);
    }

    public static mapStateToProps(s: ReduxState): StateProps {
        return {
            formData: s.prop("remote").prop(RemoteScope.PARTNER_DETAILS) as PartnerDetails,
        };
    }


    public render() {
        const general = this.props.formData &&
            this.props.formData.content ?
            this.props.formData.content : undefined;
        return (
            general ? (
                <GeneralView general={general}/>
            ) : (
                <LoadingData loading={false} />
            )
        );
    }
}

/**
 *
 */
export const General = connect(
    GeneralComp.mapStateToProps,
    {},
)(GeneralComp);
