import * as React from "react";

import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { ensure } from "../../../utils/Optional";
import { Label } from "./Label";

/**
 *
 */
export interface CheckboxProps
    extends ThemeConsumerProps {
    value?: string;
    onChange?: (value?: boolean) => void;
    checked?: boolean;
    disabled?: boolean;
    label?: string;
    required?: boolean;
}

interface State {
    checked?: boolean;
}

/**
 *
 * @param props
 */
export class CheckboxComp
    extends React.Component<CheckboxProps, State> {

    /**
     *
     * @param props
     */
    public constructor(props: CheckboxProps) {
        super(props);

        this.state = { checked: this.props.checked };
        this.onChange = this.onChange.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: CheckboxProps) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({
                checked: this.props.checked,
            });
        }
    }

    public render() {
        const inputCheckbox = (
            <input
                style={this.props.style}
                className="scl-a-checkbox__field"
                type={"checkbox"}
                value={this.props.value || ""}
                onChange={this.onChange}
                checked={this.state.checked ?? false}
                disabled={this.props.disabled}
                required={this.props.required}
            />);
        return (
            (
                <div className="scl-a-checkbox__wrapper">
                    {!!this.props.label ? (
                        <Label
                        >
                            {inputCheckbox} <span>{this.props.label}</span>
                        </Label>) :
                        inputCheckbox
                    }
                </div>
            )
        );
    }

    private onChange(evt: React.ChangeEvent<HTMLInputElement>) {
        ensure(this.props.onChange)(evt.target.checked || undefined);
    }
}

/**
 *
 */
export const Checkbox = asThemeConsumer(CheckboxComp);
