/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { State } from "../../utils/redux/State";

import { Claim } from "../Claims/Types";
import { Message } from "../Message/Types";
import { Partner } from "../Partners/Types";
import { Product, ProductTariff, hasValue } from "../Product/Types";
import { Proxy } from "../Proxies/Types";
import { RefObject, RequestObject } from "../RemoteTypes";

export enum PolicyStatus {
    RECEIVED = "RECEIVED", // system status
    APPROVED = "APPROVED", // active
    CANCELED = "CANCELED",
    ERROR = "ERROR",
    EXPIRED = "EXPIRED",
    DUPLICATED = "DUPLICATED",
}

export enum CoverageLevel {
    FULL_FUNCTION = "FULL_FUNCTION",
    THREE_COMPONENTS = "THREE_COMPONENTS",
}

export enum PAHNotified {
    TRUE = "true",
    FALSE = "false",
}

export interface PolicyRequest {
    id?: number;
    receivingDate?: string;
    bookingDate?: string;
    policyNumber?: string;
    partnerNumber?: string;
    licensePlate?: string;
    chassisNumber?: string;
    brand?: string;
    model?: string;
    type?: string;
    contractTypePah?: string;
    vehicleType?: string;
    productionYear?: string;
    power?: number;
    startDateFactoryWarranty?: string;
    endDateFactoryWarranty?: string;
    startDateExtendedWarranty?: string;
    duration?: number;
    contractCreationDate?: string;
    mileage?: number;
    maxMileage?: number;
    resultCode?: string;
    message?: RefObject;
    status?: PolicyStatus;
    claimHolder?: RefObject;
    extendedWarranty?: RefObject;
    proxy?: Proxy;
    policyPremium?: PremiumPolicy;
    claims?: Claim[];
    businessRuleViolations?: PolicyBusinessRuleViolation[];
    pahNotified?: boolean;
    termsAndConditions?: string;
}

export interface PolicyMessage extends Message {
    policies: any[]; // check
}

export enum ErrorLevel {
    CRITICAL = "CRITICAL",
    WARNING = "WARNING",
}

export interface BusinessRuleViolation {
    id: number;
    createdAt: string;
    errorDescription: string;
    errorCode: string;
    errorLevel: ErrorLevel;
}

export interface PolicyBusinessRuleViolation extends BusinessRuleViolation {
    policy: any; // check
}

export interface PremiumPolicy {
    id?: number;
    createdAt?: string;
    maxKW?: number; // mandatory
    premium?: number; // mandatory
    tax?: number; // mandatory
    netPremium?: number; // mandatory
    cost?: number; // mandatory
    netPremiumExCost?: number; // mandatory
    commissionVWPFS?: number;
    netMij?: number; // mandatory
    frontier?: number; // mandatory
    insurer?: number; // mandatory
    signCommitteeDamage?: number; // mandatory
    incassoProvision?: number; // mandatory
    productTariff?: ProductTariff;
}

export interface Policy {
    id: number;
    createdAt: string;
    receivingDate: string;
    bookingDate: string;
    policyNumber: string;
    partnerNumber: string;
    productNumber?: string;
    licensePlate: string;
    chassisNumber: string;
    brand: string;
    model: string;
    type: string;
    contractTypePah?: string;
    vehicleType: string;
    productionYear: string;
    power: number;
    startDateFactoryWarranty: string;
    endDateFactoryWarranty: string;
    startDateExtendedWarranty: string;
    duration: number;
    contractCreationDate: string;
    mileage: number;
    maxMileage: number;
    resultCode: string;
    message: PolicyMessage;
    status: PolicyStatus;
    policyHolder: Partner;
    extendedWarranty: Product;
    proxy: Proxy;
    policyPremium: PremiumPolicy;
    claims: Claim[];
    businessRuleViolations: PolicyBusinessRuleViolation[];
    pahNotified: boolean;
    soaName: string;
    productCodeVWFS: string;
    coverageCodeVWFS: string;
    termsAndConditions: string;
}

export interface Policies extends RequestObject {
    content: Policy[];
}

export interface PolicyDetails {
    content: Policy;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type PolicyFilters = Readonly<Partial<{
    policyNumber: string;
    partnerNumber: string;
    partnerName: string;
    productNumber: string;
    licensePlate: string;
    chassisNumber: string;
    policyStatus: PolicyStatus;
}>>;

export const canSubmitPolicyForm =
    (policyEdit: State<PolicyRequest>): boolean => {
        const validMandatoryPolicyFields = policyEdit.map(f => {
            const mandatoryPolicyField = Object.keys(f).filter(k =>
                (k === "partnerNumber" && hasValue(f[k]))
                || (k === "chassisNumber" && hasValue(f[k]))
                || (k === "power" && hasValue(f[k]))
                || (k === "extendedWarranty" && hasValue(f[k]))
                || (k === "mileage" && hasValue(f[k]))
                || (k === "maxMileage" && hasValue(f[k]))
                || (k === "startDateFactoryWarranty" && hasValue(f[k]))
                || (k === "endDateFactoryWarranty" && hasValue(f[k]))
                || (k === "startDateExtendedWarranty" && hasValue(f[k]))
                || (k === "vehicleType" && hasValue(f[k]))
                || (k === "duration" && hasValue(f[k]))
                || (k === "model" && hasValue(f[k]))
                || (k === "brand" && hasValue(f[k])));

            return (
                mandatoryPolicyField
            );
        }).length === 13;
        return (
            validMandatoryPolicyFields
        );
    };
