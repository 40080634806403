
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import { Partners } from "../../../../../store/Partners/Types";
import { PolicyRequest } from "../../../../../store/Policies/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { getPartnerTypeColor } from "../../../../utils/FormatHelpers";
import { TableSelectCell } from "../../../06-molecules/TableSelectCell";

interface State {
    selectedRows: string[];
}

/**
 *
 */
interface StateProps {
    policy: PolicyRequest;
    loading: boolean;
}

/**
 *
 */
interface OwnProps {
    results: Partners;
    onChange: <K extends keyof PolicyRequest>(key: K, value: PolicyRequest[K]) => void;
    partnerNumber?: string;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    policy: s.prop("policyRequest").map(e => ({
        ...e,
    })),
    loading:
            isRemoteLoading(s, RemoteScope.PARTNER_RESULTS)
    ,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.PARTNER_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {


    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results && this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private selectItem(value: boolean | undefined, id: string) {
        this.setState({
            selectedRows: !!value ? [
                id,
            ] : [],
        }, () => this.props.onChange("partnerNumber", this.state.selectedRows[0]));
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: this.props.partnerNumber ? [this.props.partnerNumber] : [],
        };
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private amIChecked(id: string, selectedRows: string[]): boolean {
        return selectedRows.some((row) => row === id);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const checked = this.amIChecked(row.partnerNumber, this.state.selectedRows);
            return ({
                id: row.id,
                data: [
                    (
                        <TableSelectCell
                            key={`table-cell-${row.id}`}
                            checked={checked}
                            selectItem={() => this.selectItem(!checked, row.partnerNumber)}
                        />
                    ),
                    row.name ?? "",
                    row.partnerNumber,
                    row.partnerType ? (
                        <Badge
                            key={`p-badge-${index}`}
                            theme={{
                                palette: getPartnerTypeColor(row.partnerType),
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        >
                            {PartnerTypeDisplay[row.partnerType]}
                        </Badge>
                    ) : "",
                    row.iban,
                    (
                        <Currency
                            key={`p-invoice-balance-${index}`}
                            amount={row.invoiceBalance}
                        />
                    ),
                    (
                        <Currency
                            key={`p-claim-balance-${index}`}
                            amount={row.claimBalance}
                        />
                    ),
                    (
                        <h6 key={`p-total-balance-${index}`}>
                            <Currency
                                amount={row.totalBalance}
                            />
                        </h6>
                    ),
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Name",
                },
                {
                    label: "Number",
                },
                {
                    label: "Type",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Invoice balance",
                },
                {
                    label: "Claim balance",
                },
                {
                    label: "Total balance",
                    alignRight: true,
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
