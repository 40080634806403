import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { IconNames, Icon } from "../../../../../../components/core/05-atoms/Icon";
import { LinkProps } from "../../../../../../components/core/05-atoms/Link";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { InfoCard } from "../../../../../../components/core/06-molecules/InfoCard";
import { Spinner } from "../../../../../../components/core/06-molecules/Spinner";
import { Card } from "../../../../../../components/core/07-organisms/Card";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";

import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { FileTypeDisplay } from "../../../../../../store/Files/Displays";
import { FileDownload } from "../../../../../../store/Files/Types";
import { Message } from "../../../../../../store/Message/Types";
import { ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../../theme/_Types";

/**
 *
 */
interface StateProps {
    loadingDownloadResults: boolean;
    fileDownloadResult: FileDownload;
}

/**
 *
 */
interface OwnProps {
    link: LinkProps;
    data: Message;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    loadDownload: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingDownloadResults: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.FILES, {
        skip: skip,
    })),
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, {id: id})),
});

/**
 *
 */
export class SuccessComp
    extends React.Component<Props, {}> {


    public constructor(props: Props) {
        super(props);
    }

    /**
     *
     */
    public render() {
        return (
            <Card
                title={"Generation Complete"}
                collapsable={false}
                defaultCollapsed={false}
            >
                <Container
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >

                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <InfoCard
                                disabled={this.props.fileDownloadResult
                                && this.props.loadingDownloadResults}
                                title={(this.props.fileDownloadResult
                                && this.props.loadingDownloadResults) ? "Downloading.." : "Download"}
                                description={((this.props.fileDownloadResult)
                                && this.props.loadingDownloadResults) ? (
                                        <div style={{marginTop: "4px"}}>
                                            <Spinner  active />
                                        </div>
                                    ) : (
                                        "You can download the report now"
                                    )}
                                link={{
                                    onClick: () =>
                                        this.props.fileDownloadResult
                                && this.props.loadingDownloadResults ? null
                                            : this.props.loadDownload(this.props.data.id),
                                }}
                                icon={IconNames.DOWNLOAD}
                            />
                        </Grid>
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Div
                                theme={{
                                    padding: {
                                        "": { t: 3 },
                                        "sm": { t: 4 },
                                    },
                                }}
                            >
                                <FieldSet title="Details">
                                    <FormField
                                        label="Name"
                                        value={this.props.data.messageName}
                                        asSummary
                                    />
                                    <FormField
                                        label="Created at"
                                        type="date-time"
                                        value={this.props.data.createdAt}
                                        asSummary
                                    />
                                    <FormField
                                        label="Type"
                                        value={FileTypeDisplay[this.props.data.messageType]}
                                        asSummary
                                    />
                                </FieldSet>
                            </Div>
                        </Grid>
                    </Div>
                    <ButtonsWrapper
                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                        alignment={Alignment.RIGHT}
                    >
                        <Button
                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                            className="scl-a-btn--big"
                            buttonType="button"
                            link={this.props.link}
                        >
                        Continue
                        &nbsp;&nbsp;
                            <Icon name={IconNames.ARROW_RIGHT} />
                        </Button>
                    </ButtonsWrapper>
                </Container>
            </Card>
        );
    }
}

/**
 *
 */
export const Success = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SuccessComp);
