import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { MemoRequest } from "./Types";
import { RemoteScope } from "../RemoteTypes";
import { State } from "../../utils/redux/State";

/**
 *
 */
export type InitUpdateMemoAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_MEMO> & {
    id?: string;
}
>;

/**
 *
 *
 */
export const initUpdateMemo =
    (id?: string): InitUpdateMemoAction => ({
        type: ActionTypeKeys.INIT_UPDATE_MEMO,
        id: id,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateMemoReducer:
ReducerFn<InitUpdateMemoAction> =
    (s, a) => {
        const memoResults = s.prop("remote").prop(RemoteScope.MEMO_RESULTS);
        const currentMemo = memoResults?.content.find(memo => memo.id.toString() === a.id);
        return (
            s.setProp(
                "memoRequest",
                s.prop("memoRequest").update((_old) => currentMemo ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateMemoAction = Readonly<
Action<ActionTypeKeys.UPDATE_MEMO> & {
    memo?: Partial<MemoRequest>;
}
>;

/**
 *
 *
 */
export const updateMemo =
    (memo: MemoRequest): UpdateMemoAction => ({
        type: ActionTypeKeys.UPDATE_MEMO,
        memo: memo,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateMemoReducer:
ReducerFn<UpdateMemoAction> =
    (s, a) => (
        s.setProp(
            "memoRequest",
            s.prop("memoRequest").updateWith(
                a.memo ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearMemoAction = Readonly<
Action<ActionTypeKeys.CLEAR_MEMO>
>;

/**
 *
 * @param keys
 */
export const clearMemo:
() => ClearMemoAction =
    () => ({
        type: ActionTypeKeys.CLEAR_MEMO,
    });

/**
 *
 */
export const clearMemoReducer:
ReducerFn<ClearMemoAction> =
    (s, _a) => (
        s.setProp(
            "memoRequest",
            State.create<MemoRequest>(() => ({})),
        )
    );
