/* eslint-disable react/display-name */

import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { PageTabs } from "../../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";

import { CollectiveInvoiceStatusTypeDisplay } from "../../../../../store/CollectiveInvoice/Displays";
import { initUpdateInExForm } from "../../../../../store/CollectiveInvoice/GenerateIncasso";
import {
    CollectiveInvoice,
    CollectiveInvoiceStatus,
    CollectiveInvoices,
    InExFormScope,
} from "../../../../../store/CollectiveInvoice/Types";
import { FileDownload } from "../../../../../store/Files/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { ThemeSize, ThemePalette, ThemeShadowSizes, ThemeBreakpoints } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { canDownloadFile } from "../../../../utils/FileDownload";
import { getCollectiveInvoiceStatusColor } from "../../../../utils/FormatHelpers";
import { DownloadButtonCell } from "../../../06-molecules/DownloadButtonCell";
import { GenerateBar } from "../../../07-organisms/GenerateBar";

import { DownloadFileProps } from "../Files/Results";
import { ConfirmInExModal } from "./ConfirmInExModal";

export interface SelectedRows {
    id: string;
    balance: number;
    invoiceNumber?: number;
}

interface State {
    selectedRows: SelectedRows[];
}

/**
 *
 */
interface StateProps extends DownloadFileProps {
    user?: UserInfo;
}

/**
 *
 */
interface OwnProps {
    results: CollectiveInvoices;
    currentTab?: PageTabs;
    asAuditTab?: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openInExRequest: (selectedRows: SelectedRows[], isDebit: boolean) => void;
    loadDownload: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

export const OpenInExReq =
    (selectedRows: SelectedRows[], isDebit: boolean) => (
        () => (
            <Modal
                modalID={isDebit ? "incasso" : "excasso"}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmInExModal
                    modalID={isDebit ? "incasso" : "excasso"}
                    selectedRows={selectedRows}
                    scope={isDebit ? InExFormScope.INCASSO : InExFormScope.EXCASSO}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFileInitDownload: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    loadingFileDownload: isRemoteLoading(s, RemoteScope.FILE_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_RESULTS, {
        skip: skip,
    })),
    openInExRequest: (selectedRows: SelectedRows[], isDebit: boolean) => {
        dispatch(
            modalShow(
                "incasso",
                OpenInExReq(selectedRows, isDebit),
            ),
        );
        dispatch(initUpdateInExForm());
    },
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, { id: id })),
});

export const sumOfSelectedRows = (selectedRows: SelectedRows[]): number => {
    let sum = 0;
    selectedRows.map(row => sum += row.balance);
    return sum;
};

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.amIChecked = this.amIChecked.bind(this);
        this.loadDownload = this.loadDownload.bind(this);
        this.openInEx = this.openInEx.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {

        const isDebit = this.props.results.content.some(
            (row: CollectiveInvoice) => (row.creditDebitIndicator === CreditDebitIndicator.DBIT));
        return (
            <React.Fragment>
                {this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE
                    && this.props.asAuditTab !== true
                    && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && (
                    <GenerateBar
                        title={
                            isDebit ? "Select Debit Collective invoices" :
                                "Select Credit Collective invoices"}
                        selectedBalance={sumOfSelectedRows(this.state.selectedRows)}
                        isValid={this.state.selectedRows.length > 0 ? true : undefined}
                        // onClickIncasso={() => {
                        //     this.props.openIncassoRequest(this.state.selectedRows);
                        // }}
                        // onClickExcasso={() => {
                        //     this.props.openExcassoRequest(this.state.selectedRows);
                        // }}
                        onClick={this.openInEx(isDebit)}
                        currentTab={this.props.currentTab}
                        debit={isDebit}
                    />
                )
                }
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const isLoading = ((this.props.fileDownloadResult && this.props.fileDownloadResult.id === row.messageId)
                && this.props.loadingFileDownload);
            return ({
                id: row.id,
                data: [
                    ...(this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE
                        && this.props.asAuditTab !== true
                        && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                        && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ? [(
                            row.status === CollectiveInvoiceStatus.UNPAID
                                || row.status === CollectiveInvoiceStatus.REVERSED ? (
                                    <Checkbox
                                        checked={this.amIChecked(row.id.toString())}
                                        onChange={(value?: boolean) => this.selectItem(value,
                                            {
                                                id: row.id.toString(), balance: row.totalAmount.amount,
                                                invoiceNumber: row.invoiceNumber,
                                            })}
                                    />
                                )
                                : ""
                        )] : []),
                    row.invoiceNumber,
                    row.invoiceDate
                        ? <Moment date={new Date(row.invoiceDate)} format={"DD-MM-YYYY"} key={`invoicedate${index}`} />
                        : "",
                    ...(this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE ? [
                        row.dealerName,
                    ] : []),
                    ...(this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE ? [
                        row.partner ? (
                            <NavLink to={`/partners/${row.partner.id}`} target="_blank">
                                <span className="scl-a-link scl-b-h scl-b-h6">
                                    <Icon name="eye" />&nbsp;&nbsp;{row.partner.partnerNumber}
                                </span>
                            </NavLink>
                        ) : row.partnerNumber,
                    ] : []),
                    row.mutationDate
                        ? <Moment date={new Date(row.mutationDate)} format={"DD-MM-YYYY"} key={`mutationdate${index}`}/>
                        : "",
                    (
                        <Badge
                            key={`badge-${row.id}`}
                            theme={{ paletteState: getCollectiveInvoiceStatusColor(row.status) }}>
                            {CollectiveInvoiceStatusTypeDisplay[row.status]}
                        </Badge>
                    ),
                    row.creditDebitIndicator === CreditDebitIndicator.DBIT ? "Debit" : "Credit",
                    (
                        <h6
                            key={`h6-${row.id}`}>
                            <Currency amount={row.totalAmount.amount} />
                        </h6>
                    ),
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >{
                            this.props.asAuditTab ? (
                                <NavLink to={`/audit/collectiveinvoices/${row.id}`}>
                                    <Button
                                        icon={IconNames.ARROW_RIGHT}
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                            ) :
                                row.messageId ? (
                                    <DownloadButtonCell
                                        isLoading={isLoading}
                                        loadDownload={this.loadDownload(row.messageId)}
                                        canDownloadFile={canDownloadFile(this.props)}
                                    />
                                ) : ""}</ButtonsWrapper>,
                ],
            });
        });
    }

    private openInEx(isDebit: boolean) {
        return () => this.props.openInExRequest(this.state.selectedRows, isDebit);
    }

    private loadDownload(id: number) {
        return () => this.props.loadDownload(id);
    }

    private selectItem(value: boolean | undefined, selectedRows: SelectedRows) {
        this.setState({
            selectedRows: !!value ? [...this.state.selectedRows, selectedRows]
                : this.state.selectedRows.filter((row) => row.id !== selectedRows.id,
                ),
        });
    }

    private amIChecked(id: string): boolean {
        return this.state.selectedRows.some((row) => row.id === id);
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: [],
        };
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                ...(this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE
                    && this.props.asAuditTab !== true
                    && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                    && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ? [{
                        label: "Select",
                    }] : []),
                {
                    label: "Number",
                },
                {
                    label: "Date",
                },
                ...(this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE ? [{ label: "Partner name" }] : []),
                ...(this.props.currentTab !== PageTabs.PARTNER_COLLECTIVEINVOICE ? [{ label: "Partner number" }] : []),
                {
                    label: "Mutation date",
                },
                {
                    label: "Status",
                },
                {
                    label: "Mutation type",
                },
                {
                    label: "Amount",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
