import React from "react";
import { PageTabs, PageTabsDisplay } from "../../../../store/AppDisplays";
import { OrderedSet } from "immutable";
import { CreditDebitIndicator, Entry } from "../../../../store/Statements/Types";
import { Product, ProductTariff } from "../../../../store/Product/Types";
import { UserInfo } from "../../../../store/AppTypes";
import { Policy } from "../../../../store/Policies/Types";
import { Partner } from "../../../../store/Partners/Types";
import { Menu, MenuItem, MenuOrientation } from "../../../../components/core/07-organisms/Menu";
import { Div } from "../../../../components/core/03-base/Div";
import { Container } from "../../../../components/core/03-base/Container";
import { ThemeShadowSizes, ThemePalette } from "../../../../theme/_Types";

interface OwnProps {
    tabs: OrderedSet<PageTabs>;
    defaultTab: PageTabs;
    params?: {
        accountId?: string;
        statementId?: string;
        statementLineId?: string;
        partnerId?: string;
        policyNumber?: string;
        policyId?: string;
        creditDebitIndicator?: CreditDebitIndicator;
        statementLine?: Entry | undefined;
        tariffId?: string;
        user?: UserInfo;
        policy?: Policy;
        product?: Product;
        partner?: Partner;
    };
    results?: {
        product?: Product;
        tariffs?: ProductTariff[];
    };
    child?: boolean;
}

interface State {
    showContent: boolean;
    currentTab: PageTabs;
}

type Props = OwnProps;

/**
 *
 */
export class Tabs
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { showContent: false, currentTab: this.props.defaultTab };
        this.showTabContent = this.showTabContent.bind(this);
    }

    public componentDidMount() {
        this.showTabContent(this.props.defaultTab);
    }

    public render() {
        return (
            <>
                {!this.props.child &&
                    <Div
                        theme={{
                            padding: {
                                "": { b: 3 },
                                "sm": { b: 4 },
                            },
                        }}
                    >
                        <Menu
                            orientation={MenuOrientation.HORIZONTAL}
                            items={this.getItems(this.props.tabs)}
                        />
                    </Div>
                }
                <Div
                    theme={{
                        padding: {
                            "": { b: 3 },
                            "sm": { b: 4 },
                        },
                    }}
                >
                    <>
                        {this.props.child &&
                            <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
                                <Container
                                    theme={{
                                        palette: ThemePalette.CONTRAST_TERTIARY,
                                        padding: {
                                            "": { t: 1 },
                                            "sm": { t: 2 },
                                        },
                                    }}
                                >
                                    <Menu
                                        orientation={MenuOrientation.HORIZONTAL}
                                        items={this.getItems(this.props.tabs)}
                                    />
                                </Container>
                            </Div>
                        }
                        {this.state.showContent && (
                            React.createElement(PageTabsDisplay[this.state.currentTab].component,
                                { ...this.props.params, ...this.props.results },
                            )
                        )}
                    </>
                </Div>
            </>
        );
    }

    private getItems(tabs: OrderedSet<PageTabs>): MenuItem[] {
        return tabs
            .map((tab: PageTabs): MenuItem => ({
                disabled: !PageTabsDisplay[tab].component,
                children: PageTabsDisplay[tab].title,
                link: { onClick: () => this.showTabContent(tab) },
                active: this.state.currentTab === tab,
            }))
            .toArray();
    }

    private showTabContent(tab: PageTabs) {
        this.setState({
            showContent: true,
            currentTab: tab,
        });
    }
}
