import { CoverageLevel, ErrorLevel, PAHNotified, PolicyStatus } from "./Types";

/**
 *
 */
export const ErrorLevelDisplay: Readonly<Record<ErrorLevel, string>> = {
    [ErrorLevel.CRITICAL]: "Critical",
    [ErrorLevel.WARNING]: "Warning",
};

/**
 *
 */
export const CoverageLevelDisplay: Readonly<Record<CoverageLevel, string>> = {
    [CoverageLevel.FULL_FUNCTION]: "Full Functional",
    [CoverageLevel.THREE_COMPONENTS]: "Three Components",
};

/**
 *
 */
export const PAHNotifiedDisplay: Readonly<Record<PAHNotified, string>> = {
    [PAHNotified.TRUE]: "Yes",
    [PAHNotified.FALSE]: "No",
};

/**
 *
 */
export const PolicyStatusDisplay: Readonly<Record<PolicyStatus, string>> = {
    [PolicyStatus.APPROVED]: "Active",
    [PolicyStatus.CANCELED]: "Canceled",
    [PolicyStatus.ERROR]: "Error",
    [PolicyStatus.RECEIVED]: "Received",
    [PolicyStatus.EXPIRED]: "Expired",
    [PolicyStatus.DUPLICATED]: "Duplicated",
};
