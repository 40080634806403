import * as React from "react";
import { connect } from "react-redux";
import { Accounts } from "../../../../store/Accounts/Types";
import { PageTabs, Pages, SubPages } from "../../../../store/AppDisplays";
import { BookingPeriods } from "../../../../store/BookingPeriod/Types";
import { Brands } from "../../../../store/Brand/Types";
import { BusinessRuleViolations } from "../../../../store/BusinessRuleViolations/Types";
import { ClaimErrors } from "../../../../store/ClaimErrors/Types";
import { ClaimInvoiceBookings } from "../../../../store/ClaimMatch/Types";
import { Claims } from "../../../../store/Claims/Types";
import { Excassos } from "../../../../store/Excassos/Types";
import { Files } from "../../../../store/Files/Types";
import { GeneralLedgers } from "../../../../store/GeneralLedgers/Types";
import { Incassos } from "../../../../store/Incassos/Types";
import { Invoices } from "../../../../store/Invoice/Types";
import { Mandates } from "../../../../store/Mandates/Types";
import { ManualBookingsResults } from "../../../../store/ManualBooking/Types";
import { Memos } from "../../../../store/Memos/Types";
import { Models } from "../../../../store/Models/Types";
import { Payments } from "../../../../store/PartnerPayments/Types";
import { Partners } from "../../../../store/Partners/Types";
import { Policies } from "../../../../store/Policies/Types";
import { Products } from "../../../../store/Product/Types";
import { ReduxState, RemoteErrors } from "../../../../store/ReduxState";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { Statements } from "../../../../store/Statements/Types";
import { Tariffs } from "../../../../store/Tariffs/Types";
import { getErrorValidationMessage } from "../../../utils/ErrorMessage";

import {
    Div,
} from "../../../../components/core/03-base/Div";
import { ThemePalette, ThemeShadowSizes } from "../../../../theme/_Types";
import { CollectiveInvoices } from "../../../../store/CollectiveInvoice/Types";
import { Results as AccountResults } from "../../09-views/00-blocks/Accounts/Results";
import { Results as ClaimResults } from "../../09-views/00-blocks/Claims/Results";
import { Results as PartnerResults } from "../../09-views/00-blocks/Partners/Results";
import { Results as PolicyResults } from "../../09-views/00-blocks/Policies/Results";
import { Results as StatementResults } from "../00-blocks/Accounts/Statements/Results";
import { Results as BookingPeriodResults } from "../00-blocks/BookingPeriod/Results";
import { Results as BrandResults } from "../00-blocks/Brands/Results";
import { Results as BusinessRuleViolationResults } from "../00-blocks/BusinessRuleViolations/Results";
import { Results as ClaimErrorResults } from "../00-blocks/ClaimErrors/Results";
import { Results as ClaimBookResults } from "../00-blocks/ClaimMatch/ResultsBook";
import { Results as ClaimInvoiceManualBookResults } from "../00-blocks/ClaimMatch/ResultsManualBook";
import { Results as PartnerInvoiceManualBookResults } from "../00-blocks/Partners/ResultsManualBookPartnerInvoices";
import { Results as CollectiveInvoiceResults } from "../00-blocks/CollectiveInvoice/Results";
import { Results as CollectiveInvoiceBookResults } from "../00-blocks/CollectiveInvoice/ResultsBook";
import { Results as CollectiveInvoiceManualBookResults } from "../00-blocks/CollectiveInvoice/ResultsManualBook";
import { Results as ExcassoBookResults } from "../00-blocks/Excassos/ResultsBook";
import { Results as FileResults } from "../00-blocks/Files/Results";
import { Results as GeneralLedgerResults } from "../00-blocks/GeneralLedger/Results";
import { Results as GeneralLedgerBookResults } from "../00-blocks/GeneralLedger/ResultsBook";
import { Results as GeneralLedgerManualBookResults } from "../00-blocks/GeneralLedger/ResultsManualBook";
import { Results as IncassoBookResults } from "../00-blocks/Incassos/ResultsBook";
import { Results as InvoiceResults } from "../00-blocks/Invoice/Results";
import { Results as InvoiceManualBookResults } from "../00-blocks/Invoice/ResultsManualBook";
import { LoadingData } from "../../../../components/core/09-views/00-blocks/LoadingData";
import { Results as MandateResults } from "../00-blocks/Mandates/Results";
import { Results as MBResults } from "../00-blocks/ManualBookings/Results";
import { Results as MemoResults } from "../00-blocks/Memos/Results";
import { Results as ModelResults } from "../00-blocks/Models/Results";
import { Results as PartnerBookResults } from "../00-blocks/Partners/ResultsBook";
import { Results as SelectPartnerResults } from "../00-blocks/Partners/ResultsGenerateInvoice";
import { Results as PartnerManualBookResults } from "../00-blocks/Partners/ResultsManualBook";
import { Results as PaymentResults } from "../00-blocks/Payments/Results";
import { Results as ProductResults } from "../00-blocks/Product/Results";
import { Results as TariffResults } from "../00-blocks/Tariffs/Results";

/**
 *
 */
interface StateProps {
    remoteError?: RemoteErrors;
}

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    currentPage: Pages | SubPages | PageTabs;
    accountId?: string;
    statementId?: string;
    statementLineId?: string;
    partnerId?: string;
    policyId?: string;
    results?: Statements | CollectiveInvoices | Invoices | Accounts | Claims |
    Policies | Partners | Incassos | GeneralLedgers | Files | Excassos | Products | ClaimInvoiceBookings |
    Tariffs | ManualBookingsResults | BookingPeriods | Payments | Mandates | ClaimErrors | Memos | Brands | Models |
    BusinessRuleViolations;
    generateInvoice?: (selectedRows: number[]) => void;
    scope?: RemoteScope;
}

/**
 *
 */
type Props = OwnProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    remoteError: !!p.scope ? getErrorValidationMessage(s.prop("remoteErrors"), p.scope) : undefined,
});

/**
 *
 */
export class ResultComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);
    }

    public getResultsByPage() {
        switch (this.props.currentPage) {
        case Pages.CLAIMS:
            return <ClaimResults results={(this.props.results as Claims)} />;
        case PageTabs.POLICIES_CLAIMS:
            return <ClaimResults results={(this.props.results as Claims)} />;
        case SubPages.MANAGE_BRANDS:
            return <BrandResults results={(this.props.results as Brands)} />;
        case SubPages.MANAGE_MODELS:
            return <ModelResults results={(this.props.results as Models)} />;
        case SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS:
            return <BusinessRuleViolationResults results={(this.props.results as BusinessRuleViolations)} />;
        case PageTabs.CLAIMS_ERRORS:
            return <ClaimErrorResults results={(this.props.results as ClaimErrors)} />;
        case SubPages.ACCOUNTS_DETAIL:
            return (
                <StatementResults
                    results={this.props.results as Statements}
                    accountId={this.props.accountId ? this.props.accountId : ""}
                />
            );
        case Pages.COLLECTIVEINVOICES:
            return (
                <CollectiveInvoiceResults
                    results={this.props.results as CollectiveInvoices}
                />
            );
        case Pages.GENERAL_LEDGER:
            return (
                <GeneralLedgerResults
                    results={this.props.results as GeneralLedgers}
                />
            );
        case SubPages.AUDIT_TRAIL_GL:
            return (
                <GeneralLedgerResults
                    results={this.props.results as GeneralLedgers}
                    asAuditTab
                />
            );
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES:
            return (
                <CollectiveInvoiceBookResults
                    results={this.props.results as CollectiveInvoices}
                    statementLineId={this.props.statementLineId}
                />);
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES:
            return (
                <CollectiveInvoiceManualBookResults
                    results={this.props.results as CollectiveInvoices}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO:
            return (
                <IncassoBookResults
                    results={this.props.results as Incassos}
                    statementLineId={this.props.statementLineId}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO:
            return (
                <ExcassoBookResults
                    results={this.props.results as Excassos}
                    statementLineID={this.props.statementLineId}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS:
            return (
                <ClaimBookResults
                    results={this.props.results as ClaimInvoiceBookings}
                    statementLineId={this.props.statementLineId}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_PARTNER:
            return (
                <PartnerBookResults
                    results={this.props.results as Partners}
                    statementLineId={this.props.statementLineId}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNER:
            return (
                <PartnerManualBookResults
                    results={this.props.results as Partners}
                />
            );
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER:
            return (
                <GeneralLedgerBookResults
                    results={this.props.results as GeneralLedgers}
                    statementLineId={this.props.statementLineId}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER:
            return (
                <GeneralLedgerManualBookResults
                    results={this.props.results as GeneralLedgers}
                />
            );
        case PageTabs.PARTNER_COLLECTIVEINVOICE:
            return (
                <CollectiveInvoiceResults
                    results={this.props.results as CollectiveInvoices}
                    currentTab={PageTabs.PARTNER_COLLECTIVEINVOICE}
                />
            );
        case PageTabs.PARTNER_INVOICE:
            return (
                <InvoiceResults
                    results={this.props.results as Invoices}
                    currentTab={PageTabs.PARTNER_INVOICE}
                />
            );
        case Pages.INDIVIDUALINVOICES:
            return (
                <InvoiceResults
                    results={this.props.results as Invoices}
                />
            );
        case PageTabs.PARTNER_PAYMENTS:
            return (
                <PaymentResults
                    results={this.props.results as Payments}
                    currentTab={PageTabs.PARTNER_PAYMENTS}
                />
            );
        case SubPages.AUDIT_TRAIL_PAYMENTS:
            return (
                <PaymentResults
                    results={this.props.results as Payments}
                    currentTab={PageTabs.PARTNER_PAYMENTS}
                    asAuditTab
                />
            );
        case PageTabs.POLICIES_INVOICES:
            return (
                <InvoiceResults
                    results={this.props.results as Invoices}
                    currentTab={PageTabs.POLICIES_INVOICES}
                />
            );
        case PageTabs.POLICIES_MEMO:
            return (
                <MemoResults
                    results={this.props.results as Memos}
                    currentTab={PageTabs.POLICIES_MEMO}
                    // policyId={this.props.policyId}
                />
            );
        case PageTabs.PARTNER_MANDATES:
            return (
                <MandateResults
                    results={this.props.results as Mandates}
                    currentTab={PageTabs.PARTNER_MANDATES}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES:
            return (
                <InvoiceManualBookResults
                    results={this.props.results as Invoices}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS:
            return (
                <ClaimInvoiceManualBookResults
                    results={this.props.results as Invoices}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES:
            return (
                <PartnerInvoiceManualBookResults
                    results={this.props.results as Invoices}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES}
                />
            );
        case Pages.ACCOUNTS:
            return <AccountResults results={this.props.results as Accounts} />;
        case Pages.TARIFFS:
            return <TariffResults results={this.props.results as Tariffs} />;
        case Pages.PRODUCT:
            return <ProductResults results={this.props.results as Products} />;
        case Pages.POLICIES:
            return <PolicyResults results={this.props.results as Policies} />;
        case Pages.PARTNERS:
            return <PartnerResults results={this.props.results as Partners} />;
        case SubPages.COLLECTIVE_INVOICE_GENERATION:
            return (
                <SelectPartnerResults
                    results={(this.props.results as Partners)}
                    generateInvoice={this.props.generateInvoice}
                />
            );
        case Pages.FILES:
            return <FileResults results={(this.props.results as Files)} />;
        case Pages.MANUAL_BOOKING:
            return <MBResults results={(this.props.results as ManualBookingsResults)} />;
        case Pages.BOOKING_PERIOD:
            return <BookingPeriodResults results={(this.props.results as BookingPeriods)} />;
        case SubPages.AUDIT_TRAIL_POLICIES:
            return (
                <PolicyResults
                    results={(this.props.results as Policies)}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_PRODUCTS:
            return (
                <ProductResults
                    results={(this.props.results as Products)}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_PARTNERS:
            return (
                <PartnerResults
                    results={(this.props.results as Partners)}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_TARIFFS:
            return (
                <TariffResults
                    results={(this.props.results as Tariffs)}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_BOOKING_PERIOD:
            return (
                <BookingPeriodResults
                    results={(this.props.results as BookingPeriods)}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_CI:
            return (
                <CollectiveInvoiceResults
                    results={this.props.results as CollectiveInvoices}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_INVOICE:
            return (
                <InvoiceResults
                    results={this.props.results as Invoices}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_STATEMENT:
            return (
                <StatementResults
                    results={this.props.results as Statements}
                    accountId={this.props.accountId ? this.props.accountId : ""}
                    asAuditTab
                />
            );
        case SubPages.AUDIT_TRAIL_FREEBOOKING:
            return <MBResults results={(this.props.results as ManualBookingsResults)} asAuditTab />;
        }
        return null;
    }

    /**
     *
     */
    public render() {
        return (
            <>
                {
                    (!!this.props.remoteError && this.props.loadingResults) ||
                        (
                            !this.props.results ||
                            !this.props.results.content ||
                            (this.props.results
                                && this.props.results.content
                                && this.props.results.content.length <= 0))
                        ? (
                            <LoadingData
                                loading={this.props.loadingResults}
                                init={!this.props.results}
                                errorMessage={!!this.props.remoteError ? this.props.remoteError.message : undefined}
                            />
                        ) :
                        <Div theme={{ shadow: ThemeShadowSizes.TINY, palette: ThemePalette.CONTRAST_PRIMARY }}>
                            {this.getResultsByPage()}
                        </Div>
                }
            </>
        );
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    undefined,
)(ResultComp);
