import { RefObject, RequestObject } from "../RemoteTypes";
import { JournalEventEntry } from "../JournalEntry/Types";
import { hasValue } from "../Product/Types";
import { GeneralLedger } from "../GeneralLedgers/Types";
import { State } from "../../utils/redux/State";

enum Status {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export interface Account {
    id: number;
    createdAt?: string;
    iban: string;
    name: string;
    status: Status;
    bic: string;
    privateIdentification: string;
    accountOwner: string;
    balance: number;
    currencyCode: string;
    mutationDate: string;
    allBooked: boolean;
    journalEventEntry?: JournalEventEntry; // new
    generalLedger?: GeneralLedger;
}

export interface AccountDetails {
    content: Account;
}

export interface Accounts extends RequestObject {
    content: Account[];
}

export interface AccountRequest {
    iban?: string;
    name?: string;
    bic?: string;
    privateIdentification?: string;
    accountOwner?: string;
    balance?: number;
    currencyCode?: string;
    generalLedger?: RefObject;
}

export const canSubmitAccountForm =
    (account: State<AccountRequest>): boolean => {
        const validMandatoryAccountFields = account.map(f => {
            const mandatoryAccountField = Object.keys(f).filter(k =>
                (k === "iban" && hasValue(f[k]))
                || (k === "name" && hasValue(f[k]))
                || (k === "generalLedger" && hasValue(f[k]))
                || (k === "bic" && hasValue(f[k]))
                || (k === "privateIdentification" && hasValue(f[k]))
                || (k === "accountOwner" && hasValue(f[k])));

            return mandatoryAccountField;
        }).length === 6;
        return validMandatoryAccountFields;
    };
