import React from "react";
import { Button } from "../../../components/core/05-atoms/Button";
import { ThemePalette, ThemeShadowSizes, ThemeAnimations } from "../../../theme/_Types";
import { Spinner } from "../../../components/core/06-molecules/Spinner";
import { IconNames } from "../../../components/core/05-atoms/Icon";

/**
 *
 */
interface Props {
    isLoading: boolean;
    loadDownload: () => void;
    canDownloadFile: boolean;
}

/**
 *
 * @param props
 */
export const DownloadButtonCell: React.FC<Props> = (props) => (
    props.isLoading ? (
        <div style={{ paddingRight: "5px" }}>
            <Spinner active />
        </div>
    ) : (
        <Button
            icon={IconNames.DOWNLOAD}
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
                shadow: ThemeShadowSizes.TINY,
                animation: ThemeAnimations.GROW_IN,
            }}
            link={{ onClick: props.loadDownload }}
            disabled={props.canDownloadFile}
        />
    )
);
