import { State } from "../../utils/redux/State";

import { Message } from "../Message/Types";
import { hasRequiredFields } from "../Product/Types";
import { RequestObject } from "../RemoteTypes";

export interface ReportAccountingGenerate extends RequestObject {
    content: Message;
}

export type ReportForm = Readonly<Partial<{
    dateFrom: string;
    dateTo: string;
    referenceDate: string;
    proxyId: number;
    type: string;
}>>;

export interface Authorizer {
    id: string;
    name: string;
}

export enum ReportFormScope {
    NVGA = "NVGA",
    INSURANCE_OVERVIEW = "INSURANCE_OVERVIEW",
    OUTSTANDING_ITEMS = "OUTSTANDING_ITEMS",
}

export interface Authorizers extends RequestObject {
    content: Authorizer[];
}

export const getReportForm =
(reportForm: State<ReportForm>): ReportForm =>
    reportForm.map(f => ({
        ...f,
    }));

export const requiredFields = (r?: ReportFormScope): Array<(keyof ReportForm)> => {
    switch (r) {
    case ReportFormScope.NVGA:
        return ["dateFrom", "dateTo", "proxyId"];
    case ReportFormScope.INSURANCE_OVERVIEW:
        return ["dateFrom", "dateTo"];
    case ReportFormScope.OUTSTANDING_ITEMS:
        return ["referenceDate"];
    default:
        return [];
    }
};

export const canSubmitReportForm =
(reportForm: State<ReportForm>): boolean => reportForm.map(f => hasRequiredFields(
    f,
    ["type", ...requiredFields(f.type as ReportFormScope)],
));

export const ReportFormToParams = (f: ReportForm) => f;
