import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { GeneralLedger } from "../../../../../store/GeneralLedgers/Types";


interface OwnProps {
    details: GeneralLedger;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="GL id"
                            asSummary
                            value={this.props.details.id}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Name"
                            asSummary
                            value={this.props.details.name}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.accountCode}
                            label="Account code"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.freebookingOnClaimInvoice === true ? "Yes" : "No"}
                            label="Free booking on claim invoice"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.freebookingOnCollectiveInvoice === true ? "Yes" : "No"}
                            label="Free booking on collective invoice"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.freebookingOnPartner === true ? "Yes" : "No"}
                            label="Free booking on partner"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.freebookingOnPolicyInvoice === true ? "Yes" : "No"}
                            label="Free booking on policy invoice"
                            asSummary
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}

