import * as React from "react";
import { asThemeConsumer } from "../../../theme/Theme";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";

interface Props
    extends ThemeConsumerProps {
    onSubmit?: () => void;
    isValid?: boolean;
}

const FormComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-o-form",
    );

    return !props.children
        ? null
        : (
            <form
                className={clazzName}
                onSubmit={(e) => {
                    e.preventDefault();
                    if (props.isValid !== false && props.onSubmit) {
                        props.onSubmit();
                    }
                }}
            >
                {props.children}
            </form>
        );
};

/**
 *
 */
export const Form = asThemeConsumer(FormComp);
