import { SequenceType } from "./Types";

/**
 *
 */
export const MandateSequenceTypeDisplay: Readonly<Record<SequenceType, string>> = {
    [SequenceType.FRST]: "First",
    [SequenceType.RCUR]: "Recurring",
    [SequenceType.FNAL]: "Final",
    [SequenceType.OOFF]: "One off",
};
