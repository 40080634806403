/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import { Models } from "../../../../../store/Models/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { clearTariffModelFilter } from "../../../../../store/Tariffs/FilterType";
import { TariffFilters } from "../../../../../store/Tariffs/Types";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { FilterTariffModelModal } from "../Models/FilterTariffModelModal";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ThemeSize, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
type setFilterFn = (filters: Partial<TariffFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof TariffFilters>) => void;
type OnChange =
    <K extends keyof TariffFilters>(key: K, value: TariffFilters[K]) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: TariffFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: TariffFilters;
    resultsModels: Models;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
    loadModelResults: () => void;
    clearModelResults: () => void;
    selectModel: (modalId: string, onChange: OnChange, id?: string, name?: string)
    => void;
    clearModelFilter: () => void;
}

interface State {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    resultsModels: s.prop("remote").prop(RemoteScope.MODEL_RESULTS) as Models,
    searchEnabled: hasSearchableFilters(s, FilterScope.TARIFFS, p.defaultFilters),
    filters: getFilters(s, FilterScope.TARIFFS) as TariffFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.TARIFFS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.TARIFFS));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.TARIFF_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.TARIFF_RESULTS)),
        dispatch(remoteClearError(RemoteScope.TARIFF_RESULTS))
    ),
    loadModelResults: () => dispatch(remoteTrigger(RemoteScope.MODEL_RESULTS, { skip: 0 })),
    clearModelResults: () => (
        dispatch(remoteClearResponse(RemoteScope.MODEL_RESULTS)),
        dispatch(remoteClearError(RemoteScope.MODEL_RESULTS))
    ),
    selectModel:
            (modalId: string, onChange: OnChange, id?: string, name?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <FilterTariffModelModal
                                    modalID={modalId}
                                    modelNumber={id}
                                    onChange={onChange}
                                    modelName={name}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
    clearModelFilter: () => dispatch(clearTariffModelFilter()),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, State> {


    public constructor(props: Props) {
        super(props);

        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.maxKWValidation = this.maxKWValidation.bind(this);
        this.modelIdValidation = this.modelIdValidation.bind(this);
        this.onChangeMaxKW = this.onChangeMaxKW.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }


    public componentWillUnmount() {
        this.props.clearResults();
        this.props.clearModelResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ sm: 12, md: 6 }}
                    >
                        <FormField
                            label="Passenger vehicle"
                            placeholder="Make a choice"
                            options={[
                                {label: "Yes", value: "true"},
                                {label: "No", value: "false"},
                            ]}
                            onChange={(value?: string | number) =>
                                this.onChange("passengerVehicle",
                                    !!value && (value.toString() === "true" || value.toString() === "false") ? value.toString() === "true" : undefined)}
                            value={this.props.filters.passengerVehicle === undefined ?
                                undefined : this.props.filters.passengerVehicle.toString()}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Passenger vehicle provided")}
                        />
                    </Grid>
                    <Grid
                        size={{ sm: 12, md: 6 }}
                    >
                        <FormField
                            label="Commercial vehicle"
                            placeholder="Make a choice"
                            options={[
                                {label: "Yes", value: "true"},
                                {label: "No", value: "false"},
                            ]}
                            onChange={(value?: string | number) =>
                                this.onChange("commercialVehicle",
                                    !!value && (value.toString() === "true" || value.toString() === "false") ? value.toString() === "true" : undefined)}
                            value={this.props.filters.commercialVehicle === undefined ?
                                undefined : this.props.filters.commercialVehicle.toString()}
                            validationFunction={
                                (value: string | number) =>
                                    hasValueValidation(value.toString(),
                                        "Commercial vehicle provided")}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Max KW"
                            type={"number"}
                            onChange={this.onChangeMaxKW}
                            value={this.props.filters?.maxKW ?? ""}
                            validationFunction={this.maxKWValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <div
                            style={{
                                position: "relative",
                            }}
                        >
                            <FormField
                                label="Model"
                                placeholder={"Make a choice"}
                                readonly
                                validationFunction={this.modelIdValidation}
                                value={this.props.filters.modelName}
                            />
                            {this.props.filters.modelId ?
                                <div
                                    style={{
                                        position: "absolute",
                                        right: "50px",
                                        top: "50%",
                                        transform: "translateY(-50%) scale(0.45)",
                                    }}
                                >
                                    <Button
                                        style={{fontSize: "1.5em"}}
                                        icon={IconNames.TIMES}
                                        theme={{
                                            palette: ThemePalette.BRAND_ACCENT,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                        link={{ onClick: this.props.clearModelFilter }}
                                    />
                                </div> : <></>
                            }
                            <div
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                }}
                            >
                                <Button
                                    icon={this.props.filters.modelId ? IconNames.EDIT : IconNames.PLUS}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    link={{
                                        onClick: () =>
                                            this.props.selectModel(
                                                "select-model--modal",
                                                this.onChange,
                                                this.props.filters.modelId?.toString(),
                                                this.props.filters.modelName,
                                            ),
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private maxKWValidation(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Max KW provided",
        );
    }

    private modelIdValidation(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Model provided",
        );
    }

    private onChangeMaxKW(value?: string | number) {
        return this.onChange("maxKW", value ? Number(value) : undefined);
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
        this.props.loadModelResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof TariffFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof TariffFilters>(key: K, value: TariffFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
