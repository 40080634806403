import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ReduxState } from "../../../../../store/ReduxState";
import { CreatePolicy } from "./CreatePolicy";

interface Params {
    policyId: string;
}

interface StateProps {
    params: Params;
}

type Props = StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
});

/**
 *
 * @param s
 */

/**
 *
 */
export class EditPolicyComp
    extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <CreatePolicy edit policyId={this.props.params.policyId} />
        );
    }
}

/**
 *
 */
export const EditPolicy = connect(
    mapStateToProps,
    {},
)((props: Props) => <EditPolicyComp {...props}/>);
