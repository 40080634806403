/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AxiosResponse } from "axios";

export interface VersionRequest {
    version?: string;
    commit?: string;
    build_start_time?: string;
}

export const mapVersionRequest =
    (resp: AxiosResponse): VersionRequest => resp.data;
