import "core-js/stable";
import "regenerator-runtime/runtime";

import "./index.scss";

import { I18n } from "@aws-amplify/core";
import {Amplify} from "@aws-amplify/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
    BrowserRouter as Router,
} from "react-router-dom";

import { App } from "./App";
import { HomeNavBar } from "./App/components/09-views/00-blocks/NavBar";
import { SideBar } from "./App/components/09-views/Sidebar";
import { FlexWrapperFocus } from "./components/core/03-base/FlexWrapper";
import * as serviceWorker from "./serviceWorker";
import { createReduxStore } from "./store/ReduxStore";
import { ErrorBoundary } from "./utils/Boundary";
import { FlexColumn } from "./components/core/03-base/Flex/Column";
import { FlexRow } from "./components/core/03-base/Flex/Row";
import { ThemePalette, Themes } from "./theme/_Types";
import { BreakpointConsumer } from "./utils/Breakpoints";
import { ensure } from "./utils/Optional";
import { MaryProvider } from "./utils/redux/MaryProvider";
import { BaseProvider } from "./components/core/03-base/Base";
import { I18nLanguage, I18nMarylocales } from "./utils/translations/I18n";
import { VerifyContextWrapper } from "./App/utils/VerifyContextWrapper";
import { I18nAmplifyAuthTranslationsNL, I18nMaryTranslationsNL } from "./utils/translations/nl/I18n";
import { EnvIndicatorBar } from "./components/core/06-molecules/EnvIndicatorBar";

//
const elm = document.getElementById("root") as HTMLElement;

/**
 *
 */
enum ConfigProps {
    COGNITO_USER_POOL_ID = "data-cognito-userpoolid",
    COGNITO_APP_CLIENT_ID = "data-cognito-appclientid",
    COGNITO_REGION = "data-cognito-region",
    API_ENDPOINT = "data-api-endpoint",
}

I18n.configure(I18nMarylocales);
I18n.putVocabularies({
    nl: {
        ...I18nAmplifyAuthTranslationsNL,
        ...I18nMaryTranslationsNL,
        "app.app-title": "Extended Warranty System",
        "app.general": "Algemeen",
        "app.yes": "Ja",
        "app.no": "Nee",
        "app.pdf": "PDF",
        "app.csv": "CSV",
        "app.date-of-update": "Update datum",
        "app.make-a-choice": "Maak een keuze",
        "app.validation.has-value-message": "{{label}} ingevuld.",
        "app.06-molecules.downloadpdfbutton.no-download": "Geen download beschikbaar",
        "app.go-to-link": "Ga naar link",
        "app.home.title": "Welkom,",
        "app.home.description": "U kunt alles doen wat te maken heeft met het beheer van de API's",
        "app.remote-error": "Claude kan niet aan uw aanvraag voldoen.",
        "app.09-views.00-blocks.auth-download-successr": "Het bestand is gedownload.",
        "app.fatal-remote-error": "Zelfs Claude weet niet wat er mis is!",
        "app.remote-init-error": "Geen API beschikbaar.",
        "app.no-id-message-error": "Geen id beschikbaar.",
        "app.remote-init-message":
            "Claude kan geen verbinding met de API maken.",
        "app.notification": "Notificatie",
        "app.warning": "Waarschuwing",
        "app.store.getremoteerrormessage.status": "Status:",
        "app.store.getremoteerrormessage.error": "Foutmelding:",
        "app.not-authorized.title": "Niet geautoriseerd.",
        "app.not-authorized.message":
            "U bent niet geautoriseerd, hierdoor kunnen wij geen verbinding met de API maken, ververs de pagina!",
    },
});

Amplify.configure({
    Auth: {
        region: elm.getAttribute(ConfigProps.COGNITO_REGION),
        userPoolId: elm.getAttribute(ConfigProps.COGNITO_USER_POOL_ID),
        userPoolWebClientId: elm.getAttribute(ConfigProps.COGNITO_APP_CLIENT_ID),
    },
});

const store = createReduxStore({
    apiEndpoint: ensure(elm.getAttribute(ConfigProps.API_ENDPOINT) || undefined),
});

/**
 *
 */
const Root: React.FunctionComponent<{}> = () => (
    <BaseProvider
        theme={Themes.VWPFS}
        language={I18nLanguage.ENGLISH}
    >
        <ErrorBoundary>
            <Provider store={store}>
                <Router>
                    <MaryProvider
                        flexDefaultFocus={FlexWrapperFocus.SIDEBAR_INACTIVE}
                    >
                        <SideBar />
                        <FlexColumn isPage >
                            <BreakpointConsumer>
                                {(value) => {
                                    if (!value.breakpointAtSM) {
                                        return (
                                            <FlexRow isFixed>
                                                <HomeNavBar />
                                            </FlexRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                }}
                            </BreakpointConsumer>
                            <EnvIndicatorBar />
                            <FlexRow
                                theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                            >
                                <FlexColumn hasScrollable>
                                    <VerifyContextWrapper>
                                        <App />
                                    </VerifyContextWrapper>
                                </FlexColumn>
                            </FlexRow>
                        </FlexColumn>
                    </MaryProvider>
                </Router>
            </Provider>
        </ErrorBoundary>
    </BaseProvider>
);

ReactDOM.render(<Root />, elm);
serviceWorker.unregister();
