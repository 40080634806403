import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { BookingPeriodMonthsDisplay } from "../../../../../store/BookingPeriod/Displays";
import { BookingPeriod } from "../../../../../store/BookingPeriod/Types";

interface OwnProps {
    details: BookingPeriod;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Starting date"
                            asSummary
                            value={this.props.details.startingDate}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Ending date"
                            asSummary
                            value={this.props.details.endingDate}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.status}
                            label="Status"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={BookingPeriodMonthsDisplay[this.props.details.month]}
                            label="Month"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.year}
                            label="Year"
                            asSummary
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}


