
import * as React from "react";
import { connect } from "react-redux";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { Form } from "../../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../../components/core/07-organisms/Form/Field";
import { hasDateValueValidation,
    hasValueValidation } from "../../../../../../components/core/07-organisms/Form/Field.utils";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { updateFilters, clearFilters } from "../../../../../../store/FilterActions";
import { hasSearchableFilters, FilterScope, getFilters } from "../../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { StatementFilters } from "../../../../../../store/Statements/Types";
import { ThemePalette } from "../../../../../../theme/_Types";

/**
 *
 */
type setFilterFn = (filters: Partial<StatementFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof StatementFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: StatementFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: StatementFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.STATEMENTS, p.defaultFilters),
    filters: getFilters(s, FilterScope.STATEMENTS) as StatementFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.STATEMENTS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.STATEMENTS));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.STATEMENT_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.STATEMENT_RESULTS)),
        dispatch(remoteClearError(RemoteScope.STATEMENT_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.creationDateValidationMessage = this.creationDateValidationMessage.bind(this);
        this.mutationDateValidationMessage = this.mutationDateValidationMessage.bind(this);
        this.stmtIdValidationMessage = this.stmtIdValidationMessage.bind(this);
        this.onChangeCreationDate = this.onChangeCreationDate.bind(this);
        this.onChangeMutationDate = this.onChangeMutationDate.bind(this);
        this.onChangeStmtId = this.onChangeStmtId.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Creation date"
                            onChange={this.onChangeCreationDate}
                            value={this.props.filters?.creationDate ?? ""}
                            type="date"
                            validationFunction={this.creationDateValidationMessage}
                        />
                    </Grid>

                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Mutation date"
                            onChange={this.onChangeMutationDate}
                            value={this.props.filters?.mutationDate ?? ""}
                            type="date"
                            validationFunction={this.mutationDateValidationMessage}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Statement Id"
                            onChange={this.onChangeStmtId}
                            value={this.props.filters?.stmtId ?? ""}
                            validationFunction={this.stmtIdValidationMessage}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private creationDateValidationMessage(value: string | number) {
        return hasDateValueValidation(
            value.toString(),
            "Creation date provided",
        );
    }

    private mutationDateValidationMessage(value: string | number) {
        return hasDateValueValidation(
            value.toString(),
            "Mutation date provided",
        );
    }

    private stmtIdValidationMessage(value: string | number) {
        return hasValueValidation(value.toString(), "Statement Id provided");
    }

    private onChangeCreationDate(value?: string | number) {
        return this.onChange("creationDate", value?.toString() ?? "");
    }

    private onChangeMutationDate(value?: string | number) {
        return this.onChange("mutationDate", value?.toString() ?? "");
    }

    private onChangeStmtId(value?: string | number) {
        return this.onChange("stmtId", value?.toString() ?? "");
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof StatementFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof StatementFilters>(key: K, value: StatementFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
