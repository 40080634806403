import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../store/AppDisplays";
import { Filter } from "../../../02-pages/Filter";
import * as InvoiceTypes from "../../../../../../store/Invoice/Types";
import { isRemoteLoading, ReduxState } from "../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Results } from "../../../02-pages/Results";
import { InvoiceType } from "../../../../../../store/Invoice/Types";
import { ClaimInvoiceStatus } from "../../../../../../store/ClaimMatch/Types";
import { Div } from "../../../../../../components/core/03-base/Div";

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: InvoiceTypes.Invoices;
}

type Props = StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.INVOICE_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.INVOICE_RESULTS) as InvoiceTypes.Invoices,
});

/**
 *
 * @param props
 */
export class PartnerInvoicesComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES}
                    defaultFilters={{
                        invoiceStatus: `${ClaimInvoiceStatus.UNPAID},${ClaimInvoiceStatus.PARTIALLY_PAID}`,
                        invoiceType: InvoiceType.PartnerInvoice,
                    }}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES}
                    scope={RemoteScope.INVOICE_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const PartnerInvoices = connect(
    mapStateToProps,
    {},
)(PartnerInvoicesComp);
