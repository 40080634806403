
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../components/core/05-atoms/Icon";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import * as PartnerType from "../../../../store/Partners/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";

interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: PartnerType.Partners;
    user?: UserInfo;
}

type Props = StateProps;

const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.PARTNER_RESULTS),
    results: s.prop("remote").prop(RemoteScope.PARTNER_RESULTS) as PartnerType.Partners,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

export class PartnersComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.PARTNERS].title}
                                description={PageDisplay[Pages.PARTNERS].description}
                            />
                        </Grid>
                        {this.props.user?.userRole === UserRoles.EW_ADMIN &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <NavLink
                                        to={"/partners/create"}
                                        exact
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            className="scl-a-btn--big"
                                        >
                                            {SubPageDisplay[SubPages.PARTNER_CREATE].title}
                                        &nbsp;
                                        &nbsp;
                                            <Icon name={IconNames.PLUS} />
                                        </Button>
                                    </NavLink>
                                </Div>
                            </Grid>}
                    </Div>
                    <Filter
                        loadingFilters={this.props.loadingFilters}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.PARTNERS}
                    />
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.PARTNERS}
                        scope={RemoteScope.PARTNER_RESULTS}
                    />
                </Container>
            </Page>
        );
    }
}

export const Partners = connect(
    mapStateToProps,
    {},
)(PartnersComp);
