import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { State } from "../../utils/redux/State";
import { PremiumPolicy } from "./Types";
import { hasRequiredFields } from "../Product/Types";
import { RemoteScope } from "../RemoteTypes";

/**
 *
 */
export type InitUpdatePolicyPremiumAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_PREMIUM_POLICY> & {}
>;

/**
 *
 *
 */
export const initUpdatePolicyPremium =
    (): InitUpdatePolicyPremiumAction => ({
        type: ActionTypeKeys.INIT_UPDATE_PREMIUM_POLICY,
    });

/**
 *
 * @param s
 * @param _a
 */
export const initUpdatePolicyPremiumReducer:
ReducerFn<InitUpdatePolicyPremiumAction> =
    (s, _a) => {
        const policyDetails = s.prop("remote").prop(RemoteScope.POLICY_DETAILS);
        return (
            s.setProp(
                "policyPremiumRequest",
                s.prop("policyPremiumRequest").update((_old) => policyDetails?.content.policyPremium ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdatePolicyPremiumAction = Readonly<
Action<ActionTypeKeys.UPDATE_PREMIUM_POLICY> & {
    policyPremium?: Partial<PremiumPolicy>;
}
>;

/**
 *
 *
 */
export const updatePolicyPremium =
    (policyPremium: PremiumPolicy): UpdatePolicyPremiumAction => ({
        type: ActionTypeKeys.UPDATE_PREMIUM_POLICY,
        policyPremium: policyPremium,
    });

/**
 *
 * @param s
 * @param a
 */
export const updatePolicyPremiumReducer:
ReducerFn<UpdatePolicyPremiumAction> =
    (s, a) => (
        s.setProp(
            "policyPremiumRequest",
            s.prop("policyPremiumRequest").updateWith(
                a.policyPremium ? a.policyPremium : {},
            ),
        )
    );

/**
 *
 */
export type ClearPolicyPremiumAction = Readonly<
Action<ActionTypeKeys.CLEAR_PREMIUM_POLICY>
>;

export const clearPolicyPremium:
() => ClearPolicyPremiumAction =
    () => ({
        type: ActionTypeKeys.CLEAR_PREMIUM_POLICY,
    });

/**
 *
 */
export const clearPolicyPremiumReducer:
ReducerFn<ClearPolicyPremiumAction> =
    (s, _a) => (
        s.setProp(
            "policyPremiumRequest",
            State.create<PremiumPolicy>(() => ({})),
        )
    );

export const canSubmitUpdatePolicyPremiumForm =
    (policyPremium: State<PremiumPolicy>): boolean => {
        const validMandatoryPolicyPremiumFields =
            policyPremium.map(f => hasRequiredFields(
                f,
                [
                    "maxKW",
                    "premium",
                    "tax",
                    "netPremium",
                    "cost",
                    "netPremiumExCost",
                    "commissionVWPFS",
                    "netMij",
                    "frontier",
                    "insurer",
                    "signCommitteeDamage",
                    "incassoProvision",
                ],
            ));
        return (
            validMandatoryPolicyPremiumFields
        );
    };
