import { AxiosResponse } from "axios";
import { Incassos, IncassoFilters } from "./Types";

export const mapIncassoRequests =
    (resp: AxiosResponse): Incassos => resp.data as Incassos;

/**
 *
 */
export const IncassoFiltersToParams = (f: IncassoFilters) => ({
    ...f,
});
