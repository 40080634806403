
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { UserInfo } from "../../../../../store/AppTypes";
import { PartnerDetails } from "../../../../../store/Partners/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { DetailView } from "./component/DetailView";
import { Container } from "../../../../../components/core/03-base/Container";

interface Params {
    partnerId: string;
}

interface StateProps {
    params: Params;
    details: PartnerDetails;
    loading: Boolean;
    user?: UserInfo;
}

interface DispatchProps {
    loadDetails?: (id: string) => void;
    clearDetails?: () => void;
}

export type Props = DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    details: s.prop("remote").prop(RemoteScope.PARTNER_DETAILS) as PartnerDetails,
    loading: isRemoteLoading(s, RemoteScope.PARTNER_DETAILS),
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadDetails: (id: string) => dispatch(remoteTrigger(RemoteScope.PARTNER_DETAILS, {
        id: id,
    })),
    clearDetails: () => (
        dispatch(remoteClearResponse(RemoteScope.PARTNER_DETAILS)),
        dispatch(remoteClearError(RemoteScope.PARTNER_DETAILS))
    ),
});

/**
 *
 */
export class DetailComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

    }

    public componentDidMount() {
        this.props.loadDetails?.(this.props.params.partnerId);
    }

    public componentWillUnmount() {
        this.props.clearDetails?.();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <DetailView {...this.props} />
                </Container>
            </Page>
        );
    }
}

export const Detail = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <DetailComp {...props}/>);
