/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import { PolicyStatusDisplay } from "../../../../../store/Policies/Displays";
import { PolicyFilters, PolicyStatus } from "../../../../../store/Policies/Types";
import { RemoteScope } from "../../../../../store/RemoteTypes";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { hasLicensePlateValidation, hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { ThemePalette } from "../../../../../theme/_Types";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { formatLicensePlate, isValidLicensePlate, parseLicenseplate } from "../../../../../utils/LicensePlate";

/**
 *
 */
type setFilterFn = (filters: Partial<PolicyFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof PolicyFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: PolicyFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: PolicyFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.POLICIES, p.defaultFilters),
    filters: getFilters(s, FilterScope.POLICIES) as PolicyFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.POLICIES)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.POLICIES));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.POLICY_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.POLICY_RESULTS)),
        dispatch(remoteClearError(RemoteScope.POLICY_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.initAndClearFilters = this.initAndClearFilters.bind(this);
        this.policyNumberValidation = this.policyNumberValidation.bind(this);
        this.productNumberValidation = this.productNumberValidation.bind(this);
        this.chassisNumberValidation = this.chassisNumberValidation.bind(this);
        this.partnerNumberValidation = this.partnerNumberValidation.bind(this);
        this.partnerNameValidation = this.partnerNameValidation.bind(this);
        this.statusValidation = this.statusValidation.bind(this);
        this.onChangePolicyNumber = this.onChangePolicyNumber.bind(this);
        this.onChangeLicensePlate = this.onChangeLicensePlate.bind(this);
        this.onChangeProductNumber = this.onChangeProductNumber.bind(this);
        this.onChangeChassisNumber = this.onChangeChassisNumber.bind(this);
        this.onChangePartnerNumber = this.onChangePartnerNumber.bind(this);
        this.onChangePartnerName = this.onChangePartnerName.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Policy number"
                            onChange={this.onChangePolicyNumber}
                            value={
                                this.props.defaultFilters?.policyNumber
                                    ? this.props.defaultFilters.policyNumber :
                                    this.props.filters && this.props.filters.policyNumber}
                            disabled={!!(this.props.defaultFilters && this.props.defaultFilters.policyNumber)}
                            readonly={!!(this.props.defaultFilters && this.props.defaultFilters.policyNumber)}
                            validationFunction={this.policyNumberValidation}
                        />
                    </Grid>

                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            type="licensePlate"
                            label="License plate"
                            onChange={this.onChangeLicensePlate}
                            value={isValidLicensePlate((this.props.defaultFilters?.licensePlate ?? this.props.filters?.licensePlate))
                                ? formatLicensePlate((this.props.defaultFilters?.licensePlate ?? this.props.filters?.licensePlate))
                                : (this.props.defaultFilters?.licensePlate ?? this.props.filters?.licensePlate)?.toUpperCase()
                            }
                            disabled={!!(this.props.defaultFilters?.licensePlate)}
                            readonly={!!(this.props.defaultFilters?.licensePlate)}
                            validationFunction={
                                (value: string | number) =>
                                    hasLicensePlateValidation(value,
                                        "License plate provided") ?? ""}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Product number"
                            onChange={this.onChangeProductNumber}
                            value={
                                this.props.defaultFilters?.productNumber
                                    ? this.props.defaultFilters.productNumber :
                                    this.props.filters && this.props.filters.productNumber}
                            disabled={!!(this.props.defaultFilters && this.props.defaultFilters.productNumber)}
                            readonly={!!(this.props.defaultFilters && this.props.defaultFilters.productNumber)}
                            validationFunction={this.productNumberValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Chassis number"
                            onChange={this.onChangeChassisNumber}
                            value={
                                this.props.defaultFilters?.chassisNumber
                                    ? this.props.defaultFilters.chassisNumber :
                                    this.props.filters && this.props.filters.chassisNumber}
                            disabled={!!(this.props.defaultFilters && this.props.defaultFilters.chassisNumber)}
                            readonly={!!(this.props.defaultFilters && this.props.defaultFilters.chassisNumber)}
                            validationFunction={this.chassisNumberValidation}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Policy holder number"
                            onChange={this.onChangePartnerNumber}
                            value={
                                this.props.defaultFilters?.partnerNumber
                                    ? this.props.defaultFilters.partnerNumber :
                                    this.props.filters && this.props.filters.partnerNumber}
                            disabled={!!(this.props.defaultFilters && this.props.defaultFilters.partnerNumber)}
                            readonly={!!(this.props.defaultFilters && this.props.defaultFilters.partnerNumber)}
                            validationFunction={this.partnerNumberValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Policy holder name"
                            onChange={this.onChangePartnerName}
                            value={
                                this.props.defaultFilters?.partnerName
                                    ? this.props.defaultFilters.partnerName :
                                    this.props.filters && this.props.filters.partnerName}
                            disabled={!!(this.props.defaultFilters && this.props.defaultFilters.partnerName)}
                            readonly={!!(this.props.defaultFilters && this.props.defaultFilters.partnerName)}
                            validationFunction={this.partnerNameValidation}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Status"
                            onChange={this.onChangeStatus}
                            value={
                                this.props.defaultFilters?.policyStatus
                                    ? this.props.defaultFilters.policyStatus :
                                    this.props.filters && this.props.filters.policyStatus}
                            placeholder="Make a choice"
                            options={Object.keys(PolicyStatus).map((p) => ({
                                label: PolicyStatusDisplay[p] as string,
                                value: p,
                            }))}
                            validationFunction={this.statusValidation}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initAndClearFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private policyNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Policy number provided");
    }

    private onChangePolicyNumber(value?: string | number) {
        return this.onChange("policyNumber", value ? value.toString() : "");
    }

    private onChangeLicensePlate(value?: string | number) {
        return this.onChange("licensePlate",  isValidLicensePlate(value?.toString())
            ? parseLicenseplate(value?.toString())
            ?? (value?.toString().toUpperCase() ?? "")
            : (value?.toString().toUpperCase() ?? ""));
    }

    private productNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Product number provided");
    }

    private onChangeProductNumber(value?: string | number) {
        return this.onChange("productNumber", value ? value.toString() : "");
    }

    private chassisNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Chassis number provided");
    }

    private onChangeChassisNumber(value?: string | number) {
        return this.onChange("chassisNumber", value ? value.toString() : "");
    }

    private partnerNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Policy holder number provided");
    }

    private onChangePartnerNumber(value?: string | number) {
        return this.onChange("partnerNumber", value ? value.toString() : "");
    }

    private partnerNameValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Policy holder name provided");
    }

    private onChangePartnerName(value?: string | number) {
        return this.onChange("partnerName", value ? value.toString() : "");
    }

    private statusValidation(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Status provided",
        );
    }

    private onChangeStatus(value?: string | number) {
        return this.onChange("policyStatus", value ? value.toString() as PolicyStatus : undefined);
    }

    private initFilters() {
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private initAndClearFilters() {
        this.clearFilters();
        this.initFilters();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof PolicyFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof PolicyFilters>(key: K, value: PolicyFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
