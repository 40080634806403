import { RequestObject } from "../RemoteTypes";
import { State } from "../../utils/redux/State";
import { hasRequiredFields } from "../Product/Types";

export type Invoices = Readonly<Partial<{
}>>;

export type Payments = Readonly<Partial<{
    paymentId: number;
    date: string;
    amount: number;
    invoiceNumber: string;
    statementId: string;
}>>;

export enum PartnerType {
    POLICY_HOLDER = "POLICY_HOLDER",
    CLAIM_HANDLER = "CLAIM_HANDLER",
    INSURER = "INSURER",
    SELF_INSURER = "SELF_INSURER",
}

export interface Partner {
    id: number;
    createdAt: string;
    name: string;
    partnerNumber: string;
    partnerNumberPah: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    houseNumber: string;
    houseNumberAddition: string;
    residence?: string;
    iban: string;
    bic: string;
    email: string;
    partnerType: PartnerType;
    invoiceBalance: number;
    claimBalance: number;
    totalBalance: number;
}

export interface Partners extends RequestObject {
    content: Partner[];
}

export interface PartnerDetails {
    content: Partner;
}

// for create and edit
export interface PartnerRequest {
    id?: number;
    name?: string;
    partnerNumber?: string;
    partnerNumberPah?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    houseNumber?: string;
    houseNumberAddition?: string;
    residence?: string;
    iban?: string;
    bic?: string;
    email?: string;
    partnerType?: PartnerType;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type PartnerFilters = Readonly<Partial<{
    partnerNumber: string;
    partnerType: PartnerType;
    partnerTypes: PartnerType[];
    iban: string;
    name: string;
    periodFrom: string;
    periodTo: string;
}>>;

export interface BookPartner {
    entryId: number;
    paidObjectId: number;
    paidAmount: number;
    description?: string;
}

export const canSubmitPartnerForm =
    (partnerRequest: State<PartnerRequest>): boolean => {
        const validMandatoryPartnerFields =
            partnerRequest.map(f => hasRequiredFields(
                f,
                ["name", "addressLine1", "zipCode", "residence"],
            ));
        return (
            validMandatoryPartnerFields
        );
    };
