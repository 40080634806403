import { ClaimStatus } from "./Types";
import { PaidStatus } from "../AppTypes";

/**
 *
 */
export const ClaimStatusDisplay: Readonly<Record<ClaimStatus, PaidStatus>> = {
    [ClaimStatus.PAID]: PaidStatus.PAID,
    [ClaimStatus.ACCEPTED]: PaidStatus.UNPAID,
    [ClaimStatus.UNPAID]: PaidStatus.UNPAID,
    [ClaimStatus.ERROR]: PaidStatus.UNPAID,
    [ClaimStatus.UPDATED]: PaidStatus.UNPAID,
};
