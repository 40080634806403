
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { Products } from "../../../../../store/Product/Types";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette, ThemePaletteState, ThemeShadowSizes } from "../../../../../theme/_Types";
import { getProductStatusColor } from "../../../../utils/FormatHelpers";


/**
 *
 */
interface OwnProps {
    results: Products;
    asAuditTab?: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.PRODUCT_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, _index) => ({
            id: row.id,
            data: [
                row.id,
                row.name,
                row.forUsedVehicles === true ? (
                    <Badge theme={{ paletteState: ThemePaletteState.SUCCESS }}> Yes </Badge>
                ) : <Badge theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}> No </Badge>,
                row.productNumber,
                row.coverageCodeForPah,
                (
                    <Badge
                        key={`badge-${row.id}`}
                        theme={{
                            paletteState:
                                getProductStatusColor
                                (row.status),
                        }}
                    >
                        {row.status}
                    </Badge>
                ),
                <ButtonsWrapper
                    key={`navlink-${row.id}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.asAuditTab ? (
                            <NavLink to={`/audit/products/${row.id}`}>
                                <Button
                                    icon={IconNames.ARROW_RIGHT}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                />
                            </NavLink>
                        ) :
                            (
                                <>
                                    <NavLink to={`/extendedwarranties/${row.id}`}>
                                        <Button
                                            icon={IconNames.ARROW_RIGHT}
                                            theme={{
                                                palette: ThemePalette.CONTRAST_PRIMARY,
                                                shadow: ThemeShadowSizes.TINY,
                                            }}
                                        />
                                    </NavLink>
                                </>
                            )}</ButtonsWrapper>,
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Number",
                },
                {
                    label: "Name",
                },
                {
                    label: "For used vehicle",
                },
                {
                    label: "Product number",
                },
                {
                    label: "Coverage code (PAH)",
                },
                {
                    label: "Status",
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
