import * as React from "react";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { Form } from "../../../../../../components/core/07-organisms/Form";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { FormTextArea } from "../../../../../../components/core/07-organisms/Form/TextArea";
import { CoverageLevelDisplay } from "../../../../../../store/Policies/Displays";
import { Product } from "../../../../../../store/Product/Types";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";


interface Props {
    general: Product;
}
/**
 *
 * @param props
 */
export const GeneralView: React.FC<Props> = (props: Props) => (
    <React.Fragment>
        <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
            <Container
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    padding: {
                        "": { y: 3 },
                        "sm": { y: 4 },
                    },
                }}
            >
                <Form>
                    <FieldSet title="Product info">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Name"
                                    value={props.general.name}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Description"
                                    value={props.general.description}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Product number"
                                    value={props.general.productNumber}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Coverage code for PAH"
                                    value={props.general.coverageCodeForPah}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Proxy"
                                    value={props.general.proxy ? props.general.proxy.name : ""}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Coverage level"
                                    value={CoverageLevelDisplay[props.general.coverageLevel]}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                    {props.general.brands &&
                            <FieldSet title="Brand info">
                                <Div className="scl-b-row">
                                    {props.general.brands.map((brand, index) => (
                                        <Grid
                                            size={{ xs: 12, md: 4 }}
                                            key={index}
                                        >
                                            <FormField
                                                label={`Brand ${index + 1}`}
                                                value={brand.name}
                                                asSummary
                                            />
                                        </Grid>
                                    ))}
                                </Div>
                            </FieldSet>}
                    {!!props.general.insurer &&
                            <FieldSet title="Insurer">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Name"
                                            value={props.general.insurer.name}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Partner number"
                                            value={props.general.insurer.partnerNumber}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Parter number (PAH)"
                                            value={props.general.insurer.partnerNumberPah}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Address line 1"
                                            value={props.general.insurer.addressLine1}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Address line 2"
                                            value={props.general.insurer.addressLine2}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Zip code"
                                            value={props.general.insurer.zipCode}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="House number"
                                            value={props.general.insurer.houseNumber}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="House number addition"
                                            value={props.general.insurer.houseNumberAddition}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 4 }}
                                    >
                                        <FormField
                                            label="Residence"
                                            value={props.general.insurer.residence}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                    }
                    <FieldSet title="Reporting">
                        <Div className="scl-b-row">
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Soa name"
                                    value={props.general.soaName}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="First part VWFS"
                                    value={props.general.firstPartVWFS}
                                    asSummary
                                />
                            </Grid>
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <FormField
                                    label="Second part VWFS"
                                    value={props.general.secondPartVWFS}
                                    asSummary
                                />
                            </Grid>
                        </Div>
                    </FieldSet>
                    <FieldSet title="Terms and conditions">
                        <FormTextArea
                            label=""
                            value={props.general.termsAndConditions}
                            asSummary
                        />
                    </FieldSet>
                </Form>
            </Container>
        </Div>
    </React.Fragment>
);
