import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../..//store/AppDisplays";
import { Filter } from "../../../02-pages/Filter";
import * as InvoiceTypes from "../../../../../../store/CollectiveInvoice/Types";
import { isRemoteLoading, ReduxState } from "../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Results } from "../../../02-pages/Results";
import { CreditDebitIndicator } from "../../../../../../store/Statements/Types";
import { CollectiveInvoiceStatus } from "../../../../../../store/CollectiveInvoice/Types";
import { Div } from "../../../../../../components/core/03-base/Div";

interface OwnProps {
    statementLineId: string;
    creditDebitIndicator: CreditDebitIndicator;
}

interface State {
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: InvoiceTypes.CollectiveInvoices;
}

type Props = OwnProps & StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.COLLECTIVE_INVOICE_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.COLLECTIVE_INVOICE_RESULTS) as InvoiceTypes.CollectiveInvoices,
});

/**
 *
 * @param props
 */
export class CollectiveInvoicesComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {};
    }

    public render() {
        return (
            <Div style={{ marginTop: "-20px" }}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES}
                    defaultFilters={{
                        invoiceStatus:
                            // eslint-disable-next-line max-len
                            `${CollectiveInvoiceStatus.UNPAID},${CollectiveInvoiceStatus.PARTIALLY_PAID},${CollectiveInvoiceStatus.REVERSED}`,
                    }}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES}
                    scope={RemoteScope.COLLECTIVE_INVOICE_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const CollectiveInvoices = connect(
    mapStateToProps,
    {},
)(CollectiveInvoicesComp);
