import * as React from "react";
import { connect } from "react-redux";
import { MBFilters } from "../../../../../store/ManualBooking/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { validFromValidation, validTillValidation } from "../../../../../utils/FormValidations";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { ThemePalette } from "../../../../../theme/_Types";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
type setFilterFn = (filters: Partial<MBFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof MBFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    defaultFilters?: MBFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: MBFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.MANUAL_BOOKING, p.defaultFilters),
    filters: getFilters(s, FilterScope.MANUAL_BOOKING) as MBFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.MANUAL_BOOKING)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.MANUAL_BOOKING));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.MANUAL_BOOKING_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.MANUAL_BOOKING_RESULTS)),
        dispatch(remoteClearError(RemoteScope.MANUAL_BOOKING_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.state = {};

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.fromDateValidation = this.fromDateValidation.bind(this);
        this.toDateValidation = this.toDateValidation.bind(this);
        this.descriptionValidation = this.descriptionValidation.bind(this);
        this.onChangeFromDate = this.onChangeFromDate.bind(this);
        this.onChangeToDate = this.onChangeToDate.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Booking date from"
                            onChange={this.onChangeFromDate}
                            value={this.props.filters?.bookingDateFrom ?? ""}
                            type="date"
                            validationFunction={this.fromDateValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Booking date to"
                            onChange={this.onChangeToDate}
                            value={this.props.filters?.bookingDateTo ?? ""}
                            type="date"
                            validationFunction={this.toDateValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormField
                            label="Description"
                            onChange={this.onChangeDescription}
                            value={this.props.filters?.description ?? ""}
                            validationFunction={this.descriptionValidation}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private fromDateValidation(value: string | number) {
        return validFromValidation(value.toString(),
            this.props.filters.bookingDateTo
                ? this.props.filters.bookingDateTo : undefined);
    }

    private toDateValidation(value: string | number) {
        return validTillValidation(value.toString(),
            this.props.filters.bookingDateFrom
                ? this.props.filters.bookingDateFrom : undefined);
    }

    private onChangeFromDate(value?: string | number) {
        return this.onChange("bookingDateFrom", value?.toString() ?? "");
    }

    private onChangeToDate(value?: string | number) {
        return this.onChange("bookingDateTo", value?.toString() ?? "");
    }

    private descriptionValidation(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Description provided",
        );
    }

    private onChangeDescription(value?: string | number) {
        return this.onChange("description", value?.toString() ?? "");
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof MBFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof MBFilters>(key: K, value: MBFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
