import React from "react";
import { GeneralView } from "../../../../../00-blocks/Product/component/GeneralView";
import { Product } from "../../../../../../../../store/Product/Types";

interface Props {
    product: Product;
}

export const General: React.FC<Props> = (props: Props) => (
    <GeneralView general={props.product}/>
);
