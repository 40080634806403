import React from "react";
import { Container } from "../../../../components/core/03-base/Container";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Icon, IconNames } from "../../../../components/core/05-atoms/Icon";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Results } from "./Results";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Accounts as AccountType } from "../../../../store/Accounts/Types";


/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    results: AccountType;
    user?: UserInfo;
}

interface DispatchProps {
    loadResults: () => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingResults: isRemoteLoading(s, RemoteScope.ACCOUNT_RESULTS),
    results: s.prop("remote").prop(RemoteScope.ACCOUNT_RESULTS) as AccountType,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: () => dispatch(remoteTrigger(RemoteScope.ACCOUNT_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.ACCOUNT_RESULTS)),
        dispatch(remoteClearError(RemoteScope.ACCOUNT_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class AccountsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadResults();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.ACCOUNTS].title}
                                description={PageDisplay[Pages.ACCOUNTS].description}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_DEBTOR &&
                            this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <NavLink to={SubPageDisplay[SubPages.ACCOUNTS_CREATE].path}>
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            className="scl-a-btn--big"
                                        >
                                            {SubPageDisplay[SubPages.ACCOUNTS_CREATE].title}
                                        &nbsp;
                                        &nbsp;
                                            <Icon name={IconNames.ARROW_RIGHT} />
                                        </Button>
                                    </NavLink>
                                </Div>
                            </Grid>}
                    </Div>
                    <Div
                        theme={{
                            padding: {
                                "": { t: 2 },
                                "sm": { t: 3 },
                            },
                        }}
                    >
                        <Results
                            results={this.props.results}
                            loadingResults={this.props.loadingResults}
                            currentPage={Pages.ACCOUNTS}
                            scope={RemoteScope.ACCOUNT_RESULTS}
                        />
                    </Div>
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Accounts = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountsComp);
