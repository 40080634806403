import { State } from "../../utils/redux/State";

import { Model } from "../Models/Types";
import { Product, hasRequiredFields } from "../Product/Types";
import { RefObject, RequestObject } from "../RemoteTypes";

export interface Brand {
    id: number;
    createdAt?: string;
    name: string;
    description: string;
    passengerVehicles: boolean;
    commercialVehicles: boolean;
    coverageCodeForPah?: string;
    brandPartVWFS?: string;
    models: Model[];
    extendedWarranties: Product[];
}

export interface Brands extends RequestObject {
    content: Brand[];
}

export interface BrandDetails extends RequestObject {
    content: Brand;
}

export interface BrandRequest {
    id?: number;
    name?: string;
    description?: string;
    passengerVehicles?: boolean;
    commercialVehicles?: boolean;
    coverageCodeForPah?: string;
    brandPartVWFS?: string;
    models?: RefObject[];
    extendedWarranties?: RefObject[];
}

export const canSubmitBrandForm =
    (brandRequest: State<BrandRequest>): boolean => {
        const validMandatoryBrandFields =
        brandRequest.map(f => hasRequiredFields(
            f,
            ["name", "coverageCodeForPah"],
        ));
        return (
            validMandatoryBrandFields
        );
    };
