import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { BusinessRuleViolationTypeDisplay } from "../../../../../store/BusinessRuleViolations/Displays";
import { UserRoles, UserInfo } from "../../../../../store/AppTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { BusinessRuleViolations } from "../../../../../store/BusinessRuleViolations/Types";
import { ThemeBreakpoints, ThemePalette, ThemePaletteState, ThemeShadowSizes } from "../../../../../theme/_Types";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: BusinessRuleViolations;
}

interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    updateRuleStatus: (id: number, active: boolean) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS, {
        skip: skip,
    })),
    updateRuleStatus: (id: number, active: boolean) =>
        dispatch(remoteTrigger(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS_PUT, {
            id: id,
            active: active,
        })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results && this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                BusinessRuleViolationTypeDisplay[row.policyBusinessRule],
                row.active ? <Badge theme={{ paletteState: ThemePaletteState.SUCCESS }} >Active</Badge> :
                    <Badge theme={{ paletteState: ThemePaletteState.DANGER }} >Disabled</Badge>,

                <ButtonsWrapper
                    key={`navlink-${index}`}
                    asGroup
                    alignment={Alignment.RIGHT}
                    orientations={{
                        [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                    }}
                >{
                        this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ? (
                            row.active ? (
                                <Button
                                    theme={{
                                        paletteState: ThemePaletteState.DANGER,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    className="scl-a-btn--tiny"
                                    link={{ onClick: () => this.props.updateRuleStatus(row.id, false) }}
                                >
                            Disable
                                </Button>
                            ) : (
                                <Button
                                    theme={{
                                        paletteState: ThemePaletteState.SUCCESS,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    className="scl-a-btn--tiny"
                                    link={{ onClick: () => this.props.updateRuleStatus(row.id, true) }}
                                >
                            Activate
                                </Button>
                            )) : ""}</ButtonsWrapper>,
            ],
        }));
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Policy Business Rule",
                },
                {
                    label: "Active/Disabled",
                },
                this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    {
                        label: "Actions",
                    } :
                    {
                        label: "",
                    },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
