import { AxiosResponse } from "axios";
import { Products, ProductDetail, ProductFilters } from "./Types";

/**
 *
 * @param resp
 */
export const mapProductRequests =
    (resp: AxiosResponse): Products => resp.data as Products;

/**
 *
 * @param resp
 */
export const mapProductDetailsRequests =
    (resp: AxiosResponse): ProductDetail => resp.data as ProductDetail;

/**
 *
 */
export const ProductFiltersToParams = (f: ProductFilters) => ({
    ...f,
});
