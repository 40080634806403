import React from "react";
import { ThemePaletteState, ThemePalette, ThemeShadowSizes, ThemeProviderProps } from "../../../theme/_Types";
import { Div } from "../03-base/Div";
import { Navbar } from "./Navbar";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "./Navbar/Stack";
import { NavbarTitle } from "./Navbar/Title";
import { Button } from "../05-atoms/Button";
import * as I18n from "../../../utils/translations/I18n";
import { BreakpointConsumer } from "../../../utils/Breakpoints";
import { NavLink } from "react-router-dom";

export enum FormBarSize {
    FULL_WIDTH = "FULL_WIDTH",
    WITH_SIDEBAR = "WITH_SIDEBAR",
    WITH_COLLAPSED_SIDEBAR ="WITH_COLLAPSED_SIDEBAR"
}

interface OwnProps {
    onClick?: () => void;
    buttonLabel?: React.ReactNode;
    isValid?: boolean;
    title: React.ReactNode;
    hasRequiredFields?: boolean;
    invalidMessage?: string;
    showButton?: boolean;
    size?: FormBarSize;
    navLink?: string;
}

/**
 *
 */
type Props = OwnProps;

export const navbarTheme = (isValid?: boolean): Partial<Readonly<ThemeProviderProps>> => {
    if (isValid === true) {
        return { paletteState: ThemePaletteState.SUCCESS };
    } else if (isValid === false) {
        return { paletteState: ThemePaletteState.DANGER };
    }
    return { palette: ThemePalette.BRAND_ACCENT };
};

export const FormBar: React.FunctionComponent<Props> = props => (
    <BreakpointConsumer>
        {(value) => (
            <Div
                className={
                    `scl-o-formbar ${props.size === FormBarSize.WITH_COLLAPSED_SIDEBAR
                        ? "scl-o-formbar--collapsed-sidebar" : ""}`}
                theme={{
                    shadow: ThemeShadowSizes.HUGE,
                }}
            >
                <Navbar theme={navbarTheme(props.isValid)} className="scl-h-text-align--left">
                    <NavbarStack>
                        {value.breakpointAtSM &&
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {props.title}
                                </NavbarTitle>
                            </NavbarStackContent>}
                        <NavbarStackRight>
                            <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                                <Div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    {(!!props.isValid || !!props.showButton) &&
                                        <Div
                                            theme={{ padding: { "": { l: 2 }, "sm": { l: 3 } } }}
                                        >
                                            {!!props.onClick || !!props.navLink ?
                                                !!props.navLink ?
                                                    <NavLink to={props.navLink}>
                                                        <Button
                                                            className="scl-a-btn--big"
                                                            style={{ marginRight: "10px" }}
                                                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                                        >
                                                            {props.buttonLabel ? props.buttonLabel : "Continue"}
                                                        </Button>
                                                    </NavLink>
                                                    :
                                                    <Button
                                                        link={{
                                                            onClick: () =>
                                                                props.onClick ? props.onClick() : null,
                                                        }}
                                                        className="scl-a-btn--big"
                                                        style={{ marginRight: "10px" }}
                                                        theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                                    >
                                                        {props.buttonLabel ? props.buttonLabel : "Continue"}
                                                    </Button> :

                                                <Button
                                                    buttonType="submit"
                                                    className="scl-a-btn--big"
                                                    style={{ marginRight: "10px" }}
                                                    theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                                >
                                                    {props.buttonLabel ? props.buttonLabel : "Continue"}
                                                </Button>
                                            }
                                        </Div>
                                    }
                                    {
                                        (props.hasRequiredFields && props.isValid !== true) ? (
                                            <Div
                                                theme={{ padding: { "": { l: 2 }, "sm": { l: 3 } } }}
                                            >
                                                <b>
                                                    {I18n.getText("mary.07-organisms.formbar.required",
                                                        "Make sure all mandatory fields are filled in.")}
                                                </b>
                                            </Div>
                                        ) :
                                            (!!props.invalidMessage && props.isValid !== true) && (
                                                <Div
                                                    theme={{ padding: { "": { l: 2 }, "sm": { l: 3 } } }}
                                                >
                                                    <b>{props.invalidMessage}</b>
                                                </Div>
                                            )
                                    }
                                </Div>
                            </Div>
                        </NavbarStackRight>
                    </NavbarStack>
                </Navbar>
            </Div>
        )}
    </BreakpointConsumer>
);
