import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ManualBookBar, bookingBarData } from "../../../00-blocks/ManualBookings/Create/ManualBookBar";
import { PageDisplay, Pages } from "../../../../../../store/AppDisplays";
import { FileDownload } from "../../../../../../store/Files/Types";
import { deleteManualBooking, deleteManualBookingSelection,
    updateManualBooking } from "../../../../../../store/ManualBooking/CreateType";
import { ManualBookingTypeDisplay } from "../../../../../../store/ManualBooking/Displays";
import { ManualBooking, ManualBookingAmount, ManualBookingResults, ManualBookingTypes,
    ManualBookings } from "../../../../../../store/ManualBooking/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { getManualBookingTypeColor } from "../../../../../utils/FormatHelpers";

import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../../store/RemoteActions";
import { ConfirmManualBookingModal } from "./ConfirmManualBookingModal";
import { DownloadFileProps } from "../../Files/Results";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../../components/core/05-atoms/CurrencyDisplay";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { Table, RowData, TableData } from "../../../../../../components/core/06-molecules/Table";
import { Card } from "../../../../../../components/core/07-organisms/Card";
import { ThemeSize, ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";
import { isNegative } from "../../../../../../utils/FormatHelpers";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";

/**
 *
 */
export interface StatePropsResults extends DownloadFileProps {
    manualBooking?: ManualBookings;
    manualBookings?: ManualBooking[];
}

/**
 *
 */
export interface DispatchPropsResults {
    bookingType: ManualBookingTypes;
    loadResults: (skip: number) => void;
    manualBook: (manualBooking: ManualBookings) => void;
    addManualBookSelection: (selectedRows: ManualBooking[]) => void;
    deleteManualBookSelection: (selectedRows: ManualBooking[]) => void;
    openBookRequest: (modalId: string, callBack: () => void) => void;
    deleteManualBookingRow: (id: number) => void;
    loadDownload: (id: number) => void;
}

/**
 *
 */
export const mapStateToPropsResults = (s: ReduxState): StatePropsResults => ({
    manualBooking: s.mapProp("manualBooking", (item) => ({
        freeBookingItems: item.prop("freeBookingItems"),
        bookingDate: item.prop("bookingDate"),
        description: item.prop("description"),
    })),
    manualBookings: s.prop("manualBooking").prop("freeBookingItems"),
    loadingFileInitDownload: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    loadingFileDownload: isRemoteLoading(s, RemoteScope.FILE_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
});

/**
 *
 */
export const mapDispatchToPropsResults = (scope: RemoteScope, bookingType: ManualBookingTypes) =>
    (dispatch: DispatchFunc): DispatchPropsResults => ({
        bookingType: bookingType,
        loadResults: (skip: number) => dispatch(remoteTrigger(scope, {
            skip: skip,
        })),
        manualBook: (manualBooking: ManualBookings) => (
            dispatch(remoteTrigger(RemoteScope.MANUAL_BOOKING_CREATE, {
                manualBooking: manualBooking,
            }),
            )),
        addManualBookSelection: (selectedRows: ManualBooking[]) => (
            dispatch(updateManualBooking(selectedRows))
        ),
        deleteManualBookSelection: (selectedRows: ManualBooking[]) => (
            dispatch(deleteManualBookingSelection(selectedRows))
        ),
        openBookRequest: (modalId: string, callBack: () => void) => dispatch(
            modalShow(
                modalId,
                () => (
                    <Modal
                        modalID={modalId}
                        theme={{ size: ThemeSize.SMALL }}
                    >
                        <ConfirmManualBookingModal
                            modalID={modalId}
                            onBookConfirmed={callBack}
                        />
                    </Modal>
                ),
            ),
        ),
        deleteManualBookingRow: (id: number) => (
            dispatch(deleteManualBooking(id, bookingType),
            )
        ),
        loadDownload: (id: number) =>
            dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, {id: id})),
    });

/**
 *
 */
interface StateProps {
    results?: ManualBooking[];
    resultsSuccess: ManualBookingResults;
}

/**
 *
 */
interface DispatchProps {
    // loadResults: (skip: number) => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = StateProps & DispatchProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("manualBooking").prop("freeBookingItems"),
    resultsSuccess: s.prop("remote").prop(RemoteScope.MANUAL_BOOKING_CREATE) as ManualBookingResults,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    // loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.ACCOUNT_RESULTS, {
    //     skip: skip,
    // })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.MANUAL_BOOKING_CREATE)),
        dispatch(remoteClearError(RemoteScope.MANUAL_BOOKING_CREATE))
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    /**
     *
     */
    public render() {
        const manualBookingResults = this.props.results?.map((booking): ManualBookingAmount =>
            ({amount: booking.amount}));

        const calcBookingBarData = bookingBarData(manualBookingResults);

        return (
            this.props.resultsSuccess ?
                <Redirect to={PageDisplay[Pages.MANUAL_BOOKING].path} />
                : (
                    <Div theme={{ padding: { "": { b: 2, t: 2 }, "sm": { b: 3, t: 3 } } }}>
                        <Div theme={{ shadow: ThemeShadowSizes.TINY, palette: ThemePalette.CONTRAST_PRIMARY }}>
                            <Card title="Free booking" collapsable defaultCollapsed>
                                <>
                                    <Table
                                        hasActions
                                        data={{
                                            columns: this.resultsData().columns,
                                            rows: this.resultsData().rows,
                                            sort: this.resultsData().sort,
                                        }}
                                    />
                                </>
                            </Card>
                            <ManualBookBar
                                title={"Total"}
                                notFixed
                                {...calcBookingBarData}
                            />
                        </Div>
                    </Div>)
        );
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        if (this.props.results && this.props.results.length > 0) {
            return this.props.results.map((row, index): RowData => ({
                id: row.objectId ? (`${row.objectId}-${index}`) : (`${index}-free-booking`),
                data: [
                    row.objectType ?
                        (
                            <Badge
                                theme={{
                                    palette: getManualBookingTypeColor(row.objectType),
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            >
                                {ManualBookingTypeDisplay[row.objectType]}
                            </Badge>
                        )
                        : "",
                    row.description ? row.description : "",
                    !isNegative(row.amount) ?
                        (
                            <h6>
                                <Currency amount={row.amount} />
                            </h6>
                        ) : "",
                    isNegative(row.amount) ?
                        (
                            <h6>
                                <Currency amount={- row.amount} />
                            </h6>
                        ) : "",
                ],
            }));
        } else {
            return [];
        }
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Type",
                },
                {
                    label: "Number / Code",
                },
                {
                    label: "Debit balance",
                    alignRight: true,
                },
                {
                    label: "Credit balance",
                    alignRight: true,
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
