import * as React from "react";
import { connect } from "react-redux";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Models } from "../../../../../store/Models/Types";
import Moment from "react-moment";
import { TariffRequest } from "../../../../../store/Tariffs/Types";
import { RowData, Table } from "../../../../../components/core/06-molecules/Table";
import { Container } from "../../../../../components/core/03-base/Container";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { ThemePalette } from "../../../../../theme/_Types";
import { TableSelectCell } from "../../../06-molecules/TableSelectCell";

interface State {
    selectedRows: string[];
}

/**
 *
 */
interface StateProps {
    tariff: TariffRequest;
    loading: boolean;
}

/**
 *
 */
interface OwnProps {
    results: Models;
    onChange: <K extends keyof TariffRequest>(key: K, value: TariffRequest[K]) => void;
    modelNumber?: string;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    tariff: s.prop("tariffRequest").map(e => ({
        ...e,
    })),
    loading:
            isRemoteLoading(s, RemoteScope.MODEL_RESULTS)
    ,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.MODEL_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results && this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private selectItem(value: boolean | undefined, id: string) {
        this.setState({
            selectedRows: !!value ? [
                id,
            ] : [],
        }, () => this.props.onChange("model", {id: Number(this.state.selectedRows[0])}));
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: this.props.modelNumber ? [this.props.modelNumber] : [],
        };
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private amIChecked(id: string, selectedRows: string[]): boolean {
        return selectedRows.some((row) => row === id);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const checked = this.amIChecked(row.id.toString(), this.state.selectedRows);
            return ({
                id: row.id,
                data: [
                    (
                        <TableSelectCell
                            key={`table-select-${row.id}`}
                            checked={checked}
                            selectItem={() => this.selectItem(!checked, row.id.toString())}
                        />
                    ),
                    row.name ? row.name : "",
                    row.createdAt
                        ? <Moment date={new Date(row.createdAt)} format={"DD-MM-YYYY"} key={`c-date-${index}`}/>
                        : "",
                    row.description,
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Name",
                },
                {
                    label: "Created at",
                },
                {
                    label: "Description",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
