/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import * as FormFields from "../../../../components/core/07-organisms/Form/Field";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { Required } from "../../../../components/core/05-atoms/Required";
import { Card } from "../../../../components/core/07-organisms/Card";
import { Form } from "../../../../components/core/07-organisms/Form";
import { ValidationStatus } from "../../../../components/core/07-organisms/Form/_Types";
import { FormBar } from "../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { PageDisplay, Pages, ReportFormDisplays } from "../../../../store/AppDisplays";
import { FileDownload } from "../../../../store/Files/Types";
import { Proxies } from "../../../../store/Proxies/Types";
import { RemoteErrors, ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteTrigger } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { updateReportForm, clearReportForm } from "../../../../store/Reports/FormActions";
import { ReportForm, ReportAccountingGenerate, canSubmitReportForm, getReportForm, ReportFormScope, requiredFields }
    from "../../../../store/Reports/Types";
import { ThemeShadowSizes, ThemePalette } from "../../../../theme/_Types";
import { getErrorValidationMessage } from "../../../utils/ErrorMessage";

import { LoadingData } from "../../../../components/core/09-views/00-blocks/LoadingData";
import { Failed } from "../00-blocks/Reports/Generate/Failed";
import { Success } from "../00-blocks/Reports/Generate/Success";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { hasDateValueValidation, hasValueValidation } from "../../../../components/core/07-organisms/Form/Field.utils";
import { validFromValidation, validTillValidation } from "../../../../utils/FormValidations";
import { Container } from "../../../../components/core/03-base/Container";

/**
 *
 */
type setReportFormFn = (fields: Partial<ReportForm>) => void;
type clearReportFormFn = (fields?: Array<keyof ReportForm>) => void;

interface State {
    submitted: boolean;
}

/**
 *
 */
interface StateProps {
    canSubmitReportForm: boolean;
    reportForm: ReportForm;
    // FormHelpers
    loadingFormHelpers: boolean;
    formHelpers: {
        proxies?: Proxies;
    };
    // Generate stuff
    resultsGenerate?: ReportAccountingGenerate;
    resultsErrorGenerate?: RemoteErrors;
    loadingGenerateResults: boolean;
    // Download stuff
    loadingDownloadResults: boolean;
    fileDownloadResult?: FileDownload;
}
/**
 *
 */
interface DispatchProps {
    setReportForm: setReportFormFn;
    submitReportForm: (fields: ReportForm) => void;
    loadFormHelpers: () => void;
    clearReportForm: clearReportFormFn;
    loadDownload: (id: number) => void;
}

type Props = DispatchProps & StateProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => {
    const error = s.prop("remoteErrors");

    return {
        canSubmitReportForm: canSubmitReportForm(s.prop("reportForm")),
        reportForm: getReportForm(s.prop("reportForm")),
        // FormHelpers
        formHelpers: {
            proxies: s.prop("remote").prop(RemoteScope.PROXIES),
        },
        loadingFormHelpers: isRemoteLoading(s, RemoteScope.PROXIES),

        // Generate stuff
        resultsGenerate: s.prop("remote").prop(RemoteScope.GENERATE_REPORT_ACCOUNTING_POST),
        resultsErrorGenerate: getErrorValidationMessage(error, RemoteScope.GENERATE_REPORT_ACCOUNTING_POST),
        loadingGenerateResults: isRemoteLoading(s, RemoteScope.GENERATE_REPORT_ACCOUNTING_POST),
        // Download stuff
        loadingDownloadResults: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
        fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD),
    };
};

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setReportForm: (reportForm) => dispatch(updateReportForm(reportForm)),
    submitReportForm: (fields: ReportForm) => {
        dispatch(remoteTrigger(RemoteScope.GENERATE_REPORT_ACCOUNTING_POST, {
            fields: fields,
        }));
    },
    loadFormHelpers: () => {
        dispatch(remoteTrigger(RemoteScope.PROXIES, {
            forReports: true,
        }));
    },
    clearReportForm: (fields) => {
        dispatch(clearReportForm(fields));
    },
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, { id: id })),
});

/**
 *
 * @param props
 */
export class ReportsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {
            submitted: false,
        };

        this.generate = this.generate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.clear = this.clear.bind(this);
        this.continue = this.continue.bind(this);
    }

    public componentDidMount() {
        this.clear();
        this.props.loadFormHelpers();
    }

    public componentWillUnmount() {
        this.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >

                    <Header
                        title={PageDisplay[Pages.REPORTS].title}
                        description={PageDisplay[Pages.REPORTS].description}
                    />

                    {this.props.loadingFormHelpers ||
                        (
                            !this.props.formHelpers ||
                            !this.props.formHelpers.proxies?.content ||
                            (this.props.formHelpers
                                && this.props.formHelpers.proxies?.content
                                && this.props.formHelpers.proxies?.content.length <= 0))
                        ? (
                            <LoadingData loading={this.props.loadingFormHelpers}/>
                        )
                        : (
                            <Div
                                theme={{
                                    shadow: ThemeShadowSizes.TINY,
                                    margin: {
                                        "": { t: 2 },
                                        "sm": { t: 3 },
                                    },
                                }}
                            >

                                {(!this.state.submitted || this.props.loadingGenerateResults) ? (
                                    <Card
                                        title={this.props.loadingGenerateResults
                                            ? "Loading..." : "Specification"}
                                        collapsable={false}
                                        defaultCollapsed={false}
                                    >
                                        <Container
                                            theme={{
                                                palette: ThemePalette.CONTRAST_PRIMARY,
                                                padding: {
                                                    "": { y: 3 },
                                                    "sm": { y: 4 },
                                                },
                                            }}
                                        >
                                            {this.props.loadingGenerateResults
                                                ? (
                                                    <LoadingIndicator
                                                        type={LoadingIndications.DEFAULT}
                                                        theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                                    />
                                                )
                                                : (
                                                    <Form onSubmit={this.generate}>
                                                        <Div className="scl-b-row">
                                                            <Grid
                                                                size={{ xs: 12, md: 6 }}
                                                            >
                                                                <FormFields.FormField
                                                                    onChange={(value?: string | number) =>
                                                                        this.onChange("type", value
                                                                            ? value.toString() : undefined)}
                                                                    type="text"
                                                                    required
                                                                    value={
                                                                        this.props.reportForm
                                                                            && this.props.reportForm.type}

                                                                    label="Type"
                                                                    placeholder="Make a choice"
                                                                    options={Object.keys(ReportFormScope).map(item => ({
                                                                        label: ReportFormDisplays[item] as string,
                                                                        value: item,
                                                                    }))}
                                                                    validationFunction={
                                                                        (value: string | number) =>
                                                                            hasValueValidation(
                                                                                value.toString(),
                                                                                "Type provided")}
                                                                />
                                                            </Grid>
                                                        </Div>
                                                        {(requiredFields(this.props.reportForm.type as ReportFormScope).includes("dateTo")
                                        && requiredFields(this.props.reportForm.type as ReportFormScope).includes("dateFrom")) && (
                                                            <Div className="scl-b-row">
                                                                <Grid
                                                                    size={{ xs: 12, md: 6 }}
                                                                >
                                                                    <FormFields.FormField
                                                                        label="From"
                                                                        required
                                                                        onChange={(value?: string | number) =>
                                                                            this.onChange("dateFrom", value
                                                                                ? value.toString() : "")}
                                                                        value={this.props.reportForm
                                                                            ? this.props.reportForm.dateFrom : ""}
                                                                        type="date"
                                                                        validationFunction={
                                                                            (value: string | number) =>
                                                                                validFromValidation(value.toString(),
                                                                                    this.props.reportForm
                                                                                        ? this.props.reportForm.dateTo
                                                                                        : undefined)
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    size={{ xs: 12, md: 6 }}
                                                                >
                                                                    <FormFields.FormField
                                                                        label="Until"
                                                                        required
                                                                        onChange={(value?: string | number) =>
                                                                            this.onChange("dateTo", value
                                                                                ? value.toString() : "")}
                                                                        value={this.props.reportForm ?
                                                                            this.props.reportForm.dateTo : ""}
                                                                        type="date"
                                                                        validationFunction={
                                                                            (value: string | number) =>
                                                                                validTillValidation(value.toString(),
                                                                                    this.props.reportForm
                                                                                        ? this.props.reportForm.dateFrom
                                                                                        : undefined)
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Div>
                                                        )}
                                                        <Div className="scl-b-row">
                                                            {(requiredFields(this.props.reportForm.type as ReportFormScope).includes("proxyId")) && (
                                                                <Grid
                                                                    size={{ xs: 12, md: 6 }}
                                                                >
                                                                    <FormFields.FormField
                                                                        onChange={(value?: string | number) =>
                                                                            this.onChange("proxyId", value
                                                                                ? Number(value) : undefined)}
                                                                        type="text"
                                                                        value={
                                                                            this.props.reportForm
                                                                            && this.props.reportForm.proxyId}

                                                                        label="Proxy"
                                                                        placeholder="Make a choice"
                                                                        required
                                                                        options={this.props.formHelpers.proxies.content
                                                                            .map(item => ({
                                                                                label: item.name,
                                                                                value: item.id,
                                                                            }
                                                                            ))}
                                                                        validationFunction={
                                                                            (value: string | number) =>
                                                                                hasValueValidation(value.toString(),
                                                                                    "Proxy provided")}
                                                                    />
                                                                </Grid>
                                                            )}
                                                            {(requiredFields(this.props.reportForm.type as ReportFormScope).includes("referenceDate")) && (
                                                                <Grid
                                                                    size={{ xs: 12, md: 6 }}
                                                                >
                                                                    <FormFields.FormField
                                                                        label="Reference date"
                                                                        required
                                                                        onChange={(value?: string | number) =>
                                                                            this.onChange("referenceDate", value
                                                                                ? value.toString() : "")}
                                                                        value={this.props.reportForm ?
                                                                            this.props.reportForm.referenceDate : ""}
                                                                        type="date"
                                                                        validationFunction={
                                                                            (value: string | number) =>
                                                                                hasDateValueValidation(value.toString(), "Reference date provided")
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Div>
                                                        <Required />
                                                        <FormBar
                                                            buttonLabel={"Generate"}
                                                            title={"Generate report"}
                                                            isValid={!!this.props.canSubmitReportForm
                                                                ? this.formIsValid() : undefined}
                                                        />
                                                    </Form>
                                                )}
                                        </Container>
                                    </Card>)
                                    : (
                                        <>
                                            {(!!this.props.resultsGenerate && this.props.resultsGenerate.content.id) ? (
                                                <Success
                                                    link={{ onClick: this.continue }}
                                                    data={this.props.resultsGenerate.content}
                                                />
                                            )
                                                : (
                                                    <Failed
                                                        title={this.props.resultsErrorGenerate?.message}
                                                        link={{ onClick: this.continue }}
                                                    />
                                                )}
                                        </>)
                                }
                            </Div>)
                    }
                </Container>
            </Page>
        );
    }

    private continue() {
        this.setState({ submitted: false });
        this.clear();
    }

    private clear() {
        const fields =
            (Object.keys(this.props.reportForm) as Array<keyof ReportForm>);
        this.props.clearReportForm(fields);
    }

    private formIsValid() {
        if (requiredFields(this.props.reportForm.type as ReportFormScope).includes("dateTo")
            && requiredFields(this.props.reportForm.type as ReportFormScope).includes("dateFrom")) {
            if (this.props.reportForm.dateFrom && this.props.reportForm.dateTo) {
                return validFromValidation(this.props.reportForm.dateFrom,
                    this.props.reportForm
                        ? this.props.reportForm.dateTo : undefined).status === ValidationStatus.SUCCESS
                    && validTillValidation(this.props.reportForm.dateTo,
                        this.props.reportForm
                            ? this.props.reportForm.dateFrom : undefined).status === ValidationStatus.SUCCESS;
            }
            return false;
        }
        return true;
    }

    private onChange = <K extends keyof ReportForm>(key: K, value: ReportForm[K]) => {
        this.props.setReportForm({
            [key]: value,
        });
    };

    private generate() {
        this.props.submitReportForm({
            ...this.props.reportForm,
        });
        this.setState({
            submitted: true,
        });
    }
}

/**
 *
 */
export const Reports = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportsComp);
