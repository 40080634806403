import React from "react";

import { connect } from "react-redux";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { PageDisplay, Pages } from "../../../../store/AppDisplays";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { RemoteScope } from "../../../../store/RemoteTypes";

import { Claims as ClaimsType} from "../../../../store/Claims/Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";


/**
 *
 */
interface StateProps {
    filtersLoading: boolean;
    tableLoading: boolean;
    results: ClaimsType;
}

type Props = StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    filtersLoading: false,
    tableLoading: isRemoteLoading(s, RemoteScope.CLAIM_RESULTS),
    results: s.prop("remote").prop(RemoteScope.CLAIM_RESULTS) as ClaimsType,
});

/**
 *
 */
export class ClaimsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    /**
     *
     */
    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 12 }}
                        >
                            <Header
                                title={PageDisplay[Pages.CLAIMS].title}
                                description={PageDisplay[Pages.CLAIMS].description}
                            />
                        </Grid>
                    </Div>
                    <Filter
                        loadingFilters={this.props.filtersLoading}
                        loadingResults={this.props.tableLoading}
                        currentPage={Pages.CLAIMS}
                    />
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.tableLoading}
                        currentPage={Pages.CLAIMS}
                        scope={RemoteScope.CLAIM_RESULTS}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Claims = connect(
    mapStateToProps,
    {},
)(ClaimsComp);
