
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../components/core/05-atoms/Icon";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import { GeneralLedgers } from "../../../../store/GeneralLedgers/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";
/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: GeneralLedgers;
    user?: UserInfo;
}

type Props = StateProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.GENERAL_LEDGER),
    results: s.prop("remote").prop(RemoteScope.GENERAL_LEDGER) as GeneralLedgers,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 * @param props
 */
export class GeneralLedgerComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.GENERAL_LEDGER].title}
                                description={PageDisplay[Pages.GENERAL_LEDGER].description}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_DEBTOR &&
                            this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <NavLink
                                        to={"/generalledgers/create"}
                                        exact
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            className="scl-a-btn--big"
                                        >
                                            {SubPageDisplay[SubPages.GENERAL_LEDGER_CREATE].title}
                                        &nbsp;
                                        &nbsp;
                                            <Icon name={IconNames.PLUS} />
                                        </Button>
                                    </NavLink>
                                </Div>
                            </Grid>}
                    </Div>
                    <Filter
                        loadingFilters={this.props.loadingFilters}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.GENERAL_LEDGER}
                    />
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.GENERAL_LEDGER}
                        scope={RemoteScope.GENERAL_LEDGER}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const GeneralLedger = connect(
    mapStateToProps,
    {},
)(GeneralLedgerComp);
