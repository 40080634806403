import { FileFilters, Files } from "./Types";
import { AxiosResponse } from "axios";

export const mapFileRequests =
(resp: AxiosResponse): Files => resp.data as Files;

/**
 *
 */
export const FileFiltersToParams = (f: FileFilters) => ({
    ...f,
});
