import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ReduxState } from "../../../../../store/ReduxState";
import { CreateBrand } from "./CreateBrand";

interface Params {
    brandId: string;
}

interface StateProps {
    params: Params;
}

type Props = StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
});

/**
 *
 * @param s
 */

/**
 *
 */
export class EditBrandComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {
        return (
            <CreateBrand edit brandId={this.props.params.brandId} />
        );
    }
}

/**
 *
 */
export const EditBrand = connect(
    mapStateToProps,
    {},
)((props: Props) => <EditBrandComp {...props}/>);
