/* eslint-disable max-len */
import { SelectedRow } from "../../App/components/09-views/00-blocks/Partners/ResultsBook";
import { BookingPeriods, BookingPeriodStatus } from "../../store/BookingPeriod/Types";
import { CreditDebitIndicator, StatementDetails } from "../../store/Statements/Types";
import { DateToDateString } from "../../utils/FormatHelpers";

export const amIChecked = (id: number, selectedRows: SelectedRow[]): boolean =>
    (selectedRows.some((row) => row.id === id));

export const myAmount = (id: number, selectedRows: SelectedRow[], fallBack?: number): number | undefined => {
    const currentRow = selectedRows.find((row) => row.id === id);
    const formattedAmount = currentRow ? (currentRow.amount) : fallBack ? fallBack : undefined;
    return currentRow ? currentRow.amount === 0 ? undefined :
        formattedAmount : fallBack;
};

/*
 We check for debit or credit, depending on that we calculate with a negative or positve value
*/
export const statementLineBalance =
    (amount: number, debt: boolean): number => {
        if (amount === 0) {
            return 0;
        }
        if (debt) {
            return (- amount);
        }
        return amount;
    };

/*
 We check for debit or credit, depending on that we calculate with a negative or positve value
*/
export const selectedPartialBalance =
    (amount: number, debt: boolean): number => {
        if (amount === 0) {
            return 0;
        }
        if (debt) {
            return (- amount);
        }
        return amount;
    };

/*
 Calculate the uncleared balance based on the selected amount
*/
export const calcUnclearedBalanceAfter = (uncleared: number, selected: number, cred?: boolean): number => {
    const unclearedRounded = uncleared * 100;
    const selectedRounded = selected * 100;

    if (cred === true) {
        return Math.round(unclearedRounded - (- selectedRounded)) / 100;
    } else {
        return Math.round(unclearedRounded - selectedRounded) / 100;
    }
};

export const calcIsValid =
    (selectionAmount: number, _uncleared: number, _selected: number): boolean | undefined => {
        if (selectionAmount > 0) {
            return true;
        } else {
            return undefined;
        }
    };

export const calcIsValidBookBar =
    (selectionAmount: number, uncleared: number, selected: number, creditDebitIndicator: CreditDebitIndicator): boolean | undefined => {
        if (selectionAmount > 0) {
            if (creditDebitIndicator === CreditDebitIndicator.DBIT) {
                return (selected > 0 && ((uncleared - selected) >= 0));
            } else {
                return (selected < 0 && ((selected + uncleared) >= 0));
            }
        } else {
            return undefined;
        }
    };

export const validSelectedRow = (balance: number, selected?: number): boolean => {
    if (!!selected) {
        if (balance > 0) {
            return (selected <= balance && selected >= 0);
        } else {
            return (selected >= balance && selected <= 0);
        }
    } else {
        return !!balance || balance === 0;
    }
};

export const withinClosedBookingPeriod =
    (results: BookingPeriods, details: StatementDetails, loading: boolean) => {
        if (results && details && !loading) {
            const creationDate = details.content && details.content.creationDateTime;
            const creationDateFormatted = DateToDateString(new Date(creationDate));
            const bookingPeriodsObject = results && results.content.find(period => period.startingDate <= creationDateFormatted && period.endingDate >= creationDateFormatted);
            return (bookingPeriodsObject && bookingPeriodsObject.status === BookingPeriodStatus.CLOSED)
                || (!bookingPeriodsObject);
        } else {
            return false;
        }
    };

export const roundTwoDecimals = (amount: number): number => Math.round(amount * 100) / 100;
