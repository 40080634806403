/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";

import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { PageTabs } from "../../../../../store/AppDisplays";
import { StatementDetails, Entry as StatementLine,
    CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { ConfirmBookingModal } from "../Accounts/Statements/ConfirmBookingModal";
import {
    amIChecked, myAmount,
    calcUnclearedBalanceAfter, validSelectedRow, calcIsValidBookBar, statementLineBalance,
} from "../../../../utils/Booking";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import { DownloadFileProps } from "../Files/Results";
import { CollectiveInvoiceStatusTypeDisplay } from "../../../../../store/CollectiveInvoice/Displays";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Icon } from "../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { CollectiveInvoices, BookInvoice, CollectiveInvoiceStatus } from "../../../../../store/CollectiveInvoice/Types";
import { FileDownload } from "../../../../../store/Files/Types";
import { ThemeSize, ThemePalette, ThemePaletteState, ThemeBreakpoints } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { canDownloadFile } from "../../../../utils/FileDownload";
import { getCollectiveInvoiceStatusColor } from "../../../../utils/FormatHelpers";
import { DownloadButtonCell } from "../../../06-molecules/DownloadButtonCell";
import { BookBar } from "../../../07-organisms/BookBar";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Container } from "../../../../../components/core/03-base/Container";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";

export interface SelectedRow {
    id: number;
    amount: number;
}

interface State {
    selectedRows: SelectedRow[];
}

/**
 *
 */
interface StateProps extends DownloadFileProps {
    details: StatementDetails;
}

/**
 *
 */
interface OwnProps {
    results: CollectiveInvoices;
    statementLineId?: string;
    currentTab?: PageTabs;
    currentTitle?: string;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    book: (id: string, patch: BookInvoice) => void;
    openBookRequest: (statementLineId: string, callBack: () => void) => void;
    loadDownload: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

export const OpenBookReq =
    (statementLineID: string, callBack: () => void) => (
        () => (
            <Modal
                modalID={statementLineID}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmBookingModal
                    modalID={statementLineID}
                    statementLineId={statementLineID}
                    onBookConfirmed={callBack}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    details: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementDetails,
    loadingFileInitDownload: isRemoteLoading(s, RemoteScope.FILE_INIT_DOWNLOAD),
    loadingFileDownload: isRemoteLoading(s, RemoteScope.FILE_DOWNLOAD),
    fileDownloadResult: s.prop("remote").prop(RemoteScope.FILE_INIT_DOWNLOAD) as FileDownload,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_RESULTS, {
        skip: skip,
    })),
    book: (id: string, patch: BookInvoice) => (
        dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_BOOK_PATCH, {
            patch: patch,
            id: id,
        }))
    ),
    openBookRequest: (statementLineId: string, callBack: () => void) => dispatch(
        modalShow(
            statementLineId,
            OpenBookReq(statementLineId, callBack),
        ),
    ),
    loadDownload: (id: number) =>
        dispatch(remoteTrigger(RemoteScope.FILE_INIT_DOWNLOAD, { id: id })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.confirmedBooking = this.confirmedBooking.bind(this);
        this.bookBarCallBack = this.bookBarCallBack.bind(this);
        this.confirmedBookingCB = this.confirmedBookingCB.bind(this);
        this.loadDownload = this.loadDownload.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        const statementLine = this.props.details
            && this.props.details.content.entries
            && this.props.details.content.entries.length > 0 ? this.props.details.content.entries.find(line =>
                line.id.toString() === this.props.statementLineId) : undefined;

        const selectedBalance = this.state.selectedRows.map(row => row.amount)
            .reduce(
                (cur, next) => cur + next, 0);

        if (!statementLine) {
            return null;
        }

        const unclearedBalance = statementLine.balance;

        const unclearedBalanceAfter = calcUnclearedBalanceAfter(unclearedBalance, selectedBalance,
            statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT);

        const isValid = calcIsValidBookBar(this.state.selectedRows.length, unclearedBalance, selectedBalance,
            statementLine.creditDebitIndicator);

        return (
            <Form onSubmit={this.bookBarCallBack(statementLine)} isValid={isValid}>
                <BookBar
                    title={this.props.currentTitle ? this.props.currentTitle : "Booking Collective invoices"}
                    selectedBalance={selectedBalance}
                    unclearedBalanceAfter={unclearedBalanceAfter}
                    unclearedBalance={unclearedBalance}
                    isValid={isValid}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData(statementLine).columns,
                        rows: this.resultsData(statementLine).rows,
                        sort: this.resultsData(statementLine).sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> :  null}
            </Form>
        );
    }

    private bookBarCallBack(statementLine: StatementLine) {
        return () => this.props.openBookRequest(
            statementLine.id.toString(),
            this.confirmedBookingCB(statementLine));
    }

    private confirmedBookingCB(statementLine: StatementLine) {
        return () => this.confirmedBooking(statementLine);
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(statementLine: StatementLine): RowData[] {

        return this.props.results.content.map((row, index) => {
            const unclearedBalance = statementLineBalance(
                statementLine.balance,
                statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT);

            // CI function in Booking.ts ?
            const rowBalance =
                statementLine.creditDebitIndicator === CreditDebitIndicator.CRDT ?
                    ((- row.balance) >= unclearedBalance ? (- row.balance) : unclearedBalance) :
                    ((- row.balance) <= unclearedBalance ? (- row.balance) : unclearedBalance);

            const isLoading = ((this.props.fileDownloadResult && this.props.fileDownloadResult.id === row.messageId)
                && this.props.loadingFileDownload);

            return ({
                id: row.id,
                themePalleteState: validSelectedRow(rowBalance, myAmount(row.id, this.state.selectedRows))
                    ? undefined : ThemePaletteState.DANGER,
                tooltip: validSelectedRow(rowBalance,
                    myAmount(row.id, this.state.selectedRows)) ? undefined : "The selected balance is not valid",
                data: [
                    row.status === CollectiveInvoiceStatus.UNPAID
                    || row.status === CollectiveInvoiceStatus.REVERSED
                    || row.status === CollectiveInvoiceStatus.PARTIALLY_PAID ?
                        (
                            <div
                                className="scl-row-selection"
                                style={{
                                    display: "inline-flex",
                                    flexDirection: "row",
                                    flexGrow: 0,
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className="scl-row-selection__checkbox"
                                    style={{
                                        flex: 1,
                                        flexGrow: 0,
                                        marginRight: "5px",
                                    }}
                                >
                                    <Checkbox
                                        checked={amIChecked(row.id, this.state.selectedRows)}
                                        onChange={(value?: boolean) => this.selectItem(value, row.id
                                            , rowBalance)}
                                    />
                                </div>
                                <div
                                    className="scl-row-selection__input"
                                    style={{
                                        flex: 1,
                                        flexGrow: 0,
                                    }}
                                >
                                    {amIChecked(row.id, this.state.selectedRows) &&
                                        <FormField
                                            type="currency"
                                            placeholder="e.g. 100"
                                            onChange={(value?: number | string) => {
                                                this.onChangeAmount(row.id,
                                                    value ? typeof value === "string"
                                                        ? parseFloat(value) : value : 0);
                                            }}
                                            value={myAmount(row.id, this.state.selectedRows)}
                                        />
                                    }
                                </div>
                            </div>
                        ) : "",
                    row.invoiceNumber,
                    row.invoiceDate
                        ? <Moment date={new Date(row.invoiceDate)} format={"DD-MM-YYYY"} key={`invoicedate${index}`} />
                        : "",
                    row.dealerName,
                    row.partner ? (
                        <NavLink to={`/partners/${row.partner.id}`} target="_blank">
                            <span className="scl-a-link scl-b-h scl-b-h6">
                                <Icon name="eye" />&nbsp;&nbsp;{row.partner.partnerNumber}
                            </span>
                        </NavLink>
                    ) : row.partnerNumber,
                    row.mutationDate
                        ? <Moment date={new Date(row.mutationDate)} format={"DD-MM-YYYY"} key={`mutationdate${index}`}/>
                        : "",
                    (
                        <Badge
                            key={`badge-${row.id}`}
                            theme={{
                                paletteState:
                                    getCollectiveInvoiceStatusColor(row.status),
                            }}
                        >
                            {CollectiveInvoiceStatusTypeDisplay[row.status.toString()]}
                        </Badge>
                    ),
                    <Currency amount={row.totalAmount.amount} key={`bookinvoice${index}`} />,
                    (
                        <h6
                            key={`h6-${row.id}`}
                        >
                            <Currency amount={row.balance} />
                        </h6>
                    ),
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >{
                            row.messageId ? (
                                <DownloadButtonCell
                                    isLoading={isLoading}
                                    loadDownload={this.loadDownload(row.messageId)}
                                    canDownloadFile={canDownloadFile(this.props)}
                                />
                            ) : ""}</ButtonsWrapper>,
                ],
            });
        });
    }

    private loadDownload(id: number) {
        return () => this.props.loadDownload(id);
    }

    private resultsData(statementLine: StatementLine) {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Number",
                },
                {
                    label: "Date",
                },
                {
                    label: "Partner name",
                },
                {
                    label: "Partner number",
                },
                {
                    label: "Mutation date",
                },
                {
                    label: "Status",
                },
                {
                    label: "Amount",
                },
                {
                    label: "Balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(statementLine),
        };
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: [],
        };
    }

    private selectItem(value: boolean | undefined, id: number, amount: number) {
        this.setState({
            selectedRows: !!value ? [{
                id: id,
                amount: amount,
            }] : [],
        });
    }

    private onChangeAmount = (id: number, value: number) => {
        this.setState({
            selectedRows: this.state.selectedRows.map(row => {
                if (row.id === id) {
                    return ({
                        id: row.id,
                        amount: value,
                    });
                }
                return row;
            }),
        });
    };

    private confirmedBooking = (statementLine: StatementLine) => {
        this.props.book(this.props.details.content.id.toString(), {
            entryId: statementLine.id,
            paidObjectId: this.state.selectedRows[0].id,
            paidAmount: this.state.selectedRows[0].amount,
        });
    };
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
