/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { FieldSet } from "../../../../../components/core/06-molecules/FieldSet";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/index";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";
import { canSubmitAddProductTariffForm, addProductTariff, clearProductTariff } from "../../../../../store/Product/AddProductTariffTypes";
import { ProductTariffRequests, ProductDetail } from "../../../../../store/Product/Types";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeSize, ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { DetailWrapper } from "../../00-blocks/Tariffs/DetailWrapper";
import { SelectTariffModal } from "../../00-blocks/Tariffs/SelectTariffModal";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

type OnChange =
    <K extends keyof ProductTariffRequests>(key: K, value: ProductTariffRequests[K], index: number) => void;

interface Params {
    ewId: string;
}

interface StateProps {
    params: Params;
    tariff: ProductTariffRequests;
    canSubmitAddProductTariffForm: boolean;
    resultsSuccess: ProductDetail;
}

interface DispatchProps {
    onChange: (productTariff: ProductTariffRequests) => void;
    selectTariff: (modalId: string, index: number, onChange: OnChange, id?: number) => void;
    addProductTariff: (productTariff: ProductTariffRequests, productId: string) => void;
    clear: () => void;
}

type Props = DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    tariff: getProductTariff(s),
    canSubmitAddProductTariffForm: canSubmitAddProductTariffForm(s.prop("productTariffRequest")),
    resultsSuccess: s.prop("remote").prop(RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH) as ProductDetail,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    onChange: (productTariff: ProductTariffRequests) => dispatch(addProductTariff(productTariff)),
    selectTariff:
            (modalId: string, index: number, onChange: OnChange, id?: number) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <SelectTariffModal
                                    modalID={modalId}
                                    productTariffId={id}
                                    index={index}
                                    onChange={onChange}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
    addProductTariff: (productTariff: ProductTariffRequests, productId: string) =>
        dispatch(remoteTrigger(RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH, {
            productId: productId,
            productTariff: productTariff,
        })),
    clear: () => {
        dispatch(clearProductTariff());
        dispatch(remoteClearResponse(RemoteScope.TARIFF_DETAILS));
        dispatch(remoteClearResponse(RemoteScope.TARIFF_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_ADD_PRODUCT_TARIFF_PATCH));
    },
});

/**
 *
 * @param s
 */
export const getProductTariff =
    (s: ReduxState): ProductTariffRequests =>
        s.prop("productTariffRequest").map(e => ({
            ...e,
        }));

/**
 *
 * @param s
 */

/**
 *
 */
export class AddProductTariffComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = {};
        this.addProductTariff = this.addProductTariff.bind(this);
    }

    public componentDidMount() {
        this.props.clear();
    }

    public componentWillUnmount() {
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                        margin: {
                            "": { b: 4 },
                            "sm": { b: 5 },
                        },
                    }}
                >
                    {this.props.resultsSuccess ?
                        <Redirect to={`/extendedwarranties/${this.props.resultsSuccess.content.id}`} />
                        :
                        <>
                            <Header
                                title={SubPageDisplay[SubPages.PRODUCT_ACTIVE_ADD_PRODUCT_TARIFF].title}
                                goBack={`/extendedwarranties/${this.props.params.ewId}`}
                            />
                            <Form onSubmit={this.addProductTariff}>
                                <Div
                                    theme={{
                                        shadow: ThemeShadowSizes.TINY,
                                        margin: {
                                            "": { t: 2 },
                                            "sm": { t: 3 },
                                        },
                                    }}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    type="date"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("startingDate", value ? value.toString() : "")}
                                                    value={this.props.tariff.startingDate}
                                                    label="Starting Date"
                                                    required
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasValueValidation(value,
                                                                "Starting Date provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    type="date"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("endingDate", value ? value.toString() : "")}
                                                    value={this.props.tariff.endingDate}
                                                    label="Ending Date"
                                                    required
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasValueValidation(value,
                                                                "Ending Date provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    type="number"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("durationOfInsurance",
                                                            value ? Number(value) : undefined)}
                                                    value={this.props.tariff.durationOfInsurance}
                                                    label="Duration Of Insurance"
                                                    required
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            FormFields.hasValueValidation(value,
                                                                "Duration Of Insurance provided")}
                                                />
                                            </Grid>
                                        </Div>
                                        <FieldSet title="Selected Tariff line">
                                            {this.props.tariff.tariffLine?.id &&
                                                <DetailWrapper tariffId={this.props.tariff.tariffLine?.id} />
                                            }
                                            <Button
                                                link={{
                                                    onClick: () =>
                                                        this.props.selectTariff(
                                                            `${1}-modal`,
                                                            1,
                                                            this.onChange,
                                                            this.props.tariff.tariffLine?.id,
                                                        ),
                                                }}
                                                buttonType={"button"}
                                                theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            >
                                                {this.props.tariff.tariffLine?.id ? "Update tariff line" : "Select Tariff line"}
                                            </Button>
                                        </FieldSet>
                                        <FormBar
                                            buttonLabel={"Add product tariff"}
                                            title={"Add product tariff"}
                                            isValid={
                                                !!this.props.canSubmitAddProductTariffForm
                                                    ? this.props.canSubmitAddProductTariffForm
                                                    : undefined
                                            }
                                        />
                                    </Container>
                                </Div>
                            </Form>
                        </>
                    }
                </Container>
            </Page>
        );
    }

    private onChange =
        <K extends keyof ProductTariffRequests>(key: K, value: ProductTariffRequests[K]) => {
            this.props.onChange({
                [key]: value,
            });
        };

    private addProductTariff() {
        this.props.addProductTariff(
            this.props.tariff,
            this.props.params.ewId,
        );
    }
}

/**
 *
 */
export const AddProductTariff = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <AddProductTariffComp {...props}/>);
