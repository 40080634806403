/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Moment from "react-moment";
import { DetailModal } from "../DetailModalTariff";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { LoadingIndications, LoadingIndicator } from "../../../../../../components/core/05-atoms/LoadingIndicator";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages } from "../../../../../../store/AppDisplays";
import { RevisionTypeDisplay } from "../../../../../../store/AuditTrail/Displays";
import { AuditTrails } from "../../../../../../store/AuditTrail/Types";
import { ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Tariff } from "../../../../../../store/Tariffs/Types";
import { ThemeSize, ThemePalette, ThemeShadowSizes, ThemeBreakpoints } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";
import { getRevisionTypeColor } from "../../../../../utils/FormatHelpers";
import { Header } from "../../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../../components/core/09-views/01-sections/Page";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";

interface Params {
    tariffId: string;
}

/**
 *
 */
interface StateProps {
    params: Params;
    results: AuditTrails;
    loading: boolean;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (id: string, skip: number) => void;
    clearResults: () => void;
    openDetail: (tariff: Tariff) => void;
}

/**
 *
 */
type Props = DispatchProps & StateProps;

export const OpenDetailRequest = (tariff: Tariff) => (
    () => (
        <Modal
            modalID={tariff.id.toString()}
            theme={{ size: ThemeSize.HUGE }}
        >
            <DetailModal
                modalID={tariff.id.toString()}
                tariff={tariff}
            />
        </Modal>
    )
);

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    results: s.prop("remote").prop(RemoteScope.AUDIT_TRAIL_TARIFF) as AuditTrails,
    loading: isRemoteLoading(s, RemoteScope.AUDIT_TRAIL_TARIFF),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (id: string, skip: number) => dispatch(remoteTrigger(RemoteScope.AUDIT_TRAIL_TARIFF, {
        id: id,
        skip: skip,
    })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.AUDIT_TRAIL_TARIFF)),
        dispatch(remoteClearError(RemoteScope.AUDIT_TRAIL_TARIFF))
    ),
    openDetail: (tariff: Tariff) => {
        dispatch(
            modalShow(
                tariff.id.toString(),
                OpenDetailRequest(tariff),
            ),
        );
    },
});

/**
 *
 */
export class TariffAuditTrailDetailComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    public componentDidMount() {
        this.props.loadResults(this.props.params.tariffId, 0);
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    /**
     *
     */
    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={SubPageDisplay[SubPages.AUDIT_TRAIL_TARIFFS_DETAIL].title}
                        description={SubPageDisplay[SubPages.AUDIT_TRAIL_TARIFFS_DETAIL].description}
                        goBack={SubPageDisplay[SubPages.AUDIT_TRAIL_TARIFFS].path}
                    />
                    {this.props.loading ?
                        <LoadingIndicator
                            type={LoadingIndications.DEFAULT}
                            theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                        /> :
                        <Div
                            theme={{
                                shadow: ThemeShadowSizes.TINY,
                                palette: ThemePalette.CONTRAST_PRIMARY,
                            }}
                        >
                            <Table
                                hasActions
                                data={{
                                    columns: this.resultsData().columns,
                                    rows: this.resultsData().rows,
                                    sort: this.resultsData().sort,
                                }}
                            />
                            {(this.props.results?.totalPages && this.props.results?.totalPages > 1) ?
                                <Div
                                    className="scl-h-text-align--center"
                                    theme={{
                                        padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                                    }}
                                >
                                    <Pagination
                                        pageCount={this.props.results.totalPages}
                                        currentPage={this.props.results.number}
                                        onPageChange={this.handlePageClick}
                                        theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                    />
                                </Div> : null}
                        </Div>}
                </Container>
            </Page>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(this.props.params.tariffId,skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results?.content.map((row, index) => {
            const tariff = row.entity as Tariff;
            return ({
                id: `${row.entity.id}${index}`,
                data: [
                    (
                        <Badge
                            key={`badge-${row.entity.id}`}
                            theme={{ paletteState: getRevisionTypeColor(row.metadata.revisionType) }}>
                            {RevisionTypeDisplay[row.metadata.revisionType]}
                        </Badge>
                    ),
                    row.metadata.delegate.user,
                    <Moment
                        date={new Date(row.metadata.delegate.revisionDate)}
                        format={"DD-MM-YYYY HH:mm:ss"}
                        key={`revision-date${index}`}
                    />,
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <Button
                            key={`button-${row.entity.id}`}
                            icon={IconNames.VIEW}
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                shadow: ThemeShadowSizes.TINY,
                            }}
                            link={{ onClick: this.openDetail(tariff) }}
                        />
                    </ButtonsWrapper>,
                ],
            });
        });
    }

    private openDetail(tariff: Tariff) {
        return () => this.props.openDetail(tariff);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Revision type",
                },
                {
                    label: "User",
                },
                {
                    label: "Revision date",
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const TariffAuditTrailDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <TariffAuditTrailDetailComp {...props}/>);
