
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Form } from "../../../../../components/core/07-organisms/Form";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormBar } from "../../../../../components/core/07-organisms/FormBar";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages } from "../../../../../store/AppDisplays";
import { Brands } from "../../../../../store/Brand/Types";
import { updateModel, clearModel } from "../../../../../store/Models/CreateType";
import { ModelRequest, ModelDetails, canSubmitModelForm } from "../../../../../store/Models/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RefObject, RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette } from "../../../../../theme/_Types";
import { LoadingData } from "../../../../../components/core/09-views/00-blocks/LoadingData";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

interface OwnProps {
    edit: boolean;
    modelId: string;
}

interface StateProps {
    model: ModelRequest;
    loadingFormHelpers: boolean;
    formHelpers: {
        brandResults?: Brands;
    };
    canSubmitModelForm: boolean;
    resultsSuccess: ModelDetails;
}

interface DispatchProps {
    updateModel: (model: ModelRequest) => void;
    clear: () => void;
    loadFormHelpers: () => void;
    loadModelDetailResults: (id: string) => void;
    addModel: (model: ModelRequest) => void;
    editModel: (model: ModelRequest) => void;
}

type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    model: getModel(s),
    loadingFormHelpers: isRemoteLoading(s, RemoteScope.BRAND_RESULTS),
    formHelpers: {
        brandResults: s.prop("remote").prop(RemoteScope.BRAND_RESULTS),
    },
    canSubmitModelForm: canSubmitModelForm(s.prop("modelRequest")),
    resultsSuccess: (s.prop("remote").prop(RemoteScope.MODEL_CREATE)
            || s.prop("remote").prop(RemoteScope.MODEL_EDIT)) as ModelDetails,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    updateModel: (model: ModelRequest) => dispatch(updateModel(model)),
    clear: () => {
        dispatch(clearModel());
        dispatch(remoteClearResponse(RemoteScope.BRAND_RESULTS));
        dispatch(remoteClearResponse(RemoteScope.MODEL_CREATE));
        dispatch(remoteClearResponse(RemoteScope.MODEL_EDIT));
    },
    loadFormHelpers: () => {
        dispatch(remoteTrigger(RemoteScope.BRAND_RESULTS, { skip: 0 }));
    },
    addModel: (model: ModelRequest) => dispatch(remoteTrigger(RemoteScope.MODEL_CREATE, {
        model: model,
    })),
    editModel: (model: ModelRequest) => dispatch(remoteTrigger(RemoteScope.MODEL_EDIT, {
        model: model,
    })),
    loadModelDetailResults: (id: string) => dispatch(remoteTrigger(RemoteScope.MODEL_DETAILS, { id: id })),
});

/**
 *
 * @param s
 */
export const getModel =
    (s: ReduxState): ModelRequest =>
        s.prop("modelRequest").map(e => ({
            ...e,
        }));

/**
 *
 */
export class CreateModelComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = { created: false };

        this.addModel = this.addModel.bind(this);
        this.editModel = this.editModel.bind(this);
    }

    public componentDidMount() {
        this.props.clear();
        this.props.loadFormHelpers();
        if (this.props.edit) {
            this.props.loadModelDetailResults(this.props.modelId);
        }
    }

    public componentWillUnmount() {
        this.props.clear();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { t: 3, b: 5 },
                            "sm": { t: 4, b: 6 },
                        },
                    }}
                >
                    {this.props.edit ? (
                        <Header
                            title={SubPageDisplay[SubPages.MODEL_EDIT].title}
                            goBack={SubPageDisplay[SubPages.MANAGE_MODELS].path}
                        />
                    ) : (
                        <Header
                            title={SubPageDisplay[SubPages.MODEL_CREATE].title}
                            goBack={SubPageDisplay[SubPages.MANAGE_MODELS].path}
                        />
                    )}
                    {this.props.loadingFormHelpers ||
                        (
                            !this.props.formHelpers.brandResults
                        )
                        ? (
                            <LoadingData loading={this.props.loadingFormHelpers} />
                        )
                        : (<>
                            {this.props.resultsSuccess && (
                                <Redirect to={SubPageDisplay[SubPages.MANAGE_MODELS].path} />
                            )}
                            <Form onSubmit={this.props.edit ? this.editModel : this.addModel}>
                                <Div
                                    theme={{
                                        shadow: ThemeShadowSizes.TINY,
                                        margin: {
                                            "": { t: 2 },
                                            "sm": { t: 3 },
                                        },
                                    }}
                                >
                                    <Container
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            padding: {
                                                "": { y: 3 },
                                                "sm": { y: 4 },
                                            },
                                        }}
                                    >
                                        <Div className="scl-b-row">
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Name"
                                                    required
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("name", value ? value.toString() :
                                                            "")}
                                                    value={this.props.model.name}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value.toString(),
                                                                "Name provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    label="Description"
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("description", value ? value.toString() :
                                                            "")}
                                                    value={this.props.model.description}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value.toString(),
                                                                "Description provided")}
                                                />
                                            </Grid>
                                            <Grid
                                                size={{ xs: 12, md: 4 }}
                                            >
                                                <FormFields.FormField
                                                    onChange={(value?: string | number) =>
                                                        this.onChange("brand", value
                                                            ? { id: value } as RefObject : undefined)}
                                                    value={this.props.model.brand && this.props.model.brand.id
                                                        ? this.props.model.brand.id : undefined}
                                                    label="Brand"
                                                    required
                                                    placeholder="Make a choice"
                                                    readonly={this.props.edit}
                                                    disabled={this.props.edit}
                                                    options={
                                                        this.props.formHelpers.brandResults.content.map(item => ({
                                                            label: item.name,
                                                            value: Number(item.id),
                                                        }))}
                                                    validationFunction={
                                                        (value: string | number) =>
                                                            hasValueValidation(value.toString(),
                                                                "Brand selected")}
                                                />
                                            </Grid>
                                        </Div>
                                        <Required />
                                    </Container>
                                </Div>
                                <FormBar
                                    buttonLabel={this.props.edit ? "Update" : "Create"}
                                    title={this.props.edit ? "Edit model" : "Create model"}
                                    isValid={!!this.props.canSubmitModelForm ?
                                        this.props.canSubmitModelForm : undefined}
                                />
                            </Form>
                        </>
                        )}
                </Container>
            </Page>
        );
    }

    private onChange = <K extends keyof ModelRequest>(key: K, value: ModelRequest[K]) => {
        this.props.updateModel({
            [key]: value,
        });
    };

    private addModel() {
        this.props.addModel(this.props.model);
    }

    private editModel() {
        this.props.editModel(this.props.model);
    }
}

/**
 *
 */
export const CreateModel = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateModelComp);
