/* eslint-disable max-len */
import { Action } from "redux";

import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { FilterScope, FilterTypes } from "./FilterTypes";
import { ReducerFn } from "./ReduxState";
import { ClaimFilters } from "./Claims/Types";
import { ClaimMatchFilters } from "./ClaimMatch/Types";
import { IncassoFilters } from "./Incassos/Types";
import { StatementFilters } from "./Statements/Types";
import { GeneralLedgerFilters } from "./GeneralLedgers/Types";
import { PolicyFilters } from "./Policies/Types";
import { PartnerFilters } from "./Partners/Types";
import { CollectiveInvoiceFilters } from "./CollectiveInvoice/Types";
import { FileFilters } from "./Files/Types";
import { InvoiceFilters } from "./Invoice/Types";
import { ExcassoFilters } from "./Excassos/Types";
import { MBFilters } from "./ManualBooking/Types";
import { BookingPeriodFilters } from "./BookingPeriod/Types";
import { PaymentFilters } from "./PartnerPayments/Types";
import { MandateFilters } from "./Mandates/Types";
import { ProductFilters } from "./Product/Types";
import { TariffFilters } from "./Tariffs/Types";

/**
 *
 */
type UpdateFiltersAction = Readonly<
Action<ActionTypeKeys.UPDATE_FILTERS> & {
    filters?: Partial<FilterTypes>;
    scope?: FilterScope;
}
>;

/**
 *
 * @param filters
 */
export const updateFilters:
(
    filters: Partial<FilterTypes>,
    scope: FilterScope) => Thunk<void> =
    (filters, scope) => ((dispatch, _getState) => {
        const action: UpdateFiltersAction = {
            type: ActionTypeKeys.UPDATE_FILTERS,
            filters: filters,
            scope: scope,
        };
        dispatch(action);
    });

export const filteredFilter = (oldFilters: FilterTypes, newFilters: FilterTypes): FilterTypes => {
    const clearFilterKeys = Object.keys(newFilters).filter(key => (
        newFilters[key] === ""
    ));
    const updateFilterKeys = Object.keys(newFilters).filter(key => (
        newFilters[key] !== ""
    ));
    const filteredFilters: FilterTypes = {};
    Object.keys(oldFilters).filter(f => (
        !clearFilterKeys.includes(f)
    )).forEach(key => (
        filteredFilters[key] = oldFilters[key] as string
    ));
    updateFilterKeys.forEach(key => (
        filteredFilters[key] = newFilters[key] as string
    ));
    return filteredFilters;
};

/**
 *
 */
export const updateFiltersReducer:
ReducerFn<UpdateFiltersAction> =
    (s, a) => (
        a.scope ?
            a.scope === FilterScope.CLAIMS ?
                s.setProp(
                    "filters",
                    s.prop("filters").setProp(
                        a.scope,
                        s.prop("filters").prop(FilterScope.CLAIMS)
                            .update((oldFilters) => (
                                filteredFilter(oldFilters, a.filters ?? {}) as ClaimFilters
                            )),
                    )) :
                a.scope === FilterScope.CLAIM_MATCH ?
                    s.setProp(
                        "filters",
                        s.prop("filters").setProp(
                            a.scope,
                            s.prop("filters").prop(FilterScope.CLAIM_MATCH)
                                .update((oldFilters) => (
                                    filteredFilter(oldFilters, a.filters ?? {}) as ClaimMatchFilters
                                )),
                        )) :
                    a.scope === FilterScope.INCASSOS ?
                        s.setProp(
                            "filters",
                            s.prop("filters").setProp(
                                a.scope,
                                s.prop("filters").prop(FilterScope.INCASSOS)
                                    .update((oldFilters) => (
                                        filteredFilter(oldFilters, a.filters ?? {}) as IncassoFilters
                                    )),
                            )) :
                        a.scope === FilterScope.EXCASSOS ?
                            s.setProp(
                                "filters",
                                s.prop("filters").setProp(
                                    a.scope,
                                    s.prop("filters").prop(FilterScope.EXCASSOS)
                                        .update((oldFilters) => (
                                            filteredFilter(oldFilters, a.filters ?? {}) as ExcassoFilters
                                        )),
                                )) :
                            a.scope === FilterScope.STATEMENTS ?
                                s.setProp(
                                    "filters",
                                    s.prop("filters").setProp(
                                        a.scope,
                                        s.prop("filters").prop(FilterScope.STATEMENTS)
                                            .update((oldFilters) => (
                                                filteredFilter(oldFilters, a.filters ?? {}) as StatementFilters
                                            )),
                                    )) :
                                a.scope === FilterScope.POLICIES ?
                                    s.setProp(
                                        "filters",
                                        s.prop("filters").setProp(
                                            a.scope,
                                            s.prop("filters").prop(FilterScope.POLICIES)
                                                .update((oldFilters) => (
                                                    filteredFilter(oldFilters, a.filters ?? {}) as PolicyFilters
                                                )),
                                        )) :
                                    a.scope === FilterScope.PARTNERS ?
                                        s.setProp(
                                            "filters",
                                            s.prop("filters").setProp(
                                                a.scope,
                                                s.prop("filters").prop(FilterScope.PARTNERS)
                                                    .update((oldFilters) => (
                                                        filteredFilter(oldFilters, a.filters ?? {}) as PartnerFilters
                                                    )),
                                            )) :
                                        a.scope === FilterScope.GENERAL_LEDGER ?
                                            s.setProp(
                                                "filters",
                                                s.prop("filters").setProp(
                                                    a.scope,
                                                    s.prop("filters").prop(FilterScope.GENERAL_LEDGER)
                                                        .update((oldFilters) => (
                                                            filteredFilter(oldFilters, a.filters ?? {}) as GeneralLedgerFilters
                                                        )),
                                                )) :
                                            a.scope === FilterScope.FILES ?
                                                s.setProp(
                                                    "filters",
                                                    s.prop("filters").setProp(
                                                        a.scope,
                                                        s.prop("filters").prop(FilterScope.FILES)
                                                            .update((oldFilters) => (
                                                                filteredFilter(oldFilters, a.filters ?? {}) as FileFilters
                                                            )),
                                                    )) :
                                                a.scope === FilterScope.INVOICES ?
                                                    s.setProp(
                                                        "filters",
                                                        s.prop("filters").setProp(
                                                            a.scope,
                                                            s.prop("filters").prop(FilterScope.INVOICES)
                                                                .update((oldFilters) => (
                                                                    filteredFilter(oldFilters, a.filters ?? {}) as InvoiceFilters
                                                                )),
                                                        )) :
                                                    a.scope === FilterScope.MANUAL_BOOKING ?
                                                        s.setProp(
                                                            "filters",
                                                            s.prop("filters").setProp(
                                                                a.scope,
                                                                s.prop("filters").prop(FilterScope.MANUAL_BOOKING)
                                                                    .update((oldFilters) => (
                                                                        filteredFilter(oldFilters, a.filters ?? {}) as MBFilters
                                                                    )),
                                                            )) :
                                                        a.scope === FilterScope.BOOKING_PERIOD ?
                                                            s.setProp(
                                                                "filters",
                                                                s.prop("filters").setProp(
                                                                    a.scope,
                                                                    s.prop("filters").prop(FilterScope.BOOKING_PERIOD)
                                                                        .update((oldFilters) => (
                                                                            filteredFilter(oldFilters, a.filters ?? {}) as BookingPeriodFilters
                                                                        )),
                                                                )) :
                                                            a.scope === FilterScope.MANDATES ?
                                                                s.setProp(
                                                                    "filters",
                                                                    s.prop("filters").setProp(
                                                                        a.scope,
                                                                        s.prop("filters").prop(FilterScope.MANDATES)
                                                                            .update((oldFilters) => (
                                                                                filteredFilter(oldFilters, a.filters ?? {}) as MandateFilters
                                                                            )),
                                                                    )) :
                                                                a.scope === FilterScope.TARIFFS ?
                                                                    s.setProp(
                                                                        "filters",
                                                                        s.prop("filters").setProp(
                                                                            a.scope,
                                                                            s.prop("filters").prop(FilterScope.TARIFFS)
                                                                                .update((oldFilters) => (
                                                                                    filteredFilter(oldFilters, a.filters ?? {}) as TariffFilters
                                                                                )),
                                                                        )) :
                                                                    s.setProp(
                                                                        "filters",
                                                                        s.prop("filters").setProp(
                                                                            a.scope,
                                                                            s.prop("filters").prop(FilterScope.COLLECTIVE_INVOICES)
                                                                                .update((oldFilters) => (
                                                                                    filteredFilter(oldFilters, a.filters ?? {}) as CollectiveInvoiceFilters
                                                                                )),
                                                                        )) : s
    );

/**
 *
 */
type ClearFiltersAction = Readonly<
Action<ActionTypeKeys.CLEAR_FILTERS> &
{
    keys?: Array<keyof StatementFilters> | Array<keyof ClaimFilters> |
    Array<keyof ClaimMatchFilters> | Array<keyof CollectiveInvoiceFilters> |
    Array<keyof IncassoFilters> | Array<keyof GeneralLedgerFilters> |
    Array<keyof PartnerFilters> | Array<keyof PolicyFilters> |
    Array<keyof FileFilters> | Array<keyof InvoiceFilters> | Array<keyof ExcassoFilters>
    | Array<keyof MBFilters> | Array<keyof BookingPeriodFilters> | Array<keyof PaymentFilters> |
    Array<keyof MandateFilters> | Array<keyof ProductFilters> | Array<keyof TariffFilters>;
    scope?: FilterScope;
}
>;

/**
 *
 * @param keys
 */
export const clearFilters:
(
    keys?: Array<keyof StatementFilters> | Array<keyof ClaimFilters> | Array<keyof CollectiveInvoiceFilters> |
    Array<keyof IncassoFilters> | Array<keyof GeneralLedgerFilters> |
    Array<keyof ClaimMatchFilters> | Array<keyof PartnerFilters> | Array<keyof PolicyFilters> |
    Array<keyof FileFilters> | Array<keyof InvoiceFilters> | Array<keyof ExcassoFilters> |
    Array<keyof MBFilters> | Array<keyof BookingPeriodFilters> | Array<keyof PaymentFilters> |
    Array<keyof MandateFilters> | Array<keyof ProductFilters> | Array<keyof TariffFilters>,
    scope?: FilterScope) => ClearFiltersAction =
    (keys, scope) => ({
        type: ActionTypeKeys.CLEAR_FILTERS,
        keys: keys,
        scope: scope,
    });

/**
 *
 */
export const clearFiltersReducer:
ReducerFn<ClearFiltersAction> =
    (s, a) => (
        a.scope
            ?
            s.setProp(
                "filters",
                s.prop("filters").setProp(
                    a.scope,
                    s.prop("filters").prop(a.scope).clear(),
                ),
            )
            :
            s
    );
