/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";

import { ReduxState } from "../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { getPartnerTypeColor } from "../../../../utils/FormatHelpers";
import { PageTabs } from "../../../../../store/AppDisplays";
import { StatementDetails, Entry as StatementLine, CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { ConfirmBookingModal } from "../Accounts/Statements/ConfirmBookingModal";
import { Partners, BookPartner } from "../../../../../store/Partners/Types";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import {
    amIChecked, myAmount, statementLineBalance, calcUnclearedBalanceAfter, validSelectedRow, calcIsValidBookBar,
} from "../../../../utils/Booking";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ThemeSize, ThemePalette, ThemePaletteState, ThemeShadowSizes } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { BookBar } from "../../../07-organisms/BookBar";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Container } from "../../../../../components/core/03-base/Container";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";

export interface SelectedRow {
    id: number;
    amount: number;
}

interface State {
    selectedRows: SelectedRow[];
}

/**
 *
 */
interface StateProps {
    details: StatementDetails;
}

/**
 *
 */
interface OwnProps {
    results: Partners;
    statementLineId?: string;
    currentTab?: PageTabs;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    bookPartners: (id: string, patch: BookPartner) => void;
    openBookRequest: (statementLineId: string, callBack: () => void) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

export const OpenBookReq =
    (statementLineID: string, callBack: () => void) => (
        () => (
            <Modal
                modalID={statementLineID}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmBookingModal
                    modalID={statementLineID}
                    statementLineId={statementLineID}
                    onBookConfirmed={callBack}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    details: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementDetails,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.PARTNER_RESULTS, {
        skip: skip,
    })),
    bookPartners: (id: string, patch: BookPartner) => (
        dispatch(remoteTrigger(RemoteScope.PARTNER_BOOK_PATCH, {
            id: id,
            patch: patch,
        }))
    ),
    openBookRequest: (statementLineId: string, callBack: () => void) => dispatch(
        modalShow(
            statementLineId,
            OpenBookReq(statementLineId, callBack),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.confirmedBooking = this.confirmedBooking.bind(this);
        this.bookBarCallBack = this.bookBarCallBack.bind(this);
        this.confirmedBookingCB = this.confirmedBookingCB.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        const statementLine = this.props.details
            && this.props.details.content.entries
            && this.props.details.content.entries.length > 0 ? this.props.details.content.entries.find(line =>
                line.id.toString() === this.props.statementLineId) : undefined;

        const selectedBalance = this.state.selectedRows.map(row => row.amount)
            .reduce(
                (cur, next) => cur + next, 0);

        if (!statementLine) {
            return null;
        }

        const unclearedBalance = statementLine.balance;

        const unclearedBalanceAfter = calcUnclearedBalanceAfter(unclearedBalance, selectedBalance,
            statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT);

        const isValid = calcIsValidBookBar(
            this.state.selectedRows.length,
            unclearedBalance,
            selectedBalance,
            statementLine.creditDebitIndicator);

        return (
            <Form onSubmit={this.bookBarCallBack(statementLine)} isValid={isValid}>
                <BookBar
                    title={"Booking Partners"}
                    selectedBalance={selectedBalance}
                    unclearedBalanceAfter={unclearedBalanceAfter}
                    unclearedBalance={unclearedBalance}
                    isValid={isValid}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData(statementLine).columns,
                        rows: this.resultsData(statementLine).rows,
                        sort: this.resultsData(statementLine).sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </Form>
        );
    }

    private bookBarCallBack(statementLine: StatementLine) {
        return () => this.props.openBookRequest(
            statementLine.id.toString(),
            this.confirmedBookingCB(statementLine));
    }

    private confirmedBookingCB(statementLine: StatementLine) {
        return () => this.confirmedBooking(statementLine);
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(statementLine: StatementLine): RowData[] {
        return this.props.results.content.map((row, index) => {
            const unclearedBalance = statementLineBalance(
                statementLine.balance,
                statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT);
            return ({
                id: row.id,
                themePalleteState: validSelectedRow(unclearedBalance, myAmount(row.id, this.state.selectedRows))
                    ? undefined : ThemePaletteState.DANGER,
                tooltip: validSelectedRow(
                    unclearedBalance,
                    myAmount(row.id, this.state.selectedRows)) ? undefined : "The selected balance is not valid",
                data: [
                    (
                        <div
                            key={`selection-${row.id}`}
                            className="scl-row-selection"
                            style={{
                                display: "inline-flex",
                                flexDirection: "row",
                                flexGrow: 0,
                                alignItems: "center",
                            }}
                        >
                            <div
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                <Checkbox
                                    checked={amIChecked(row.id, this.state.selectedRows)}
                                    onChange={(value?: boolean) => this.selectItem(value, row.id,
                                        unclearedBalance)}
                                />
                            </div>
                            <div
                                className="scl-row-selection__input"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                }}
                            >
                                {amIChecked(row.id, this.state.selectedRows) &&
                                    <FormField
                                        type="currency"
                                        placeholder="e.g. 100"
                                        onChange={(value?: number | string) => {
                                            this.onChangeAmount(row.id,
                                                value ? typeof value === "string"
                                                    ? parseFloat(value) : value : 0);
                                        }}
                                        value={myAmount(row.id, this.state.selectedRows)}
                                    />
                                }
                            </div>
                        </div>
                    ),
                    row.name ?? "",
                    row.partnerNumber,
                    row.partnerType ? (
                        <Badge
                            theme={{
                                palette: getPartnerTypeColor(row.partnerType),
                                shadow: ThemeShadowSizes.TINY,
                            }}
                        >
                            {PartnerTypeDisplay[row.partnerType]}
                        </Badge>
                    ) : "",
                    row.iban,
                    <Currency amount={row.invoiceBalance} key={`partner-${index}`} />,
                    <Currency amount={row.claimBalance} key={`partner-${index}`} />,
                    (
                        <h6 key={`total-balance-${index}`}>
                            <Currency amount={row.totalBalance} key={`partner-${index}`} />
                        </h6>
                    ),
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData(statementLine: StatementLine) {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Name",
                },
                {
                    label: "Number",
                },
                {
                    label: "Type",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Invoice balance",
                },
                {
                    label: "Claim balance",
                },
                {
                    label: "Total balance",
                    alignRight: true,
                },
            ],
            rows: this.getProcessedRows(statementLine),
        };
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: [],
        };
    }

    private selectItem(value: boolean | undefined, id: number, amount: number) {
        this.setState({
            selectedRows: !!value ? [{
                id: id,
                amount: amount,
            }] : [],
        });
    }

    private onChangeAmount = (id: number, value: number) => {
        this.setState({
            selectedRows: this.state.selectedRows.map(row => {
                if (row.id === id) {
                    return ({
                        id: row.id,
                        amount: value,
                    });
                }

                return row;
            }),
        });
    };

    private confirmedBooking = (statementLine: StatementLine) => {
        this.props.bookPartners(this.props.details.content.id.toString(), {
            entryId: statementLine.id,
            paidObjectId: this.state.selectedRows[0].id,
            paidAmount: this.state.selectedRows[0].amount,
        });
    };
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
