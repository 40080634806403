import { AxiosResponse } from "axios";
import { Accounts, AccountDetails } from "./Types";

export const mapAccountRequests =
(resp: AxiosResponse): Accounts => resp.data as Accounts;

/**
 *
 * @param resp
 */
export const mapAccountDetailsRequests =
    (resp: AxiosResponse): AccountDetails => resp.data as AccountDetails;
