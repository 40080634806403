import { Action } from "redux";
import { State } from "../../utils/redux/State";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { AccountRequest } from "./Types";

/**
 *
 */
export type CreateAccountAction = Readonly<
Action<ActionTypeKeys.CREATE_ACCOUNT> & {
    account?: Partial<AccountRequest>;
}
>;

/**
 *
 *
 */
export const createAccount =
    (account: AccountRequest): CreateAccountAction => ({
        type: ActionTypeKeys.CREATE_ACCOUNT,
        account: account,
    });

/**
 *
 * @param s
 * @param a
 */
export const createAccountReducer:
ReducerFn<CreateAccountAction> =
    (s, a) => (
        s.setProp(
            "accountRequest",
            s.prop("accountRequest").updateWith(
                a.account ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearAccountAction = Readonly<
Action<ActionTypeKeys.CLEAR_ACCOUNT>
>;

/**
 *
 * @param keys
 */
export const clearAccount:
() => ClearAccountAction =
    () => ({
        type: ActionTypeKeys.CLEAR_ACCOUNT,
    });

/**
 *
 */
export const clearAccountReducer:
ReducerFn<ClearAccountAction> =
    (s, _a) => (
        s.setProp(
            "accountRequest",
            State.create<AccountRequest>(() => ({})),
        )
    );
