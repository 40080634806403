/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../../../../components/core/09-views/01-sections/Page";
import { Header } from "../../../../../../components/core/09-views/01-sections/Header";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { Blockquote } from "../../../../../../components/core/05-atoms/Blockquote";
import { Icon, IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { LoadingIndications, LoadingIndicator } from "../../../../../../components/core/05-atoms/LoadingIndicator";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { Card } from "../../../../../../components/core/07-organisms/Card";
import { DragNDrop } from "../../../../../../components/core/07-organisms/DragNDrop";
import { Markdown } from "../../../../../../components/core/07-organisms/RTE";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages } from "../../../../../../store/AppDisplays";
import { RemoteErrors, ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { ThemeShadowSizes, ThemePalette, ThemeBreakpoints } from "../../../../../../theme/_Types";
import { modalClose, modalShow } from "../../../../../../utils/redux/ActionTypes";
import { getErrorValidationMessage } from "../../../../../utils/ErrorMessage";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import {ModalNotification} from "../../../../../../components/core/09-views/03-modals/Notification";


interface Params {
    accountId: string;
}

interface State {
    ready: boolean;
    loading: boolean;
}

interface DispatchProps {
    uploadStatement: (getBinary: string | ArrayBuffer | null) => void;
    openErrorModal: (message: string) => void;
    clearResults: () => void;
}

/**
 *
 */
interface StateProps {
    params: Params;
    isRemoteLoading: boolean;
    resultsErrorGenerate?: RemoteErrors;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => {
    const error = s.prop("remoteErrors");
    return {
        params: p.match.params as Params,
        isRemoteLoading: isRemoteLoading(s, RemoteScope.FILE_UPLOAD),
        resultsErrorGenerate:
            getErrorValidationMessage(error, RemoteScope.FILE_UPLOAD),
    };
};

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    uploadStatement:
        (getBinary: string | ArrayBuffer | null) => dispatch(remoteTrigger(RemoteScope.FILE_UPLOAD, {
            file: getBinary,
        })),
    openErrorModal: (message: string) => dispatch(modalShow("uploadwarning", () => (
        <ModalNotification
            id={"uploadwarning"}
            onCloseModal={() => modalClose("uploadwarning")}
            message={message} />
    ))),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.FILE_UPLOAD)),
        dispatch(remoteClearError(RemoteScope.FILE_UPLOAD))
    ),
});

/**
 *
 * @param props
 */
export class UploadComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            ready : false,
            loading: false,
        };
        this.onReceivedFile = this.onReceivedFile.bind(this);
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >

                    <Header
                        title={SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_DETAIL].title}
                        goBack={"/accounts/" + this.props.params.accountId}
                    />

                    <Header
                        title={SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_UPLOAD].title}
                        description={SubPageDisplay[SubPages.ACCOUNTS_DETAIL_STATEMENTS_UPLOAD].description}
                    />

                    <Div
                        theme={{
                            shadow: ThemeShadowSizes.TINY,
                            margin: {
                                "": {t: 2},
                                "sm": {t: 3},
                            }}}
                    >
                        <Card
                            title={this.state.loading ? "Loading..." :
                                this.props.resultsErrorGenerate
                                    ? "Upload failed" : this.state.ready ? "Statement processed"
                                        : "Upload"}
                            collapsable={false}
                            defaultCollapsed={false}
                        >
                            <Container
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    padding: {
                                        "": { y: 3 },
                                        "sm": { y: 4 },
                                    },
                                }}
                            >
                                {this.props.isRemoteLoading
                                    ? (
                                        <LoadingIndicator
                                            type={LoadingIndications.DEFAULT}
                                            theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                        />
                                    )
                                    : (
                                        !this.state.ready ?
                                            <DragNDrop
                                                onInvalid={this.props.openErrorModal}
                                                onReceivedFile={this.onReceivedFile}
                                            />
                                            :
                                            <>
                                                {this.props.resultsErrorGenerate ? (
                                                    <>
                                                        <Blockquote>
                                                            <h4>
                                                                {this.props.resultsErrorGenerate
                                                                    ? this.props.resultsErrorGenerate.message
                                                                    : "Something went wrong"},
                                                            </h4><br/>
                                                            <div className="scl-h-text--big">
                                                                    We couldn&apos;t upload the statement.</div>
                                                            <br/>
                                                            <div className="scl-h-text--big">
                                                Claude suggests to continue and try again.
                                                            </div>
                                                        </Blockquote>
                                                        <ButtonsWrapper
                                                            orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                            alignment={Alignment.RIGHT}
                                                        >
                                                            <NavLink
                                                                to={`/accounts/${this.props.params.accountId}`}
                                                                exact
                                                            >
                                                                <Button
                                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                                    className="scl-a-btn--big"
                                                                    buttonType="submit"
                                                                >
                                                Continue
                                                &nbsp;&nbsp;
                                                                    <Icon name={IconNames.ARROW_RIGHT} />
                                                                </Button>
                                                            </NavLink>
                                                        </ButtonsWrapper>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Div className="scl-b-row">
                                                            <Grid size={{ xs: 12, md: 12}}>
                                                                <Markdown content={"#### Finished"}/>
                                                                <div className="scl-o-rte">
                                                                    <p className="scl-h-text--huge">
                                                        The statement has been uploaded successfully.
                                                        It could take some time for the statement to be fully processed.
                                                                        <br />
                                                        In case you still don&apos;t see the uploaded statement after you have continued,
                                                        please navigate to the &quot;Files&quot; page to check if the statement was received by the system.
                                                                    </p>
                                                                </div>
                                                            </Grid>
                                                        </Div>
                                                        <ButtonsWrapper
                                                            orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                            alignment={Alignment.RIGHT}
                                                        >
                                                            <NavLink
                                                                to={`/accounts/${this.props.params.accountId}`}
                                                                exact
                                                            >
                                                                <Button
                                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                                    className="scl-a-btn--big"
                                                                    buttonType="submit"
                                                                >
                                                Continue
                                                &nbsp;&nbsp;
                                                                    <Icon name={IconNames.ARROW_RIGHT} />
                                                                </Button>
                                                            </NavLink>
                                                        </ButtonsWrapper></>)}
                                            </>
                                    )}
                            </Container>
                        </Card>
                    </Div>
                </Container>
            </Page>
        );
    }

    private onReceivedFile(getBinary: string | ArrayBuffer | null) {
        this.props.uploadStatement(getBinary);
        if (this.props.isRemoteLoading) {
            setTimeout(() => this.setState({
                ready: true,
            }), 300);
        }
    }
}

/**
 *
 */
export const Upload = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <UploadComp {...props}/>);
