import * as React from "react";
import { connect } from "react-redux";
import { Div } from "../../../../../components/core/03-base/Div";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { Card } from "../../../../../components/core/07-organisms/Card";
import { ErrorLevelDisplay } from "../../../../../store/Policies/Displays";
import { PolicyBusinessRuleViolation, ErrorLevel } from "../../../../../store/Policies/Types";
import { ThemeShadowSizes, ThemePaletteState } from "../../../../../theme/_Types";

/**
 *
 */
interface OwnProps {
    errors: PolicyBusinessRuleViolation[];
}

/**
 *
 */
type Props = OwnProps;

/**
 *
 */
export class ErrorDetailComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    public render() {
        return (
            <Div theme={{ shadow: ThemeShadowSizes.TINY, paletteState: ThemePaletteState.WARNING }}>
                <Card title="Error log" collapsable defaultCollapsed={false}>
                    <Table
                        hasActions
                        data={{
                            columns: this.resultsData().columns,
                            rows: this.resultsData().rows,
                            sort: this.resultsData().sort,
                        }}
                    />
                </Card>
            </Div>
        );
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.errors.map((row, index) => ({
            id: row.id,
            data: [
                row.errorDescription,
                (
                    <Badge
                        key={`p-badge-${index}`}
                        theme={{
                            paletteState:
                                row.errorLevel === ErrorLevel.CRITICAL
                                    ? ThemePaletteState.DANGER
                                    : ThemePaletteState.WARNING,
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        {ErrorLevelDisplay[row.errorLevel]}
                    </Badge>
                ),
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Error description",
                },
                {
                    label: "Error level",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const ErrorDetail = connect(
    undefined,
    {},
)(ErrorDetailComp);
