import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { Accounts } from "../../../../../store/Accounts/Types";
import { NavLink } from "react-router-dom";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import Moment from "react-moment";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeBreakpoints, ThemePalette, ThemePaletteState, ThemeShadowSizes } from "../../../../../theme/_Types";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: Accounts;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.ACCOUNT_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results?.totalPages && this.props.results?.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.name,
                row.iban,
                row?.generalLedger?.accountCode ?? "",
                (row.allBooked ?
                    <Badge theme={{ paletteState: ThemePaletteState.SUCCESS }} >Yes</Badge> :
                    <Badge theme={{ paletteState: ThemePaletteState.DANGER }} >No</Badge>
                ),
                row.mutationDate ?
                    (
                        <Moment
                            date={new Date(row.mutationDate)}
                            format={"DD-MM-YYYY"}
                            key={`mutation-date${index}`}
                        />
                    ) : "",
                (
                    <h6 key={`account-balance-${index}`}>
                        <Currency amount={row.balance} />
                    </h6>),
                (
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <NavLink
                            to={`/accounts/${row.id}`}>
                            <Button
                                icon={IconNames.ARROW_RIGHT}
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            />
                        </NavLink>
                    </ButtonsWrapper>
                ),
            ],
        }));
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Name",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "GL Account code",
                },
                {
                    label: "All Booked",
                },
                {
                    label: "Mutation date",
                },
                {
                    label: "Balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
