import React from "react";
import { connect } from "react-redux";
import { Header } from "../../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";
import { Results } from "../../00-blocks/ManualBookings/Create/Results";
import { Container } from "../../../../../components/core/03-base/Container";
import { Div } from "../../../../../components/core/03-base/Div";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages, PageTabs } from "../../../../../store/AppDisplays";
import { clearManualBookings } from "../../../../../store/ManualBooking/CreateType";
import { Tabs } from "../../00-blocks/Tabs";


/**
 *
 */
interface DispatchProps {
    clearNewManualBooking: () => void;
}

type Props = DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc ): DispatchProps => ({
    clearNewManualBooking: () => (
        dispatch(clearManualBookings())
    ),
});

/**
 *
 */
export class CreateManualBookingComp
    extends React.Component<Props, {}> {

    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.clearNewManualBooking();
    }

    public componentWillUnmount() {
        this.props.clearNewManualBooking();
    }
    /**
     *
     */
    public render() {
        const getTabs = () => SubPageDisplay[SubPages.MANUAL_BOOKING_CREATE].tabs;

        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header
                        title={PageDisplay[Pages.MANUAL_BOOKING].title}
                        goBack={PageDisplay[Pages.MANUAL_BOOKING].path}
                    />
                    <Header
                        title={SubPageDisplay[SubPages.MANUAL_BOOKING_CREATE].title}
                    />
                    <Results/>
                    <Div
                        theme={{
                            padding: {
                                "": { b: 5 },
                                "sm": { b: 6 },
                            },
                        }}
                    >
                        <Tabs
                            tabs={getTabs()}
                            defaultTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNER}
                        />
                    </Div>
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const CreateManualBooking = connect(
    undefined,
    mapDispatchToProps,
)(CreateManualBookingComp);
