/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import Moment from "react-moment";
import { PageTabs } from "../../../../../store/AppDisplays";
import { Memos } from "../../../../../store/Memos/Types";
import { UpdateMemoModal } from "../Policies/UpdateMemoModal";
import { DeleteMemoModal } from "./DeleteMemoModal";
import { UserInfo, UserRoles } from "../../../../../store/AppTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePalette, ThemeBreakpoints,
    ThemeShadowSizes, ThemePaletteState } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";

/**
 *
 */
interface OwnProps {
    results: Memos;
    currentTab?: PageTabs;
    // policyId?: string;
}

/**
 *
 */
interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openMemoRequest: (edit: boolean, memoId: string) => void;
    openDeleteRequest: (memoId: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

export const OpenMemoReq =
    (edit: boolean, memoId: string) => (
        () => (
            <Modal
                modalID={memoId}
                theme={{ size: ThemeSize.SMALL }}
            >
                <UpdateMemoModal
                    modalID={memoId}
                    memoId={memoId}
                    edit={edit}
                />
            </Modal>
        )
    );

export const OpenDeleteReq =
    (memoId: string) => (
        () => (
            <Modal
                modalID={memoId}
                theme={{ size: ThemeSize.SMALL }}
            >
                <DeleteMemoModal
                    modalID={memoId}
                    memoId={memoId}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.MEMO_RESULTS, {
        skip: skip,
    })),
    openMemoRequest: (edit: boolean, memoId: string) => dispatch(
        modalShow(
            memoId,
            OpenMemoReq(edit, memoId),
        ),
    ),
    openDeleteRequest: (memoId: string) => dispatch(
        modalShow(
            memoId,
            OpenDeleteReq(memoId),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openMemoReq = this.openMemoReq.bind(this);
        this.openDelReq = this.openDelReq.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                (
                    <Moment
                        date={new Date(row.createdAt)}
                        format={"DD-MM-YYYY"}
                        key={`created-date${index}`}
                    />
                ),
                row.description,
                this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    (
                        <ButtonsWrapper
                            alignment={Alignment.RIGHT}
                            asGroup
                            orientations={{
                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                            }}
                        >
                            <Button
                                link={{ onClick: this.openMemoReq(true, row.id.toString()) }}
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            >
                            Edit
                            </Button>
                            <Button
                                icon={IconNames.DELETE}
                                link={{ onClick: this.openDelReq(row.id.toString()) }}
                                theme={{
                                    paletteState: ThemePaletteState.DANGER,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            />
                        </ButtonsWrapper>
                    ) : "",
            ],
        }));
    }

    private openMemoReq(edit: boolean, memoId: string) {
        return () => this.props.openMemoRequest(edit, memoId);
    }

    private openDelReq(memoId: string) {
        return () => this.props.openDeleteRequest(memoId);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Created at",
                },
                {
                    label: "Description",
                },
                this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    {
                        label: "Actions",
                    } :
                    {
                        label: "",
                    },
                // {
                //     label: "Actions",
                // },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
