import { RefObject, RequestObject } from "../RemoteTypes";
import { CoverageLevel } from "../Policies/Types";
import { hasValue } from "../Product/Types";
import { State } from "../../utils/redux/State";
import { Model } from "../Models/Types";

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type TariffFilters = Readonly<Partial<{
    passengerVehicle: boolean;
    commercialVehicle: boolean;
    maxKW: number;
    modelId: number;
    modelName?: string;
}>>;

export interface Tariff {
    id: number;
    createdAt?: string;
    description: string;
    passengerVehicle: boolean;
    commercialVehicle: boolean;
    model?: Model;
    maxKW: number;
    maxMileage: number;
    tariffPeriod: number;
    coverageLevel: CoverageLevel;
    premium: number;
    tax: number;
    netPremium: number;
    cost: number;
    netPremiumExCost: number;
    commissionVWPFS: number;
    netMij: number;
    frontier: number;
    insurer: number;
    signCommitteeDamage: number;
    incassoProvision: number;
}

export interface Tariffs extends RequestObject {
    content: Tariff[];
}

export interface TariffDetails {
    content: Tariff;
}

export interface TariffRequest {
    id?: number;
    description?: string;
    passengerVehicle?: boolean;
    commercialVehicle?: boolean;
    model?: RefObject;
    maxKW?: number; // mandatory
    maxMileage?: number; // mandatory
    tariffPeriod?: number; // mandatory
    coverageLevel?: CoverageLevel; // mandatory
    premium?: number; // mandatory
    tax?: number; // mandatory
    netPremium?: number; // mandatory
    cost?: number; // mandatory
    netPremiumExCost?: number; // mandatory
    commissionVWPFS?: number;
    netMij?: number; // mandatory
    frontier?: number; // mandatory
    insurer?: number; // mandatory
    signCommitteeDamage?: number; // mandatory
    incassoProvision?: number; // mandatory
}

export const canSubmitTariffForm =
    (tariffRequest: State<TariffRequest>): boolean => {
        const validMandatoryTariffFields = tariffRequest.map(f => {
            const mandatoryTariffField = Object.keys(f).filter(k =>
                (k === "maxKW" && hasValue(f[k]))
                                            || (k === "maxMileage" && hasValue(f[k]))
                                            || (k === "tariffPeriod" && hasValue(f[k]))
                                            || (k === "coverageLevel" && hasValue(f[k]))
                                            || (k === "premium" && hasValue(f[k]))
                                            || (k === "tax" && hasValue(f[k]))
                                            || (k === "netPremium" && hasValue(f[k]))
                                            || (k === "cost" && hasValue(f[k]))
                                            || (k === "netPremiumExCost" && hasValue(f[k]))
                                            || (k === "commissionVWPFS" && hasValue(f[k]))
                                            || (k === "netMij" && hasValue(f[k]))
                                            || (k === "frontier" && hasValue(f[k]))
                                            || (k === "insurer" && hasValue(f[k]))
                                            || (k === "signCommitteeDamage" && hasValue(f[k]))
                                            || (k === "incassoProvision" && hasValue(f[k])));

            return (
                mandatoryTariffField
            );
        }).length === 15;
        const isPassengerOrCommerical = tariffRequest.prop("passengerVehicle") === true
            || tariffRequest.prop("commercialVehicle") === true;
        return (validMandatoryTariffFields && isPassengerOrCommerical);
    };
