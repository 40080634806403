import * as React from "react";
import { RowData, ColumnData } from "./Table";
import { ThemePaletteState } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { Tooltip } from "../05-atoms/Tooltip";
import { createPalleteStateClassName } from "../../../theme/Theme";

interface State {
    showTooltip: boolean;
}

interface OwnProps {
    rowData: RowData;
    even?: boolean;
    hasActions?: boolean;
    dataColumns?: ColumnData[];
    themePalleteState?: ThemePaletteState;
}

type Props =  OwnProps;

export class TableRow
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            showTooltip: false,
        };

        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
    }

    public render(): JSX.Element {
        const clazzName = joinClasses(
            "scl-m-table__tr",
            !!this.props.even ? "scl-m-table__tr--even" : undefined,
            createPalleteStateClassName(this.props.themePalleteState),
        );

        return (
            <>
                { !!this.props.rowData.tooltip && this.state && this.state.showTooltip ?
                    <tr
                        className="scl-m-table__tr"
                        style={{height: "0", borderSpacing: "0"}}
                    >
                        <td
                            style={{padding: "0"}}
                            colSpan={this.props.rowData.data.length}
                            className={"scl-m-table__td"}
                        >
                            <Tooltip>
                                {this.props.rowData.tooltip}
                            </Tooltip>
                        </td>
                    </tr>
                    : null
                }
                <tr
                    className={clazzName}
                    onMouseEnter={this.showTooltip}
                    onMouseLeave={this.hideTooltip}
                >
                    {this.props.rowData.data.map((value, indexC) => (
                        <td
                            key={`tr-td-${indexC}`}
                            className={joinClasses(
                                "scl-m-table__td",
                                ((indexC + 1 === this.props.rowData.data.length && !!this.props.hasActions)
                                    ? "with-actions" : undefined),
                                this.props.dataColumns
                                    && this.props.dataColumns[indexC]
                                    && !!this.props.dataColumns[indexC].alignRight ? "align-right" : undefined,
                            )}
                        >
                            {value || ""}
                        </td>
                    ))}
                </tr>
            </>
        );
    }

    private showTooltip() {
        this.setState({
            showTooltip: true,
        });
    }

    private hideTooltip() {
        this.setState({
            showTooltip: false,
        });
    }
}
