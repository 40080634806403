/* eslint-disable react/display-name */

import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ClaimInvoiceStatusDisplay } from "../../../../../store/ClaimMatch/Displays";
import { BookClaims, ClaimInvoiceBookings } from "../../../../../store/ClaimMatch/Types";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { CreditDebitIndicator, StatementDetails, Entry as StatementLine } from "../../../../../store/Statements/Types";
import { ThemeSize, ThemePalette, ThemePaletteState } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import {
    amIChecked,
    calcIsValid,
    calcUnclearedBalanceAfter,
    myAmount,
    selectedPartialBalance,
    validSelectedRow,
} from "../../../../utils/Booking";
import { getClaimInvoiceStatusColor } from "../../../../utils/FormatHelpers";
import { BookBar } from "../../../07-organisms/BookBar";
import { ConfirmBookingModal } from "../Accounts/Statements/ConfirmBookingModal";


export interface SelectedRow {
    id: number;
    amount: number;
}

interface State {
    selectedRows: SelectedRow[];
}

/**
 *
 */
interface StateProps {
    details: StatementDetails;
}

/**
 *
 */
interface OwnProps {
    results: ClaimInvoiceBookings;
    statementLineId?: string;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    book: (id: string, patch: BookClaims) => void;
    openBookRequest: (statementLineId: string, callBack: () => void) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

export const OpenBookReq =
    (statementLineID: string, callBack: () => void) => (
        () => (
            <Modal
                modalID={statementLineID}
                theme={{ size: ThemeSize.SMALL }}
            >
                <ConfirmBookingModal
                    modalID={statementLineID}
                    statementLineId={statementLineID}
                    onBookConfirmed={callBack}
                />
            </Modal>
        )
    );

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    details: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementDetails,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.CLAIM_MATCH_RESULTS, {
        skip: skip,
    })),
    book: (id: string, patch: BookClaims ) => (
        dispatch(remoteTrigger(RemoteScope.CLAIM_MATCH_BOOK_PATCH, {
            patch: patch,
            id: id,
        }))
    ),
    openBookRequest: (statementLineId: string, callBack: () => void) => dispatch(
        modalShow(
            statementLineId,
            OpenBookReq(statementLineId, callBack),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.confirmedBooking = this.confirmedBooking.bind(this);
        this.areAllClaimsChecked = this.areAllClaimsChecked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.bookBarCallBack = this.bookBarCallBack.bind(this);
        this.confirmedBookingCB = this.confirmedBookingCB.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
            return;
        }
    }

    /**
     *
     */
    public render() {
        const statementLine = this.props.details
        && this.props.details.content.entries
        && this.props.details.content.entries.length > 0 ?  this.props.details.content.entries.find(line =>
                line.id.toString() === this.props.statementLineId) : undefined;

        const selectedBalance = this.state.selectedRows.map(row => row.amount)
            .reduce(
                (cur, next)  => cur + next, 0);

        if (!statementLine) {
            return null;
        }

        const unclearedBalance = statementLine.balance;
        const unclearedBalanceAfter = calcUnclearedBalanceAfter(unclearedBalance, selectedBalance,
            statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT);
        const isValid =  calcIsValid(this.state.selectedRows.length, unclearedBalance, selectedBalance);

        return (
            <Form onSubmit={this.bookBarCallBack(statementLine)} isValid={isValid}>
                <BookBar
                    title={"Booking Claims"}
                    selectedBalance={selectedBalance}
                    unclearedBalanceAfter={unclearedBalanceAfter}
                    unclearedBalance={unclearedBalance}
                    isValid={isValid}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData(statementLine).columns,
                        rows: this.resultsData(statementLine).rows,
                        sort: this.resultsData(statementLine).sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </Form>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private getProcessedRows(_statementLine: StatementLine): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            themePalleteState: validSelectedRow(- row.balance, myAmount(row.id, this.state.selectedRows))
                ? undefined : ThemePaletteState.DANGER,
            tooltip: validSelectedRow(
                - row.balance,
                myAmount(row.id, this.state.selectedRows)) ? undefined : "The selected balance is not valid",
            data: [
                (
                    <div
                        key={`div-id-${row.id}`}
                        className="scl-row-selection"
                        style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            flexGrow: 0,
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="scl-row-selection__checkbox"
                            style={{
                                flex: 1,
                                flexGrow: 0,
                                marginRight: "5px",
                            }}
                        >
                            <Checkbox
                                checked={amIChecked(row.id, this.state.selectedRows)}
                                onChange={(value?: boolean) => this.selectItem(value, row.id
                                    , (- row.balance))}
                            />
                        </div>
                        <div
                            className="scl-row-selection__input"
                            style={{
                                flex: 1,
                                flexGrow: 0,
                            }}
                        >
                            {amIChecked(row.id, this.state.selectedRows) &&
                                    <FormField
                                        type="currency"
                                        placeholder="e.g. 100"
                                        onChange={(value?: number | string) => {
                                            this.onChangeAmount(row.id,
                                                value ? typeof value === "string"
                                                    ? parseFloat(value) : value : 0);
                                        }}
                                        value={myAmount(row.id, this.state.selectedRows)}
                                    />
                            }
                        </div>
                    </div>
                ),
                row.invoiceNumber,
                <Moment date={new Date(row.invoiceDate)} format={"DD-MM-YYYY"} key={`date-${index}`}/>,
                row.creditDebitIndicator === CreditDebitIndicator.DBIT ? "Debit" : "Credit",
                (
                    <Badge
                        key={`badge-id-${row.id}`}
                        theme={{paletteState: getClaimInvoiceStatusColor(row.status)}}>
                        {ClaimInvoiceStatusDisplay[row.status.toString()]}
                    </Badge>
                ),
                <Currency amount={row.amount.amount} key={`claim-match${index}`} />,
                (
                    <h6
                        key={`h6-id-${row.id}`}
                    >
                        {<Currency amount={row.balance} />}
                    </h6>
                ),
            ],
        }));
    }

    private bookBarCallBack(statementLine: StatementLine) {
        return () => this.props.openBookRequest(
            statementLine.id.toString(),
            this.confirmedBookingCB(statementLine));
    }

    private confirmedBookingCB(statementLine: StatementLine) {
        return () => this.confirmedBooking(statementLine);
    }

    private resultsData(statementLine: StatementLine) {
        return {
            sort: undefined,
            columns: [
                {
                    label: (
                        <div
                            className="scl-row-selection scl-row-selection--all"
                            style={{
                                display: "inline-flex",
                                flexDirection: "row",
                                flexGrow: 0,
                                alignItems: "baseline",
                            }}
                        >
                            <div
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                <Checkbox
                                    style={{
                                        verticalAlign: "bottom",
                                    }}
                                    onChange={this.onChange}
                                    checked={
                                        this.areAllClaimsChecked(this.props.results, this.state.selectedRows)
                                    }
                                />
                            </div>
                            <label
                                className="scl-row-selection__checkbox"
                                style={{
                                    flex: 1,
                                    flexGrow: 0,
                                    marginRight: "5px",
                                }}
                            >
                                &nbsp;Select
                            </label>
                        </div>
                    ),
                },
                {
                    label: "Invoice number",
                },
                {
                    label: "Invoice date",
                },
                {
                    label: "Mutation type",
                },
                {
                    label: "Status",
                },
                {
                    label: "Amount",
                },
                {
                    label: "Balance",
                    alignRight: true,
                },
            ],
            rows: this.getProcessedRows(statementLine),
        };
    }

    private onChange(value?: boolean) {
        return this.selectItems(value);
    }
    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: [],
        };
    }

    private selectItems(value: boolean | undefined) {
        if (value) {
            const ListArr = this.props.results.content.map((row => ({
                id: row.id,
                amount: ( - row.balance),
            })));

            this.setState({
                selectedRows: ListArr,
            });
        } else {
            this.setState({
                selectedRows: [],
            });
        }
    }

    private selectItem(value: boolean | undefined, id: number, amount: number) {
        this.setState({
            selectedRows: !!value ? [...this.state.selectedRows, {
                id: id,
                amount: amount,
            }]
                : this.state.selectedRows.filter((row) => row.id !== id,
                ),
        });
    }

    private onChangeAmount = (id: number, value: number) => {
        this.setState({
            selectedRows: this.state.selectedRows.map(row => {
                if (row.id === id) {
                    return ({
                        id: row.id,
                        amount: value,
                    });
                }
                return row;
            }),
        });
    };

    private confirmedBooking = (statementLine: StatementLine) => {
        this.props.book(this.props.details.content.id.toString(), {
            claimInvoiceIdToBookedAmount: (
                this.state.selectedRows.map(
                    (row) => ({[row.id.toString()]: selectedPartialBalance(row.amount,
                        statementLine.creditDebitIndicator !== CreditDebitIndicator.DBIT)})).reduce((result, item) => {
                    const key = Object.keys(item)[0];
                    result[key] = item[key];
                    return result;
                }, {}))
            ,
            entryId: statementLine.id,
        });
    };

    private areAllClaimsChecked = (results: ClaimInvoiceBookings, selectedRows: SelectedRow[]): boolean => {
        if (results && results.content) {
            return selectedRows.length === results.content.length;
        }
        return false;
    };
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
