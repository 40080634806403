import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../store/AppDisplays";
import { Filter } from "../../../02-pages/Filter";
import * as ClaimTypes from "../../../../../../store/Claims/Types";
import { isRemoteLoading, ReduxState } from "../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Results } from "../../../02-pages/Results";
import { Div } from "../../../../../../components/core/03-base/Div";

interface OwnProps {
    policyNumber: string;
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: ClaimTypes.Claims;
}

type Props = OwnProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.CLAIM_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.CLAIM_RESULTS) as ClaimTypes.Claims,
});

/**
 *
 * @param props
 */
export class ClaimsComp
    extends React.Component<Props, {}> {


    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.POLICIES_CLAIMS}
                    defaultFilters={{policyNumber: this.props.policyNumber}}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.POLICIES_CLAIMS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Claims = connect(
    mapStateToProps,
    {},
)(ClaimsComp);
