import { FinEntryLine } from "../ManualBooking/Types";
import { Partner } from "../Partners/Types";
import { CreditDebitIndicator, Entry } from "../Statements/Types";

export enum Operation {
    BOOKING = "BOOKING",
    UNBOOKING = "UNBOOKING",
    FREEBOOKING = "FREEBOOKING",
    FREEUNBOOKING = "FREEUNBOOKING"
}

export interface Payment {
    id: number;
    createdAt?: string;
    entry?: Entry;
    amount?: number;
    creditDebitIndicator?: CreditDebitIndicator;
    operation?: Operation;
    invoiceDate?: string;
    invoiceNumber?: number;
    relatedUnbooking?: {};
    finEntryLine?: FinEntryLine;
    partner?: Partner;
}
