import React from "react";
import { Policy } from "../../../../../../../../store/Policies/Types";
import { VehicleView } from "../../../../..//00-blocks/Policies/component/VehicleView";

interface Props {
    policy: Policy;
}

export const Vehicle: React.FC<Props> = (props: Props) => (
    <VehicleView general={props.policy}/>
);
