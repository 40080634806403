import React from "react";

import { Button } from "../../../components/core/05-atoms/Button";
import { ThemePaletteState, ThemeShadowSizes, ThemePalette } from "../../../theme/_Types";

/**
 *
 */
interface Props {
    checked: boolean;
    selectItem: () => void;
}

/**
 *
 * @param props
 */
export const TableSelectCell: React.FC<Props> = (props) => (
    <div
        className="scl-row-selection"
        style={{
            display: "inline-flex",
            flexDirection: "row",
            flexGrow: 0,
            alignItems: "center",
        }}
    >
        <div
            className="scl-row-selection__checkbox"
            style={{
                flex: 1,
                flexGrow: 0,
                marginRight: "5px",
            }}
        >
            <Button
                theme={props.checked ? {
                    paletteState: ThemePaletteState.DANGER,
                    shadow: ThemeShadowSizes.TINY,
                } : {
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    shadow: ThemeShadowSizes.TINY,
                }}
                link={{ onClick: props.selectItem }}
            >
                {props.checked ? "Deselect" : "Select"}
            </Button>
        </div>
    </div>
);
