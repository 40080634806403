/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/require-await */
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { OrderedSet } from "immutable";
import { Action } from "redux";
import { Optional } from "../utils/Optional";

import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { PageDisplay, Pages } from "./AppDisplays";
import {
    CognitoAttributes,
    UserInfo,
    UserRoles,
} from "./AppTypes";
import { ReducerFn, clearState } from "./ReduxState";
import { remoteTrigger } from "./RemoteActions";
import { RemoteScope } from "./RemoteTypes";

export type AppInitActionType = Readonly<Action<ActionTypeKeys.APP_INIT> & { userInfo: UserInfo}>;
/**
 *
 */
export type AppInitAction = Readonly<
Action<ActionTypeKeys.APP_INIT> & {
    userInfo?: UserInfo;
}>;

export const appInit: (userInfo?: UserInfo) => Thunk<void> =
    () => async (dispatch, _getState) => {
        try {
            // load user auth config
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const user: CognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const attributes = (user as any).attributes as CognitoAttributes | undefined;

            // This check is implemented to deal with the exceptional scenario that
            // a user gets deleted and recreated in cognito while the end user is
            // logged in. This exceptional scenario should cause the application to
            // reset as much of its context as possible, as to let the user login as
            // the new subject.
            if (!attributes) {
                // FIXME(ed): make a BAIL_OUT(): method to deal with these cases.
                await Auth.signOut();
                window.location.href = "/";
            }

            // create action outside of dispatch for type checks.
            const action: AppInitAction = {
                type: ActionTypeKeys.APP_INIT,
                userInfo: {
                    token: async () => (
                        (await Auth.currentAuthenticatedUser({
                            bypassCache: true,
                        })) as CognitoUser)
                        .getSignInUserSession()?.getIdToken()?.getJwtToken()
                    ,
                    username: attributes?.["email"],
                    userDisplay: attributes?.["email"],
                    email: attributes?.["email"],
                    userRole: attributes?.["custom:role"] as unknown as UserRoles,
                },
            };

            // initialize the view
            dispatch(action);

            // trigger initial loading
            dispatch(remoteTrigger(RemoteScope.VERSION, undefined));
        } catch (err) {
            if (err === "not authenticated") {
                dispatch(signOut(false, () => window.location.href = "/"));
            }
        }
    };

export const signOut: (g: boolean, nav: () => void) => Thunk<void> =
    (g, nav) => async (dispatch, _getState) => {

        dispatch({
            type: ActionTypeKeys.SIGN_OUT,
        });

        try {
            await Auth.signOut({ global: g });
        } catch (err) {
            console.warn("Claude thinks we can't logout, please try again or refresh the application!");
        } finally {
            dispatch(signedOut(nav));
        }
    };

const signedOut:
(nav: () => void) => Thunk<void> =
    (nav) => (
        async (dispatch) => {
            localStorage.clear();
            dispatch({ type: ActionTypeKeys.SIGNED_OUT });
            nav();
        }
    );

export type ToggleSidebarAction = Readonly<
Action<ActionTypeKeys.TOGGLE_SIDEBAR> & {
    force?: boolean;
}>;

export const toggleSidebar: (force?: boolean) => Thunk<void> =
    (force) => async (dispatch, _getState) => {
        dispatch({
            type: ActionTypeKeys.TOGGLE_SIDEBAR,
            force,
        });
    };

/**
 *
 */
export const toggleSidebarReducer:
ReducerFn<ToggleSidebarAction> =
     (s, a) => {
         const current = a.force !== undefined ? a.force : !s.prop("sidebarCollapsed");
         localStorage.setItem("isCollapsed", JSON.stringify(current));
         return (
             s.setProp(
                 "sidebarCollapsed",
                 current,
             )
         );
     };

// TODO: This state is only set once. Consider move to default state.
export const appInitReducer: ReducerFn<AppInitActionType> =
    (state, action) => {
        const userInfo = action.userInfo ? action.userInfo.userRole : undefined;
        return (state
            .setProp("user", Optional.of({ ...action.userInfo }))
            .setProp(
                "pages",
                state.prop("pages").updateWith({
                    pages: userInfo ? OrderedSet(Object.keys(Pages).map(key => Pages[key] as Pages)
                        .filter(
                            page => PageDisplay[page].roles
                                .includes(userInfo)
                        )) : OrderedSet([]),
                }),
            ));
    };

export const signOutReducer: ReducerFn<any> = (s, _a) => s;
export const signedOutReducer: ReducerFn<any> = (s, _a) => clearState(s);
