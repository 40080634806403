import { InvoiceType } from "./Types";

/**
 *
 */
export const InvoiceTypeDisplay: Readonly<Record<InvoiceType, string>> = {
    [InvoiceType.ClaimInvoice]: "Claim",
    [InvoiceType.PolicyInvoice]: "Policy",
    [InvoiceType.PartnerInvoice]: "Partner",
    [InvoiceType.GeneralLedgerInvoice]: "General ledger",
};
