import { AxiosResponse } from "axios";
import { Brands, BrandDetails } from "./Types";

/**
 *
 * @param resp
 */
export const mapBrandRequests =
    (resp: AxiosResponse): Brands => resp.data as Brands;

/**
 *
 * @param resp
 */
export const mapBrandDetailsRequests =
(resp: AxiosResponse): BrandDetails => resp.data as BrandDetails;
