import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Header } from "../../../../components/core/09-views/01-sections/Header";

import { DispatchFunc } from "../../../../store/ActionTypes";
import { BusinessRuleViolations as BusinessRuleViolationsType } from "../../../../store/BusinessRuleViolations/Types";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../store/RemoteActions";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../store/AppDisplays";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { Results } from "./Results";
import { Container } from "../../../../components/core/03-base/Container";

/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    results: BusinessRuleViolationsType;
}

interface DispatchProps {
    loadResults: () => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingResults: isRemoteLoading(s, RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS),
    results: s.prop("remote").prop(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS) as BusinessRuleViolationsType,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: () => dispatch(remoteTrigger(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS)),
        dispatch(remoteClearError(RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class BusinessRuleViolationsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadResults();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={SubPageDisplay[SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS].title}
                                description={SubPageDisplay[SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS].description}
                                goBack={PageDisplay[Pages.MANAGE].path}
                            />
                        </Grid>
                    </Div>
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={SubPages.MANAGE_BUSINESS_RULE_VIOLATIONS}
                        scope={RemoteScope.BUSINESS_RULE_VIOLATION_RESULTS}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const BusinessRuleViolations = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BusinessRuleViolationsComp);
