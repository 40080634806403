import { AxiosResponse } from "axios";
import { BusinessRuleViolations } from "./Types";


/**
 *
 * @param resp
 */
export const mapBusinessRuleViolationRequests =
    (resp: AxiosResponse): BusinessRuleViolations => resp.data as BusinessRuleViolations;
