import * as React from "react";
import { connect } from "react-redux";
import { Product, ProductDetail } from "../../../../../../store/Product/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { ReduxState } from "../../../../../../store/ReduxState";
import { GeneralView } from "../component/GeneralView";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";

/**
 *
 */
interface OwnProps {
    product?: Product;
}

/**
 *
 */
interface StateProps {
    detail: ProductDetail;
}

/**
 *
 */
type Props = OwnProps & StateProps;

/**
 *
 * @param props
 */
export class GeneralComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }
    public static mapStateToProps(s: ReduxState): StateProps {
        return {
            detail: s.prop("remote").prop(RemoteScope.PRODUCT_DETAILS) as ProductDetail,
        };
    }


    public render() {
        const general = this.props.product
            ? this.props.product
            : this.props.detail &&
                this.props.detail.content ?
                this.props.detail.content : undefined;
        return (
            general ? (
                <GeneralView general={general}/>
            ) : (
                <LoadingData loading={false} />
            )
        );
    }
}

/**
 *
 */
export const General = connect(
    GeneralComp.mapStateToProps,
    {},
)(GeneralComp);
