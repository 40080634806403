import { PaidStatus } from "../../store/AppTypes";
import { RevisionType } from "../../store/AuditTrail/Types";
import { BookingPeriodStatus } from "../../store/BookingPeriod/Types";
import { ClaimInvoiceStatus } from "../../store/ClaimMatch/Types";
import { ClaimStatus, DamageStatus } from "../../store/Claims/Types";
import { CollectiveInvoiceStatus } from "../../store/CollectiveInvoice/Types";
import { MBStatus, ManualBookingTypes } from "../../store/ManualBooking/Types";
import { MessageStatus } from "../../store/Message/Types";
import { PartnerType } from "../../store/Partners/Types";
import { PolicyStatus } from "../../store/Policies/Types";
import { ProductStatus } from "../../store/Product/Types";
import { StatementStatus, BookedObjectType } from "../../store/Statements/Types";
import { ThemePaletteState, ThemePalette } from "../../theme/_Types";

export const getMessageStatusColor = (status: MessageStatus) => {
    switch (status) {
    case MessageStatus.RECEIVED:
        return ThemePaletteState.WARNING;
    case MessageStatus.UNPARSEABLE:
        return ThemePaletteState.DANGER;
    case MessageStatus.CREATED:
        return ThemePaletteState.SUCCESS;
    case MessageStatus.PROCESSED:
        return ThemePaletteState.DISABLED;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getClaimStatusColor = (status: ClaimStatus) => {
    switch (status) {
    case ClaimStatus.ACCEPTED:
    case ClaimStatus.UPDATED:
        return ThemePaletteState.WARNING;
    case ClaimStatus.ERROR:
    case ClaimStatus.UNPAID:
        return ThemePaletteState.DANGER;
    case ClaimStatus.PAID:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getClaimInvoiceStatusColor = (status: ClaimInvoiceStatus) => {
    switch (status) {
    case ClaimInvoiceStatus.UNPAID:
    case ClaimInvoiceStatus.CANCELED:
        return ThemePaletteState.DANGER;
    case ClaimInvoiceStatus.PAID:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getBookingPeriodStatusColor = (status: BookingPeriodStatus) => {
    switch (status) {
    case BookingPeriodStatus.OPEN:
        return ThemePaletteState.WARNING;
    case BookingPeriodStatus.CLOSED:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getPaidStatusColor = (status: PaidStatus) => {
    switch (status) {
    case PaidStatus.UNPAID:
        return ThemePaletteState.DANGER;
    case PaidStatus.PAID:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getCollectiveInvoiceStatusColor = (status: CollectiveInvoiceStatus) => {
    switch (status) {
    case CollectiveInvoiceStatus.UNPAID:
    case CollectiveInvoiceStatus.CANCELED:
        return ThemePaletteState.DANGER;
    case CollectiveInvoiceStatus.PARTIALLY_PAID:
    case CollectiveInvoiceStatus.REVERSED:
        return ThemePaletteState.WARNING;
    case CollectiveInvoiceStatus.PAID:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getFreeBookingStatusColor = (status: MBStatus) => {
    switch (status) {
    case MBStatus.BOOKED:
        return ThemePaletteState.SUCCESS;
    case MBStatus.UNBOOKED:
        return ThemePaletteState.DANGER;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getStatementStatusColor = (status: StatementStatus) => {
    switch (status) {
    case StatementStatus.PROCESSING:
        return ThemePaletteState.WARNING;
    case StatementStatus.ERROR:
        return ThemePaletteState.DANGER;
    case StatementStatus.PROCESSED:
        return ThemePaletteState.SUCCESS;
    case StatementStatus.UNPROCESSED:
        return ThemePaletteState.DISABLED;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getPolicyStatusColor = (status: PolicyStatus) => {
    switch (status) {
    case PolicyStatus.RECEIVED:
    case PolicyStatus.DUPLICATED:
        return ThemePaletteState.WARNING;
    case PolicyStatus.ERROR:
    case PolicyStatus.CANCELED:
        return ThemePaletteState.DANGER;
    case PolicyStatus.APPROVED:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getProductStatusColor = (status: ProductStatus) => {
    switch (status) {
    case ProductStatus.CREATED:
        return ThemePaletteState.WARNING;
    case ProductStatus.ACTIVE:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getPartnerTypeColor = (status: PartnerType) => {
    switch (status) {
    case PartnerType.CLAIM_HANDLER:
        return ThemePalette.BRAND_TERTIARY;
    case PartnerType.INSURER:
        return ThemePalette.CONTRAST_SECONDARY;
    case PartnerType.SELF_INSURER:
        return ThemePalette.CONTRAST_PRIMARY;
    default:
        return ThemePalette.CONTRAST_ACCENT;
    }
};

export const getManualBookingTypeColor = (status: ManualBookingTypes) => {
    switch (status) {
    case ManualBookingTypes.PARTNER:
        return ThemePalette.BRAND_TERTIARY;
    case ManualBookingTypes.CLAIM_INVOICE:
        return ThemePalette.CONTRAST_SECONDARY;
    case ManualBookingTypes.GENERAL_LEDGER:
        return ThemePalette.CONTRAST_PRIMARY;
    case ManualBookingTypes.POLICY_INVOICE:
        return ThemePalette.CONTRAST_TERTIARY;
    default:
        return ThemePalette.CONTRAST_ACCENT;
    }
};

export const getBookedObjectTypeColor = (status: BookedObjectType) => {
    switch (status) {
    case BookedObjectType.PARTNER_INVOICE:
        return ThemePalette.BRAND_TERTIARY;
    case BookedObjectType.CLAIM_INVOICE:
        return ThemePalette.CONTRAST_SECONDARY;
    case BookedObjectType.GENERAL_LEDGER_INVOICE:
        return ThemePalette.CONTRAST_PRIMARY;
    case BookedObjectType.COLLECTIVE_INVOICE:
        return ThemePalette.CONTRAST_TERTIARY;
    case BookedObjectType.INCASSO:
        return ThemePalette.BRAND_PRIMARY;
    default:
        return ThemePalette.CONTRAST_ACCENT;
    }
};

export const getDamageStatusColor = (status: DamageStatus) => {
    switch (status) {
    case DamageStatus.RECEIVED:
        return ThemePaletteState.WARNING;
    case DamageStatus.MATCHED:
        return ThemePaletteState.SUCCESS;
    case DamageStatus.ERROR:
        return ThemePaletteState.DANGER;
    case DamageStatus.DUPLICATED:
        return ThemePaletteState.WARNING;
    case DamageStatus.UPDATE:
        return ThemePaletteState.SUCCESS;
    default:
        return ThemePaletteState.DISABLED;
    }
};

export const getRevisionTypeColor = (type: RevisionType) => {
    switch (type) {
    case RevisionType.INSERT:
        return ThemePaletteState.SUCCESS;
    case RevisionType.UPDATE:
        return ThemePaletteState.WARNING;
    case RevisionType.DELETE:
        return ThemePaletteState.DANGER;
    case RevisionType.UNKNOWN:
        return ThemePaletteState.DISABLED;
    default:
        return ThemePaletteState.DISABLED;
    }
};
