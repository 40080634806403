import { AxiosResponse } from "axios";
import { MBFilters, ManualBookingsResults, ManualBookingResults } from "./Types";

export const mapManualBookingRequests =
(resp: AxiosResponse): ManualBookingResults => resp.data as ManualBookingResults;

export const mapMBResultsRequests =
(resp: AxiosResponse): ManualBookingsResults => resp.data as ManualBookingsResults;

/**
 *
 */
export const MBFiltersToParams = (f: MBFilters) => ({
    ...f,
});
