import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { State } from "../../utils/redux/State";
import { ReducerFn } from "../ReduxState";
import { GLRequest } from "./Types";
import { RemoteScope } from "../RemoteTypes";

/**
 *
 */
export type InitUpdateGLAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_GL> & {}
>;

/**
 *
 *
 */
export const initUpdateGL =
    (): InitUpdateGLAction => ({
        type: ActionTypeKeys.INIT_UPDATE_GL,
    });

/**
 *
 * @param s
 * @param _a
 */
export const initUpdateGLReducer:
ReducerFn<InitUpdateGLAction> =
    (s, _a) => {
        const gLDetails = s.prop("remote").prop(RemoteScope.GENERAL_LEDGER_DETAILS);
        return (
            s.setProp(
                "gLRequest",
                s.prop("gLRequest").update((_old) => gLDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateGLAction = Readonly<
Action<ActionTypeKeys.UPDATE_GL> & {
    ledger?: Partial<GLRequest>;
}
>;

/**
 *
 *
 */
export const updateGL =
    (ledger: GLRequest): UpdateGLAction => ({
        type: ActionTypeKeys.UPDATE_GL,
        ledger: ledger,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateGLReducer:
ReducerFn<UpdateGLAction> =
    (s, a) => (
        s.setProp(
            "gLRequest",
            s.prop("gLRequest").updateWith(
                a.ledger ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearGLAction = Readonly<
Action<ActionTypeKeys.CLEAR_GL>
>;

/**
 *
 * @param keys
 */
export const clearGL:
() => ClearGLAction =
    () => ({
        type: ActionTypeKeys.CLEAR_GL,
    });

/**
 *
 */
export const clearGLReducer:
ReducerFn<ClearGLAction> =
    (s, _a) => (
        s.setProp(
            "gLRequest",
            State.create<GLRequest>(() => ({})),
        )
    );
