import * as React from "react";
import { connect } from "react-redux";

import { ReduxState } from "../../../../../../../store/ReduxState";
import { DispatchFunc } from "../../../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import { BookedStatements, UnbookStatementLine, BookedObjectType,
    Entry, CreditDebitIndicator } from "../../../../../../../store/Statements/Types";

import { BookedObjectTypeDisplay } from "../../../../../../../store/Statements/Displays";
import { UnbookLineModal } from "./UnbookLineModal";
import { UserInfo, UserRoles } from "../../../../../../../store/AppTypes";
import { Div } from "../../../../../../../components/core/03-base/Div";
import { Badge } from "../../../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../../../components/core/05-atoms/CurrencyDisplay";
import { Modal } from "../../../../../../../components/core/06-molecules/Modal";
import { Table, RowData, TableData } from "../../../../../../../components/core/06-molecules/Table";
import { Card } from "../../../../../../../components/core/07-organisms/Card";
import { Navbar } from "../../../../../../../components/core/07-organisms/Navbar";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../../../components/core/07-organisms/Navbar/Title";
import { ThemeSize, ThemeShadowSizes, ThemePalette,
    ThemePaletteState, ThemeBreakpoints } from "../../../../../../../theme/_Types";
import { modalShow } from "../../../../../../../utils/redux/ActionTypes";
import { getBookedObjectTypeColor } from "../../../../../../utils/FormatHelpers";
import { Button } from "../../../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment,
    Orientation } from "../../../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface StateProps {
    results: BookedStatements;
    user?: UserInfo;
}

/**
 *
 */
interface OwnProps {
    statementId: string;
    statementLineId: string;
    statementLine?: Entry;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number, id: string) => void;
    openUnbookRequest: (patch: UnbookStatementLine, id: string, statementId: string) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.BOOKED_STATEMENT_RESULTS) as BookedStatements,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number, id: string) => dispatch(remoteTrigger(RemoteScope.BOOKED_STATEMENT_RESULTS, {
        skip: skip,
        id: id,
    })),
    openUnbookRequest: (patch: UnbookStatementLine, id: string, statementId: string) => dispatch(
        modalShow(
            id,
            () => (
                <Modal
                    modalID={id}
                    theme={{ size: ThemeSize.SMALL }}
                >
                    <UnbookLineModal
                        modalID={id}
                        id={id}
                        statementId={statementId}
                        objectId={patch.bookedObjectId}
                        type={patch.bookedObjectType}
                    />
                </Modal>
            ),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.unbook = this.unbook.bind(this);
    }

    public componentDidMount() {
        this.props.loadResults(0, this.props.statementLineId);
    }

    /**
     *
     */
    public render() {
        // eslint-disable-next-line max-len
        const totalAmount = this.props.results && this.props.results.content && this.props.results.content.map(row => row.amount)
            .reduce(
                (cur, next) => cur + next, 0);
        return (
            <Div theme={{ shadow: ThemeShadowSizes.TINY, palette: ThemePalette.CONTRAST_PRIMARY }}>
                <Card title="Booked Items" collapsable defaultCollapsed={false}>
                    <Table
                        hasActions
                        data={{
                            columns: this.resultsData().columns,
                            rows: this.resultsData().rows,
                            sort: this.resultsData().sort,
                        }}
                    />
                </Card>
                <Navbar theme={{ palette: ThemePalette.BRAND_ACCENT }} className="scl-h-text-align--left">
                    <NavbarStack>
                        <NavbarStackContent>
                            <NavbarTitle>
                                Total
                            </NavbarTitle>
                        </NavbarStackContent>
                        <NavbarStackRight>
                            <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                                <Div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "stretch",
                                        }}
                                        theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                                    >
                                        <span
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    paddingLeft: "5px",
                                                    textAlign: "right",
                                                }}
                                            >
                                                <span
                                                    className="scl-b-h scl-b-h3 "
                                                    style={{
                                                        color: "#fff",
                                                    }}
                                                >
                                                    <Currency
                                                        amount={totalAmount}
                                                    />
                                                </span>
                                            </span>
                                        </span>
                                    </Div>
                                </Div>
                            </Div>
                        </NavbarStackRight>
                    </NavbarStack>
                </Navbar>
            </Div>
        );
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        const isCredit = this.props.statementLine?.creditDebitIndicator === CreditDebitIndicator.CRDT;
        return this.props.results && this.props.results.content && this.props.results.content.map((row, _index) => {
            const currentAmount = (isCredit)
                ?
                {
                    deb: row.amount && row.amount < 0 ? (- row.amount) : 0,
                    cred: row.amount && row.amount > 0 ? row.amount : 0,
                } :
                {
                    deb: row.amount && row.amount > 0 ? row.amount : 0,
                    cred: row.amount && row.amount < 0 ? (- row.amount) : 0,
                };
            return ({
                id: row.bookedObjectId,
                data: [
                    row.bookedObjectId,
                    row.bookedObjectType ?
                        (
                            <Badge
                                theme={{
                                    palette: getBookedObjectTypeColor(row.bookedObjectType),
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            >
                                {BookedObjectTypeDisplay[row.bookedObjectType]}
                            </Badge>
                        )
                        : "",
                    currentAmount.deb ? (
                        <h6>
                            <Currency
                                amount={currentAmount.deb}
                            />
                        </h6>) : "",
                    currentAmount.cred ? (
                        <h6>
                            <Currency
                                amount={currentAmount.cred}
                            />
                        </h6>) : "",
                    <ButtonsWrapper
                        key={`navlink-${row.bookedObjectId}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >{
                            this.props.user?.userRole !== UserRoles.EW_DEBTOR
                        && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                                (
                                    <Button
                                        theme={{
                                            paletteState: ThemePaletteState.DANGER,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                        link={{
                                            onClick: () =>
                                                this.unbook(
                                                    row.bookedObjectId,
                                                    row.bookedObjectType,
                                                    this.props.statementLineId,
                                                    this.props.statementId,
                                                ),
                                        }}
                                    >
                                Unbook
                                    </Button>
                                ) : ""}</ButtonsWrapper>,
                ],
            });
        });
    }

    private unbook(objectId: number, type: BookedObjectType, statementLineId: string, statementId: string) {
        this.props.openUnbookRequest({
            bookedObjectId: objectId,
            bookedObjectType: type,
        }, statementLineId, statementId);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Id",
                },
                {
                    label: "Type",
                },
                {
                    label: "Debit balance",
                    alignRight: true,
                },
                {
                    label: "Credit balance",
                    alignRight: true,
                },
                this.props.user?.userRole !== UserRoles.EW_DEBTOR
                && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                    {
                        label: "Actions",
                    } : {
                        label: "",
                    },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
