
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemePaletteState, ThemePalette, ThemeBreakpoints } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";


/**
 *
 */
interface OwnProps {
    modalID: string;
    tariffId: string;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    deleteRequest: (id: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    deleteRequest: (id: string) => {
        dispatch(remoteTrigger(
            RemoteScope.TARIFF_DELETE,
            {
                tariffId: id,
                modalID: id,
            },
        ));
    },
});

export const closeModal = (props: Props) => () => props.closeModal(props.modalID);

export const deleteRequest = (props: Props) => () => props.deleteRequest(props.tariffId);

/**
 *
 * @param props
 */
const DeleteTariffComp: React.FunctionComponent<Props> = (props) => (
    <FlexColumn
    >
        <FlexRow isFixed>
            <Navbar
                theme={{
                    paletteState: ThemePaletteState.DANGER,
                }}
            >
                <NavbarStack>
                    <NavbarStackContent>
                        <NavbarTitle>
                           Delete the Tariff
                        </NavbarTitle>
                    </NavbarStackContent>
                    <NavbarStackRight>
                        <NavbarIcon>
                            <Button  icon={IconNames.TIMES}
                                link={{
                                    onClick: closeModal(props),
                                }}/>
                        </NavbarIcon>
                    </NavbarStackRight>
                </NavbarStack>
            </Navbar>
        </FlexRow>
        <FlexRow
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
            }}
        >
            <FlexColumn>
                <FlexRow isScrollable >
                    <FillUp>
                        <Container
                            theme={{
                                padding: {
                                    "": { y: 3 },
                                    "md": { y: 4 },
                                },
                            }}
                        >

                                You are about to delete the tariff ({props.tariffId}).
                            <br /><br />
                                This action cannot be undone.
                            <br /><br />
                            <b>Are you sure you want to delete this tariff?</b>

                        </Container>
                    </FillUp>
                </FlexRow>
            </FlexColumn>
        </FlexRow>
        <FlexRow isFixed>
            <FlexColumn
                theme={{
                    palette: ThemePalette.CONTRAST_SECONDARY,
                }}
            >
                <Container
                    theme={{
                        padding: { "": { y: 3 } },
                    }}
                >
                    <ButtonsWrapper
                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                        alignment={Alignment.RIGHT}
                    >
                        <Button
                            theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                            link={{ onClick: closeModal(props) }}
                        >Cancel
                        </Button>
                        <Button
                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                            link={{ onClick: deleteRequest(props) }}
                        >Yes</Button>
                    </ButtonsWrapper>
                </Container>
            </FlexColumn>
        </FlexRow>
    </FlexColumn >
);

/**
 *
 */
export const DeleteTariffModal = connect(
    undefined,
    mapDispatchToProps,
)(DeleteTariffComp);
