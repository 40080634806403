import * as React from "react";
import { Tabs } from "../../Tabs";
import { PageTabs, SubPageDisplay, SubPages } from "./../../../../../../store/AppDisplays";
import { Product } from "../../../../../../store/Product/Types";
import { Div } from "../../../../../../components/core/03-base/Div";


interface Props {
    product: Product;
}
/**
 *
 * @param props
 */
export const ProductView: React.FC<Props> = (props: Props) => (
    <Div>
        <Tabs
            tabs={SubPageDisplay[SubPages.PRODUCT_DETAIL].tabs}
            defaultTab={PageTabs.PRODUCT_GENERAL}
            results={{
                product: props.product,
                tariffs: props?.product?.productTariffs,
            }}
            child
        />
    </Div>
);
