import * as React from "react";
import { connect } from "react-redux";


import { DispatchFunc } from "../../../../../../store/ActionTypes";
import * as FormFields from "../../../../../../components/core/07-organisms/Form/Field";
import { updateDescription } from "../../../../../../store/BookingDescription";
import { ReduxState } from "../../../../../../store/ReduxState";
import { Div } from "../../../../../../components/core/03-base/Div";
import { FlexColumn } from "../../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../../components/core/04-helpers/FillUp";
import { IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { Required } from "../../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../../components/core/07-organisms/Navbar/Title";
import { ThemeBreakpoints, ThemePalette } from "../../../../../../theme/_Types";
import { modalClose } from "../../../../../../utils/redux/ActionTypes";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Container } from "../../../../../../components/core/03-base/Container";

/**
 *
 */
interface OwnProps {
    modalID: string;
    statementLineId: string;
    onBookConfirmed: () => void;
}

/**
 *
 */
interface StateProps {
    bookingDescription: string;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    updateDescription: (description: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    bookingDescription: s.prop("bookingDescription"),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    updateDescription: (description: string) => dispatch(updateDescription(description)),
});

export const confirmBooking = (props: Props) => () => {
    props.onBookConfirmed();
    props.closeModal(props.modalID);
};

export const closeModal = (props: Props) => () => props.closeModal(props.modalID);

/**
 *
 * @param props
 */
const ConfirmBookingComp: React.FunctionComponent<Props> = (props) => (
    <FlexColumn
    >
        <FlexRow isFixed>
            <Navbar
                theme={{
                    palette: ThemePalette.CONTRAST_TERTIARY,
                }}
            >
                <NavbarStack>
                    <NavbarStackContent>
                        <NavbarTitle>
                            Booking Statement Line
                        </NavbarTitle>
                    </NavbarStackContent>
                    <NavbarStackRight>
                        <NavbarIcon>
                            <Button
                                icon={IconNames.TIMES}
                                link={{
                                    onClick: closeModal(props),
                                }}/>
                        </NavbarIcon>
                    </NavbarStackRight>
                </NavbarStack>
            </Navbar>
        </FlexRow>
        <FlexRow
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
            }}
        >
            <FlexColumn>
                <FlexRow isScrollable >
                    <FillUp>
                        <Container
                            theme={{
                                padding: {
                                    "": { y: 3 },
                                    "md": { y: 4 },
                                },
                            }}
                        >
                            <Div>
                                You are about to book on statement line ({props.statementLineId}).
                                <br /><br />
                                <FormFields.FormField
                                    label="Description"
                                    required
                                    placeholder="e.g. Booked today"
                                    onChange={(value?: string | number) =>
                                        props.updateDescription(value?.toString() ?? "")}
                                    value={props.bookingDescription}
                                />
                                <Required /><br /><br />
                                <b>Are you sure you want to book the selection on this statement line?</b>
                            </Div>
                        </Container>
                    </FillUp>
                </FlexRow>
            </FlexColumn>
        </FlexRow>
        <FlexRow isFixed>
            <FlexColumn
                theme={{
                    palette: ThemePalette.CONTRAST_SECONDARY,
                }}
            >
                <Container
                    theme={{
                        padding: { "": { y: 3 } },
                    }}
                >
                    <ButtonsWrapper
                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                        alignment={Alignment.RIGHT}
                    >
                        <Button
                            theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                            link={{ onClick: closeModal(props)}}
                        >
                            Cancel
                        </Button>
                        <Button
                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                            link={{ onClick: confirmBooking(props)}}

                            disabled={!props.bookingDescription}
                        >
                            Yes
                        </Button>
                    </ButtonsWrapper>
                </Container>
            </FlexColumn>
        </FlexRow>
    </FlexColumn >
);

/**
 *
 */
export const ConfirmBookingModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmBookingComp);
