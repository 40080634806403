
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../components/core/05-atoms/Icon";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { SubPageDisplay, SubPages, PageDisplay, Pages } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";

import { Brands as BrandType } from "../../../../store/Brand/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Results } from "./Results";


/**
 *
 */
interface StateProps {
    loadingResults: boolean;
    results: BrandType;
    user?: UserInfo;
}

interface DispatchProps {
    loadResults: () => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingResults: isRemoteLoading(s, RemoteScope.BRAND_RESULTS),
    results: s.prop("remote").prop(RemoteScope.BRAND_RESULTS) as BrandType,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: () => dispatch(remoteTrigger(RemoteScope.BRAND_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.BRAND_RESULTS)),
        dispatch(remoteClearError(RemoteScope.BRAND_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class BrandsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public componentDidMount() {
        this.props.loadResults();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={SubPageDisplay[SubPages.MANAGE_BRANDS].title}
                                description={SubPageDisplay[SubPages.MANAGE_BRANDS].description}
                                goBack={PageDisplay[Pages.MANAGE].path}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <NavLink to={SubPageDisplay[SubPages.BRAND_CREATE].path}>
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            className="scl-a-btn--big"
                                        >
                                            {SubPageDisplay[SubPages.BRAND_CREATE].title}
                                        &nbsp;
                                        &nbsp;
                                            <Icon name={IconNames.ARROW_RIGHT} />
                                        </Button>
                                    </NavLink>
                                </Div>
                            </Grid>}
                    </Div>
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={SubPages.MANAGE_BRANDS}
                        scope={RemoteScope.BRAND_RESULTS}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const Brands = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BrandsComp);
