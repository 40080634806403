/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    ConfirmSignIn,
    ConfirmSignUp,
    ForgotPassword,
    RequireNewPassword,
    SignIn,
    VerifyContact,
    withAuthenticator,
    SignUp,
} from "aws-amplify-react";
import React from "react";
import { Map, OrderedSet } from "immutable";
import { ReduxState, RemoteErrors } from "../src/store/ReduxState";
import { DispatchFunc } from "../src/store/ActionTypes";
import { appInit } from "../src/store/AppActions";
import { connect } from "react-redux";
import { RemoteScope, RemoteErrorType } from "./store/RemoteTypes";
import { modalClose, modalShow } from "./utils/redux/ActionTypes";
import * as I18n from "./utils/translations/I18n";
import { AmplifyTheme } from "./components/core/08-vendors";
import { UserInfo } from "./store/AppTypes";
import {
    Switch,
    Route,
} from "react-router-dom";
import { PageDisplay, Pages, SubPages, SubPageDisplay} from "./store/AppDisplays";
import { ModalNotification } from "./components/core/09-views/03-modals/Notification";
import { Blockquote } from "./components/core/05-atoms/Blockquote";
import { RTE } from "./components/core/07-organisms/RTE";
import { ThemePaletteState, ThemePalette } from "./theme/_Types";
import { remoteClearError } from "./store/RemoteActions";
import { NoMatchingRoute } from "./components/core/09-views/02-pages/NoMatchingRoute";
import { Loading } from "./components/core/09-views/02-pages/Loading";

/**
 *
 */
interface OwnProps { }

/**
 *
 */
interface DispatchProps {
    triggerInit: () => void;
    handleRemoteError: (scope: RemoteScope, error: RemoteErrors) => void;
}

/**
 *
 */
interface StateProps {
    availablePages: OrderedSet<Pages>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    currentUser?: UserInfo;
}

const handleRemoteErrorModal = (id: string, onClose: () => void, error: RemoteErrors) => {
    const modal = (
        <ModalNotification
            id={id}
            title={error.type === RemoteErrorType.VALIDATION
                ? I18n.getText("app.notification", "Notification")
                : I18n.getText("app.warning", "Warning")}
            onCloseModal={onClose}
            theme={{
                paletteState: error.type !== RemoteErrorType.VALIDATION
                    ? error.type === RemoteErrorType.UNKNOWN_ERROR
                        || error.type === RemoteErrorType.DEFAULT
                        ? ThemePaletteState.DANGER : ThemePaletteState.WARNING
                    : undefined,
                palette: error.type === RemoteErrorType.VALIDATION ? ThemePalette.CONTRAST_TERTIARY
                    : undefined,
            }}
        >
            <React.Fragment>
                <Blockquote
                    style={{
                        maxHeight: "200px",
                        overflowY: "scroll",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line",
                    }}
                >
                    <RTE>
                        <h5>
                            {error.type !== RemoteErrorType.UNKNOWN_ERROR ?
                                I18n.getText("app.remote-error", "Claude can't continue with your request.") :
                                I18n.getText("app.fatal-remote-error", "Even Claude doesn't know what went wrong here!")
                            }
                        </h5>
                        {error.message}
                    </RTE>
                </Blockquote>
            </React.Fragment>
        </ModalNotification>
    );
    // eslint-disable-next-line react/display-name
    return () => modal;
};

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    triggerInit: () => dispatch(appInit()),
    handleRemoteError: (scope, error) => dispatch(
        modalShow("handleremoteerror", handleRemoteErrorModal("handleremoteerror", () => {
            dispatch(modalClose("handleremoteerror"));
            dispatch(remoteClearError(scope));
        }, error))),
});

const mapStateToProps = (state: ReduxState): StateProps => ({
    availablePages: state.prop("pages").prop("pages"),
    remoteErrors: state.prop("remoteErrors"),
    currentUser: state.prop("user").isPresent() ? state.prop("user").get() : undefined,
});

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 * AppComp implements Claude's root component.
 */
class AppComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.props.triggerInit();
        this.checkRemoteError();
    }

    public componentDidUpdate(_prevProps: Props) {
        this.checkRemoteError();
    }

    /**
     *
     */
    public render() {
        if (this.props.currentUser !== undefined) {
            return (
                <Switch>
                    {
                        this.props.availablePages.map((page: Pages, index) => ([
                            <Route
                                key={"route-" + PageDisplay[page].path + index}
                                path={PageDisplay[page].path}
                                component={PageDisplay[page].component}
                                exact
                            />,

                            PageDisplay[page].subpages.map((subpage: SubPages, subindex) => (
                                <Route
                                    key={"route-" + SubPageDisplay[subpage].path + subindex}
                                    path={SubPageDisplay[subpage].path}
                                    component={SubPageDisplay[subpage].component}
                                    exact
                                />
                            ))]
                        ))
                    }
                    <Route component={NoMatchingRoute}/>
                </Switch>
            );
        } else {
            return (
                <div>
                    <Switch>
                        <Route path="/" exact component={Loading}/>
                    </Switch>
                </div>
            );
        }
    }

    private checkRemoteError = () => {
        const errorEntry = this.props.remoteErrors.findEntry((val) => !!val);
        if (errorEntry) {
            const [scope, reason] = errorEntry;
            if ((reason.type === RemoteErrorType.VALIDATION && scope === RemoteScope.COLLECTIVE_INVOICE_GENERATE) ||
                (reason.type === RemoteErrorType.VALIDATION && scope === RemoteScope.FILE_UPLOAD) ||
                (reason.type === RemoteErrorType.VALIDATION && scope === RemoteScope.GENERATE_REPORT_ACCOUNTING_POST)) {
                return;
            } else {
                this.props.handleRemoteError(scope, reason);
            }
        }
    };
}

/**
 *
 */
const AppWrapper: React.FunctionComponent<OwnProps> = props => (<AppComp {...props as Props} />);

/**
 *
 */
export const AppConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWrapper);

export const App = withAuthenticator(
    AppConnect,
    false,
    [
        <SignIn key={1} />,
        <ConfirmSignIn key={2} />,
        <VerifyContact key={3} />,
        <SignUp key={4} />,
        <ConfirmSignUp key={5} />,
        <ForgotPassword key={6} />,
        <RequireNewPassword key={7} />,
    ],
    null,
    AmplifyTheme,
);

export const testUtil = {
    AppComp: AppComp,
    handleRemoteErrorModal: handleRemoteErrorModal,
    mapDispatchToProps: mapDispatchToProps,
    mapStateToProps: mapStateToProps,
};
