import React from "react";
import { connect } from "react-redux";
import { ActivateProductModal } from "../../00-blocks/Product/ActivateProductModal";
import { DeactivateProductModal } from "../../00-blocks/Product/DeactivateProductModal";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { DeleteProductModal } from "../../00-blocks/Product/DeleteProductModal";
import { DetailView } from "./component/DetailView";
import { Container } from "../../../../../components/core/03-base/Container";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { PageDisplay, Pages } from "../../../../../store/AppDisplays";
import { UserInfo } from "../../../../../store/AppTypes";
import { ProductDetail, Products } from "../../../../../store/Product/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeSize } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { Page } from "../../../../../components/core/09-views/01-sections/Page";

interface Params {
    ewId: string;
}

interface StateProps {
    params: Params;
    details: ProductDetail;
    loading: boolean;
    resultsSuccess?: Products;
    user?: UserInfo;
}

interface DispatchProps {
    loadEWDetails?: (id: string) => void;
    clearDetails?: () => void;
    openActivateRequest?: (id: string) => void;
    openDeactivateRequest?: (id: string) => void;
    openDeleteRequest?: (id: string) => void;
}

export type Props = DispatchProps & StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: RouteComponentProps): StateProps => ({
    params: p.match.params as Params,
    details: s.prop("remote").prop(RemoteScope.PRODUCT_DETAILS) as ProductDetail,
    loading: isRemoteLoading(s, RemoteScope.PRODUCT_DETAILS),
    resultsSuccess: s.prop("remote").prop(RemoteScope.PRODUCT_DELETE) as Products,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadEWDetails: (id: string) => dispatch(remoteTrigger(RemoteScope.PRODUCT_DETAILS, {
        id: id,
    })),
    clearDetails: () => (
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_DETAILS)),
        dispatch(remoteClearError(RemoteScope.PRODUCT_DETAILS))
    ),
    openActivateRequest: (id: string) => dispatch(
        modalShow(
            "activateproduct" + id,
            () => (
                <Modal
                    modalID={"activateproduct" + id}
                    theme={{ size: ThemeSize.SMALL }}
                >
                    <ActivateProductModal
                        modalID={"activateproduct" + id}
                        id={id}
                    />
                </Modal>
            ),
        ),
    ),
    openDeactivateRequest: (id: string) => dispatch(
        modalShow(
            "deactivateproduct" + id,
            () => (
                <Modal
                    modalID={"deactivateproduct" + id}
                    theme={{ size: ThemeSize.SMALL }}
                >
                    <DeactivateProductModal
                        modalID={"deactivateproduct" + id}
                        id={id}
                    />
                </Modal>
            ),
        ),
    ),
    openDeleteRequest: (id: string) => dispatch(
        modalShow(
            "deleteproduct" + id,
            () => (
                <Modal
                    modalID={"deleteproduct" + id}
                    theme={{ size: ThemeSize.SMALL }}
                >
                    <DeleteProductModal
                        modalID={"deleteproduct" + id}
                        id={id}
                    />
                </Modal>
            ),
        ),
    ),
});

/**
 *
 */
export class DetailComp
    extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        this.props.loadEWDetails?.(this.props.params.ewId);
    }

    public componentWillUnmount() {
        this.props.clearDetails?.();
    }

    public render() {
        return (
            this.props.resultsSuccess ?
                <Redirect to={PageDisplay[Pages.PRODUCT].path} />
                : (
                    <Page>
                        <Container
                            theme={{
                                padding: {
                                    "": { y: 3 },
                                    "sm": { y: 4 },
                                },
                            }}
                        >
                            <DetailView {...this.props}/>
                        </Container>
                    </Page>)
        );
    }
}

/**
 *
 */
export const Detail = connect(
    mapStateToProps,
    mapDispatchToProps,
)((props: Props) => <DetailComp {...props}/>);
