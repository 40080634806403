import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { ReduxState } from "../../../../../store/ReduxState";

import { CreateGL } from "./CreateGL";

interface Params {
    gLId: string;
}

interface StateProps {
    params: Params;
}

type Props = StateProps;

/**
 *
 * @param s
 */

/**
 *
 */
export class EditGLComp
    extends React.Component<Props, {}> {


    public constructor(props: Props) {
        super(props);
    }

    public static mapStateToProps(s: ReduxState, p: RouteComponentProps): StateProps {
        return {
            params: p.match.params as Params,
        };
    }

    public render() {
        return (
            <CreateGL edit gLId={this.props.params.gLId} />
        );
    }
}

/**
 *
 */
export const EditGL = connect(
    EditGLComp.mapStateToProps,
    {},
)((props: Props) => <EditGLComp {...props}/>);
