import * as React from "react";
import { connect } from "react-redux";

import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    hasSearchableFilters,
    getFilters,
    FilterScope,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { PageTabs } from "../../../../../store/AppDisplays";
import { ExcassoFilters } from "../../../../../store/Excassos/Types";
import { validTillValidation, validFromValidation } from "../../../../../utils/FormValidations";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ValidationStatus } from "../../../../../components/core/07-organisms/Form/_Types";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { ThemePalette } from "../../../../../theme/_Types";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
type setFilterFn = (filters: Partial<ExcassoFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof ExcassoFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: ExcassoFilters;
    currentTab?: PageTabs;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: ExcassoFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.EXCASSOS, p.defaultFilters),
    filters: getFilters(s, FilterScope.EXCASSOS ) as ExcassoFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.EXCASSOS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.EXCASSOS));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.EXCASSO_RESULTS, { skip: 0})),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.EXCASSO_RESULTS)),
        dispatch(remoteClearError(RemoteScope.EXCASSO_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.fromDateValidation = this.fromDateValidation.bind(this);
        this.toDateValidation = this.toDateValidation.bind(this);
        this.onChangeFromDate = this.onChangeFromDate.bind(this);
        this.onChangeToDate = this.onChangeToDate.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="From date"
                            placeholder="e.g. 2020/11/01"
                            onChange={this.onChangeFromDate}
                            type="date"
                            value={this.props.filters?.creationDateFrom ?? ""}
                            validationFunction={this.fromDateValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="To date"
                            placeholder="e.g. 2020/11/01"
                            onChange={this.onChangeToDate}
                            type="date"
                            value={this.props.filters?.creationDateTo ?? ""}
                            validationFunction={this.toDateValidation}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={(!this.props.searchEnabled || this.props.loadingResults)}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={(this.props.searchEnabled && !this.props.loadingResults)
                            ? !this.formIsValid() : false}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private fromDateValidation(value: string | number) {
        return validFromValidation(value.toString(),
            this.props.filters.creationDateTo
                ? this.props.filters.creationDateTo : undefined);
    }

    private onChangeFromDate(value?: string | number) {
        return this.onChange("creationDateFrom", value?.toString() ?? "");
    }

    private toDateValidation(value: string | number) {
        return validTillValidation(value.toString(),
            this.props.filters.creationDateFrom
                ? this.props.filters.creationDateFrom : undefined);
    }

    private onChangeToDate(value?: string | number) {
        return this.onChange("creationDateTo", value?.toString() ?? "");
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof ExcassoFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof ExcassoFilters>(key: K, value: ExcassoFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private formIsValid = () => {
        if (this.props.filters.creationDateFrom && this.props.filters.creationDateTo) {
            return validFromValidation(this.props.filters.creationDateFrom,
                this.props.filters.creationDateTo).status === ValidationStatus.SUCCESS
                && validTillValidation(this.props.filters.creationDateTo,
                    this.props.filters.creationDateFrom).status === ValidationStatus.SUCCESS;
        }
        return true;
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
