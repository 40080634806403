import { Action } from "redux";

import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn, CollectiveInvoiceForm } from "../ReduxState";
import { State } from "../../utils/redux/State";

/**
 *
 */
type UpdateCollectiveInvoiceFormAction = Readonly<
Action<ActionTypeKeys.UPDATE_COLLECTIVE_INVOICE_FORM> & {
    duration?: Partial<CollectiveInvoiceForm>;
}
>;

export const updateCollectiveInvoiceForm =
    (duration: Partial<CollectiveInvoiceForm>): UpdateCollectiveInvoiceFormAction => ({
        type: ActionTypeKeys.UPDATE_COLLECTIVE_INVOICE_FORM,
        duration: duration,
    });

/**
 *
 */
export const updateCollectiveInvoiceFormReducer:
ReducerFn<UpdateCollectiveInvoiceFormAction> =
    (s, a) => (
        s.setProp(
            "collectiveInvoiceForm",
            s.prop("collectiveInvoiceForm").updateWith(a.duration ? a.duration : {}),
        )
    );

/**
 *
 */
type ClearCollectiveInvoiceFormAction = Readonly<
Action<ActionTypeKeys.CLEAR_COLLECTIVE_INVOICE_FORM> &
{
    keys?: Array<keyof CollectiveInvoiceForm>;
}
>;

/**
 *
 * @param keys
 */
export const clearCollectiveInvoiceForm:
(
    keys?: Array<keyof CollectiveInvoiceForm>,
) => ClearCollectiveInvoiceFormAction =
    (keys) => ({
        type: ActionTypeKeys.CLEAR_COLLECTIVE_INVOICE_FORM,
        keys: keys,
    });

/**
 *
 */
export const clearCollectiveInvoiceFormReducer:
ReducerFn<ClearCollectiveInvoiceFormAction> =
    (s, _a) => (
        s.setProp(
            "collectiveInvoiceForm",
            State.create<CollectiveInvoiceForm>(() => ({})),
        )
    );

/**
 *
 */
type AddPartnersSelectionCIAction = Readonly<
Action<ActionTypeKeys.UPDATE_PARTNERS_CI> & {
    partnersCI: number[];
}
>;

/**
 *
 * @param CollectiveInvoiceForm
 */

export const addPartnersSelectionCI =
    (partnersCI: number[]): AddPartnersSelectionCIAction => ({
        type: ActionTypeKeys.UPDATE_PARTNERS_CI,
        partnersCI: partnersCI,
    });

/**
 *
 */
export const addPartnersSelectionCIReducer:
ReducerFn<AddPartnersSelectionCIAction> =
    (s, a) => {
        const partnersCI = s.prop("partnersCI");
        const selectedPartners = a.partnersCI ? a.partnersCI : [];
        const hasPartners = partnersCI
            ? partnersCI.some((partner) => selectedPartners && selectedPartners.filter((p) =>
                (partner === p)).length > 0) : false;
        return (
            s.setProp("partnersCI",
                (partnersCI && hasPartners ?
                    [...partnersCI.filter((partner) => selectedPartners && !selectedPartners.some((p) =>
                        partner === p)), ...selectedPartners] : partnersCI
                        ? [...partnersCI, ...selectedPartners] : [...selectedPartners]),
            )
        );
    };

/**
 *
 */
export type DeletePartnersSelectionCIAction = Readonly<
Action<ActionTypeKeys.DELETE_PARTNERS_CI> & {
    partnersCI: number[];
}
>;

export const deletePartnersSelectionCI =
(partnersCI: number[]): DeletePartnersSelectionCIAction => ({
    type: ActionTypeKeys.DELETE_PARTNERS_CI,
    partnersCI: partnersCI,
});

export const deletePartnersSelectionCIReducer:
ReducerFn<DeletePartnersSelectionCIAction> =
(s, a) => {
    const partnersCI = s.prop("partnersCI");
    const selectedPartners = a.partnersCI ? a.partnersCI : [];
    return (
        s.setProp(
            "partnersCI",
            partnersCI?.filter((partner) => selectedPartners && !selectedPartners.some((p) =>
                partner === p)),
        )
    );
};

/**
 *
 */
export type DeleteSinglePartnerCIAction = Readonly<
Action<ActionTypeKeys.DELETE_SINGLE_PARTNER_CI> & {
    id: number;
}
>;

export const deleteSinglePartnerCI =
(id: number): DeleteSinglePartnerCIAction => ({
    type: ActionTypeKeys.DELETE_SINGLE_PARTNER_CI,
    id: id,
});

export const deleteSinglePartnerCIReducer:
ReducerFn<DeleteSinglePartnerCIAction> =
(s, a) => {
    const partnersCI = s.prop("partnersCI");
    return (
        s.setProp(
            "partnersCI",
            partnersCI ? partnersCI.filter((partner) => !(partner === a.id)) : []
        )
    );
};

export type ClearPartnersCIAction = Readonly<
Action<ActionTypeKeys.CLEAR_PARTNERS_CI>
>;

/**
 *
 * @param keys
 */
export const clearPartnersCI:
() => ClearPartnersCIAction =
    () => ({
        type: ActionTypeKeys.CLEAR_PARTNERS_CI,
    });

/**
 *
 */
export const clearPartnersCIReducer:
ReducerFn<ClearPartnersCIAction> =
    (s, _a) => (
        s.setProp(
            "partnersCI",
            [],
        )
    );
