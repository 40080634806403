import { AxiosResponse } from "axios";
import { Excassos, ExcassoFilters } from "./Types";

export const mapExcassoRequests =
    (resp: AxiosResponse): Excassos => resp.data as Excassos;

/**
 *
 */
export const ExcassoFiltersToParams = (f: ExcassoFilters) => ({
    ...f,
});
