import * as React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    hasSearchableFilters,
    getFilters,
    FilterScope,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger, remoteClearResponse, remoteClearError } from "../../../../../store/RemoteActions";
import { SubPages } from "../../../../../store/AppDisplays";
import { ProductFilters, ProductStatus } from "../../../../../store/Product/Types";
import { ProductStatusTypeDisplay } from "../../../../../store/Product/Displays";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ThemePalette } from "../../../../../theme/_Types";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
type setFilterFn = (filters: Partial<ProductFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof ProductFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: ProductFilters;
    collapseFilter?: boolean;
    currentPage?: SubPages;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: ProductFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.PRODUCTS, p.defaultFilters),
    filters: getFilters(s, FilterScope.PRODUCTS) as ProductFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.PRODUCTS)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.PRODUCTS));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.PRODUCT_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.PRODUCT_RESULTS)),
        dispatch(remoteClearError(RemoteScope.PRODUCT_RESULTS))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.statusValidation = this.statusValidation.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            onChange={this.onChangeStatus}
                            value={this.props.filters?.ewStatus}
                            label="Product Status"
                            placeholder="Make a choice"
                            disabled={this.props.defaultFilters?.ewStatus !== undefined}
                            readonly={this.props.defaultFilters?.ewStatus !== undefined}
                            options={Object.keys(ProductStatus).map((p) => ({
                                label: ProductStatusTypeDisplay[p] as string,
                                value: p,
                            }))}
                            validationFunction={this.statusValidation}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private statusValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Status provided");
    }

    private onChangeStatus(value?: string | number) {
        return this.onChange("ewStatus", value ? value.toString() as ProductStatus : undefined);
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof ProductFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof ProductFilters>(key: K, value: ProductFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
