import { Action } from "redux";

import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { ReportForm } from "./Types";
import { State } from "../../utils/redux/State";
import { RemoteScope } from "../RemoteTypes";

type InitUpdateReportFormAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_REPORT_FORM> & {
}
>;

export const initUpdateReportForm =
    (): InitUpdateReportFormAction => ({
        type: ActionTypeKeys.INIT_UPDATE_REPORT_FORM,
    });

/**
 *
 */
export const initUpdateReportFormReducer:
ReducerFn<InitUpdateReportFormAction> =
    (s, _a) => {
        const proxyResults = s.prop("remote").prop(RemoteScope.PROXIES)?.content;
        const hasOnlyOneProxy = proxyResults?.length === 1;
        return (
            s.setProp(
                "reportForm",
                s.prop("reportForm").updateWith({
                    proxyId: hasOnlyOneProxy && proxyResults ? proxyResults[0].id : undefined,
                }),
            )
        );
    };

/**
 *
 */
type UpdateReportFormAction = Readonly<
Action<ActionTypeKeys.UPDATE_REPORT_FORM> & {
    props?: Partial<ReportForm>;
}
>;

/**
 *
 * @param ReportFormProps
 */
export const updateReportForm:
(props: Partial<ReportForm>) => UpdateReportFormAction =
    (props) => ({
        type: ActionTypeKeys.UPDATE_REPORT_FORM,
        props: props,
    });

/**
 *
 */
export const updateReportFormReducer:
ReducerFn<UpdateReportFormAction> =
    (s, a) => (
        s.setProp(
            "reportForm",
            s.prop("reportForm").updateWith(a.props ? a.props : {}),
        )
    );

/**
 *
 */
type ClearReportFormAction = Readonly<
Action<ActionTypeKeys.CLEAR_REPORT_FORM> &
{
    keys?: Array<keyof ReportForm>;
}
>;

/**
 *
 * @param keys
 */
export const clearReportForm:
(
    keys?: Array<keyof ReportForm>,
) => ClearReportFormAction =
    (keys) => ({
        type: ActionTypeKeys.CLEAR_REPORT_FORM,
        keys: keys,
    });

/**
 *
 */
export const clearReportFormReducer:
ReducerFn<ClearReportFormAction> =
    (s, _a) => (
        s.setProp(
            "reportForm",
            State.create<ReportForm>(() => ({})),
        )
    );
