import * as React from "react";

import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    title?: string;
    active?: boolean;
}

/**
 *
 * @param props
 */
const HrComp: React.FunctionComponent<Props> = (props) => {
    const className = joinClasses(
        props.className,
        "scl-a-hr",
        !!props.active ? "scl-a-hr--active" : undefined,
    );

    return (
        <hr
            className={className}
            data-content={props.title}
        />
    );
};

/**
 *
 */
export const Hr = asThemeConsumer(HrComp);
