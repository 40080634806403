import { ProductStatus, VehicleType, MaxMileage } from "./Types";

/**
 *
 */
export const ProductStatusTypeDisplay: Readonly<Record<ProductStatus, string>> = {
    [ProductStatus.CREATED]: "Created",
    [ProductStatus.ACTIVE]: "Active",
    [ProductStatus.INACTIVE]: "Inactive",
};

/**
 *
 */
export const VehicleTypeDisplay: Readonly<Record<VehicleType, string>> = {
    [VehicleType.PERSONENAUTO]: "Personenauto",
    [VehicleType.BEDRIJFSAUTO]: "Bedrijfsauto",
};

/**
 *
 */
export const MaxMileageDisplay: Readonly<Record<MaxMileage, string>> = {
    [MaxMileage.HUNDREDTHOUSAND]: "100000",
    [MaxMileage.HUNDREDTWENTYTHOUSAND]: "120000",
    [MaxMileage.HUNDREDFIFTYTHOUSAND]: "150000",
    [MaxMileage.TWOHUNDREDTHOUSAND]: "200000",
};
