
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack,
    NavbarStackContent, NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { Models } from "../../../../../store/Models/Types";
import { PolicyRequest } from "../../../../../store/Policies/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";

import { Results } from "../../00-blocks/Models/ResultsCreatePolicy";

/**
 *
 */
interface OwnProps {
    modalID: string;
    brandName: string;
    modelNumber?: string;
    onChange: <K extends keyof PolicyRequest>(key: K, value: PolicyRequest[K]) => void;
}

interface StateProps {
    results?: Models;
    remoteLoading: boolean;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    loadBrandResults: (brandName: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.MODEL_PER_BRAND_RESULTS),
    remoteLoading: isRemoteLoading(s, RemoteScope.MODEL_PER_BRAND_RESULTS),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    loadBrandResults: (brandName: string) => dispatch(remoteTrigger(RemoteScope.MODEL_PER_BRAND_RESULTS, {
        skip: 0,
        brandName: brandName,
    })),
});

/**
 *
 */
export class SelectPolicyModelModalComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.closeModal = this.closeModal.bind(this);
    }

    public componentDidMount(){
        this.props.loadBrandResults(this.props.brandName);
    }

    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.BRAND_ACCENT,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    Select a model
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button  icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }}/>
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn
                        style={{
                            maxHeight: "calc(98vh - 120px)",
                        }}
                    >
                        <FlexRow isScrollable>
                            <div style={{width: "100%"}}>
                                {this.props.remoteLoading && !this.props.results
                                    ? (
                                        <FillUp>
                                            <Container
                                                theme={{
                                                    padding: {
                                                        "": { y: 3 },
                                                        "md": { y: 4 },
                                                    },
                                                }}
                                            >
                                                <LoadingIndicator
                                                    type={LoadingIndications.DEFAULT}
                                                    theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                                />
                                            </Container>
                                        </FillUp>
                                    )
                                    : !!this.props.results && (
                                        <Results
                                            results={this.props.results}
                                            onChange={this.props.onChange}
                                            modelNumber={this.props.modelNumber}
                                        />)}
                            </div>
                        </FlexRow>
                        <FlexRow isFixed>
                            <FlexColumn
                                theme={{
                                    palette: ThemePalette.CONTRAST_TERTIARY,
                                }}
                            >
                                <Container
                                    theme={{
                                        padding: { "": { y: 3 } },
                                    }}
                                >
                                    <ButtonsWrapper
                                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                        alignment={Alignment.RIGHT}
                                    >
                                        <Button
                                            theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                            link={{ onClick: this.closeModal() }}
                                        >
                                            Continue
                                        </Button>
                                    </ButtonsWrapper>
                                </Container>
                            </FlexColumn>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }
}

/**
 *
 */
export const SelectPolicyModelModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectPolicyModelModalComp);
