import * as React from "react";
import { PageTabs, Pages, SubPages } from "../../../../store/AppDisplays";
import { BookingPeriodFilters } from "../../../../store/BookingPeriod/Types";
import {
    ClaimMatchFilters as ClaimMatchFiltersType,
} from "../../../../store/ClaimMatch/Types";
import {
    ClaimFilters as ClaimFiltersType,
} from "../../../../store/Claims/Types";
import {
    CollectiveInvoiceFilters as CollectiveInvoiceFiltersType,
} from "../../../../store/CollectiveInvoice/Types";
import {
    ExcassoFilters as ExcassoFiltersType,
} from "../../../../store/Excassos/Types";
import { GeneralLedgerFilters } from "../../../../store/GeneralLedgers/Types";
import {
    IncassoFilters as IncassoFiltersType,
} from "../../../../store/Incassos/Types";
import {
    InvoiceFilters as InvoiceFiltersType,
} from "../../../../store/Invoice/Types";
import {
    MandateFilters as MandateFiltersType,
} from "../../../../store/Mandates/Types";
import {
    MBFilters as MBFiltersType,
} from "../../../../store/ManualBooking/Types";
import { ModelFilters as ModelFiltersType } from "../../../../store/Models/Types";
import { PaymentFilters as PaymentFiltersType } from "../../../../store/PartnerPayments/Types";
import { PartnerFilters as PartnerFiltersType } from "../../../../store/Partners/Types";
import { ProductFilters as ProductFiltersType } from "../../../../store/Product/Types";
import { StatementFilters } from "../../../../store/Statements/Types";

import {
    ThemePalette,
    ThemeShadowSizes,
} from "../../../../theme/_Types";
import { Card } from "../../../../components/core/07-organisms/Card";
import { Filter as StatementsFilters } from "../00-blocks/Accounts/Statements/Filters";
import { Filter as BPFilters } from "../00-blocks/BookingPeriod/Filters";
import { Filter as ClaimMatchFilters } from "../00-blocks/ClaimMatch/Filters";
import { Filter as ClaimFilters } from "../00-blocks/Claims/Filters";
import { Filter as CollectiveInvoiceFilters } from "../00-blocks/CollectiveInvoice/Filters";
import { Filter as ExcassoFilters } from "../00-blocks/Excassos/Filters";
import { Filter as FileFilters } from "../00-blocks/Files/Filters";
import { Filter as GeneralLedgerFilter } from "../00-blocks/GeneralLedger/Filters";
import { Filter as IncassoFilters } from "../00-blocks/Incassos/Filters";
import { Filter as InvoiceFilters } from "../00-blocks/Invoice/Filters";
import { Filter as MandateFilters } from "../00-blocks/Mandates/Filters";
import { Filter as MBFilters } from "../00-blocks/ManualBookings/Filters";
import { Filter as ModelFilters } from "../00-blocks/Models/Filters";
import { Filter as PartnerFilters } from "../00-blocks/Partners/Filters";
import { Filter as PartnerFiltersCI } from "../00-blocks/Partners/FiltersCI";
import { Filter as PaymentFilters } from "../00-blocks/Payments/Filters";
import { Filter as PolicyFilters } from "../00-blocks/Policies/Filters";
import { Filter as ProductFilters } from "../00-blocks/Product/Filters";
import { Filter as TariffFilters } from "../00-blocks/Tariffs/Filters";
import { LoadingIndications, LoadingIndicator } from "../../../../components/core/05-atoms/LoadingIndicator";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";

/**
 *
 */
interface OwnProps {
    noSpacing?: boolean;
    collapseFilter?: boolean;
    loadingFilters: boolean;
    loadingResults: boolean;
    filterType?: string;
    currentPage: Pages | SubPages | PageTabs;
    defaultFilters?: StatementFilters | CollectiveInvoiceFiltersType | PartnerFiltersType | ClaimFiltersType |
    IncassoFiltersType | ClaimMatchFiltersType | GeneralLedgerFilters | InvoiceFiltersType | ExcassoFiltersType |
    MBFiltersType | BookingPeriodFilters | PaymentFiltersType | ModelFiltersType | ProductFiltersType;
}

/**
 *
 */
type Props = OwnProps;

/**
 *
 * @param props
 */
export class Filter
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public getFiltersByPage() {
        switch (this.props.currentPage) {
        case Pages.CLAIMS:
            return (
                <ClaimFilters
                    loadingResults={this.props.loadingResults}
                />);
        case SubPages.MANAGE_MODELS:
            return (
                <ModelFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as ModelFiltersType}
                />);
        case PageTabs.POLICIES_CLAIMS:
            return (
                <ClaimFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as ClaimFiltersType}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_CLAIMS:
            return (
                <ClaimMatchFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as ClaimMatchFiltersType}
                />);
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS:
            return (
                <InvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as InvoiceFiltersType}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_INCASSO:
            return (
                <IncassoFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as IncassoFiltersType}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_EXCASSO:
            return (
                <ExcassoFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as ExcassoFiltersType}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_COLLECTIVEINVOICES:
            return (
                <CollectiveInvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as CollectiveInvoiceFiltersType}
                    currentTab={this.props.currentPage}
                />);
        case SubPages.ACCOUNTS_DETAIL:
            return (
                <StatementsFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as StatementFilters}
                />
            );
        case SubPages.COLLECTIVE_INVOICE_GENERATION:
            return (
                <PartnerFiltersCI
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as PartnerFiltersType}
                />
            );
        case PageTabs.PARTNER_COLLECTIVEINVOICE:
            return (
                <CollectiveInvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as CollectiveInvoiceFiltersType}
                    currentTab={PageTabs.PARTNER_COLLECTIVEINVOICE}
                />
            );
        case PageTabs.PARTNER_INVOICE:
            return (
                <InvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as InvoiceFiltersType}
                    currentTab={PageTabs.PARTNER_INVOICE}
                />
            );
        case Pages.INDIVIDUALINVOICES:
            return (
                <InvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as InvoiceFiltersType}
                    currentTab={Pages.INDIVIDUALINVOICES}
                />
            );
        case PageTabs.PARTNER_PAYMENTS:
            return (
                <PaymentFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as PaymentFiltersType}
                />
            );
        case PageTabs.PARTNER_MANDATES:
            return (
                <MandateFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as MandateFiltersType}
                />
            );
        case PageTabs.POLICIES_INVOICES:
            return (
                <InvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as InvoiceFiltersType}
                    currentTab={PageTabs.POLICIES_INVOICES}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES:
            return (
                <InvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as InvoiceFiltersType}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES}
                />
            );
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES:
            return (
                <InvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as InvoiceFiltersType}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES}
                />
            );
        case Pages.POLICIES:
            return (
                <PolicyFilters
                    loadingResults={this.props.loadingResults}
                />
            );
        case Pages.TARIFFS:
            return (
                <TariffFilters
                    loadingResults={this.props.loadingResults}
                />
            );
        case Pages.PRODUCT:
            return (
                <ProductFilters
                    loadingResults={this.props.loadingResults}
                />
            );
        case Pages.PARTNERS:
            return (
                <PartnerFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as PartnerFiltersType}
                />
            );
        case SubPages.POLICY_CREATE:
            return (
                this.props.filterType === "partners" ?
                    (
                        <PartnerFilters
                            loadingResults={this.props.loadingResults}
                            defaultFilters={this.props.defaultFilters as PartnerFiltersType}
                            currentPage={this.props.currentPage}
                        />
                    ) :
                    (
                        <ProductFilters
                            loadingResults={this.props.loadingResults}
                            defaultFilters={this.props.defaultFilters as ProductFiltersType}
                            currentPage={this.props.currentPage}
                        />
                    )
            );
        case Pages.COLLECTIVEINVOICES:
            return (
                <CollectiveInvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as CollectiveInvoiceFiltersType}
                />
            );
        case Pages.GENERAL_LEDGER:
            return (
                <GeneralLedgerFilter
                    loadingResults={this.props.loadingResults}
                />);
        case PageTabs.ACCOUNTS_DETAIL_STATEMENTS_DETAIL_BOOK_GENERAL_LEDGER:
            return (
                <GeneralLedgerFilter
                    loadingResults={this.props.loadingResults}
                />);
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER:
            return (
                <GeneralLedgerFilter
                    loadingResults={this.props.loadingResults}
                />);
        case Pages.FILES:
            return (
                <FileFilters
                    loadingResults={this.props.loadingResults}
                />);
        case PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES:
            return (
                <CollectiveInvoiceFilters
                    loadingResults={this.props.loadingResults}
                    defaultFilters={this.props.defaultFilters as CollectiveInvoiceFiltersType}
                    currentTab={PageTabs.MANUAL_BOOKING_CREATE_BOOK_COLLECTIVEINVOICES}
                />
            );
        case Pages.MANUAL_BOOKING:
            return (
                <MBFilters
                    loadingResults={this.props.loadingResults}
                    loadingFilters={this.props.loadingFilters}
                />);
        case Pages.BOOKING_PERIOD:
            return (
                <BPFilters
                    loadingResults={this.props.loadingResults}
                    loadingFilters={this.props.loadingFilters}
                />);
        }
        return null;
    }

    public render() {
        return (
            <Div
                theme={{
                    padding: this.props.noSpacing ? undefined : {
                        "": { t: 2 },
                        "sm": { t: 3 },
                    },
                }}
            >
                <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
                    <Card title="Filters" collapsable defaultCollapsed={!!this.props.collapseFilter}>
                        <Container
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                padding: {
                                    "": { y: 3 },
                                    "sm": { y: 4 },
                                },
                            }}
                        >
                            {this.props.loadingFilters
                                ? (
                                    <LoadingIndicator
                                        type={LoadingIndications.DEFAULT}
                                        theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                    />
                                )
                                : this.getFiltersByPage()
                            }
                        </Container>
                    </Card>
                </Div>
            </Div>
        );
    }
}
