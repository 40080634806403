import { BookingPeriodMonths, BookingPeriodStatus } from "./Types";

/**
 *
 */
export const BookingPeriodMonthsDisplay: Readonly<Record<BookingPeriodMonths, string>> = {
    [BookingPeriodMonths.JANUARY]: "January",
    [BookingPeriodMonths.FEBRUARY]: "February",
    [BookingPeriodMonths.MARCH]: "March",
    [BookingPeriodMonths.APRIL]: "April",
    [BookingPeriodMonths.MAY]: "May",
    [BookingPeriodMonths.JUNE]: "June",
    [BookingPeriodMonths.JULY]: "July",
    [BookingPeriodMonths.AUGUST]: "August",
    [BookingPeriodMonths.SEPTEMBER]: "September",
    [BookingPeriodMonths.OCTOBER]: "October",
    [BookingPeriodMonths.NOVEMBER]: "November",
    [BookingPeriodMonths.DECEMBER]: "December",
};

/**
 *
 */
export const BookingPeriodStatusDisplay: Readonly<Record<BookingPeriodStatus, string>> = {
    [BookingPeriodStatus.OPEN]: "Open",
    [BookingPeriodStatus.CLOSED]: "Closed",
};
