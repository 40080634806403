import { PolicyBusinessRule } from "./Types";


/**
 *
 */
export const BusinessRuleViolationTypeDisplay: Readonly<Record<PolicyBusinessRule, string>> = {
    [PolicyBusinessRule.NON_EXISTING_EW_NUMBER]: "Non Existing EW Number",
    [PolicyBusinessRule.EW_HAS_EXPIRED]: "EW Has Expired",
    [PolicyBusinessRule.EW_IS_OVERLAPPING_WITH_FACTORY_WARRANTY]: "EW is overlapping with factory warranty",
    [PolicyBusinessRule.EW_SIGN_UP_DEADLINE_MISSED]: "EW signup deadline missed",
    [PolicyBusinessRule.EW_STARTED_ALREADY]: "EW started already",
    [PolicyBusinessRule.EW_NOT_ACTIVE]: "EW not active",
    [PolicyBusinessRule.NON_EXISTING_BRAND]: "Non existing brand",
    [PolicyBusinessRule.BRAND_NOT_RECOGNIZED]: "Brand not recognized",
    [PolicyBusinessRule.NON_EXISTING_MODEL]: "Non existing model",
    [PolicyBusinessRule.INVALID_VEHICLE_TYPE]: "Invalid vehicle type",
    [PolicyBusinessRule.MAX_POWER_BREACHED]: "Max power breached",
    [PolicyBusinessRule.TARIFF_LINE_NOT_FOUND]: "Tariff line not found",
    [PolicyBusinessRule.CLOSED_BOOKING_PERIOD]: "Closed booking period",
    [PolicyBusinessRule.NO_PRODUCT_NUMBER_DEFINED]: "No product number defined",
    [PolicyBusinessRule.NO_PARTNER_NUMBER_DEFINED]: "No partner number defined",
    [PolicyBusinessRule.NO_PARTNER_FOUND]: "No partner found",
    [PolicyBusinessRule.WRONG_PARTNER_TYPE]: "Wrong partner type",
    [PolicyBusinessRule.INVALID_BRAND_AND_MODEL_COMBINATION]: "Invalid brand and model combination",
    [PolicyBusinessRule.MULTIPLE_MODELS_MATCHED]: "Multiple models matched",
};
