import * as React from "react";
import { Redirect } from "react-router-dom";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { Icon, IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { Form } from "../../../../../../components/core/07-organisms/Form";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { FormBar } from "../../../../../../components/core/07-organisms/FormBar";
import { UserRoles } from "../../../../../../store/AppTypes";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";

import { Props as PremiumProps } from "../Tabs/Premium";

interface OwnProps {
    asAudit?: boolean;
}

type Props = OwnProps & PremiumProps;
/**
 *
 * @param props
 */
export const PremiumView: React.FC<Props> = (props: Props) => {
    const premium = props.formData &&
            props.formData.content &&
            props.formData.content.policyPremium ?
        props.formData.content.policyPremium : undefined;
    return (
        <React.Fragment>
            <Div theme={{ shadow: ThemeShadowSizes.TINY }}>
                <Container
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    {props.resultsSuccess && (
                        <Redirect to={`/policies/${props.policyId ?? ""}`} />
                    )}
                    <Form>
                        <FieldSet
                            title={`Max. ${premium?.maxKW ?? ""} kW`}
                            key={`tariff-${premium?.id ?? ""}`}
                        >
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Premium"
                                        type="currency"
                                        value={premium?.premium}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Tax"
                                        type="currency"
                                        value={premium?.tax}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Net Premium"
                                        type="currency"
                                        value={premium?.netPremium}
                                        asSummary
                                    />
                                </Grid>
                            </Div>
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Cost"
                                        type="currency"
                                        value={premium?.cost}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Net Premium Excluding Cost"
                                        type="currency"
                                        value={premium?.netPremiumExCost}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Commission VWPFS"
                                        type="currency"
                                        value={premium?.commissionVWPFS}
                                        asSummary
                                    />
                                </Grid>
                            </Div>
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Net mij"
                                        type="currency"
                                        value={premium?.netMij}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Frontier"
                                        type="currency"
                                        value={premium?.frontier}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Insurer"
                                        type="currency"
                                        value={premium?.insurer}
                                        asSummary
                                    />
                                </Grid>
                            </Div>
                            <Div className="scl-b-row">
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Sign Committee Damage"
                                        type="currency"
                                        value={premium?.signCommitteeDamage}
                                        asSummary
                                    />
                                </Grid>
                                <Grid
                                    size={{ xs: 12, md: 4 }}
                                >
                                    <FormField
                                        label="Incasso Provision"
                                        type="currency"
                                        value={premium?.incassoProvision}
                                        asSummary
                                    />
                                </Grid>
                            </Div>
                        </FieldSet>
                    </Form>
                    {props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && props.asAudit !== true ?
                        <FormBar
                            title="Manage premium"
                            onClick={() => props.openPremiumRequest?.(props.policyId ?? "")}
                            showButton
                            buttonLabel={<>
                                Update premium
                                &nbsp;
                                &nbsp;
                                <Icon name={IconNames.ARROW_RIGHT} />
                            </>}
                        /> : <></>}
                </Container>
            </Div>
        </React.Fragment>
    );
};
