import React from "react";
import { DropzoneRootProps, DropzoneInputProps } from "react-dropzone";
import { BreakpointConsumer } from "../../../utils/Breakpoints";
import * as I18n from "../../../utils/translations/I18n";

export interface Props {
    wrapper: (props?: DropzoneRootProps) => DropzoneRootProps;
    input: (props?: DropzoneInputProps) => DropzoneInputProps;
}

export const DragNDropArea: React.FunctionComponent<Props> = props => (
    <div {...props.wrapper({ className: "dropzone scl-o-dragndrop-area" })}>
        <input {...props.input()} className="scl-o-dragndrop-area__input" />
        <BreakpointConsumer>
            {(value) => {
                if (!value.breakpointAtSM) {
                    return (
                        <React.Fragment>
                            <div className="scl-o-dragndrop-area__title">
                                {I18n.getText("mary.07-organisms.dragndroparea.title--touch", "Click & Select")}</div>
                            <div className="scl-o-dragndrop-area__description">
                                {I18n.getText("mary.07-organisms.dragndroparea.description--touch",
                                    "And upload your files")}</div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <div className="scl-o-dragndrop-area__title">
                                {I18n.getText("mary.07-organisms.dragndroparea.title", "Drag & Drop")}</div>
                            <div className="scl-o-dragndrop-area__description">
                                {I18n.getText("mary.07-organisms.dragndroparea.description",
                                    "Or click to select files")}</div>
                        </React.Fragment>
                    );
                }
            }}
        </BreakpointConsumer>
    </div>
);
