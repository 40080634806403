import { CollectiveInvoiceStatus } from "./Types";

export const CollectiveInvoiceStatusTypeDisplay: Readonly<Record<CollectiveInvoiceStatus, string>> = {
    [CollectiveInvoiceStatus.PAID]: "Paid",
    [CollectiveInvoiceStatus.UNPAID]: "Unpaid",
    [CollectiveInvoiceStatus.REVERSED]: "Reversed",
    [CollectiveInvoiceStatus.CANCELED]: "Canceled",
    [CollectiveInvoiceStatus.PENDING]: "Pending",
    [CollectiveInvoiceStatus.PARTIALLY_PAID]: "Partially paid",
};
