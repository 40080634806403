/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";


import { SelectedRows } from "./Results";
import { InExFormScope, getProps, canSubmitInExForm, InExGenerate,
    InExInfo } from "../../../../../store/CollectiveInvoice/Types";
import { updateInExForm, clearInExForm } from "../../../../../store/CollectiveInvoice/GenerateIncasso";
import { Accounts } from "../../../../../store/Accounts/Types";
import { InExGenerationForm, isRemoteLoading, ReduxState } from "../../../../../store/ReduxState";
import { Div } from "../../../../../components/core/03-base/Div";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { IconNames, Icon } from "../../../../../components/core/05-atoms/Icon";
import { LinkTarget } from "../../../../../components/core/05-atoms/Link";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { InfoCard } from "../../../../../components/core/06-molecules/InfoCard";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";

type setInExFormFn = (fields: Partial<InExGenerationForm>) => void;
type clearInExFormFn = (fields?: Array<keyof InExGenerationForm>) => void;

interface OwnProps {
    modalID: string;
    selectedRows: SelectedRows[];
    scope: InExFormScope;
    forPartnerInvoices?: boolean;
}

interface State {
    ready: boolean;
    loading: boolean;
}

interface StateProps {
    inExForm: InExGenerationForm;
    resultsAccounts: Accounts;
    canSubmitInExForm: boolean;
    resultsInEx: InExGenerate;
    loading: boolean;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    mapWithIncasso: (selectedRows: InExInfo) => void;
    mapWithExcasso: (selectedRows: InExInfo, forPartnerInvoices?: boolean) => void;
    loadResults: () => void;
    setInExForm: setInExFormFn;
    clearInExForm: clearInExFormFn;
    loadResultsAccounts: () => void;
}

type Props = OwnProps & DispatchProps & StateProps;

export const mapStateToProps = (s: ReduxState): StateProps => {
    const incasso = s.prop("inexGenerationForm");
    return {
        inExForm: getProps(incasso, InExFormScope.INCASSO) as InExGenerationForm,
        resultsAccounts: s.prop("remote").prop(RemoteScope.ACCOUNT_RESULTS) as Accounts,
        canSubmitInExForm: canSubmitInExForm(incasso, InExFormScope.INCASSO),
        resultsInEx: s.prop("remote").prop(RemoteScope.COLLECTIVE_INVOICE_INCASSO) as InExGenerate ||
            s.prop("remote").prop(RemoteScope.COLLECTIVE_INVOICE_EXCASSO) as InExGenerate,
        loading: isRemoteLoading(s, RemoteScope.ACCOUNT_RESULTS),
    };
};

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    mapWithIncasso: (selectedRows: InExInfo) => dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_INCASSO, {
        selectedRows: selectedRows,
    })),
    mapWithExcasso: (selectedRows: InExInfo, forPartnerInvoices?: boolean) =>
        dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_EXCASSO, {
            selectedRows: selectedRows,
            forPartnerInvoices: forPartnerInvoices,
        })),
    loadResults: () => dispatch(remoteTrigger(RemoteScope.COLLECTIVE_INVOICE_RESULTS, {
        skip: 0,
    })),
    setInExForm: (reportForm) => dispatch(updateInExForm(reportForm)),
    clearInExForm: (fields) => {
        dispatch(clearInExForm(fields));
    },
    loadResultsAccounts: () => dispatch(remoteTrigger(RemoteScope.ACCOUNT_RESULTS, {
        skip: 0,
        inexformscope: InExFormScope.INCASSO,
    })),
});

export const closeModal = (props: Props) => () => {
    props.closeModal(props.modalID);
    props.loadResults();
    props.clearInExForm();
};

/**
 *
 */
export class ConfirmInExComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { ready: false, loading: false };
        this.generate = this.generate.bind(this);
        // this.closeModal = this.closeModal.bind(this);
        this.onChangeRequestedDate = this.onChangeRequestedDate.bind(this);
        this.onChangeIncassoName = this.onChangeIncassoName.bind(this);
        this.onChangeAccountId = this.onChangeAccountId.bind(this);
        this.onChangeIncassoNote = this.onChangeIncassoNote.bind(this);
        this.incassoNameValidationMessage = this.incassoNameValidationMessage.bind(this);
        this.accountIdValidationMessage = this.accountIdValidationMessage.bind(this);
        this.incassoNoteValidationMessage = this.incassoNoteValidationMessage.bind(this);
    }

    public componentDidMount() {
        this.props.loadResultsAccounts();
    }

    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {this.props.scope === InExFormScope.INCASSO ?
                                        "Generate Incasso" : "Generate Excasso"}
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button
                                        icon={IconNames.TIMES}
                                        link={{ onClick: closeModal(this.props) }}/>
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn>
                        <FlexRow isScrollable >
                            <FillUp>
                                <Container
                                    theme={{
                                        padding: {
                                            "": { y: 3 },
                                            "md": { y: 4 },
                                        },
                                    }}
                                >
                                    {this.props.loading ? (
                                        <LoadingIndicator
                                            type={LoadingIndications.DEFAULT}
                                            theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                        />
                                    ) :
                                        this.state.loading
                                            ? (
                                                <LoadingIndicator
                                                    type={LoadingIndications.DEFAULT}
                                                    theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                                />
                                            )
                                            : (
                                                !this.state.ready ?
                                                    <>
                                                        {this.props.resultsAccounts && this.props.resultsAccounts.content ?
                                                            <>
                                                                <Form>
                                                                    <Div className="scl-b-row">
                                                                        <Grid
                                                                            size={{ xs: 12, md: 6 }}
                                                                        >
                                                                            <FormField
                                                                                label="Requested collection date"
                                                                                required
                                                                                placeholder="e.g. 2020-01-31"
                                                                                onChange={this.onChangeRequestedDate}
                                                                                value={this.props.inExForm?.requestedDate ?? ""}
                                                                                type="date"
                                                                                popperPlacement={"bottom-end"}
                                                                                minDate={new Date()}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            size={{ xs: 12, md: 6 }}
                                                                        >
                                                                            <FormField
                                                                                label={this.props.scope === InExFormScope.INCASSO ? "Incasso file name" : "Excasso file name"}
                                                                                required
                                                                                placeholder={this.props.scope === InExFormScope.INCASSO ? "e.g. My Incasso File" : "My Excasso file"}
                                                                                onChange={this.onChangeIncassoName}
                                                                                value={this.props.scope === InExFormScope.INCASSO ? this.props.inExForm?.incassoName : this.props.inExForm?.excassoName}
                                                                                type="text"
                                                                                validationFunction={
                                                                                    this.incassoNameValidationMessage}
                                                                            />
                                                                        </Grid>
                                                                    </Div>
                                                                    <Div className="scl-b-row">
                                                                        <Grid
                                                                            size={{ xs: 12, md: 6 }}
                                                                        >
                                                                            <FormField
                                                                                onChange={this.onChangeAccountId}
                                                                                value={this.props.inExForm?.accountId ?? ""}
                                                                                label="AccountId"
                                                                                required
                                                                                placeholder="Make a choice"
                                                                                options={this.props.resultsAccounts.content.map(item => ({
                                                                                    label: item.iban,
                                                                                    value: item.id,
                                                                                }))}
                                                                                validationFunction={this.accountIdValidationMessage}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            size={{ xs: 12, md: 6 }}
                                                                        >
                                                                            <FormField
                                                                                label="Note"
                                                                                required
                                                                                placeholder="e.g. Message"
                                                                                onChange={this.onChangeIncassoNote}
                                                                                value={this.props.scope === InExFormScope.INCASSO ? this.props.inExForm?.incassoNote : this.props.inExForm?.excassoNote}
                                                                                type="text"
                                                                                validationFunction={this.incassoNoteValidationMessage}
                                                                            />
                                                                        </Grid>
                                                                    </Div>
                                                                </Form>
                                                                <Div><Required /></Div>
                                                            </> :
                                                            <LoadingIndicator
                                                                type={LoadingIndications.DEFAULT}
                                                                theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                                            />
                                                        }
                                                        <b>Are you sure you want to proceed? Click &apos;Yes&apos;</b>
                                                    </>
                                                    :
                                                    <>
                                                        <Div className="scl-b-row">
                                                            <Grid size={{ xs: 2, md: 2 }}>
                                                            &nbsp;
                                                            </Grid>
                                                            <Grid
                                                                size={{ xs: 8, md: 8 }}
                                                            >
                                                                {this.props.resultsInEx &&
                                                                this.props.resultsInEx.content &&
                                                                this.props.resultsInEx.content.downloadUrl ?
                                                                    <InfoCard
                                                                        title={"Download"}
                                                                        description={`You can download the ${this.props.scope.toLowerCase()} file now.`}
                                                                        link={{
                                                                            href: this.props.resultsInEx.content.downloadUrl,
                                                                            target: LinkTarget.BLANK,
                                                                        }}
                                                                        icon={IconNames.DOWNLOAD}
                                                                    /> :
                                                                    <span>Oops, something went wrong. Continue to try again!</span>}
                                                            </Grid>
                                                        </Div>
                                                        <ButtonsWrapper
                                                            orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                            alignment={Alignment.RIGHT}
                                                        >
                                                            <Button
                                                                theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                                                className="scl-a-btn--big"
                                                                buttonType="submit"
                                                                link={{ onClick: closeModal(this.props) }}
                                                            >
                                                            Continue
                                                            &nbsp;&nbsp;
                                                                <Icon name={IconNames.ARROW_RIGHT} />
                                                            </Button>
                                                        </ButtonsWrapper>
                                                    </>
                                            )}
                                </Container>
                                {!this.state.ready &&
                                    <FlexRow isFixed>
                                        <FlexColumn
                                            theme={{
                                                palette: ThemePalette.CONTRAST_SECONDARY,
                                            }}
                                        >
                                            <Container
                                                theme={{
                                                    padding: { "": { y: 3 } },
                                                }}
                                            >
                                                <ButtonsWrapper
                                                    orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                    alignment={Alignment.RIGHT}
                                                >
                                                    <Button
                                                        theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                                        link={{ onClick: closeModal(this.props) }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                        disabled={!this.props.canSubmitInExForm}
                                                        link={{ onClick: this.generate }}
                                                    >
                                                        Yes
                                                    </Button>
                                                </ButtonsWrapper>
                                            </Container>
                                        </FlexColumn>
                                    </FlexRow>}
                            </FillUp>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }
    private generate() {
        this.setState({
            loading: true,
        }, () => {
            setTimeout(() => this.setState({
                ready: true,
                loading: false,
            }), 2000);
        });
        if (this.props.scope === InExFormScope.INCASSO) {
            this.props.mapWithIncasso({
                requestedDate: this.props.inExForm.requestedDate || "",
                incassoName: this.props.inExForm.incassoName || "",
                incassoNote: this.props.inExForm.incassoNote || "",
                accountId: this.props.inExForm.accountId || 0,
                collectiveInvoicesIds: this.props.selectedRows.map(row => Number(row.id)),
                partnerInvoicesIds: [],
                excassoName: this.props.inExForm.incassoName || "",
                excassoNote: this.props.inExForm.incassoNote || "",
            });
        } else {
            this.props.mapWithExcasso({
                requestedDate: this.props.inExForm.requestedDate || "",
                excassoName: this.props.inExForm.excassoName || "",
                excassoNote: this.props.inExForm.excassoNote || "",
                accountId: this.props.inExForm.accountId || 0,
                collectiveInvoicesIds: this.props.forPartnerInvoices ? [] : this.props.selectedRows.map(row => Number(row.id)),
                partnerInvoicesIds: this.props.forPartnerInvoices ? this.props.selectedRows.map(row => Number(row.id)) : [],
                incassoName: this.props.inExForm.excassoName || "",
                incassoNote: this.props.inExForm.excassoNote || "",
            }, this.props.forPartnerInvoices);
        }
    }

    private onChangeRequestedDate(value?: string | number) {
        return this.onChange("requestedDate", value?.toString() ?? "");
    }

    private onChangeIncassoName(value?: string | number) {
        return this.onChange(this.props.scope === InExFormScope.INCASSO ? "incassoName" : "excassoName", value?.toString() ?? "");
    }

    private incassoNameValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            this.props.scope === InExFormScope.INCASSO ? "Incasso name provided" : "Excasso name provided",
        );
    }

    private onChangeAccountId(value?: string | number) {
        return this.onChange("accountId", value ? Number(value) : undefined);
    }

    private accountIdValidationMessage(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Account Id provided",
        );
    }

    private onChangeIncassoNote(value?: string | number) {
        return this.onChange(this.props.scope === InExFormScope.INCASSO ? "incassoNote" : "excassoNote", value?.toString() ?? "");
    }

    private incassoNoteValidationMessage(value: string | number) {
        return hasValueValidation(value.toString(), "Note provided");
    }

    private onChange = <K extends keyof InExGenerationForm>(key: K, value: InExGenerationForm[K]) => {
        this.props.setInExForm({
            [key]: value,
        });
    };
}

/**
 *
 */
export const ConfirmInExModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfirmInExComp);
