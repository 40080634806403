
import React from "react";
import { Div } from "../../../components/core/03-base/Div";
import { Button } from "../../../components/core/05-atoms/Button";
import { Currency } from "../../../components/core/05-atoms/CurrencyDisplay";
import { Navbar } from "../../../components/core/07-organisms/Navbar";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../components/core/07-organisms/Navbar/Title";
import { ThemePalette } from "../../../theme/_Types";
import { navbarTheme } from "../09-views/00-blocks/ManualBookings/Create/ManualBookBar";

interface OwnProps {
    selectedBalance: number;
    unclearedBalanceAfter: number;
    unclearedBalance: number;
    isValid?: boolean;
    title: string;
}

/**
 *
 */
type Props = OwnProps;

export const BookBar: React.FunctionComponent<Props> = props => (
    <Div
        style={{
            position: "fixed",
            left: "400px",
            bottom: 0,
            right: 0,
            zIndex: 9,
        }}
    >
        <Navbar theme={navbarTheme(props.isValid)} className="scl-h-text-align--left">
            <NavbarStack>
                <NavbarStackContent>
                    <NavbarTitle>
                        {props.title}
                    </NavbarTitle>
                </NavbarStackContent>
                <NavbarStackRight>
                    <Div theme={{ padding: { "": { x: 2 }, "sm": { x: 3 } } }}>
                        <Div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                }}
                                theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "5px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <span
                                            className="scl-a-label__field scl-a-label__field--as-summary"
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            Uncleared balance
                                        </span>
                                        <span
                                            className="scl-b-h scl-b-h3 "
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <Currency amount={props.unclearedBalance} />
                                        </span>
                                    </span>
                                </span>
                            </Div>
                            <Div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "5px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <span
                                            className="scl-a-label__field scl-a-label__field--as-summary"
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            Selected balance
                                        </span>
                                        <span
                                            className="scl-b-h scl-b-h3 "
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <Currency amount={props.selectedBalance} />
                                        </span>
                                    </span>
                                </span>
                            </Div>
                            <Div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "stretch",
                                }}
                                theme={{ padding: { "": { r: 1 }, "sm": { r: 2 } } }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span
                                        style={{
                                            paddingLeft: "15px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <span
                                            className="scl-a-label__field scl-a-label__field--as-summary"
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <b>
                                            Uncleared after booking
                                            </b>
                                        </span>
                                        <span
                                            className="scl-b-h scl-b-h3 "
                                            style={{
                                                color: "#fff",
                                            }}
                                        >
                                            <Currency amount={props.unclearedBalanceAfter} />
                                        </span>
                                    </span>
                                </span>
                            </Div>
                            {!!props.isValid &&
                            <Div
                                theme={{ padding: { "": { l: 2 }, "sm": { l: 3 } } }}
                            >
                                <Button
                                    // link={{
                                    //     onClick: props.onClick,
                                    // }}
                                    buttonType="submit"
                                    className="scl-a-btn--big"
                                    theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                                >
                                    Book selection
                                </Button>
                            </Div>
                            }
                        </Div>
                    </Div>
                </NavbarStackRight>
            </NavbarStack>
        </Navbar>
    </Div>
);
