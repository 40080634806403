/* eslint-disable max-len */
import React from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { Icon, IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";
import { SubPageDisplay, SubPages, PageDisplay, Pages, PageTabs } from "../../../../../../store/AppDisplays";
import { UserRoles } from "../../../../../../store/AppTypes";
import { ProductStatus } from "../../../../../../store/Product/Types";
import { ThemePaletteState, ThemePalette, ThemeBreakpoints, ThemeShadowSizes } from "../../../../../../theme/_Types";
import { getProductStatusColor } from "../../../../../utils/FormatHelpers";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";
import { Summary } from "../../../00-blocks/Summary";
import { Tabs } from "../../../00-blocks/Tabs";
import { Header } from "../../../../../../components/core/09-views/01-sections/Header";
import { Props as DetailProps } from "../Detail";

interface OwnProps {
    asAudit?: boolean;
}

type Props = OwnProps & DetailProps;

/**
 *
 * @param props
 */
export const DetailView: React.FC<Props> = (props: Props) => (
    <>
        {!props.asAudit &&
                <Header
                    title={SubPageDisplay[SubPages.PRODUCT_DETAIL].title}
                    goBack={PageDisplay[Pages.PRODUCT].path}
                />
        }
        {
            props.loading || !props.details ?
                (
                    <LoadingData loading={!!props.loading} />
                ) :
                (
                    <>
                        <Summary
                            hasActions
                            tableColumnData={[
                                { label: "Number" },
                                { label: "Name" },
                                { label: "For used vehicle" },
                                { label: "Product number" },
                                { label: "coverage Code (PAH)" },
                                { label: "Status" },
                                props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && props.asAudit !== true ?
                                    {
                                        label: "Actions",
                                    } :
                                    {
                                        label: "",
                                    },
                            ]}
                            tablerowData={[
                                {
                                    id: 1,
                                    data: [
                                        props.details?.content.id,
                                        props.details?.content.name,
                                        props.details?.content.forUsedVehicles ?
                                            (
                                                <Badge
                                                    theme={{ paletteState: ThemePaletteState.SUCCESS }}
                                                >
                                                        Yes
                                                </Badge>
                                            ) :
                                            (
                                                <Badge
                                                    theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                                >
                                                        No
                                                </Badge>
                                            ),
                                        props.details?.content.productNumber,
                                        props.details?.content.coverageCodeForPah,
                                        (
                                            <Badge
                                                key={`badge-${1}`}
                                                theme={{
                                                    paletteState:
                                                            getProductStatusColor
                                                            (props.details.content.status),
                                                }}
                                            >
                                                {props.details.content.status}
                                            </Badge>
                                        ),
                                        // inactive n created
                                        props.user?.userRole !== UserRoles.EW_ADMIN_READONLY && props.asAudit !== true ?
                                            (<ButtonsWrapper
                                                asGroup
                                                alignment={Alignment.RIGHT}
                                                orientations={{
                                                    [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                                                }}
                                            > {
                                                    props.details.content.status !== ProductStatus.ACTIVE ? (
                                                        <>
                                                            <Button
                                                                label="Activate"
                                                                className="scl-a-btn--tiny"
                                                                theme={{
                                                                    paletteState: ThemePaletteState.SUCCESS,
                                                                    shadow: ThemeShadowSizes.TINY,
                                                                }}
                                                                link={{
                                                                    onClick: () =>
                                                                        props.openActivateRequest?.(
                                                                            props.details.content.id.toString()),
                                                                }}
                                                            />
                                                            {
                                                                props.details.content.status ===
                                                                ProductStatus.CREATED
                                                                &&
                                                                <Button
                                                                    theme={{
                                                                        paletteState: ThemePaletteState.DANGER,
                                                                        shadow: ThemeShadowSizes.TINY,
                                                                    }}
                                                                    className="scl-a-btn--tiny"
                                                                    link={{
                                                                        onClick: () =>
                                                                            props.openDeleteRequest?.(
                                                                                props.details.content.id.toString()),
                                                                    }}
                                                                >
                                                                    <Icon name={IconNames.DELETE} />
                                                                </Button>
                                                            }
                                                            {props.details.content.status
                                                                !== ProductStatus.INACTIVE &&
                                                                <NavLink to={`/extendedwarranties/${props.params.ewId}/edit`}>
                                                                    <Button
                                                                        label="Edit"
                                                                        className="scl-a-btn--tiny"
                                                                        theme={{
                                                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                                                            shadow: ThemeShadowSizes.TINY,
                                                                        }}
                                                                    />
                                                                </NavLink>}
                                                        </>
                                                    ) :
                                                        <>
                                                            <Button
                                                                label="Deactivate"
                                                                className="scl-a-btn--tiny"
                                                                theme={{
                                                                    paletteState: ThemePaletteState.DANGER,
                                                                    shadow: ThemeShadowSizes.TINY,
                                                                }}
                                                                link={{
                                                                    onClick: () =>
                                                                        props.openDeactivateRequest?.(
                                                                            props.details.content.id.toString()),
                                                                }}
                                                            />
                                                            <NavLink to={`/extendedwarranties/${props.params.ewId}/addproducttariff`}>
                                                                <Button
                                                                    label="Add Product Tariff"
                                                                    className="scl-a-btn--tiny"
                                                                    theme={{
                                                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                                                        shadow: ThemeShadowSizes.TINY,
                                                                    }}
                                                                />
                                                            </NavLink>
                                                        </>
                                                }
                                            </ButtonsWrapper>) : "",
                                    ],
                                }]}
                        />
                        {props.asAudit !== true &&
                            <Tabs
                                tabs={SubPageDisplay[SubPages.PRODUCT_DETAIL].tabs}
                                defaultTab={PageTabs.PRODUCT_GENERAL}
                            />}
                    </>
                )
        }
    </>
);
