import * as React from "react";
import { connect } from "react-redux";


import { ReduxState } from "../../../../../../store/ReduxState";
import * as ClaimTypes from "../../../../../../store/Claims/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../../components/core/03-base/Grid";
import { FieldSet } from "../../../../../../components/core/06-molecules/FieldSet";
import { ThemeShadowSizes, ThemePalette } from "../../../../../../theme/_Types";
import { FormField } from "../../../../../../components/core/07-organisms/Form/Field";
import { Container } from "../../../../../../components/core/03-base/Container";

/**
 *
 */
interface StateProps {
    details: ClaimTypes.ClaimDetails;
}

/**
 *
 */
type Props = StateProps;

/**
 *
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    details: s.prop("remote").prop(RemoteScope.CLAIM_DETAILS) as ClaimTypes.ClaimDetails,
});

/**
 *
 * @param props
 */
export class BookingsComp
    extends React.Component<Props, {}> {


    public constructor(props: Props) {
        super(props);
    }

    public render() {
        const bookings = this.props.details &&
                            this.props.details.content &&
                            this.props.details.content.damageBookings ?
            this.props.details.content.damageBookings : undefined;
        return (
            <React.Fragment>
                {bookings && bookings.map((booking, index) => (
                    <Div
                        theme={{
                            shadow: ThemeShadowSizes.TINY,
                            margin: {
                                "": { b: 1 },
                                "sm": { b: 1 },
                            },
                        }}
                        key={`booking-${index}`}
                    >
                        <Container
                            theme={{
                                palette: ThemePalette.CONTRAST_PRIMARY,
                                padding: {
                                    "": { y: 3 },
                                    "sm": { y: 4 },
                                },
                            }}
                        >
                            <FieldSet title="Bookings info">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Booking Id"
                                            value={booking.id}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Claim number (PAH)"
                                            value={booking.claimNumberPAH}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Claim element type"
                                            value={booking.claimElementType}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Relation number (PAH)"
                                            value={booking.relationNumberStakeholderPAH}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Status"
                                            value={booking.status}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                            <FieldSet title="Bank info">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 6 }}
                                    >
                                        <FormField
                                            label="Beneficiary account number"
                                            value={booking.bankAccountBeneficiaryNumber}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                            <FieldSet title="Damage amount info">
                                <Div className="scl-b-row">
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Internal material"
                                            type="currency"
                                            value={booking.cascoDamageAmountInternalMaterial}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="Internal labour"
                                            type="currency"
                                            value={booking.cascoDamageAmountInternalLabour}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="External material"
                                            type="currency"
                                            value={booking.cascoDamageAmountExternalMaterial}
                                            asSummary
                                        />
                                    </Grid>
                                    <Grid
                                        size={{ xs: 12, md: 3 }}
                                    >
                                        <FormField
                                            label="External labour"
                                            type="currency"
                                            value={booking.cascoDamageAmountExternalLabour}
                                            asSummary
                                        />
                                    </Grid>
                                </Div>
                            </FieldSet>
                        </Container>
                    </Div>
                ))}
            </React.Fragment>
        );
    }
}

/**
 *
 */
export const Bookings = connect(
    mapStateToProps,
    {},
)(BookingsComp);
