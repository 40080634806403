import { Action } from "redux";
import { State } from "../../utils/redux/State";

import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { PolicyRequest } from "./Types";

/**
 *
 */
export type InitUpdatePolicyAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_POLICY> & {
}
>;

/**
 *
 *
 */
export const initUpdatePolicy =
    (): InitUpdatePolicyAction => ({
        type: ActionTypeKeys.INIT_UPDATE_POLICY,
    });

/**
 *
 * @param s
 * @param _a
 */
export const initUpdatePolicyReducer:
ReducerFn<InitUpdatePolicyAction> =
    (s, _a) => {
        const policyDetails = s.prop("remote").prop(RemoteScope.POLICY_DETAILS);
        const policyEditRequestData: State<PolicyRequest> =
            State.create<PolicyRequest>(() => policyDetails
                ? {
                    ...policyDetails.content,
                    extendedWarranty: { id: policyDetails.content?.extendedWarranty?.id },
                }
                : {});
        return (
            s.setProp(
                "policyRequest",
                policyEditRequestData,
            )
        );
    };

/**
 *
 */
export type UpdatePolicyAction = Readonly<
Action<ActionTypeKeys.UPDATE_POLICY> & {
    policy?: Partial<PolicyRequest>;
}
>;

/**
 *
 *
 */
export const updatePolicy =
    (policy: PolicyRequest): UpdatePolicyAction => ({
        type: ActionTypeKeys.UPDATE_POLICY,
        policy: policy,
    });

/**
 *
 * @param s
 * @param a
 */
export const updatePolicyReducer:
ReducerFn<UpdatePolicyAction> =
    (s, a) => s.setProp(
        "policyRequest",
        s.prop("policyRequest").updateWith(
            a.policy ? { ...a.policy }
                : {},
        ),
    );

/**
 *
 */
export type ClearPolicyAction = Readonly<
Action<ActionTypeKeys.CLEAR_POLICY>
>;

/**
 *
 * @param keys
 */
export const clearPolicy:
() => ClearPolicyAction =
    () => ({
        type: ActionTypeKeys.CLEAR_POLICY,
    });

/**
 *
 */
export const clearPolicyReducer:
ReducerFn<ClearPolicyAction> =
    (s, _a) => (
        s.setProp(
            "policyRequest",
            State.create<PolicyRequest>(() => ({})),
        )
    );

/**
 *
 */
export type UpdateModelOnBrandChangeAction = Readonly<
Action<ActionTypeKeys.UPDATE_MODEL_ON_BRAND_CHANGE> & {
}
>;

/**
 *
 *
 */
export const updateModelOnBrandChange =
    (): UpdateModelOnBrandChangeAction => ({
        type: ActionTypeKeys.UPDATE_MODEL_ON_BRAND_CHANGE,
    });

/**
 *
 * @param s
 * @param _a
 */
export const updateModelOnBrandChangeReducer:
ReducerFn<UpdateModelOnBrandChangeAction> =
    (s, _a) => {
        const selectedModelName = s.prop("policyRequest").map(e => ({
            ...e,
        })).model;
        const modelsPerSelectedBrand = s.prop("remote").prop(RemoteScope.MODEL_PER_BRAND_RESULTS)?.content.find(model =>
            model.name === selectedModelName);
        return s.setProp(
            "policyRequest",
            s.prop("policyRequest").updateWith({
                model: modelsPerSelectedBrand?.name ?? "",
            }),
        );
    };
