/* eslint-disable max-len */
import { RemoteErrorType, RemoteScope } from "../../store/RemoteTypes";
import { RemoteErrors } from "../../store/ReduxState";
import { Map } from "immutable";

// https://github.platform.vwfs.io/levi9-nl/vwpfs-ewservice/blob/develop/src/main/java/com/vwpfs/ew/commons/exceptions/ErrorCode.java

export enum RemoteErrorsCodes {
    EW_NOT_FOUND = "EW_NOT_FOUND",
    POLICY_NOT_FOUND = "POLICY_NOT_FOUND",
    POLICY_HAS_CLAIMS = "POLICY_HAS_CLAIMS",
    POLICY_ALREADY_CANCELED = "POLICY_ALREADY_CANCELED",
    TARIFF_LINE_NOT_FOUND = "TARIFF_LINE_NOT_FOUND",
    IBAN_IS_MANDATORY = "IBAN_IS_MANDATORY",
    BIC_IS_MANDATORY = "BIC_IS_MANDATORY",
    PARTNER_NOT_FOUND = "PARTNER_NOT_FOUND",
    INVOICE_NOT_FOUND = "INVOICE_NOT_FOUND",
    STATEMENT_NOT_FOUND = "STATEMENT_NOT_FOUND",
    CLAIM_NOT_FOUND = "CLAIM_NOT_FOUND",
    CLAIM_MSG_ELEMENT_NOT_FOUND = "CLAIM_MSG_ELEMENT_NOT_FOUND",
    BRAND_NOT_FOUND = "BRAND_NOT_FOUND",
    MODEL_NOT_FOUND = "MODEL_NOT_FOUND",
    GL_NOT_FOUND = "GL_NOT_FOUND",
    GL_ENTRY_NOT_FOUND = "GL_ENTRY_NOT_FOUND",
    JOURNAL_EVENT_ENTRY_NOT_FOUND = "JOURNAL_EVENT_ENTRY_NOT_FOUND",
    INCASSO_NOT_FOUND = "INCASSO_NOT_FOUND",
    EXCASSO_NOT_FOUND = "EXCASSO_NOT_FOUND",
    PROXY_NOT_FOUND = "PROXY_NOT_FOUND",
    JOURNAL_EVENT_NOT_FOUND = "JOURNAL_EVENT_NOT_FOUND",
    STATEMENT_ENTRY_NOT_FOUND = "STATEMENT_ENTRY_NOT_FOUND",
    PYMENT_NOT_FOUND = "PYMENT_NOT_FOUND",
    MANDATE_NOT_FOUND = "MANDATE_NOT_FOUND",
    MESSAGE_NOT_FOUND = "MESSAGE_NOT_FOUND",
    UNKNOWN_FILE_TYPE = "UNKNOWN_FILE_TYPE",
    FILE_PROCESSING_ERROR = "FILE_PROCESSING_ERROR",
    EVENT_PROCESSING_ERROR = "EVENT_PROCESSING_ERROR",
    FILE_GENERATING_ERROR = "FILE_GENERATING_ERROR",
    FILE_NOT_FOUND = "FILE_NOT_FOUND",
    WRONG_INSURER_ID = "WRONG_INSURER_ID",
    ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
    MODEL_OR_BRAND = "MODEL_OR_BRAND",
    VALIDATION_CONSTRAINT_VIOLATION = "VALIDATION_CONSTRAINT_VIOLATION",
    OPERATION_NOT_PERMITTED = "OPERATION_NOT_PERMITTED",
    BUSINESS_RULE_VIOLATION = "BUSINESS_RULE_VIOLATION",
    NO_INVOICES_FOUND = "NO_INVOICES_FOUND",
    NO_INVOICES_SELECTED = "NO_INVOICES_SELECTED",
    INVOICE_ALREADY_PAID = "INVOICE_ALREADY_PAID",
    SELECTED_IDS_NOT_EXIST = "SELECTED_IDS_NOT_EXIST",
    REQ_COLL_DATE_MANDATORY = "REQ_COLL_DATE_MANDATORY",
    MESSAGE_PROCESSING_ERROR = "MESSAGE_PROCESSING_ERROR",
    MESSAGE_NAME_EXISTS = "MESSAGE_NAME_EXISTS",
    MESSAGE_NAME_MANDATORY = "MESSAGE_NAME_MANDATORY",
    DIFFERENT_AMOUNTS = "DIFFERENT_AMOUNTS",
    INVALID_AMOUNT = "INVALID_AMOUNT",
    MILEAGE_IS_REQUIRED = "MILEAGE_IS_REQUIRED",
    VEHICLE_TYPE_IS_REQUIRED = "VEHICLE_TYPE_IS_REQUIRED",
    DURATION_IS_REQUIRED = "DURATION_IS_REQUIRED",
    SAME_PRODUCT_ALREADY_CREATED = "SAME_PRODUCT_ALREADY_CREATED",
    PRODUCT_IS_ACTIVE = "PRODUCT_IS_ACTIVE",
    ENTRY_IS_ALREADY_BOOKED = "ENTRY_IS_ALREADY_BOOKED",
    PAYMENT_ALREADY_UNBOOKED = "PAYMENT_ALREADY_UNBOOKED",
    BOOKING_ON_CANCELED_INVOICE = "BOOKING_ON_CANCELED_INVOICE",
    UNBOOKING_FAILED = "UNBOOKING_FAILED",
    WRONG_CREDIT_DEBIT_INDICATOR = "WRONG_CREDIT_DEBIT_INDICATOR",
    WRONG_STATUS = "WRONG_STATUS",
    PARTIALLY_PAID_RESTRICTION = "PARTIALLY_PAID_RESTRICTION",
    UNKNOWN_BOOKED_OBJECT_TYPE = "UNKNOWN_BOOKED_OBJECT_TYPE",
    STATEMENT_ALREADY_EXISTS = "STATEMENT_ALREADY_EXISTS",
    FILE_ALREADY_EXISTS = "FILE_ALREADY_EXISTS",
    INVOICE_MUST_BE_UNPAID_OR_REVERSED = "INVOICE_MUST_BE_UNPAID_OR_REVERSED",
    UNSTRUCTURED_NOT_PROVIDED = "UNSTRUCTURED_NOT_PROVIDED",
    STATEMENT_IN_PROCESSING_STATUS = "STATEMENT_IN_PROCESSING_STATUS",
    FREEBOOKING_NOT_FOUND = "FREEBOOKING_NOT_FOUND",
    BOOKING_PERIOD_NOT_FOUND = "BOOKING_PERIOD_NOT_FOUND",
    BOOKING_PERIOD_ALREADY_EXISTS = "BOOKING_PERIOD_ALREADY_EXISTS",
    CLOSED_BOOKING_PERIOD = "CLOSED_BOOKING_PERIOD",
    NOT_EXISTING_BOOKING_PERIOD = "NOT_EXISTING_BOOKING_PERIOD",
    COLLECTIVE_INVOICE_ALREADY_CREATED = "COLLECTIVE_INVOICE_ALREADY_CREATED",
    PASSENGER_OR_COMMERCIAL = "PASSENGER_OR_COMMERCIAL",
    VEHICLE_TYPE_MANDATORY = "VEHICLE_TYPE_MANDATORY",
    UNKNOWN_FREEBOOKING_ITEM_TYPE = "UNKNOWN_FREEBOOKING_ITEM_TYPE",
    BRAND_IS_MANDATORY = "BRAND_IS_MANDATORY",
    POLICY_BRAND_NOT_IN_EW = "POLICY_BRAND_NOT_IN_EW",
    POLICY_ALREADY_EXISTS = "POLICY_ALREADY_EXISTS",
    EW_NOT_ACTIVE = "EW_NOT_ACTIVE",
    EW_HAS_NO_TARIFF_LINES = "EW_HAS_NO_TARIFF_LINES",
    POLICY_PREMIUM_NOT_FOUND = "POLICY_PREMIUM_NOT_FOUND",
    PRODUCT_TARIFF_NOT_FOUND = "PRODUCT_TARIFF_NOT_FOUND",
    WRONG_ENDING_DATE = "WRONG_ENDING_DATE",
    MEMO_NOT_FOUND = "MEMO_NOT_FOUND",
    INCORRECT_PARTNER_TYPE = "INCORRECT_PARTNER_TYPE",
    CLAIM_HOLDER_PARTNER_ALREADY_EXISTS = "CLAIM_HOLDER_PARTNER_ALREADY_EXISTS",
    BOOKING_NOT_ALLOWED = "BOOKING_NOT_ALLOWED",
    GL_REQUIRED = "GL_REQUIRED",
    BOOKING_DATE_IS_MANDATORY = "BOOKING_DATE_IS_MANDATORY",
    BOOKING_ON_STATEMENT_ACC_GL = "BOOKING_ON_STATEMENT_ACC_GL",
    RUNTIME_EXCEPTION_OCCURRED = "RUNTIME_EXCEPTION_OCCURRED",
    UNHANDLED_EXCEPTION_OCCURRED = "UNHANDLED_EXCEPTION_OCCURRED",
    RTE = "RTE",
}

export const getErrorValidationMessage =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): RemoteErrors | undefined => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let errMessage;
        if (errorEntry) {
            const [scope, reason] = errorEntry;
            errMessage = scope === rc ? reason : undefined;
        }
        return errMessage;
    };

/**
 *
 */
export const ErrorTypeDisplay: Readonly<Record<RemoteErrorsCodes, string>> = {
    [RemoteErrorsCodes.EW_NOT_FOUND]: "No product found.",
    [RemoteErrorsCodes.POLICY_NOT_FOUND]: "No policy found.",
    [RemoteErrorsCodes.POLICY_HAS_CLAIMS]: "A policy that has claims cannot be canceled.",
    [RemoteErrorsCodes.POLICY_ALREADY_CANCELED]: "Policy has a canceled status already.",
    [RemoteErrorsCodes.TARIFF_LINE_NOT_FOUND]: "No tariff line found.",
    [RemoteErrorsCodes.IBAN_IS_MANDATORY]: "IBAN is mandatory.",
    [RemoteErrorsCodes.BIC_IS_MANDATORY]: "BIC is mandatory.",
    [RemoteErrorsCodes.PARTNER_NOT_FOUND]: "No partner found.",
    [RemoteErrorsCodes.INVOICE_NOT_FOUND]: "No invoice found.",
    [RemoteErrorsCodes.STATEMENT_NOT_FOUND]: "No statement found.",
    [RemoteErrorsCodes.CLAIM_NOT_FOUND]: "No claim found.",
    [RemoteErrorsCodes.CLAIM_MSG_ELEMENT_NOT_FOUND]: "Claim message element not found.",
    [RemoteErrorsCodes.BRAND_NOT_FOUND]: "No brand found.",
    [RemoteErrorsCodes.MODEL_NOT_FOUND]: "No model found.",
    [RemoteErrorsCodes.GL_NOT_FOUND]: "No general ledger found.",
    [RemoteErrorsCodes.GL_ENTRY_NOT_FOUND]: "No general ledger entry found.",
    [RemoteErrorsCodes.JOURNAL_EVENT_ENTRY_NOT_FOUND]: "No journal event entry found.",
    [RemoteErrorsCodes.INCASSO_NOT_FOUND]: "No incasso found.",
    [RemoteErrorsCodes.EXCASSO_NOT_FOUND]: "No excasso found.",
    [RemoteErrorsCodes.PROXY_NOT_FOUND]: "No proxy found.",
    [RemoteErrorsCodes.JOURNAL_EVENT_NOT_FOUND]: "No journal event found.",
    [RemoteErrorsCodes.STATEMENT_ENTRY_NOT_FOUND]: "No statement entry found.",
    [RemoteErrorsCodes.PYMENT_NOT_FOUND]: "No payment found.",
    [RemoteErrorsCodes.MANDATE_NOT_FOUND]: "No mandate found.",
    [RemoteErrorsCodes.MESSAGE_NOT_FOUND]: "No message found.",
    [RemoteErrorsCodes.UNKNOWN_FILE_TYPE]: "File type is unknown.",
    [RemoteErrorsCodes.FILE_PROCESSING_ERROR]: "The file could not be processed.",
    [RemoteErrorsCodes.EVENT_PROCESSING_ERROR]: "The event could not be processed.",
    [RemoteErrorsCodes.FILE_GENERATING_ERROR]: "The file could not be generated.",
    [RemoteErrorsCodes.FILE_NOT_FOUND]: "No file found.",
    [RemoteErrorsCodes.WRONG_INSURER_ID]: "The selected insurer does not exist.",
    [RemoteErrorsCodes.ACCOUNT_NOT_FOUND]: "No account found.",
    [RemoteErrorsCodes.MODEL_OR_BRAND]: "Choose either brand or modal.",
    [RemoteErrorsCodes.VALIDATION_CONSTRAINT_VIOLATION]: "There were violations of some validation constraints.",
    [RemoteErrorsCodes.OPERATION_NOT_PERMITTED]: "Operation not permitted.",
    [RemoteErrorsCodes.BUSINESS_RULE_VIOLATION]: "Business rule violated.",
    [RemoteErrorsCodes.NO_INVOICES_FOUND]: "No open invoices found",
    [RemoteErrorsCodes.NO_INVOICES_SELECTED]: "No invoices were selected.",
    [RemoteErrorsCodes.INVOICE_ALREADY_PAID]: "Ths selected invoice was already paid.",
    [RemoteErrorsCodes.SELECTED_IDS_NOT_EXIST]: "The selected ids do not exist.",
    [RemoteErrorsCodes.REQ_COLL_DATE_MANDATORY]: "Request collection date is a mandatory field.",
    [RemoteErrorsCodes.MESSAGE_PROCESSING_ERROR]: "The message could not be processed.",
    [RemoteErrorsCodes.MESSAGE_NAME_EXISTS]: "The message name should be unique.",
    [RemoteErrorsCodes.MESSAGE_NAME_MANDATORY]: "The messaege name field is mandatory.",
    [RemoteErrorsCodes.DIFFERENT_AMOUNTS]: "You cannot book an excasso/incasso with a different amount than the statement line.",
    [RemoteErrorsCodes.INVALID_AMOUNT]: "The amount that you are trying to book with is invalid.",
    [RemoteErrorsCodes.MILEAGE_IS_REQUIRED]: "Kilometeres (mileage) is a mandatory field.",
    [RemoteErrorsCodes.VEHICLE_TYPE_IS_REQUIRED]: "Vehicle type is a mandatory field.",
    [RemoteErrorsCodes.DURATION_IS_REQUIRED]: "Duration is a mandatory field.",
    [RemoteErrorsCodes.SAME_PRODUCT_ALREADY_CREATED]: "A product with same details already exists.",
    [RemoteErrorsCodes.PRODUCT_IS_ACTIVE]: "The product is already in active state.",
    [RemoteErrorsCodes.ENTRY_IS_ALREADY_BOOKED]: "The selected entry is already booked.",
    [RemoteErrorsCodes.PAYMENT_ALREADY_UNBOOKED]: "The selected payment is already unbooked.",
    [RemoteErrorsCodes.BOOKING_ON_CANCELED_INVOICE]: "You cannot book with a canceled invoice.",
    [RemoteErrorsCodes.UNBOOKING_FAILED]: "The unbooking was not successful.",
    [RemoteErrorsCodes.WRONG_CREDIT_DEBIT_INDICATOR]: "The indicated credit/debit is incorrect.",
    [RemoteErrorsCodes.WRONG_STATUS]: "The status is incorrect.",
    [RemoteErrorsCodes.PARTIALLY_PAID_RESTRICTION]: "Partially paid restriction.",
    [RemoteErrorsCodes.UNKNOWN_BOOKED_OBJECT_TYPE]: "The booking object type is unknown.",
    [RemoteErrorsCodes.STATEMENT_ALREADY_EXISTS]: "The uploaded statement exists already",
    [RemoteErrorsCodes.FILE_ALREADY_EXISTS]: "The uploaded file exists already",
    [RemoteErrorsCodes.INVOICE_MUST_BE_UNPAID_OR_REVERSED]: "Invoice must be unpaid or reversed.",
    [RemoteErrorsCodes.UNSTRUCTURED_NOT_PROVIDED]: "Unstructured remittances not provided.",
    [RemoteErrorsCodes.STATEMENT_IN_PROCESSING_STATUS]: "Statement is still processing",
    [RemoteErrorsCodes.FREEBOOKING_NOT_FOUND]: "Free booking not found",
    [RemoteErrorsCodes.BOOKING_PERIOD_NOT_FOUND]: "This booking period does not exist",
    [RemoteErrorsCodes.BOOKING_PERIOD_ALREADY_EXISTS]: "This booking period already exists",
    [RemoteErrorsCodes.CLOSED_BOOKING_PERIOD]: "This booking period is closed",
    [RemoteErrorsCodes.NOT_EXISTING_BOOKING_PERIOD]: "This booking period does not exist",
    [RemoteErrorsCodes.COLLECTIVE_INVOICE_ALREADY_CREATED]: "This collective invoice is already created",
    [RemoteErrorsCodes.PASSENGER_OR_COMMERCIAL]: "Please select either passenger or commerical.",
    [RemoteErrorsCodes.VEHICLE_TYPE_MANDATORY]: "The vehicle type is a mandatory field.",
    [RemoteErrorsCodes.UNKNOWN_FREEBOOKING_ITEM_TYPE]: "The item that you are trying to book with is unknown.",
    [RemoteErrorsCodes.BRAND_IS_MANDATORY]: "The brand is a mandatory field.",
    [RemoteErrorsCodes.POLICY_BRAND_NOT_IN_EW]: "The policy brand does not match with the EW brand.",
    [RemoteErrorsCodes.POLICY_ALREADY_EXISTS]: "The policy with same details already exists.",
    [RemoteErrorsCodes.EW_NOT_ACTIVE]: "The product is not in active state.",
    [RemoteErrorsCodes.EW_HAS_NO_TARIFF_LINES]: "The product has no tariff lines",
    [RemoteErrorsCodes.POLICY_PREMIUM_NOT_FOUND]: "This policy premium does not exist",
    [RemoteErrorsCodes.PRODUCT_TARIFF_NOT_FOUND]: "This product tariff does not exist",
    [RemoteErrorsCodes.WRONG_ENDING_DATE]: "The ending date is incorrect",
    [RemoteErrorsCodes.MEMO_NOT_FOUND]: "This memo does not exist",
    [RemoteErrorsCodes.INCORRECT_PARTNER_TYPE]: "This partner type is not valid",
    [RemoteErrorsCodes.CLAIM_HOLDER_PARTNER_ALREADY_EXISTS]: "This claim holder already exists",
    [RemoteErrorsCodes.BOOKING_NOT_ALLOWED]: "Free booking on this object is not allowed",
    [RemoteErrorsCodes.GL_REQUIRED]: "General ledger is required",
    [RemoteErrorsCodes.BOOKING_DATE_IS_MANDATORY]: "Booking date is a mandatory field",
    [RemoteErrorsCodes.BOOKING_ON_STATEMENT_ACC_GL]: "The account has same general ledger against which booking was intiated",
    [RemoteErrorsCodes.RUNTIME_EXCEPTION_OCCURRED]: "System error.",
    [RemoteErrorsCodes.UNHANDLED_EXCEPTION_OCCURRED]: "System error.",
    [RemoteErrorsCodes.RTE]: "System error.",
};
