import * as React from "react";
import { connect } from "react-redux";
import { Pages, PageTabs } from "../../../../../store/AppDisplays";
import { ClaimInvoiceStatusDisplay } from "../../../../../store/ClaimMatch/Displays";
import { ClaimInvoiceStatus } from "../../../../../store/ClaimMatch/Types";
import { InvoiceTypeDisplay } from "../../../../../store/Invoice/Displays";
import { InvoiceFilters, InvoiceType } from "../../../../../store/Invoice/Types";
import { clearInvoicePartnerFilter } from "../../../../../store/Partners/FilterType";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { CreditDebitIndicatorTypeDisplay } from "../../../../../store/Statements/Displays";
import { CreditDebitIndicator } from "../../../../../store/Statements/Types";
import { validFromValidation, validTillValidation } from "../../../../../utils/FormValidations";

import { DispatchFunc } from "../../../../../store/ActionTypes";
import { clearFilters, updateFilters } from "../../../../../store/FilterActions";
import {
    FilterScope,
    getFilters,
    hasSearchableFilters,
} from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { FilterInvoicePartnerModal } from "../Partners/FilterInvoicePartnerModal";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { ThemeSize, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
type setFilterFn = (filters: Partial<InvoiceFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof InvoiceFilters>) => void;
type OnChange =
    <K extends keyof InvoiceFilters>(key: K, value: InvoiceFilters[K]) => void;

interface State {
    init: boolean;
}

/**
 *
 */
interface OwnProps {
    loadingResults: boolean;
    defaultFilters?: InvoiceFilters;
    currentTab?: PageTabs | Pages;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: InvoiceFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
    selectPartner: (modalId: string, onChange: OnChange, id?: string, name?: string)
    => void;
    clearPartnerFilter: () => void;
}

interface State {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.INVOICES, p.defaultFilters),
    filters: getFilters(s, FilterScope.INVOICES) as InvoiceFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.INVOICES)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.INVOICES));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.INVOICE_RESULTS, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.INVOICE_RESULTS)),
        dispatch(remoteClearError(RemoteScope.INVOICE_RESULTS))
    ),
    selectPartner:
            (modalId: string, onChange: OnChange, id?: string, name?: string) => {
                dispatch(
                    modalShow(
                        modalId,
                        () => (
                            <Modal
                                modalID={modalId}
                                theme={{ size: ThemeSize.HUGE }}
                            >
                                <FilterInvoicePartnerModal
                                    modalID={modalId}
                                    partnerNumber={id}
                                    onChange={onChange}
                                    partnerName={name}
                                />
                            </Modal>
                        ),
                    ),
                );
            },
    clearPartnerFilter: () => dispatch(clearInvoicePartnerFilter()),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.state = { init: true };

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.invoiceNumberValidation = this.invoiceNumberValidation.bind(this);
        this.mutationTypeValidation = this.mutationTypeValidation.bind(this);
        this.fromDateValidation = this.fromDateValidation.bind(this);
        this.toDateValidation = this.toDateValidation.bind(this);
        this.collectiveInvoiceNumberValidation = this.collectiveInvoiceNumberValidation.bind(this);
        this.invoiceStatusValidation = this.invoiceStatusValidation.bind(this);
        this.partnerIdValidation = this.partnerIdValidation.bind(this);
        this.invoiceTypeValidation = this.invoiceTypeValidation.bind(this);
        this.onChangeInvoiceNumber = this.onChangeInvoiceNumber.bind(this);
        this.onChangeMutationType = this.onChangeMutationType.bind(this);
        this.onChangeFromDate = this.onChangeFromDate.bind(this);
        this.onChangeToDate = this.onChangeToDate.bind(this);
        this.onChangeCollectiveInvoiceNumber = this.onChangeCollectiveInvoiceNumber.bind(this);
        this.onChangeInvoiceStatus = this.onChangeInvoiceStatus.bind(this);
        this.onChangeInvoiceType = this.onChangeInvoiceType.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Invoice number"
                            onChange={this.onChangeInvoiceNumber}
                            value={this.props.filters?.invoiceNumber ?? ""}
                            type="text"
                            validationFunction={this.invoiceNumberValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Mutation type"
                            onChange={this.onChangeMutationType}
                            value={
                                this.props.filters?.creditDebitIndicator !== undefined ?
                                    this.props.filters.creditDebitIndicator :
                                    this.props.defaultFilters?.creditDebitIndicator
                                        ? this.props.defaultFilters.creditDebitIndicator : undefined}
                            placeholder={
                                this.props.defaultFilters?.creditDebitIndicator ? undefined
                                    : "Make a choice"}
                            options={Object.keys(CreditDebitIndicator).map((p) => ({
                                label: CreditDebitIndicatorTypeDisplay[p] as string,
                                value: p,
                            }))}
                            validationFunction={this.mutationTypeValidation}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="From date"
                            onChange={this.onChangeFromDate}
                            type="date"
                            value={this.props.filters.creationDateFrom ?? ""}
                            validationFunction={this.fromDateValidation}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="To date"
                            onChange={this.onChangeToDate}
                            type="date"
                            value={this.props.filters.creationDateTo ?? ""}
                            validationFunction={this.toDateValidation}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    {(this.props.currentTab !== PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS) &&
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Collective invoice number"
                            onChange={this.onChangeCollectiveInvoiceNumber}
                            value={this.props.filters.collectiveInvoiceNumber ?? ""}
                            type="text"
                            validationFunction={this.collectiveInvoiceNumberValidation}
                        />
                    </Grid>
                    }
                    {(this.props.currentTab !== PageTabs.MANUAL_BOOKING_CREATE_BOOK_INVOICES
                    && this.props.currentTab !== PageTabs.MANUAL_BOOKING_CREATE_BOOK_CLAIMS
                    && this.props.currentTab !== PageTabs.MANUAL_BOOKING_CREATE_BOOK_PARTNERINVOICES) &&
                    this.state.init === false &&
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Invoice status"
                            onChange={this.onChangeInvoiceStatus}
                            values={
                                (this.props.defaultFilters?.invoiceStatus
                                ?? this.props.filters?.invoiceStatus)?.split(",").map((v) => ({
                                    label: ClaimInvoiceStatusDisplay[v] as string,
                                    value: v,
                                }))}
                            placeholder="Make a selection"
                            options={Object.keys(ClaimInvoiceStatus).map((p) => ({
                                label: ClaimInvoiceStatusDisplay[p] as string,
                                value: p,
                            }))}
                            multiple
                            validationFunction={this.invoiceStatusValidation}
                        />
                    </Grid>}
                </Div>
                {this.props.currentTab === PageTabs.PARTNER_INVOICE &&
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormField
                            label="Invoice type"
                            onChange={this.onChangeInvoiceType}
                            value={
                                this.props.defaultFilters?.invoiceType
                                    ? this.props.defaultFilters.invoiceType :
                                    this.props.filters && this.props.filters.invoiceType}
                            placeholder="Make a choice"
                            options={Object.keys(InvoiceType).map((p) => ({
                                label: InvoiceTypeDisplay[p] as string,
                                value: p,
                            }))}
                            validationFunction={this.invoiceTypeValidation}
                        />
                    </Grid>
                </Div>}
                {this.props.currentTab === Pages.INDIVIDUALINVOICES &&
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <div
                            style={{
                                position: "relative",
                            }}
                        >
                            <FormField
                                label="Partner"
                                placeholder={"Make a choice"}
                                readonly
                                validationFunction={this.partnerIdValidation}
                                value={this.props.filters.partnerName}
                            />
                            {this.props.filters.partnerId ?
                                <div
                                    style={{
                                        position: "absolute",
                                        right: "50px",
                                        top: "50%",
                                        transform: "translateY(-50%) scale(0.45)",
                                    }}
                                >
                                    <Button
                                        style={{fontSize: "1.5em"}}
                                        icon={IconNames.TIMES}
                                        theme={{
                                            palette: ThemePalette.BRAND_ACCENT,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                        link={{ onClick: this.props.clearPartnerFilter }}
                                    />
                                </div> : <></>
                            }
                            <div
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                }}
                            >
                                <Button
                                    icon={this.props.filters.partnerId ? IconNames.EDIT : IconNames.PLUS}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    link={{
                                        onClick: () =>
                                            this.props.selectPartner(
                                                "select-partner--modal",
                                                this.onChange,
                                                this.props.filters.partnerId?.toString(),
                                                this.props.filters.partnerName,
                                            ),
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Div>}
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private partnerIdValidation(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Partner provided",
        );
    }

    private invoiceNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Invoice number provided");
    }

    private onChangeInvoiceNumber(value?: string | number) {
        return this.onChange("invoiceNumber", value ? value.toString() : "");
    }

    private mutationTypeValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Mutation type provided");
    }

    private onChangeMutationType(value?: string | number) {
        return this.onChange("creditDebitIndicator", value
            ? value.toString() as CreditDebitIndicator
            : undefined);
    }

    private fromDateValidation(value: string | number) {
        return validFromValidation(value.toString(),
            this.props.filters.creationDateTo
                ? this.props.filters.creationDateTo : undefined);
    }

    private onChangeFromDate(value?: string | number) {
        return this.onChange("creationDateFrom", value?.toString() ?? "");
    }

    private toDateValidation(value: string | number) {
        return validTillValidation(value.toString(),
            this.props.filters.creationDateFrom
                ? this.props.filters.creationDateFrom : undefined);
    }

    private onChangeToDate(value?: string | number) {
        return this.onChange("creationDateTo", value?.toString() ?? "");
    }

    private collectiveInvoiceNumberValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Collective invoice number provided");
    }

    private onChangeCollectiveInvoiceNumber(value?: string | number) {
        return this.onChange("collectiveInvoiceNumber", value ? Number(value) : undefined);
    }

    private invoiceStatusValidation(value: string | number) {
        return hasValueValidation(value.toString(),
            "Invoice status provided");
    }

    private onChangeInvoiceStatus(value?: string | number) {
        return this.onChange("invoiceStatus", value
            ? value.toString() : undefined);
    }

    private invoiceTypeValidation(value: string | number) {
        return hasValueValidation(
            value.toString(),
            "Invoice type provided",
        );
    }

    private onChangeInvoiceType(value?: string | number) {
        return this.onChange("invoiceType", value ? value.toString() as InvoiceType : undefined);
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
        this.setState({
            init: true,
        }, () => this.setState({ init: false }));
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof InvoiceFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof InvoiceFilters>(key: K, value: InvoiceFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
