import { ManualBookingTypes } from "./Types";

/**
 *
 */
export const ManualBookingTypeDisplay: Readonly<Record<ManualBookingTypes, string>> = {
    [ManualBookingTypes.CLAIM_INVOICE]: "Claim Invoice",
    [ManualBookingTypes.COLLECTIVE_INVOICE]: "Collective Invoice",
    [ManualBookingTypes.GENERAL_LEDGER]: "General Ledger",
    [ManualBookingTypes.GENERAL_LEDGER_INVOICE]: "General Ledger Invoice",
    [ManualBookingTypes.POLICY_INVOICE]: "Indiv. Invoice",
    [ManualBookingTypes.PARTNER]: "Partner",
    [ManualBookingTypes.PARTNER_INVOICE]: "Partner Invoice",
};
