import * as React from "react";
import { connect } from "react-redux";
import { BookingPeriodMonthsDisplay } from "../../../../../store/BookingPeriod/Displays";
import { BookingPeriodFilters, BookingPeriodMonths } from "../../../../../store/BookingPeriod/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { updateFilters, clearFilters } from "../../../../../store/FilterActions";
import { hasSearchableFilters, FilterScope, getFilters } from "../../../../../store/FilterTypes";
import { ReduxState } from "../../../../../store/ReduxState";
import { ThemePalette } from "../../../../../theme/_Types";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Button } from "../../../../../components/core/05-atoms/Button";

/**
 *
 */
type setFilterFn = (filters: Partial<BookingPeriodFilters>) => void;
type clearFiltersFn = (filters?: Array<keyof BookingPeriodFilters>) => void;

/**
 *
 */
interface OwnProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    defaultFilters?: BookingPeriodFilters;
}

/**
 *
 */
interface StateProps {
    searchEnabled: boolean;
    filters: BookingPeriodFilters;
}

/**
 *
 */
interface DispatchProps {
    setFilter: setFilterFn;
    clearFilters: clearFiltersFn;
    loadResults: () => void;
    clearResults: () => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState, p: OwnProps): StateProps => ({
    searchEnabled: hasSearchableFilters(s, FilterScope.BOOKING_PERIOD, p.defaultFilters),
    filters: getFilters(s, FilterScope.BOOKING_PERIOD) as BookingPeriodFilters,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFilter: (filters) => dispatch(updateFilters(filters, FilterScope.BOOKING_PERIOD)),
    clearFilters: (filters) => {
        dispatch(clearFilters(filters, FilterScope.BOOKING_PERIOD));
    },
    loadResults: () => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD, { skip: 0 })),
    clearResults: () => (
        dispatch(remoteClearResponse(RemoteScope.BOOKING_PERIOD)),
        dispatch(remoteClearError(RemoteScope.BOOKING_PERIOD))
    ),
});

/**
 *
 * @param props
 */
export class FilterComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSearchFilter = this.onSearchFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.initFilters = this.initFilters.bind(this);
        this.monthValidationMessage = this.monthValidationMessage.bind(this);
        this.yearValidationMessage = this.yearValidationMessage.bind(this);
        this.onChangeYear = this.onChangeYear.bind(this);
        this.onChangeMonth = this.onChangeMonth.bind(this);
    }

    public componentDidMount() {
        this.initFilters();
    }

    public componentWillUnmount() {
        this.props.clearResults();
    }

    public render() {
        return (
            <Form onSubmit={this.onSearchFilter}>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            label="Year"
                            onChange={this.onChangeYear}
                            value={this.props.filters.year ?? ""}
                            validationFunction={this.yearValidationMessage}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            onChange={this.onChangeMonth}
                            value={
                                this.props.filters &&
                                this.props.filters.month}
                            label="Month"
                            placeholder="Make a choice"
                            options={Object.keys(BookingPeriodMonths).map((m, index) => ({
                                label: BookingPeriodMonthsDisplay[m] as string | undefined,
                                value: index + 1,
                            }))}
                            validationFunction={this.monthValidationMessage}
                        />
                    </Grid>
                </Div>
                <ButtonsWrapper alignment={Alignment.CENTER} defaultOrientation={Orientation.HORIZONTAL}>
                    <Button
                        className="scl-a-btn--big scl-a-btn scl-a-btn--brand-link"
                        link={{ onClick: this.initFilters }}
                        disabled={!this.props.searchEnabled || this.props.loadingResults}
                    >
                        Clear filters
                    </Button>
                    {" "}
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        className="scl-a-btn--big"
                        buttonType="submit"
                        disabled={this.props.loadingResults}
                    >
                        Search
                    </Button>
                </ButtonsWrapper>
            </Form>
        );
    }

    private monthValidationMessage(value: string | number) {
        return hasValueValidation(value.toString(), "Month provided");
    }

    private yearValidationMessage(value: string | number) {
        return hasValueValidation(value.toString(), "Year provided");
    }

    private onChangeYear(value?: string | number) {
        return this.onChange(
            "year",
            value ? Number(value) : undefined,
        );
    }

    private onChangeMonth(value?: string | number) {
        return this.onChange(
            "month",
            value ? Number(value) : undefined,
        );
    }

    private initFilters() {
        this.clearFilters();
        if (this.props.defaultFilters) {
            this.props.setFilter(this.props.defaultFilters);
        }
        this.props.loadResults();
    }

    private clearFilters = () => {
        const filters =
            (Object.keys(this.props.filters) as Array<keyof BookingPeriodFilters>);

        this.props.clearFilters(filters);
    };

    private onChange = <K extends keyof BookingPeriodFilters>(key: K, value: BookingPeriodFilters[K]) => {
        this.props.setFilter({
            [key]: value,
        });
    };

    private onSearchFilter = () => {
        this.props.loadResults();
    };
}

/**
 *
 */
export const Filter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilterComp);
