import * as React from "react";
import { connect } from "react-redux";

import { clearMandate, updateMandate } from "../../../../../store/Mandates/Create";
import { MandateSequenceTypeDisplay } from "../../../../../store/Mandates/Displays";
import { MandateRequest, SequenceType, canSubmitMandateForm } from "../../../../../store/Mandates/Types";
import { ReduxState, isRemoteLoading } from "../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../store/RemoteTypes";


import { DispatchFunc } from "../../../../../store/ActionTypes";
import { remoteClearResponse, remoteTrigger } from "../../../../../store/RemoteActions";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Container } from "../../../../../components/core/03-base/Container";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { hasValueValidation } from "../../../../../components/core/07-organisms/Form/Field.utils";
import { Required } from "../../../../../components/core/05-atoms/Required";
import { Div } from "../../../../../components/core/03-base/Div";
import { FlexColumn } from "../../../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../../../components/core/03-base/Flex/Row";
import { Grid } from "../../../../../components/core/03-base/Grid";
import { FillUp } from "../../../../../components/core/04-helpers/FillUp";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { LoadingIndications, LoadingIndicator } from "../../../../../components/core/05-atoms/LoadingIndicator";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { Navbar } from "../../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent,
    NavbarStackRight } from "../../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../../components/core/07-organisms/Navbar/Title";
import { ThemeBreakpoints, ThemePalette } from "../../../../../theme/_Types";
import { modalClose } from "../../../../../utils/redux/ActionTypes";

/**
 *
 */
interface OwnProps {
    modalID: string;
    edit?: boolean;
    mandateId?: string;
}

interface StateProps {
    mandate: MandateRequest;
    canSubmitMandateForm: boolean;
    loadingMandateDetails: boolean;
}

/**
 *
 */
interface DispatchProps {
    closeModal: (id: string) => void;
    createMandate: (mandate: MandateRequest) => void;
    editMandate: (modalID: string, mandate: MandateRequest) => void;
    onChange: (mandate: MandateRequest) => void;
    clearMandate: () => void;
    loadMandateDetail: (id: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingMandateDetails: isRemoteLoading(s, RemoteScope.MANDATE_DETAILS),
    canSubmitMandateForm: canSubmitMandateForm(s.prop("mandateRequest")),
    mandate: s.prop("mandateRequest").map(e => ({
        ...e,
    })),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
    createMandate: (mandate: MandateRequest) => dispatch(remoteTrigger(RemoteScope.MANDATE_CREATE, {
        mandate: mandate,
    })),
    editMandate: (modalID: string, mandate: MandateRequest) => dispatch(remoteTrigger(RemoteScope.MANDATE_EDIT, {
        mandate: mandate,
        modalID: modalID,
    })),
    onChange: (mandate: MandateRequest) => dispatch(updateMandate(mandate)),
    clearMandate: () => {
        dispatch(clearMandate());
        dispatch(remoteClearResponse(RemoteScope.MANDATE_CREATE));
        dispatch(remoteClearResponse(RemoteScope.MANDATE_EDIT));
    },
    loadMandateDetail: (id: string) => dispatch(remoteTrigger(RemoteScope.MANDATE_DETAILS, {
        id: id.toString(),
    })),
});

/**
 *
 */
export class UpdateMandateComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        // this.state = { ready: false, loading: false };
        // this.generate = this.generate.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.createMandate = this.createMandate.bind(this);
        this.editMandate = this.editMandate.bind(this);
    }

    public componentDidMount() {
        this.props.clearMandate();
        if (this.props.edit) {
            this.props.loadMandateDetail(this.props.mandateId || "");
        }
    }

    public componentWillUnmount() {
        this.props.clearMandate();
    }

    /**
     *
     */
    public render() {
        return (
            <FlexColumn
            >
                <FlexRow isFixed>
                    <Navbar
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                        }}
                    >
                        <NavbarStack>
                            <NavbarStackContent>
                                <NavbarTitle>
                                    {this.props.edit ? "Edit Mandate" : "Add Mandate"}
                                </NavbarTitle>
                            </NavbarStackContent>
                            <NavbarStackRight>
                                <NavbarIcon>
                                    <Button
                                        icon={IconNames.TIMES}
                                        link={{ onClick: this.closeModal() }}/>
                                </NavbarIcon>
                            </NavbarStackRight>
                        </NavbarStack>
                    </Navbar>
                </FlexRow>
                <FlexRow
                    theme={{
                        palette: ThemePalette.CONTRAST_PRIMARY,
                    }}
                >
                    <FlexColumn>
                        <FlexRow isScrollable >
                            <FillUp>
                                <Container
                                    theme={{
                                        padding: {
                                            "": { y: 3 },
                                            "md": { y: 4 },
                                        },
                                    }}
                                >
                                    {this.props.loadingMandateDetails ?
                                        (
                                            <LoadingIndicator
                                                type={LoadingIndications.DEFAULT}
                                                theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                                            />
                                        ) :
                                        <>
                                            <Form>
                                                <Div className="scl-b-row">
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormField
                                                            label="Mandate Id"
                                                            required
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("mandateId",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.mandate.mandateId}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Mandate Id provided")}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormField
                                                            label="IBAN"
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("iban",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.mandate.iban}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Iban provided")}
                                                        />
                                                    </Grid>
                                                </Div>
                                                <Div className="scl-b-row">
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormField
                                                            label="Valid from"
                                                            required
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("validFrom",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.mandate.validFrom}
                                                            type="date"
                                                            popperPlacement={"bottom-end"}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Valid from provided")}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormField
                                                            label="Valid to"
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("validTo",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.mandate.validTo}
                                                            type="date"
                                                            popperPlacement={"bottom-end"}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Valid to provided")}
                                                        />
                                                    </Grid>
                                                </Div>
                                                <Div className="scl-b-row">
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormField
                                                            label="Date of signature"
                                                            onChange={(value?: string | number) =>
                                                                this.onChange("dateOfSignature",
                                                                    value ? value.toString() : "")}
                                                            value={this.props.mandate.dateOfSignature}
                                                            type="date"
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Date of signature provided")}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <FormField
                                                            label="Sequence type"
                                                            required
                                                            onChange={(value?: string | number) =>
                                                                this.onChange(
                                                                    "sequenceType",
                                                                    value
                                                                        ? value.toString() as SequenceType
                                                                        : undefined)}
                                                            value={this.props.mandate.sequenceType}
                                                            placeholder="Make a choice"
                                                            options={Object.keys(SequenceType).map((p) => ({
                                                                label: MandateSequenceTypeDisplay[p] as string,
                                                                value: p,
                                                            }))}
                                                            validationFunction={
                                                                (value: string | number) =>
                                                                    hasValueValidation(value.toString(),
                                                                        "Sequence type provided")}
                                                        />
                                                    </Grid>
                                                </Div>
                                            </Form>
                                            <Div><Required /></Div>
                                        </>}
                                </Container>
                                <FlexRow isFixed>
                                    <FlexColumn
                                        theme={{
                                            palette: ThemePalette.CONTRAST_SECONDARY,
                                        }}
                                    >
                                        <Container
                                            theme={{
                                                padding: { "": { y: 3 } },
                                            }}
                                        >
                                            <ButtonsWrapper
                                                orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL }}
                                                alignment={Alignment.RIGHT}
                                            >
                                                <Button
                                                    theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                                    link={{ onClick: this.closeModal() }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                                    disabled={!this.props.canSubmitMandateForm}
                                                    link={{
                                                        onClick:
                                                            this.props.edit ? this.editMandate : this.createMandate,
                                                    }}
                                                    buttonType="submit"
                                                >
                                                    Yes
                                                </Button>
                                            </ButtonsWrapper>
                                        </Container>
                                    </FlexColumn>
                                </FlexRow>
                            </FillUp>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        );
    }

    private onChange = <K extends keyof MandateRequest>(key: K, value: MandateRequest[K]) => {
        this.props.onChange({
            [key]: value,
        });
    };

    private createMandate() {
        this.props.createMandate(this.props.mandate);
    }

    private editMandate() {
        this.props.editMandate(this.props.modalID, this.props.mandate);
    }

    private closeModal() {
        return () => this.props.closeModal(this.props.modalID);
    }
}

/**
 *
 */
export const UpdateMandateModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateMandateComp);
