import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";

import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { ClaimInvoiceStatusDisplay } from "../../../../../store/ClaimMatch/Displays";
import { AuditInvoice } from "../../../../../store/Invoice/Types";
import { CreditDebitIndicatorTypeDisplay } from "../../../../../store/Statements/Displays";


interface OwnProps {
    details: AuditInvoice;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Invoice number"
                            asSummary
                            value={this.props.details.invoiceNumber}
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Invoice date"
                            asSummary
                            value={this.props.details.invoiceDate}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.mutationDate}
                            label="Mutation date"
                            asSummary
                            type="date"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={CreditDebitIndicatorTypeDisplay[this.props.details.creditDebitIndicator]}
                            label="Mutation type"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.balance}
                            label="Balance"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.amount.amount}
                            label="Amount"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.debtorName}
                            label="Partner name"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.debtorIBAN}
                            label="Partner IBAN"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.debtorBIC}
                            label="Partner BIC"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Start date extended warranty"
                            asSummary
                            value={this.props.details.startDateExtendedWarranty}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="End date extended warranty"
                            asSummary
                            value={this.props.details.endDateExtendedWarranty}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Status"
                            asSummary
                            value={ClaimInvoiceStatusDisplay[this.props.details.status]}
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.productName}
                            label="Product name"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.policyNumber}
                            label="Policy number"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.objectType}
                            label="Type"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.brand}
                            label="Brand"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.chassisNumber}
                            label="Chassis number"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.licensePlate}
                            label="License plate"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.maxMileage}
                            label="Max mileage"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.power}
                            label="Power"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.model}
                            label="Model"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.commissionVWPFS}
                            label="Commission VWPFS"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.signCommitteeDamage}
                            label="Sign committee damage"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.frontier}
                            label="Frontier"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.incassoProvision}
                            label="Incasso provision"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.insuranceDuration}
                            label="Insurance duration"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.insuranceTax}
                            label="Insurance tax"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.netPremiumExCost}
                            label="Net premium excluding cost"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.netPremiumIncCost}
                            label="Net premium including cost"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.frontier}
                            label="Frontier"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.proxyInsurerAmount}
                            label="Proxy insurer amount"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.outgoingCostsAndSurcharges}
                            label="Outgoing costs and surcharges"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}

