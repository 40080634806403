
import { OrderedSet } from "immutable";
import React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { InfoCard } from "../../../../components/core/06-molecules/InfoCard";
import { Pages, PageDisplay } from "../../../../store/AppDisplays";
import { UserInfo } from "../../../../store/AppTypes";
import { ReduxState } from "../../../../store/ReduxState";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";


interface StateProps {
    user?: UserInfo;
    availablePages: OrderedSet<Pages>;
}

type Props = StateProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
    availablePages: s.prop("pages").prop("pages").filter(
        (item) => item !== Pages.HOME),
});

class HomeComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Header title="Welcome," description={PageDisplay[Pages.HOME].description} />

                    <Div className="scl-b-row">
                        {
                            this.props.user && (
                                this.props.availablePages.map((page: Pages, index) => (
                                    <Grid key={index} size={{ xs: 12, md: 6, lg: 3 }}>
                                        <InfoCard
                                            title={PageDisplay[page].title}
                                            description={PageDisplay[page].description}
                                            navLink={PageDisplay[page].path}
                                            beta={PageDisplay[page].beta}
                                        />
                                    </Grid>
                                ))
                            )
                        }
                    </Div>
                </Container>
            </Page>
        );
    }
}

export const Home = connect(
    mapStateToProps,
    {},
)(HomeComp);
