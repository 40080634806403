import * as React from "react";
import { connect } from "react-redux";
import { LoadingData } from "../../../../../../components/core/09-views/00-blocks/LoadingData";


/**
 *
 */
interface OwnProps {
}

/**
 *
 */
interface StateProps {
    // formData: PolicyDetails;
}

/**
 *
 */
interface DispatchProps {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param props
 */
export class AuditTrailComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }


    public render() {
        return (
            <LoadingData loading={false} init={false} />
        );
    }
}

/**
 *
 */
export const AuditTrail = connect(
    undefined,
    {},
)(AuditTrailComp);
