import React from "react";
import { connect } from "react-redux";
import { PageTabs } from "../../../../../../store/AppDisplays";
import { Filter } from "../../../02-pages/Filter";
import { Mandates as MandateType } from "../../../../../../store/Mandates/Types";
import { isRemoteLoading, ReduxState } from "../../../../../../store/ReduxState";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Results } from "../../../02-pages/Results";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { UpdateMandateModal } from "../UpdateMandateModal";
import { Div } from "../../../../../../components/core/03-base/Div";
import { Icon, IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { FormBar } from "../../../../../../components/core/07-organisms/FormBar";
import { ThemeSize } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";

interface OwnProps {
    partnerId: string;
}

interface DispatchProps {
    openMandateRequest: () => void;
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: MandateType;
}

type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.MANDATE_RESULTS),
    results:
            s.prop("remote").prop(RemoteScope.MANDATE_RESULTS) as MandateType,
});

/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    openMandateRequest: () => {
        dispatch(
            modalShow(
                "mandate",
                () => (
                    <Modal
                        modalID={"mandate"}
                        theme={{ size: ThemeSize.SMALL }}
                    >
                        <UpdateMandateModal
                            modalID={"mandate"}
                        />
                    </Modal>
                ),
            ),
        );
    },
});

/**
 *
 * @param props
 */
export class MandatesComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
    }

    public render() {
        return (
            <Div style={{ marginTop: "-20px" }}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.PARTNER_MANDATES}
                    defaultFilters={{
                        partnerId: this.props.partnerId,
                    }}
                />
                <FormBar
                    title="Manage mandates"
                    onClick={this.props.openMandateRequest}
                    showButton
                    buttonLabel={<>
                        Add mandate
                        &nbsp;
                        &nbsp;
                        <Icon name={IconNames.PLUS} />
                    </>}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.PARTNER_MANDATES}
                    scope={RemoteScope.MANDATE_RESULTS}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const Mandates = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MandatesComp);
