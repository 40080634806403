import React from "react";
import { connect } from "react-redux";
import { ReduxState, isRemoteLoading } from "../../../../../../store/ReduxState";
import { Filter } from "../../../../09-views/02-pages/Filter";
import { Results } from "../../../../09-views/02-pages/Results";
import { PageTabs } from "../../../../../../store/AppDisplays";
import { GeneralLedgers } from "../../../../../../store/GeneralLedgers/Types";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { Div } from "../../../../../../components/core/03-base/Div";

interface OwnProps {
    statementLineId: string;
}

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: GeneralLedgers;
}

type Props = OwnProps & StateProps;

/**
 *
 * @param s
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.GENERAL_LEDGER),
    results:
            s.prop("remote").prop(RemoteScope.GENERAL_LEDGER) as GeneralLedgers,
});

/**
 *
 * @param props
 */
export class GeneralLedgerComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Div style={{marginTop: "-20px"}}>
                <Filter
                    loadingFilters={this.props.loadingFilters}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER}
                />
                <Results
                    results={this.props.results}
                    loadingResults={this.props.loadingResults}
                    currentPage={PageTabs.MANUAL_BOOKING_CREATE_BOOK_GENERAL_LEDGER}
                    scope={RemoteScope.GENERAL_LEDGER}
                />
            </Div>
        );
    }
}

/**
 *
 */
export const GeneralLedger = connect(
    mapStateToProps,
    {},
)(GeneralLedgerComp);
