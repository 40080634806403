import * as React from "react";
import { ThemeConsumerProps, ThemePalette } from "../../../../theme/_Types";
import { Div } from "../../03-base/Div";
import { LoadingIndications, LoadingIndicator } from "../../05-atoms/LoadingIndicator";
import { LostAndFound } from "./LostAndFound";
import * as I18n from "../../../../utils/translations/I18n";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    loading: boolean;
    init?: boolean;
    errorMessage?: string;
}

/**
 *
 * @param props
 */
export const LoadingData: React.FunctionComponent<Props> = (props) => (
    <Div
        className="scl-h-text-align--center"
        theme={{
            padding: { "": { y: 3 }, "sm": { y: 4 } },
        }}
    >
        {!!props.errorMessage ?
            <LostAndFound title="Oops!" description={props.errorMessage} />
            : props.loading ? (
                <>
                    <LoadingIndicator
                        type={LoadingIndications.DEFAULT}
                        theme={{ palette: ThemePalette.BRAND_PRIMARY }}
                    />
                    <h6>{
                        I18n.getText("mary.09-views.00-blocks.loadingdata.is-loading",
                            "Loading data..")}</h6>
                </> ) :
                !props.loading && props.init ?
                    <LostAndFound
                        title={I18n.getText("mary.09-views.00-blocks.loadingdata.title--init",
                            "Have we searched yet?") ?? ""}
                        description={I18n.getText("mary.09-views.00-blocks.loadingdata.description--init",
                            "Claude suggests to adjust the filter.") ?? ""} />
                    :   <LostAndFound
                        title={I18n.getText("mary.09-views.00-blocks.loadingdata.title",
                            "No results!") ?? ""}
                        description={I18n.getText("mary.09-views.00-blocks.loadingdata.description",
                            "Claude thinks there is no data to display.") ?? ""}
                    />

        }
    </Div>
);
