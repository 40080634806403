import React from "react";
import { Div } from "../../../../../components/core/03-base/Div";
import { Grid } from "../../../../../components/core/03-base/Grid";
import * as FormFields from "../../../../../components/core/07-organisms/Form/Field";
import { CollectiveInvoiceStatusTypeDisplay } from "../../../../../store/CollectiveInvoice/Displays";
import { CollectiveInvoice } from "../../../../../store/CollectiveInvoice/Types";
import { CreditDebitIndicatorTypeDisplay } from "../../../../../store/Statements/Displays";

interface OwnProps {
    details: CollectiveInvoice;
}

type Props = OwnProps;

/**
 *
 */
export class Detail
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

    }

    public render() {
        return (
            <>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Booking date"
                            asSummary
                            value={this.props.details.bookingDate}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Invoice date"
                            asSummary
                            value={this.props.details.invoiceDate}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.mutationDate}
                            label="Mutation date"
                            asSummary
                            type="date"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={CreditDebitIndicatorTypeDisplay[this.props.details.creditDebitIndicator]}
                            label="Mutation type"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.balance}
                            label="Balance"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.totalAmount.amount}
                            label="Amount"
                            asSummary
                            type="currency"
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.dealerName}
                            label="Partner name"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.partnerIBAN}
                            label="Partner IBAN"
                            asSummary
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            value={this.props.details.partnerNumber}
                            label="Partner number"
                            asSummary
                        />
                    </Grid>
                </Div>
                <Div className="scl-b-row">
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Period from"
                            asSummary
                            value={this.props.details.periodFrom}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Period to"
                            asSummary
                            value={this.props.details.periodTo}
                            type="date"
                        />
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                    >
                        <FormFields.FormField
                            label="Status"
                            asSummary
                            value={CollectiveInvoiceStatusTypeDisplay[this.props.details.status]}
                        />
                    </Grid>
                </Div>
            </>
        );
    }
}
