import React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../components/core/03-base/Container";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Button } from "../../../../components/core/05-atoms/Button";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { PageDisplay, Pages } from "../../../../store/AppDisplays";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import { BookingPeriods } from "../../../../store/BookingPeriod/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteTrigger } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";

/**
 *
 */
interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: BookingPeriods;
    user?: UserInfo;
}

interface DispatchProps {
    createBookingPeriod: () => void;
}

type Props = StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.BOOKING_PERIOD),
    results: s.prop("remote").prop(RemoteScope.BOOKING_PERIOD) as BookingPeriods,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    createBookingPeriod: () => dispatch(remoteTrigger(RemoteScope.BOOKING_PERIOD_CREATE, {})),
});

/**
 *
 * @param props
 */
export class BookingPeriodComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);
        this.state = {
        };
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.BOOKING_PERIOD].title}
                                description={PageDisplay[Pages.BOOKING_PERIOD].description}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_DEBTOR &&
                            this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                            <Grid
                                size={{ xs: 12, md: 4 }}
                            >
                                <Div
                                    style={{ textAlign: "right" }}
                                >
                                    <Button
                                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                        className="scl-a-btn--big"
                                        link={{ onClick: this.props.createBookingPeriod }}
                                    >
                                        Create Booking Period
                                    </Button>
                                </Div>
                            </Grid>}
                    </Div>
                    <Filter
                        loadingFilters={this.props.loadingFilters}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.BOOKING_PERIOD}
                    />
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.BOOKING_PERIOD}
                        scope={RemoteScope.BOOKING_PERIOD}
                    />
                </Container>
            </Page>
        );
    }
}

/**
 *
 */
export const BookingPeriod = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BookingPeriodComp);
