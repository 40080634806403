import { AxiosResponse } from "axios";
import { Tariffs, TariffDetails, TariffFilters } from "./Types";

export const mapTariffRequests =
(resp: AxiosResponse): Tariffs => resp.data as Tariffs;

/**
 *
 * @param resp
 */
export const mapTariffDetailsRequests =
    (resp: AxiosResponse): TariffDetails => resp.data as TariffDetails;

/**
 *
 */
export const TariffFiltersToParams = (f: TariffFilters) => ({
    ...f,
});
