/* eslint-disable max-len */
import { Auth } from "aws-amplify";
import * as React from "react";
import { connect } from "react-redux";
import { ThemePalette } from "../../../../../../theme/_Types";
import { Blockquote } from "../../../../05-atoms/Blockquote";
import { Button } from "../../../../05-atoms/Button";
import { Form } from "../../../../07-organisms/Form";
import * as Forms from "../../../../07-organisms/Form/index";
import * as I18n from "../../../../../../utils/translations/I18n";

/**
 *
 */
interface OwnProps {
}

interface State {
    updated: boolean;
    hasError: boolean;
    errorMsg: string;
    oldPassword: string;
    newPassword: string;
}

/**
 *
 */
type Props = OwnProps;

/**
 *
 */
export class ChangePasswordComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.initState();

        this.initState = this.initState.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <>
                {this.state.errorMsg ? (
                    <>
                        <Blockquote>
                            <div className="scl-h-text">
                                {this.state.errorMsg}
                            </div>
                        </Blockquote><br />
                    </>)
                    : this.state.updated ? (
                        <>
                            <Blockquote>
                                <div className="scl-h-text">
                                    { I18n.getText("mary.09-views.03-modals.accountsettings.tabs.changepassword--success",
                                        "Your password has been changed successfully.")}
                                </div>
                            </Blockquote><br />
                        </>
                    )
                        : <React.Fragment />}
                <Form>
                    <Forms.FormField
                        label={I18n.getText("mary.09-views.03-modals.accountsettings.tabs.changepassword-old-password-label",
                            "Old password")}
                        type={"password"}
                        onChange={(value?: string | number) =>
                            this.setState({ oldPassword: value?.toString() || "" })}
                        value={this.state.oldPassword}
                    />
                    <Forms.FormField
                        label={I18n.getText("mary.09-views.03-modals.accountsettings.tabs.changepassword-new-password-label",
                            "New password")}
                        type={"password"}
                        onChange={(value?: string | number) =>
                            this.setState({ newPassword: value?.toString() || "" })}
                        value={this.state.newPassword}
                        max={99}
                        min={6}
                        validationFunction={
                            (value: string | number) =>
                                Forms.meetsPasswordValidation(value,
                                    I18n.getText(
                                        "mary.09-views.03-modals.accountsettings.tabs.changepassword-new-password--valid",
                                        "Password provided.") ?? "", 99, 6)}
                    />
                    <Button
                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                        link={{
                            onClick:
                                () => this.changePassword(
                                    this.state.oldPassword,
                                    this.state.newPassword,
                                ),
                        }}
                        disabled={!(this.state.oldPassword && this.state.newPassword)}
                    >
                        { I18n.getText(
                            "mary.09-views.03-modals.accountsettings.tabs.changepassword-new-password-submit-btn",
                            "Change")}
                    </Button>
                </Form>
            </>
        );
    }

    private initState() {
        return {
            updated: false,
            hasError: false,
            errorMsg: "",
            oldPassword: "",
            newPassword: "",
        };
    }

    private changePassword(oldpass: string, newpass: string) {
        this.setState(this.initState());
        Auth.currentAuthenticatedUser()
            .then((user) => Auth.changePassword(user, oldpass, newpass))
            .then(_data => {
                this.setState({
                    updated: true,
                });
            })
            .catch((err: {message?: string} ) => {
                if (err) {
                    this.setState({
                        hasError: true,
                        errorMsg: err?.message?.toString() ?? "",
                    });
                }
            });
    }
}

/**
 *
 */
export const ChangePassword = connect(
    undefined,
    undefined,
)(ChangePasswordComp);
