import * as React from "react";
import { connect } from "react-redux";
import {
    Container,
} from "../../../../../components/core/03-base/Container";
import {
    Button,
} from "../../../../../components/core/05-atoms/Button";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
import { Icon, IconNames } from "../../../../../components/core/05-atoms/Icon";
import { Claims } from "../../../../../store/Claims/Types";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RowData, Table } from "../../../../../components/core/06-molecules/Table";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Alignment, ButtonsWrapper, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";

/**
 *
 */
interface OwnProps {
    results: Claims;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps;

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.CLAIM_RESULTS, {
        skip: skip,
    })),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                row.claimNumber,
                row.claimNumberPAH,
                <Moment date={new Date(row.claimDate)} format={"DD-MM-YYYY"} key={`date${index}`} />,
                row.policyId ? (
                    <NavLink to={`/policies/${row.policyId}`} target="_blank">
                        <span className="scl-a-link scl-b-h scl-b-h6">
                            <Icon name="eye"/>&nbsp;&nbsp;{row.policyNumber}
                        </span>
                    </NavLink>
                ) : row.policyNumber,
                (
                    <h6 key={`h6-${index}`}>
                        <Currency amount={row.amount} />
                    </h6>
                ),
                (
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    >
                        <NavLink to={`/claims/${row.id}`} >
                            <Button
                                icon={IconNames.ARROW_RIGHT}
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                            />
                        </NavLink>
                    </ButtonsWrapper>
                ),
            ],
        }));
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: {
                column: 1,
                asc: true,
            },
            columns: [
                {
                    label: "Claim number",
                    sortable: true,
                },
                {
                    label: "Claim number (PAH)",
                },
                {
                    label: "Claim date",
                },
                {
                    label: "Policy number",
                },
                {
                    label: "Amount",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    undefined,
    mapDispatchToProps,
)(ResultsComp);
