import { GLEntry } from "../CollectiveInvoice/Types";
import { RequestObject } from "../RemoteTypes";
import { Amount } from "../Statements/Types";

export enum ManualBookingTypes {
    PARTNER = "PARTNER",
    PARTNER_INVOICE = "PARTNER_INVOICE",
    COLLECTIVE_INVOICE = "COLLECTIVE_INVOICE",
    POLICY_INVOICE = "POLICY_INVOICE",
    GENERAL_LEDGER = "GENERAL_LEDGER",
    GENERAL_LEDGER_INVOICE = "GENERAL_LEDGER_INVOICE",
    CLAIM_INVOICE = "CLAIM_INVOICE",
}

export enum VehicleType {
    PERSONENAUTO = "PERSONENAUTO",
    BEDRIJFSAUTO = "BEDRIJFSAUTO",
}

export enum MBStatus {
    BOOKED = "BOOKED",
    UNBOOKED = "UNBOOKED",
}

export interface FinEntryLine {
    id: number;
    createdAt: string;
    date: string;
    description: string;
    costcenter: string;
    costunit: string;
    payable: string;
    amount: Amount;
    glEntry: GLEntry;
    gLAccountCode: string;
}

export interface FreeBookingItem {
    id: number;
    createdAt: string;
    objectType: ManualBookingTypes;
    objectId: number;
    amount: number;
    description?: string | number;
    freeBooking: {}; // check
    finEntryLines: FinEntryLine[];
}

export interface ManualBookingResults {
    id: number;
    createdAt: string;
    bookingDate: string;
    description: string;
    status: MBStatus;
    freeBookingItems: FreeBookingItem[];
}

export interface ManualBookingsResults extends RequestObject {
    content: ManualBookingResults[];
}

// below for create
export interface ManualBookingAmount {
    amount: number;
}

export interface ManualBooking extends ManualBookingAmount {
    objectId: number;
    objectType: ManualBookingTypes;
    description?: string | number;
}

export interface ManualBookings {
    freeBookingItems?: ManualBooking[];
    description?: string;
    bookingDate?: string;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type MBFilters = Readonly<Partial<{
    bookingDateFrom: string;
    bookingDateTo: string;
    description: string;
}>>;
