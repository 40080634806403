
import * as FormFields from "../components/core/07-organisms/Form/index";
import { ValidationStatus } from "../components/core/07-organisms/Form/_Types";
import * as I18n from "./translations/I18n";


export const validTillValidation = (value: string, fromValue: string | undefined): FormFields.ValidationFeedback => {
    if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/i.test(value)) {
        return {
            status: ValidationStatus.ERROR,
            message: I18n.getText("mary.utils.formvalidations.date--error",
                "Date is not valid."),
        };
    }

    if (fromValue && (fromValue > value)) {
        return {
            status: ValidationStatus.ERROR,
            message: I18n.getText("mary.utils.formvalidations.date-to--error",
                "The selected date can't be before the from date."),
        };
    }

    return {
        status: ValidationStatus.SUCCESS,
        message: I18n.getText("mary.utils.formvalidations.date-to--success",
            "To date provided."),
    };
};

export const validFromValidation = (value: string, tillValue: string | undefined): FormFields.ValidationFeedback => {
    if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/i.test(value)) {
        return {
            status: ValidationStatus.ERROR,
            message: I18n.getText("mary.utils.formvalidations.date--error",
                "Date is not valid."),
        };
    }

    if (tillValue && (tillValue < value)) {
        return {
            status: ValidationStatus.ERROR,
            message: I18n.getText("mary.utils.formvalidations.date-from--error",
                "The selected date can't be past the to date."),
        };
    }

    return {
        status: ValidationStatus.SUCCESS,
        message:  I18n.getText("mary.utils.formvalidations.date-from--success",
            "From date provided."),
    };
};
