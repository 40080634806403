/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../../../../store/ActionTypes";
import { ReduxState } from "../../../../../../../store/ReduxState";
import * as StatementTypes from "../../../../../../../store//Statements/Types";
import { NavLink } from "react-router-dom";
import { UnbookRecordModal } from "../UnbookRecordModal";
import { RemoteScope } from "../../../../../../../store/RemoteTypes";
import Moment from "react-moment";
import { UserInfo, UserRoles } from "../../../../../../../store/AppTypes";
import { Badge } from "../../../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../../../components/core/05-atoms/CurrencyDisplay";
import { ButtonsWrapper, Alignment,
    Orientation } from "../../../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../../../components/core/06-molecules/Modal";
import { Table, RowData } from "../../../../../../../components/core/06-molecules/Table";
import { ThemeSize, ThemePaletteState, ThemeBreakpoints,
    ThemePalette, ThemeShadowSizes } from "../../../../../../../theme/_Types";
import { modalShow } from "../../../../../../../utils/redux/ActionTypes";
import { Button } from "../../../../../../../components/core/05-atoms/Button";

/**
 *
 */
interface OwnProps {
    accountId: string;
    statementId: string;
}

/**
 *
 */
interface StateProps {
    results: StatementTypes.StatementDetails;
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    openUnbookRequest: (statementId: string, statementLineId: string) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

export const OpenUnbookRequest = (statementId: string, statementLineId: string) => (
    () => (
        <Modal
            modalID={statementLineId}
            theme={{ size: ThemeSize.SMALL }}
        >
            <UnbookRecordModal
                modalID={statementLineId}
                statementId={statementId}
                statementLineId={statementLineId}
            />
        </Modal>
    )
);

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.STATEMENT_DETAILS) as StatementTypes.StatementDetails,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    openUnbookRequest: (statementId: string, statementLineId: string) => dispatch(
        modalShow(
            statementLineId,
            OpenUnbookRequest(statementId, statementLineId),
        ),
    ),
});

/**
 *
 * @param props
 */
export class StatementLinesComp
    extends React.Component<Props, {}> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props, ctx?: {}) {
        super(props, ctx);

        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openUnbook = this.openUnbook.bind(this);
    }

    public render() {
        return (
            <Table
                hasActions
                data={{
                    columns: this.resultsData().columns,
                    rows: this.resultsData().rows,
                    sort: this.resultsData().sort,
                }}
            />
        );
    }

    private getProcessedRows(): RowData[] {
        if (!this.props.results.content.entries) {
            return [];
        }
        return this.props.results.content.entries.map((row, index) => {
            const payInfArray: string[] = [];
            row.entryDetails.forEach(detail => payInfArray.push(detail.batch?.pmtInfId));
            const messageArray: string[] = [];
            row.entryDetails.forEach(trandetail => trandetail.transactionDetails.forEach(unstructured => (
                unstructured.unstructuredRemittances.forEach(remittance => messageArray.push(remittance.unstructured))
            )));
            return ({
                id: row.id,
                data: [
                    (
                        <Moment
                            date={new Date(row.bookingDate)}
                            format={"DD-MM-YYYY"}
                            key={`statement-line-date${index}`}
                        />),
                    messageArray.length > 0 ? messageArray.join() : payInfArray.length > 0 ? payInfArray.join() : "",
                    row.statementLineStatus === StatementTypes.StatementLineStatus.MATCHED ?
                        <Badge theme={{ paletteState: ThemePaletteState.SUCCESS }}>Yes</Badge> :
                        <Badge theme={{ paletteState: ThemePaletteState.DANGER }}>No</Badge>,
                    row.creditDebitIndicator === StatementTypes.CreditDebitIndicator.DBIT ? "DEBIT" : "CREDIT",
                    (
                        <Currency
                            key={`currency${index}`}
                            amount={row.amount.amount}
                        />
                    ),
                    (
                        <h6
                            key={`h6${index}`}>
                            <Currency
                                amount={row.balance}
                            />
                        </h6>
                    ),
                    (
                        <ButtonsWrapper
                            key={`wrapper${index}`}
                            alignment={Alignment.RIGHT}
                            asGroup
                            orientations={{
                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                            }}>
                            <NavLink
                                to={`/accounts/${this.props.accountId}/${this.props.statementId}/${row.id}/manage`}
                            >
                                <Button
                                    className="scl-a-btn--tiny"
                                    style={{ verticalAlign: "middle" }}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                >
                                    Manage
                                </Button>
                            </NavLink>
                            {(row.statementLineStatus === StatementTypes.StatementLineStatus.PARTIALLY_MATCHED ||
                                row.statementLineStatus === StatementTypes.StatementLineStatus.MATCHED)
                                && this.props.user?.userRole !== UserRoles.EW_DEBTOR
                                && this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY ?
                                <Button
                                    className="scl-a-btn--tiny"
                                    style={{ verticalAlign: "middle" }}
                                    theme={{
                                        paletteState: ThemePaletteState.DANGER,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    link={{ onClick: this.openUnbook(this.props.statementId, row.id.toString()) }}
                                >
                                    Unbook
                                </Button> : <></>}
                        </ButtonsWrapper>
                    ),
                ],
            });
        });
    }

    private openUnbook(statementId: string, statementLineId: string) {
        return () => this.props.openUnbookRequest(statementId, statementLineId);
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Booking date",
                },
                {
                    label: "Message",
                },
                {
                    label: "Booked",
                },
                {
                    label: "Mutation Type",
                },
                {
                    label: "Mutation Amount",
                },
                {
                    label: "Uncleared Balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const StatementLines = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StatementLinesComp);
