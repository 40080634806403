/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

// DONE
// tslint:disable
export const Toast = {
    position: "relative",
    color: "var(--scl-color-state-contrast--danger)",
    backgroundColor: "var(--scl-color-state-brand--danger)",
    fontSize: "16px",
    fontWeight: "var(--scl-font-family--font-weight)",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.2",
    padding: "20px",
    width: "560px",
    maxWidth: "100%",
    margin: "0 auto",
};

// DONE
export const Container = {
    backgroundColor: "transparent",
    minHeight: "100%",
    height: "100%",
    padding: "20px",
    fontFamily: "var(--scl-font-family)",
    fontWieght: "var(--scl-font-family--font-weight)",
    width: "100%",
    overflow: "auto",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
};

// DONE
export const FormContainer = {
    textAlign: "center",
    paddingTop: "0",
    margin: "0 auto",
    position: "relative",
    maxWidth: "100%",
};

// DONE
export const FormSection = {
    position: "relative",
    marginBottom: "0",
    backgroundColor: "var(--scl-color-contrast--primary)",
    padding: "0",
    textAlign: "left",
    width: "560px",
    minWidth: "0",
    maxWidth: "100%",
    borderRadius: "0",
    boxShadow: "2px 0 28px rgba(0,0,0,0.2)",
};

export const FormField = {
    marginBottom: "22px",
};

// DONE
export const SectionHeader = {
    color: "var(--scl-color-brand--text-heading)",
    backgroundColor: "var(--scl-color-contrast--tertiary)",
    fontSize: "18px",
    fontWeight: "var(--scl-font-family-heading--font-weight)",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    lineHeight: "1.2",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "20px",
    marginBottom: "0",
    fontFamily: "var(--scl-font-family-heading)",
    textTransform: "var(--scl-font-family-heading--text-transform)",
};

// DONE
export const SectionBody = {
    padding: "30px",
    marginBottom: "0",
};

// DONE
export const SectionFooter = {
    color: "var(--scl-color-brand--text-body)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "30px",
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "var(--scl-color-contrast--tertiary)",
};

// DONE
export const SectionFooterPrimaryContent = {
    marginLeft: "0",
    width: "100%",
    marginBottom: "0",
};

// DONE
export const SectionFooterSecondaryContent = {
    marginLeft: "0",
    paddingLeft: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    width: "100%",
    lineHeight: "1.2",
};

// DONE
export const Input = {
    display: "block",
    width: "100%",
    padding: "20px",
    fontSize: "16px",
    fontFamily: "var(--scl-font-family)",
    fontWeight: "var(--scl-font-family--font-weight)",
    color: "var(--scl-color-brand--text-body)",
    backgroundColor: "var(--scl-color-contrast--primary)",
    backgroundImage: "none",
    border: "1px solid var(--scl-color-contrast--tertiary)",
    borderRadius: "0px",
    boxSizing: "border-box",
    marginBottom: "10px",
    WebkitAppearance: "none",
    outline: "0",
};

// DONE
export const Button = {
    minWidth: "153px",
    // eslint-disable-next-line max-len
    transition: "color 3s ease, box-shadow .3s ease, background-color .3s ease, text-shadow .3s ease, border-color .3s ease",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "9px",
    paddingBottom: "9px",
    borderStyle: "solid",
    borderWidth: "0",
    textDecoration: "none",
    lineHeight: "20px",
    textAlign: "center",
    outline: "0",
    display: "block",
    fontWeight: "var(--scl-a-btn--font-weight)",
    fontSize: "var(--scl-a-btn--font-size)",
    fontFamily: "var(--scl-a-btn--font-family)",
    backgroundColor: "var(--scl-color-brand--accent)",
    borderColor: "var(--scl-color-brand--accent)",
    borderRadius: "var(--scl-a-btn--border-radius)",
    textTransform: "var(--scl-a-btn--text-transform)",
    color: "var(--scl-color-contrast--accent)",
    width: "100%",
    letterSpacing: "0",
};

// NOT DONE
export const SignInButton = {
    "position": "relative",
    "width": "100%",
    "borderRadius": "4px",
    "marginBottom": "10px",
    "cursor": "pointer",
    "padding": 0,
    "fontFamily": "'vwfs-sans', 'Open Sans', 'Arial', 'sans-serif'",
    "color": "var(--scl-color-contrast--accent)",
    "fontSize": "14px",
    "#google_signin_btn": {
        backgroundColor: "var(--scl-color-brand--accent)",
        fontFamily: "'vwfs-sans', 'Open Sans', 'Arial', 'sans-serif'",
        border: "1px solid var(--scl-color-brand--accent)",
    },
    "#facebook_signin_btn": {
        backgroundColor: "var(--scl-color-brand--accent)",
        borderColor: "var(--scl-color-brand--accent)",
    },
    "#amazon_signin_btn": {
        backgroundColor: "var(--scl-color-brand--accent)",
        border: "none",
    },
};

// NOT DONE
export const SignInButtonIcon = {
    "position": "absolute",
    "left": 0,
    "#google_signin_btn_icon": {
        backgroundColor: "var(--scl-color-contrast--accent)",
        borderRadius: "4px 0 0 4px",
        height: "30px",
        width: "30px",
        padding: "11px",
    },
    "#facebook_signin_btn_icon": {
        height: "33px",
        width: "18px",
        padding: "10px 14px",
    },
    "#amazon_signin_btn_icon": {
        padding: "10px",
        height: "32px",
        width: "32px",
    },
};

// DONE
export const SignInButtonContent = {
    display: "block",
    padding: "18px 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
};

// NOT DONE
export const Strike = {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #bbb",
    lineHeight: "0.1em",
    margin: "32px 0",
    color: "var(--scl-color-brand--tertiary)",
};

// NOT DONE
export const StrikeContent = {
    background: "var(--scl-color-contrast--primary)",
    padding: "0 25px",
    fontSize: "14px",
    fontWeight: "var(--scl-font-family--font-weight)",
};

// DONE
export const ActionRow = {
    marginBottom: "15px",
};

// DONE
export const FormRow = {
    marginBottom: "12px",
};

// DONE
export const A = {
    color: "var(--scl-a-color)",
    cursor: "pointer",
    fontFamily: "var(--scl-font-family)",
    fontWeight: "var(--scl-font-family--font-weight)",
};

// DONE
export const Hint = {
    color: "var(--scl-color-brand--text-body)",
    fontSize: "14px",
};

// DONE
export const Radio = {
    margin: "0px",
    marginRight: "10px",
    verticalAlign: "middle",
    height: "20px",
    width: "20px",
};

// OTHER
export const Label = {
    color: "var(--scl-color-brand--text-heading)",
    verticalAlign: "middle",
    fontSize: "var(--scl-font-size)",
    fontFamily: "var(--scl-font-family-bold)",
    fontWeight: "var(--scl-font-family-bold--font-weight)",
};

// DONE
export const InputLabel = {
    color: "var(--scl-color-brand--text-heading)",
    marginBottom: "8px",
    fontSize: "var(--scl-font-size)",
    fontFamily: "var(--scl-font-family-bold)",
    fontWeight: "var(--scl-font-family-bold--font-weight)",
};

// DONE
export const SelectInput = {
    display: "flex",
};

export const AmplifyTheme = {
    container: Container,
    formContainer: FormContainer,
    formSection: FormSection,
    formField: FormField,

    sectionHeader: SectionHeader,
    sectionBody: SectionBody,
    sectionFooter: SectionFooter,
    sectionFooterPrimaryContent: SectionFooterPrimaryContent,
    sectionFooterSecondaryContent: SectionFooterSecondaryContent,

    input: Input,
    selectInput: SelectInput,
    button: Button,
    photoPickerButton: PhotoPickerButton,
    photoPlaceholder: PhotoPlaceholder,
    signInButton: SignInButton,
    signInButtonIcon: SignInButtonIcon,
    signInButtonContent: SignInButtonContent,
    amazonSignInButton: AmazonSignInButton,
    facebookSignInButton: FacebookSignInButton,
    googleSignInButton: GoogleSignInButton,
    oAuthSignInButton: OAuthSignInButton,

    formRow: FormRow,
    strike: Strike,
    strikeContent: StrikeContent,
    actionRow: ActionRow,
    a: A,

    hint: Hint,
    radio: Radio,
    inputLabel: InputLabel,
    label: Label,
    toast: Toast,

    navBar: NavBar,
    nav: Nav,
    navRight: NavRight,
    navItem: NavItem,
    navButton: NavButton,
};
// tslint:enable
