/* eslint-disable react/display-name */
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { DeleteRecordModal } from "./DeleteRecordModal";
import Moment from "react-moment";
import { BalanceType, Statements, StatementStatus } from "../../../../../../store/Statements/Types";
import { UserInfo, UserRoles } from "../../../../../../store/AppTypes";
import { Badge } from "../../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames } from "../../../../../../components/core/05-atoms/Icon";
import { Modal } from "../../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../../store/ActionTypes";
import { ReduxState } from "../../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../../store/RemoteTypes";
import { ThemeSize, ThemePalette, ThemeShadowSizes, ThemeBreakpoints } from "../../../../../../theme/_Types";
import { modalShow } from "../../../../../../utils/redux/ActionTypes";
import { getStatementStatusColor } from "../../../../../utils/FormatHelpers";
import { Container } from "../../../../../../components/core/03-base/Container";
import { Button } from "../../../../../../components/core/05-atoms/Button";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../../components/core/06-molecules/ButtonsWrapper";


/**
 *
 */
interface OwnProps {
    results: Statements;
    accountId: string;
    asAuditTab?: boolean;
}

/**
 *
 */
interface StateProps {
    user?: UserInfo;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openDeleteRequest: (statementId: string) => void;
}

/**
 *
 */
type Props = OwnProps & DispatchProps & StateProps;

export const DeleteStatementModal = (id: string) => (
    () => (
        <Modal
            modalID={id}
            theme={{ size: ThemeSize.SMALL }}
        >
            <DeleteRecordModal
                modalID={id}
                statementId={id}
            />
        </Modal>
    )
);

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.STATEMENT_RESULTS, {
        skip: skip,
    })),
    openDeleteRequest: (statementId: string) => dispatch(
        modalShow(
            statementId,
            DeleteStatementModal(statementId),
        ),
    ),
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.openDelete = this.openDelete.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results?.totalPages && this.props.results?.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </React.Fragment>
        );
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => {
            const balanceOPBD = row.balances.find(balance => balance.balanceTypeCode === BalanceType.OPENINGBALANCE);
            const balanceCLBD = row.balances.find(balance => balance.balanceTypeCode === BalanceType.CLOSINGBALANCE);
            return ({
                id: row.id,
                data: [
                    row.stmtId,
                    (
                        <Moment
                            date={new Date(row.creationDateTime)}
                            format={"DD-MM-YYYY"}
                            key={`creation-date${index}`}
                        />
                    ),
                    row.mutationDate ?
                        (
                            <Moment
                                date={new Date(row.mutationDate)}
                                format={"DD-MM-YYYY"}
                                key={`mutation-date${index}`}
                            />
                        ) : "",
                    (
                        <Badge
                            key={`badge${index}`}
                            theme={{ paletteState: getStatementStatusColor(row.statementStatus) }}>
                            {row.statementStatus ? row.statementStatus.toString() : "n.a."}
                        </Badge>
                    ),
                    balanceOPBD ? <h6><Currency amount={balanceOPBD.amount.amount} /></h6> : "",
                    balanceCLBD ? <h6><Currency amount={balanceCLBD.amount.amount} /></h6> : "",
                    <ButtonsWrapper
                        key={`navlink-${index}`}
                        asGroup
                        alignment={Alignment.RIGHT}
                        orientations={{
                            [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                        }}
                    > {

                            this.props.asAuditTab ? (
                                <NavLink to={`/audit/statements/${row.id}`}>
                                    <Button
                                        icon={IconNames.ARROW_RIGHT}
                                        theme={{
                                            palette: ThemePalette.CONTRAST_PRIMARY,
                                            shadow: ThemeShadowSizes.TINY,
                                        }}
                                    />
                                </NavLink>
                            ) :
                                (
                                    <>
                                        {row.statementStatus === StatementStatus.UNPROCESSED &&
                                this.props.user?.userRole !== UserRoles.EW_DEBTOR &&
                                this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                                <Button
                                    icon={IconNames.DELETE}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_PRIMARY,
                                        shadow: ThemeShadowSizes.TINY,
                                    }}
                                    link={{ onClick: this.openDelete(row.id.toString()) }}
                                />}
                                        <NavLink to={`/accounts/${this.props.accountId}/${row.id}`} exact>
                                            <Button
                                                icon={IconNames.ARROW_RIGHT}
                                                theme={{
                                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                                    shadow: ThemeShadowSizes.TINY,
                                                }}
                                            />
                                        </NavLink>
                                    </>
                                )}</ButtonsWrapper>,
                ],
            });
        });
    }

    private openDelete(id: string) {
        return () => this.props.openDeleteRequest(id);
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Statement ID",
                },
                {
                    label: "Creation date",
                },
                {
                    label: "Mutation date",
                },
                {
                    label: "Status",
                },
                {
                    label: "Opening Balance",
                },
                {
                    label: "Closing Balance",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
