import * as React from "react";
import { connect } from "react-redux";

import { getPartnerTypeColor } from "../../../../utils/FormatHelpers";
import { PageTabs } from "../../../../../store/AppDisplays";
import { Partners, Partner } from "../../../../../store/Partners/Types";
import { PartnerTypeDisplay } from "../../../../../store/Partners/Displays";
import { amIChecked, myAmount } from "../../../../utils/ManualBooking";
import { ManualBooking, ManualBookingTypes, ManualBookings } from "../../../../../store/ManualBooking/Types";
import { mapDispatchToPropsResults, mapStateToPropsResults,
    DispatchPropsResults, StatePropsResults } from "../ManualBookings/Create/Results";
import { Badge } from "../../../../../components/core/05-atoms/Badge";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData } from "../../../../../components/core/06-molecules/Table";
import { Form } from "../../../../../components/core/07-organisms/Form";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { ThemePalette, ThemeShadowSizes } from "../../../../../theme/_Types";
import { bookingBarData, ManualBookBar } from "../ManualBookings/Create/ManualBookBar";
import { Container } from "../../../../../components/core/03-base/Container";
import { FormField } from "../../../../../components/core/07-organisms/Form/Field";
import { Checkbox } from "../../../../../components/core/05-atoms/Checkbox";


interface State {
    selectedRows: ManualBooking[];
}

/**
 *
 */
interface StateProps {
    manualBookings?: ManualBooking[];
}

/**
 *
 */
interface OwnProps {
    results: Partners;
    currentTab?: PageTabs;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchPropsResults & StatePropsResults;

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.resultsData = this.resultsData.bind(this);
        this.onAddSelection = this.onAddSelection.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
        this.submit = this.submit.bind(this);
    }

    /**
     *
     */
    public componentDidUpdate(prevProps: Props) {
        if (prevProps.results !== this.props.results) {
            this.setState(this.refreshedRowState());
        }
    }

    /**
     *
     */
    public render() {
        const calcBookingBarData = bookingBarData(this.props.manualBookings, this.state.selectedRows);

        return (
            <Form onSubmit={this.submit} isValid={calcBookingBarData.isValid === true}>
                <ManualBookBar
                    title={"Free booking"}
                    {...calcBookingBarData}
                />
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.props.loadResults}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> : null}
            </Form>
        );
    }

    private submit() {
        this.props.openBookRequest(
            "partner-free-booking", () =>
                this.props.manualBook(this.props.manualBooking as ManualBookings));
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results.content.map((row, index) => ({
            id: row.id,
            data: [
                (
                    <div
                        key={`selection-${row.id}`}
                        className="scl-row-selection"
                        style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            flexGrow: 0,
                            alignItems: "center",
                        }}
                    >
                        <div
                            className="scl-row-selection__checkbox"
                            style={{
                                flex: 1,
                                flexGrow: 0,
                                marginRight: "5px",
                            }}
                        >
                            <Checkbox
                                checked={amIChecked(row.id, this.state.selectedRows)}
                                onChange={(value?: boolean) => this.selectItem(value, row,
                                    myAmount(row.id, this.state.selectedRows) || 0)}
                            />
                        </div>
                        <div
                            className="scl-row-selection__input"
                            style={{
                                flex: 1,
                                flexGrow: 0,
                            }}
                        >
                            {(amIChecked(row.id, this.state.selectedRows)) &&
                                    <FormField
                                        type="currency"
                                        placeholder="e.g. 100"
                                        onChange={(value?: number | string) => {
                                            this.onChangeAmount(row,
                                                value ? typeof value === "string"
                                                    ? parseFloat(value) : value : 0);
                                        }}
                                        value={myAmount(row.id,
                                            this.state.selectedRows,
                                            0)}
                                    />
                            }
                        </div>
                    </div>
                ),
                row.name ? row.name : "",
                row.partnerNumber,
                row.partnerType ? (
                    <Badge
                        key={`p-badge-${index}`}
                        theme={{
                            palette: getPartnerTypeColor(row.partnerType),
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        {PartnerTypeDisplay[row.partnerType]}
                    </Badge>
                ) : "",
                row.iban,
                (
                    <Currency
                        key={`p-invoice-balance-${index}`}
                        amount={row.invoiceBalance}
                    />
                ),
                (
                    <Currency
                        key={`p-claim-balance-${index}`}
                        amount={row.claimBalance}
                    />
                ),
                (
                    <h6 key={`p-total-balance-${index}`}>
                        <Currency
                            amount={row.totalBalance}
                        />
                    </h6>
                ),
            ],
        }));
    }

    /**
     *
     */
    private resultsData() {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Name",
                },
                {
                    label: "Number",
                },
                {
                    label: "Type",
                },
                {
                    label: "IBAN",
                },
                {
                    label: "Invoice balance",
                },
                {
                    label: "Claim balance",
                },
                {
                    label: "Total balance",
                    alignRight: true,
                },
            ],
            rows: this.getProcessedRows(),
        };
    }

    /**
     *
     */
    private refreshedRowState(): State {
        return {
            selectedRows: this.props.manualBookings ? this.props.manualBookings
                .filter(mB => (mB.objectType === this.props.bookingType) &&
                        (this.props.results.content.some(row => row.id === mB.objectId)))
                .map((mB): ManualBooking => ({
                    objectId: mB.objectId,
                    description: mB.description ? mB.description : "",
                    amount: mB.amount,
                    objectType: this.props.bookingType,
                })) : [],
        };
    }

    private selectItem(value: boolean | undefined, row: Partner, amount: number) {
        this.setState({
            selectedRows: !!value
                ? [...this.state.selectedRows.filter((sR) => (sR.objectId !== row.id
                    && sR.objectType === this.props.bookingType)), {
                    objectId: row.id,
                    amount: amount,
                    description: row.partnerNumber,
                    objectType: this.props.bookingType,
                    // name: row.name,
                }] : this.state.selectedRows.filter((sR) => sR.objectId !== row.id
                    && sR.objectType === this.props.bookingType),
        }, !!value ? this.onAddSelection : () => this.props.deleteManualBookingRow(row.id));
    }

    private onChangeAmount = (row: Partner, value: number) => {
        const manualBookingEntry = this.state.selectedRows.find(mB => (mB.objectId === row.id
                && mB.objectType === this.props.bookingType));

        this.setState({
            selectedRows: manualBookingEntry ? this.state.selectedRows.map(sR => {
                if (sR.objectId === row.id && sR.objectType === this.props.bookingType) {
                    return ({
                        objectId: sR.objectId,
                        amount: value,
                        description: sR.description,
                        objectType: this.props.bookingType,
                        // name: sR.name,
                    });
                }

                return sR;
            }) : [...this.state.selectedRows, {
                objectId: row.id,
                amount: value,
                objectType: this.props.bookingType,
                description: row.partnerNumber,
                // name: row.name,
            }],
        }, this.onAddSelection);
    };

    private onAddSelection = () => {
        this.props.addManualBookSelection(this.state.selectedRows);
    };
}

/**
 *
 */
export const Results = connect(
    mapStateToPropsResults,
    mapDispatchToPropsResults(RemoteScope.PARTNER_RESULTS, ManualBookingTypes.PARTNER),
)(ResultsComp);
