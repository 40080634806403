import { Amount, CreditDebitIndicator } from "../Statements/Types";
import { RequestObject } from "../RemoteTypes";
import { Partner } from "../Partners/Types";
import { ClaimInvoiceStatus } from "../ClaimMatch/Types";
import { InvoicePayment } from "../CollectiveInvoice/Types";

export enum InvoiceType {
    PolicyInvoice = "PolicyInvoice",
    ClaimInvoice = "ClaimInvoice",
    PartnerInvoice = "PartnerInvoice",
    GeneralLedgerInvoice = "GeneralLedgerInvoice",
}

// interface PolicyInfo {
//     policyNumber: string;
//     licensePlate: string;
//     chassisNumber: string;
//     brand: string;
//     model: string;
//     type: string;
//     power: number;
//     startDateExtendedWarranty: string;
//     endDateExtendedWarranty: string;
// }

export interface Invoice {
    id: number;
    createdAt: string;
    objectType: InvoiceType;
    invoiceNumber: number;
    amount: Amount;
    balance: number;
    invoiceAmount: number;
    invoiceDate: string;
    creditDebitIndicator: CreditDebitIndicator;
    status: ClaimInvoiceStatus;
    partner: Partner;
    payments: InvoicePayment[];
    messageId: number; // need from BE
    collectiveInvoiceNumber: number;
    mutationDate: string;
}

export interface Invoices extends RequestObject {
    content: Invoice[];
}

export interface AuditInvoice {
    id: number;
    createdAt: string;
    objectType: InvoiceType;
    invoiceNumber: number;
    amount: Amount;
    balance: number;
    invoiceAmount: number;
    invoiceDate: string;
    creditDebitIndicator: CreditDebitIndicator;
    status: ClaimInvoiceStatus;
    partner: Partner;
    payments: InvoicePayment[];
    messageId: number; // need from BE
    collectiveInvoiceNumber: number;
    mutationDate: string;
    brand: string;
    chassisNumber: string;
    commissionVWPFS: number;
    debtorBIC: string;
    debtorIBAN: string;
    debtorName: string;
    endDateExtendedWarranty: string;
    frontier: number;
    ft_GADEKCO: string;
    houseNumber: string;
    incassoProvision: number;
    insuranceDuration: number;
    insuranceTax: number;
    insurer: number;
    licensePlate: string;
    maxMileage: number;
    model: string;
    netPremiumExCost: number;
    netPremiumIncCost: number;
    outgoingCostsAndSurcharges: number;
    partnerIBAN: string;
    partnerNumber: string;
    policyNumber: string;
    power: number;
    productName: string;
    proxyInsurerAmount: number;
    residence: string;
    signCommitteeDamage: number;
    startDateExtendedWarranty: string;
    streetName: string;
    transactionType: number;
    zipCode: string;
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type InvoiceFilters = Readonly<Partial<{
    partnerNumber: string; // not there yet
    partnerId: number;
    partnerName?: string;
    creationDateFrom: string;
    creationDateTo: string;
    creditDebitIndicator: CreditDebitIndicator;
    invoiceStatus: string;
    invoiceNumber: string;
    collectiveInvoiceNumber: number;
    policyNumber: string;
    invoiceType: InvoiceType;
}>>;
