
import * as React from "react";
import { connect } from "react-redux";
import { Container } from "../../../../../components/core/03-base/Container";
import { Button } from "../../../../../components/core/05-atoms/Button";
import { Currency } from "../../../../../components/core/05-atoms/CurrencyDisplay";
import { IconNames } from "../../../../../components/core/05-atoms/Icon";
import { ButtonsWrapper, Alignment, Orientation } from "../../../../../components/core/06-molecules/ButtonsWrapper";
import { Modal } from "../../../../../components/core/06-molecules/Modal";
import { Pagination } from "../../../../../components/core/06-molecules/Pagination";
import { Table, RowData, TableData } from "../../../../../components/core/06-molecules/Table";
import { DispatchFunc } from "../../../../../store/ActionTypes";
import { CoverageLevelDisplay } from "../../../../../store/Policies/Displays";
import { ProductDetail, ProductRequest, ProductTariffRequests } from "../../../../../store/Product/Types";
import { ReduxState } from "../../../../../store/ReduxState";
import { remoteTrigger } from "../../../../../store/RemoteActions";
import { RemoteScope } from "../../../../../store/RemoteTypes";
import { Tariffs } from "../../../../../store/Tariffs/Types";
import { ThemeSize, ThemePalette, ThemeBreakpoints, ThemeShadowSizes } from "../../../../../theme/_Types";
import { modalShow } from "../../../../../utils/redux/ActionTypes";
import { TableSelectCell } from "../../../06-molecules/TableSelectCell";
import { DetailModal } from "./DetailModal";


export interface SelectedRow {
    id: number;
}

interface State {
    selectedRows: SelectedRow[];
}

/**
 *
 */
interface StateProps {
    productDetails: ProductDetail;
    product: ProductRequest;
}

/**
 *
 */
interface OwnProps {
    results: Tariffs;
    onChange: <K extends keyof ProductTariffRequests>
    (key: K, value: ProductTariffRequests[K], index: number) => void;
    index: number;
    edit?: boolean;
    productTariffId?: number;
}

/**
 *
 */
interface DispatchProps {
    loadResults: (skip: number) => void;
    openTariffDetail: (id: number) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const mapStateToProps = (s: ReduxState): StateProps => ({
    productDetails: s.prop("remote").prop(RemoteScope.PRODUCT_DETAILS) as ProductDetail,
    product: s.prop("productRequest").map(e => ({
        ...e,
    })),
});

/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    loadResults: (skip: number) => dispatch(remoteTrigger(RemoteScope.TARIFF_RESULTS, {
        skip: skip,
    })),
    openTariffDetail: (id: number) => {
        dispatch(
            modalShow(
                id.toString(),
                () => (
                    <Modal
                        modalID={id.toString()}
                        theme={{ size: ThemeSize.SMALL }}
                    >
                        <DetailModal
                            modalID={id.toString()}
                            tariffId={id}
                        />
                    </Modal>
                ),
            ),
        );
    },
});

/**
 *
 */
export class ResultsComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = this.refreshedRowState();

        this.handlePageClick = this.handlePageClick.bind(this);
        this.resultsData = this.resultsData.bind(this);
        this.getProcessedRows = this.getProcessedRows.bind(this);
    }

    /**
     *
     */
    public render() {
        return (
            <React.Fragment>
                <Table
                    hasActions
                    data={{
                        columns: this.resultsData().columns,
                        rows: this.resultsData().rows,
                        sort: this.resultsData().sort,
                    }}
                />
                {(this.props.results && this.props.results.totalPages && this.props.results.totalPages > 1) ?
                    <Container
                        className="scl-h-text-align--center"
                        theme={{
                            padding: { "": { t: 3, b: 2 }, "sm": { t: 4, b: 3 } },
                        }}
                    >
                        <Pagination
                            pageCount={this.props.results.totalPages}
                            currentPage={this.props.results.number}
                            onPageChange={this.handlePageClick}
                            theme={{ palette: ThemePalette.CONTRAST_PRIMARY }}
                        />
                    </Container> :  null}
            </React.Fragment>
        );
    }

    private selectItem(value: boolean | undefined, id: number) {
        this.setState({
            selectedRows: !!value ? [{
                id: id,
            }] : [],
        }, () => this.props.onChange("tariffLine", this.state.selectedRows[0], this.props.index));
    }

    private refreshedRowState(): State {
        return {
            selectedRows: this.props.productTariffId ? [{ id: this.props.productTariffId }] : [],
        };
    }

    private handlePageClick(skip: number) {
        this.props.loadResults(skip);
    }

    private amIChecked(id: number, selectedRows: SelectedRow[]): boolean {
        return selectedRows.some((row) => row.id === id);
    }

    /**
     *
     */
    private getProcessedRows(): RowData[] {
        return this.props.results && this.props.results.content && this.props.results.content.map((row, _index) => {
            const checked = this.amIChecked(row.id, this.state.selectedRows);
            return ({
                id: row.id,
                data: [
                    (
                        <TableSelectCell
                            key={`table-cell-${row.id}`}
                            checked={checked}
                            selectItem={() => this.selectItem(!checked, row.id)}
                        />
                    ),
                    row.passengerVehicle && row.commercialVehicle
                        ? "Passenger and Commerical" : row.commercialVehicle ? "Commercial" : "Passenger",
                    row.maxKW,
                    CoverageLevelDisplay[row.coverageLevel],
                    row.tariffPeriod,
                    (
                        <h6 key={`tariff-cell-${row.id}`}>
                            <Currency amount={row.premium} />
                        </h6>),
                    (
                        <ButtonsWrapper
                            key={`button-cell-${row.id}`}
                            asGroup
                            alignment={Alignment.RIGHT}
                            orientations={{
                                [ThemeBreakpoints.XS]: Orientation.HORIZONTAL,
                            }}
                        >
                            <Button
                                icon={IconNames.VIEW}
                                theme={{
                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                    shadow: ThemeShadowSizes.TINY,
                                }}
                                style={{ marginRight: "10px" }}
                                link={{ onClick: () => this.props.openTariffDetail(row.id) }}
                            />
                        </ButtonsWrapper>
                    ),
                ],
            });
        });
    }

    /**
     *
     */
    private resultsData(): TableData {
        return {
            sort: undefined,
            columns: [
                {
                    label: "Select",
                },
                {
                    label: "Type",
                },
                {
                    label: "Max KW",
                },
                {
                    label: "Coverage Level",
                },
                {
                    label: "Period",
                },
                {
                    label: "Premium",
                    alignRight: true,
                },
                {
                    label: "Actions",
                },
            ],
            rows: this.getProcessedRows(),
        };
    }
}

/**
 *
 */
export const Results = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResultsComp);
