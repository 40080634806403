import * as React from "react";

import { ThemeProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { Button } from "../05-atoms/Button";
import { Icon, IconNames } from "../05-atoms/Icon";

/**
 *
 */
interface ButtonProps {
    changeThePage: (currentPage: number) => void;
    targetPage: number;
    disabled?: boolean;
}

/**
 *
 */
export const PaginationButtonComp: React.FunctionComponent<ButtonProps> = (props) => (
    <Button
        link={{onClick: (_e: React.MouseEvent | React.TouchEvent) => props.changeThePage(props.targetPage )}}
        disabled={props.disabled ? props.disabled : false}
    >
        { props.children ? props.children : (
            <span aria-hidden="true">
                {props.targetPage + 1}
            </span>
        )}
    </Button>
);

/**
 *
 */
export interface Props
    extends ThemeProps {
    className?: string;
    pageCount: number;
    currentPage: number;
    onPageChange: (currentPage: number) => void;
}
/**
 *
 * @param props
 */
export const PaginationComp: React.FunctionComponent<Props> = (props) => {
    const className = joinClasses(
        "scl-m-pagination",
        props.className,
        props.theme && props.theme.palette ? "scl-m-pagination--" + props.theme.palette : undefined,
    );

    const maxPageNumber = props.pageCount - 1;
    const minPageNumber = 0;

    const changeThePage = (newPage: number) => {
        props.onPageChange(newPage);
    };

    const getPageItems = (
        <React.Fragment>
            {
                props.currentPage + 1 <= maxPageNumber ? null : (
                    props.currentPage - 2 >= minPageNumber &&
                <li className="scl-m-pagination__item" key={props.currentPage - 2}>
                    <PaginationButtonComp
                        targetPage={props.currentPage - 2}
                        changeThePage={changeThePage}
                    />
                </li>
                )
            }
            {
                props.currentPage - 1 >= minPageNumber &&
            <li className="scl-m-pagination__item" key={props.currentPage - 1}>
                <PaginationButtonComp
                    targetPage={props.currentPage - 1}
                    changeThePage={changeThePage}
                />
            </li>
            }
            <li className="scl-m-pagination__item active" key={props.currentPage}>
                <PaginationButtonComp
                    targetPage={props.currentPage}
                    changeThePage={changeThePage}
                    disabled
                />
            </li>
            {
                props.currentPage + 1 <= maxPageNumber &&
            <li className="scl-m-pagination__item" key={props.currentPage + 1}>
                <PaginationButtonComp
                    targetPage={props.currentPage + 1}
                    changeThePage={changeThePage}
                />
            </li>
            }
            {
                props.currentPage - 1 >= minPageNumber ? null : (
                    props.currentPage + 2 <= maxPageNumber &&
                <li className="scl-m-pagination__item" key={props.currentPage + 2}>
                    <PaginationButtonComp
                        targetPage={props.currentPage + 2}
                        changeThePage={changeThePage}
                    />
                </li>
                )
            }
        </React.Fragment>
    );

    return (
        <ul className={className}>
            <li className={"scl-m-pagination__item"}>
                <PaginationButtonComp
                    targetPage={0}
                    changeThePage={changeThePage}
                    disabled={!(props.currentPage > 0)}
                >
                    <Icon name={IconNames.FIRST} />
                </PaginationButtonComp>
            </li>
            <li className={"scl-m-pagination__item scl-h-hidden--to-md"}>
                <PaginationButtonComp
                    targetPage={props.currentPage - 1}
                    changeThePage={changeThePage}
                    disabled={!(props.currentPage > 0)}
                >
                    <Icon name={IconNames.PREVIOUS} />
                </PaginationButtonComp>
            </li>
            {getPageItems}
            <li className={"scl-m-pagination__item scl-h-hidden--to-md"}>
                <PaginationButtonComp
                    targetPage={props.currentPage + 1}
                    changeThePage={changeThePage}
                    disabled={!(props.currentPage + 1 < props.pageCount)}
                >
                    <Icon name={IconNames.NEXT} />
                </PaginationButtonComp>
            </li>
            <li className={"scl-m-pagination__item"}>
                <PaginationButtonComp
                    targetPage={maxPageNumber}
                    changeThePage={changeThePage}
                    disabled={!(props.currentPage + 1 < props.pageCount)}
                >
                    <Icon name={IconNames.LAST} />
                </PaginationButtonComp>
            </li>
        </ul>
    );
};

/**
 *
 */
export const Pagination = asThemeConsumer(PaginationComp);
