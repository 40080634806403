/* eslint-disable max-len */
import { Partner } from "../Partners/Types";
import { Payment } from "../PartnerPayments/Types";
import { Policy } from "../Policies/Types";
import { Product } from "../Product/Types";
import { RequestObject } from "../RemoteTypes";
import { Tariff } from "../Tariffs/Types";
import { GeneralLedger } from "../GeneralLedgers/Types";
import { BookingPeriod } from "../BookingPeriod/Types";
import { CollectiveInvoice } from "../CollectiveInvoice/Types";
import { AuditInvoice } from "../Invoice/Types";
import { Statement } from "../Statements/Types";
import { ManualBookingResults } from "../ManualBooking/Types";

export enum RevisionType {
    UNKNOWN = "UNKNOWN",
    INSERT = "INSERT",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
}

interface RevisionMetadataInteger {
    requiredRevisionNumber: number;
    revisionInstant: string;
    requiredRevisionInstant: string;
    delegate: {
        id: number;
        user: string;
        revisionDate: string;
    };
    revisionType: RevisionType;
    revisionNumber: number;
    revisionDate: string;
}

export interface RevisionInteger {
    metadata: RevisionMetadataInteger;
    entity: Policy | Product | Partner | Tariff | Payment | GeneralLedger | BookingPeriod | CollectiveInvoice | AuditInvoice | Statement | ManualBookingResults;
    requiredRevisionNumber: number;
    revisionInstant: string;
    requiredRevisionInstant: string;
    revisionNumber: number;
}

export interface AuditTrails extends RequestObject {
    content: RevisionInteger[];
}
