/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { RequestObject } from "../RemoteTypes";
import { Amount, CreditDebitIndicator } from "../Statements/Types";
import { Partner } from "../Partners/Types";
import { Claim } from "../Claims/Types";
import { InvoicePayment } from "../CollectiveInvoice/Types";
import { State } from "../../utils/redux/State";
import { Policy } from "../Policies/Types";
import { Proxy } from "../Proxies/Types";

export enum ClaimInvoiceStatus {
    PAID = "PAID",
    UNPAID = "UNPAID",
    CANCELED = "CANCELED",
    PENDING = "PENDING",
    PARTIALLY_PAID = "PARTIALLY_PAID",
}

export enum Operation {
    BOOKING = "BOOKING",
    UNBOOKING = "UNBOOKING",
}

export interface ClaimInvoiceBooking {
    id: number;
    createdAt?: string;
    objectType?: string;
    invoiceNumber: number;
    amount: Amount;
    balance: number;
    invoiceAmount: number;
    invoiceDate: string;
    creditDebitIndicator: CreditDebitIndicator;
    status: ClaimInvoiceStatus;
    partner: Partner;
    payments: InvoicePayment[];
    transactionType: number;
    policyNumber: string;
    insuranceDuration: number;
    productName: string;
    policyId: number;
    policy: Policy;
    mutationDate: string;
    proxyId: number;
    proxy: Proxy;
    claimNumber: string;
    claimNumberPAH: string;
    claimDate: string;
    claim: Claim;
    ft_GADEKCO: string;
}

export interface ClaimInvoiceBookings extends RequestObject {
    content: ClaimInvoiceBooking[];
}

/**
 * Filters specifies the allowed filter values for the interface.
 */
export type ClaimMatchFilters = Readonly<Partial<{
    startDate: string;
    endDate: string;
    entryId: string;
}>>;

/**
 *
 */
export const ClaimMatchFiltersToParams = (f: ClaimMatchFilters) => ({
    ...f,
});

export const mapClaimMatchRequests =
    (resp: AxiosResponse): ClaimInvoiceBookings => {
        const requests = (resp.data.content ? resp.data.content as ClaimInvoiceBooking[]
            : resp.data as ClaimInvoiceBooking[]);

        const filteredResults = requests.filter((claimInvoice) => (
            // claimInvoice.status === ClaimInvoiceStatus.UNPAID &&
            !!claimInvoice.balance
        ));
        const claimMatch = {
            content: filteredResults,
            totalPages: 1,
            totalElements: filteredResults.length,
            number: 1,
        };

        return claimMatch;
    };

export interface BookClaims {
    claimInvoiceIdToBookedAmount: {
        [key: string]: number;
    };
    entryId: number;
    description?: string;
}

// export const canSearchClaimMatch =
// (s: ReduxState): boolean =>
//         s.prop("filters").prop(FilterScope.CLAIM_MATCH)
//             .map( f => Object.keys(f).length === 3 && Object.keys(f).every(k => !!f[k]));

export const canSearchClaimMatch =
    (claimFilters: State<ClaimMatchFilters>): boolean =>
        claimFilters.map(f => Object.keys(f).length === 3 && Object.keys(f).every(k => !!f[k]));
