import React from "react";

import * as PolicyTypes from "../../../../store/Policies/Types";
import { UserInfo, UserRoles } from "../../../../store/AppTypes";
import { isRemoteLoading, ReduxState } from "../../../../store/ReduxState";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Div } from "../../../../components/core/03-base/Div";
import { Grid } from "../../../../components/core/03-base/Grid";
import { Icon, IconNames } from "../../../../components/core/05-atoms/Icon";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../../../../store/AppDisplays";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { ThemePalette } from "../../../../theme/_Types";
import { Header } from "../../../../components/core/09-views/01-sections/Header";
import { Page } from "../../../../components/core/09-views/01-sections/Page";
import { Filter } from "./Filter";
import { Results } from "./Results";
import { Container } from "../../../../components/core/03-base/Container";
import { Button } from "../../../../components/core/05-atoms/Button";

interface StateProps {
    loadingFilters: boolean;
    loadingResults: boolean;
    results: PolicyTypes.Policies;
    user?: UserInfo;
}

type Props = StateProps;

const mapStateToProps = (s: ReduxState): StateProps => ({
    loadingFilters: false,
    loadingResults: isRemoteLoading(s, RemoteScope.POLICY_RESULTS),
    results: s.prop("remote").prop(RemoteScope.POLICY_RESULTS) as PolicyTypes.Policies,
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
});

export class PoliciesComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Page>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Div className="scl-b-row">
                        <Grid
                            size={{ xs: 12, md: 8 }}
                        >
                            <Header
                                title={PageDisplay[Pages.POLICIES].title}
                                description={PageDisplay[Pages.POLICIES].description}
                            />
                        </Grid>
                        {this.props.user?.userRole !== UserRoles.EW_ADMIN_READONLY &&
                        <Grid
                            size={{ xs: 12, md: 4 }}
                        >
                            <Div
                                style={{ textAlign: "right" }}
                            >
                                <NavLink
                                    to={"/policies/create"}
                                    exact
                                >
                                    <Button
                                        theme={{ palette: ThemePalette.BRAND_ACCENT }}
                                        className="scl-a-btn--big"
                                    >
                                        {SubPageDisplay[SubPages.POLICY_CREATE].title}
                                        &nbsp;
                                        &nbsp;
                                        <Icon name={IconNames.PLUS} />
                                    </Button>
                                </NavLink>
                            </Div>
                        </Grid>}
                    </Div>
                    <Filter
                        loadingFilters={this.props.loadingFilters}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.POLICIES}
                    />
                    <Results
                        results={this.props.results}
                        loadingResults={this.props.loadingResults}
                        currentPage={Pages.POLICIES}
                        scope={RemoteScope.POLICY_RESULTS}
                    />
                </Container>
            </Page>
        );
    }
}

export const Policies = connect(
    mapStateToProps,
    undefined,
)(PoliciesComp);
